import React, { useEffect, useState } from 'react';
import styles from './AlumniChats.module.scss';
import BarChatContainer from './BarChatContainer/BarChatContainer';

const AlumniChats = (props) => {
  const [requestTrends, setRequestTrends] = useState([]);
  const [recordsTrend, setRecordsTrend] = useState([]);

  const { alumniRequestTrend, alumniRecordsTrend, getRecordsTrend, getRequestTrend } = props;

  useEffect(() => {
    getRecordsTrend();
    getRequestTrend();
  }, []);

  useEffect(() => {
    if (alumniRecordsTrend?.data) {
      setRecordsTrend(Object.values(alumniRecordsTrend?.data));
    }
    if (alumniRequestTrend?.data) {
      setRequestTrends(Object.values(alumniRequestTrend?.data));
    }
  }, [alumniRequestTrend, alumniRecordsTrend]);

  return (
    <div className={styles.chatsContainer}>
      <div className={styles.chatContainer}>
        <h6>Requests trend</h6>
        <BarChatContainer chatData={requestTrends} />
      </div>
      <div className={styles.chatContainer}>
        <h6>Records trend</h6>
        <BarChatContainer chatData={recordsTrend} />
      </div>
    </div>
  );
};

export default AlumniChats;
