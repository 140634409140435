import React from 'react';
import styles from './AddManuallyModal.module.scss';
import { GrFormClose } from 'react-icons/gr';
import bulk from './../../images/bulk.png';
import single from './../../images/single.png';
const AddManuallyModal = ({ onHide, onAddBulk, onAddSingle }) => {
  return (
    <div className={styles['modal-overlay']}>
      <div className={styles.modalContainer}>
        <div className={styles.close} onClick={onHide}>
          <GrFormClose />
        </div>
        <div className={styles.header}>
          <div className={styles.title}>Add Manually</div>
        </div>

        <div className={styles.body}>
          <div className={styles.box} onClick={onAddBulk}>
            <div className={styles.boxIcon}>
              <img src={bulk} />
            </div>
            <div className={styles.boxText}>Bulk Upload</div>
            <div className={styles.boxSubText}>Some Subtext goes here</div>
          </div>
          <div className={styles.orText}>Or</div>
          <div className={styles.box} onClick={onAddSingle}>
            <div className={styles.boxIcon}>
              <img src={single} />
            </div>
            <div className={styles.boxText}>Add Single Record</div>
            <div className={styles.boxSubText}>Some Subtext goes here</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManuallyModal;
