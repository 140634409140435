import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';
import Modal from 'react-bootstrap/Modal';
import { isEmpty } from 'lodash';
import Button from '../../../core-components/Button';
import styles from './CreateSubtypeFriendlyName.module.scss';
import { getErrorMessage, getTrimmedValue } from '../../../core-components/Utilities/utilities';
import ValidatedFormInputField from '../../../core-components/ValidatedFormInputField/ValidatedFormInputField';
import useDebounce from '../../../core-components/Utilities/debounce';
import { validateFriendlyName } from '../../../../../../api/company';

export default (props) => {
  const {
    setFieldValue,
    setSubmitting,
    handleSubmit,
    isSubmitting,
    errors,
    values,
    subtypeName,
    resetForm,
    dirty
  } = props;

  const [initialCall, setIntialCall] = useState(true);
  const urlParams = new URLSearchParams(document.location.search);
  const companyId = urlParams.has('company_id') ? urlParams.get('company_id') : null;

  /**
   using this useEffect to check for initla render of component as we dont want to call debounce funtioanlity(2nd useEffect)
   becuase we want to show freindly name prepopulated in edit modal if it exists and we are setting up that value in contianer of this componet
   and in inital call if friendly names exists  validate api used to get called so to stop that api call in intial render used this useEffect
   
   *  */

  useEffect(() => {
    setIntialCall(false);
  }, []);

  // using debounce to validate frienldy name
  const debounceFrienldyName = useDebounce(values?.friendlyName, 1000);
  const isValid =
    !isSubmitting &&
    isEmpty(errors) &&
    values?.friendlyName &&
    !values?.errorForfriendlyName?.error;

  // using debounced value to call validate api for validating the friendly name
  useEffect(() => {
    if (debounceFrienldyName && !initialCall) {
      const payloads = {
        subtypeId: props?.subTypeId,
        packageId: props?.packageId,
        friendlyName: values?.friendlyName,
        companyId: props?.companyId || companyId
      };
      if (isEmpty(errors) && values?.friendlyName?.length < 20 && values?.friendlyName.length > 1) {
        validateFriendlyName(payloads)
          .then((response) => {
            setSubmitting(false);
            setFieldValue('errorForfriendlyName', { error: false, errorMsg: '' });
          })
          .catch((err) => {
            setFieldValue('errorForfriendlyName', { error: true, errorMsg: getErrorMessage(err) });
            setSubmitting(false);
            console.log(err);
          });
      } else {
        values?.errorForfriendlyName?.error
          ? setFieldValue('errorForfriendlyName', { error: false, errorMsg: '' })
          : null;
      }
    }
  }, [debounceFrienldyName]);

  const onHideHandler = () => {
    setFieldValue('friendlyName', '');
    resetForm();
    props?.onHide();
  };
  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };
  return (
    <Modal
      size='md'
      show={props?.show}
      onHide={onHideHandler}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter' className={styles.title}>
          <span className={styles.title}>Set a Custom Name for</span>
          <span className={styles.subtypeName}>{subtypeName}</span>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Field
              type='text'
              placeholder={props?.placeholder}
              component={ValidatedFormInputField}
              name='friendlyName'
              onBlur={(e) => {
                props?.handleBlur(e);
                setValue('friendlyName', values?.friendlyName, true);
              }}
            />
            {/* checking for errors to empty as if any UI validation error then show that its frist priority */}
            <span className={styles.errorMsg}>
              {isEmpty(errors) && values?.errorForfriendlyName?.errorMsg}
            </span>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.footer}>
            <p className={styles.footerLabel}> Are you sure?</p>
            <div>
              <Button onClick={onHideHandler} className={styles.cancelButton}>
                Cancel
              </Button>
              <Button
                type='submit'
                disabled={!isValid || !dirty}
                className={!isValid || !dirty ? styles.disableBtn : styles.submitButton}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
