import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import ordinal from 'ordinal';

import { PLAIN_CHECK_ICONS } from '../../../utils/commonConstant';
import CustomCounter from '../../CustomCounter';

import styles from './Reference.module.scss';

export default function Reference(props) {
  // eslint-disable-next-line no-unused-vars
  const { values, pConfig, handleChange, limit, empCount } = props;

  const handleConfigChange = (value, index) => {
    let dummy = [...values.config];
    dummy[index] = value;
    handleChange('config', dummy);
  };
  let totalRefCount = values.config.length ? values.config.reduce((a, b) => a + b, 0) : 0;

  return (
    <Row className={styles.idPackageComp}>
      <Col xl={12} md={12} lg={12} sm={12} xs={12}>
        <h6 className={styles.checkHeading}>
          <span style={{ marginRight: '16px', fontSize: '16px' }} title={'Reference'}>
            <FontAwesomeIcon
              data-testid='icon'
              icon={PLAIN_CHECK_ICONS['reference'] ? PLAIN_CHECK_ICONS['reference'].icon : faBan}
              color='#2755FE'
            />
          </span>
          {`Reference`}
        </h6>
        <span className={styles.subTitle}>{`Choose ${limit} references`}</span>
      </Col>
      <Col xl={11} md={11} lg={11} sm={11} xs={11} className={styles.checkContainer}>
        {values.config.map((value, index) => {
          return (
            <Col
              xl={12}
              md={12}
              lg={12}
              sm={12}
              xs={12}
              key={`incrementGridCount_${index}`}
              className={styles.refContainer}
            >
              <span className={styles.refText}>
                {`${index === 0 ? 'Last' : `${ordinal(index + 1)} Last`} Employment`}
                {index + 1 > empCount ? '(Only Ref Check)' : null}:
              </span>

              <CustomCounter
                limit={limit}
                totalCount={totalRefCount}
                value={value}
                index={index}
                valueChange={(value, index) => {
                  handleConfigChange(value, index);
                }}
              />
            </Col>
          );
        })}
      </Col>
    </Row>
  );
}
