import React from 'react';
import BulkUploadPackage from '../../../../BulkUploadPackage/BulkUploadPackage';
const SelectPackage = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <BulkUploadPackage {...props} />
    </div>
  );
};

export default SelectPackage;
