import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../ChargesApproval.module.scss';

export default function RechargeModal(props) {
  return (
    <div>
      <Modal
        size='md'
        show={props?.showCreditLimitModal}
        dialogClassName='educationModal adcApproveModal'
        onHide={props?.setShowCreditLimitModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <span>Credit Limit Exhausted</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.addEditEducationModalBody}>
          <div>
            <p>
              You have exhausted your credit limit.Please clear any open invoices immediately to
              resume services. For more queries, please contact cs@springverify.com
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
