// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VELtNrIXvDoag7eWeP1E {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.VELtNrIXvDoag7eWeP1E .bQ2e9maIEJswXADMHg5l {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.QOg4y4ehh9DChehIbBg3 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 48px;
  font-family: Poppins, sans-serif;
  min-height: 695px;
}
.QOg4y4ehh9DChehIbBg3 .O1BKaHjzCSr4qHKqokOk {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.QOg4y4ehh9DChehIbBg3 .O1BKaHjzCSr4qHKqokOk .pVcNWT7sa76eqVlcSDaj {
  color: #666666;
  font-weight: 500;
  font-size: 12px;
}
.QOg4y4ehh9DChehIbBg3 .O1BKaHjzCSr4qHKqokOk .UuVwzgTY6Do640Uqqy2C {
  cursor: pointer;
  color: #2755fe;
  font-weight: 500;
  font-size: 14px;
}
.QOg4y4ehh9DChehIbBg3 .yq96FHWK3jEv_1TIOOUU {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ViewBuyPackage/ReviewOrder/ReviewOrder.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,gCAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;;AACA;EACE,mBAAA;EACA,gDAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,gCAAA;EACA,iBAAA;AAEF;AADE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAGJ;AAFI;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AAIN;AAFI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAIN;AADE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;AAGJ","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  margin-top: -190px;\n  font-family: Poppins, sans-serif;\n  .heading {\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 36px;\n    color: #ffffff;\n  }\n}\n.packagesCard {\n  background: #ffffff;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  margin: 30px auto 48px;\n  padding: 48px;\n  font-family: Poppins, sans-serif;\n  min-height: 695px;\n  .packageDetailsText {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 12px;\n    .packageDetailsTextLeft {\n      color: #666666;\n      font-weight: 500;\n      font-size: 12px;\n    }\n    .packageDetailsTextRight {\n      cursor: pointer;\n      color: #2755fe;\n      font-weight: 500;\n      font-size: 14px;\n    }\n  }\n  .loaderContainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-height: 200px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `VELtNrIXvDoag7eWeP1E`,
	"heading": `bQ2e9maIEJswXADMHg5l`,
	"packagesCard": `QOg4y4ehh9DChehIbBg3`,
	"packageDetailsText": `O1BKaHjzCSr4qHKqokOk`,
	"packageDetailsTextLeft": `pVcNWT7sa76eqVlcSDaj`,
	"packageDetailsTextRight": `UuVwzgTY6Do640Uqqy2C`,
	"loaderContainer": `yq96FHWK3jEv_1TIOOUU`
};
export default ___CSS_LOADER_EXPORT___;
