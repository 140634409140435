import React from 'react';
import { DropdownList } from 'react-widgets';
import styles from './coreComponents.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
  const {
    data,
    value,
    name,
    label,
    onChange,
    handleDropDownValues,
    required,
    dropDownLabelClass,
    color
  } = props;

  const style = {
    fontSize: '14px !important',
    fontWeight: ' 600 !important',
    lineHeight: ' 21px !important',
    color: '#262626 !important',
    marginBottom: '6px !important,'
  };
  return (
    <div>
      <span className={styles.dropDownLabel}>
        {label} {required && <span style={{ color: color ? color : 'red' }}>*</span>}
      </span>
      <div>
        <DropdownList
          data={data}
          value={value || ''}
          textField='labsel'
          name={name}
          selectIcon={<FontAwesomeIcon icon={faCaretDown} />}
          onChange={(selectedValue) => {
            onChange(name, selectedValue);
            if (handleDropDownValues) handleDropDownValues(name, selectedValue);
          }}
        />
      </div>
    </div>
  );
};
