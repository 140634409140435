// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D5l8bOsUTKvphroR1Vnp {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #595959 !important;
  flex: none !important;
  align-self: flex-end !important;
}`, "",{"version":3,"sources":["webpack://./src/core-components/CustomSwitch/CustomSwitch.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,8BAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;EACA,qBAAA;EACA,+BAAA;AACF","sourcesContent":[".switch {\n  font-style: normal !important;\n  font-weight: normal !important;\n  font-size: 16px !important;\n  line-height: 22px !important;\n  color: #595959 !important;\n  flex: none !important;\n  align-self: flex-end !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `D5l8bOsUTKvphroR1Vnp`
};
export default ___CSS_LOADER_EXPORT___;
