import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../core-components/Button';
import Image from '../core-components/Image';

export default ({ title, onConfirm, onHide, ...props }) => {
  return (
    <Modal
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      {...props}
    >
      <Modal.Body>
        <Row>
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image name='confirmationSymbol.svg' />
          </Col>
          <Col lg={11} md={11} sm={11} xs={11}>
            <p className='h4'>{`Disconnect ${title}?`}</p>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className='mt-5'>
        <Button onClick={() => onHide()} variant='secondary'>
          {'Cancel'}
        </Button>
        <Button onClick={() => onConfirm()} variant='danger'>
          {'Disconnect'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
