import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { setIntegration } from '../../../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    integrationData: state?.integrationData || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIntegrationDataAPI: (companyId) => dispatch(setIntegration(companyId))
  };
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        recruiteeApiKey: '',
        recruiteeCompanyId: ''
      };
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true
  })
);
