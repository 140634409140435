import React from 'react';

import ordinal from 'ordinal';
import { Row, Col, ListGroup } from 'react-bootstrap';

import Image from '../../../../core-components/Image';
import {
  IdentityArr,
  AddressArr,
  EducationArr,
  DRUG_CHECK_TEXT,
  AddressConstants
} from '../../../../utils/commonConstant';
import { getFilteredChecks } from '../../../../utils/utilities';

import styles from './ChecksIncluded.module.scss';

const renderCreditWorld = (type = '', heading = 'World check') => {
  return (
    <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
      <ListGroup>
        <ListGroup.Item className={styles.listGroupItem}>
          <Image name={type} svg={type} className={styles.checkSvg} />
          <span className={styles.checkTextHeading}>{heading}</span>
        </ListGroup.Item>
      </ListGroup>
    </Col>
  );
};

/** funtion to render checks for court and address
 *
 *  if check array length = 1, show both permanent and current in case of ANY_ / ANY_2
 *                             else show respective address , permanent or current
 *
 *  if check array length > 1,  show both permanent and current
 */
const getFormatedChecks = (checks) => {
  let count = '',
    result = '';
  if (checks.length == 1) {
    if (checks[0]?.type === AddressConstants.ANY_1 || checks[0]?.type === AddressConstants.ANY_2) {
      count = checks[0]?.type === AddressConstants.ANY_2 ? 2 : '';
      result = AddressArr[checks[0]?.type];
    } else if (checks[0]?.type === AddressConstants.PERMANENT) {
      result = AddressArr.PERMANENT;
    } else if (checks[0]?.type === AddressConstants.CURRENT) {
      result = AddressArr.CURRENT;
    }
  } else {
    result = AddressArr.ANY_2;
  }
  return checks.length === 1 ? (
    <ListGroup.Item className={styles.listGroupItem}>
      <Image name={'CheckIcon.svg'} svg={'CheckIcon.svg'} className={styles.checkItemSvg} />
      <span className={styles.checkText}>{count}</span>
      <span className={styles.checkText}>{result}</span>
    </ListGroup.Item>
  ) : (
    checks.map((check) => (
      <ListGroup.Item className={styles.listGroupItem}>
        <Image name={'CheckIcon.svg'} svg={'CheckIcon.svg'} className={styles.checkItemSvg} />
        <span className={styles.checkText}>{AddressArr[check?.type]}</span>
      </ListGroup.Item>
    ))
  );
};

const truncateText = (text) => {
  if (typeof text !== 'string') {
    return '';
  }
  return text.length > 40 ? `${text.substring(0, 40)}...` : text;
};

function ChecksIncluded({ config, isAadhaarAllowed }) {
  // creating reference checks
  const getReferenceData = (reference) => {
    let result = [];
    for (let ref in reference) {
      let singleRef = '';
      if (reference[parseInt(ref)] > 0) {
        singleRef = (
          <ListGroup.Item key={ref} className={styles.listGroupItem}>
            <Image name={'CheckIcon.svg'} svg={'CheckIcon.svg'} className={styles.checkItemSvg} />
            <span className={styles.checkText}>{reference[ref]}</span>
            &nbsp;
            <span className={styles.checkText}>{`references from ${
              parseInt(ref) === 0
                ? 'last employment'
                : `${ordinal(parseInt(ref) + 1)} last employment`
            } `}</span>
          </ListGroup.Item>
        );
        result.push(singleRef);
      }
    }
    return result;
  };
  return (
    <>
      <Row>
        {config?.identity && config.identity.length ? (
          <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'identityIcon.svg'}
                  svg={'identityIcon.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Identity'}</span>
              </ListGroup.Item>

              {config.identity.map((check, index) => {
                let str = getFilteredChecks(config.identity, 'identity');
                if (str) {
                  str = isAadhaarAllowed ? str : str.filter((ID) => ID.toUpperCase() !== 'AADHAAR');
                }
                const isAnyExist = check?.match(/ANY_/g) ? true : false;
                const isAllExist = config.identity.includes('ALL') ? true : false;
                const allChecks = isAllExist
                  ? Object.values(IdentityArr)
                  : Object.values(IdentityArr).filter((ID) => ID !== 'Aadhaar');
                return (
                  <ListGroup.Item key={`identity_${index}`} className={styles.listGroupItem}>
                    <Image
                      name={'CheckIcon.svg'}
                      svg={'CheckIcon.svg'}
                      className={styles.checkItemSvg}
                    />
                    {isAnyExist ? (
                      <div>
                        <div>
                          <span className={styles.checkText}>{'Any'}</span>
                          &nbsp;
                          <span className={styles.checkText}>{`${check.split('_')[1]}`}</span>
                        </div>
                        {str && (
                          <span className={styles.checkText}>{`(${Object.values(str).join(
                            ', '
                          )})`}</span>
                        )}
                      </div>
                    ) : isAllExist ? (
                      <>
                        <span className={styles.checkText}>{'All'}</span>
                        &nbsp;
                        {str && (
                          <span
                            className={styles.checkText}
                            style={{ marginLeft: '8px' }}
                          >{`(${allChecks.join(', ')})`}</span>
                        )}
                      </>
                    ) : (
                      <span className={styles.checkText}>
                        {check === 'VOTER' ? IdentityArr['VOTER_ID'] : IdentityArr[check]}
                      </span>
                    )}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        ) : null}
        {config?.address && config.address.length ? (
          <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'addressIcon.svg'}
                  svg={'addressIcon.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Address'}</span>
              </ListGroup.Item>
              {getFormatedChecks(config.address)}
            </ListGroup>
          </Col>
        ) : null}

        {config?.court && config.court.map && config.court.length ? (
          <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'courtRecordIcon.svg'}
                  svg={'courtRecordIcon.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Court'}</span>
              </ListGroup.Item>
              {getFormatedChecks(config.court)}
            </ListGroup>
          </Col>
        ) : null}

        {config?.employment && config.employment > 0 ? (
          <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'employmentIcon.svg'}
                  svg={'employmentIcon.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Employment'}</span>
              </ListGroup.Item>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'CheckIcon.svg'}
                  svg={'CheckIcon.svg'}
                  className={styles.checkItemSvg}
                />
                <span className={styles.checkText}>{'Last'}</span>
                &nbsp;
                <span className={styles.checkText}>{config.employment}</span>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        ) : null}

        {config?.education &&
        (config.education > 0 || (config.education.length && config.education.map)) ? (
          <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'educationIcon.svg'}
                  svg={'educationIcon.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Education'}</span>
              </ListGroup.Item>

              {!Array.isArray(config.education) ? (
                <ListGroup.Item className={styles.listGroupItem}>
                  <Image
                    name={'CheckIcon.svg'}
                    svg={'CheckIcon.svg'}
                    className={styles.checkItemSvg}
                  />
                  <span className={styles.checkText}>
                    {config?.educationOrderType === 'NONE' ? 'Total' : 'Highest'}
                  </span>
                  &nbsp;
                  <span className={styles.checkText} variant={'subtitle2'}>
                    {config.education}
                  </span>
                </ListGroup.Item>
              ) : (
                config.education.map((edu, index) => {
                  return (
                    <ListGroup.Item className={styles.listGroupItem} key={index}>
                      <Image
                        name={'CheckIcon.svg'}
                        svg={'CheckIcon.svg'}
                        className={styles.checkItemSvg}
                      />
                      <span component={'span'} className={styles.checkText}>
                        {EducationArr[edu]}
                      </span>
                    </ListGroup.Item>
                  );
                })
              )}
            </ListGroup>
          </Col>
        ) : null}

        {config?.reference && (config.reference > 0 || config.reference.length) ? (
          <Col lg={6} md={6} sm={6} xs={12} className={styles.checkListContainer}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'referenceIcon.svg'}
                  svg={'referenceIcon.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Reference'}</span>
              </ListGroup.Item>

              {(config?.referenceType === 'SPECIFIC' ||
                config?.referenceType === 'SPECIFIC_LOCK') &&
              Array.isArray(config?.reference) ? (
                config.reference.map((ref, index) => {
                  if (ref > 0) {
                    return (
                      <ListGroup.Item key={index} className={styles.listGroupItem}>
                        <Image
                          name={'CheckIcon.svg'}
                          svg={'CheckIcon.svg'}
                          className={styles.checkItemSvg}
                        />
                        <span className={styles.checkText}>{ref}</span>
                        &nbsp;
                        <span className={styles.checkText}>{`references from ${
                          index === 0 ? 'last employment' : `${ordinal(index + 1)} last employment`
                        } `}</span>
                      </ListGroup.Item>
                    );
                  }
                })
              ) : config?.referenceType === 'TOTAL' && Array.isArray(config?.reference) ? (
                getReferenceData(config.reference)
              ) : (
                <ListGroup.Item key={0} className={styles.listGroupItem}>
                  <Image
                    name={'CheckIcon.svg'}
                    svg={'CheckIcon.svg'}
                    className={styles.checkItemSvg}
                  />

                  <span className={styles.checkText}>Total reference: {config.reference}</span>
                </ListGroup.Item>
              )}
              {config?.referenceType === 'SPECIFIC' ? (
                <div className={styles.listingText}>
                  <Image name={'Star.svg'} svg={'Star.svg'} className={styles.checkItemSvg} />
                  <span className={styles.checkText}>Configurable</span>
                </div>
              ) : null}
              {config?.referenceType === 'SPECIFIC_LOCK' ? (
                <div className={styles.listingText}>
                  <Image name={'Star.svg'} svg={'Star.svg'} className={styles.checkItemSvg} />
                  <span className={styles.checkText}>Fixed</span>
                </div>
              ) : null}
            </ListGroup>
          </Col>
        ) : null}

        {config?.world ? renderCreditWorld('worldIcon.svg', 'World check') : null}

        {config?.credit ? renderCreditWorld('cibilCreditScoreIcon.svg', 'Credit Check') : null}

        {config?.drug && config.drug !== 'NONE'
          ? renderCreditWorld('drugIcon.svg', `Drug test (${DRUG_CHECK_TEXT[config.drug]})`)
          : null}

        {config?.custom && config.custom.length ? (
          <Col lg={6} md={6} sm={6} xs={12}>
            <ListGroup>
              <ListGroup.Item className={styles.listGroupItem}>
                <Image
                  name={'CustomCheck.svg'}
                  svg={'CustomCheck.svg'}
                  className={styles.checkSvg}
                />
                <span className={styles.checkTextHeading}>{'Custom check'}</span>
              </ListGroup.Item>
              {config.custom.map((custom, index) => {
                return (
                  <ListGroup.Item key={index} className={styles.listGroupItem}>
                    <Image
                      name={'CheckIcon.svg'}
                      svg={'CheckIcon.svg'}
                      className={styles.checkItemSvg}
                    />
                    <span className={styles.checkText}>
                      {truncateText(custom?.customCheckName) || truncateText(custom)}
                    </span>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        ) : null}
      </Row>
    </>
  );
}

export default ChecksIncluded;
