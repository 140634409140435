import axios from 'axios';

import localStorage from './localStorage';
import AppConstants from '../core-components/AppConstants';
import { SV_ERRORS } from '../utils/svErrorsCode';
import history from '../utils/history';

const http = axios.create({
  baseURL: AppConstants.developmentAPIURL,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache'
  },
  proxy: {
    port: 4444
  },
  maxContentLength: 1024 * 1024 * 20
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getToken()
    ? localStorage.getBGVTokenChange() === 'true' && window.location?.pathname === '/company/bgv'
      ? localStorage.getBGVToken()
      : localStorage.getToken()
    : localStorage.getBGVToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.data?.sv_error_code === SV_ERRORS?.password_expired.sv_error_code ||
      error.response?.data?.sv_error_code === SV_ERRORS?.password_missing_to_expire.sv_error_code
    ) {
      sessionStorage.setItem('passwordExpired', true);
      sessionStorage.setItem('passwordExpiredCode', error.response.data.sv_error_code);
      forceLogout();
    }
    if (
      error &&
      error.response &&
      (error.response.status === 403 ||
        (error.response.status === 401 &&
          !(
            error.response.data.message === 'User Password Expired' ||
            error.response.data.message === 'Wrong password' ||
            error.response.data.message ===
            'Password is already setted for this account, please login.' ||
            error.response.data.message === 'User has not signed up or did not verify email' ||
            error.response.data.msg === 'Wrong password' ||
            error.response.data.msg === 'User has not signed up or did not verify email' ||
            (error?.response?.data?.body && !error?.response?.data?.body?.login_method_password)
          )))
    ) {
      forceLogout(error?.response);
    }
    return Promise.reject(error);
  }
);

const forceLogout = (error) => {
  if (
    error?.config?.url === 'auth/google-login' ||
    error?.config?.url === 'auth/microsoft-login' ||
    error?.config?.url === 'auth/signIn' ||
    error?.config?.url === 'auth/login'
  ) {
    return;
  }
  localStorage.clearStorage();
  setTimeout(() => {
    window.location.reload();
  }, 2500);
};

export default http;
