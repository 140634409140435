/* eslint-disable no-unused-expressions */
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import {
  getSecuritySettings as getSecuritySettingsAPI,
  postSecuritySettings as postSecuritySettingsAPI
} from '../../../../actions/company';
import AppConstants from '../../../../core-components/AppConstants';
import validationSchema from './securitySettings.validation';
const mapStateToProps = (state) => {
  return { securitySettingsData: state.securitySettings };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSecuritySettings: (companyId) => dispatch(getSecuritySettingsAPI(companyId)),
    postSecuritySettings: (companyId) => dispatch(postSecuritySettingsAPI(companyId))
  };
};
const companyId = new URLSearchParams(document.location.search).get('company_id') || false;
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let whiteListDomainArray = [];
      props?.securitySettingsData &&
        props?.securitySettingsData?.companyUserDomainAllowedLists &&
        props?.securitySettingsData?.companyUserDomainAllowedLists?.length > 0 &&
        props?.securitySettingsData?.companyUserDomainAllowedLists.map((value) => {
          whiteListDomainArray.push(value.domain);
        });

      let propsValue = {
        passwordLogin: props?.securitySettingsData?.loginMethodPassword,
        googleLogin: props?.securitySettingsData?.loginMethodGoogle,
        microsoftLogin: props?.securitySettingsData?.loginMethodMicrosoft,
        passwordExpiry: props?.securitySettingsData?.passwordExpiry || '',
        autoLogout: props?.securitySettingsData?.autoLogout || '',
        domainWhitelist: whiteListDomainArray,
        autoLogoutToogle: props?.securitySettingsData?.autoLogout !== null ? true : false,
        passwordExpiryToogle: props?.securitySettingsData?.passwordExpiry !== null ? true : false,
        domainWhitelistToogle:
          props?.securitySettingsData?.companyUserDomainAllowedLists?.length > 0 ? true : false
      };
      return mergeWith({}, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { props, setFieldValue }) => { }
  })
);
