import React, { useState, useEffect } from 'react';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../core-components/Button';
import styles from './AlumniVerification.module.scss';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { BiSolidEyedropper, BiInfoCircle } from 'react-icons/bi';
import Mockup from './Mockup';
import id1 from './images/bgcolor-id.png';
import id2 from './images/legal-id.png';
import id3 from './images/logo-id.png';
import id4 from './images/quote-id.png';
import Image from '../../core-components/Image';
import './custom-checkbox.scss';
import { errToastMessage } from '../../utils/Utilities';

const AlumniVerification = (props) => {
  const data = {
    defaultBrandQuote:
      'Our alumni consists of the brightest minds in the country today. We have no doubt they will help you, not just achieve your goals but set a new benchmark.'
  };

  const { alumniSettings, getAlumniSettings, editAlumniSettings } = props;

  const [formData, setFormData] = useState({
    legalName: '',
    brandName: '',
    brandQuote: '',
    titleBackgroundColor: 'black',
    titleTextColor: 'white',
    alumniLogoUrlId: '',
    selectedLogoUrl: '',
    companyLogos: {},
    hasDualLogo: false,
    isBrandEnabledInAlumniSettings: false,
    isBrandQuoteEnabled: false,
    companyLogoEnabled: false
  });

  const [isLogosGood, setIsLogosGood] = useState(false);

  const isValidImageUrl = async (url) => {
    if (!url) return null;

    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = url;
      img.onload = () => {
        resolve(img.height > 60);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const setLogoErrors = async () => {
    const cL = alumniSettings.data?.companyLogos?.reduce((logoObject, logo) => {
      return { ...logoObject, [logo.logoType]: logo };
    }, {});
    const logos = Object.values(cL).map((e) => e.logoUrl);
    const isValid = await Promise.all(logos.map(isValidImageUrl));
    if (isValid.includes(false)) {
      setIsLogosGood(false);
    } else {
      setIsLogosGood(true);
    }
  };

  function validateColorString(color) {
    if (!color) return false;
    return color.split('(').length === color.split(')').length;
  }

  useEffect(() => {
    getAlumniSettings(props?.isSA ? props?.companyId : '');
  }, []);

  useEffect(() => {
    if (alumniSettings?.data) {
      setLogoErrors();
      updateInitialValues();
    }
  }, [alumniSettings?.data]);

  useEffect(() => {
    const selectedLogo =
      Object.values(formData?.companyLogos)?.find((logo) => logo.id === formData?.alumniLogoUrlId)
        ?.logoUrl || null;
    handleFormChange('selectedLogoUrl', selectedLogo);
  }, [formData.alumniLogoUrlId]);

  const lastSettingValidation = () => {
    if (
      alumniSettings?.data?.legalName === formData?.legalName &&
      alumniSettings?.data?.brandName === formData?.brandName &&
      alumniSettings?.data?.brandQuote == formData?.brandQuote &&
      alumniSettings?.data?.titleBackgroundColor === formData?.titleBackgroundColor &&
      alumniSettings?.data?.titleTextColor === formData?.titleTextColor &&
      alumniSettings?.data?.isBrandEnabledInAlumniSettings ===
        formData?.isBrandEnabledInAlumniSettings &&
      alumniSettings?.data?.isBrandQuoteEnabled === formData?.isBrandQuoteEnabled &&
      alumniSettings?.data?.companyLogoEnabled == formData?.companyLogoEnabled &&
      alumniSettings?.data?.alumniLogoUrlId === formData?.alumniLogoUrlId
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateInitialValues = () => {
    setFormData({
      ...formData,
      legalName: alumniSettings?.data?.legalName || '',
      brandName: alumniSettings?.data?.brandName || '',
      brandQuote: alumniSettings?.data?.brandQuote || '',
      titleBackgroundColor: validateColorString(alumniSettings?.data?.titleBackgroundColor)
        ? alumniSettings?.data?.titleBackgroundColor
        : 'rgba(0, 0, 0, 1)',
      titleTextColor: validateColorString(alumniSettings?.data?.titleTextColor)
        ? alumniSettings.data.titleTextColor
        : 'rgb(255, 255, 255)',
      isBrandEnabledInAlumniSettings: alumniSettings?.data?.isBrandEnabledInAlumniSettings || false,
      isBrandQuoteEnabled: alumniSettings?.data?.isBrandQuoteEnabled || false,
      companyLogoEnabled:
        alumniSettings.data?.companyLogoEnabled &&
        alumniSettings.data?.companyLogos?.find(
          (logo) => logo.id === alumniSettings.data?.alumniLogoUrlId
        )?.logoUrl !== null,
      alumniLogoUrlId: alumniSettings.data?.alumniLogoUrlId || null,
      companyLogos: alumniSettings.data?.companyLogos?.reduce((logoObject, logo) => {
        return { ...logoObject, [logo.logoType]: logo };
      }, {})
    });

    if (alumniSettings?.data?.brandQuote === data.defaultBrandQuote) {
      setSelectedOption('Default');
    } else {
      setSelectedOption('Custom');
    }
  };

  const [gradientError, setGradientError] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleFormChange = (field, value) => {
    const newValues = {
      ...formData,
      [field]: value
    };

    setFormData(newValues);
    setHasUnsavedChanges(true);
  };

  const brandQuoteCount = formData?.brandQuote?.length;

  const handleBrandNameEnabledChange = () => {
    handleFormChange('isBrandEnabledInAlumniSettings', !formData.isBrandEnabledInAlumniSettings);
  };

  const handleQuoteCheckboxChange = (e) => {
    const updatedValues = { ...formData };
    updatedValues['isBrandQuoteEnabled'] = e.target.checked;
    if (!e.target.checked) {
      setSelectedOption('');
      updatedValues['brandQuote'] = '';
    } else if (!formData?.brandQuote || formData?.brandQuote === data.defaultBrandQuote) {
      setSelectedOption('Default');
      updatedValues['brandQuote'] = data.defaultBrandQuote;
    } else {
      setSelectedOption('Custom');
    }
    setFormData({ ...updatedValues });
  };

  const handleRadioChange = (event) => {
    if (event.target.id === 'Default') {
      handleFormChange('brandQuote', data.defaultBrandQuote);
    } else {
      handleFormChange('brandQuote', alumniSettings?.data?.brandQuote || data.defaultBrandQuote);
    }
    setHasUnsavedChanges(true);
    setSelectedOption(event.target.id);
  };

  const handleLogoCheckboxChange = (e) => {
    const udpateValues = {};
    if (e.target.checked) {
      const selectedLogo =
        Object.values(formData?.companyLogos)?.find((logo) => logo.id === formData?.alumniLogoUrlId)
          ?.logoUrl || null;
      if (!selectedLogo) {
        const firstKeyWithValue = Object.keys(formData?.companyLogos)?.find(
          (key) => formData.companyLogos[key].logoUrl
        );
        udpateValues.alumniLogoUrlId = formData?.companyLogos[firstKeyWithValue]?.id;
      }
      udpateValues.companyLogoEnabled = true;
    } else {
      udpateValues.companyLogoEnabled = false;
      udpateValues.alumniLogoUrlId = alumniSettings.data.alumniLogoUrlId || null;
    }
    setFormData({ ...formData, ...udpateValues });
  };

  const handleSave = () => {
    setIsSubmitting(true);
    const requestBody = { ...formData };
    if (props?.isSA) {
      requestBody['company_id'] = props?.companyId;
    }
    if (!requestBody.brandQuote) {
      requestBody.brandQuote = '';
    }
    editAlumniSettings(requestBody)
      .then((resp) => {
        setIsSubmitting(false);
        setHasUnsavedChanges(false);
        getAlumniSettings(props?.isSA ? props?.companyId : '');
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const handleCancel = () => {
    updateInitialValues();
    setHasUnsavedChanges(false);
  };

  return (
    <Form>
      <Card className={styles.communicationCard}>
        <div className={styles.tabInfoText}>
          Fully customise the look and feel of the verification request page to align with your
          brand.{' '}
          <span>
            <a
              href='https://support.springworks.in/portal/en/kb/articles/ex-employee-verification'
              target='_blank'
              style={{
                textDecoration: 'none',
                color: '#2755FE'
              }}
            >
              Know More.
            </a>
          </span>
        </div>
        <Card className={styles.communicationContainer}>
          <div className={styles.ContentArea}>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Legal Name</div>
              <div className={styles.input}>
                <Form.Control
                  type='name'
                  value={formData.legalName}
                  placeholder='Legal Name'
                  disabled
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>
                Brand Name
                <OverlayTrigger
                  key={`password_protected`}
                  placement='top'
                  style
                  overlay={
                    <Tooltip id={`password_protected`} style={{ width: '200px' }}>
                      <p>To edit the brand name please go to Brand settings tab.</p>
                    </Tooltip>
                  }
                >
                  <Image name='info.svg' className={'ml-2'} />
                </OverlayTrigger>
              </div>
              <div className={styles.sectionSubHeader}>
                Include Brand Name on top of verification page for easier recognition
              </div>
              <div className={styles.input}>
                <Form.Control
                  type='name'
                  placeholder='Brand Name'
                  value={formData.brandName}
                  disabled
                />
              </div>
              <div className={styles.customRadioAndCheckGroup}>
                <CustomCheckbox
                  id='brandNameCheckbox'
                  label='Enabled'
                  checked={formData.brandName && formData.isBrandEnabledInAlumniSettings}
                  disabled={!formData.brandName}
                  onChange={handleBrandNameEnabledChange}
                />
              </div>
            </div>
            <div className={styles.liner} />
            <div className={styles.fields}>
              <div className={styles.sectionHeader}>Brand Quote</div>
              <div className={styles.input}>
                <Form.Control
                  as='textarea'
                  rows={3}
                  name='brandQuote'
                  style={{ resize: 'none', fontStyle: 'italic' }}
                  onInput={(event) => {
                    if (event.target.value.trim().length > 160) {
                      event.target.value = event.target.value.trim().slice(0, 160);
                    }
                  }}
                  placeholder='Type your brand quote here'
                  onChange={(e) => {
                    handleFormChange('brandQuote', e.target.value);
                  }}
                  value={formData.brandQuote}
                  disabled={!formData.isBrandQuoteEnabled || selectedOption === 'Default'}
                />
                <div
                  className={styles.brandQuoteCharacterLimit}
                  style={
                    brandQuoteCount == 160 || (brandQuoteCount < 10 && brandQuoteCount > 0)
                      ? { color: '#DC3545' }
                      : null
                  }
                >
                  Characters {brandQuoteCount} out of 160
                </div>
              </div>
              <div className={styles.customRadioAndCheckGroup}>
                <CustomCheckbox
                  id='brandQuoteCheckbox'
                  label='Enabled'
                  checked={formData.isBrandQuoteEnabled}
                  onChange={handleQuoteCheckboxChange}
                />
                <CustomRadio
                  label='Default'
                  checked={formData.isBrandQuoteEnabled && selectedOption === 'Default'}
                  onChange={handleRadioChange}
                  disabled={!formData.isBrandQuoteEnabled}
                />
                <CustomRadio
                  label='Custom'
                  checked={formData.isBrandQuoteEnabled && selectedOption === 'Custom'}
                  onChange={handleRadioChange}
                  disabled={!formData.isBrandQuoteEnabled}
                />
              </div>
            </div>
            <div className={styles.liner} />
            <div className={styles.fields}>
              <div className={styles.sectionHeader} style={{ display: 'flex' }}>
                Company Logo
                <OverlayTrigger
                  key={`password_protected`}
                  placement='top'
                  style
                  overlay={
                    <Tooltip id={`password_protected`} style={{ width: '200px' }}>
                      <p>
                        To {formData.hasDualLogo ? 'edit' : 'add'} the company logo please go to
                        Brand settings tab.
                      </p>
                    </Tooltip>
                  }
                >
                  <Image name='info.svg' className={'ml-2'} />
                </OverlayTrigger>
              </div>
              <div className={styles.sectionSubHeader}>Include logo in title</div>
              <div className={styles.input}>
                <CustomCheckbox
                  id='logoCheckbox'
                  label='Enable'
                  checked={isLogosGood && formData.alumniLogoUrlId && formData.companyLogoEnabled}
                  onChange={(e) => handleLogoCheckboxChange(e)}
                  disabled={
                    Object.values(formData.companyLogos).every((logo) => logo.logoUrl === null) ||
                    !isLogosGood
                  }
                />
                {isLogosGood && (
                  <div className={styles.companyLogos}>
                    {Object.keys(formData.companyLogos)
                      .sort()
                      .map((key) => {
                        return (
                          formData.companyLogos[key].logoUrl && (
                            <div
                              className={`${styles.logoContainer} ${
                                formData.companyLogoEnabled &&
                                formData.alumniLogoUrlId === formData.companyLogos[key].id
                                  ? styles.active
                                  : ''
                              }`}
                              style={{
                                background:
                                  formData.companyLogos[key].logoType === 'dark'
                                    ? '#F2F2F2'
                                    : '#6a778b',
                                position: 'relative',
                                pointerEvents: formData.companyLogoEnabled ? 'auto' : 'none'
                              }}
                              onClick={() => {
                                handleFormChange('alumniLogoUrlId', formData.companyLogos[key].id);
                              }}
                            >
                              <div className={styles.select}>
                                {formData.companyLogoEnabled &&
                                formData.alumniLogoUrlId === formData.companyLogos[key].id ? (
                                  <TickCircle />
                                ) : null}
                              </div>
                              <div className={styles.logo}>
                                <img src={formData.companyLogos[key].logoUrl} />
                              </div>
                            </div>
                          )
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.liner} />
            <div className={styles.fields}>
              <div className={styles.columns}>
                <div>
                  <div className={styles.sectionHeader}>Title Background Color</div>
                  <ColorPickerInput
                    type={'any'}
                    color={formData.titleBackgroundColor}
                    setColor={(e) => handleFormChange('titleBackgroundColor', e)}
                    setGradientError={setGradientError}
                  />
                </div>
                <div>
                  <div className={styles.sectionHeader}>Title Text Color</div>
                  <ColorPickerInput
                    type={'solid'}
                    color={formData.titleTextColor}
                    setColor={(e) => handleFormChange('titleTextColor', e)}
                    setGradientError={setGradientError}
                  />
                </div>
              </div>
              {gradientError && <div className={styles.logoError}>{gradientError}</div>}
            </div>
          </div>

          <div className={styles.previewArea}>
            <div className={styles.previewTitle}>PAGE PREVIEW</div>
            <div
              className={styles.mockup}
              style={{
                marginTop: '50px',
                border: '1px solid #f3f2f2'
              }}
            >
              <Mockup
                legalName={formData.legalName}
                brandName={formData.brandName}
                brandQuote={formData.brandQuote}
                brandLogo={isLogosGood ? formData.selectedLogoUrl : ''}
                titleBgColor={formData.titleBackgroundColor}
                titleTextColor={formData.titleTextColor}
                isLogoEnabled={formData.companyLogoEnabled}
                isBrandNameEnabled={formData.isBrandEnabledInAlumniSettings}
                isQuoteEnabled={formData.isBrandQuoteEnabled}
              />
            </div>
            <img src={id1} className={styles.placeholder1} style={{ width: '20%' }} />
            <img src={id2} className={styles.placeholder2} style={{ width: '20%' }} />
            <img src={id3} className={styles.placeholder3} style={{ width: '28%' }} />
            <img src={id4} className={styles.placeholder4} style={{ width: '20%' }} />
          </div>
        </Card>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.cancelButton}
            disabled={!hasUnsavedChanges || lastSettingValidation()}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className={styles.submitButton}
            disabled={
              isSubmitting ||
              (formData.isBrandQuoteEnabled && brandQuoteCount < 10) ||
              gradientError ||
              lastSettingValidation()
            }
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Card>
    </Form>
  );
};

export default AlumniVerification;

const CustomCheckbox = ({ id, label, checked, onChange, disabled }) => {
  return (
    <div
      className={`custom-control custom-checkbox custom-checkbox-style-1 ${
        disabled ? 'disabled' : ''
      }`}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <input
        type='checkbox'
        className='custom-control-input'
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className='custom-control-label' htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

const CustomRadio = ({ label, checked, onChange, disabled }) => {
  return (
    <div
      className={`custom-control custom-radio custom-radio-style-1 ${disabled ? 'disabled' : ''}`}
    >
      <input
        type='radio'
        className='custom-control-input'
        id={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className='custom-control-label' htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

const TickCircle = () => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 22a9.97 9.97 0 0 0 7.071-2.929A9.97 9.97 0 0 0 22 12a9.969 9.969 0 0 0-2.929-7.071A9.969 9.969 0 0 0 12 2a9.969 9.969 0 0 0-7.071 2.929A9.969 9.969 0 0 0 2 12a9.969 9.969 0 0 0 2.929 7.071A9.969 9.969 0 0 0 12 22Z'
      fill='#2755FE'
    />
    <path
      d='m8 12 3 3 6-6'
      stroke='#fff'
      strokeWidth={2.167}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const ColorPickerInput = ({ type, color, setColor, setGradientError }) => {
  const [visible, setVisible] = useState(false);
  const { valueToHex, isGradient } = useColorPicker(color, setColor);

  const handleGradientChange = (gradient) => {
    setColor(gradient);
    const stops = (gradient.toLowerCase().match(/rgba/g) || []).length;
    if (stops > 3) {
      setGradientError('Maximum 3 colors allowed in Gradient');
    } else {
      setGradientError(null);
    }
  };

  function convertFloatToIntInRGBA(str) {
    return str.replace(/(\d+\.\d+)/g, (match) => Math.round(parseFloat(match)));
  }

  return (
    <div className={styles.gradientPicker} style={{ position: 'relative' }}>
      {visible ? (
        <>
          <div className={styles.colorPicker}>
            {type === 'solid' ? (
              <ColorPicker
                value={color}
                onChange={(e) => {
                  setColor(convertFloatToIntInRGBA(e));
                }}
                hideControls={true}
                hidePresets={true}
              />
            ) : (
              <ColorPicker
                value={color}
                onChange={(e) => {
                  handleGradientChange(convertFloatToIntInRGBA(e));
                }}
                hidePresets={true}
                hideEyeDrop={true}
              />
            )}
          </div>
          <div
            style={{
              width: '100%',
              height: '100vh',
              position: 'fixed',
              top: 0,
              left: 0
            }}
            onClick={() => {
              setVisible(!visible);
            }}
          ></div>
        </>
      ) : null}
      <div
        className={styles.pickerInput}
        style={{ position: 'relative' }}
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <div className={styles.pickerText}>{isGradient ? 'Gradient' : valueToHex()}</div>
        <div
          className={styles.pickerBox}
          style={{ background: color, position: 'absolute', right: '5px' }}
        >
          <BiSolidEyedropper style={{ color: 'white' }} />
        </div>
      </div>
    </div>
  );
};
