import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
