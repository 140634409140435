import React from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from 'formik';

export default ({ field, form, ...props }) => {
  return (
    <div>
      <Form.Check
        id={field.name}
        {...field}
        {...props}
        type='checkbox'
        disabled={props.disabled}
        checked={!props.disabled ? field.value : ''}
        isInvalid={!props.label && form && form.touched[field.name] && !!form.errors[field.name]}
        data-testid='testField'
      />
      {props.label && (
        <p style={{ color: '#DC3545', marginTop: '.25rem', fontSize: '80%' }}>
          <ErrorMessage name={field.name} />
        </p>
      )}
    </div>
  );
};
