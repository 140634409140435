import {
  INVALIDATE_CANDIDATES,
  REQUEST_CANDIDATES,
  SET_CANDIDATES,
  SET_CANDIDATE_INFO,
  UPDATE_CANDIDATE_INFO,
  UPDATE_STATUS_COUNT,
  REQUEST_INSUFFICIENCIES,
  SET_INSUFFICIENCIES,
  INVALIDATE_INSUFFICIENCIES,
  INVALIDATE_SEARCH,
  REQUEST_SEARCH,
  SET_SEARCH_RESULT,
  UPDATE_CATEGORIES,
  REQUEST_CATEGORIES,
  SET_CATEGORIES,
  INVALIDATE_CATEGORIES,
  UPDATE_COMPANY_SERVICES,
  INVALIDATE_ADMINISTRATORS,
  REQUEST_ADMINISTRATORS,
  SET_ADMINISTRATORS,
  UPDATE_ADMINISTRATORS,
  UPDATE_FORM_CONFIG,
  UPDATE_TAGS,
  REQUEST_TAGS,
  SET_TAGS,
  INVALIDATE_TAGS,
  SET_NEW_INSUFFICIENCIES,
  INVALIDATE_NEW_INSUFFICIENCIES,
  SET_EMAIL_PREFERENCES,
  INVALIDATE_EMAIL_PREFERENCES,
  REQUEST_EMAIL_PREFERENCES,
  SET_PURCHASE_HISTORY,
  INVALIDATE_PURCHASE_HISTORY,
  INVALIDATE_ADD_CANDIDATE_CONFIG,
  REQUEST_ADD_CANDIDATE_CONFIG,
  SET_ADD_CANDIDATE_CONFIG,
  INVALIDATE_VIEW_BUY_PACKAGES,
  REQUEST_VIEW_BUY_PACKAGES,
  SET_VIEW_BUY_PACKAGES,
  REQUEST_PURCHASE_HISTORY,
  INVALIDATE_CREDIT_TRANSACTION_HISTORY,
  REQUEST_CREDIT_TRANSACTION_HISTORY,
  SET_CREDIT_TRANSACTION_HISTORY,
  INVALIDATE_INSUFF_CREDITS,
  REQUEST_INSUFF_CREDITS,
  SET_INSUFF_CREDITS,
  REQUEST_APPROVAL_LIST,
  SET_APPROVAL_LIST,
  REQUEST_PAYMENT_DETAILS,
  SET_PAYMENT_DETAILS,
  SET_BULK_UPLOAD_DATA,
  INVALIDATE_BULK_UPLOAD_DATA,
  SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA,
  GET_ADDRESS_COMPONENT,
  SET_ADDRESS_COMPONENT,
  INVALIDATE_ADDRESS_COMPONENT,
  REQUEST_USAGE_LOGS,
  SET_USAGE_LOGS,
  INVALIDATE_USAGE_LOGS,
  INVALIDATE_CREDIT_TRANSACTION_DETAILS,
  REQUEST_CREDIT_TRANSACTION_DETAILS,
  SET_CREDIT_TRANSACTION_DETAILS,
  SET_SECURITY_SETTINGS_CONFIGURATION,
  REQUEST_SECURITY_SETTINGS_CONFIGURATION,
  SET_INTEGRATION_DATA,
  REQUEST_INTEGRATION_DATA,
  INVALIDATE_INTEGRATION_DATA,
  SET_INTEGRATION_FIELD_SUBTYPE_MAPPING,
  INVALIDATE_INTEGRATION_FIELD_SUBTYPE_MAPPING,
  REQUEST_INTEGRATION_FIELD_SUBTYPE_MAPPING,
  SET_GREENHOUSE_FIELD,
  SET_GREENHOUSE_CUSTOM_FIELD_JOBS,
  INVALIDATE_SECURITY_SETTINGS_CONFIGURATION,
  SET_WALLET_DETAIL,
  REQUEST_WALLET_DETAIL,
  INVALIDATE_WALLET_DETAIL,
  INVALIDATE_SELECTED_TIERS,
  SET_SELECTED_TIERS,
  INVALIDATE_PURCHASE_ORDER,
  SET_PURCHASE_ORDER,
  REQUEST_PURCHASE_ORDER,
  INVALIDATE_PAYMENT_USERS,
  REQUEST_PAYMENT_USERS,
  SET_PAYMENT_USERS,
  INVALIDATE_ZOHO_CONNECTION,
  REQUEST_ZOHO_CONNECTION,
  SET_ZOHO_CONNECTION,
  REQUEST_ADDITIONAL_CHARGE_SETTINGS,
  SET_ADDITIONAL_CHARGE_SETTINGS,
  INVALIDATE_ADDITIONAL_CHARGE_SETTINGS,
  REQ_EXPIRE_PASSWORD,
  SET_EXPIRE_PASSWORD_RES,
  REQUEST_ALUMNI_TABLE_DATA,
  SET_ALUMNI_TABLE_DATA,
  REQUEST_ALUMNI_RECORDS_TREND,
  REQUEST_ALUMNI_REQUEST_TREND,
  SET_ALUMNI_RECORDS_TREND,
  SET_ALUMNI_REQUEST_TREND,
  REQUEST_ALUMNI_RECORD_OVERVIEW,
  REQUEST_ALUMNI_REQUEST_OVERVIEW,
  SET_ALUMNI_RECORD_OVERVIEW,
  SET_ALUMNI_REQUEST_OVERVIEW,
  REQUEST_HRMS_SYNC_DATA,
  SET_HRMS_SYNC_DATA,
  REQUEST_BRAND_SETTINGS,
  SET_BRAND_SETTINGS,
  REQUEST_ALUMNI_SETTINGS,
  SET_ALUMNI_SETTINGS,
  SET_ALUMNI_UPLOAD_DATA,
  INVALIDATE_ALUMNI_UPLOAD_DATA,
  SET_REFERRAL_DATA,
  INVALIDATE_FREQUENT_INTEGRATION_DATA,
  SET_FREQUENT_INTEGRATION_DATA,
  REQUEST_FREQUENT_INTEGRATION_DATA
} from '../actions/company';

import {
  INVALIDATE_BGV_CONFIG,
  REQUEST_BGV_CONFIG,
  SET_BGV_CONFIG,
  INVALIDATE_BGV_CANDIDATE_DETAILS,
  REQUEST_BGV_CANDIDATE_DETAILS,
  SET_BGV_CANDIDATE_DETAILS,
  INVALIDATE_BGV_CACHE,
  REQUEST_BGV_CACHE,
  SET_BGV_CACHE,
  SET_ID_UPLOAD,
  SET_OCR,
  SET_CERTIFICATE_UPLOAD,
  SET_CERTIFICATE_UPLOAD_UPDATED_WITH_PASSWORD,
  SET_GRADESHEET_UPLOAD,
  SET_GRADESHEET_UPLOAD_UPDATED_WITH_PASSWORD,
  SET_SINGLE_GRADESHEET_UPLOAD,
  SET_SINGLE_GRADESHEET_UPLOAD_UPDATED,
  INVALIDATE_CERTIFICATE_UPLOAD,
  INVALIDATE_GRADESHEET_UPLOAD,
  DELETE_GRADESHEET_UPLOAD,
  SET_BGV_FEEDBACK,
  INVALIDATE_BGV_FEEDBACK,
  SET_TEMPORARY_EDUCATION,
  INVALIDATE_TEMPORARY_EDUCATION,
  SET_BGV_CONSENT,
  INVALIDATE_BGV_CONSENT,
  SET_OCR_ERROR,
  SET_EMP_DOC_TYPE,
  INVALIDATE_EMP_DOC_TYPE,
  SET_ADDRESS_DOC_TYPE,
  INVALIDATE_ADDRESS_DOC_TYPE,
  SET_ADDRESS_FRONT_DOC,
  SET_ADDRESS_BACK_DOC,
  SET_ADDRESS_SAME_DOC,
  INVALIDATE_ADDRESS_FRONT_DOC,
  INVALIDATE_ADDRESS_BACK_DOC,
  INVALIDATE_ADDRESS_SAME_DOC,
  REQUEST_BGV_PACKAGE_CONFIG,
  SET_BGV_PACKAGE_CONFIG,
  INVALIDATE_BGV_PACKAGE_CONFIG,
  INVALIDATE_HR_REFER,
  REQUEST_HR_REFER,
  SAVE_HR_REFER,
  SKIP_HR_REFER
} from '../actions/bgv';

import { INVALIDATE_REPORTS, SET_REPORTS, REQUEST_REPORTS } from '../actions/admin';

import { UPDATE_PROFILE, UPDATE_PAYMENT_TYPE, INVALIDATE_PROFILE } from '../actions/authentication';

export default (state, action) => {
  return {
    candidates: candidates(state ? state.candidates : { all: [] }, action),
    statusCount: statusCount(state ? state.statusCount : {}, action),
    categories: categories(state ? state.categories : [], action),
    insufficiencies: insufficiencies(state ? state.insufficiencies : { result: [] }, action),
    search: search(state ? state.search : { result: [] }, action),
    profile: profile(state ? state.profile : {}, action),
    companyPaymentType: companyPayment(state ? state.companyPaymentType : {}, action),
    companyServices: companyServices(state ? state.companyServices : {}, action),
    administrators: administrators(state ? state.administrators : [], action),
    candidateInfo: candidateInfo(state ? state.candidateInfo : {}, action),
    formConfig: formConfig(state ? state.formConfig : {}, action),
    tags: tags(state ? state.tags : [], action),
    bgvConfig: bgvConfig(state ? state.bgvConfig : {}, action),
    initialCandidateDetails: bgvInitialCandidateDetails(
      state ? state.initialCandidateDetails : {},
      action
    ),
    bgvCachedData: bgvCachedData(state ? state.bgvCachedData : {}, action),
    idDetails: idDetails(state ? state.idDetails : {}, action),
    singleGradeUpload: singleGradeUpload(state ? state.singleGradeUpload : {}, action),
    certificateDetails: certificateDetails(state ? state.certificateDetails : {}, action),
    gradesheetDetails: gradesheetDetails(state ? state.gradesheetDetails : {}, action),
    feedback: setBgvFeedback(state ? state.feedback : {}, action),
    temporaryEducation: setTemporaryEducationData(state ? state.temporaryEducation : {}, action),
    bgvConsentData: setBgvConsentData(state ? state.bgvConsentData : {}, action),
    empDocType: setEmpDocType(state ? state.empDocType : {}, action),
    addressDocType: setAddressDocType(state ? state.addressDocType : {}, action),
    addressDocFront: setAddressDocFront(state ? state.addressDocFront : {}, action),
    addressDocBack: setAddressDocBack(state ? state.addressDocBack : {}, action),
    addressDocSame: setAddressDocSame(state ? state.addressDocSame : {}, action),
    insufficienciesNew: setNewInsufficiencies(state ? state.insufficienciesNew : {}, action),
    emailPreferences: setEmailPreferences(state ? state.emailPreferences : {}, action),
    createCandConfig: setAddCandidateConfig(state ? state.createCandConfig : {}, action),
    viewBuyPackages: setViewBuyPackages(state ? state.viewBuyPackages : {}, action),
    purchaseHistory: setPurchaseHistory(state ? state.purchaseHistory : {}, action),
    bgvPackageConfig: getBgvPackageConfig(state ? state.bgvPackageConfig : {}, action),
    creditTransactionHistory: getCreditTransactionHistory(
      state ? state.creditTransactionHistory : {},
      action
    ),
    insuffCredits: getInsuffCredits(state ? state.insuffCredits : {}, action),
    approvalList: getApprovalList(state ? state.approvalList : [], action),
    paymentDetails: getCompanyPaymentDetails(state ? state.paymentDetails : {}, action),
    bulkData: setBulkUploadData(state ? state.bulkData : {}, action),
    geoCodeAddress: geoCodeAddress(state ? state.geoCodeAddress : {}, action),
    usageLogs: usageLogs(state ? state.usageLogs : {}, action),
    paymentCreditTransaction: setPaymentCreditTransaction(
      state ? state.paymentCreditTransaction : {},
      action
    ),
    securitySettings: setSecuritySettings(state ? state.securitySettings : {}, action),
    walletDetail: setWalletDetail(state ? state.walletDetail : {}, action),
    selectedTiers: selectedTiersReducer(state ? state.selectedTiers : {}, action),
    purchaseOrders: purchaseOrdersReducer(state ? state.purchaseOrders : {}, action),
    requestPaymentUsers: paymentRequestUserReducer(state ? state.requestPaymentUsers : {}, action),
    zohoConnection: zohoConnectedReducer(state ? state.zohoConnection : {}, action),
    paymentCreditTransaction: setPaymentCreditTransaction(
      state ? state.paymentCreditTransaction : {},
      action
    ),
    securitySettings: setSecuritySettings(state ? state.securitySettings : {}, action),
    integrationData: setIntegrationData(state ? state.integrationData : {}, action),
    frequentIntegration: setFrequentIntegrationData(state ? state.frequentIntegration : {}, action),
    integrationFieldSubtypeMapping: setIntegrationFieldSubtypeMapping(
      state ? state.integrationFieldSubtypeMapping : {},
      action
    ),
    greenHouseCustomFields: setGreenHouseCustomFields(
      state ? state.greenHouseCustomFields : {},
      action
    ),
    greenHouseCustomFieldJobs: setGreenHouseCustomFieldJobs(
      state ? state.greenHouseCustomFieldJobs : {},
      action
    ),
    adcSettings: setAdditionalChargeSettings(state ? state.adcSettings : {}, action),
    expirePassword: expirePassword(state ? state.expirePassword : {}, action),
    alumniTableData: alumniTableData(state ? state.alumniTableData : {}, action),
    alumniRequestTrend: alumniRequestTrend(state ? state.alumniRequestTrend : {}, action),
    alumniRecordsTrend: alumniRecordsTrend(state ? state.alumniRecordsTrend : {}, action),
    alumniRequestOverview: alumniRequestOverview(state ? state.alumniRequestOverview : {}, action),
    alumniRecordOverview: alumniRecordOverview(state ? state.alumniRecordOverview : {}, action),
    HRMSSyncData: HRMSSyncData(state ? state.HRMSSyncData : {}, action),
    brandSettings: brandSettings(state ? state.brandSettings : {}, action),
    alumniSettings: alumniSettings(state ? state.alumniSettings : {}, action),
    alumniBulkUpload: alumniBulkUpload(state ? state.alumniBulkUpload : {}, action),
    referralData: referralData(state ? state.referralData : {}, action),
    bgvHrReferralData: hrReferralData(state ? state.bgvHrReferralData : {}, action),
    reportData: setReports(state ? state.reportData : {}, action)
  };
};

const candidates = (state, action) => {
  switch (action.type) {
    case INVALIDATE_CANDIDATES:
      return { all: [] };
    case REQUEST_CANDIDATES:
      return { ...state, loading: true };
    case SET_CANDIDATES:
      const candidatesList = state.all;
      candidatesList[action.index] = action.data;
      return { ...state, all: candidatesList, loading: false };
    default:
      return state;
  }
};

const candidateInfo = (state, action) => {
  const candidateData = action.data;
  switch (action.type) {
    case SET_CANDIDATE_INFO:
      return {
        ...state,
        candidateData
      };
    case UPDATE_CANDIDATE_INFO:
      return action.data;
    default:
      return state;
  }
};

const statusCount = (state, action) => {
  if (action.type === UPDATE_STATUS_COUNT) {
    return action.data;
  } else {
    return state;
  }
};

const categories = (state, action) => {
  switch (action.type) {
    case INVALIDATE_CATEGORIES:
      return [];
    case REQUEST_CATEGORIES:
      return { ...state, loading: true };
    case SET_CATEGORIES:
      return {
        ...state,
        loading: false
      };
    case UPDATE_CATEGORIES:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};

const administrators = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADMINISTRATORS:
      return {};
    case REQUEST_ADMINISTRATORS:
      return { ...state, loading: true };
    case SET_ADMINISTRATORS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_ADMINISTRATORS:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};
const paymentRequestUserReducer = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAYMENT_USERS:
      return {};
    case REQUEST_PAYMENT_USERS:
      return { ...state, loading: true };
    case SET_PAYMENT_USERS:
      return {
        ...state,
        paymentUsers: action.data,
        loading: false
      };
    default:
      return state;
  }
};
const zohoConnectedReducer = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ZOHO_CONNECTION:
      return {};
    case REQUEST_ZOHO_CONNECTION:
      return { ...state, loading: true };
    case SET_ZOHO_CONNECTION:
      return {
        ...state,
        zohoConnection: action.data,
        loading: false
      };
    default:
      return state;
  }
};
const search = (state, action) => {
  switch (action.type) {
    case INVALIDATE_SEARCH:
      return { ...state, result: [] };
    case REQUEST_SEARCH:
      return { ...state, loading: true };
    case SET_SEARCH_RESULT:
      return { ...state, result: action.data, loading: false };
    default:
      return state;
  }
};

const profile = (state, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return { ...state, profile: action.data };

    case INVALIDATE_PROFILE:
      return { ...state, profile: {} };
    default:
      return state;
  }
};

const companyPayment = (state, action) => {
  if (action.type === UPDATE_PAYMENT_TYPE) {
    return { ...state, companyPaymentType: action.data };
  } else {
    return state;
  }
};

const companyServices = (state, action) => {
  if (action.type === UPDATE_COMPANY_SERVICES) {
    return action.data;
  } else {
    return state;
  }
};

const insufficiencies = (state, action) => {
  switch (action.type) {
    case INVALIDATE_INSUFFICIENCIES:
      return { result: [] };
    case REQUEST_INSUFFICIENCIES:
      return { ...state, loading: true };
    case SET_INSUFFICIENCIES:
      const inSufficiencies = state.result;
      inSufficiencies[action.index] = action.data;
      return {
        ...state,
        result: inSufficiencies,
        loading: false
      };
    default:
      return state;
  }
};

const formConfig = (state, action) => {
  if (action.type === UPDATE_FORM_CONFIG) {
    return action.data;
  } else {
    return state;
  }
};

const tags = (state, action) => {
  switch (action.type) {
    case INVALIDATE_TAGS:
      return [];
    case REQUEST_TAGS:
      return { ...state, loading: true };
    case SET_TAGS:
      return {
        ...state,
        loading: false
      };
    case UPDATE_TAGS:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};

// BGV Section
const bgvConfig = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BGV_CONFIG:
      return {};
    case REQUEST_BGV_CONFIG:
      return { ...state, loading: true };
    case SET_BGV_CONFIG:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const bgvInitialCandidateDetails = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BGV_CANDIDATE_DETAILS:
      return { ...state, loading: true };
    case REQUEST_BGV_CANDIDATE_DETAILS:
      return { ...state, loading: true };
    case SET_BGV_CANDIDATE_DETAILS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const idDetails = (state, action) => {
  switch (action.type) {
    case SET_ID_UPLOAD:
      return action.data;
    case SET_OCR:
      return action.data;
    case SET_OCR_ERROR:
      return action.data;
    default:
      return state;
  }
};

const singleGradeUpload = (state, action) => {
  switch (action.type) {
    case SET_SINGLE_GRADESHEET_UPLOAD:
      return action.data;
    case SET_SINGLE_GRADESHEET_UPLOAD_UPDATED:
      const stateCopy = state;
      if (action.data.url) {
        stateCopy.link.data = action.data.url;
        return stateCopy;
      }
      return action.data;
    default:
      return state;
  }
};

const bgvCachedData = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BGV_CACHE:
      return { ...state, loading: true };
    case REQUEST_BGV_CACHE:
      return { ...state, loading: true };
    case SET_BGV_CACHE:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const certificateDetails = (state, action) => {
  switch (action.type) {
    case INVALIDATE_CERTIFICATE_UPLOAD:
      return {};
    case SET_CERTIFICATE_UPLOAD:
      return action.data;
    case SET_CERTIFICATE_UPLOAD_UPDATED_WITH_PASSWORD:
      return action.data;
    default:
      return state;
  }
};

const gradesheetDetails = (state, action) => {
  switch (action.type) {
    case INVALIDATE_GRADESHEET_UPLOAD:
      return [];
    case SET_GRADESHEET_UPLOAD:
      const gradesheetCopy = state.length ? [...state] : [];
      if (gradesheetCopy[action.data.index]) {
        gradesheetCopy[action.data.index] = { ...action.data };
      } else {
        gradesheetCopy.push({ ...action.data });
      }
      return gradesheetCopy;
    case DELETE_GRADESHEET_UPLOAD:
      const gradesheet = action.data.length ? [...action.data] : [];
      return gradesheet;
    case SET_GRADESHEET_UPLOAD_UPDATED_WITH_PASSWORD:
      return action.data;
    default:
      return state;
  }
};

const setBgvFeedback = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BGV_FEEDBACK:
      return {};
    case SET_BGV_FEEDBACK:
      return { isFeedbackSubmitted: true };
    default:
      return state;
  }
};

const setTemporaryEducationData = (state, action) => {
  switch (action.type) {
    case INVALIDATE_TEMPORARY_EDUCATION:
      return {};
    case SET_TEMPORARY_EDUCATION:
      return { ...action.data };
    default:
      return state;
  }
};

const setBgvConsentData = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BGV_CONSENT:
      return {};
    case SET_BGV_CONSENT:
      return { ...action.data };
    default:
      return state;
  }
};

const setEmpDocType = (state, action) => {
  switch (action.type) {
    case INVALIDATE_EMP_DOC_TYPE:
      return '';
    case SET_EMP_DOC_TYPE:
      return action.data;
    default:
      return state;
  }
};

const setAddressDocType = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_DOC_TYPE:
      return {};
    case SET_ADDRESS_DOC_TYPE:
      return action.data;
    default:
      return state;
  }
};

const setAddressDocFront = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_FRONT_DOC:
      return {};
    case SET_ADDRESS_FRONT_DOC:
      return action.data;

    default:
      return state;
  }
};

const setAddressDocBack = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_BACK_DOC:
      return {};
    case SET_ADDRESS_BACK_DOC:
      return action.data;
    default:
      return state;
  }
};

const setAddressDocSame = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_SAME_DOC:
      return {};
    case SET_ADDRESS_SAME_DOC:
      return { same: action.data };
    default:
      return state;
  }
};

const setNewInsufficiencies = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_NEW_INSUFFICIENCIES:
      return {};
    case SET_NEW_INSUFFICIENCIES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setEmailPreferences = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_EMAIL_PREFERENCES:
      return { ...state, loading: true };
    case INVALIDATE_EMAIL_PREFERENCES:
      return {};
    case SET_EMAIL_PREFERENCES:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

//************ PRE-PAID PACKAGES***********/

const setPurchaseHistory = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_PURCHASE_HISTORY:
      return {};
    case REQUEST_PURCHASE_HISTORY:
      return { loading: true };
    case SET_PURCHASE_HISTORY:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setAddCandidateConfig = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ADD_CANDIDATE_CONFIG:
      return {};
    case REQUEST_ADD_CANDIDATE_CONFIG:
      return { loading: true };
    case SET_ADD_CANDIDATE_CONFIG:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setViewBuyPackages = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_VIEW_BUY_PACKAGES:
      return {};
    case REQUEST_VIEW_BUY_PACKAGES:
      return { loading: true };
    case SET_VIEW_BUY_PACKAGES:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const getBgvPackageConfig = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BGV_PACKAGE_CONFIG:
      return { ...state, loading: true };
    case REQUEST_BGV_PACKAGE_CONFIG:
      return { ...state, loading: true };
    case SET_BGV_PACKAGE_CONFIG:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const getCreditTransactionHistory = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_CREDIT_TRANSACTION_HISTORY:
      return { all: [] };
    case REQUEST_CREDIT_TRANSACTION_HISTORY:
      return { ...state, loading: true };
    case SET_CREDIT_TRANSACTION_HISTORY:
      const list = state.all;
      list[action.index] = action.data;

      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const getInsuffCredits = (state = { all: [] }, action) => {
  switch (action.type) {
    case INVALIDATE_INSUFF_CREDITS:
      return { all: [] };
    case REQUEST_INSUFF_CREDITS:
      return { ...state, loading: true };
    case SET_INSUFF_CREDITS:
      const list = state.all;
      list[action.index] = action.data;

      return { ...state, all: list, loading: false };
    default:
      return state;
  }
};

const getApprovalList = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_APPROVAL_LIST:
      return { ...state, loading: true };
    case SET_APPROVAL_LIST:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};
const getCompanyPaymentDetails = (state, action) => {
  switch (action.type) {
    case REQUEST_PAYMENT_DETAILS:
      return { loading: true };
    case SET_PAYMENT_DETAILS:
      return { ...state, ...action.data, loading: false };
    default:
      return state;
  }
};

const setBulkUploadData = (state = { bulkData: {} }, action) => {
  switch (action.type) {
    case INVALIDATE_BULK_UPLOAD_DATA:
      return {};
    case SET_BULK_UPLOAD_DATA:
      return { ...state, rows: action?.data };
    case SET_DROPDOWN_VALUES_BULK_UPLOAD_DATA:
      return { ...state, values: action?.data };
    default:
      return state;
  }
};

const geoCodeAddress = (state, action) => {
  switch (action.type) {
    case INVALIDATE_ADDRESS_COMPONENT:
      return {};
    case GET_ADDRESS_COMPONENT:
      return { ...state, ...{ address: null, loading: true } };
    case SET_ADDRESS_COMPONENT:
      return { ...state, ...{ address: action.data.results, loading: false } };
    default:
      return state;
  }
};

const usageLogs = (state, action) => {
  switch (action.type) {
    case INVALIDATE_USAGE_LOGS:
      return {};
    case REQUEST_USAGE_LOGS:
      return { ...state, ...{ usageLogs: null, loading: true } };
    case SET_USAGE_LOGS:
      return { ...state, ...{ usageLogs: action.data, loading: false } };
    default:
      return state;
  }
};

const setPaymentCreditTransaction = (state, action) => {
  switch (action.type) {
    case INVALIDATE_CREDIT_TRANSACTION_DETAILS:
      return {};
    case REQUEST_CREDIT_TRANSACTION_DETAILS:
      return { ...state, ...{ paymentCreditTransaction: null, loading: true } };
    case SET_CREDIT_TRANSACTION_DETAILS:
      return { ...state, ...{ paymentCreditTransaction: action.data, loading: false } };
    default:
      return state;
  }
};
const setSecuritySettings = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SECURITY_SETTINGS_CONFIGURATION:
      return {};
    case REQUEST_SECURITY_SETTINGS_CONFIGURATION:
      return { loading: true };
    case SET_SECURITY_SETTINGS_CONFIGURATION:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};
const setWalletDetail = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_WALLET_DETAIL:
      return {};
    case REQUEST_WALLET_DETAIL:
      return { loading: true };
    case SET_WALLET_DETAIL:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};
const setIntegrationData = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_INTEGRATION_DATA:
      return {};
    case REQUEST_INTEGRATION_DATA:
      return { loading: true };
    case SET_INTEGRATION_DATA:
      return { ...action.data?.integrations, loading: false };
    default:
      return state;
  }
};
const setFrequentIntegrationData = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_FREQUENT_INTEGRATION_DATA:
      return {};
    case REQUEST_FREQUENT_INTEGRATION_DATA:
      return { data: {}, loading: true };
    case SET_FREQUENT_INTEGRATION_DATA:
      return { data: action.data?.mostFrequentIntegrations, loading: false };
    default:
      return state;
  }
};
const setIntegrationFieldSubtypeMapping = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_INTEGRATION_FIELD_SUBTYPE_MAPPING:
      return {};
    case REQUEST_INTEGRATION_FIELD_SUBTYPE_MAPPING:
      return { loading: true };
    case SET_INTEGRATION_FIELD_SUBTYPE_MAPPING:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};
const setGreenHouseCustomFields = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_INTEGRATION_DATA:
      return { loading: true };
    case SET_GREENHOUSE_FIELD:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};
const setGreenHouseCustomFieldJobs = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_INTEGRATION_DATA:
      return { loading: true };
    case SET_GREENHOUSE_CUSTOM_FIELD_JOBS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const setAdditionalChargeSettings = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_ADDITIONAL_CHARGE_SETTINGS:
      return {};
    case REQUEST_ADDITIONAL_CHARGE_SETTINGS:
      return { loading: true };
    case SET_ADDITIONAL_CHARGE_SETTINGS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const selectedTiersReducer = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_SELECTED_TIERS:
      return {};
    case SET_SELECTED_TIERS:
      return { ...action.data };
    default:
      return state;
  }
};

const purchaseOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_PURCHASE_ORDER:
      return { loading: true };
    case INVALIDATE_PURCHASE_ORDER:
      return {};
    case SET_PURCHASE_ORDER:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const expirePassword = (state = {}, action) => {
  switch (action.type) {
    case REQ_EXPIRE_PASSWORD:
      return { loading: true };
    case SET_EXPIRE_PASSWORD_RES:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};
const alumniTableData = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_TABLE_DATA:
      return { loading: true };
    case SET_ALUMNI_TABLE_DATA:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};
const referralData = (state = {}, action) => {
  switch (action.type) {
    case SET_REFERRAL_DATA:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRequestTrend = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_REQUEST_TREND:
      return { loading: true };
    case SET_ALUMNI_REQUEST_TREND:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRecordsTrend = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_RECORDS_TREND:
      return { loading: true };
    case SET_ALUMNI_RECORDS_TREND:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRequestOverview = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_REQUEST_OVERVIEW:
      return { loading: true };
    case SET_ALUMNI_REQUEST_OVERVIEW:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniRecordOverview = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_RECORD_OVERVIEW:
      return { loading: true };
    case SET_ALUMNI_RECORD_OVERVIEW:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const HRMSSyncData = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_HRMS_SYNC_DATA:
      return { loading: true };
    case SET_HRMS_SYNC_DATA:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const brandSettings = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_BRAND_SETTINGS:
      return { loading: true };
    case SET_BRAND_SETTINGS:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniSettings = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ALUMNI_SETTINGS:
      return { loading: true };
    case SET_ALUMNI_SETTINGS:
      return { ...action?.data, loading: false };
    default:
      return state;
  }
};

const alumniBulkUpload = (state = {}, action) => {
  switch (action.type) {
    case SET_ALUMNI_UPLOAD_DATA:
      return { ...action?.data };
    case INVALIDATE_ALUMNI_UPLOAD_DATA:
      return {};
  }
};
const hrReferralData = (state, action) => {
  switch (action.type) {
    case INVALIDATE_HR_REFER:
      return {};
    case REQUEST_HR_REFER:
      return { ...state, loading: true };
    case SAVE_HR_REFER:
      return { ...state, loading: false, contacts: action.data, isSkipped: false };
    case SKIP_HR_REFER:
      return { ...state, loading: false, contacts: [], isSkipped: true };
    default:
      return state;
  }
};

const setReports = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_REPORTS:
      return {};
    case REQUEST_REPORTS:
      return { ...state, loading: true };
    case SET_REPORTS:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};
