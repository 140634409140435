// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GN8awscgoMRyUNI7Uhao {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.coPLJd9FTMs4ty6qOxRI {
  display: flex;
  border-radius: 4px;
  align-items: center;
  max-width: 633px;
  border: 1px solid #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.BAhVvZ3owOjkGYKEfBXU {
  margin-bottom: 32px;
  width: 100%;
}

.rkk6WJDrD8iwRYcPuB_p {
  border: none;
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
}

.rx0IJfNQEKiyoIetvWHI {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  align-items: center;
  max-width: 633px;
}

.ev1WXeh0ZIkaqtg5_g7w {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  align-items: center;
  max-width: 633px;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.A1wgDjMmqa7IHqZQO0o5 {
  padding: 2px;
  padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/core-components/PasswordValidatedInputField/PasswordValidateFormInputField.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,cAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gDAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,aAAA;EACA,sCAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sCAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gDAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF","sourcesContent":[".inputError {\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 80%;\n  color: #dc3545;\n}\n\n.error {\n  display: flex;\n  border-radius: 4px;\n  align-items: center;\n  max-width: 633px;\n  border: 1px solid #dc3545;\n  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);\n}\n\n.wrapper {\n  margin-bottom: 32px;\n  width: 100%;\n}\n\n.inputDiv {\n  border: none;\n  outline: none;\n  box-shadow: none;\n  border-color: #ced4da;\n}\n\n.container {\n  display: flex;\n  border: 1px solid rgba(0, 0, 0, 0.125);\n  border-radius: 4px;\n  align-items: center;\n  max-width: 633px;\n}\n\n.containerFocus {\n  display: flex;\n  border: 1px solid rgba(0, 0, 0, 0.125);\n  border-radius: 4px;\n  align-items: center;\n  max-width: 633px;\n  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);\n}\n\n.iconDiv {\n  padding: 2px;\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": `GN8awscgoMRyUNI7Uhao`,
	"error": `coPLJd9FTMs4ty6qOxRI`,
	"wrapper": `BAhVvZ3owOjkGYKEfBXU`,
	"inputDiv": `rkk6WJDrD8iwRYcPuB_p`,
	"container": `rx0IJfNQEKiyoIetvWHI`,
	"containerFocus": `ev1WXeh0ZIkaqtg5_g7w`,
	"iconDiv": `A1wgDjMmqa7IHqZQO0o5`
};
export default ___CSS_LOADER_EXPORT___;
