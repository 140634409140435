import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  getPurchaseOrders as getPurchaseOrderAPI,
  invalidatePurchaseOrders as invalidatePurchaseOrdersAPI
} from '../../../../../actions/company';
const mapStateToProps = (state) => {
  return {
    pendingOrders: state?.purchaseOrders ? state?.purchaseOrders : null
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPendingOrders: (limit, offset) => dispatch(getPurchaseOrderAPI(limit, offset)),
    invalidatePendingOrders: () => dispatch(invalidatePurchaseOrdersAPI())
  };
};
export default connect(mapStateToProps, mapDispatchToProps);
