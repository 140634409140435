import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { Table, Card, OverlayTrigger, Form, Col, Tooltip } from 'react-bootstrap';

import CategoryModals from '../../CategoryModals';
import AdminModals from '../../Administrators/AdminModals';
import Button from '../../../../core-components/Button';
import Icon from '../../../../core-components/Icon';
import Image from '../../../../core-components/Image';
import Pagination from '../../../../core-components/Pagination';

import styles from './ManageTags.module.scss';

const ManageTags = (props) => {
  const pageSizes = [10, 25, 50, 100];
  const defaultPageSize = pageSizes[0];

  const [state, setState] = useState({
    pageSize: defaultPageSize,
    pageNumber: 0,
    query: '',
    count: 0,
    data: [],
    isData: true,
    loading: true,
    modalShow: false,
    modalTitle: null,
    modalSubTitle: null,
    modalShowStatus: false,
    modalButton: null,
    id: null,
    selectedRow: null,
    info: ''
  });

  const modalToggle = (modalTitle, modalSubTitle, modalButton, index, row, info = '') => {
    setState((prevState) => ({
      ...prevState,

      modalShow: true,
      modalTitle,
      modalSubTitle,
      modalButton,
      id: index,
      selectedRow: { category: row },
      info
    }));
  };

  const modalHide = (value) => {
    if (value === 'reload tag') {
      setState((prevState) => ({
        ...prevState,
        pageSize: defaultPageSize,
        pageNumber: 0, //setting to default value
        modalShowStatus: true,
        modalShow: false
      }));
      fetchTags(defaultPageSize, 0); //default pageSize and pageNumber
    } else {
      setState((prevState) => ({
        ...prevState,
        modalShow: false
      }));
    }
  };

  const modalHideStatus = () => {
    setState((prevState) => ({
      ...prevState,
      modalShowStatus: false
    }));
  };
  useEffect(() => {
    props?.invalidateTags();
    let limit = pageSize;
    let offset = pageNumber;
    fetchTags(limit, offset);
    props?.getPaymentDetails();
  }, []);

  const fetchTags = (limit, offset) => {
    props?.getTags(limit, offset);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      data: props?.tags?.categories,
      count: props?.tags?.totalCategories,
      loading: props?.tags?.loading,
      isData: props?.tags?.totalCategories === 0 ? false : true
    }));
  }, [props.tags]);

  const paginate = (limit, offset) => {
    setState((prevState) => ({
      ...prevState,
      loading: true
    }));
    fetchTags(limit, offset);
  };

  const { pageNumber, pageSize, count, data, loading } = state;
  const pageSizeOptions = [];
  let index = 0;

  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  return (
    <>
      <AdminModals
        show={state.modalShowStatus}
        onHide={modalHideStatus}
        content={
          state.modalTitle === 'Edit Tag'
            ? 'Tag has been updated successfully'
            : state.modalTitle === 'Delete Tag'
            ? 'Tag deleted successfully'
            : 'Tag created successfully'
        }
      />
      <Card className={styles.manageCatCard}>
        <nav className={styles.manageCatCardHeader}>
          <div className={styles.headerComp}>Total {count} Tags</div>
          <Button
            className={`${styles.createButton} ${styles.accentColor}`}
            onClick={() => modalToggle('Create Tag', 'Name', 'Create')}
          >
            {'+Add Tag'}
          </Button>
        </nav>
        {state.modalShow && (
          <CategoryModals
            show={state.modalShow}
            onHide={modalHide}
            title={state.modalTitle}
            subTitle={state.modalSubTitle}
            button={state.modalButton}
            categoryId={state.id}
            category={state.selectedRow}
            info={state.info}
            placeholder={'Tag Name'}
          />
        )}

        <Card className={styles.tableCard}>
          <Table className={styles.manageCategoryTable}>
            <thead>
              <tr>
                <th className={styles.textCenter}>#</th>
                <th>Tags</th>
                <th className={styles.textEnd}>Candidates</th>
                <th className={styles.alignCenter}>Actions</th>
              </tr>
            </thead>
            {state.isData ? (
              <tbody>
                {loading
                  ? Array.apply(null, { length: pageSize })
                      .map(Number.call, Number)
                      .map((loadData, i) => {
                        return (
                          <tr key={i}>
                            <td className={styles.textCenter}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignRight}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignCenter}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                          </tr>
                        );
                      })
                  : data &&
                    data.map((rowData, i) => {
                      return (
                        <tr key={i}>
                          <td className={styles.textCenter}>{pageNumber * pageSize + (i + 1)}</td>

                          <td className={styles.mainField}>{rowData.category}</td>
                          <td
                            className={styles.highlighted}
                            onClick={() =>
                              window.open(`/candidates?tag=${rowData.category}`, '_blank')
                            }
                          >
                            {rowData.candidatesCount}
                          </td>
                          <td className={styles.alignRight}>
                            <div className={styles.icons}>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id={'EditTag'}>
                                    <p>{'Edit'}</p>
                                  </Tooltip>
                                }
                              >
                                <div
                                  className={styles.icon}
                                  onClick={() =>
                                    modalToggle(
                                      'Edit Tag',
                                      'Name',
                                      'Save',
                                      rowData.id,
                                      rowData.category
                                    )
                                  }
                                >
                                  <Icon
                                    icon={'EditCategory'}
                                    color={'#333333'}
                                    width={14}
                                    height={14}
                                  />
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id={'DeleteTag'}>
                                    <p>{'Delete'}</p>
                                  </Tooltip>
                                }
                              >
                                <div
                                  className={styles.icon}
                                  onClick={() => {
                                    modalToggle(
                                      'Delete Tag',
                                      'Are you sure you want to delete this Tag?',
                                      'Delete',
                                      rowData.id,
                                      rowData.category,
                                      'Candidates associated with this tag won’t be deleted, but the tag itself will be removed from them.'
                                    );
                                  }}
                                >
                                  <Icon
                                    icon={'DeleteCategory'}
                                    color={'#333333'}
                                    width={12}
                                    height={14}
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <div className={styles.noDataWrapper}>
                      <Image name={'NoData.svg'} />
                      <div className={styles.noDataContent}>{'No tags found'}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {state.isData ? (
            <Form.Row className={styles.paginationWrapper}>
              <Col className={styles.showPagination}>
                Show
                <select
                  className={styles.selectPagination}
                  value={pageSize}
                  onChange={(event) => {
                    const value = parseInt(event.target.value);
                    setState((prevState) => ({
                      ...prevState,
                      pageNumber: 0,
                      pageSize: value
                    }));
                    paginate(value, 0);
                  }}
                >
                  {pageSizeOptions}
                </select>
                items of {count}
              </Col>
              <Col>
                <Pagination
                  forcePage={pageNumber}
                  onPageChange={({ selected }) => {
                    setState((prevState) => ({
                      ...prevState,
                      pageNumber: selected
                    }));
                    paginate(pageSize, selected);
                  }}
                  pageCount={Math.ceil(count / pageSize)}
                />
              </Col>
              <Col xs='3'></Col>
            </Form.Row>
          ) : null}
        </Card>
      </Card>
    </>
  );
};

export default ManageTags;
