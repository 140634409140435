import React from 'react';

import { forEach, isArray, isObject, snakeCase, camelCase } from 'lodash';
import {
  AddressArr,
  IdentityArr,
  PACKAGE_UNIT_INDICATION,
  PACKAGE_UNIT_INDICATION_FACTOR,
  TDS_OPTIONS,
  SUPPORTED_MIME_TYPE_FAMILY,
  SUPPORTED_SIGNATURE_FAMILY,
  SUPPORTED_MIME_TYPE,
  BULK_ALLOWED_FORMATS
} from './CommonConstants';

import validator from 'validator';
import AppConstants from '../../../../../core-components/AppConstants';
export const FOR_WHITE_LISTED_DOMAIN =
  /^@\w+((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{2,61}|[a-z0-9-]{2,30}\.[a-z]{2,})$/;

export const forceLogout = () => {
  localStorage.clear();
  setTimeout(() => {
    window.location.reload();
  }, 2500);
};

export const getErrorMessage = (error) => {
  if (error?.response?.data || error?.data || (error?.errors && error?.errors?.length)) {
    const errorObject =
      (error?.data && error?.data?.errorMessage) ||
      error?.response?.data?.message ||
      error?.response?.data?.errorMsg ||
      (Array.isArray(error?.response?.data?.errors) &&
        error?.response?.data?.errors?.length &&
        error?.response?.data?.errors[0]?.msg) ||
      error?.response?.data?.msg ||
      error?.response?.data ||
      error?.errors[0]?.msg ||
      'Something went wrong.';

    switch (typeof errorObject) {
      case 'object':
        return errorObject.msg || JSON.stringify(errorObject.msg) || errorObject.msg;
      case 'string':
        return errorObject;
      default:
        return 'Something went wrong.';
    }
  }
  return 'Something went wrong.';
};

export const toSnakeCase = (camel_case_object) => {
  var snakeCaseObject = {};
  forEach(camel_case_object, function (value, key) {
    if (isArray(value)) {
      value = value.map((v) => {
        return isObject(v) ? toSnakeCase(v) : v;
      });
    } else if (isObject(value)) {
      value = toSnakeCase(value);
    }
    snakeCaseObject[snakeCase(key)] = value;
  });
  return snakeCaseObject;
};

export const getTrimmedValue = (value, withSpace) => {
  if (value) {
    return withSpace ? value.trim().replace(/\s\s+/g, ' ') : value.trim().replace(/\s+/g, '');
  } else {
    return '';
  }
};

export const convertToIndianNumeration = (value, decimal) => {
  if (value) {
    value = value.toFixed(2);
  }
  return value
    ? parseFloat(value).toLocaleString('en-IN', { minimumFractionDigits: decimal ? decimal : 0 })
    : 0;
};

export const indianNumerationValue = (value, decimal) => {
  if (value) {
    value = value.toFixed(2);
  }

  return (
    <>
      &#8377;
      {value
        ? parseFloat(value).toLocaleString('en-IN', {
            minimumFractionDigits: decimal ? decimal : 0
          })
        : 0}
    </>
  );
};

export const calculateTax = (amount, companyTax) => {
  return parseFloat(parseFloat(amount * (companyTax / 100)).toFixed(2)) || 0;
};
export const toCamelCaseTemp = (snake_case_object) => {
  var camelCaseObject = {};
  forEach(snake_case_object, function (value, key) {
    if (isArray(value)) {
      value = value.map((v) => {
        return isObject(v) ? toCamelCaseTemp(v) : v;
      });
    } else if (isObject(value)) {
      value = toCamelCaseTemp(value);
    }
    camelCaseObject[camelCase(key)] = value;
  });
  return camelCaseObject;
};

export const getPackageUnitIndication = (lowestUnit, packageBalance, candidateCount = 0) => {
  const indicationRange = Math.floor(
    Number.parseFloat(lowestUnit * PACKAGE_UNIT_INDICATION_FACTOR)
  );
  const packageUnitBalanceAfterCandidateAddition = packageBalance - candidateCount;

  if (packageBalance === 0 || packageUnitBalanceAfterCandidateAddition < 0) {
    return PACKAGE_UNIT_INDICATION.NILL;
  } else if (
    indicationRange >= packageBalance ||
    packageUnitBalanceAfterCandidateAddition <= indicationRange
  ) {
    return PACKAGE_UNIT_INDICATION.LOW;
  } else {
    return PACKAGE_UNIT_INDICATION.SUFFICIENT;
  }
};
export const getFilteredChecks = (checks = [], type = '') => {
  let strArr = [];
  const isAnyExist = checks.join(',').match(/ANY_/g) ? true : false;
  const isAllId = checks.includes('ALL');
  if (checks.length && isAnyExist && type) {
    if (type.toLowerCase() === 'identity') {
      strArr = Object.values(IdentityArr).filter((item) => {
        return !checks.includes(item);
      });
    } else if (type.toLowerCase() === 'address' || type.toLowerCase() === 'court') {
      strArr = Object.keys(AddressArr).filter((item) => {
        return !checks.includes(item);
      });
    }

    return strArr;
  } else if (isAllId) {
    strArr = Object.values(IdentityArr).filter((item) => {
      return item;
    });
    return strArr;
  } else {
    return null;
  }
};
export const getTdsOptions = (value, disableOption = false) => {
  const options = [...TDS_OPTIONS];
  if (value === 'BOTH') {
    return disableTdsOptions(options, disableOption);
  } else if (value === '2') {
    return disableTdsOptions(
      options.filter((opt) => opt.value != 10),
      disableOption
    );
  } else if (value === '10') {
    return disableTdsOptions(
      options.filter((opt) => opt.value != 2),
      disableOption
    );
  }
};

// this funtion is used to disable TDS radio options other NONE option, if "Use credit balance" checkbox selected
export const disableTdsOptions = (options, disableOption) => {
  options.forEach((opt) => {
    opt.disable = disableOption && opt.value != 'NONE' ? true : false;
  });
  return options;
};
export const calculateTDS = (amount, companyTds) => {
  return amount > 0 ? parseFloat(parseFloat(amount * (companyTds / 100)).toFixed(2)) : 0;
};
const ALPHANUMERIC_STRICT = /^[a-zA-Z0-9]/;

export const validateEmail = (emailValue) => {
  let emailError = true;
  if (emailValue) {
    const isEmailValid = validator.isEmail(emailValue);
    const isFirstCharAlphaNumeric = emailValue[0].match(ALPHANUMERIC_STRICT) !== null;
    const isLastCharOfUsernameAlphaNumeric = isEmailValid
      ? emailValue[emailValue.search(/@/) - 1].match(ALPHANUMERIC_STRICT) !== null
      : false;
    emailError = isEmailValid && isFirstCharAlphaNumeric && isLastCharOfUsernameAlphaNumeric;
  }
  return emailError;
};
export const getFileHeader = (header, extension) => {
  let fileType = 'unknown';
  switch (header) {
    case '89504e47':
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      if (SUPPORTED_SIGNATURE_FAMILY.image.includes(extension)) {
        fileType = `.${extension}`;
      }
      break;
    case '25504446':
      if (SUPPORTED_SIGNATURE_FAMILY.pdf.includes(extension)) {
        fileType = `.${extension}`;
      }
      break;
    case 'd0cf11e0':
    case '0d444f43':
    case 'f11e0a1b':
    case 'dba52d00':
      if (SUPPORTED_SIGNATURE_FAMILY.binary.includes(extension)) {
        fileType = `.${extension}`;
      }
      break;
    case '504b34':
      if (SUPPORTED_SIGNATURE_FAMILY.zip.includes(extension)) {
        fileType = `.${extension}`;
      }
      break;
    default: // Or you can use the blob.fileType as fallback
      if (SUPPORTED_SIGNATURE_FAMILY.csv.includes(extension)) {
        fileType = `.${extension}`;
      }
  }
  return fileType;
};

export const getFileMimeType = (header, file) => {
  let fileType = 'unknown';
  const fileExtension = file.name.split('.').pop().toLowerCase();
  switch (fileExtension) {
    case 'png':
    case 'jpeg':
    case 'jpg':
      if (SUPPORTED_MIME_TYPE_FAMILY.image.includes(SUPPORTED_MIME_TYPE[fileExtension])) {
        fileType = getFileHeader(header, fileExtension);
      }
      break;
    case 'pdf':
      if (SUPPORTED_MIME_TYPE_FAMILY.pdf.includes(SUPPORTED_MIME_TYPE[fileExtension])) {
        fileType = getFileHeader(header, fileExtension);
      }
      break;
    case 'doc':
    case 'docx':
      if (SUPPORTED_MIME_TYPE_FAMILY.document.includes(SUPPORTED_MIME_TYPE[fileExtension])) {
        fileType = getFileHeader(header, fileExtension);
      }
      break;
    case 'xls':
    case 'xlsx':
      if (SUPPORTED_MIME_TYPE_FAMILY.sheet.includes(SUPPORTED_MIME_TYPE[fileExtension])) {
        fileType = getFileHeader(header, fileExtension);
      }
    case 'csv':
      if (SUPPORTED_MIME_TYPE_FAMILY.csv.includes(SUPPORTED_MIME_TYPE[fileExtension])) {
        fileType = '.csv';
      }
      break;
    default: // Or you can use the blob.fileType as fallback
      fileType = 'unknown';
  }
  return fileType;
};

export const validateImage = (fileArr, fileType) => {
  let errMessage = '';
  let extensionArr = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
  const size = parseInt(fileArr.size / 1024);
  const extension = fileType;
  if (size < AppConstants.fileSize && extension !== 'application/pdf') {
    errMessage = 'FILE_SIZE_EXCEEDS';
  } else if (!extensionArr.includes(extension)) {
    errMessage = 'FILE_TYPE_MISMATCH';
  }
  return errMessage;
};

// Covert to Object to Query string
export const covertObjToQueryString = (obj) => {
  if (obj && typeof obj === 'object') {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  } else {
    return '';
  }
};

export const bulkAccept = BULK_ALLOWED_FORMATS.map(function (x) {
  return '.' + x;
}).join(',');
