import React from 'react';
import styles from '../Integrations.module.scss';
import { Card } from 'react-bootstrap';

function IntegrationLayout(props) {
  return (
    <div>
      <div className={styles.parentDivWrapper}>
        <>
          <div className={styles.header}>
            <div className={styles.heading}>{'Integrations'}</div>
          </div>
          <Card className={styles.bodyCard}>{props.children}</Card>
        </>
      </div>
    </div>
  );
}

export default IntegrationLayout;
