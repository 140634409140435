import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
  getViewBuyPackages as getPackagesAPI,
  getPaymentAndWalletDetails
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    walletDetail: state.walletDetail,
    packages: state.viewBuyPackages,
    createCandConfig:
      state.createCandConfig && !isEmpty(state.createCandConfig) ? state.createCandConfig : {}
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPackages: (data) => dispatch(getPackagesAPI(data)),
    getPaymentDetails: () => dispatch(getPaymentAndWalletDetails())
  };
};
export default connect(mapStateToProps, mapDispatchToProps);
