import React from 'react';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';

export default (props) => {
  return (
    <ReactPaginate
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel={'...'}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
      {...props}
    />
  );
};
