import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getViewBuyPackages as getPackagesAPI,
  setActiveTiers
} from '../../../../../actions/company';
const mapStateToProps = (state) => {
  return {
    packages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null,
    createCandConfig:
      state.createCandConfig && !isEmpty(state.createCandConfig) ? state.createCandConfig : null,
    selectedTiers:
      state.selectedTiers && !isEmpty(state.selectedTiers) ? state.selectedTiers : null,
    paymentDetails: state.paymentDetails && !isEmpty(state.paymentDetails) ? state.paymentDetails : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackages: (data) => dispatch(getPackagesAPI(data)),
    setActiveTiers: (data) => dispatch(setActiveTiers(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
