import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';

import Button from '../../core-components/Button';
import ValidatedFormInputField from '../../core-components/ValidatedFormInputField';
import storage from '../../api/localStorage';

import styles from '../ForgotPassword/ForgotPassword.module.scss';
import { PASSWORD_RULES } from '../../utils/commonConstant';
import { checkPassword } from '../../api/authentication';
import { useState } from 'react';
import Loader from '../../core-components/Loader';
import Image from '../../core-components/Image';
import { errToastMessage, getErrorMessage } from '../../utils/utilities';
import PasswordValidatedFormInputField from '../../core-components/PasswordValidatedInputField/PasswordValidatedFormInputField';
import { isEmpty } from 'lodash';
import PasswordRulesBox from '../../core-components/DynamicPasswordRules/PasswordRulesBox';
import { checkPasswordRules } from '../../utils/ValidationSchema';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { handleSubmit, isSubmitting, status, location, setSubmitting, setFieldValue } = props;
  const [passwordSet, setPasswordSet] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [matchingResults, setMatchingResults] = useState(PASSWORD_RULES);

  const token = new URLSearchParams(document.location.search).get('token') || null;

  const onPasswordChange = (val) => {
    if (!isEmpty(val)) {
      const res = checkPasswordRules(val);
      setMatchingResults(res);
    }
  };

  useEffect(() => {
    const newPassword = props.values.password;

    onPasswordChange(newPassword);

    return () => {
      setMatchingResults(PASSWORD_RULES);
    };
  }, [props.values]);

  useEffect(() => {
    let resetPassword = false;
    if (props.location.pathname === '/set-password') {
      resetPassword = false;
    } else {
      resetPassword = true;
    }
    checkPassword(token, resetPassword)
      .then((res) => {
        if (res.data.message === 'Valid token') {
          setPasswordSet(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setPasswordSet(true);
        setForgotPassword(true);
        errToastMessage(err);
        setLoading(false);
      });
  }, []);

  if (token) {
    storage.clearStorage();
    storage.setToken(token, 'RESET-PASSWORD');
  } else {
    props.history.replace('/signIn');
  }
  return (
    <div className={styles.forgotPasswordWrapper}>
      {!loading ? (
        !passwordSet ? (
          <div className={styles.passwordEmailCard}>
            <div className={styles.emailMessage}>
              <p>Choose a new password</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Field
                data-testid='password-input'
                component={PasswordValidatedFormInputField}
                name='password'
                type='text'
                label='New password'
                handleChange={(e) => {
                  setSubmitting(false);
                }}
              />
              <Field
                data-testid='confirm-password-input' // Add this line
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                component={ValidatedFormInputField}
                noAutoTrimmedRequired={true}
                name='confirmPassword'
                type='password'
                label='Confirm password'
              />
              <label className='form-label'>
                New password must satisfy the following conditions
              </label>
              <PasswordRulesBox
                isUnsetFlexWrap={true}
                showAsListOnly={isEmpty(props.values.password)}
                matchingResults={matchingResults}
              />
              <Button
                type='submit'
                disabled={isSubmitting || isEmpty(props.values.password) || !isEmpty(props.errors)}
                style={{ marginTop: '1rem' }}
              >
                Confirm
              </Button>
            </Form>
          </div>
        ) : forgotPassword ? (
          <div className={styles.passwordSet}>
            <Image className={styles.passwordSetIcon} name='referenceIconHover.svg' />
            <Button onClick={() => props.history.push('/signIn')} className={styles.goBackBtn}>
              Go back to Sign in
            </Button>
            <div className='d-flex w-100 justify-content-center'>
              <Button
                onClick={() => props.history.push('/forgot-password')}
                className={styles.forgotPasswordBtn}
              >
                Forgot password?
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.passwordSet}>
            <Image className={styles.passwordSetIcon} name='welcome_modal_greentick.svg' />
            <p>Password is already set for this account, please login.</p>
            <Button onClick={() => props.history.push('/signIn')} className={styles.goBackBtn}>
              Go back to Sign in
            </Button>
            <div className='d-flex w-100 justify-content-center'>
              <Button
                onClick={() => props.history.push('/forgot-password')}
                className={styles.forgotPasswordBtn}
              >
                Forgot password?
              </Button>
            </div>
          </div>
        )
      ) : (
        <div className={styles.passwordSet}>
          <Loader />
        </div>
      )}
    </div>
  );
};
