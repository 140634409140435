// Import libraries
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

// Import styles
import styles from './ModalCommonStyles.module.scss';

// Import components and others
import LaptopIcon from '../../../../images/laptop-icon.png';
import SuccessIcon from '../../../../images/green-check-round.png';

/**
 * Modal component for buying a laptop.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether the modal is visible or not.
 * @param {Function} props.onHide - Callback function to handle modal close event.
 * @param {Object} props.laptop - The laptop object containing model, display, processor, ram, storage, price, and mrp.
 * @returns {JSX.Element} The rendered SuccessModal component.
 */
const SuccessModal = ({ show, onHide, laptop }) => {
  return (
    <Modal show={show} onHide={onHide} centered size='lg'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className={styles.body}>
        <div className={styles.modalContainer}>
          <div className={styles.modalBody}>
            <div className={styles.checkIcon}>
              <img src={SuccessIcon} alt='success' className={styles.successIcon} />
            </div>
            <div className={styles.title}>Laptop requested successfully</div>
            <div className={styles.infoText}>
              Our vendor relationship manager will soon
              <br /> contact you for further details
            </div>
            <hr />
            <div className={styles.laptopInfo}>
              <div className={styles.laptopIcon}>
                <img src={LaptopIcon} alt='laptop' />
              </div>
              <div className={styles.laptopDetails}>
                <h4>{laptop.model}</h4>
                <Table borderless className={styles.specsTable}>
                  <tbody>
                    <tr>
                      <td>Display</td>
                      <td>Processor</td>
                      <td>RAM</td>
                      <td>Hard Drive</td>
                    </tr>
                    <tr>
                      <td className={styles.specificationText}>{laptop.display}</td>
                      <td className={styles.specificationText}>{laptop.processor}</td>
                      <td className={styles.specificationText}>{laptop.ram}</td>
                      <td className={styles.specificationText}>{laptop.storage}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button className={styles.closeBtn} variant='primary' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
