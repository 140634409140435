import React from 'react';
import { Card } from 'react-bootstrap';
import styles from '../IntegrationMain.module.scss';
import cn from 'classnames';

const SkeletonCard = ({ isGridView }) => {
  return (
    <Card className={isGridView ? styles.gridHeight : styles.listHeight}>
      <Card.Body className={isGridView ? styles.gridViewInner : styles.listViewInner}>
        <div className={styles.cardBody}>
          <div className={styles.cardHeaderContainer}>
            <div className={styles.cardImage}>
              <div className={styles.skeletonCircle} />
            </div>
            <div className={styles.titleContainer}>
              <div className={styles.titleBox}>
                <div className={styles.skeletonLine} style={{ width: '150px', height: '24px' }} />
              </div>
              <div className={styles.skeletonLine} style={{ width: '100px', height: '18px' }} />
            </div>
          </div>
          <div className={cn(styles.cardDetails, styles.details)}>
            <div className={styles.skeletonLine} />
            <div className={styles.skeletonLine} />
            <div className={styles.skeletonLine} />
          </div>
        </div>
        <div className={styles.cardAction}>
          <div className={styles.skeletonButton} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default SkeletonCard;
