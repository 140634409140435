// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WIFCzB823Jhz6a000cG7 {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 38px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
}
.WIFCzB823Jhz6a000cG7::placeholder {
  color: #c2cfff;
}
@media (min-width: 768px) {
  .WIFCzB823Jhz6a000cG7 {
    font-size: var(--label-font-size);
  }
}`, "",{"version":3,"sources":["webpack://./src/core-components/Input/Input.module.scss","webpack://./src/utils/breakpoints.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,kBAAA;EACA,8BAAA;EACA,gCAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AAAF;AACE;EACE,cAAA;AACJ;ACFE;EDVF;IAcI,iCAAA;EAEF;AACF","sourcesContent":["@import 'src/utils/breakpoints.scss';\n.input {\n  border: 1px solid #ebedf2;\n  border-radius: 4px;\n  background-color: var(--white);\n  font-size: var(--para-font-size);\n  font-weight: normal;\n  line-height: 38px;\n  height: 38px;\n  width: 100%;\n  color: #4c4c4c;\n  &::placeholder {\n    color: #c2cfff;\n  }\n  @include md {\n    font-size: var(--label-font-size);\n  }\n}\n","@import './constants.scss';\n\n// Small devices\n@mixin sm {\n  @media (min-width: #{$screen-sm-min}) {\n    @content;\n  }\n}\n\n// Medium devices\n@mixin md {\n  @media (min-width: #{$screen-md-min}) {\n    @content;\n  }\n}\n\n// Large devices\n@mixin lg {\n  @media (min-width: #{$screen-lg-min}) {\n    @content;\n  }\n}\n\n// Extra large devices\n@mixin xl {\n  @media (min-width: #{$screen-xl-min}) {\n    @content;\n  }\n}\n\n// Custom devices\n@mixin rwd($screen) {\n  @media (min-width: $screen+'px') {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `WIFCzB823Jhz6a000cG7`
};
export default ___CSS_LOADER_EXPORT___;
