import React, { useState, useEffect } from 'react';
import { has, isEmpty } from 'lodash';

import { Card, Badge, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

import Image from '../../../../../core-components/Image';
import IDModal from './AddIdModal';
import ProgressBar from '../../../../../core-components/ProgressBar';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BGVConfirmDialog from '../../../CommonComponent/BGVConfirmDialog';
import logger from '../../../../../core-components/Logger';
import { CONFIG_ID_TYPES, ID_DROPDOWN } from '../../../../../utils/commonConstant';
import { identityDropdownToObject, identityDropdownToArray } from './Identity.mapper';

import styles from './Identity.module.scss';
import { getTotalCheckCount } from '../../../../../utils/utilities';

export default (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(0); //TO DO : update for index of delete operation
  const [idDelete, setIdDelete] = useState(false);
  const [idPresent, setIdPresent] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anyCount, setAnyCount] = useState(0);
  const [config, setConfig] = useState(null); // TO DO : delete after api integration ( This is how config comes for identity )
  const [dropdownValues, setDropdownValues] = useState([]);
  const [currentCheckIndex, setCurrectCheckIndex] = useState(0);
  const [currentId, setCurrentId] = useState(null);
  const [specificIdCount, setSpecificIdCount] = useState(0);
  const [skippable, setSkippable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dropDownCalled, setDropdownCalled] = useState(0);

  const [identitySkipEnable, setIdentitySkipEnable] = useState(
    props.cached_data && has(props.cached_data, 'identity_skip_enable')
      ? props.cached_data.identity_skip_enable
      : false
  );

  const checkConfig = JSON.parse(JSON.stringify(props.config));
  const [idConfigs, setIdConfigs] = useState(
    checkConfig.config && checkConfig.config.identity ? checkConfig.config.identity : []
  );

  // Function to check whether the config has credit check and if the form does not have a PAN check then adding it to the configs
  const creditCheck = () => {
    if (
      checkConfig?.config?.credit &&
      !idConfigs?.includes('PAN') &&
      !idConfigs?.some((config) => config.includes('ANY'))
    ) {
      setIdConfigs((prevIdCheck) => [...prevIdCheck, 'PAN']);
    } else if (checkConfig?.config?.credit && !idConfigs?.includes('PAN')) {
      let count = 0;
      for (let i = 0; i < props?.config?.config?.identity.length; i++) {
        if (props?.config?.config?.identity[i].includes('ANY_')) {
          count += Number(props?.config?.config?.identity[i].split('_')[1]);
        } else {
          count += 1;
        }
      }

      const cachedData = props?.cached_data?.cache_data?.id_check?.filter(
        (check) => check?.id_type
      );
      const idTypes = cachedData?.filter((check) => check?.id_type?.name === 'PAN');
      const identityDropdownValue = props?.cached_data?.identity_dropdown?.filter(
        (check) => check?.label === 'PAN'
      );

      if (
        cachedData?.length > 0 &&
        cachedData?.length === count &&
        !idConfigs?.includes('PAN') &&
        idTypes?.length === 0
      ) {
        setIdConfigs((prevIdCheck) => [...prevIdCheck, 'PAN']);
      } else if (
        props?.config?.config?.identity?.length > 1 &&
        cachedData?.length >= count &&
        !idConfigs?.includes('PAN') &&
        idTypes?.length === 1 &&
        identityDropdownValue?.length
      ) {
        setIdConfigs((prevIdCheck) => [...prevIdCheck, 'PAN']);
      } else if (
        !idConfigs?.includes('PAN') &&
        idTypes?.length === 1 &&
        cachedData?.length > count &&
        props?.config?.config?.identity?.length === 1
      ) {
        setIdConfigs((prevIdCheck) => [...prevIdCheck, 'PAN']);
      }
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    // sprint-10 changes

    // logger code
    const loggerObj = {
      message: 'Identity Main Page',
      data: JSON.stringify(props.cached_data),
      category: `candidate-id-${props.candidateId}`,
      subCategory: 'Identity',
      type: 'info'
    };
    logger.push(loggerObj);
  }, []);

  useEffect(() => {
    if (dropdownValues?.length > 0) {
      if (props.cached_data && !has(props.cached_data, 'identity_dropdown') && dropDownCalled < 1) {
        setDropdownCalled(1);
        callCacheForDropdown(identityDropdownToObject(dropdownValues));
      }
      checkForIdPresernt();
    }
  }, [dropdownValues]);

  const callCacheForDropdown = (values) => {
    let saveObject;

    saveObject = {
      ...props?.cached_data,
      cache_data: { ...props?.cached_data?.cache_data },
      current_state: '1',
      identity_dropdown: values
    };

    //formatting cache data to save
    const finalCacheData = {
      saveObject
    };
    props
      .setBgvCache(finalCacheData)
      .then((response) => {
        // logger code
        const loggerObj = {
          message: ` Added Dropdown Values`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Identity Main page',
          type: 'info'
        };
        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);
        // logger code
        const loggerObj = {
          message: ` Couldnt Add Dropdown values`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Identity Main Page',
          type: 'error'
        };
        logger.push(loggerObj);
      });
  };

  const ifAllIdPresent = () => {
    for (let i = 0; i < config.length; i++) {
      if (isEmpty(props?.cached_data?.cache_data?.id_check[i])) {
        return false;
      }
    }
    return true;
  };

  const skipId = () => {
    for (let i = 0; i < specificIdCount; i++) {
      // all specific Id should be present to skip
      if (isEmpty(props?.cached_data?.cache_data?.id_check[i])) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    creditCheck();
    checkForIdPresernt();
  }, [props.cached_data, specificIdCount]);

  useEffect(() => {
    nonAnyArrayandDropdownValues();
    checkForIdPresernt();
  }, [idConfigs, props.cached_data]);

  const checkIfIdentityEmpty = () => {
    const identityCheckArray = props?.cached_data?.cache_data?.id_check?.map((d) => {
      return d && !isEmpty(d);
    });
    return identityCheckArray?.some((check) => check === null || !check);
  };
  const checkForIdPresernt = () => {
    if (
      props?.cached_data &&
      has(props.cached_data, 'cache_data') &&
      props?.cached_data?.cache_data &&
      has(props.cached_data.cache_data, 'id_check') &&
      !isEmpty(props.cached_data.cache_data.id_check) &&
      props.cached_data.cache_data.id_check.length === getTotalCheckCount(idConfigs) &&
      !loading
    ) {
      if (!checkIfIdentityEmpty()) {
        setIdPresent(true);
      } else if (
        props?.cached_data?.cache_data?.id_check?.length >= specificIdCount &&
        specificIdCount
      ) {
        setIdPresent(false);
        setSkippable(skipId());
      } else {
        setIdPresent(false);
      }
    } else if (
      getTotalCheckCount(idConfigs) ===
      props?.cached_data?.cache_data?.id_check?.filter((check) => check?.id_type)?.length
    ) {
      setIdPresent(true);
    } else {
      setIdPresent(false);
    }
  };

  //sprint-10 changes
  const nonAnyArrayandDropdownValues = () => {
    // get number of ANY
    let tempConfig = idConfigs.includes('ALL')
      ? ['PAN', 'AADHAAR', 'DL', 'VOTER_ID', 'PASSPORT']
      : idConfigs;
    let count = 0;
    let specificCountTemp = 0;

    let checks = ['PAN', 'AADHAAR', 'DL', 'VOTER_ID', 'PASSPORT'];
    for (let i = 0; i < idConfigs.length; i++) {
      if (idConfigs[i].includes('ANY_')) {
        count = idConfigs[i].split('_');

        tempConfig.splice(i, 1);
        i--;
      } else {
        for (let j = 0; j < checks.length; j++) {
          if (idConfigs[i] === checks[j]) {
            checks.splice(j, 1);
            j--;
            specificCountTemp++;
          }
        }
      }
    }
    for (let i = 0; i < count[1]; i++) {
      tempConfig.unshift('ANY');
    }
    setLoading(false);
    setAnyCount(count[1]);
    setSpecificIdCount(specificCountTemp);
    setConfig(tempConfig);
    setDropdownValues(checks);
  };

  const handleIdDelete = () => {
    let cacheData = { ...props.cached_data.cache_data };
    let removeType = cacheData['id_check'][removeIndex].id_type.name;

    cacheData['id_check'][removeIndex] = {};
    let saveObject;

    // if specific id is deleted then  skip shouldnt be allowed
    if (removeIndex < specificIdCount) {
      setIdentitySkipEnable(false);
    }
    saveObject = {
      ...props.cached_data,
      cache_data: { ...cacheData },
      current_state: '1',
      identity_skip_enable: removeIndex < specificIdCount ? false : identitySkipEnable
    };

    if (currentId === 'ANY') {
      let temp =
        props?.cached_data && props?.cached_data?.identity_dropdown
          ? identityDropdownToArray(props.cached_data.identity_dropdown)
          : null;
      // Since we are removing ANY value and pushing PAN to the idConfigs, we need to verify whether PAN is already present in the dropdown or not
      const filteredValue = temp?.filter((tempValue) => tempValue === removeType);
      if (filteredValue?.length === 0) {
        temp.push(removeType);
      }
      saveObject['identity_dropdown'] = identityDropdownToObject(temp);
    }

    // If PAN is not present in the cache data, but if it present in the idConfigs then removing it from the configs as well
    const idTypes = cacheData?.id_check?.filter((check) => check?.id_type?.name === 'PAN');
    if (
      idTypes?.length === 0 &&
      idConfigs?.includes('PAN') &&
      !props?.config?.config?.identity?.includes('PAN') &&
      checkConfig?.config?.credit
    ) {
      setIdConfigs(checkConfig?.config?.identity);
    }

    // If PAN is present in the cache data, but user removed one of the other checks, then moving PAN to the idConfigs and makeing the NEXT button enabled
    if (
      idTypes?.length === 1 &&
      idConfigs?.includes('PAN') &&
      !props?.config?.config?.identity?.includes('PAN') &&
      checkConfig?.config?.credit &&
      props?.config?.config?.identity?.length === 1
    ) {
      let count = 0;
      for (let i = 0; i < props?.config?.config?.identity.length; i++) {
        if (props?.config?.config?.identity[i].includes('ANY_')) {
          count = Number(props?.config?.config?.identity[i].split('_')[1]);
        }
      }

      const cachedData = props?.cached_data?.cache_data?.id_check?.filter(
        (check) => check?.id_type
      );

      if (count === cachedData?.length) {
        idConfigs.shift();
        setIdConfigs(idConfigs);
        saveObject.cache_data.id_check = saveObject?.cache_data?.id_check?.filter(
          (value) => !isEmpty(value)
        );
      }
    }

    //formatting cache data to save
    const finalCacheData = {
      saveObject
    };
    props
      .setBgvCache(finalCacheData)
      .then((response) => {
        // logger code
        const loggerObj = {
          message: ` Identity is deleted`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Identity Main page',
          type: 'info'
        };
        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);
        // logger code
        const loggerObj = {
          message: ` Identity is not deleted`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Identity Main Page',
          type: 'error'
        };
        logger.push(loggerObj);
      });
  };

  const handleConfirmSubmit = (childProps) => {
    childProps.handleSubmit();
  };

  // const getIdType = (val) => {
  //   setIdType(val);
  // };

  const handleConfirmModal = () => {
    setShowConfirmation(!showConfirmation);
  };

  const getIdCardLabelText = (check) => {
    let checkText = check === CONFIG_ID_TYPES.ANY ? 'Any ID' : `${ID_DROPDOWN[check].label}`;

    if (check === CONFIG_ID_TYPES.AADHAAR || check === CONFIG_ID_TYPES.PAN) {
      checkText += ` Card`;
    }

    return checkText;
  };

  const getInfoCardLabel = (data) => {
    switch (data?.id_type?.label) {
      case CONFIG_ID_TYPES.PAN:
        return 'PAN Card';
      case 'Aadhaar':
        return 'Aadhaar Card';
      default:
        return data?.id_type?.label;
    }
  };

  const addIDCard = (check, index) => {
    return (
      <Container className={styles.addIdentityCard} key={index}>
        <Row>
          <Col className={styles.idCheckName}>
            {getIdCardLabelText(check)}
            <span className={styles.required}>*</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className={styles.addBtn}
              disabled={
                checkConfig.candidate_bgv_form_config.include_aadhaar_in_id_check === 0 &&
                config.includes('AADHAAR')
                  ? true
                  : false
              }
              onClick={() => {
                setModalShow(true);
                setTitle('Add');
                setCurrentId(check);
                setCurrectCheckIndex(index);
              }}
            >
              <Image className={styles.addSign} name='AddId.svg' />
              Add ID proof
            </Button>
          </Col>
        </Row>
        {checkConfig.candidate_bgv_form_config.include_aadhaar_in_id_check === 0 &&
        check === 'AADHAAR' ? (
          <Col className={styles.aadhaarConfigError}>{'Aadhaar config error'}</Col>
        ) : null}
      </Container>
    );
  };

  const idInfoCard = (data, check, index) => {
    return (
      <Container className={styles.idInfoCard} key={index}>
        <Row>
          <Col md={10} sm={9} xs={9} lg={10}>
            <div className={styles.checkName}>{getInfoCardLabel(data)}</div>
            <div className={styles.checkInfo}>ID Number- {data?.identity_number}</div>
          </Col>
          <Col md={1} sm={1} xs={1} lg={1}>
            <Image
              style={{ marginRight: '21px', cursor: 'pointer' }}
              name='BgvIdEdit.svg'
              onClick={() => {
                setModalShow(true);
                setTitle('Edit');
                setCurrentId(data?.id_type?.name);
                setCurrectCheckIndex(index);
              }}
            />
          </Col>
          <Col md={1} sm={1} xs={1} lg={1}>
            <Image
              name='BgvIdDelete.svg'
              style={{ display: showRemove && index === removeIndex && 'none', cursor: 'pointer' }}
              onClick={() => {
                setShowRemove(!showRemove);
                setCurrentId(check);
                setRemoveIndex(index);
              }}
            />
            {showRemove && index === removeIndex && (
              <Badge
                className={styles.badge}
                onClick={() => {
                  handleIdDelete();
                  setShowRemove(false);
                  setIdDelete(!idDelete);
                }}
                pill
                variant='danger'
              >
                Delete ?
              </Badge>
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  const indexing =
    props.totalSteps
      .map((val) => {
        return val?.id;
      })
      .indexOf(1) + 1;

  // Function to handle the ID Dropdown array values
  const identityDropdownValues = (values) => {
    const cachedData = props?.cached_data?.cache_data?.id_check?.filter((check) => check?.id_type);
    const idTypes = cachedData?.filter((check) => check?.id_type?.name === 'PAN');

    if (idConfigs?.includes('PAN') && checkConfig?.config?.credit && idTypes?.length === 1) {
      const filteredDropdownValues = values?.filter((data) => data?.label !== 'PAN');
      return identityDropdownToArray(filteredDropdownValues);
    }

    return identityDropdownToArray(values);
  };

  return (
    <Card className={styles.identityCard}>
      <Card.Body className={styles.identityBody}>
        <Row>
          <Col sm={12} xs={12} md={12} lg={12}>
            {!isMobileOnly ? (
              <>
                <span className={styles.identityHeading}>Identity</span>
                <div className={styles.identityProgressContainer}>
                  <span className={styles.stepText}>
                    {indexing} of {props?.totalSteps?.length}
                  </span>
                  <ProgressBar max={props?.totalSteps?.length} min={0} now={indexing} />
                </div>
              </>
            ) : (
              <>
                <BgvSecureText />
                <div className={styles.identityProgressContainer}>
                  <span className={styles.identityHeading}>Identity</span>
                  <span className={styles.stepText}>
                    {indexing} of {props?.totalSteps?.length}
                  </span>
                </div>
                <ProgressBar max={props?.totalSteps?.length} min={0} now={indexing} />
              </>
            )}
          </Col>
        </Row>
        <p className={styles.heading}>Add your ID proof here</p>
        {/* SPRINT-10 CHANGES */}
        {!loading &&
          config.map((check, index) => {
            if (
              props?.cached_data &&
              props?.cached_data?.cache_data &&
              has(props.cached_data.cache_data, 'id_check') &&
              props?.cached_data?.cache_data?.id_check?.length > index &&
              typeof props.cached_data.cache_data.id_check[index] !== undefined &&
              !isEmpty(props.cached_data.cache_data.id_check[index])
            ) {
              return idInfoCard(props.cached_data.cache_data.id_check[index], check, index);
            } else {
              return addIDCard(check, index);
            }
          })}
        {/* Confirm Dialog */}
        {showConfirmation && (
          <BGVConfirmDialog
            showConfirmation={showConfirmation}
            handleConfirmSubmit={handleConfirmSubmit}
            handleHide={handleConfirmModal}
            enableBtn={idPresent || identitySkipEnable}
            {...props}
          />
        )}
      </Card.Body>
      {modalShow ? (
        <IDModal
          show={modalShow}
          title={title}
          candidateId={props.candidateId}
          // idType={(val) => getIdType(val)}
          onHide={() => setModalShow(false)}
          idCheckIndex={currentCheckIndex}
          dropdownValue={
            currentId === 'ANY'
              ? props?.cached_data &&
                props?.cached_data?.identity_dropdown &&
                identityDropdownValues(props.cached_data.identity_dropdown)
              : currentId
          }
        />
      ) : null}

      <Card.Footer className={styles.identityFooter}>
        {/* <Row>
          {anyCount ? (
            <Col sm={12} xs={12} md={12} lg={12} className={styles.skipBtn}>
              <Form.Check
                checked={identitySkipEnable}
                disabled={!skippable || idPresent}
                type={'checkbox'}
                id='identitySkipEnable'
                name='identitySkipEnable'
                label={`I don't have any further details than what’s provided.`}
                onChange={handleSkip}
              />
            </Col>
          ) : null}
        </Row> */}
        <Row>
          <Col sm={12} xs={12} md={12} lg={12} className={styles.identitySubmitBtn}>
            {/* Added common prev button */}
            <BgvGoBackBtn handleClick={props.prevStep} />
            <Button
              disabled={
                !idPresent && !props.isLoadingBgvCache && !props.internalLoading
                  ? !identitySkipEnable
                    ? true
                    : false
                  : false
              }
              className={
                idPresent
                  ? styles.activeBtn
                  : identitySkipEnable
                  ? styles.activeBtn
                  : styles.disabledBtn
              }
              onClick={() => {
                if (props.finalSubmitCheck === 1) {
                  setShowConfirmation(true);
                } else {
                  props.handleSubmit();
                }
              }}
            >
              {props.finalSubmitCheck === 1 ? 'Submit' : 'Next'}
            </Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
