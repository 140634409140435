import * as Yup from 'yup';
import {
  getEmailValidationSchema,
  getNameValidationSchema,
  getCtcValidationSchema,
  getExitReasonSchema,
  getDesignationSchema
} from '../../utils/validationSchema';
import { isValidPhoneNumber } from '../../utils/Utilities';

const tenYearsAgo = new Date();
tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 10);

export default Yup.object().shape({
  isStaffIdNotAvailable: Yup.boolean(),
  fullName: getNameValidationSchema({ message: 'Full Name', isRequired: true }),
  staffId: Yup.string().when('isStaffIdNotAvailable', {
    is: false,
    then: Yup.string()
      .required('Staff Id is required')
      .matches(/[a-zA-Z0-9]+/, 'Please provide valid Staff ID')
  }),
  dateOfBirth: Yup.date()
    .max(tenYearsAgo, 'Date10')
    .when('isStaffIdNotAvailable', {
      is: true,
      then: Yup.date().required('Date of Birth is required')
    }),
  personalEmail: getEmailValidationSchema({}),
  businessEmail: getEmailValidationSchema({}),
  phoneNumber: Yup.string().test('phone-validation', 'Invalid phone number', function (value) {
    const { countryCode } = this.parent;
    const phoneNumber = `${countryCode}${value}`;
    if (countryCode && value && !isValidPhoneNumber(phoneNumber)) {
      return false;
    }
    return true;
  }),
  designation: getDesignationSchema({ isRequired: true, isNullable: true }),
  dateOfJoining: Yup.string().required('Date of Joining is required'),
  lastWorkingDay: Yup.string().required('Last working day is required'),
  exitReason: getExitReasonSchema({ isRequired: false, isNullable: true }),
  rmName: getNameValidationSchema({ message: 'Reporting Manager Name' }),
  rmEmail: getEmailValidationSchema({ message: 'Reporting Manager Email' }),
  rmPhone: Yup.string().test('rmPhone-validation', 'Invalid phone number', function (value) {
    const { rmCountryCode } = this.parent;
    const rmPhoneNumber = `${rmCountryCode}${value}`;
    if (rmCountryCode && value && !isValidPhoneNumber(rmPhoneNumber)) {
      return false;
    }
    return true;
  }),
  ctc: getCtcValidationSchema({ isRequired: false, isNullable: true })
});
