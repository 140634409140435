import * as Yup from 'yup';
import { userCategoryValidationSchema } from '../../../utils/ValidationSchema';

const TITLE = ['Edit Category', 'Edit Tag', 'Create Category', 'Create Tag'];
export default Yup.object().shape({
  category: Yup.string().when(['modalTile'], {
    is: (modalTile) => TITLE.includes(modalTile),
    then: userCategoryValidationSchema({ message: 'category', isRequired: true })
  })
});
