import React from 'react';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { postExternalApiTokenName } from '../../../../../../api/company';
import Button from '../../../../../../core-components/Button';
import Input from '../../../../../../core-components/Input';
import styles from '../../ManageApiToken.module.scss';
import { isValidTokenName, isValidExpiryLimit } from '../../../../../../utils/ValidationSchema';
import { errToastMessage, getTrimmedValue } from '../../../../../../utils/utilities';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { show, dispatch, dataDispatch, getJwtTokenList, getErrorMessageForInvalidTokenName } =
    props;
  const [tokenName, setTokenName] = useState('');
  const [neverExpire, setNeverExpire] = useState(false);
  const [expireLimit, setExpiryLimit] = useState('');
  const [isValidName, setIsValidName] = useState(true);
  const [isValidLimit, setIsValidLimit] = useState(true);
  const [isInitialState, setIsInitialState] = useState(true);

  const onChangeTokenNameHandler = (e) => {
    const value = e.target.value;
    const isValidName = isValidTokenName(value);
    setTokenName(value);
    setIsInitialState(false);
    setIsValidName(isValidName);
  };

  const onChangeExpiryLimitHandler = (e) => {
    const value = e.target?.value?.replace(/\D/g, '');
    const isValidLimit = isValidExpiryLimit(value);
    setExpiryLimit(value);
    setIsValidLimit(isValidLimit);
    setIsInitialState(false);
  };

  const onCloseHandler = () => {
    dispatch({ type: 'TOGGLE_GEN_TOKEN_MODAL' });
  };

  const handleExpireCheckbox = (e) => {
    setNeverExpire(e.target.checked);
    if (e.target.checked) {
      setExpiryLimit('');
    }
    setIsInitialState(false);
  };

  // function to enable disable create button as per requirements
  const isCreateButtonDisabled = () => {
    if (isInitialState) {
      return true;
    }
    if (isValidName && tokenName) {
      if (neverExpire) {
        return false;
      } else if (expireLimit) {
        return !isValidLimit;
      } else {
        return true;
      }
    }
    return true;
  };
  const onSaveHandler = () => {
    dataDispatch({
      type: 'SET_PAGINATION',
      offset: 0,
      limit: 10
    });
    // API call
    onCloseHandler();
    let payload = { name: tokenName?.trim(), expires_at: neverExpire ? null : expireLimit };
    postExternalApiTokenName(payload)
      .then((resp) => {
        getJwtTokenList();
        toast.info('Token generated successfully');
      })
      .catch((error) => {
        onCloseHandler();
        errToastMessage('Token with same name already exists');
      });
  };

  return (
    <Modal
      show={show}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      onHide={onCloseHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ marginLeft: '-6px' }}>Generate token </Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body style={{ marginBottom: '25px', padding: '30px' }}>
          <Form.Row>
            <Form.Label>Name</Form.Label>
            <Input
              data-testid='tokenNameInput'
              style={!isValidName ? { borderColor: 'red' } : null}
              placeholder={'Token Name'}
              value={tokenName}
              onChange={onChangeTokenNameHandler}
              onBlur={() => {
                setIsValidName(isValidTokenName(getTrimmedValue(tokenName, true)));
                setTokenName(getTrimmedValue(tokenName, true));
              }}
              type='text'
            />
            {!isValidName ? (
              <div className={styles.errorMsg}>{getErrorMessageForInvalidTokenName(tokenName)}</div>
            ) : null}
          </Form.Row>
          <Form.Row>
            <Form.Check
              checked={neverExpire}
              type={'checkbox'}
              data-testid='neverExpireCheckbox'
              name='neverExpireCheckbox'
              label={`Never expire`}
              onChange={handleExpireCheckbox}
              style={{ marginTop: '20px' }}
            />
          </Form.Row>
          <Form.Label className={styles.expiryTimeLimit}>Expiry time limit</Form.Label>
          <Form.Row style={{ justifyContent: 'flex-start' }}>
            <div style={{ marginRight: '10px' }}>
              <Input
                style={!isValidLimit && !neverExpire ? { borderColor: 'red' } : null}
                placeholder={'Time limit in seconds'}
                onChange={onChangeExpiryLimitHandler}
                type='integer'
                disabled={neverExpire}
                value={expireLimit}
              />
            </div>
            <div className={styles.parentDivMinMax}>
              <span className={styles.minMaxText}>Min: 600 </span>
              <span className={styles.minMaxText}>Max: 86400</span>
            </div>
            {!isValidLimit && !neverExpire ? (
              <div className={styles.errorMsg}>Expiry Limit must be between 600 and 86400</div>
            ) : null}
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCloseHandler} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={onSaveHandler}
            className={styles.submitButton}
            disabled={isCreateButtonDisabled()}
          >
            Create
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
