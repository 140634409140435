import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { has, isArray } from 'lodash';
import { Form, Col, Row, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../../../../core-components/Pagination';
import styles from './InsuffCredits.module.scss';
import { convertToIndianNumeration } from '../../../../../../utils/utilities';

const getInsuffVerification = (insuffType = null) => {
  const obj = {
    VERIFICATION: 0,
    ADDITIONAL_CHARGE: 0,
    ADD_ON: 0
  };

  if (insuffType && insuffType.type?.length) {
    for (let i = 0; i < insuffType.type.length; i++) {
      obj[insuffType.type[i]] = obj[insuffType.type[i]] + 1;
    }
  }

  return obj;
};

function InsuffCredits(props) {
  const pageSizes = [10, 25, 50, 100];

  const [limit, setLimit] = useState(pageSizes[0]);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [obj, setObj] = useState({
    VERIFICATION: 0,
    ADDITIONAL_CHARGE: 0,
    ADD_ON: 0
  });

  // Handle for API call
  const fetchInsuffCredits = (where = { limit: 10, offset: 0 }) => {
    setLoading(true);
    props?.getInsuffCredits(where).catch((error) => {
      console.error(error);
    });
  };

  // Initally fetching transaction history
  useEffect(() => {
    props?.invalidateInsuffCredits();
    fetchInsuffCredits();
  }, []);

  //Execute every time on the updation of insuffCredits
  useEffect(() => {
    if (
      has(props.insuffCredits, 'all') &&
      has(props.insuffCredits, 'loading') &&
      props.insuffCredits.loading === false
    ) {
      setData(props.insuffCredits.all[offset].data);
      setCount(props.insuffCredits.all[offset].count);
      setAvailableBalance(props.insuffCredits.all[offset].creditBalance);
      setObj(getInsuffVerification(props.insuffCredits.all[offset].insuffType));
      props.setAvailableBalanceFromInsuff(props.insuffCredits.all[offset].balance);
      props.setCompanyBalance(props.insuffCredits.all[offset].creditBalance);
      props.setInsuffOffset(offset);
      props.setWarningObj(getInsuffVerification(props.insuffCredits.all[offset].insuffType));
      setLoading(false);
    }
  }, [props.insuffCredits]);

  const paginate = (limit, offset) => {
    const where = { limit, offset };
    fetchInsuffCredits(where);
  };

  const pageSizeOptions = [];
  let index = 0;

  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const renderPrice = (transaction) => {
    return (
      <>
        <p className={styles.date}>{moment(transaction?.createdAt).format('DD MMM YYYY')}</p>
        <p className={`${styles.amount} ${styles.amountDebit}`}>
          {'- '}
          <span className={styles.rupee}>&#8377;</span>
          {convertToIndianNumeration(transaction?.amount * -1)}
        </p>
      </>
    );
  };

  const renderInfo = (transaction, type) => {
    switch (type) {
      case 'VERIFICATION':
        return (
          <p className={styles.packageAddedText}>
            {`Verification for`}{' '}
            <Link to={`/viewCandidate?candidateId=${transaction?.candidateId}`}>
              {transaction?.candidateName}
            </Link>
          </p>
        );
      case 'ADD_ON':
        return (
          <p className={styles.addonText}>
            Add-on {transaction?.checkType} check for{' '}
            <Link to={`/viewCandidate?candidateId=${transaction?.candidateId}`}>
              {transaction?.candidateName}
            </Link>
          </p>
        );
      case 'ADDITIONAL_CHARGE':
        return (
          <p className={styles.addonText}>
            Additional charges for {transaction?.checkType} check for{' '}
            <Link to={`/viewCandidate?candidateId=${transaction?.candidateId}`}>
              {transaction?.candidateName}
            </Link>
          </p>
        );
      default:
        return null;
    }
  };

  const Warning = () => {
    let updateAvailableBalance;
    if (props?.insuffCredits?.all[offset]?.balance <= 0 && availableBalance <= 0) {
      updateAvailableBalance = Math.abs(props?.insuffCredits?.all[offset]?.balance);
    } else if (props?.insuffCredits?.all[offset]?.balance <= 0 && availableBalance >= 0) {
      updateAvailableBalance = Math.abs(
        props?.insuffCredits?.all[offset]?.balance + availableBalance
      );
    }
    updateAvailableBalance = convertToIndianNumeration(updateAvailableBalance);
    return (
      <div className={styles.alertContainer}>
        <Alert variant={'warning'} className={styles.alert}>
          <span>
            <FontAwesomeIcon icon={faInfoCircle} color={'#FAAD14'} style={{ marginRight: '8px' }} />
          </span>
          <div className={styles.actionsContainer}>
            <p>
              {props?.insuffCredits?.all[offset] && props.insuffCredits.all[offset].insuffType && (
                <>
                  {obj['VERIFICATION'] > 0 &&
                    (obj['ADDITIONAL_CHARGE'] > 0 || obj['ADD_ON'] > 0) ? (
                    <>
                      {'Please add '} <span className={styles.rupee}>&#8377;</span>
                      {`${updateAvailableBalance} to start ${props.insuffCredits.all[offset].insuffType.count} pending verifications & clear dues`}
                    </>
                  ) : (
                    <>
                      {obj['VERIFICATION'] > 0 && (
                        <>
                          {'Please add '} <span className={styles.rupee}>&#8377;</span>
                          {`${updateAvailableBalance} to start ${props.insuffCredits.all[offset].insuffType.count} pending verifications`}
                        </>
                      )}

                      {(obj['ADDITIONAL_CHARGE'] > 0 || obj['ADD_ON'] > 0) && (
                        <>
                          {'Please add '} <span className={styles.rupee}>&#8377;</span>
                          {`${updateAvailableBalance} to clear dues`}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </p>
            <Button variant={'primary'} className={styles.addCred}>
              {'Add credits'}
            </Button>
          </div>
        </Alert>
      </div>
    );
  };

  return (
    <>
      {loading ? null : data && data.length ? (
        <div className={styles.transactionHistoryContainer}>
          <h5>{'Insufficient credits'}</h5>

          <Warning />

          <div className={styles.insuffDataTable}>
            {data.map((transaction, index) => {
              return (
                <div className={styles.transactionDetail} key={index}>
                  <Row>
                    <Col lg={8} md={8} sm={8} xs={8} className={styles.detailsContainer}>
                      {renderInfo(transaction, transaction.type)}

                      {transaction.userName ? (
                        <p className={styles.addedRemovedBy}>{`Added by ${transaction.userName !== 'SA' ? transaction.userName : 'SpringVerify'
                          }`}</p>
                      ) : null}
                    </Col>

                    <Col lg={4} md={4} sm={4} xs={4} className={styles.priceContainer}>
                      {renderPrice(transaction)}
                    </Col>
                  </Row>
                </div>
              );
            })}

            <Form.Row className={styles.paginationWrapper}>
              <Col className={styles.showPagination}>
                Show{' '}
                <select
                  className={styles.selectPagination}
                  value={limit}
                  onChange={(event) => {
                    setOffset(0);
                    setLimit(parseInt(event.target.value));
                    paginate(parseInt(event.target.value), 0);
                  }}
                >
                  {pageSizeOptions}
                </select>{' '}
                items of {count}
              </Col>
              <Col>
                <Pagination
                  forcePage={offset}
                  onPageChange={({ selected }) => {
                    setOffset(selected);
                    paginate(limit, selected);
                  }}
                  pageCount={count / limit}
                />
              </Col>
              <Col />
            </Form.Row>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default InsuffCredits;
