import { Span } from '@sentry/core';
import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import styles from './Modal.module.scss';

export default ({ title, onCreateAutomatically, onCreateManually, onHide, ...props }) => {
  const [isOnCreateManually, setIsOnCreateManually] = useState(false);

  const onCreateManuallyHandler = () => {
    setIsOnCreateManually(true);
  };
  const handleClose = () => {
    window.open('/integrations', '_self');
  };
  const onBackHandler = () => {
    setIsOnCreateManually(false);
  };
  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      onHide={handleClose}
      backdropClassName='customFieldGreenhouse'
      {...props}
    >
      <Modal.Header className={isOnCreateManually ? styles.backdrop : null} closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='h5'>{`Create ${title}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={isOnCreateManually ? styles.backdrop : null}>
        <div className='m-3'>
          <Row>
            <p className=''>
              In order for the integration to work, a custom field will have to be declared on
              Greenhouse to identify the relevant package for each candidate.
            </p>
            <a className='' href='https://greenhouse.com/' target='_blank'>
              Learn more
            </a>
          </Row>
          <Row>
            <p className='h5 mt-2'>Custom Field Details</p>
          </Row>
          <Row>
            <p className='h6'>Name: SpringVerify BGV Package</p>
          </Row>
          <Row>
            <p className='h6'>Type: Dropdown</p>
          </Row>
          <Row>
            <p className='h6'>Module: Jobs</p>
          </Row>
          {isOnCreateManually ? null : (
            <Row>
              <p className={styles.note}>
                NOTE: Each time new Job is created, the relevant SpringVerify package must be
                selected by the user creating the job. When the candidate reaches the desired stage
                or status, BGV will be automatically processed based on the package selected in the
                Job.
              </p>
            </Row>
          )}
        </div>
      </Modal.Body>
      {isOnCreateManually ? (
        <Modal.Footer>
          <Row>
            <h5 className='h5 col-md-12 mt-4'>Are you sure you want to create it manually?</h5>
            <p className='col-md-12 mt-2'>
              The integration wil not be complete until you create the custom field on Greenhouse
              and configure it on SpringVerify
            </p>
            <span className={styles.btnContainer}>
              <Button
                className={styles.btnOutline}
                onClick={() => onBackHandler()}
                variant='outline-primary'
              >
                {'Go back'}
              </Button>
              <Button onClick={() => onCreateManually()} variant='danger'>
                {'Create Manually'}
              </Button>
            </span>
          </Row>
        </Modal.Footer>
      ) : (
        <Modal.Footer className='mt-3'>
          <Button
            className={styles.btnOutline}
            onClick={() => onCreateManuallyHandler()}
            variant='outline-primary'
          >
            {'Create Manually'}
          </Button>
          <Button
            className={styles.onCreateAutomaticallyBtn}
            onClick={() => onCreateAutomatically()}
            variant='primary'
          >
            {'Create Automatically'}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
