import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { educationGradesheetUpload as educationGradesheetUploadAPI } from '../../../../../../../actions/bgv';

const mapStateToProps = (state) => {
  return {
    temporaryEducation: !isEmpty(state.temporaryEducation) ? { ...state.temporaryEducation } : {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    educationGradesheetUpload: (data) => dispatch(educationGradesheetUploadAPI(data))
  };
};

export { mapStateToProps, mapDispatchToProps };
export default compose(connect(mapStateToProps, mapDispatchToProps));
