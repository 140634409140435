import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  uploadCandidateCSV as uploadCandidateCSVAPI,
  setBulkUploadData as setBulkUploadDataAPI
} from '../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    companyId: (state && state?.profile?.profile?.company_user_mapping?.company_id) || ''
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadCandidateCSV: (data) => dispatch(uploadCandidateCSVAPI(data)),
    setBulkUploadDataAPI: (data) => dispatch(setBulkUploadDataAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
