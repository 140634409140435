import { connect } from 'react-redux';
import {
  setIntegration as setIntegrationDataAPI,
  getCompanyPaymentDetails
} from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    integrationData: state?.integrationData || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIntegrationDataAPI: (companyId) => dispatch(setIntegrationDataAPI(companyId)),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
