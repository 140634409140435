import { connect } from 'react-redux';

import {
  getInsufficiencies,
  invalidateInsufficiencies,
  getPurchaseHistory as getPurchaseHistoryAPI,
  invalidatePurchaseHistory
} from '../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    purchaseHistory: state.purchaseHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInsufficiencies: (limit, offset, status) =>
      dispatch(getInsufficiencies(limit, offset, status)),
    getPurchaseHistory: (limit, offset) => dispatch(getPurchaseHistoryAPI(limit, offset)),
    invalidateInsufficiencies: () => dispatch(invalidateInsufficiencies()),
    invalidatePurchaseHistory: () => dispatch(invalidatePurchaseHistory())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
