// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ho2Mj6oOYiSAybiophB3 {
  display: flex;
}
.Ho2Mj6oOYiSAybiophB3 .J7I22c5Lz3wW0m7Pjh2r {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins, sans-serif;
  color: #333333;
  cursor: pointer;
  padding: 24px 16px 24px 16px;
}
.Ho2Mj6oOYiSAybiophB3 .J7I22c5Lz3wW0m7Pjh2r:first-child {
  padding-left: 24px;
}
.Ho2Mj6oOYiSAybiophB3 .J7I22c5Lz3wW0m7Pjh2r .hzzJpxcnt6GwzknsKk96 {
  color: #2755FE;
  border-bottom: 3px solid #2755FE;
  padding-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/core-components/TabComponent/TabComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,4BAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,cAAA;EACA,gCAAA;EACA,mBAAA;AAAN","sourcesContent":[".tabComponentWrapper {\n  display: flex;\n\n  .tabStyles {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    font-family: Poppins, sans-serif;\n    color: #333333;\n    cursor: pointer;\n    padding: 24px 16px 24px 16px;\n\n    &:first-child {\n      padding-left: 24px;\n    }\n\n    .tabActive {\n      color: #2755FE;\n      border-bottom: 3px solid #2755FE;\n      padding-bottom: 6px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabComponentWrapper": `Ho2Mj6oOYiSAybiophB3`,
	"tabStyles": `J7I22c5Lz3wW0m7Pjh2r`,
	"tabActive": `hzzJpxcnt6GwzknsKk96`
};
export default ___CSS_LOADER_EXPORT___;
