import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Row } from 'react-bootstrap';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import { DropdownList } from 'react-widgets';

const useStyles = makeStyles({
  backButton: {
    color: '#2755fe',
    '&:hover': {
      backgroundColor: '#1f44ca',
    },
  },
});

export default (props) => {
  const { open, handleClose, handleContinue, categories } = props;
  const [categoryTypeId, setCategoryType] = useState(-1);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const classes = useStyles();
  const buttonStyle = {
    backgroundColor: isHovered ? '#1f44ca' : '#2755fe',
  };

  const onValueChange = (value) => {
    setIsConsentChecked(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleContinue(categoryTypeId != -1 ? categoryTypeId : '');
  };

  const handleDropdownChange = (categorySelected) => {
    setCategoryType(categorySelected.id);
  };

  return (
    <Modal size='lg' show={open} centered backdrop='static' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Category</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <div style={{ margin: '20px 0px 20px 0px' }}>
            <Form.Label>
              <p style={{ fontColor: '#293845', margin: '0px' }}>Category(Optional)</p>
            </Form.Label>

            <Row style={{ height: '10vh' }}>
              <div style={{ width: '50%' }}>
                <DropdownList
                  data={[{ id: -1, category_name: 'None' }, ...categories]}
                  dataKey='id'
                  textField='category_name'
                  onSelect={handleDropdownChange}
                  selectIcon={<FontAwesomeIcon icon={faCaretDown} />}
                />
              </div>
            </Row>

            <Row style={{ paddingLeft: '1.1rem' }}>
              <Form.Check
                type='checkbox'
                checked={isConsentChecked}
                onChange={(e) => {
                  onValueChange(e.target.checked);
                }}
                label={
                  <p style={{ fontColor: '#293845' }}>
                    I understand that any option selected in the steps ahead will apply to all
                    candidates being added and cannot be changed later.
                  </p>
                }
              />
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className={classes.backButton} variant='outline-primary' onClick={handleClose}>
            Back
          </Button>

          <Button
            disabled={!isConsentChecked}
            type='submit'
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Next
          </Button>

        </Modal.Footer>
      </Form>
    </Modal>
  );
};
