import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineEdit,
  AiFillMessage
} from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import React from 'react';
import Image from '../core-components/Image';
/*
 @@  Tabs for Alumni Verification Page
*/
export const TAB_RECENT_REQUEST = 'Recent Requests';
export const TAB_ALUMNI_RECORDS = 'Alumni Records';
export const TAB_DATA_SYNC_LOG = 'Data Sync Log';
export const TAB_CONFIGURATION = 'Configuration';

/*
 @@  Tabs Mapping for Query Params
*/
export const DEFAULT_TAB_MAPPING = {
  recent_request: TAB_RECENT_REQUEST,
  alumni_records: TAB_ALUMNI_RECORDS,
  sync_logs: TAB_DATA_SYNC_LOG,
  config: TAB_CONFIGURATION
};

export const RECENT_INPUT_SEARCH_TYPE = [
  { label: 'Name', value: 'name' },
  { label: 'Staff ID', value: 'staff_id' }
];
export const ALUMNI_INPUT_SEARCH_TYPE = [
  { label: 'Name', value: 'name' },
  { label: 'Staff ID', value: 'staff_id' },
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'phone' }
];
export const DATA_SYNC_MODE = [
  { label: 'All', value: '' },
  { label: 'Manual', value: 'Manual' },
  { label: 'HRMS', value: 'HRMS' }
];
export const DATA_SYNC_STATUS = [
  { label: 'All', value: '' },
  { label: 'Added', value: 'ADDED' },
  { label: 'Updated', value: 'UPDATED' },
  { label: 'Duplicate', value: 'DUPLICATE' },
  { label: 'Skipped', value: 'SKIPPED' },
  { label: 'No records found', value: 'NO_RECORDS_FOUND' },
  { label: 'Error', value: 'ERROR' }
];

export const getIconBasedOnStatus = (status) => {
  switch (status) {
    case 'duplicate':
      return <FiCopy color='#24BBC5' width={14} />;
    case 'failed':
      return <GiCancel color='#E97070' />;
    case 'message':
      return <AiFillMessage />;
    case 'skipped':
      return <AiOutlineExclamationCircle color='#F8A461' />;
    case 'updated':
      return <AiOutlineEdit color='#0844A6' />;
    default:
      return <AiOutlineCheckCircle color='#1D8A42' />;
  }
};

const textBasedOnCount = (count = 0, singular, plural) => {
  count = parseInt(count);
  return count > 1 ? plural : singular;
};
export const getTooltipBasedOnStatus = (status, value) => {
  switch (status) {
    case 'duplicate':
      return `${value} ${textBasedOnCount(
        value,
        'record was',
        'records were'
      )} not added as ${textBasedOnCount(
        value,
        'it is a',
        'they are'
      )} duplicate ${textBasedOnCount(value, 'record', 'records')}`;
    case 'failed':
      return `${value} ${textBasedOnCount(
        value,
        'record was',
        'records were'
      )} not added`;
    case 'added':
      return `${value} ${textBasedOnCount(
        value,
        'record was',
        'records were'
      )} added to the system`;
    case 'updated':
      return `${value} existing ${textBasedOnCount(
        value,
        'record was',
        'records were'
      )} updated as there were changes in the data`;
    default:
      return '-';
  }
};

export const getEventType = (event) => {
  switch (event) {
    case 'DISCONNECTION':
      return 'Disconnected';
    case 'RECORD_SYNC':
      return 'Record Sync';
    case 'CONNECTION':
      return 'Connected';
    default:
      return event;
  }
};
export const getEventTypeIcon = (event) => {
  switch (event) {
    case 'DISCONNECTION':
      return <Image name='disconnect.svg' />;
    case 'CONNECTION':
      return <Image name='connected.svg' />;
    default:
      return '';
  }
};

export const getMode = (mode) => {
  switch (mode) {
    case 'MANUAL':
      return 'Manually uploaded';
    case 'HRMS':
      return 'HRMS';
    default:
      return mode;
  }
};
