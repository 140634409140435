// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  justify-content: center;
  cursor: pointer;
}
.pagination li {
  border: 0;
  max-width: fit-content;
  height: 23px;
  text-align: center;
  margin: 0 0.625rem;
  padding: 0 8px;
}
.pagination li a {
  color: #000000;
  font-size: 14px;
  font-style: normal;
}
.pagination .active {
  border-radius: 2px;
  background-color: #2855ff;
}
.pagination .active a {
  color: #fff;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/core-components/Pagination/Pagination.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,eAAA;AACF;AAAE;EACE,SAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAGN;AAAE;EACE,kBAAA;EACA,yBAAA;AAEJ;AADI;EACE,WAAA;EACA,aAAA;AAGN","sourcesContent":[".pagination {\n  justify-content: center;\n  cursor: pointer;\n  li {\n    border: 0;\n    max-width: fit-content;\n    height: 23px;\n    text-align: center;\n    margin: 0 0.625rem;\n    padding: 0 8px;\n    a {\n      color: #000000;\n      font-size: 14px;\n      font-style: normal;\n    }\n  }\n  .active {\n    border-radius: 2px;\n    background-color: #2855ff;\n    a {\n      color: #fff;\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
