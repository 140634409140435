import React, { useState } from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import RazorpayButton from '../Razorpay';
import styles from './Confirm.module.scss';

export default (props) => {
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const { paymentsObj, show, onClose, setShowLoader } = props;
  const handleCheckboxChange = (e) => {
    setIsConsentChecked(e.target.checked);
  };
  return (
    <Modal
      size='lg'
      centered
      show={show}
      onHide={onClose}
      className={styles.confirmationModal}
      backdropClassName={'confirmationModalBackdrop'}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <Col className={styles.confirModalBody}>
          <Row lg={12} md={12} sm={12} xs={12} className={styles.confirmTextContainer}>
            <h5>TDS Deduction</h5>
          </Row>
          <Row lg={12} md={12} sm={12} xs={12} className={styles.confirmTextContainer}>
            <div className={styles.confirmTextBody}>
              You have opted for Tax Deduction at Source. Use this option
              <span style={{ fontWeight: 'bold' }}> ONLY </span> if you understand the implications
              of deducting taxes at the source; if not, please proceed without this option.
            </div>
          </Row>
          <Row lg={12} md={12} sm={12} xs={12} className={styles.confirmTextBodyRed}>
            In case of non-submission of TDS certificate, TDS credit will be reversed.
          </Row>
          <Row
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${styles.confirmTextContainer} ${styles.consent}`}
          >
            <Form.Check
              type='checkbox'
              checked={isConsentChecked}
              onChange={handleCheckboxChange}
              label={
                <p style={{ fontColor: '#293845' }}>
                  I confirm that my organisation will provide TDS certificate for each quarter of
                  the financial Year.
                </p>
              }
            />
          </Row>
        </Col>
      </Modal.Body>
      <Modal.Footer className={styles.confirmModalFooter}>
        <Button type='button' variant='outline-primary' onClick={onClose}>
          Cancel
        </Button>
        <RazorpayButton
          setShowLoader={setShowLoader}
          {...paymentsObj}
          buttonText={'Confirm'}
          setTransactionId={props.setTransactionId}
          displayValue={false}
          onHide={onClose}
          disable={isConsentChecked ? false : true}
        />
      </Modal.Footer>
    </Modal>
  );
};
