import {
  Badge,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Image from '../../../../../core-components/Image';
import Select from 'react-select';
import styles from './AdditionalChargeControls.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  ADC_ALWAYS_APPROVE,
  ADC_APPROVAL_REQUIRED,
  ADC_APPROVE_TILL_LIMIT,
  ADC_CUSTOM_AMOUNT,
  ADC_CUSTOM_CONFIG,
  AUTO_APPROVAL_AMOUNT
} from '../../../../../utils/commonConstant';
import { useField, useFormikContext } from 'formik';
import { convertToCommaSeparateString } from '../../../../../utils/utilities';

export default (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { touched } = meta;
  const { setValue, setTouched, setError } = helpers;
  const { component, isCustom, amountLimit, type, header, content } = props;
  const { errors } = useFormikContext();

  // Radio Button, Dropdown and Input control variables
  const [approval, setApproval] = useState('');
  const [amountUpto, setAmountUpto] = useState('');
  const [amountInput, setAmountInput] = useState('');

  const [openAmountDropdown, setOpenAmountDropdown] = useState(false);

  const setAmountTillLimitValues = () => {
    const amount = AUTO_APPROVAL_AMOUNT.find((a) => a.value === amountLimit);
    if (amount) {
      setAmountUpto(amount.value);
    } else {
      setAmountUpto(ADC_CUSTOM_AMOUNT);
      const amount = convertToCommaSeparateString(amountLimit?.toString());
      setAmountInput(amount);
    }
    const value = { type };
    if (component === 'passThrough') value['amount_limit'] = amountLimit;
    setValue(value, true);
  };

  // To update UI with get api Data
  useEffect(() => {
    if (isCustom) {
      setValue({ type: ADC_CUSTOM_CONFIG }, true);
      setTouched(false, true);
      return;
    }
    switch (type) {
      case ADC_APPROVAL_REQUIRED:
        setApproval('manual');
        break;
      case ADC_ALWAYS_APPROVE:
        setApproval('auto');
        setAmountUpto(type);
        break;
      case ADC_APPROVE_TILL_LIMIT:
        setApproval('auto');
        setAmountTillLimitValues();
        break;
      default:
        break;
    }
  }, [type]);

  // Resetting values when user switches to previous state without saving
  const handleChangeConfig = ({ target: { value } }) => {
    setApproval(value);
    if (value === 'manual') {
      setValue({ type: ADC_APPROVAL_REQUIRED }, true);
      return;
    }
    if (component === 'international') {
      setValue({ type: ADC_ALWAYS_APPROVE }, true);
      return;
    }
    if (amountLimit && type === ADC_APPROVE_TILL_LIMIT) {
      setAmountTillLimitValues();
      return;
    }
    if (type === ADC_ALWAYS_APPROVE) {
      setAmountUpto(ADC_ALWAYS_APPROVE);
    } else {
      setAmountUpto('');
    }
    setValue({ type: type === ADC_ALWAYS_APPROVE ? type : ADC_APPROVE_TILL_LIMIT }, true);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0 4px 4px 0'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontWeight: '400',
        color: '#ccc'
      };
    }
  };

  return (
    <div className={styles.controllerContainer}>
      <div className={styles.header}>
        <Image name={component === 'international' ? 'globe.svg' : 'certificate.svg'} />
        <div className={styles.headerContent}>
          <h5>{header}</h5>
          <p>{content}</p>
        </div>
      </div>
      {isCustom && (
        <div className={styles.customConfigContainer}>
          <h6>Custom configurations</h6>
          <p>
            Note : You are running a custom configuration. Please contact{' '}
            <a target='_blank' href='mailto:cs@springverify.com'>
              cs@springverify.com
            </a>{' '}
            <br /> to modify the configuration
          </p>
        </div>
      )}
      {!isCustom && (
        <FormControl className={styles.actionsContainer}>
          <RadioGroup row value={approval} onChange={handleChangeConfig}>
            <Grid container>
              <Grid item xs={4}>
                <FormControlLabel
                  value='manual'
                  className={styles.radioBtn}
                  control={<Radio />}
                  label={<div className={styles.radioLabel}>Manual approval required</div>}
                />
              </Grid>
              <Grid item xs={8}>
                <Box>
                  <FormControlLabel
                    value='auto'
                    className={styles.radioBtn}
                    control={<Radio />}
                    label={<div className={styles.radioLabel}>Auto-approve</div>}
                  />
                  <Badge overlap='rectangular' className={styles.recommendedBadge}>
                    Recommended
                  </Badge>
                </Box>
                {component !== 'international' && approval === 'auto' && (
                  <Box className={styles.customAmountContainer}>
                    <div className={styles.amountUptoLabel}>
                      Amount upto<span>*</span>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            <div className={styles.amountInfoTooltip}>
                              Any costs above this amount will require manual approval
                            </div>
                          </Tooltip>
                        }
                      >
                        <Image className={styles.infoIcon} name='info.svg' />
                      </OverlayTrigger>{' '}
                      <div className={styles.amountUptoLabel + ' ' + 'd-inline'}>:</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      {amountUpto !== ADC_CUSTOM_AMOUNT && (
                        <div className={styles.dropdownContainer}>
                          <div className={styles.currencySymbol}>₹</div>
                          <Select
                            className={styles.amountDropdown}
                            placeholder='Select amount'
                            styles={customStyles}
                            options={AUTO_APPROVAL_AMOUNT}
                            defaultValue={AUTO_APPROVAL_AMOUNT.find((a) => a.value === amountUpto)}
                            defaultMenuIsOpen={openAmountDropdown}
                            isSearchable={false}
                            onChange={(e) => {
                              setAmountUpto(e?.value);
                              setOpenAmountDropdown(false);
                              if (
                                e?.value !== ADC_ALWAYS_APPROVE &&
                                e?.value !== ADC_APPROVE_TILL_LIMIT &&
                                e?.value !== ADC_CUSTOM_AMOUNT
                              ) {
                                setValue(
                                  { type: ADC_APPROVE_TILL_LIMIT, amount_limit: e?.value },
                                  true
                                );
                              }
                              if (e?.value === ADC_CUSTOM_AMOUNT) {
                                setValue({ type: ADC_APPROVE_TILL_LIMIT }, true);
                                setAmountInput('');
                                setTimeout(() => {
                                  setError({ pass_through: 'Approve amount is required' });
                                }, 0);
                                setTouched(false, true);
                              }
                              if (e?.value === ADC_ALWAYS_APPROVE) {
                                setValue({ type: e?.value }, true);
                                return;
                              }
                            }}
                          />
                        </div>
                      )}
                      {amountUpto === ADC_CUSTOM_AMOUNT && (
                        <div className={styles.dropdownContainer}>
                          <div className={styles.currencySymbol}>₹</div>
                          <input
                            className={
                              styles.amountInput +
                              ' ' +
                              (errors?.pass_through?.amount_limit && styles.errInput)
                            }
                            value={amountInput}
                            type='text'
                            pattern='^[0-9]*$'
                            onChange={(e) => {
                              const val = e?.target?.value?.replace(/[^\d]+/g, '');
                              const amount = val ? Number(val) : '';
                              setValue(
                                { type: ADC_APPROVE_TILL_LIMIT, amount_limit: amount },
                                true
                              );
                              setAmountInput(convertToCommaSeparateString(val));
                              setTimeout(() => {
                                setTouched(true, true);
                              }, 0);
                            }}
                            onBlur={() => {
                              setTouched(true, true);
                            }}
                            placeholder='Enter custom amount'
                          />

                          <button
                            type='button'
                            className={styles.inputArrowBtn}
                            onClick={() => {
                              setAmountUpto('');
                              setValue({ type: ADC_APPROVE_TILL_LIMIT }, true);
                              setOpenAmountDropdown(true);
                            }}
                          >
                            <Image name='angleDown.svg' />
                          </button>
                          {errors?.pass_through && (
                            <div className={styles.errMeg}>
                              {' '}
                              {errors?.pass_through?.amount_limit}{' '}
                            </div>
                          )}
                        </div>
                      )}
                      <div className={styles.information}>
                        <Image className={styles.infoImage} name='idea.svg' />{' '}
                        <p>
                          {' '}
                          <b>90%</b> of additional costs are generally under <br />{' '}
                          <b>
                            <span className={styles.robotoFont}>₹</span>2,500
                          </b>
                        </p>
                      </div>
                    </div>
                  </Box>
                )}
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
};
