import React, { useState, useEffect } from 'react';
import PackageListing from './PackageListing';
import styles from './ViewBuyPackage.module.scss';
import ReviewOrder from './ReviewOrder';
import PaymentResults from '../../../core-components/PaymentResults';
import { Button } from 'react-bootstrap';
import { convertToIndianNumeration } from '../../../utils/utilities';
import AppConstants from '../../../core-components/AppConstants';
import history from '../../../utils/history';

function BuyPackage(props) {
  const limit = 50,
    offset = 0;

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageOptionIndex, setSelectedPackageOptionIndex] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [candidateCount, setCandidateCount] = useState('');
  const [purchasedPackageName, setPurchasedPackageName] = useState('');
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    const where = {
      limit,
      offset
    };
    props?.invalidateViewBuyPackages();
    props?.getViewBuyPackages(where);
    props?.getAddCandidateConfig();
    props?.getPaymentDetails();
    return () => {
      props?.invalidateViewBuyPackages();
    };
  }, []);

  useEffect(() => {
    const location = history.location;
    const where = {
      limit,
      offset
    };

    if (props?.viewBuyPackages?.rows?.length === 0 && props?.createCandConfig?.packages?.length) {
      props?.invalidateViewBuyPackages();
      props?.getViewBuyPackages(where);
    }

    if (
      location.search &&
      !props?.viewBuyPackages?.loading &&
      props?.viewBuyPackages?.rows?.length
    ) {
      const query = new URLSearchParams(location.search);
      const _id = query.get('_id');

      if (_id) {
        let obj;
        const subType = props?.viewBuyPackages?.rows?.filter((pack) => {
          if (String(_id) === String(pack.subtypeId)) obj = pack;
        })[0];

        const subtypeIndex = props?.viewBuyPackages?.rows?.findIndex(
          (r) => r.subtypeId === obj.subtypeId
        );

        setSelectedPackageIndex(subtypeIndex);
        setSelectedPackage(obj);
        handleStepChange(2);
      }
    } else {
      handleStepChange();
    }
  }, [history.location.search, props.viewBuyPackages]);

  const handleStepChange = (step = 1) => {
    setCurrentStep(step);
  };

  const handleSelectedPackageIndex = (subtypeId = null) => {
    const subType = props?.viewBuyPackages.rows.filter((pack) => pack.subtypeId === subtypeId)[0];
    const subtypeIndex = props?.viewBuyPackages.rows.findIndex(
      (r) => r.subtypeId === subType.subtypeId
    );
    setSelectedPackageIndex(subtypeIndex);
    setSelectedPackage(subType);
  };

  const handleSelectedPackageOptionIndex = (index = null) => {
    setSelectedPackageOptionIndex(index);
  };

  const handleBuyPackage = () => {};

  useEffect(() => {
    if (paymentStatus === 'failed' || paymentStatus === 'completed') setCurrentStep(3);
  }, [paymentStatus]);
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <PackageListing
            selectedPackageIndex={selectedPackageIndex}
            viewBuyPackages={props?.viewBuyPackages}
            createCandConfig={props?.createCandConfig}
            handleSelectedPackageIndex={handleSelectedPackageIndex}
            handleStepChange={handleStepChange}
            packageType={props.packageType}
            getPackages={() =>
              props?.getViewBuyPackages({
                limit,
                offset
              })
            }
          />
        );
      case 2:
        return (
          <ReviewOrder
            selectedPackage={selectedPackage}
            setPaymentStatus={setPaymentStatus}
            handleStepChange={handleStepChange}
            selectedPackageOptionIndex={selectedPackageOptionIndex}
            handleSelectedPackageOptionIndex={handleSelectedPackageOptionIndex}
            handleBuyPackage={handleBuyPackage}
            setOrderId={setOrderId}
            setCandidateCount={setCandidateCount}
            paymentDetails={props?.paymentDetails}
            setPurchasedPackageName={setPurchasedPackageName}
            setTransactionId={setTransactionId}
            invalidatePaymentCreditTransaction={props.invalidatePaymentCreditTransaction}
          />
        );
      case 3:
        return (
          <PaymentResults
            paymentStatus={paymentStatus}
            setPaymentStatus={setPaymentStatus}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            orderId={orderId}
            description={
              <>
                {paymentStatus === 'pending' && <p>This might take a few seconds</p>}
                {paymentStatus === 'completed' && (
                  <p>{`${purchasedPackageName} package purchased for ${convertToIndianNumeration(
                    candidateCount
                  )} candidates`}</p>
                )}
                {paymentStatus === 'failed' && (
                  <p>
                    Something went wrong with the payment gateway. Please try again in a few
                    minutes.
                  </p>
                )}
              </>
            }
            buttons={
              <>
                <Button
                  variant='primary'
                  onClick={() => {
                    props.history.push('/candidate/create');
                  }}
                >
                  {'Add candidate'}
                </Button>
                {!props.hideViewDetailsButton ? (
                  <Button
                    variant='outline-primary'
                    onClick={() => {
                      setCurrentStep(1);
                      window.open(
                        `${AppConstants.baseURL}transactionDetails?id=${transactionId}`,
                        '_blank'
                      );
                    }}
                  >
                    {'View details'}
                  </Button>
                ) : null}
              </>
            }
          />
        );
      default:
        return null;
    }
  };

  return <div className={styles.packagesWrapper}>{renderStep()}</div>;
}

export default BuyPackage;
