import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';

import Button from '../../core-components/Button';
import ValidatedFormInputField from '../../core-components/ValidatedFormInputField';

import styles from './ForgotPassword.module.scss';
import { isEmpty, isEqual } from 'lodash';

export default (props) => {
  const { handleSubmit, isSubmitting, status, values, errors } = props;
  const [initialUserInfo, setInitialUserInfo] = useState({ email: '' });

  const onSubmit = (e) => {
    setInitialUserInfo({ email: values.email });
    handleSubmit(e);
  };

  return (
    <div className={styles.forgotPasswordWrapper}>
      {!status ? (
        <div className={styles.passwordEmailCard}>
          <div className={styles.emailMessage}>
            <p>Forgot Password?</p>
            <p className={styles.information}>We’ll send a recovery email to your email address</p>
          </div>
          <Form>
            <Field component={ValidatedFormInputField} name='email' label='Email ID' />
            {props.values.loginError && (
              <div className={styles.errorMsg}>{props.values.loginError}</div>
            )}
            <Button
              type='submit'
              onClick={onSubmit}
              disabled={
                isEqual(initialUserInfo?.email, values?.email) ||
                isSubmitting ||
                props.values.loginError ||
                !values.email ||
                (errors && !isEmpty(errors))
              }
            >
              Send recovery email
            </Button>
          </Form>
          {props.values.forgotPasswordFailedError && (
            <div className={styles.errorMessage}>{props.values.forgotPasswordFailedError}</div>
          )}
        </div>
      ) : (
        <div className={styles.passwordEmailCard}>
          <div className={styles.emailMessage}>
            <p>Recovery email sent</p>
            <p className={styles.information}>We’ve sent a recovery email to your email address</p>
            <p className={styles.recoveryEmail}>{values.email ? values.email : 'NA'}</p>
            <Button onClick={() => props.history.push('/signIn')} className={styles.goBackBtn}>
              Go back to Sign in
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
