/* eslint-disable */
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useTable, useFlexLayout, useResizeColumns, useRowSelect } from 'react-table';
import Cell from './Cell';
import Header from './Header';
import { TableVirtuoso } from 'react-virtuoso';
import { MdChecklistRtl } from 'react-icons/md';

const defaultColumn = {
  Cell: Cell,
  Header: Header
};

const IndeterminateCheckbox = React.memo(
  React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    );
  })
);

const Table = ({
  columns,
  data,
  errorData,
  dispatch: dataDispatch,
  skipReset,
  showOnlyError = false,
  setSelectedRows,
  errorCount,
  addRecordTrigger
}) => {
  const memoizedColumns = useMemo(() => [...columns], [columns]);

  //filtered Data on showOnlyError
  const [datax, setDatax] = useState(data);
  const [errorDatax, setErrorDatax] = useState(errorData);
  useEffect(() => {
    if (showOnlyError) {
      setDatax(data.filter((row, index) => Object.keys(errorData?.[index]).length > 0));
      setErrorDatax(errorData.filter((row, index) => Object.keys(errorData?.[index]).length > 0));
    } else {
      setDatax(data);
      setErrorDatax(errorData);
    }
  }, [showOnlyError, data, errorData]);

  // scroll to bottom when new record is added
  const virtuosoRef = useRef(null);
  useEffect(() => {
    if (virtuosoRef.current && addRecordTrigger !== 0) {
      virtuosoRef.current.scrollToIndex({ index: data.length + 1 });
    }
  }, [addRecordTrigger]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds }
  } = useTable(
    {
      columns: memoizedColumns,
      data: datax,
      defaultColumn,
      dataDispatch,
      autoResetRowState: !skipReset
    },
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40px',
                borderRight: '1px solid #e0e0e0'
              }}
            >
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
    }
  );

  const isTableResizing = useCallback(() => {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }, [headerGroups]);

  useEffect(() => {
    const selectedRows = Object.keys(selectedRowIds).map((key) => parseInt(key));
    if (selectedRows) {
      setSelectedRows(selectedRows);
    }
  }, [selectedRowIds]);

  return (
    <TableVirtuoso
      ref={virtuosoRef}
      style={{ minHeight: '580px', height: 'calc(90vh - 25rem)', border: '1px solid #8d8d8d' }}
      totalCount={rows.length || 1}
      components={{
        Table: ({ style, ...props }) => {
          return (
            <div
              {...getTableProps()}
              {...props}
              className={`table ${isTableResizing() ? 'noselect' : ''}`}
              style={{ ...style, width: '100%' }}
            />
          );
        },
        TableBody: React.forwardRef(({ style, ...props }, ref) => (
          <div {...getTableBodyProps()} {...props} ref={ref} />
        )),
        TableRow: (props) => {
          const index = props['data-index'];
          const row = rows[index];
          if (!row) return <div {...props} className='tr' />;
          return <div {...props} {...row.getRowProps()} className='tr' />;
        }
      }}
      fixedHeaderContent={() => {
        return headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className='tableHeader tr'>
            {headerGroup.headers.map((column) => column.render('Header'))}
          </div>
        ));
      }}
      itemContent={(index) => {
        const row = rows[index];
        if (showOnlyError && rows.length === 0) {
          return (
            <div className='tr add-row'>
              <span className='svg-icon svg-gray' style={{ marginRight: '10px' }}>
                <MdChecklistRtl />
              </span>
              No validation errors found. You can continue uploading the sheet.
            </div>
          );
        }
        if (!row) return null;
        prepareRow(row);
        return row.cells.map((cell, j) => (
          <div {...cell.getCellProps()} className='td' key={j}>
            {cell.render('Cell', {
              error: errorDatax?.[index]?.[cell.column.id] || null
            })}
          </div>
        ));
      }}
    />
  );
};

export default React.memo(Table);
