// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chy_YZyL6EZu2b3ZWMGw {
  width: 100%;
  padding: 24px 48px;
}
.Chy_YZyL6EZu2b3ZWMGw .MBVsIfdcysdCGrRZx1bs {
  margin-bottom: 16px;
}
.Chy_YZyL6EZu2b3ZWMGw .MBVsIfdcysdCGrRZx1bs h1 {
  font-weight: 500;
  font-size: 24px;
  color: #464457;
}

.HH83HHY2kgLxqcNSHge_ {
  color: #2755fe;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/CreateCandidate/SelectedPackage/SelectedPackage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACN;;AAIA;EACE,cAAA;AADF","sourcesContent":[".selectedPackageContainer {\n  width: 100%;\n  padding: 24px 48px;\n\n  .customizeHeading {\n    margin-bottom: 16px;\n\n    h1 {\n      font-weight: 500;\n      font-size: 24px;\n      color: #464457;\n    }\n  }\n}\n\n.goButton {\n  color: #2755fe;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedPackageContainer": `Chy_YZyL6EZu2b3ZWMGw`,
	"customizeHeading": `MBVsIfdcysdCGrRZx1bs`,
	"goButton": `HH83HHY2kgLxqcNSHge_`
};
export default ___CSS_LOADER_EXPORT___;
