import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Image from '../../../../../core-components/Image';
import { getNewInsufficienciesComments as getNewInsufficienciesCommentsAPI } from '../../../../../api/company';
import { errToastMessage, getErrorMessage, toCamelCase } from '../../../../../utils/utilities';
import CustomSpinner from '../../../../../core-components/CustomSpinner';

import styles from './Comment.module.scss';

const limit = 10;
let isNoMoreData = false,
  offset = 0;

export default (props) => {
  const [commentsData, setCommentsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);

  const handleScroll = () => {
    if (
      document.getElementById('commentsContainer').scrollTop ===
        document.getElementById('commentsContainer').scrollHeight -
          document.getElementById('commentsContainer').offsetHeight &&
      !isNoMoreData
    ) {
      setFetching(true);
    } else {
      return;
    }
  };

  // fetching comment for the first time
  const fetchComments = (where) => {
    getNewInsufficienciesCommentsAPI(where)
      .then((response) => {
        const commentData = toCamelCase(response.data.data);
        if (
          !commentData.rows.length ||
          commentData.rows.length < 10 ||
          commentData['rows'].length === commentData.count
        ) {
          isNoMoreData = true;
        }
        offset = offset + 1;
        setCommentsData(commentData);
        setLoading(false);
      })
      .catch((error) => {
        isNoMoreData = true;
        setLoading(false);
        console.error(error);
        errToastMessage(error);
      });
  };

  // adding listener for infinite scroll and calling get comment API for the first time
  useEffect(() => {
    const where = {
      manual_insufficiency_id: props?.manualInsufficiencyId,
      limit,
      offset
    };
    fetchComments(where);

    const interval = window.setInterval(() => {
      if (document && document.getElementById('commentsContainer')) {
        document.getElementById('commentsContainer').addEventListener('scroll', handleScroll);
        clearInterval(interval);
      }
    }, 500);

    return () => {
      if (document && document.getElementById('commentsContainer')) {
        document.getElementById('commentsContainer').removeEventListener('scroll', handleScroll);
      }
      offset = 0;
      isNoMoreData = false;
    };
  }, []);

  // fetching more comments
  useEffect(() => {
    if (fetching && !isNoMoreData) {
      fetchMoreComments();
    } else {
      return;
    }
  }, [fetching]);

  const fetchMoreComments = () => {
    if (!isNoMoreData) {
      const where = {
        manual_insufficiency_id: props?.manualInsufficiencyId,
        limit,
        offset
      };

      getNewInsufficienciesCommentsAPI(where)
        .then((response) => {
          const commentData = { ...commentsData };
          commentData['rows'] = [...commentData['rows'], ...toCamelCase(response.data.data).rows];
          offset = offset + 1;
          if (
            !response.data.data.rows.length ||
            response.data.data.rows.length < 10 ||
            commentData['rows'].length === commentData.count
          ) {
            isNoMoreData = true;
          }

          setCommentsData(commentData);
          setFetching(false);
        })
        .catch((error) => {
          setFetching(false);
          isNoMoreData = true;
          console.error(error);
          errToastMessage(error);
        });
    } else {
      setFetching(false);
    }
  };

  return loading ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CustomSpinner
        className='bgvLoader'
        as='div'
        animation='border'
        size='md'
        role='status'
        aria-hidden='true'
      />
    </div>
  ) : commentsData && commentsData.rows && commentsData.rows.length ? (
    <>
      <Container
        style={{ maxHeight: '450px', overflowY: 'auto' }}
        id={'commentsContainer'}
        data-testid='commentsContainer'
      >
        {commentsData.rows.map((comment, index) => {
          return (
            <Row key={`comment-${index}`}>
              <Col lg={9} md={9} sm={12} xs={12} style={{ padding: 0 }}>
                <div className={styles.commentBox}>
                  <div className={styles.commentBoxLogo}>
                    <div className={styles.commentBoxLogoContainer}>
                      <Image
                        className={styles.commentBoxLogoImg}
                        name='insuffModalCommentLogo.svg'
                      />
                    </div>
                  </div>
                  <div className={styles.commentBoxContent}>
                    <div className={styles.commentBoxContentHeader}>
                      {`SpringVerify  ${moment(comment.createdAt).fromNow()}`}
                    </div>
                    <div
                      className={styles.commentBoxContentComment}
                      style={{ wordBreak: 'break-word' }}
                      dangerouslySetInnerHTML={{
                        __html: comment.comment.replace(/\n/gi, '</br>')
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}

        {fetching ? <>{'Fetching more comment...'}</> : null}
      </Container>
    </>
  ) : (
    <>{'No Comments Found'}</>
  );
};
