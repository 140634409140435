import { Field } from 'formik';
import React, { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Image from '../../../core-components/Image';
import cn from 'classnames';
import styles from './AmountComponent.module.scss';
import { getArrearsAmount, getPercentageText } from '../../UtilComponents/UtilitiesFunction';
import ValidatedFormInputField from '../../../core-components/ValidatedFormInputField/ValidatedFormInputField';
import {
  indianNumerationValue,
  convertToIndianNumeration
} from '../../../core-components/Utilities/utilities';
import { convertToCommaSeparateString } from '../../../utils/Utilities';

export default function AmountComponent(props) {
  const { walletDetail, amountArray, setFieldValue, values } = props;
  const {
    currentBalance,
    arrearsTotalAmount,
    arrears,
    recommendedAmount,
    customRecommendationOptions
  } = walletDetail;

  const [inputFocus, setInputFocus] = useState(false);
  // const inputRef = useRef(null);

  return (
    <div className={styles.container}>
      <div className={styles.containerSubHeader}>Select wallet recharge amount</div>
      <Form>
        {/* ------------------ Show all radio button except the Custom ------------------  */}
        {amountArray.map((val, i) => {
          return (
            <Card
              className={styles.cardContainer}
              onClick={() => {
                // Making entire Card clickable
                setFieldValue('amount', val.value);
                setFieldValue('customAmount', '');
              }}
            >
              <div
                id={val.value}
                className={`${styles.cardSubContainer} ${
                  val.id == 'option3' ? styles.recommended : ''
                }`}
              >
                <Form.Check
                  type={'radio'}
                  name='amount'
                  custom
                  id={val.id}
                  checked={val.value === parseInt(values.amount)}
                  value={val.value}
                  onChange={(e) => {
                    setFieldValue('amount', e.target.value);
                    setFieldValue('customAmount', '');
                  }}
                  className={styles.option}
                  label={
                    <div className={styles.label}>
                      {val.value ? (
                        <>
                          <div className={styles.amount}>{indianNumerationValue(val.value)}</div>
                          <div className={styles.text}>{val.text}</div>
                        </>
                      ) : (
                        <div className={styles.customAmountText}>{val.text}</div>
                      )}
                    </div>
                  }
                />
                {val.id == 'option3' ? (
                  <div className={styles.badge} bg='secondary'>
                    Recommended
                  </div>
                ) : null}
              </div>
            </Card>
          );
        })}
        {/* ----------------- Show Custom radio button only ------------------------  */}
        <Card
          onClick={(e) => {
            // Making entire Card clickable

            if (values.amount !== 'custom') {
              setFieldValue('amount', 'custom');
              setFieldValue(
                'customAmount',
                recommendedAmount ? recommendedAmount : customRecommendationOptions[0]
              );
            }
          }}
          className={styles.cardContainer}
        >
          <div className={styles.cardSubContainer}>
            <Form.Check
              type={'radio'}
              name='amount'
              custom
              id={'option4'}
              value={'custom'}
              checked={'custom' === values.amount}
              onChange={(e) => {
                setFieldValue('amount', e.target.value);
                setFieldValue('customAmount', recommendedAmount);
                // inputRef.current.focus();
                // document.querySelector(`#customAmount`)?.focus();
              }}
              className={styles.option}
              label={
                <div className={styles.label}>
                  <div className={styles.customAmountText}>Custom (Enter any amount)</div>
                </div>
              }
            />
            <div className={styles.inputFieldAndQSBContainer}>
              <div
                onClick={(e) => {
                  // Making input field clickable
                  if (values.amount !== 'custom') {
                    setFieldValue('amount', 'custom');
                    setFieldValue(
                      'customAmount',
                      recommendedAmount ? recommendedAmount : customRecommendationOptions[0]
                    );
                  }
                }}
                className={cn(styles.searchInput, inputFocus ? styles.searchInputFocus : '')}
              >
                <span className={styles.amountText}>&#8377;</span>
                <Field
                  name='customAmount'
                  component={ValidatedFormInputField}
                  type='text'
                  // innerRef={inputRef}
                  autoComplete='off'
                  // disabled={values.amount !== 'custom'}
                  value={convertToCommaSeparateString(String(values.customAmount))}
                  className={styles.inputText}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onFocus={() => {
                    setInputFocus(true);
                  }}
                  onBlur={(e) => {
                    var str = e.target.value;
                    if (str.startsWith(0)) {
                      while (str.startsWith('0')) {
                        str = str.substring(1);
                      }
                      setFieldValue('customAmount', str);
                    }
                    if (e.target.value == 0) {
                      setFieldValue('customAmount', '');
                    }
                    setInputFocus(false);
                  }}
                  onChange={(e) => {
                    const val = e?.target?.value;
                    if (typeof val === 'string') {
                      const amount = val.replace(/[^\d,]+/g, '');
                      setFieldValue('customAmount', convertToCommaSeparateString(amount));
                    }
                  }}
                  placeholder='Enter any amount'
                />
              </div>
              <div className={styles.quickSelectButtons}>
                {customRecommendationOptions &&
                  customRecommendationOptions.length > 0 &&
                  customRecommendationOptions.map((value, index) => {
                    return (
                      <div
                        id={value}
                        className={cn(styles.quickSelectButton1, index == 1 ? styles.noBorder : '')}
                        onClick={() => {
                          setFieldValue('amount', 'custom');
                          setFieldValue('customAmount', value);
                        }}
                      >
                        {index == 0 &&
                        customRecommendationOptions.length > 1 &&
                        customRecommendationOptions[0] <= 600000 ? (
                          <Image name='star.png' className={styles.quickSelectButtonPng} />
                        ) : null}
                        <span className={cn(styles.textOfQSB, styles.amount)}>
                          &#8377;{convertToIndianNumeration(value)}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* -------------- To show the error message when amount entered is less tha arrears ------------  */}
            {arrears &&
            arrearsTotalAmount &&
            arrears.length &&
            values.amount == 'custom' &&
            values.customAmount < getArrearsAmount(arrearsTotalAmount, currentBalance) ? (
              <div className={styles.fieldError}>
                {
                  <Image
                    name={'warningRedIcon.svg'}
                    className={styles.warningRedIcon}
                    isSA={props.isSA}
                  />
                }
                <span>
                  Insufficient to clear your minimum dues. Recharge for{' '}
                  {getArrearsAmount(arrearsTotalAmount, currentBalance)} or higher
                </span>
              </div>
            ) : null}
            {/* ------------ Percentage customer number display -------------  */}
            {customRecommendationOptions &&
              customRecommendationOptions.length > 1 &&
              customRecommendationOptions[0] <= 600000 && (
                <div className={styles.fieldError}>
                  {<Image name={'star.png'} className={styles.warningRedIcon} />}
                  <span className={styles.percentageText}>
                    Over {getPercentageText(parseInt(customRecommendationOptions[0]).toString())}%
                    of SpringVerify customers recharge for this amount or higher
                  </span>
                </div>
              )}
          </div>
        </Card>
      </Form>
    </div>
  );
}
