import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import storage from '../../api/localStorage';
import CommonLayout from '../CommonRoute/Layout/CommonLayout';
import { Helmet } from 'react-helmet';

export default ({ component: Comp, path, pageTitle = '', noMatchFound = false, ...rest }) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return !storage.getToken() || Object.keys(rest?.profile).length === 0 ? (
          noMatchFound ? (
            <Redirect
              to={{
                pathname: '/signIn'
              }}
            />
          ) : (
            <CommonLayout>
              <Helmet>
                <title>{pageTitle}</title>
              </Helmet>
              <Comp {...props} />
            </CommonLayout>
          )
        ) : (
          <Redirect
            to={{
              pathname: '/loading'
            }}
          />
        );
      }}
    />
  );
};
