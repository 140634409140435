// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QHMLIi0jD0hWXH0tvttY {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .QHMLIi0jD0hWXH0tvttY {
    width: 1200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ViewBuyPackage/ViewBuyPackage.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;AACF;AAAE;EAHF;IAII,aAAA;EAGF;AACF","sourcesContent":[".packagesWrapper {\n  max-width: 1200px;\n  margin: auto;\n  @media only screen and (max-width: 1200px) {\n    width: 1200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packagesWrapper": `QHMLIi0jD0hWXH0tvttY`
};
export default ___CSS_LOADER_EXPORT___;
