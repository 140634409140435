// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o1UP4VxWPjive73L1loW {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  align-items: center;
  display: none;
}
.o1UP4VxWPjive73L1loW span {
  color: #999999;
  text-transform: uppercase;
  margin-left: 8px;
}

@media screen and (max-width: 601px) {
  .o1UP4VxWPjive73L1loW {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/CommonComponent/BgvSecureText/BgvSecureText.module.scss","webpack://./src/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;AADF;AAEE;EACE,cAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;;AC2BE;EDvBA;IACE,aAAA;EAAF;AACF","sourcesContent":["@import '/src/styles/mixins/breakpoints';\n\n.secureTextMobile {\n  font-family: Poppins, sans-serif;\n  font-size: 12px;\n  align-items: center;\n  display: none;\n  span {\n    color: #999999;\n    text-transform: uppercase;\n    margin-left: 8px;\n  }\n}\n@include breakpoint-range($max: 600) {\n  .secureTextMobile {\n    display: flex;\n  }\n}\n","@use 'sass:map';\n@use 'sass:string';\n\n$break-points: (\n  400: 410px,\n  480: 480px,\n  500: 500px,\n  600: 600px,\n  768: 768px,\n  992: 992px,\n  1080: 1080px,\n  1200: 1200px,\n  1300: 1300px,\n  1400: 1400px\n);\n\n@mixin breakpoint-range($min: false, $max: false, $mode: screen, $extra: ()) {\n  @each $key in ($min, $max) {\n    @if $key and not map.has-key($break-points, $key) {\n      @error \"Available values for ($from, $to) args are: #{map.keys($break-points)}\";\n    }\n  }\n\n  $conditions: $mode;\n\n  @each $key, $value in (min-width: $min, max-width: $max) {\n    @if $value {\n      $modifier: if($key== 'max-width', -1px, 0px);\n      $condition: ' and (#{$key}: #{map.get($break-points, $value) - $modifier})';\n      $conditions: string.insert($conditions, $condition, -1);\n    }\n  }\n\n  @each $key, $value in $extra {\n    $condition: ' and (#{$key}: #{$value})';\n  }\n\n  @media #{$conditions} {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secureTextMobile": `o1UP4VxWPjive73L1loW`
};
export default ___CSS_LOADER_EXPORT___;
