import React from 'react';
import styles from '../style.module.scss';

const RecordsOverview = ({ data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Records Overview</div>
      </div>
      <div className={styles.info}>
        <div className={styles.unit}>
          <div className={styles.unitValue}>{data.totalAlumnisAdded || '0'}</div>
          <div className={styles.unitTitle}>Records Added</div>
        </div>
        <div className={styles.unit}>
          <div className={styles.unitValue}>
            {data.earliestExitDate === null ? 'N/A' : data.earliestExitDate}
          </div>
          <div className={styles.unitTitle}>Earliest Exit</div>
        </div>
        <div className={styles.unit}>
          <div className={styles.unitValue}>
            {data.recentExitDate === null ? 'N/A' : data.recentExitDate}
          </div>
          <div className={styles.unitTitle}>Recent Exit</div>
        </div>
      </div>
    </div>
  );
};

export default RecordsOverview;
