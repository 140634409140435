import { connect } from 'react-redux';
import { compose } from 'redux';

import { setBulkUploadData as setBulkUploadDataAPI } from '../../../../actions/company';
const mapStateToProps = (state) => {
  return {
    companyId: (state && state?.profile?.profile?.company_user_mapping?.company_id) || '',
    rows: (state && state?.bulkData?.rows) || []
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBulkUploadDataAPI: (data) => dispatch(setBulkUploadDataAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
