import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import { formValuesToAPI } from '../../../CommonComponent/Bgv.mapper';
import { errToastMessage, toCamelCase, toSnakeCase } from '../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  setBgvSubmission as setBgvSubmissionAPI,
  invalidateAddressDocType as invalidateAddressDocTypeAPI,
  invalidateAddressFrontDoc as invalidateFrontDocAPI,
  invalidateAddressBackDoc as invalidateBackDocAPI,
  invalidateAddressSameDoc as invalidateSameDocAPI
} from '../../../../../actions/bgv';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data)),
    invalidateAddressDocType: () => dispatch(invalidateAddressDocTypeAPI()),
    invalidateFrontDoc: () => dispatch(invalidateFrontDocAPI()),
    invalidateBackDoc: () => dispatch(invalidateBackDocAPI()),
    invalidateSameDoc: () => dispatch(invalidateSameDocAPI())
  };
};

const mapStateToProps = (state) => {
  //current state 3 for address
  let currentState = !state.bgvCachedData.data.current_state
    ? 1
    : state.bgvCachedData.data.current_state;
  //if after # there is something then that will be substep which is step1 step2 or step3
  let currentStateMap = currentState.split('#');
  return {
    cachedData: !state?.bgvCachedData?.data ? null : toCamelCase({ ...state?.bgvCachedData?.data }),
    bgvConfig: !isEmpty(state.bgvPackageConfig.data)
      ? { ...state.bgvPackageConfig.data, loading: state.bgvPackageConfig.loading }
      : null,
    isLoadingBgvCache: state?.bgvCachedData?.loading,
    currentSubStep: !isEmpty(currentStateMap[1]) ? parseInt(currentStateMap[1]) : 1 //here substep will b configured
  };
};

let propsValue = {};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    enableReinitialize: true,
    // validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      if (props.finalSubmitCheck === 3) {
        try {
          props.validateFinalSubmit(
            formValuesToAPI(toSnakeCase(props.cachedData.cacheData)),
            setSubmitting
          );
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          errToastMessage('The form was not succesfully submitted. Please try again.');
        }
      } else {
        props.nextStep(true);
      }
    },
    displayName: 'Address'
  })
);
