import * as Yup from 'yup';
import {
  employeeIdValidationSchema,
  getEmailValidationSchema,
  getNameValidationSchema,
  getUANValidationSchema
} from '../../../utils/ValidationSchema';
import { getTrimmedValue, isValidPhoneNumber } from '../../../utils/utilities';

export default Yup.object().shape({
  name: getNameValidationSchema({ message: 'name', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: false, isNullable: false }).when('formConfig', {
    is: (formConfig) => {
      return !formConfig.isEmailOptional;
    },
    then: getEmailValidationSchema({ isRequired: true, isNullable: false })
  }),
  phoneNumber: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .required('Phone Number cannot be blank'),
  consentDoc: Yup.string().when('consent', {
    is: false,
    then: Yup.string().required('Consent cannot be blank')
  }),
  resumeLink: Yup.string().when('formConfig', {
    is: (formConfig) => !formConfig.isResumeOptional,
    then: Yup.string().required('Resume cannot be blank')
  }),
  employeeId: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .when('employeeIdExists', {
      is: true,
      then: Yup.string('Employee Id already exists'),
      otherwise: employeeIdValidationSchema({
        message: 'Employee ID',
        isRequired: false
      })
    }),
  uanNumber: getUANValidationSchema({ isNullable: true }),
  serviceErrorAddress: Yup.string().when('serviceErrAddress', {
    is: true,
    then: Yup.string().required('Address Verification is needed for Court Verification to be done')
  }),
  serviceErrorEmployment: Yup.string().when('serviceErrEmployment', {
    is: true,
    then: Yup.string().required(
      'Employment verification needs to be checked to proceed with Education'
    )
  }),
  serviceErrorEducation: Yup.string().when('serviceErrEducation', {
    is: true,
    then: Yup.string().required(
      'Education verification needs to be checked to proceed with Employment'
    )
  })
});
