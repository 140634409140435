import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import styles from './AddCandidateButton.module.scss';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';



const AddCandidateButton = ({ history }) => {

    const showCandidateTooltip = new URLSearchParams(location.search)?.get('showCandidateTooltip');
    const user = useSelector((s) => s?.profile?.profile);
    const [showIndicator, setShowIndicator] = useState(false);
    const [defaultTooltipState, setDefaultTooltipState] = useState(showCandidateTooltip ? true : false);

    useEffect(() => {
        if (user?.id) {
            const isVisited = localStorage.getItem('isVisitedAddCandidate' + user.id);
            setShowIndicator(isVisited ? false : true)
        }
    }, [user]);

    const handleDropdownToggle = (isOpen) => {
        if (isOpen) {
            localStorage.setItem('isVisitedAddCandidate' + user.id, 'yes');
            setShowIndicator(false)
        }
    }

    const handleNavigateToCreateCandidate = () => {
        history?.push('/candidate/create')
    }

    const handleNavigateToBulkUpload = () => {
        history?.push('/bulkCandidate')
    }

    return (
        <>
            <Dropdown as={ButtonGroup} onToggle={handleDropdownToggle} alignRight className={styles.splitButton}>
                <Button onClick={handleNavigateToCreateCandidate} className={styles.addCandidatePrimaryBtn}>+ Add Candidate</Button>
                <OverlayTrigger
                    key={'addCandidatePrimaryBtnTT'}
                    placement={'top-end'}
                    defaultShow={defaultTooltipState}
                    overlay={
                        <Tooltip className={styles.ACTooltip + ' darkTooltip'}>
                            Add Candidates in Bulk
                        </Tooltip>
                    }
                >
                    <Dropdown.Toggle className={styles.addCandidateDropdownBtn} split id="addCandidateButton">
                        {showIndicator && <span className={styles.indicator}></span>}
                    </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu className={styles.dropdownMenu}>
                    <Dropdown.Item onClick={handleNavigateToBulkUpload}>+ Add Candidates in Bulk</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default withRouter(AddCandidateButton);
