import React, { useEffect } from 'react';

import localStorage from '../../../api/localStorage';
import AppConstants from '../../../core-components/AppConstants';

/**
 * To view Authenticated document in report
 * Authenticated with required params to dev server when external links are clicked in php(as CA is not authenticated in php)
 */
export default (props) => {
  useEffect(() => {
    let documentParams = props.location.search;
    let token = localStorage.getToken();
    let companyId = props?.profile?.profile?.company_user_mapping?.company_id;
    let baseUrl = AppConstants.developmentAPIURL;
    window.open(
      `${baseUrl + 'auth/image' + documentParams + '&companyId=' + companyId + '&token=' + token}`,
      '_self'
    );
  }, []);
  return <></>;
};
