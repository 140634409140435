import React, { useEffect, useState, createContext } from 'react';
import { Card } from 'react-bootstrap';
import _, { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { errToastMessage, toCamelCase, validateCategoriesAndTags } from '../../../utils/utilities';

import Loader from '../../../core-components/Loader';
import { isCustomisable } from '../../../utils/utilities';
import PaymentResults from '../../../core-components/PaymentResults';
import {
  submitAddCandidateConfig,
  candidatesBulkUpload,
  getAddCandidateConfig as getAddCandidateConfigAPI
} from '../../../api/company';
import { UPLOAD_STATE } from '../../../utils/commonConstant';

import BasicDetails from './BasicDetails';
import PackageListing from './PackageListing';
import SelectedPackage from './SelectedPackage';
import Step3Values from './Step3IntialJson';
import formatter from './CreateCandSubmit.formatter';

import styles from './CreateCandHOC.module.scss';
import AccessRestricted from './AccessRestricted/AccessRestricted';
export const getCandidateConfigContext = createContext();
import { Button } from 'react-bootstrap';

export default (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryTypeId, setCategoryTypeId] = useState(null);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [categoriesOrTagsDeleted, setCategoriesOrTagsDeleted] = useState({});
  const [isGoingBackToStep1, setIsGoingBackToStep1] = useState(false);
  const [uploadState, setUploadState] = useState(UPLOAD_STATE.NOT_UPLOADED);
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Setting step3 initial values
    setValues({ step3: JSON.parse(JSON.stringify(Step3Values)) });

    // if navigated to this page from add bulk candidate flow then render step = 2, directly
    if (props && props?.finalData?.isBulkUpload && props?.finalData?.step === 2) {
      const currentStepFromBulkUpload = props && props?.finalData?.step;
      const categoryTypeId = props && props?.finalData?.categoryTypeId;
      const tableData = props && props?.finalData?.tableData;
      const companyId = props && props?.finalData?.companyId;
      const fileId = props?.fileId || null;
      currentStepFromBulkUpload ? setCurrentStep(currentStepFromBulkUpload) : setCurrentStep(1);
      setCategoryTypeId(categoryTypeId);
      setIsBulkUpload(true);
      setTableData(tableData);
      setCompanyId(companyId);
      setFileId(fileId);
    }
  }, []);

  useEffect(() => {
    setValues({ ...values, step3: JSON.parse(JSON.stringify(Step3Values)) });
  }, [selectedPackageId]);

  useEffect(() => {
    if (isBulkUpload) {
      if (isSubmitting) {
        props?.setMsg({
          load: true,
          msg: 'Adding Candidates...'
        });
      } else {
        props?.setMsg({
          load: false,
          msg: ''
        });
      }
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isEmpty(categoriesOrTagsDeleted)) {
      setLoading(true);
      props
        .getAddCandidateConfig()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          errToastMessage(error);
          setLoading(false);
        });
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (currentStep === 2) {
      setLoading(true);
      props
        .getAddCandidateConfig()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          errToastMessage(error);
          setLoading(false);
        });
    }
  }, [currentStep]);

  useEffect(() => {
    if (paymentStatus == 'failed') setCurrentStep(4);
  }, [paymentStatus]);

  useEffect(() => {
    if (props.createCandConfig && !isEmpty(props.createCandConfig)) {
      if (
        props.createCandConfig.is_adding_candidate_allowed !== undefined &&
        props.createCandConfig.is_adding_candidate_allowed === false
      )
        setCurrentStep(5);
    }
  }, [props?.createCandConfig]);

  const handleSelectedSubtypeId = (subtypeId) => {
    setSelectedPackageId(subtypeId);
    setSelectedPackage(
      props.createCandConfig.packages.filter((pckg) => pckg.subtypeId === subtypeId)[0]
    );
  };
  //Here : A STEP SUBMIT
  const handleStepSubmit = (type, formValues, nextStep = null) => {
    let currentValues = { ...values };

    /**
     if condition is added to handle TDS value change and save it in state so that 
     saved value can be used to select the tds radio option when uses goes forward and backward in flow i.e to maintain the state
     *  */
    if (typeof formValues === 'object' && formValues.hasOwnProperty('tdsValue')) {
      currentValues[type] = { ...currentValues[type], tdsValue: formValues?.tdsValue };
    } else {
      for (let index = 0; index < type.length; index++) {
        currentValues[type[index]] = formValues[index];
      }
    }

    setValues(currentValues);

    if (nextStep && nextStep !== 'submit' && currentStep !== nextStep) {
      handleStepChange(nextStep);
    } else if (nextStep === 'submit') {
      startSubmit();
    }
  };

  const handleStepChange = (step, isNotCustom = false) => {
    if (step === 3 && values.step2?.customise && isNotCustom) {
      setValues({ ...values, step2: null });
    }

    setCurrentStep(step);
    // this is to cehck if we going back to basic detials page or not
    if (step === 1) {
      setIsGoingBackToStep1(true);
    } else {
      setIsGoingBackToStep1(false);
    }
  };

  const getDaysDiffForReferralBanner = (storedMonth) => {
    const currentMonth = new Date()?.getMonth()?.toString();

    return storedMonth !== currentMonth ? true : false;
  };

  //Here : Final SUBMIT
  const startSubmit = () => {
    if (props.createCandConfig && !isEmpty(props.createCandConfig)) {
      setIsSubmitting(true);
      const { addons } = props.createCandConfig;
      const payload = formatter(
        values,
        selectedPackage,
        addons,
        props.createCandConfig.includeAadhaarInIdCheck === 1 ? true : false
      );
      delete payload.candidate['resumeFile'];
      delete payload.candidate['consentFile'];
      if (payload?.candidate?.altPhone) {
        payload.candidate['phone_numbers'] = [];
        payload.candidate['phone_numbers'].push({
          belongs_to: 'CANDIDATE',
          data: payload?.candidate?.altPhone
        });
      }
      if (isBulkUpload) {
        payload['candidates'] = tableData;
        payload['category_id'] = categoryTypeId;
        payload['invite'] = true;
        payload['company_id'] = companyId;
        if (fileId) {
          payload['file_id'] = fileId;
        }
        delete payload['candidate'];

        candidatesBulkUpload(payload)
          .then((resp) => {
            toast.success('Candidates are created successfully');
            props.history.replace(location.pathname, null);
            // Month is stored in LS starting from the index 0
            const referModalLastShownMonth = localStorage.getItem('referModalLastShownMonth');
            const currentMonth = new Date()?.getMonth();
            if (
              !referModalLastShownMonth ||
              getDaysDiffForReferralBanner(referModalLastShownMonth)
            ) {
              localStorage.setItem('referModalLastShownMonth', currentMonth);
              props.history.push({
                pathname: '/candidates',
                search: `?_showReferModal=true`
              });
            } else {
              props.history.push({
                pathname: '/candidates'
              });
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            errToastMessage(error);
          });
      } else {
        checkCategoriesAndTagsAreValid(payload);
      }
    }
  };

  const checkCategoriesAndTagsAreValid = async (payload) => {
    const values = payload?.candidate;
    const selectedCategoryId = values?.category_id;
    const selectedTags = values?.tags;

    if (!selectedCategoryId && (!selectedTags || selectedTags.length === 0)) {
      submitIndividualCandidate(payload);
      setIsSubmitting(true);
      return;
    }

    try {
      const response = await getAddCandidateConfigAPI(companyId);
      const config = response?.data || {};
      const categoriesList = config?.categories;
      const tags = config?.tags;
      const res = validateCategoriesAndTags(values, categoriesList, tags);
      if (isEmpty(res)) {
        submitIndividualCandidate(payload);
        setIsSubmitting(true);
        return;
      }
      setCategoriesOrTagsDeleted(res);
      handleStepChange(1);
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
      console.error('handleContinue Error: ', err);
    }
  };

  const submitIndividualCandidate = (payload) => {
    submitAddCandidateConfig(payload)
      .then((resp) => {
        setIsSubmitting(false);
        let candidateId = toCamelCase(resp.data.data).candidate.id;
        props.history.push({
          pathname: '/viewCandidate',
          search: `?candidateId=${candidateId}`,
          state: values?.step1?.invite === true ? 'invite' : 'candidateAdded'
        });
      })
      .catch((err) => {
        setIsSubmitting(false);
        errToastMessage(err);
      });
  };

  const getFormBasedOnStep = (step) => {
    switch (step) {
      case 1:
        return (
          <BasicDetails
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            uploadState={uploadState}
            setUploadState={setUploadState}
            file={file}
            setFile={setFile}
            ctError={categoriesOrTagsDeleted}
            setCtError={setCategoriesOrTagsDeleted}
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
            isGoingBackToStep1={isGoingBackToStep1}
          />
        );
      case 2:
        return (
          <PackageListing
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            handleStepChange={(step, isNotCustom = false) => handleStepChange(step, isNotCustom)}
            savedValues={values && values.step2 ? values.step2 : null}
            packageList={props.createCandConfig.packages || []}
            handleSelectedSubtypeId={handleSelectedSubtypeId}
            selectedPackageId={selectedPackageId}
            selectedPackage={selectedPackage}
            isBulkUpload={isBulkUpload}
            bulkCandidates={tableData?.length}
            history={props?.history}
            goBackToBulkUpload={() => {
              props?.finalData?.setCurrentStep(2);
            }}
          />
        );
      case 3:
        return (
          <getCandidateConfigContext.Provider value={props?.getAddCandidateConfig}>
            <SelectedPackage
              handleStepSubmit={(type, formValues, nextStep) =>
                handleStepSubmit(type, formValues, nextStep)
              }
              handleStepChange={(step) => handleStepChange(step)}
              savedValues={values && values.step3 ? values.step3 : null}
              customizeValues={
                values && values.step2 && values.step2.customise ? values.step2.customise : null
              }
              step1Values={values && values.step1 ? values.step1 : null}
              pDetails={
                props.createCandConfig && props.createCandConfig.packages
                  ? {
                      ...selectedPackage,
                      isCustomisable: isCustomisable(selectedPackage.config)
                    }
                  : null
              }
              initialValues={Step3Values}
              setPaymentStatus={(value) => {
                // is payment is successfull call submit funtion to add candidate
                setPaymentStatus(value);
                if (value === 'completed') {
                  startSubmit();
                }
              }}
              isSubmitting={isSubmitting}
              isBulkUpload={isBulkUpload}
              bulkCandidates={tableData?.length}
            />
          </getCandidateConfigContext.Provider>
        );
      case 4:
        return (
          <PaymentResults
            paymentStatus={paymentStatus}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setPaymentStatus={setPaymentStatus}
            description={
              paymentStatus == 'failed' && (
                <p>Something went wrong. Please try again in a few minutes.</p>
              )
            }
            isBulkUpload={isBulkUpload}
          />
        );
      case 5:
        return <AccessRestricted setCurrentStep={setCurrentStep} />;
      default:
        return (
          <BasicDetails
            handleStepSubmit={(type, formValues, nextStep) =>
              handleStepSubmit(type, formValues, nextStep)
            }
            uploadState={uploadState}
            setUploadState={setUploadState}
            file={file}
            setFile={setFile}
            handleStepChange={(step) => handleStepChange(step)}
            savedValues={values && values.step1 ? values.step1 : null}
          />
        );
    }
  };

  const getStepHeading = (step) => {
    switch (step) {
      case 1:
        return 'Add Candidate Details';
      case 2:
        return 'Choose a verification package';
      case 3:
        return 'Customize verification';
      default:
        return 'Add Candidate';
    }
  };

  const stepShow = ['Add Details', 'Choose Package', 'Customize and Confirm'];

  return (
    <div className={styles.candWrapperWrapper}>
      <div className={styles.subHeading}>
        <h4 className={styles.header}>Create Candidate</h4>
        {currentStep === 1 && (
          <Button
            className={styles.addinBulk}
            onClick={() => {
              window.open('/bulkCandidate', '_blank');
            }}
          >
            + Add Candidates in Bulk
          </Button>
        )}
      </div>
      <div className={styles.cardSection}>
        {!loading ? (
          <Card
            className={`${styles.addCandidateCard} ${
              paymentStatus === 'failed' ? styles.failedCard : ''
            } ${props?.finalData?.isBulkUpload ? styles.bulkUploadCard : ''}`}
            border={props?.finalData?.isBulkUpload ? '' : 'light'}
          >
            <div className={styles.headerx}>
              <div className={styles.left} style={{ display: 'flex' }}>
                {getStepHeading(currentStep)}
              </div>
              <div className={styles.right}>
                {stepShow.map((step, index) => (
                  <>
                    <div
                      key={index}
                      className={styles.step}
                      style={{
                        color:
                          index < currentStep - 1
                            ? '#8d8d8d'
                            : index === currentStep - 1
                            ? 'black'
                            : '#8d8d8d'
                      }}
                    >
                      {step}
                    </div>
                    {index < stepShow.length - 1 && (
                      <div
                        className={styles.spacer}
                        style={{
                          color: index < currentStep - 1 ? '#8d8d8d' : '#8d8d8d'
                        }}
                      >
                        {'>'}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
            {getFormBasedOnStep(currentStep)}
          </Card>
        ) : (
          <Card
            className={`${styles.loadingCard} ${
              props?.finalData?.isBulkUpload ? styles.bulkUploadCard : ''
            }`}
            border={props?.finalData?.isBulkUpload ? '' : 'light'}
          >
            <Loader className={styles.loader} />
          </Card>
        )}
      </div>
    </div>
  );
};
