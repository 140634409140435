import React, { useEffect, useState } from 'react';

import styles from './AddBulkExEmp.module.scss';
import { ADMIN_URL } from '../core-components/Utilities/CommonConstants';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import AppConstants from '../../../../core-components/AppConstants';
import cn from 'classnames';

import BulkUploader from '../BulkUploader/BulkUploader';
const AddBulkExEmp = (props) => {
  const [stepOne, setStepOne] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('id');

  /**
   * UseEffects
   */
  useEffect(() => {
    setStepOne(true);
  }, []);

  const onModalClose = (isCancelled) => {
    if (!isCancelled) {
    } else {
      if (props?.isSA) {
        props?.history.push({
          pathname: '/company/alumniVerification',
          search: `?id=${companyId}`
        });
      } else {
        props?.history.push('/alumni-verification');
      }
    }
    setStepOne(false);
  };
  return (
    <div className={cn(`${styles.alumniWrapperWrapper}`, props?.isSA ? styles.saContainer : '')}>
      <div className={styles.subHeading}>
        <h4 className={styles.header}>{'Alumni Verification'}</h4>
      </div>
      {/* <StepTwo handleStepOne={() => setStepOne(true)} />
      {stepOne && <StepOne show={stepOne} onClose={(isCancelled) => onModalClose(isCancelled)} />} */}
      <BulkUploader />
    </div>
  );
};

export default AddBulkExEmp;
