// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.fKr13UCPQcfY492RmCGr {
  color: #ffffff;
  text-align: left;
}
.fKr13UCPQcfY492RmCGr p:first-child {
  font-size: 16px;
}
.fKr13UCPQcfY492RmCGr p:last-child {
  font-weight: normal;
  font-size: 14px;
}

.uElDlTHvEKvjUIngnvJT {
  display: flex;
  justify-content: space-between;
  font-size: 16px !important;
  font-style: normal !important;
}
.uElDlTHvEKvjUIngnvJT .zja1SNDiDJ7PXnjsfrBg {
  color: #9eb3ff;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0;
  letter-spacing: -1px;
}

.bUDmn6FwfklIPO3DfF4Q {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
}

.QMkF6N5J46LkSgc9W56p .ofNMelKhsHS_476UQNjB {
  color: #333333 !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/CommonComponent/BGVCamera/BGVCamera.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,mBAAA;EACA,eAAA;AAEJ;;AACA;EACE,aAAA;EACA,8BAAA;EACA,0BAAA;EACA,6BAAA;AAEF;AADE;EACE,cAAA;EACA,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,UAAA;EACA,oBAAA;AAGJ;;AAAA;EACE,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AAGF;;AACE;EACE,yBAAA;EACA,kBAAA;EACA,8BAAA;EACA,0BAAA;AAEJ","sourcesContent":["* {\n  font-family: Poppins, sans-serif;\n}\n\n.bgvCameraFooterTextContainer {\n  color: #ffffff;\n  text-align: left;\n  p:first-child {\n    font-size: 16px;\n  }\n  p:last-child {\n    font-weight: normal;\n    font-size: 14px;\n  }\n}\n.bgvCameraFooterButtonContainer {\n  display: flex;\n  justify-content: space-between;\n  font-size: 16px !important;\n  font-style: normal !important;\n  .retakeBtn {\n    color: #9eb3ff;\n    font-weight: 600;\n    border: none;\n    background: transparent;\n    padding: 0;\n    letter-spacing: -1px;\n  }\n}\n.confirmBtn {\n  background: #2755fe;\n  color: #ffffff;\n  cursor: pointer;\n  font-weight: 500;\n  border-radius: 2px;\n}\n\n.bgvCameraErrorBodyContainer {\n  .bgvCameraErrorTextContainer {\n    color: #333333 !important;\n    font-style: normal;\n    font-weight: normal !important;\n    font-size: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvCameraFooterTextContainer": `fKr13UCPQcfY492RmCGr`,
	"bgvCameraFooterButtonContainer": `uElDlTHvEKvjUIngnvJT`,
	"retakeBtn": `zja1SNDiDJ7PXnjsfrBg`,
	"confirmBtn": `bUDmn6FwfklIPO3DfF4Q`,
	"bgvCameraErrorBodyContainer": `QMkF6N5J46LkSgc9W56p`,
	"bgvCameraErrorTextContainer": `ofNMelKhsHS_476UQNjB`
};
export default ___CSS_LOADER_EXPORT___;
