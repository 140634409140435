import React from 'react';
import Card from 'react-bootstrap/Card';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import styles from '../Dashboard.module.scss';
import AppConstants from '../../../../core-components/AppConstants';

export default withRouter((props) => {
  return (
    <div className={styles.dashboardCardMain}>
      {props.options &&
        Object.keys(props.options).map((key, i) => {
          const option = props.options[key];
          return (
            <a
              onClick={() => {
                props?.history.push(`candidates?_type=${key}`);
              }}
            >
              <Card key={i} className={styles.dashboardCardParent}>
                <div className={styles.dashboardCard}>
                  <h4>{option}</h4>
                  <p>{_.startCase(key)}</p>
                </div>
              </Card>
            </a>
          );
        })}
    </div>
  );
});
