import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    profile: state?.profile || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  // Leaving empty, in future if we need to dispatch any action we can add here
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
