import { connect } from 'react-redux';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { withFormik } from 'formik';

import validationSchema from './FilterModal.validation';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {};

      return mergeWith(
        {
          toDate: null,
          fromDate: null
        },
        storedValues,
        (a, b) => (b === null ? a : b)
      );
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: () => {},
    displayName: 'Set custom range'
  })
);
