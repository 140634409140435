// Import libraries
import React from 'react';

// Import core-components
import AppConstants from '../../../core-components/AppConstants';

// Import frontend-common
import CandidateAnalytics from '../frontend-common/CandidateAnalytics';

function CandidateAnalyticsWrapper() {
  const env = AppConstants.env;

  return (
    <div>
      <CandidateAnalytics env={env} />
    </div>
  );
}

export default CandidateAnalyticsWrapper;
