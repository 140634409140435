import React, { useState, useEffect } from 'react';

import { Accordion, Card, ListGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../../../core-components/Image';
import CustomCounter from '../../../../../../../core-components/CustomCounter';

import {
  PLAIN_CHECK_ICONS,
  CHECKS_LIMIT,
  COURT_OPTIONS_FOR_ADDON_CONFIG,
  COURT_VERIFICATION_TYPES
} from '../../../../../../../utils/commonConstant';
import {
  convertToIndianNumeration,
  filterObjectByKeyCaseInsensitive
} from '../../../../../../../utils/utilities';
import styles from './Court.module.scss';

function Court(props) {
  const { values, onValueChange, pDetails, courtCheckCount, addonDetails } = props;

  const [courtCount, setCourtCount] = useState(0);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState('');
  const [verificationTypes, setVerificationTypes] = useState([]);

  useEffect(() => {
    checkTypeAndAddonConfig();
  }, [props]);

  const checkTypeAndAddonConfig = () => {
    if (courtCheckCount == 1 && pDetails && pDetails[0]?.verificationType) {
      const typeIncluded = 'COURT_' + (pDetails && pDetails[0]?.verificationType) || '';
      // COURT_CONFIG = ANY_1 || CURRENT || PERMANENT
      // Verification types are based on the company addon configuration and what is present for that court check in the package
      // using this method, replace(/_/g, ''), because in addons we are getting the key as standardlawfirm instead of standard_lawfirm
      setSelectedVerificationMethod(typeIncluded);
      if (
        Object.keys(addonDetails).some(
          (ele) => ele.toLowerCase() == typeIncluded.replace(/_/g, '').toLowerCase()
        )
      ) {
        setVerificationTypes(
          COURT_VERIFICATION_TYPES.filter(
            (obj) => obj.name.toLowerCase() == typeIncluded.toLowerCase()
          )
        );
      }
    } else if (courtCheckCount === 0) {
      const filteredVerificationType = COURT_VERIFICATION_TYPES.filter((obj) =>
        Object.keys(addonDetails).some(
          (ele) => ele.toLowerCase() == obj.name.replace(/_/g, '').toLowerCase()
        )
      );
      if (filteredVerificationType.length == 1) {
        // Verification type should be by default selected if only one verification type is present
        setSelectedVerificationMethod(filteredVerificationType[0]?.name);
      } else if (values?.config && values?.config.length > 0) {
        setSelectedVerificationMethod(values?.config[0]?.verificationType);
      }
      // Verification types are based on the company addon configuration and what is present for that court check in the package
      setVerificationTypes(filteredVerificationType);
    }
  };

  useEffect(() => {
    if (courtCheckCount) {
      setCourtCount(courtCheckCount);
    }
  }, [courtCheckCount]);

  const handleCourtCheckConfigChange = (event) => {
    const arr = [...values.config];
    if (event.target.checked) {
      setCourtCount((prevCount) => prevCount + 1);
      arr.push({ type: event.target.value, verificationType: selectedVerificationMethod });
    } else {
      setCourtCount((prevCount) => prevCount - 1);
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].type === event.target.value) {
          arr.splice(i, 1);
        }
      }
    }

    if (verificationTypes.length == 1) {
      // If only one verification method.type is present assign that to (all) address
      arr.forEach((obj) => {
        obj['verificationType'] = verificationTypes[0]['name'];
      });
    }
    if (arr.length == 0) {
      //unselecting the radio button if no address addons is selected
      setSelectedVerificationMethod('');
    }
    onValueChange('config', arr);
  };

  const handleVerificationTypeChange = (type) => {
    // Assiging same verification method/type for (all) crt
    const arr = [...values.config];
    arr.forEach((obj) => {
      obj['verificationType'] = type;
    });
    onValueChange('config', arr);
  };

  const priceInfoContainer = (stringText, price) => {
    switch (stringText) {
      case 'start':
        return (
          <span className={styles.startFrom}>
            {'Starts from '}
            <span className={styles.rupee}>&#8377;</span>
            {price && convertToIndianNumeration(price || 0)}
          </span>
        );
      default:
        return (
          <span className={styles.startFrom}>
            <span className={styles.rupee}>&#8377;</span>
            {price && convertToIndianNumeration(price || 0)}
            {' per check'}
          </span>
        );
    }
  };

  const priceInfoHandle = () => {
    const filteredObj = filterObjectByKeyCaseInsensitive(
      addonDetails,
      selectedVerificationMethod.replace(/_/g, ''),
      true
    );

    if (verificationTypes.length == 1 || selectedVerificationMethod) {
      // If only one verification method/type is present or any radio button is selected we need to show per check price
      return priceInfoContainer('', Object.values(filteredObj)[0]?.price);
    } else {
      // Minimum cost among all the verification method
      const price = Object.values(addonDetails).map((item) => item.price);
      const minValue = Math.min(...price);
      return priceInfoContainer('start', minValue);
    }
  };

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={styles.addonsContainerHeader}
            onClick={() => decoratedOnClick(0)}
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS['court'] ? PLAIN_CHECK_ICONS['court'].icon : faBan}
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <span>{props?.heading}</span>
              <div className={styles.headingRight}>
                {priceInfoHandle()}
                {activeAccordion === 0 ? (
                  <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                ) : (
                  <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <ListGroup className={styles.listGroup}>
                {COURT_OPTIONS_FOR_ADDON_CONFIG.map((item, index) => {
                  return pDetails && !pDetails.some((obj) => obj.type === item.name) ? (
                    <ListGroup.Item className={styles.listGroupItem}>
                      <Form.Group controlId={`${item.id}`} className={styles.formGroup}>
                        <Form.Check
                          name={`${item.name}`}
                          id={`${item.id}`}
                          type='checkbox'
                          value={item.name}
                          checked={values?.config?.some((obj) => obj.type === item.name)}
                          disabled={
                            CHECKS_LIMIT.COURT - courtCount === 0 &&
                            !values?.config?.some((obj) => obj.type === item.name)
                          }
                          onChange={(e) => {
                            handleCourtCheckConfigChange(e);
                          }}
                          label={<span className={styles.label}>{`${item.label}`}</span>}
                        />
                      </Form.Group>
                    </ListGroup.Item>
                  ) : null;
                })}
              </ListGroup>
              <ListGroup className={styles.listGroup}>
                <div className={styles.headingDiv}>
                  {'Choose verification type:'} <span className={styles.required}>*</span>
                </div>
                <div className='mt-2'>
                  <Form.Group controlId='crtType' className={styles.formGroup}>
                    {values &&
                      verificationTypes.map((type, index) => {
                        return (
                          <Form.Check
                            type={'radio'}
                            className={styles.formCheck}
                            key={`crtType-${index}`}
                          >
                            <Form.Check.Input
                              type={'radio'}
                              name={type.name}
                              id={type.name}
                              disabled={values?.config && !values?.config.length}
                              onChange={(e) => {
                                setSelectedVerificationMethod(e.target.value);
                                handleVerificationTypeChange(e.target.value);
                              }}
                              checked={
                                selectedVerificationMethod === type.name ||
                                verificationTypes.length == 1 ||
                                values?.config?.some((obj) => obj.verificationType === type.name)
                              }
                              value={type.name}
                            />
                            <Form.Check.Label htmlFor={type.name}>{type.label}</Form.Check.Label>
                          </Form.Check>
                        );
                      })}
                  </Form.Group>
                </div>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Court;
