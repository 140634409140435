import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';

import { toCamelCase } from '../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  setBgvSubmission as setBgvSubmissionAPI,
  invalidateEmpDocType as invalidateEmpDocTypeAPI,
  setEmpDocType as setEmpDocTypeAPI,
  invalidateGradesheetUpload as invalidateGradesheetUploadAPI,
  setGradesheetUpload as setGradesheetUploadAPI
} from '../../../../../actions/bgv';

const noOfValidDetail = (educationCheck) => {
  let count = 0;
  for (let i in educationCheck) {
    if (Object.keys(educationCheck[i]).length === 3) {
      count++;
    }
  }
  return count;
};

const checkEmploymentSaved = (bgvCachedData) => {
  if (
    bgvCachedData &&
    bgvCachedData.cacheData &&
    bgvCachedData.cacheData.employmentCheck &&
    bgvCachedData.cacheData.employmentCheck.data &&
    bgvCachedData.cacheData.employmentCheck.data.length
  ) {
    return noOfValidDetail(bgvCachedData.cacheData.employmentCheck.data);
  } else {
    return 0;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data)),
    setGradesheetUpload: (data) => dispatch(setGradesheetUploadAPI(data)),
    setEmpDocType: (data) => dispatch(setEmpDocTypeAPI(data)),
    invalidateEmpDocType: () => dispatch(invalidateEmpDocTypeAPI()),
    invalidateGradesheetUpload: () => dispatch(invalidateGradesheetUploadAPI())
  };
};

const mapStateToProps = (state) => {
  let currentState = !state.bgvCachedData.data.current_state
    ? 1
    : state.bgvCachedData.data.current_state;
  let currentStateMap = currentState.split('#');
  const employmentSaved = checkEmploymentSaved(toCamelCase(state.bgvCachedData.data));

  return {
    cached_data: !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data },
    isLoadingBgvCache: state.bgvCachedData.loading,
    currentSubStep:
      sessionStorage.getItem('reload') === 'false'
        ? !isEmpty(currentStateMap[1])
          ? parseInt(currentStateMap[1])
          : 1
        : 1,
    employmentSaved,
    employmentCheck:
      state?.bgvCachedData?.data?.cache_data?.employment_check?.data?.length > 0
        ? state.bgvCachedData.data.cache_data.employment_check.data
        : null,
    bgvPackageConfig: state.bgvPackageConfig ? state.bgvPackageConfig.data || null : null
  };
};
let propsValue = {};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        no_of_previous_jobs: 0
      };
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    // validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      const getRefEmpCount = () => {
        let totalRefEmpCount = 0;
        let refEmpArray = [];
        let actualRefCount = 0;
        let empToCollect = 0;
        if (
          props?.bgvPackageConfig?.config?.reference?.length &&
          props?.bgvPackageConfig?.config?.reference?.length >
            props?.bgvPackageConfig?.config?.employment
        ) {
          // refEmpArray = [1, 0];
          refEmpArray = props.bgvPackageConfig.config.reference;

          // && parseInt(refEmpArray[refEmpArray.length - 1]) > 0
          actualRefCount = refEmpArray.reduce((a, b) => Number(a) + Number(b));
          if (actualRefCount > 0) {
            refEmpArray.forEach((ref, index) => {
              if (
                parseInt(ref) > 0 ||
                (totalRefEmpCount < actualRefCount && index < refEmpArray.length - 1)
              ) {
                totalRefEmpCount += parseInt(ref);
                empToCollect++;
              }
            });
          }
          return empToCollect;
        } else {
          return props.bgvPackageConfig.config.employment;
        }
      };
      const noOfEmploymentToSave = props.bgvPackageConfig ? parseInt(getRefEmpCount() || 0) : 0;
      if (noOfEmploymentToSave === 0) {
        props
          .setBgvCache(props?.cached_data)
          .then(() => {
            setSubmitting(false);
            // setTimeout(() => {
            props.nextStep(false, props.stepCurrentIndex + 1);
            // }, 1000);
          })
          .catch((error) => {
            setSubmitting(false);
            console.error(error);
          });
      } else {
        setSubmitting(false);
        props.nextStep(true);
      }
    },
    displayName: 'Employment Details'
  })
);
