import * as Yup from 'yup';
import { getPasswordValidationchema } from '../../utils/ValidationSchema';

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || 'Path must be same as Reference',
    params: {
      reference: ref.path
    },
    test: function (value) {
      return value === this.resolve(ref);
    }
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export default Yup.object().shape({
  password: getPasswordValidationchema({ isRequired: true }),
  confirmPassword: Yup.string()
    .equalTo(Yup.ref('password'), 'Passwords do not match')
    .required('*Required')
});
