export const GENDER = {
  1: 'Male',
  2: 'Female',
  3: 'Not Specified'
};

/**
 * in this function,
 *  if hasTwoColumnsForName checkbox checked -> then add fullName value to list back and make it empty in state
 *  else hasTwoColumnsForName checkbox un-checked -> then add firstName and lastName value to list back and make them empty in state
 */
export const twoColumnsForName = ({ values, dropDownValues, setFieldValue }) => {
  let dropDownListValues = getFilteredArray([...dropDownValues]);
  if (values.hasTwoColumnsForName) {
    // pushing back fullName to list
    values['fullName'] ? dropDownListValues.push(values['fullName']) : null;

    // after pushing back empty the state for mapping values to table
    values['fullName'] ? setFieldValue('fullName', '') : null;
  } else {
    // pushing back values to list
    values['firstName'] ? dropDownListValues.push(values['firstName']) : null;
    values['lastName'] ? dropDownListValues.push(values['lastName']) : null;

    // after pushing back empty the state for mapping values to table
    values['firstName'] ? setFieldValue('firstName', '') : null;
    values['lastName'] ? setFieldValue('lastName', '') : null;
  }

  return dropDownListValues;
};

//left menu dropdown values
export const dropDownValuesHandler = ({ values, fieldName, dropDownValues, valueSelected }) => {
  let dropDownListValues = [...dropDownValues]; // copy of  drop-down list

  /** if case: selected value is None and filedValue is not None then push back to list
   * else if case: value already exit, then add old value back to drop-down list and remove new selected value from drop-down list
   * else case : just remove new selected value from drop-down list
   */

  if (valueSelected === 'None') {
    if (values[fieldName] != 'None') {
      if (values[fieldName]) {
        dropDownListValues.push(values[fieldName]);
      }
    }
  } else if (values[fieldName]) {
    values[fieldName] != 'None' && dropDownListValues.push(values[fieldName]);

    const index = dropDownListValues.indexOf(valueSelected);
    dropDownListValues.splice(index, 1);
  } else {
    const index = dropDownListValues.indexOf(valueSelected);
    dropDownListValues.splice(index, 1);
  }

  return dropDownListValues;
};

export const continueHandler = ({ props, categoryTypeId, tableData, companyId }) => {
  // for SA side = props.saCompanyId passed from AddBulkCandidat.jsx file
  // for CA side = pick companyId from state
  const id = props?.isSA ? props?.saCompanyId : props?.companyId;
  props?.isCA
    ? props.history.push({
        pathname: '/candidate/create',
        state: { isBulkUpload: true, step: 2, categoryTypeId, tableData, companyId }
      })
    : props.history.push({
        pathname: '/company/candidate/create',
        search: `?company_id=${id}`,
        state: { isBulkUpload: true, step: 2, categoryTypeId, tableData }
      });
};

// mapping data for table respective to dropdown values
export const formatCandidateDetails = (data, values) => {
  const formatted = data.reduce(function (result, d, i) {
    if (i !== 0) {
      result.push({
        first_name: values?.hasTwoColumnsForName
          ? d[data[0].indexOf(values?.firstName)]
          : d[data[0].indexOf(values?.fullName)] || '',
        last_name: values?.hasTwoColumnsForName ? d[data[0].indexOf(values.lastName)] : '',
        phone: d[data[0].indexOf(values.phone)] || '',
        emp_id: d[data[0].indexOf(values.empId)] || '',
        email: d[data[0].indexOf(values.email)] || ''
      });
    }
    return result;
  }, []);
  return formatted;
};

// after editing values, map values for validate API call for BE validation
export const formatEditedDataForApi = (data) => {
  const formatted = data.map((d) => {
    var obj = {};
    Object.keys(d).forEach(function (key) {
      if (typeof d[key] === 'object' && !Array.isArray(d[key]) && d[key] !== null) {
        obj[key] = d[key].value || '';
      } else {
        obj[key] = d[key];
      }
    });
    return obj;
  });
  return formatted;
};

// creating filters radio options - (sablove table)
export const getRadioOptions = (buttonClicked, totalCount, errorCount, noErrorCount, tableData) => {
  const total = totalCount > 0 ? `(${totalCount})` : '';
  const error = errorCount > 0 ? `(${errorCount})` : '';
  const noError = noErrorCount > 0 ? `(${noErrorCount})` : '';

  let options = [
    { value: 'all', label: `All records ${total}`, disable: true },
    {
      value: 'withError',
      label: `Records with Errors ${error}`,
      disable: true
    },
    { value: 'withoutError', label: `Records without Errors ${noError}`, disable: true }
  ];
  if (buttonClicked && tableData?.length > 0) {
    options.forEach((opt) => {
      opt.disable = errorCount === 0 && opt.value === 'withError' ? true : false;
    });
  }
  return options;
};

// get rows with error
export const getDataWithError = (arrayValue, length = false) => {
  const dataWithError =
    arrayValue.length > 0 &&
    arrayValue.reduce(function (result, d, i) {
      for (const key of Object.keys(d)) {
        if (typeof d[key] === 'object' && !Array.isArray(d[key]) && d[key] !== null) {
          result.push({ ...d, indx: i });
          break;
        }
      }
      return result;
    }, []);
  return length ? dataWithError.length || 0 : dataWithError;
};

// get rows without error
export const getDataWithoutError = (newArray, length = false) => {
  const dataWithoutError =
    newArray.length > 0 &&
    newArray.reduce(function (result, d, i) {
      for (const key of Object.keys(d)) {
        if (typeof d[key] === 'object' && !Array.isArray(d[key]) && d[key] !== null) {
          return result;
        }
      }
      result.push(d);
      return result;
    }, []);
  return length ? dataWithoutError.length || 0 : dataWithoutError;
};

// validate required drop down values if any value is selected as None or not
export const validateFields = (values) => {
  return [
    values.fullName,
    values.firstName,
    values.lastName,
    values.phone,
    values.gender,
    values.email
  ].includes('None');
};

// get only rows with data - return non - empty rows
export const getNonEmptyRows = (data) => {
  const nonEmptyRows = data.reduce(function (result, d, i) {
    if (d.length > 0) {
      result.push(d);
    }
    return result;
  }, []);
  return nonEmptyRows;
};

// this funtion is to valid data
export const getIsValid = (tableData, values, val = false) => {
  const error1 = getDataWithError(tableData, true) === 0 && tableData.length ? false : true; // if any row has any error or not
  let error2 = validateFields(values); // checks for NONE value for dropdown

  if (val) {
    // this means we only want to check for NONE value
    return error2;
  }
  return error1 || error2;
};

export const getFilteredArray = (array) => {
  let resultArray = [];
  for (let value of array) {
    if (value) {
      resultArray.push(value);
    }
  }
  return resultArray;
};

export const checkEmptyFields = (values) => {
  // if 2 names - then they should not be empty string
  if (values?.hasTwoColumnsForName) {
    return !values?.firstName || !values?.lastName || !values?.phone || !values?.email;
  }
  // any required field should not be empty string
  return !values?.fullName || !values?.phone || !values?.email;
};
