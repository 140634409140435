import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import styles from './Loader.module.scss';

export default (props) => {
  return (
    <div className={styles.loader}>
      <Spinner data-testid='spinner' animation='border' {...props} />
    </div>
  );
};
