// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZAGQAlRc1Bl8BhnNoDHs {
  width: 105%;
  height: 30%;
  background: #f3fbff;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 18px;
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
}
.ZAGQAlRc1Bl8BhnNoDHs .X0o0iys5OSft5GXzJttd {
  font-size: 20px;
  font-style: normal;
  font-weight: 250;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.ZAGQAlRc1Bl8BhnNoDHs .XaKN6YW7u7zi58iZkT_8 {
  margin-left: -20px;
}
.ZAGQAlRc1Bl8BhnNoDHs .fjDPdlDvJfzpUV_Sk7x1 {
  color: #595959;
}
.ZAGQAlRc1Bl8BhnNoDHs .hSN9uOns6khxGQdfnSLB {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/Banner/Banner.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAAE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAEJ;AACE;EACE,kBAAA;AACJ;AACE;EACE,cAAA;AACJ;AACE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACJ","sourcesContent":[".bannerContainer {\n  width: 105%;\n  height: 30%;\n  background: #f3fbff;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 22px;\n  color: #595959;\n  padding: 18px;\n  display: flex;\n  margin-bottom: 25px;\n  margin-top: 25px;\n  .heading {\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 250;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #333333;\n  }\n\n  .listItems {\n    margin-left: -20px;\n  }\n  .listItem {\n    color: #595959;\n  }\n  .image {\n    margin-right: 10px;\n    height: 20px;\n    width: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `ZAGQAlRc1Bl8BhnNoDHs`,
	"heading": `X0o0iys5OSft5GXzJttd`,
	"listItems": `XaKN6YW7u7zi58iZkT_8`,
	"listItem": `fjDPdlDvJfzpUV_Sk7x1`,
	"image": `hSN9uOns6khxGQdfnSLB`
};
export default ___CSS_LOADER_EXPORT___;
