import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { isEmpty, mergeWith } from 'lodash';
import { saveFieldMapping, triggerIntegrationStage } from '../../../../../../api/company';
import { toast } from 'react-toastify';
import { errToastMessage } from '../../../utils/Utilities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        stayingPeriod: '',
        tags: {}
      };

      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting }) => {
      const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

      let statusForm = { tags: false, trigger: false };
      let subtypesMapping = [];
      for (const [key, value] of Object.entries(values.tags)) {
        if (!isEmpty(value)) {
          subtypesMapping.push({
            fieldName: 'tag',
            subtype_id: parseInt(key),
            package_id: value.packageId,
            fieldValue: value.tags.join(';')
          });
        }
      }
      const payload = {
        integrationName: 'LEVER',
        company_id: companyId,
        stageDetails: {
          stage_id: values.stayingPeriod.id
        }
      };

      const mappingPayload = {
        integrationName: 'LEVER',
        company_id: companyId,
        mappingData: subtypesMapping
      };
      triggerIntegrationStage(payload)
        .then((res) => {
          statusForm.trigger = true;
          setStatus({ ...statusForm, trigger: true });
          saveFieldMapping(mappingPayload)
            .then((res) => {
              toast.success('Stage Selection Successful. Package Mapping Successful.');
              statusForm.tags = true;
              setStatus({ ...statusForm, tags: true });
            })
            .catch((err) => {
              errToastMessage('Could not map packages with tags');
              setSubmitting(false);
            });
        })
        .catch((err) => {
          errToastMessage('Could not trigger integration');
          setSubmitting(false);
        });
    }
  })
);
