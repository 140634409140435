import React from 'react';
import classes from './ReferAndEarnBanner.module.scss';
import Image from '../../../../../core-components/Image/Image';
import { convertToIndianNumeration } from '../../../../../utils/utilities';

export default function ReferAndEarnBanner({ amount }) {
  return (
    <div className={classes.cardContainer}>
      <div className={classes.firstCol}>
        <Image name={'referLoudSpeaker.svg'} />
        <div className={classes.firstColHeading}>
          Get
          <span className={classes.firstColHeadingAmount}>
            &#8377;{convertToIndianNumeration(amount)}
          </span>
        </div>
        <div className={classes.firstColSubHeading}>for every HR you refer!</div>
      </div>
      <div className={classes.secondCol}>
        <Image name={'referGiftBox.svg'} />
      </div>
      <div className={classes.thirdCol}>
        <div className={classes.thirdColDiv}>
          <div>
            <div className={classes.thirdColHeading}>Step 1</div>
            <div className={classes.thirdColSubHeading}>Refer your HR connects</div>
            <div className={classes.thirdColSubHeading}> to us</div>
          </div>
          <div>
            <Image name={'referLoudSpeakerWhite.svg'} />
          </div>
        </div>
        <div className={classes.thirdColDiv}>
          <div>
            <div className={classes.thirdColHeading}>Step 2</div>
            <div className={classes.thirdColSubHeading}>
              Win
              <span className={classes.thirdColSubHeadingAmount}>
                &#8377;{convertToIndianNumeration(amount)}
              </span>
              on every HR
            </div>
            <div className={classes.thirdColSubHeading}>sign up!</div>
          </div>
          <div>
            <Image name={'referAmazonBox.svg'} />
          </div>
        </div>
      </div>
    </div>
  );
}
