import { Field } from 'formik';
import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { postIntegrations, getIntegrations } from '../../../../../../../api/company';
import { Button } from 'react-bootstrap';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField';
import { getErrorMessage } from '../../../../core-components/Utilities/utilities';
import styles from './InputArea.module.scss';
import { greenHouseAPIAuthentication, greenHouseWebHookDetails } from '../../../Utils/constants';
import TextWithCopyButton from '../../../Utils/TextWithCopyButton';
import { errToastMessage } from '../../../../utils/Utilities';

function InputArea(props) {
  const { values, errors } = props;

  const [greenHouseApiKey, setGreenHouseApiKey] = useState('');
  const [isGreenHouseWebHookDetected, setIsGreenHouseWebHookDetected] = useState(false);
  const [greenHouseToken, setGreenHouseToken] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  const [packages, setPackages] = useState([]);
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  useEffect(() => {
    const fetchData = async () => {
      getIntegrations().then((res) => {
        if (res?.data?.data?.GREENHOUSE?.webhook_enabled == 1) {
          setIsGreenHouseWebHookDetected(true);
          setIsConnected(true);
        }
      });
    };

    const intervalId = setInterval(fetchData, 10000); // Call the API every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const integrationData = props?.integrationData;

    if (integrationData.greenhouse && integrationData.greenhouse?.apiKey) {
      setGreenHouseApiKey(integrationData.greenhouse.apiKey);
      if (integrationData.greenhouse?.webhookEnabled != 0) {
        setIsGreenHouseWebHookDetected(true);
      }
      setGreenHouseToken(integrationData?.greenhouse?.token);
    }
  }, [props.integrationData]);

  /** API Calls **/
  const postIntegrationsApi = () => {
    const payload = {
      company_id: companyId,
      source: 'GREENHOUSE',
      details: {
        api_key: values?.greenHouseApiKey
      }
    };
    postIntegrations(payload)
      .then((resp) => {
        props.setIntegrationDataAPI(companyId);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onConnectHandler = () => {
    if (!greenHouseApiKey && !isGreenHouseWebHookDetected) {
      postIntegrationsApi();
    } else {
      props.setIntegrationDataAPI(companyId);
    }
  };

  const backStepHandler = () => {
    /** Opens the Integrations home page **/
    props.history.push('/integrations');
  };

  const getWebhookToken = () => {
    return window.location.hostname === 'portal.in.springverify.com'
      ? `https://webhook.in.springverify.com/greenhouse/${greenHouseToken}`
      : `https://dev-webhook.in.springverify.com/greenhouse/${greenHouseToken}`;
  };

  return (
    <>
      {!greenHouseApiKey && !isGreenHouseWebHookDetected ? (
        <Container>
          <Row className='mt-4'>
            <h6 className='font-weight-bold'>Step 1: Authentication via API key</h6>
          </Row>
          <Row className={styles.listItems}>
            {greenHouseAPIAuthentication.map((step, index) => {
              return (
                <div>
                  {index + 1}. {step}
                </div>
              );
            })}
          </Row>
          <Row className='mt-4'>
            <h6 className='font-weight-bold'>Step 2: Enter the API key</h6>
          </Row>
          <Form className='mt-4'>
            <Row className=''>
              <Col className={`col-sm-2 ${styles.inputLabel}`}>
                <span className=''>API key :</span>
              </Col>
              <Col className='col-sm-4'>
                <Field
                  disabled={greenHouseApiKey}
                  component={ValidatedFormInputField}
                  type='text'
                  placeholder='Key'
                  name='greenHouseApiKey'
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <div className={styles.step1FooterContainer}>
              <Button
                className={styles.goBackBtn}
                onClick={() => props.history.push('/integrations')}
              >
                Go Back
              </Button>
              <Button
                disabled={!values.greenHouseApiKey}
                className={`${greenHouseApiKey ? styles.connectedButton : styles.connectButton}`}
                onClick={onConnectHandler}
              >
                Next
              </Button>
            </div>
          </Row>
        </Container>
      ) : (greenHouseApiKey && !isGreenHouseWebHookDetected) || isConnected ? (
        <Container>
          <Row className='mt-4'>
            <h6 className='font-weight-bold'>
              Step 3: Create a Web Hook on Greenhouse and enter the details below
            </h6>
          </Row>
          <Row className={styles.listItems}>
            <ul>
              {greenHouseWebHookDetails.map((step, index) => {
                return <li>{step}</li>;
              })}
              <li className={styles.listUrl}>
                {'Endpoint URL: '}
                <TextWithCopyButton text={`${getWebhookToken()}`} />
              </li>
              <li>Secret key: {'b4ac9154-3556-410b-a76e-e76bb43967e9'}</li>
            </ul>
          </Row>
          <Row className=''>
            <h6 className='font-weight-bold'>WEB HOOK STATUS: </h6>
            <h6>&nbsp;{isGreenHouseWebHookDetected ? 'Detected' : 'Not Detected'}</h6>
          </Row>
          <Row>
            <div className={styles.step1FooterContainer}>
              <Button className={styles.goBackBtn} onClick={() => backStepHandler()}>
                Go Back
              </Button>
              <Button
                disabled={!isGreenHouseWebHookDetected}
                className={`${
                  !isGreenHouseWebHookDetected ? styles.disabledBtn : styles.connectedButton
                } ${styles.accentcolor}`}
                onClick={onConnectHandler}
              >
                Connect
              </Button>
            </div>
          </Row>
        </Container>
      ) : null}
    </>
  );
}

export default InputArea;
