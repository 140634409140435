import React, { useEffect, useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo/';
import { getAddCandidateConfig } from '../../../../api/company';
// import styles from './AddBulkCandidate.module.scss';
import cn from 'classnames';
import styles from '../AddBulkExEmp/AddBulkExEmp.module.scss';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../core-components/Utilities/utilities';

import BulkUploader from '../BulkUploader/BulkUploader';
import { errToastMessage } from '../utils/Utilities';

const AddBulkCandidate = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [categories, setCategories] = useState([]);
  const [config, setConfig] = useState(null);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  var saCompanyId = props?.isSA
    ? new URLSearchParams(document.location.search).get('company_id')
    : '';
  useEffect(() => {
    // calling config api to get categories data for the company

    getAddCandidateConfig(saCompanyId)
      .then((res) => {
        setCategories(res?.data?.categories || []);
        setConfig(res?.data);
      })
      .catch((error) => {
        errToastMessage(error);
      });

    if (props?.rows) {
      setRows(props?.rows);
    }

    //this if will execute if we redirect from package listing page
    if (
      props &&
      (props?.isSA || props?.isCA) &&
      props?.location?.state?.isBulkUpload &&
      props?.location?.state?.step === 2
    ) {
      const currentStepFromBulkUpload = props && props?.location?.state?.step;
      currentStepFromBulkUpload ? setCurrentStep(currentStepFromBulkUpload) : setCurrentStep(1);
      saCompanyId = (props && props?.location?.state?.companyId) || ''; //only for SA side
    }
  }, []);

  const handleStep1Submit = (rows, cols) => {
    setRows(rows);
    setCols(cols);
    handleStepChange(2);
    props.setBulkUploadDataAPI({ type: 'rows', data: rows });
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };
  const getFormBasedOnStep = (step) => {
    switch (step) {
      case 1:
        return <StepOne handleStepChange={handleStep1Submit} {...props} />;
      case 2:
        return (
          <StepTwo
            handleStepChange={handleStepChange}
            {...props}
            categories={categories}
            rows={rows}
            cols={cols}
            config={config}
            isBulkUpload={props?.location?.state?.isBulkUpload}
            saCompanyId={saCompanyId}
          />
        );

      default:
        return <StepOne handleStepChange={handleStepChange} {...props} />;
    }
  };

  return (
    <>
      {/* <div className={styles.cardSection}>{getFormBasedOnStep(currentStep)}</div> */}
      <div className={cn(`${styles.alumniWrapperWrapper}`, props?.isSA ? styles.saContainer : '')}>
        <div className={styles.subHeading}>
          <h4 className={styles.header}>{'Candidates'}</h4>
        </div>
        <BulkUploader {...props} flow={'candidate'} />
      </div>
    </>
  );
};

AddBulkCandidate.defaultProps = {
  isCA: false, // to check whether component is imported in CA side or not
  isSA: false // to check whether component is imported in SA side or not
};

export default AddBulkCandidate;
