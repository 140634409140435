import React from 'react';
import classes from './ReferAndEarnBanner.module.scss';
import Image from '../../../../core-components/Image/Image';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import AppConstants from '../../../../core-components/AppConstants';

export default function ReferAndEarnBanner(props) {
  return (
    <Card
      onClick={() => {
        window.open(`${AppConstants.baseURL}refer-and-earn`, '_blank');
      }}
      className={classes.cardContainer}
    >
      <div className={classes.divContainer}>
        <div className={classes.firstCol}>
          <Image name={'referLoudSpeaker.svg'} className={classes.firstColImg} />
        </div>
        <div className={classes.headingCol}>
          Refer your HR friends and get rewarded{' '}
          <span className={classes.rupee}>
            &#8377;<span className={classes.amount}>5,000</span>
          </span>{' '}
          for each successful referral
        </div>
        <div
          style={{
            position: 'absolute',
            left: '505px',
            bottom: '23px'
          }}
        >
          <SVGComponent />
        </div>
        <div>
          <Image name={'referGiftBox.svg'} className={classes.imgCol} />
        </div>
        <div>
          <Button className={classes.referNowBtn}>
            <span>Refer now</span>
          </Button>
        </div>
      </div>
    </Card>
  );
}

const SVGComponent = (props) => (
  <svg
    width={63}
    height={5}
    viewBox='0 0 63 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M62 2C62 2 60.0452 6.17127 30.4999 2.99998C0.954571 -0.171299 0.999416 3.40979 0.999416 3.40979'
      stroke='#2755FE'
      strokeWidth={1.5}
      strokeLinecap='round'
    />
  </svg>
);
