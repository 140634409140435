import { isEmpty } from 'lodash';

export const isAllStepsCompleted = (item) => {
  return (
    item &&
    !isEmpty(item) &&
    item.hasOwnProperty('step1') &&
    !isEmpty(item.step1) &&
    item.hasOwnProperty('step2') &&
    !isEmpty(item.step2)
  );
};
