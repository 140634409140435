// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RLIazZ3LzSippAiSWKrD {
  display: inline-block;
  transition: all 0.5s ease;
  margin-right: 15px;
}

.RLIazZ3LzSippAiSWKrD:hover {
  transform: scale(2);
  color: black;
  fill: black;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/BgvBodyContainer/BgvStepBodyContianer/Feedback/Star.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":[".star {\n  display: inline-block;\n  transition: all 0.5s ease;\n  margin-right: 15px;\n}\n\n.star:hover {\n  transform: scale(2);\n  color: black;\n  fill: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star": `RLIazZ3LzSippAiSWKrD`
};
export default ___CSS_LOADER_EXPORT___;
