import React from 'react';
import Image from '../../../../../core-components/Image';

import styles from './UsageLogsTable.module.scss';

function EmptyTableBody() {
  return (
    <tbody>
      <tr>
        <td colSpan={7}>
          <div className={styles.noDataWrapper}>
            <Image name={'noPurchaseHistory.svg'} />
            <div className={styles.noDataContent}>{'No Usage Logs'}</div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default EmptyTableBody;
