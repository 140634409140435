import React, { useState, useEffect } from 'react';

import { sortBy } from 'lodash';
import { Card, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import CandidateOption from './CandidateOption/CandidateOption';
import PackageDetails from './PackageDetails';
import CustomSpinner from '../../../../core-components/CustomSpinner';
import Summary from './Summary';

import { toCamelCaseTemp, getErrorMessage, errToastMessage } from '../../../../utils/utilities';
import { getPaymentsPackagesReviewOrder } from '../../../../api/company';

import styles from './ReviewOrder.module.scss';
import Loader from '../../../../core-components/Loader';

function ReviewOrder(props) {
  const [loading, setLoading] = useState(true);
  const [candidateOptionDetails, setCandidateOptionDetails] = useState([]);
  const { selectedPackage, selectedPackageOptionIndex } = props;
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getPaymentsPackagesReviewOrder((selectedPackage && selectedPackage.packageId) || null)
      .then((response) => {
        setLoading(false);
        let data = toCamelCaseTemp(response.data.data);
        // sorting array of objects on the basis of candidate count
        data['data'] = sortBy(data.data, (obj) => obj.candidateCount);
        setCandidateOptionDetails(data);
        // set last option as selected
        handleOptionSelection(data?.data?.length - 1);
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  }, []);

  const handleOptionSelection = (index = null) => {
    props?.handleSelectedPackageOptionIndex(index);
  };

  const displayToast = () => {
    errToastMessage('No recharge Details Found.');
  };

  useEffect(() => {
    if (showLoader) {
      window.onbeforeunload = function () {
        return `Your purchase is in progress. Closing / Reloading the tab will affect your purchase.`;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [showLoader]);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.heading}>{'Review order'}</div>
      </div>

      <Card className={styles.packagesCard}>
        {showLoader ? (
          <Loader />
        ) : (
          <Row>
            <Col lg={8} md={8} sm={8} xs={12}>
              {loading && (
                <div className={styles.loaderContainer}>
                  <CustomSpinner
                    className='bgvLoader'
                    as='div'
                    animation='border'
                    size='md'
                    role='status'
                    aria-hidden='true'
                  />
                </div>
              )}

              {!loading &&
              candidateOptionDetails &&
              candidateOptionDetails.data &&
              candidateOptionDetails.data.length ? (
                <CandidateOption
                  optionList={candidateOptionDetails.data}
                  selectedOptionIndex={selectedPackageOptionIndex}
                  handleOptionSelection={handleOptionSelection}
                  data-testid='candidate-option'
                />
              ) : (
                !loading &&
                candidateOptionDetails.data &&
                !candidateOptionDetails.data.length &&
                displayToast()
              )}

              <div className={styles.packageDetailsText}>
                <span className={styles.packageDetailsTextLeft}>{'Package Details'}</span>
                <span
                  className={styles.packageDetailsTextRight}
                  onClick={() => {
                    props?.handleStepChange(1);
                  }}
                  data-testid='change-package'
                >
                  {'Change Package'}
                </span>
              </div>
              <PackageDetails package={selectedPackage} data-testid='package-details' />
            </Col>
            <Col lg={4} md={4} sm={4} xs={12}>
              {loading && (
                <div className={styles.loaderContainer}>
                  <CustomSpinner
                    className='bgvLoader'
                    as='div'
                    animation='border'
                    size='md'
                    role='status'
                    aria-hidden='true'
                  />
                </div>
              )}

              {!loading &&
              candidateOptionDetails &&
              candidateOptionDetails.data &&
              candidateOptionDetails.data.length ? (
                <Summary
                  setShowLoader={setShowLoader}
                  creditBalance={candidateOptionDetails.creditBalance}
                  selectedPackage={selectedPackage}
                  selectedOption={candidateOptionDetails.data[selectedPackageOptionIndex]}
                  handleBuyPackage={props?.handleBuyPackage}
                  selectedPackageOptionIndex={props?.selectedPackageOptionIndex}
                  setPaymentStatus={props?.setPaymentStatus}
                  setOrderId={props?.setOrderId}
                  setCandidateCount={props?.setCandidateCount}
                  paymentDetails={props?.paymentDetails}
                  setPurchasedPackageName={props?.setPurchasedPackageName}
                  setTransactionId={props?.setTransactionId}
                  invalidatePaymentCreditTransaction={props?.invalidatePaymentCreditTransaction}
                  data-testid='summary'
                />
              ) : null}
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
}

export default ReviewOrder;
