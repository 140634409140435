import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  invalidateViewBuyPackages as invalidateViewBuyPackagesAPI,
  getViewBuyPackages as getViewBuyPackagesAPI,
  getAddCandidateConfig as getAddCandidateConfigAPI,
  invalidatePaymentCreditTransaction,
  getCompanyPaymentDetails
} from '../../../actions/company';
import { hasPaymentGatewayMethod } from '../../../utils/utilities';

const mapStateToProps = (state) => {
  return {
    viewBuyPackages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null,
    createCandConfig:
      state.createCandConfig && !isEmpty(state.createCandConfig) ? state.createCandConfig : null,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    packageType:
      state?.profile?.profile?.company_user_mapping?.company?.payments_company_model_config
        ?.model_type,
    hideViewDetailsButton: state?.paymentCreditTransaction?.paymentCreditTransaction ? !hasPaymentGatewayMethod(state?.paymentCreditTransaction?.paymentCreditTransaction) : true
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateViewBuyPackages: () => dispatch(invalidateViewBuyPackagesAPI()),
    getViewBuyPackages: (data) => dispatch(getViewBuyPackagesAPI(data)),
    getAddCandidateConfig: () => dispatch(getAddCandidateConfigAPI()),
    invalidatePaymentCreditTransaction: () => dispatch(invalidatePaymentCreditTransaction()),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
