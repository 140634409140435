import React, { useState, useEffect } from 'react';
import { has } from 'lodash';
import { Card, Form, Col, Row, Button, Spinner } from 'react-bootstrap';
import { Field } from 'formik';
import { DropdownList } from 'react-widgets';

import Header from '../../Header';
import Checkbox from '../../../../core-components/Checkbox';
import Footer from '../../Footer';
import CustomSpinner from '../../../../core-components/CustomSpinner';

import styles from './EmailPreferences.module.scss';
import { DIGEST_FREQUENCY, DIGEST_TYPE } from '../../../../utils/commonConstant';

const digestFrequency = DIGEST_FREQUENCY;
const digestType = DIGEST_TYPE;

function EmailPreferences(props) {
  const { isSubmitting, errors, touched, setFieldValue, values } = props;
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(
    new URLSearchParams(document.location.search).get('email')
  );

  useEffect(() => {
    props.getEmailPreferences(
      new URLSearchParams(document.location.search).get('id'),
      new URLSearchParams(document.location.search).get('companyId')
    );

    return () => {
      props.invalidateEmailPreferences();
    };
  }, []);

  useEffect(() => {
    if (
      props.emailPreferences &&
      has(props.emailPreferences, 'loading') &&
      !props.emailPreferences.loading
    ) {
      setLoading(false);
    }
  }, [props.emailPreferences]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
  };

  const handleMyTeam = () => {
    props.history.push('myTeam');
  };

  return (
    <div>
      <div className={styles.pageHeader}>
        <h4>Email preferences</h4>
      </div>
      <Card className={styles.adminCard}>
        {!loading ? (
          <>
            <div className={styles.cardHeader}>
              <p className={styles.description}>
                <span className={styles.lightColor}>{'Send email updates to '}</span>
                <span>{`(${userEmail ? userEmail : ''})`}</span>
              </p>
            </div>
            <div className={styles.prefFormContainer}>
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                data-testid="email-preferences-form"
              >
                <Form.Row>
                  <Field
                    custom
                    name='bounceEmail'
                    component={Checkbox}
                    label={
                      <span className={styles.lightColor}>
                        {'Form fill reminders / Bounce emails'}
                      </span>
                    }
                  />
                </Form.Row>
                <Form.Row>
                  <Field
                    custom
                    name='digest'
                    component={Checkbox}
                    label={<span className={styles.lightColor}>{'Digest emails'}</span>}
                  />
                </Form.Row>
                <Row className={styles.freqContainer}>
                  <Col style={{ textAlign: 'right' }} lg={3} md={3} sm={3} xs={3}>
                    <span className={`${styles.lightColor} ${styles.font14}`}>{'Frequency'}</span>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4}>
                    <DropdownList
                      className={styles.dropdown}
                      name={'digestValues.frequency'}
                      data={digestFrequency}
                      textField='label'
                      valueField='value'
                      value={values.digestValues.frequency}
                      disabled={values.digest ? false : true}
                      onChange={(value) => {
                        setFieldValue('digestValues.frequency', value.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className={styles.typeContainer}>
                  <Col style={{ textAlign: 'right' }} lg={3} md={3} sm={3} xs={3}>
                    <span className={`${styles.lightColor} ${styles.font14}`}>{'Type'}</span>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4}>
                    <DropdownList
                      className={styles.dropdown}
                      name={'digestValues.information'}
                      data={digestType}
                      textField='label'
                      valueField='value'
                      disabled={values.digest ? false : true}
                      value={values.digestValues.information}
                      onChange={(value) => {
                        setFieldValue('digestValues.information', value.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Button
                      disabled={isSubmitting || !props?.dirty}
                      variant='primary'
                      type='submit'
                      className={!isSubmitting ? styles.submitBtn : styles.submittingBtn}
                      data-testid="save-button"
                    >
                      {!isSubmitting ? (
                        'Save'
                      ) : (
                        <>
                          <Spinner
                            aria-hidden='true'
                            as={'span'}
                            animation='border'
                            size={'sm'}
                            role='status'
                          />
                          <span>&nbsp;{'Saving'}</span>
                        </>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        ) : (
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: 'calc(100vh - 50vh)',
                alignItems: 'center'
              }}
            >
              <CustomSpinner
                className='bgvLoader'
                as='div'
                animation='border'
                size='md'
                role='status'
                aria-hidden='true'
              />
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
}

export default EmailPreferences;