const TOKEN_STRING = 'token';
const TYPE = 'type';
const ACCESS_LEVEL = 'accessLevel';
const BGV_TOKEN = 'bgvToken';
const BGV_TOKEN_CHANGE = 'bgvTokenChange';
!localStorage.accessLevel && localStorage.setItem(ACCESS_LEVEL, 'admin');
!localStorage.bgvTokenChange && localStorage.setItem(BGV_TOKEN_CHANGE, 'false');

export default {
  setToken: (token, type) => {
    localStorage.setItem(TYPE, type);
    localStorage.setItem(TOKEN_STRING, token);
  },
  getToken: () => localStorage.getItem(TOKEN_STRING),
  getItem: (key) => localStorage.getItem(key),
  setItem: (key, value) => localStorage.setItem(key, value),
  getType: () => localStorage.getItem(TYPE),
  deleteToken: () => localStorage.removeItem(TOKEN_STRING),
  clearStorage: () => localStorage.clear(),
  setAccessLevel: (accessLevel) => {
    localStorage.setItem(ACCESS_LEVEL, accessLevel);
  },
  getAccessLevel: () => localStorage.getItem(ACCESS_LEVEL),
  setBGVToken: (bgvToken) => {
    localStorage.setItem(BGV_TOKEN, bgvToken);
  },
  getBGVToken: () => {
    let token = new URLSearchParams(document.location.search).get('token');
    if (!token) {
      token = localStorage.getItem(BGV_TOKEN);
    }
    return token;
  },
  setBGVTokenChange: (bgvTokenChange) => {
    localStorage.setItem(BGV_TOKEN_CHANGE, bgvTokenChange);
  },
  getBGVTokenChange: () => localStorage.getItem(BGV_TOKEN_CHANGE)
};

// Adding this for Integration  in common repo
export const getToken = () => {
  return localStorage.getItem(TOKEN_STRING);
};
