// React and libraries imports
import React, { useState, useEffect } from 'react';
import { isEmpty, has, capitalize } from 'lodash';
import { Field } from 'formik';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import { pdfjs } from 'react-pdf';
import { Modal, Form, Col, Spinner, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Core components and Utils import
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import Checkbox from '../../../../../../../core-components/Checkbox';
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import Image from '../../../../../../../core-components/Image';
import Button from '../../../../../../../core-components/Button';
import AppConstants from '../../../../../../../core-components/AppConstants';
import { getFileHeader, validateImage } from '../../../../../../../utils/utilities';
import PasswordProtectedModal from '../../../PasswordProtectedModal';

// Styles import
import styles from './AddressStep3.module.scss';

export default (props) => {
  const { setFieldValue } = props;
  const idTypeValues = ['Aadhaar', 'Driving License', 'Passport', 'Voter ID', 'Other'];
  const token = new URLSearchParams(document.location.search).get('token');

  //remove front ID
  const [showRemoveFront, setShowRemoveFront] = useState(false);
  const [showRemoveBack, setRemoveBack] = useState(false);

  //image modal
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imagePreviewURL, setImagePreviewURL] = useState(false);

  //bgv mobile camera
  const [takeIdPhoto, setTakeIdPhoto] = useState(false);
  const [cameraUploadError, setCameraUploadError] = useState(false);

  //loading id
  const [onLoadingFront, setOnloadingFront] = useState(false);
  const [onLoadingBack, setOnLoadingBack] = useState(false);

  //setting id
  const [Front, setFront] = useState(false);
  const [Back, setBack] = useState(false);

  //error
  const [onFrontError, setFrontError] = useState(false);
  const [onBackError, setBackError] = useState(false);
  const [frontMaxError, setFrontMaxError] = useState(false);
  const [backMaxError, setBackMaxError] = useState(false);
  const [frontFileError, setFrontFileError] = useState(false);
  const [backFileError, setBackFileError] = useState(false);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [currentFileFrontOrBack, setCurrentFileFrontOrBack] = useState('');
  const [backPassword, setBackPassword] = useState('');
  const [frontPassword, setFrontPassword] = useState('');

  const handleRefreshFront = () => {
    setOnloadingFront(false);
    setFrontMaxError(false);
    setFrontError(false);
    setFieldValue('frontDocUrl', '');
    props.setAddressFrontDoc('');
  };

  const handleRefreshBack = () => {
    setOnLoadingBack(false);
    setBackMaxError(false);
    setBackError(false);
    props.setAddressBackDoc('');
    setFieldValue('backDocUrl', '');
  };

  //adress setting
  useEffect(() => {
    if (!isEmpty(props.addressDocType && isEmpty(props.values.docType))) {
      setFieldValue('docType', props.addressDocType);
    }
    if (has(props.addressDocFront, 'front') && isEmpty(props.values.frontDocUrl)) {
      setFieldValue('frontDocUrl', props.addressDocFront.front);
      setFieldValue('frontPdf', props.addressDocFront.isPdf);
      setFieldValue('frontDocPassword', props.addressDocFront.password);
    }
    if (has(props.addressDocBack, 'back') && isEmpty(props.values.backDocUrl)) {
      setFieldValue('backDocUrl', props.addressDocBack.back);
      setFieldValue('backPdf', props.addressDocBack.isPdf);
      setFieldValue('backDocPassword', props.addressDocBack.password);
    }
  }, []);

  const singleDocUpdate = () => {
    props.setAddressSameDoc(!props.values.singleDoc);
  };

  //setFrontDLink
  useEffect(() => {
    if (Front) {
      let Id = props.uploadedID;
      setFieldValue('frontDocUrl', Id);
      props.setAddressFrontDoc({
        front: Id,
        isPdf: props.values.frontPdf,
        password: frontPassword
      });
      setOnloadingFront(false);
      setFront(false);
    }
  }, [Front]);
  //setBackIdLink
  useEffect(() => {
    if (Back) {
      let Id = props.uploadedID;
      setFieldValue('backDocUrl', Id);
      props.setAddressBackDoc({ back: Id, isPdf: props.values.backPdf, password: backPassword });
      setOnLoadingBack(false);
      setBack(false);
    }
  }, [Back]);

  //for mobile cam
  const handleIdPhoto = () => {
    setTakeIdPhoto(!takeIdPhoto);
    setRemoveBack(false);
    setShowRemoveFront(false);
    setCameraUploadError(false);
  };

  //image preiew
  const handleImagePreview = () => {
    setShowImagePreview(!showImagePreview);
  };

  //handleUpload for mobile cam
  const handleIdUpload = (formData, isPdf, setCameraSubmitting = null) => {
    //which photo to go where has to be asked
    //we have cam front and cam back
    if (!props.values.frontDocUrl) {
      setOnloadingFront(!onLoadingFront);

      //call s3 link here and get itsresponse and save it in file
      props
        .bgvIdUpload(formData)
        .then(() => {
          setFront(true);
          setCameraSubmitting(false);
          handleIdPhoto();
        })
        .catch((error) => {
          setFrontError(true);
        });
    } else {
      // handleFileSelectBack(formData);
      setOnLoadingBack(!onLoadingBack);
      props
        .bgvIdUpload(formData)
        .then(() => {
          setBack(true);
          setCameraSubmitting(false);
          handleIdPhoto();
        })
        .catch((error) => {
          setBackError(true);
        });
    }
  };

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    if (currentFileFrontOrBack == 'front') {
      setOnloadingFront(false);
    } else if (currentFileFrontOrBack == 'back') {
      setOnLoadingBack(false);
    }
  };

  const submitHandlerPasswordModal = (password = '') => {
    //Function for handling submit password when unable to unlock the file and when successfully saved the password
    setShowPasswordProtectedModal(false);
    if (currentFileFrontOrBack == 'front') {
      setFieldValue('frontDocPassword', password);
      setFrontPassword(password);
      setFront(true);
    } else if (currentFileFrontOrBack == 'back') {
      setBackPassword(password);
      setFieldValue('backDocPassword', password);
      setBack(true);
    }
  };

  //Id Front upload
  const handleFileSelectFront = (e, type, index) => {
    setFieldValue('frontDocPassword', '');
    let fileType = '';
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      setFrontError(false);
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileHeader(header);
      const errMessage = validateImage(blob, fileType);
      const isPdf = fileType === 'application/pdf' ? true : false;
      if (isPdf) {
        setFieldValue('frontPdf', true);
      } else {
        setFieldValue('frontPdf', false);
      }

      if (!errMessage) {
        let formData = new FormData();
        formData.append(`file`, blob);
        formData.append(`file_type`, 'CHECK_DOCUMENT');
        setOnloadingFront(!onLoadingFront);
        props
          .bgvIdUpload(formData)
          .then(() => {
            try {
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({ data: fileReader.result, password: '' });
                loadingTask.promise
                  .then((pdfDocument) => {
                    setCurrentFileFrontOrBack('');
                    setFront(true);
                  })
                  .catch((error) => {
                    if (error.message == 'No password given') {
                      setCurrentFileFrontOrBack('front');
                      setShowPasswordProtectedModal(true);
                    }
                  });
              } else {
                setCurrentFileFrontOrBack('');
                setFront(true);
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            setFrontError(true);
            setFrontFileError(true);
            setOnloadingFront(false);
          });
      } else {
        setFrontError(true);
        errMessage === 'FILE_SIZE_EXCEEDS' ? setFrontMaxError(true) : setFrontFileError(true);
      }
    };

    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };

  //Id back upload
  const handleFileSelectBack = (e, type, index) => {
    setFieldValue('backDocPassword', '');
    let fileType = '';
    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = () => {
      var arr = new Uint8Array(fileReader.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      fileType = getFileHeader(header);
      const errMessage = validateImage(blob, fileType);
      const isPdf = fileType === 'application/pdf' ? true : false;
      if (isPdf) {
        setFieldValue('backPdf', true);
      } else {
        setFieldValue('backPdf', false);
      }

      if (!errMessage) {
        let formData = new FormData();
        formData.append(`file`, blob);
        formData.append(`file_type`, 'CHECK_DOCUMENT');
        setOnLoadingBack(!onLoadingBack);
        props
          .bgvIdUpload(formData)
          .then(() => {
            try {
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({ data: fileReader.result, password: '' });
                loadingTask.promise
                  .then((pdfDocument) => {
                    setCurrentFileFrontOrBack('');
                    setBack(true);
                  })
                  .catch((error) => {
                    if (error.message == 'No password given') {
                      setCurrentFileFrontOrBack('back');
                      setShowPasswordProtectedModal(true);
                    }
                  });
              } else {
                setCurrentFileFrontOrBack('');
                setBack(true);
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            setBackError(true);
            setBackFileError(true);
            setOnLoadingBack(false);
          });
      } else {
        setBackError(true);
        errMessage === 'FILE_SIZE_EXCEEDS' ? setBackMaxError(true) : setBackFileError(true);
      }
    };

    if (blob instanceof Blob) {
      {
        /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
      }
      fileReader.readAsArrayBuffer(blob);
    } else {
      console.error(
        `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
      );
    }
  };

  //delete uploaded ID
  const DeleteLink = (IdSide) => {
    if (IdSide === 'FRONT') {
      setShowRemoveFront(false);
      setFieldValue('frontDocUrl', '');
      props.setAddressFrontDoc('');
    } else {
      setRemoveBack(false);
      props.setAddressBackDoc('');
      setFieldValue('backDocUrl', '');
    }
  };

  //do changes in contaire file

  return (
    <Modal
      size={showPasswordProtectedModal ? 'sm' : 'lg'}
      show={props.show}
      onHide={props.onHide}
      dialogClassName='bgvModalDialog'
      className='bgvModal'
      backdropClassName={'customBGVModalBackdrop'}
      centered
    >
      <div style={showPasswordProtectedModal ? { display: 'none' } : null}>
        {/* Adding this div to fix the overlapping issue */}
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className={styles.step3Title}>
              {`${props.title}  ${
                props.addressType === 'ANY ADDRESS'
                  ? props?.cachedData?.cacheData?.addressCheck[0].step1.addressType
                  : capitalize(props.addressType) + ' Address'
              }`}
            </div>
            <div className={styles.step3SubTitle}>
              {`Step ${props.currentModalSubStep} of ${props.totalStep}`}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form>
            <Form.Row>
              {isMobileOnly ? (
                <div className={styles.warningHeading}>Add your ID proof here</div>
              ) : (
                <div className={styles.warningHeading}>Add your Address proof here</div>
              )}
            </Form.Row>
            <Form.Row>
              <Col md={12} lg={12}>
                <p className={styles.warningSubHeading}>
                  Please make sure you upload a clear picture of both sides of Id. Upload .jpg,
                  .jpeg, .png or .pdf files only.
                </p>
              </Col>
            </Form.Row>
            <Form.Row className={styles.Row}>
              <Form.Label>
                Select your document type<span style={{ color: 'red' }}>*</span>
              </Form.Label>
            </Form.Row>
            <Form.Row>
              <Col md={8} lg={5}>
                <Select
                  menuPosition={'fixed'}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  options={idTypeValues}
                  value={idTypeValues?.filter((i) => i === props?.values?.docType) || ''}
                  name='docType'
                  getOptionLabel={(l) => l}
                  getOptionValue={(v) => v}
                  onChange={(value) => {
                    //here cause of slectdropdown goes as object
                    setFieldValue('docType', value);
                    props?.setAddressDocType(value);
                  }}
                />
                {props.errors.docType && <div className={styles.error}>{props.errors.docType}</div>}
              </Col>
              {props.values.docType === 'Other' && (
                <Col md={5} lg={5}>
                  <Field
                    component={ValidatedFormInputField}
                    type='text'
                    placeholder='What document is this?'
                    name='otherDoc'
                  />
                </Col>
              )}
              <Col md={2} lg={2}></Col>
            </Form.Row>
            {props.values.docType && (
              <>
                <Form.Row className={styles.Row}>
                  {isMobileOnly ? (
                    <Form.Label>
                      Add a front and back of your ID<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                  ) : (
                    <Form.Label>
                      Add document<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                  )}
                </Form.Row>
                {isMobileOnly && (
                  <Form.Row className={styles.Row}>
                    <Button
                      className={styles.addBtn}
                      disabled={
                        props.values.frontDocUrl &&
                        (props.values.singleDoc || props.values.backDocUrl) &&
                        true
                      }
                      onClick={() => {
                        handleIdPhoto();
                      }}
                    >
                      Take Photo of Address proof
                    </Button>
                  </Form.Row>
                )}
                {isMobileOnly && takeIdPhoto && (
                  <BGVCamera
                    type='single'
                    showBgvCameraModal={takeIdPhoto}
                    handleCameraModal={handleIdPhoto}
                    handleSingleUpload={handleIdUpload}
                    isCameraUploadError={cameraUploadError}
                  />
                )}
                <Form.Row>
                  <Col md={4} lg={4}>
                    {/* Id Front here conditions will come   */}
                    <div
                      className={styles.idPlaceHolder}
                      style={{ background: onFrontError && '#000000' }}
                    >
                      {!props.values.frontDocUrl ? (
                        <>
                          {/* onloading  */}
                          {onLoadingFront && (
                            <Spinner
                              animation='border'
                              variant='primary'
                              style={{ margin: '35px 85px' }}
                            />
                          )}
                          {/* onErrorhandling is left */}

                          {/* not on loading not on error  */}
                          {!onLoadingFront && onFrontError ? (
                            <>
                              <div
                                className={
                                  frontMaxError || frontFileError
                                    ? styles.idMaxError
                                    : styles.idUploadText
                                }
                              >
                                <Image name='BgvErrorUploadDoc.png' />
                                {frontMaxError ? (
                                  <div className={styles.errorTag}>
                                    {'Upload Failed,please upload a file that exceeds ' +
                                      AppConstants.fileSize +
                                      ' KB'}
                                  </div>
                                ) : (
                                  <div className={styles.errorTag}>
                                    Upload Failed. {frontFileError ? 'File-type not supported' : ''}
                                  </div>
                                )}

                                <div className={styles.errorRefresh} onClick={handleRefreshFront}>
                                  <Image name='BgvErrorRefresh.png' />
                                </div>
                              </div>
                            </>
                          ) : (
                            !onLoadingFront && (
                              <>
                                <label
                                  style={{ cursor: 'pointer', width: '210px', height: '110px' }}
                                >
                                  <div className={styles.idUploadText}>
                                    <Image name='BgvIdFront.png' />

                                    {props.values.singleDoc ? (
                                      <div className={styles.tag}>Add document </div>
                                    ) : (
                                      <div className={styles.tag}>Add Front of your Id </div>
                                    )}
                                  </div>
                                  <Form.Control
                                    id='formControlsFile'
                                    accept='image/*,application/pdf'
                                    className={styles.attachDocBtn}
                                    type='file'
                                    label='File'
                                    onChange={handleFileSelectFront}
                                  />
                                </label>
                              </>
                            )
                          )}
                        </>
                      ) : (
                        //when image is present
                        // pdf handling is left
                        <>
                          <div className={styles.uploadFunctionality}>
                            <div className={styles.functionBtn}>
                              {props.values.frontPdf ? (
                                <>
                                  {' '}
                                  <a
                                    href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.frontDocUrl}`}
                                    target='_blank'
                                  >
                                    <Image
                                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                                      name='BgvIdView.png'
                                    />
                                  </a>{' '}
                                </>
                              ) : (
                                <Image
                                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                                  onClick={() => {
                                    handleImagePreview();
                                    setImagePreviewURL(props.values.frontDocUrl);
                                  }}
                                  name='BgvIdView.png'
                                />
                              )}
                            </div>
                            <div
                              className={styles.functionBtn}
                              onClick={() => {
                                setShowRemoveFront(true);
                              }}
                              style={{ background: showRemoveFront && 'transparent' }}
                            >
                              <Image
                                style={{
                                  display: showRemoveFront && 'none',
                                  marginLeft: '5px',
                                  cursor: 'pointer'
                                }}
                                name='BgvIdDelete.png'
                              />
                              {showRemoveFront && (
                                // here call delete api
                                <Badge
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    DeleteLink('FRONT');
                                  }}
                                  pill
                                  variant='danger'
                                >
                                  Remove ?
                                </Badge>
                              )}
                            </div>
                          </div>
                          {props.values.frontPdf ? (
                            <Image
                              name='BgvIdPdf.png'
                              style={{ margin: '35px 0px 0px 90px', cursor: 'pointer' }}
                            />
                          ) : (
                            <Image
                              src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.frontDocUrl}`}
                              style={{ width: '210px', height: '115px', cursor: 'pointer' }}
                            />
                          )}
                          {props.values.frontDocPassword && (
                            <OverlayTrigger
                              key={`password_protected`}
                              placement='top'
                              overlay={
                                <Tooltip id={`password_protected`}>
                                  <p>Password is saved</p>
                                </Tooltip>
                              }
                            >
                              <div className={styles.passwordProtectIcon}>
                                <Image
                                  style={{
                                    marginRight: '5px',
                                    cursor: 'pointer',
                                    height: '24px',
                                    width: '24px'
                                  }}
                                  name='passwordSaved.png'
                                />
                              </div>
                            </OverlayTrigger>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                  {!props.values.singleDoc && (
                    <Col md={6} lg={4}>
                      {/* Id Back here condition will come  */}
                      <div
                        className={styles.idPlaceHolder}
                        style={{ background: onBackError && '#000000' }}
                      >
                        {!props.values.backDocUrl ? (
                          <>
                            {/* onloading  */}
                            {onLoadingBack && (
                              <Spinner
                                animation='border'
                                variant='primary'
                                style={{ margin: '35px 85px' }}
                              />
                            )}

                            {/* on error handling pending  */}

                            {/* not on loding or not on error  */}
                            {!onLoadingBack && onBackError ? (
                              <>
                                <div
                                  className={
                                    backMaxError || backFileError
                                      ? styles.idMaxError
                                      : styles.idUploadText
                                  }
                                >
                                  <Image name='BgvErrorUploadDoc.png' />
                                  {backMaxError ? (
                                    <div className={styles.errorTag}>
                                      {'Upload Failed,please upload a file that exceeds ' +
                                        AppConstants.fileSize +
                                        ' KB'}
                                    </div>
                                  ) : (
                                    <div className={styles.errorTag}>
                                      Upload Failed.{' '}
                                      {backFileError ? 'File-type not supported' : ''}
                                    </div>
                                  )}
                                  <div className={styles.errorRefresh} onClick={handleRefreshBack}>
                                    <Image
                                      name='BgvErrorRefresh.png'
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              !onLoadingBack && (
                                <>
                                  <label
                                    style={{ cursor: 'pointer', width: '210px', height: '110px' }}
                                  >
                                    <div className={styles.idUploadText}>
                                      <Image name='BgvIdBack.png' />

                                      <div className={styles.tag}>Add Back of your Id </div>
                                    </div>
                                    <Form.Control
                                      id='formControlsFile'
                                      className={styles.attachDocBtn}
                                      accept='image/*,application/pdf'
                                      type='file'
                                      label='File'
                                      onChange={handleFileSelectBack}
                                    />
                                  </label>
                                </>
                              )
                            )}
                          </>
                        ) : (
                          // when the doc is added
                          // pdf handling is pending
                          <>
                            <div className={styles.uploadFunctionality}>
                              <div className={styles.functionBtn}>
                                {props.values.backPdf ? (
                                  <>
                                    {' '}
                                    <a
                                      href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.backDocUrl}`}
                                      target='_blank'
                                    >
                                      <Image
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        name='BgvIdView.png'
                                      />
                                    </a>{' '}
                                  </>
                                ) : (
                                  <Image
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={() => {
                                      handleImagePreview();
                                      setImagePreviewURL(props.values.backDocUrl);
                                    }}
                                    name='BgvIdView.png'
                                  />
                                )}
                              </div>
                              <div
                                className={styles.functionBtn}
                                onClick={() => {
                                  setRemoveBack(true);
                                }}
                                style={{ background: showRemoveBack && 'transparent' }}
                              >
                                <Image
                                  style={{
                                    display: showRemoveBack && 'none',
                                    marginLeft: '5px',
                                    cursore: 'pointer'
                                  }}
                                  name='BgvIdDelete.png'
                                />
                                {showRemoveBack && (
                                  // here call delete api
                                  <Badge
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      DeleteLink('BACK');
                                    }}
                                    pill
                                    variant='danger'
                                  >
                                    Remove ?
                                  </Badge>
                                )}
                              </div>
                            </div>
                            {props.values.backDocPassword && (
                              <OverlayTrigger
                                key={`password_protected`}
                                placement='top'
                                overlay={
                                  <Tooltip id={`password_protected`}>
                                    <p>Password is saved</p>
                                  </Tooltip>
                                }
                              >
                                <div className={styles.passwordProtectIcon}>
                                  <Image
                                    style={{
                                      marginRight: '5px',
                                      cursor: 'pointer',
                                      height: '24px',
                                      width: '24px'
                                    }}
                                    name='passwordSaved.png'
                                  />
                                </div>
                              </OverlayTrigger>
                            )}
                            {props.values.backPdf ? (
                              <Image
                                name='BgvIdPdf.png'
                                style={{ margin: '35px 0px 0px 90px', cursor: 'pointer' }}
                              />
                            ) : (
                              <Image
                                src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.values.backDocUrl}`}
                                style={{ width: '210px', height: '115px', cursor: 'pointer' }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  )}
                  <Col md={6} lg={4}></Col>
                </Form.Row>

                <Form.Row className={styles.Row}>
                  <Col style={{ color: '#BFBFBF' }}>
                    <Field
                      className={styles.consentLabel}
                      custom
                      onClick={singleDocUpdate}
                      name='singleDoc'
                      component={Checkbox}
                      label='I have images of both sides of my ID in a single file.'
                    />
                  </Col>
                </Form.Row>
              </>
            )}
          </Form>
          <ImagePreviewModal
            showImagePreviewModal={showImagePreview}
            handleImagePreview={handleImagePreview}
            imagePreviewUrl={imagePreviewURL}
          />
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button className={'bgvModalSecondaryBtn'} onClick={() => props.prevSubStep()}>
            Go back
          </Button>
          <Button
            className={'bgvModalPrimaryBtn'}
            disabled={
              !isEmpty(props.errors)
                ? true
                : props.values.frontDocUrl
                ? props.values.singleDoc
                  ? false
                  : props.values.backDocUrl
                  ? false
                  : true
                : true
            }
            onClick={props.handleSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </div>
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'address'}
          SubOnHide={modalHide}
        />
      )}
    </Modal>
  );
};
