import { connect } from 'react-redux';

import { setIntegration } from '../../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    integrationData: state?.integrationData || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIntegrationDataAPI: (companyId) => dispatch(setIntegration(companyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
