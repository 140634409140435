import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChatContainer = ({ labels, chatData }) => {
  const data = {
    labels: labels || [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUNE',
      'JULY',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ],
    datasets: [
      {
        backgroundColor: '#2755FE',
        borderColor: '#2755FE',
        barThickness: 10,
        borderRadius: 20,
        hoverBackgroundColor: '#2755FE',
        hoverBorderColor: '#2755FE',
        data: chatData || [10, 59, 80, 81, 56, 89, 56, 76, 89, 56, 65, 34]
      }
    ]
  };

  const barPattern = {
    id: 'barPattern',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { top, bottom, height },
        scales: { x, y }
      } = chart;

      const datasetMeta = chart.getDatasetMeta(0);
      if (datasetMeta?.data?.length) {
        const width = datasetMeta.data[0].width;
        datasetMeta.data.forEach((dataPoint, index) => {
          ctx.save();
          ctx.fillStyle = '#F2F7FF';
          ctx.fillRect(x.getPixelForValue(index) - width / 2, top, width, height - 0.5);
          ctx.restore();
        });
      }
    }
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        }
      },
      y: {
        stacked: false,
        max: Math.max(chatData || [99, 76, 2, 81, 45, 89, 8, 76, 89, 56, 65, 34]),
        grid: {
          display: false
        }
      }
    }
  };

  return <Bar data={data} options={options} plugins={[barPattern]} />;
};

export default BarChatContainer;
