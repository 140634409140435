import {
  getReports as getReportsAPI,
  getReportsExternal as getReportsExternalAPI
} from '../api/company';
import { toCamelCase } from '../components/CompanyAdmin/frontend-common/utils/Utilities';
import { errToastMessage } from '../utils/utilities';

export const INVALIDATE_REPORTS = 'INVALIDATE_REPORTS';
export const REQUEST_REPORTS = 'REQUEST_REPORTS';
export const SET_REPORTS = 'SET_REPORTS';

export const invalidateReports = () => {
  return {
    type: INVALIDATE_REPORTS
  };
};

export const requestReports = () => {
  return {
    type: REQUEST_REPORTS
  };
};

export const setReports = (data) => {
  return {
    type: SET_REPORTS,
    data
  };
};

export const getReports = (data) => (dispatch) => {
  dispatch(requestReports());
  return getReportsAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data.data);
      dispatch(setReports(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
export const getReportsExternal = (data) => (dispatch) => {
  dispatch(requestReports());
  return getReportsExternalAPI(data)
    .then((response) => {
      const data = toCamelCase(response.data);
      dispatch(setReports(data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error);
    });
};
