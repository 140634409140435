export const ICONS = {
  EditCategory:
    'M8.545 4.765L9.235 5.455L2.44 12.25H1.75V11.56L8.545 4.765ZM11.245 0.25C11.0575 0.25 10.8625 0.325 10.72 0.4675L9.3475 1.84L12.16 4.6525L13.5325 3.28C13.602 3.21061 13.6572 3.1282 13.6948 3.03747C13.7325 2.94674 13.7518 2.84948 13.7518 2.75125C13.7518 2.65302 13.7325 2.55576 13.6948 2.46503C13.6572 2.3743 13.602 2.29189 13.5325 2.2225L11.7775 0.4675C11.6275 0.3175 11.44 0.25 11.245 0.25ZM8.545 2.6425L0.25 10.9375V13.75H3.0625L11.3575 5.455L8.545 2.6425Z',
  DeleteCategory:
    'M1.5 12.25C1.5 12.6478 1.65804 13.0294 1.93934 13.3107C2.22064 13.592 2.60218 13.75 3 13.75H9C9.39782 13.75 9.77936 13.592 10.0607 13.3107C10.342 13.0294 10.5 12.6478 10.5 12.25V3.25H1.5V12.25ZM3 4.75H9V12.25H3V4.75ZM8.625 1L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1H8.625Z',
  EditAdmin:
    'M1.66669 14.1666V16.6666H8.33335V15.0916H3.25002V14.1666C3.25002 13.6333 5.85835 12.4166 8.33335 12.4166C9.13335 12.4249 9.92502 12.5333 10.6917 12.7333L11.9584 11.4666C10.7917 11.0749 9.58335 10.8583 8.33335 10.8333C6.10835 10.8333 1.66669 11.9416 1.66669 14.1666ZM8.33335 3.33325C6.49169 3.33325 5.00002 4.82492 5.00002 6.66659C5.00002 8.50825 6.49169 9.99992 8.33335 9.99992C10.175 9.99992 11.6667 8.50825 11.6667 6.66659C11.6667 4.82492 10.175 3.33325 8.33335 3.33325ZM8.33335 8.33325C7.41669 8.33325 6.66669 7.59159 6.66669 6.66659C6.66669 5.74159 7.41669 4.99992 8.33335 4.99992C9.25002 4.99992 10 5.74992 10 6.66659C10 7.58325 9.25835 8.33325 8.33335 8.33325ZM18.0834 11.1249L17.25 11.9583L15.5417 10.2916L16.375 9.45825C16.4607 9.37427 16.5759 9.32723 16.6959 9.32723C16.8158 9.32723 16.931 9.37427 17.0167 9.45825L18.0834 10.5249C18.2584 10.6999 18.2584 10.9916 18.0834 11.1666V11.1249ZM10 15.7833L15.05 10.7333L16.7584 12.3999L11.7584 17.4583H10V15.7833Z',
  DeleteAdmin:
    'M5.00002 15.8333C5.00002 16.2754 5.17562 16.6993 5.48818 17.0118C5.80074 17.3244 6.22466 17.5 6.66669 17.5H13.3334C13.7754 17.5 14.1993 17.3244 14.5119 17.0118C14.8244 16.6993 15 16.2754 15 15.8333V5.83333H5.00002V15.8333ZM6.66669 7.5H13.3334V15.8333H6.66669V7.5ZM12.9167 3.33333L12.0834 2.5H7.91669L7.08335 3.33333H4.16669V5H15.8334V3.33333H12.9167Z',
  Flag: 'M10.1121 0.882142C10.0498 0.835286 9.97847 0.801707 9.90262 0.783476C9.82677 0.765245 9.74799 0.762749 9.67114 0.776142L8.57614 1.05014C7.45255 1.32478 6.27288 1.26146 5.18514 0.868142H5.17014C3.99402 0.432378 2.71987 0.332316 1.49014 0.579142L1.06414 0.670142V0.532142C1.06454 0.462167 1.05105 0.392809 1.02446 0.328084C0.997862 0.263359 0.95869 0.204554 0.90921 0.155074C0.85973 0.105594 0.800924 0.0664218 0.736199 0.0398273C0.671474 0.0132327 0.602116 -0.000256186 0.532141 0.000141783C0.461829 -0.00150042 0.391915 0.0111377 0.326627 0.0372919C0.26134 0.0634461 0.202036 0.102573 0.152304 0.152304C0.102573 0.202036 0.0634463 0.26134 0.0372922 0.326627C0.011138 0.391915 -0.00150043 0.46183 0.000141781 0.532142V13.1681C-0.000256188 13.2381 0.0132325 13.3075 0.039827 13.3722C0.0664216 13.4369 0.105594 13.4957 0.155074 13.5452C0.204554 13.5947 0.26336 13.6339 0.328085 13.6605C0.39281 13.6871 0.462167 13.7005 0.532141 13.7001C0.602454 13.7018 0.672369 13.6891 0.737656 13.663C0.802944 13.6368 0.862248 13.5977 0.911979 13.548C0.961711 13.4982 1.00084 13.4389 1.02699 13.3737C1.05315 13.3084 1.06578 13.2385 1.06414 13.1681V7.61814L1.67214 7.49614C2.7145 7.28341 3.79594 7.37292 4.78914 7.75414L4.83514 7.76914C5.5902 8.04661 6.38773 8.19076 7.19214 8.19514C7.75156 8.19922 8.30895 8.12757 8.84914 7.98214L9.94414 7.70814C10.0559 7.67923 10.1553 7.61489 10.2274 7.52475C10.2995 7.4346 10.3405 7.32352 10.3441 7.20814V1.30814C10.3239 1.14178 10.2409 0.989415 10.1121 0.882142Z',
  CircleTick:
    'M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z',
  NoData:
    'M64.5833 58.3333H61.2917L60.125 57.2083C64.2083 52.4583 66.6667 46.2917 66.6667 39.5833C66.6667 24.625 54.5417 12.5 39.5833 12.5C24.625 12.5 12.5 24.625 12.5 39.5833C12.5 54.5417 24.625 66.6667 39.5833 66.6667C46.2917 66.6667 52.4583 64.2083 57.2083 60.125L58.3333 61.2917V64.5833L79.1667 85.375L85.375 79.1667L64.5833 58.3333ZM39.5833 58.3333C29.2083 58.3333 20.8333 49.9583 20.8333 39.5833C20.8333 29.2083 29.2083 20.8333 39.5833 20.8333C49.9583 20.8333 58.3333 29.2083 58.3333 39.5833C58.3333 49.9583 49.9583 58.3333 39.5833 58.3333Z',
  Identity:
    'M16.5 1H1.5C0.671875 1 0 1.67188 0 2.5V13.5C0 14.3281 0.671875 15 1.5 15H16.5C17.3281 15 18 14.3281 18 13.5V2.5C18 1.67188 17.3281 1 16.5 1ZM16.5 13.5H9.475C9.50313 13.3594 9.5 13.6125 9.5 12.8C9.5 11.8063 8.55937 11 7.4 11C7.0625 11 6.81563 11.25 6 11.25C5.15938 11.25 4.95625 11 4.6 11C3.44062 11 2.5 11.8063 2.5 12.8C2.5 13.6125 2.49375 13.3594 2.525 13.5H1.5V4.5H16.5V13.5ZM11.25 11H14.75C14.8875 11 15 10.8875 15 10.75V10.25C15 10.1125 14.8875 10 14.75 10H11.25C11.1125 10 11 10.1125 11 10.25V10.75C11 10.8875 11.1125 11 11.25 11ZM11.25 9H14.75C14.8875 9 15 8.8875 15 8.75V8.25C15 8.1125 14.8875 8 14.75 8H11.25C11.1125 8 11 8.1125 11 8.25V8.75C11 8.8875 11.1125 9 11.25 9ZM11.25 7H14.75C14.8875 7 15 6.8875 15 6.75V6.25C15 6.1125 14.8875 6 14.75 6H11.25C11.1125 6 11 6.1125 11 6.25V6.75C11 6.8875 11.1125 7 11.25 7ZM6 10C7.10313 10 8 9.10312 8 8C8 6.89687 7.10313 6 6 6C4.89687 6 4 6.89687 4 8C4 9.10312 4.89687 10 6 10Z',
  Employment:
    'M6.78571 2.66667H12.2143V1.33333H6.78571V2.66667ZM19 9.33333V14.3333C19 14.7917 18.8339 15.184 18.5017 15.5104C18.1695 15.8368 17.7701 16 17.3036 16H1.69643C1.22991 16 0.830543 15.8368 0.498326 15.5104C0.166109 15.184 0 14.7917 0 14.3333V9.33333H7.125V11C7.125 11.1806 7.19215 11.3368 7.32645 11.4688C7.46075 11.6007 7.61979 11.6667 7.80357 11.6667H11.1964C11.3802 11.6667 11.5392 11.6007 11.6735 11.4688C11.8078 11.3368 11.875 11.1806 11.875 11V9.33333H19ZM10.8571 9.33333V10.6667H8.14286V9.33333H10.8571ZM19 4.33333V8.33333H0V4.33333C0 3.875 0.166109 3.48264 0.498326 3.15625C0.830543 2.82986 1.22991 2.66667 1.69643 2.66667H5.42857V1C5.42857 0.722222 5.52753 0.486111 5.72545 0.291667C5.92336 0.0972222 6.16369 0 6.44643 0H12.5536C12.8363 0 13.0766 0.0972222 13.2746 0.291667C13.4725 0.486111 13.5714 0.722222 13.5714 1V2.66667H17.3036C17.7701 2.66667 18.1695 2.82986 18.5017 3.15625C18.8339 3.48264 19 3.875 19 4.33333Z',
  Address:
    'M7.71429 5.33333C7.71429 4.59722 7.46317 3.96875 6.96094 3.44792C6.45871 2.92708 5.85268 2.66667 5.14286 2.66667C4.43304 2.66667 3.82701 2.92708 3.32478 3.44792C2.82254 3.96875 2.57143 4.59722 2.57143 5.33333C2.57143 6.06944 2.82254 6.69792 3.32478 7.21875C3.82701 7.73958 4.43304 8 5.14286 8C5.85268 8 6.45871 7.73958 6.96094 7.21875C7.46317 6.69792 7.71429 6.06944 7.71429 5.33333ZM10.2857 5.33333C10.2857 6.09028 10.1752 6.71181 9.95424 7.19792L6.29799 15.2604C6.19085 15.4896 6.03181 15.6701 5.82087 15.8021C5.60993 15.934 5.38393 16 5.14286 16C4.90179 16 4.67578 15.934 4.46484 15.8021C4.25391 15.6701 4.09821 15.4896 3.99777 15.2604L0.331473 7.19792C0.110491 6.71181 0 6.09028 0 5.33333C0 3.86111 0.502232 2.60417 1.5067 1.5625C2.51116 0.520833 3.72321 0 5.14286 0C6.5625 0 7.77455 0.520833 8.77902 1.5625C9.78348 2.60417 10.2857 3.86111 10.2857 5.33333Z',
  Education:
    'M19.2491 7.375L19.4444 10.6667C19.4734 11.1458 19.1768 11.5903 18.5547 12C17.9326 12.4097 17.0826 12.7344 16.0048 12.974C14.9269 13.2135 13.7587 13.3333 12.5 13.3333C11.2413 13.3333 10.0731 13.2135 8.99522 12.974C7.91739 12.7344 7.06742 12.4097 6.44531 12C5.82321 11.5903 5.52662 11.1458 5.55556 10.6667L5.75087 7.375L11.9792 9.26042C12.1383 9.30903 12.3119 9.33333 12.5 9.33333C12.6881 9.33333 12.8617 9.30903 13.0208 9.26042L19.2491 7.375ZM25 4C25 4.15972 24.9204 4.26736 24.7613 4.32292L12.6085 7.98958C12.5796 7.99653 12.5434 8 12.5 8C12.4566 8 12.4204 7.99653 12.3915 7.98958L5.31684 5.84375C5.00579 6.07986 4.74899 6.46701 4.54644 7.00521C4.34389 7.5434 4.22092 8.16319 4.17752 8.86458C4.63325 9.11458 4.86111 9.49306 4.86111 10C4.86111 10.4792 4.65133 10.8507 4.23177 11.1146L4.86111 15.625C4.87558 15.7222 4.84664 15.809 4.77431 15.8854C4.7092 15.9618 4.6224 16 4.51389 16H2.43056C2.32205 16 2.23524 15.9618 2.17014 15.8854C2.0978 15.809 2.06887 15.7222 2.08333 15.625L2.71267 11.1146C2.29311 10.8507 2.08333 10.4792 2.08333 10C2.08333 9.49306 2.31843 9.10764 2.78863 8.84375C2.8682 7.40625 3.22266 6.26042 3.852 5.40625L0.238715 4.32292C0.0795718 4.26736 0 4.15972 0 4C0 3.84028 0.0795718 3.73264 0.238715 3.67708L12.3915 0.0104167C12.4204 0.00347222 12.4566 0 12.5 0C12.5434 0 12.5796 0.00347222 12.6085 0.0104167L24.7613 3.67708C24.9204 3.73264 25 3.84028 25 4Z',
  World:
    'M6.5 6.85714C7.83724 6.85714 9.08702 6.72917 10.2493 6.47321C11.4117 6.21726 12.3286 5.83929 13 5.33929V6.85714C13 7.26786 12.7094 7.64881 12.1283 8C11.5471 8.35119 10.7572 8.62947 9.75846 8.83482C8.75977 9.04018 7.67361 9.14286 6.5 9.14286C5.32639 9.14286 4.24023 9.04018 3.24154 8.83482C2.24284 8.62947 1.45291 8.35119 0.871745 8C0.290582 7.64881 0 7.26786 0 6.85714V5.33929C0.671441 5.83929 1.58832 6.21726 2.75065 6.47321C3.91298 6.72917 5.16276 6.85714 6.5 6.85714ZM6.5 13.7143C7.83724 13.7143 9.08702 13.5863 10.2493 13.3304C11.4117 13.0744 12.3286 12.6964 13 12.1964V13.7143C13 14.125 12.7094 14.506 12.1283 14.8571C11.5471 15.2083 10.7572 15.4866 9.75846 15.692C8.75977 15.8973 7.67361 16 6.5 16C5.32639 16 4.24023 15.8973 3.24154 15.692C2.24284 15.4866 1.45291 15.2083 0.871745 14.8571C0.290582 14.506 0 14.125 0 13.7143V12.1964C0.671441 12.6964 1.58832 13.0744 2.75065 13.3304C3.91298 13.5863 5.16276 13.7143 6.5 13.7143ZM6.5 10.2857C7.83724 10.2857 9.08702 10.1577 10.2493 9.90179C11.4117 9.64583 12.3286 9.26786 13 8.76786V10.2857C13 10.6964 12.7094 11.0774 12.1283 11.4286C11.5471 11.7798 10.7572 12.058 9.75846 12.2634C8.75977 12.4688 7.67361 12.5714 6.5 12.5714C5.32639 12.5714 4.24023 12.4688 3.24154 12.2634C2.24284 12.058 1.45291 11.7798 0.871745 11.4286C0.290582 11.0774 0 10.6964 0 10.2857V8.76786C0.671441 9.26786 1.58832 9.64583 2.75065 9.90179C3.91298 10.1577 5.16276 10.2857 6.5 10.2857ZM6.5 0C7.67361 0 8.75977 0.102679 9.75846 0.308036C10.7572 0.513393 11.5471 0.791667 12.1283 1.14286C12.7094 1.49405 13 1.875 13 2.28571V3.42857C13 3.83929 12.7094 4.22024 12.1283 4.57143C11.5471 4.92262 10.7572 5.20089 9.75846 5.40625C8.75977 5.61161 7.67361 5.71429 6.5 5.71429C5.32639 5.71429 4.24023 5.61161 3.24154 5.40625C2.24284 5.20089 1.45291 4.92262 0.871745 4.57143C0.290582 4.22024 0 3.83929 0 3.42857V2.28571C0 1.875 0.290582 1.49405 0.871745 1.14286C1.45291 0.791667 2.24284 0.513393 3.24154 0.308036C4.24023 0.102679 5.32639 0 6.5 0Z',
  Drug: 'M11.25 0H0.75C0.3375 0 0 0.3375 0 0.75V2.25C0 2.6625 0.3375 3 0.75 3H11.25C11.6625 3 12 2.6625 12 2.25V0.75C12 0.3375 11.6625 0 11.25 0ZM1 15C1 15.55 1.45 16 2 16H10C10.55 16 11 15.55 11 15V4H1V15ZM3 9.25C3 9.1125 3.1125 9 3.25 9H5V7.25C5 7.1125 5.1125 7 5.25 7H6.75C6.8875 7 7 7.1125 7 7.25V9H8.75C8.8875 9 9 9.1125 9 9.25V10.75C9 10.8875 8.8875 11 8.75 11H7V12.75C7 12.8875 6.8875 13 6.75 13H5.25C5.1125 13 5 12.8875 5 12.75V11H3.25C3.1125 11 3 10.8875 3 10.75V9.25Z',
  Court_Record:
    'M15.7803 6.22934L15.0732 5.52224C14.7803 5.22934 14.3055 5.22934 14.0126 5.52224L13.8358 5.69903L10.3002 2.16349L10.477 1.98671C10.7699 1.69381 10.7699 1.21893 10.477 0.926057L9.76993 0.218932C9.47702 -0.0739743 9.00215 -0.0739743 8.70927 0.218932L4.82018 4.10803C4.52727 4.40093 4.52727 4.87581 4.82018 5.16868L5.52727 5.87578C5.82018 6.16868 6.29505 6.16868 6.58793 5.87578L6.76471 5.69899L8.00215 6.93643L5.46965 9.46893L5.29287 9.29215C4.90234 8.90162 4.26918 8.90162 3.87865 9.29215L0.292898 12.878C-0.0976328 13.2685 -0.0976328 13.9017 0.292898 14.2922L1.70712 15.7064C2.09765 16.0969 2.7308 16.0969 3.12134 15.7064L6.70712 12.1206C7.09765 11.7301 7.09765 11.0969 6.70712 10.7064L6.53034 10.5296L9.06284 7.99712L10.3003 9.23456L10.1235 9.41134C9.83059 9.70424 9.83059 10.1791 10.1235 10.472L10.8306 11.1791C11.1235 11.472 11.5984 11.472 11.8912 11.1791L15.7803 7.28999C16.0732 6.99712 16.0732 6.52224 15.7803 6.22934Z',
  CIBIL_Credit_Score:
    'M0 13.5C0 14.3281 0.671875 15 1.5 15H16.5C17.3281 15 18 14.3281 18 13.5V8H0V13.5ZM6 11.375C6 11.1687 6.16875 11 6.375 11H10.625C10.8313 11 11 11.1687 11 11.375V12.625C11 12.8313 10.8313 13 10.625 13H6.375C6.16875 13 6 12.8313 6 12.625V11.375ZM2 11.375C2 11.1687 2.16875 11 2.375 11H4.625C4.83125 11 5 11.1687 5 11.375V12.625C5 12.8313 4.83125 13 4.625 13H2.375C2.16875 13 2 12.8313 2 12.625V11.375ZM18 2.5V4H0V2.5C0 1.67188 0.671875 1 1.5 1H16.5C17.3281 1 18 1.67188 18 2.5Z',
  Reference:
    'M3 7C4.10313 7 5 6.10313 5 5C5 3.89687 4.10313 3 3 3C1.89688 3 1 3.89687 1 5C1 6.10313 1.89688 7 3 7ZM17 7C18.1031 7 19 6.10313 19 5C19 3.89687 18.1031 3 17 3C15.8969 3 15 3.89687 15 5C15 6.10313 15.8969 7 17 7ZM18 8H16C15.45 8 14.9531 8.22188 14.5906 8.58125C15.85 9.27188 16.7437 10.5188 16.9375 12H19C19.5531 12 20 11.5531 20 11V10C20 8.89687 19.1031 8 18 8ZM10 8C11.9344 8 13.5 6.43437 13.5 4.5C13.5 2.56562 11.9344 1 10 1C8.06563 1 6.5 2.56562 6.5 4.5C6.5 6.43437 8.06563 8 10 8ZM12.4 9H12.1406C11.4906 9.3125 10.7688 9.5 10 9.5C9.23125 9.5 8.5125 9.3125 7.85938 9H7.6C5.6125 9 4 10.6125 4 12.6V13.5C4 14.3281 4.67188 15 5.5 15H14.5C15.3281 15 16 14.3281 16 13.5V12.6C16 10.6125 14.3875 9 12.4 9ZM5.40938 8.58125C5.04688 8.22188 4.55 8 4 8H2C0.896875 8 0 8.89687 0 10V11C0 11.5531 0.446875 12 1 12H3.05938C3.25625 10.5188 4.15 9.27188 5.40938 8.58125Z',
  MenuDots:
    'M12 16C12.5304 16 13.0391 16.2107 13.4142 16.5858C13.7893 16.9609 14 17.4696 14 18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20C11.4696 20 10.9609 19.7893 10.5858 19.4142C10.2107 19.0391 10 18.5304 10 18C10 17.4696 10.2107 16.9609 10.5858 16.5858C10.9609 16.2107 11.4696 16 12 16ZM12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10ZM12 4C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6C14 6.53043 13.7893 7.03914 13.4142 7.41421C13.0391 7.78929 12.5304 8 12 8C11.4696 8 10.9609 7.78929 10.5858 7.41421C10.2107 7.03914 10 6.53043 10 6C10 5.46957 10.2107 4.96086 10.5858 4.58579C10.9609 4.21071 11.4696 4 12 4Z',
  EditCandidateCategory:
    'M3.33341 13.3335H12.6667C12.8436 13.3335 13.0131 13.4037 13.1382 13.5288C13.2632 13.6538 13.3334 13.8234 13.3334 14.0002C13.3334 14.177 13.2632 14.3465 13.1382 14.4716C13.0131 14.5966 12.8436 14.6668 12.6667 14.6668H3.33341C3.1566 14.6668 2.98703 14.5966 2.86201 14.4716C2.73699 14.3465 2.66675 14.177 2.66675 14.0002C2.66675 13.8234 2.73699 13.6538 2.86201 13.5288C2.98703 13.4037 3.1566 13.3335 3.33341 13.3335ZM2.66675 10.0002L9.33341 3.3335L11.3334 5.3335L4.66675 12.0002H2.66675V10.0002ZM10.0001 2.66683L11.3334 1.3335L13.3334 3.3335L11.9994 4.6675L10.0001 2.66683Z',
  GreenTick:
    'M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM10.001 16.413L6.288 12.708L7.7 11.292L9.999 13.587L15.293 8.293L16.707 9.707L10.001 16.413Z',
  BasicDetailsBGV:
    'M11.4141 10.9312C11.1194 10.2331 10.6916 9.59891 10.1547 9.06406C9.61947 8.52767 8.9854 8.10001 8.28754 7.80469C8.28129 7.80156 8.27504 7.8 8.26879 7.79688C9.24223 7.09375 9.87504 5.94844 9.87504 4.65625C9.87504 2.51562 8.14067 0.78125 6.00004 0.78125C3.85941 0.78125 2.12504 2.51562 2.12504 4.65625C2.12504 5.94844 2.75785 7.09375 3.73129 7.79844C3.72504 7.80156 3.71879 7.80312 3.71254 7.80625C3.01254 8.10156 2.38441 8.525 1.84535 9.06563C1.30896 9.60089 0.881296 10.235 0.585977 10.9328C0.295855 11.616 0.139386 12.3485 0.125039 13.0906C0.124622 13.1073 0.127547 13.1239 0.133642 13.1394C0.139737 13.1549 0.148878 13.1691 0.160527 13.181C0.172176 13.193 0.186098 13.2025 0.201471 13.2089C0.216844 13.2154 0.233358 13.2188 0.250039 13.2188H1.18754C1.25629 13.2188 1.31098 13.1641 1.31254 13.0969C1.34379 11.8906 1.82816 10.7609 2.68441 9.90469C3.57035 9.01875 4.74691 8.53125 6.00004 8.53125C7.25316 8.53125 8.42973 9.01875 9.31567 9.90469C10.1719 10.7609 10.6563 11.8906 10.6875 13.0969C10.6891 13.1656 10.7438 13.2188 10.8125 13.2188H11.75C11.7667 13.2188 11.7832 13.2154 11.7986 13.2089C11.814 13.2025 11.8279 13.193 11.8396 13.181C11.8512 13.1691 11.8603 13.1549 11.8664 13.1394C11.8725 13.1239 11.8755 13.1073 11.875 13.0906C11.8594 12.3438 11.7047 11.6172 11.4141 10.9312ZM6.00004 7.34375C5.28285 7.34375 4.60785 7.06406 4.10004 6.55625C3.59223 6.04844 3.31254 5.37344 3.31254 4.65625C3.31254 3.93906 3.59223 3.26406 4.10004 2.75625C4.60785 2.24844 5.28285 1.96875 6.00004 1.96875C6.71723 1.96875 7.39223 2.24844 7.90004 2.75625C8.40785 3.26406 8.68754 3.93906 8.68754 4.65625C8.68754 5.37344 8.40785 6.04844 7.90004 6.55625C7.39223 7.06406 6.71723 7.34375 6.00004 7.34375Z',
  ContactDetailsBGV:
    'M12.7047 2.72969L11.0406 1.06719C10.8375 0.864063 10.5656 0.75 10.2781 0.75C9.99062 0.75 9.71875 0.8625 9.51562 1.06719L7.72344 2.85625C7.52031 3.05937 7.40625 3.33281 7.40625 3.62031C7.40625 3.90937 7.51875 4.17969 7.72344 4.38437L9.12344 5.78594C8.80451 6.52904 8.34626 7.20421 7.77344 7.775C7.2 8.35156 6.52969 8.80625 5.78594 9.12812L4.38594 7.72656C4.18281 7.52344 3.91094 7.40937 3.62344 7.40937C3.48163 7.40885 3.34114 7.43663 3.21021 7.4911C3.07928 7.54557 2.96054 7.62562 2.86094 7.72656L1.06719 9.51562C0.864063 9.71875 0.75 9.99219 0.75 10.2797C0.75 10.5687 0.8625 10.8391 1.06719 11.0437L2.72969 12.7062C3.07656 13.0531 3.55469 13.2516 4.04531 13.2516C4.14687 13.2516 4.24531 13.2437 4.34531 13.2266C6.41406 12.8859 8.46719 11.7844 10.125 10.1281C11.7812 8.46875 12.8813 6.41562 13.225 4.34531C13.3234 3.75781 13.1266 3.15312 12.7047 2.72969ZM12.1172 4.15937C11.8125 6.00156 10.8219 7.83906 9.32969 9.33125C7.8375 10.8234 6.00156 11.8141 4.15938 12.1187C3.92813 12.1578 3.69062 12.0797 3.52187 11.9125L1.88906 10.2797L3.62031 8.54687L5.49219 10.4219L5.50625 10.4359L5.84375 10.3109C6.86707 9.9347 7.79635 9.34044 8.56717 8.56934C9.338 7.79825 9.93193 6.86877 10.3078 5.84531L10.4328 5.50781L8.54531 3.62187L10.2766 1.88906L11.9094 3.52187C12.0781 3.69062 12.1562 3.92812 12.1172 4.15937Z',
  IdentityBGV:
    'M13.5 0.5H0.5C0.223437 0.5 0 0.723437 0 1V11C0 11.2766 0.223437 11.5 0.5 11.5H13.5C13.7766 11.5 14 11.2766 14 11V1C14 0.723437 13.7766 0.5 13.5 0.5ZM12.875 10.375H1.125V1.625H12.875V10.375ZM8.53594 5.4375H10.4641C10.4844 5.4375 10.5 5.38125 10.5 5.3125V4.5625C10.5 4.49375 10.4844 4.4375 10.4641 4.4375H8.53594C8.51562 4.4375 8.5 4.49375 8.5 4.5625V5.3125C8.5 5.38125 8.51562 5.4375 8.53594 5.4375ZM8.61094 7.6875H11.5125C11.5734 7.6875 11.6234 7.63125 11.6234 7.5625V6.8125C11.6234 6.74375 11.5734 6.6875 11.5125 6.6875H8.61094C8.55 6.6875 8.5 6.74375 8.5 6.8125V7.5625C8.5 7.63125 8.55 7.6875 8.61094 7.6875ZM2.5 8.51562H3.18594C3.25156 8.51562 3.30469 8.46406 3.30937 8.39844C3.36875 7.60938 4.02812 6.98438 4.82812 6.98438C5.62813 6.98438 6.2875 7.60938 6.34688 8.39844C6.35156 8.46406 6.40469 8.51562 6.47031 8.51562H7.15625C7.1732 8.51565 7.18999 8.51222 7.20557 8.50555C7.22116 8.49888 7.23523 8.48911 7.24692 8.47683C7.25862 8.46455 7.26769 8.45003 7.27359 8.43413C7.27949 8.41824 7.2821 8.40131 7.28125 8.38437C7.2375 7.55156 6.78125 6.82656 6.11562 6.41406C6.40916 6.09139 6.57135 5.67058 6.57031 5.23438C6.57031 4.26719 5.79063 3.48438 4.82969 3.48438C3.86875 3.48438 3.08906 4.26719 3.08906 5.23438C3.08906 5.68906 3.26094 6.10156 3.54375 6.41406C3.20489 6.62405 2.92188 6.91293 2.71891 7.25603C2.51593 7.59914 2.399 7.98627 2.37812 8.38437C2.37188 8.45625 2.42812 8.51562 2.5 8.51562ZM4.82812 4.42188C5.27344 4.42188 5.63594 4.78594 5.63594 5.23438C5.63594 5.68281 5.27344 6.04688 4.82812 6.04688C4.38281 6.04688 4.02031 5.68281 4.02031 5.23438C4.02031 4.78594 4.38281 4.42188 4.82812 4.42188Z',
  EmploymentBGV:
    'M12.6967 2.74159C12.6889 2.72753 12.678 2.71346 12.6655 2.70253C12.5874 2.6244 12.4608 2.6244 12.3827 2.70253L9.69049 5.39471L8.62955 4.33378L11.3233 1.64003C11.3358 1.62753 11.3452 1.61503 11.3546 1.60096C11.4108 1.50565 11.3796 1.38378 11.2842 1.32753C9.74986 0.421276 7.74049 0.629088 6.42018 1.94784C5.3733 2.99471 5.02643 4.47909 5.38111 5.81346L0.80299 10.3916C0.756115 10.4385 0.75924 10.515 0.807677 10.5635L3.45924 13.215C3.50768 13.2635 3.5858 13.2666 3.63111 13.2197L8.20768 8.64315C9.54361 8.9994 11.028 8.65409 12.0764 7.60565C13.3952 6.28534 13.603 4.27596 12.6967 2.74159ZM11.2811 6.80878C10.3733 7.71659 9.0108 7.89159 7.92799 7.33378L7.79049 7.47128L7.78893 7.46971L3.50768 11.7525L2.27018 10.515L5.86549 6.91971C5.86549 6.91971 5.86549 6.92128 5.86705 6.92128L6.69205 6.09628C6.13424 5.01346 6.30924 3.65096 7.21705 2.74315C7.50984 2.4501 7.86244 2.22371 8.25075 2.07944C8.63907 1.93517 9.05395 1.87642 9.46705 1.90721L7.39049 3.98221C7.2967 4.07611 7.24401 4.2034 7.24401 4.33612C7.24401 4.46884 7.2967 4.59613 7.39049 4.69003L9.3358 6.63534C9.4297 6.72913 9.55699 6.78181 9.68971 6.78181C9.82243 6.78181 9.94972 6.72913 10.0436 6.63534L12.1186 4.56034C12.1764 5.36971 11.8936 6.19784 11.2811 6.80878Z',
  EducationBGV:
    'M13.5 0.515626H9.925C9.15781 0.515626 8.40781 0.735938 7.7625 1.15156L7 1.64063L6.2375 1.15156C5.59283 0.736018 4.84199 0.515215 4.075 0.515626H0.5C0.223437 0.515626 0 0.739063 0 1.01563V9.89063C0 10.1672 0.223437 10.3906 0.5 10.3906H4.075C4.84219 10.3906 5.59219 10.6109 6.2375 11.0266L6.93125 11.4734C6.95156 11.4859 6.975 11.4938 6.99844 11.4938C7.02187 11.4938 7.04531 11.4875 7.06563 11.4734L7.75937 11.0266C8.40625 10.6109 9.15781 10.3906 9.925 10.3906H13.5C13.7766 10.3906 14 10.1672 14 9.89063V1.01563C14 0.739063 13.7766 0.515626 13.5 0.515626ZM4.075 9.26563H1.125V1.64063H4.075C4.62812 1.64063 5.16562 1.79844 5.62969 2.09688L6.39219 2.58594L6.5 2.65625V9.875C5.75625 9.475 4.925 9.26563 4.075 9.26563ZM12.875 9.26563H9.925C9.075 9.26563 8.24375 9.475 7.5 9.875V2.65625L7.60781 2.58594L8.37031 2.09688C8.83438 1.79844 9.37187 1.64063 9.925 1.64063H12.875V9.26563ZM5.20156 3.64063H2.29844C2.2375 3.64063 2.1875 3.69375 2.1875 3.75781V4.46094C2.1875 4.525 2.2375 4.57813 2.29844 4.57813H5.2C5.26094 4.57813 5.31094 4.525 5.31094 4.46094V3.75781C5.3125 3.69375 5.2625 3.64063 5.20156 3.64063ZM8.6875 3.75781V4.46094C8.6875 4.525 8.7375 4.57813 8.79844 4.57813H11.7C11.7609 4.57813 11.8109 4.525 11.8109 4.46094V3.75781C11.8109 3.69375 11.7609 3.64063 11.7 3.64063H8.79844C8.7375 3.64063 8.6875 3.69375 8.6875 3.75781ZM5.20156 5.82813H2.29844C2.2375 5.82813 2.1875 5.88125 2.1875 5.94531V6.64844C2.1875 6.7125 2.2375 6.76563 2.29844 6.76563H5.2C5.26094 6.76563 5.31094 6.7125 5.31094 6.64844V5.94531C5.3125 5.88125 5.2625 5.82813 5.20156 5.82813ZM11.7016 5.82813H8.79844C8.7375 5.82813 8.6875 5.88125 8.6875 5.94531V6.64844C8.6875 6.7125 8.7375 6.76563 8.79844 6.76563H11.7C11.7609 6.76563 11.8109 6.7125 11.8109 6.64844V5.94531C11.8125 5.88125 11.7625 5.82813 11.7016 5.82813Z',
  AddressBGV:
    'M11.3531 3.51719C11.059 2.83888 10.6348 2.22472 10.1047 1.70937C9.5728 1.18937 8.94576 0.776654 8.25781 0.49375C7.54219 0.196875 6.78281 0.046875 6 0.046875C5.21719 0.046875 4.45781 0.196875 3.74219 0.492188C3.05 0.778125 2.42969 1.1875 1.89531 1.70781C1.3655 2.22345 0.941431 2.83753 0.646875 3.51562C0.342187 4.21875 0.1875 4.96562 0.1875 5.73438C0.1875 6.8375 0.451563 7.93594 0.970313 8.99531C1.3875 9.84688 1.97031 10.6766 2.70469 11.4656C3.95938 12.8125 5.275 13.6359 5.64844 13.8562C5.75444 13.9189 5.87533 13.9518 5.99844 13.9516C6.12031 13.9516 6.24062 13.9203 6.34844 13.8562C6.72187 13.6359 8.0375 12.8125 9.29219 11.4656C10.0266 10.6781 10.6094 9.84688 11.0266 8.99531C11.5484 7.9375 11.8125 6.84062 11.8125 5.73594C11.8125 4.96719 11.6578 4.22031 11.3531 3.51719ZM6 12.7531C4.97031 12.0984 1.3125 9.50625 1.3125 5.73594C1.3125 4.51875 1.79844 3.375 2.68125 2.5125C3.56719 1.64844 4.74531 1.17188 6 1.17188C7.25469 1.17188 8.43281 1.64844 9.31875 2.51406C10.2016 3.375 10.6875 4.51875 10.6875 5.73594C10.6875 9.50625 7.02969 12.0984 6 12.7531ZM6 3.10938C4.48125 3.10938 3.25 4.34062 3.25 5.85938C3.25 7.37813 4.48125 8.60938 6 8.60938C7.51875 8.60938 8.75 7.37813 8.75 5.85938C8.75 4.34062 7.51875 3.10938 6 3.10938ZM7.2375 7.09688C7.07519 7.25965 6.8823 7.38873 6.66992 7.47668C6.45754 7.56464 6.22987 7.60973 6 7.60938C5.53281 7.60938 5.09375 7.42656 4.7625 7.09688C4.59972 6.93457 4.47065 6.74167 4.38269 6.5293C4.29474 6.31692 4.24964 6.08924 4.25 5.85938C4.25 5.39219 4.43281 4.95312 4.7625 4.62187C5.09375 4.29062 5.53281 4.10938 6 4.10938C6.46719 4.10938 6.90625 4.29062 7.2375 4.62187C7.56875 4.95312 7.75 5.39219 7.75 5.85938C7.75 6.32656 7.56875 6.76562 7.2375 7.09688Z',
  ConsentBGV:
    'M8.75 3.875V3.125C8.75 3.05625 8.69375 3 8.625 3H2.625C2.55625 3 2.5 3.05625 2.5 3.125V3.875C2.5 3.94375 2.55625 4 2.625 4H8.625C8.69375 4 8.75 3.94375 8.75 3.875ZM2.625 5.25C2.55625 5.25 2.5 5.30625 2.5 5.375V6.125C2.5 6.19375 2.55625 6.25 2.625 6.25H5.5C5.56875 6.25 5.625 6.19375 5.625 6.125V5.375C5.625 5.30625 5.56875 5.25 5.5 5.25H2.625ZM8.5 7.0625C6.63594 7.0625 5.125 8.57344 5.125 10.4375C5.125 12.3016 6.63594 13.8125 8.5 13.8125C10.3641 13.8125 11.875 12.3016 11.875 10.4375C11.875 8.57344 10.3641 7.0625 8.5 7.0625ZM10.1797 12.1172C9.73125 12.5656 9.13437 12.8125 8.5 12.8125C7.86562 12.8125 7.26875 12.5656 6.82031 12.1172C6.37187 11.6687 6.125 11.0719 6.125 10.4375C6.125 9.80313 6.37187 9.20625 6.82031 8.75781C7.26875 8.30937 7.86562 8.0625 8.5 8.0625C9.13437 8.0625 9.73125 8.30937 10.1797 8.75781C10.6281 9.20625 10.875 9.80313 10.875 10.4375C10.875 11.0719 10.6281 11.6687 10.1797 12.1172ZM9.89062 9.25H9.19844C9.15781 9.25 9.12031 9.26875 9.09688 9.30156L8.10469 10.6734L7.74375 10.175C7.73219 10.1589 7.71693 10.1458 7.69925 10.1368C7.68158 10.1279 7.66201 10.1233 7.64219 10.1234H6.95312C6.85156 10.1234 6.79219 10.2391 6.85156 10.3219L8.00469 11.9172C8.05469 11.9859 8.15625 11.9859 8.20625 11.9172L9.99063 9.44844C10.0516 9.36563 9.99219 9.25 9.89062 9.25ZM4.875 12.3125H1.25V1.3125H10V6.6875C10 6.75625 10.0562 6.8125 10.125 6.8125H11C11.0688 6.8125 11.125 6.75625 11.125 6.6875V0.6875C11.125 0.410937 10.9016 0.1875 10.625 0.1875H0.625C0.348437 0.1875 0.125 0.410937 0.125 0.6875V12.9375C0.125 13.2141 0.348437 13.4375 0.625 13.4375H4.875C4.94375 13.4375 5 13.3813 5 13.3125V12.4375C5 12.3687 4.94375 12.3125 4.875 12.3125Z',
  TickBGV:
    'M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM10.0234 4.71406L6.73281 9.27656C6.68682 9.34076 6.62619 9.39306 6.55595 9.42914C6.48571 9.46523 6.40787 9.48405 6.32891 9.48405C6.24994 9.48405 6.17211 9.46523 6.10186 9.42914C6.03162 9.39306 5.97099 9.34076 5.925 9.27656L3.97656 6.57656C3.91719 6.49375 3.97656 6.37813 4.07812 6.37813H4.81094C4.97031 6.37813 5.12187 6.45469 5.21562 6.58594L6.32812 8.12969L8.78438 4.72344C8.87813 4.59375 9.02812 4.51562 9.18906 4.51562H9.92188C10.0234 4.51562 10.0828 4.63125 10.0234 4.71406Z',
  EditLarge:
    'M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C20.8027 6.94749 20.8763 6.8376 20.9264 6.71662C20.9766 6.59565 21.0024 6.46597 21.0024 6.335C21.0024 6.20403 20.9766 6.07435 20.9264 5.95338C20.8763 5.8324 20.8027 5.72251 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.06 6.19L3 17.25V21H6.75L17.81 9.94L14.06 6.19Z',
  EmailPreferencesLarge:
    'M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z',
  DeleteLarge:
    'M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z'
};
