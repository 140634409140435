import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import AppConstants from '../../core-components/AppConstants';
import Loader from '../../core-components/Loader';
import classes from './PaymentRequest.module.scss';
import Image from '../../core-components/Image';

const Invoice = ({ invoiceId, companyId, token }) => {
  const docUrl = `${AppConstants.developmentAPIURL}payments/purchase-order/proforma-invoice?invoiceId=${invoiceId}&companyId=${companyId}&is_proforma_invoice=true&token=${token}`;
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const renderLoader = () => {
    return (
      <div className={classes.loaderContainer}>
        <Loader style={{ textAlign: 'center' }} />
      </div>
    );
  };
  const handleDownload = async () => {
    try {
      const response = await fetch(docUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${invoiceId}.pdf`); // Adjust the filename if needed

      // Append the link to the body and simulate a click
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  return (
    <div className={classes.RenderLink}>
      <div onClick={handleDownload}>
        <span className={classes.tooltip}>
          <Image name='download.png' className={classes.downloadIcon} />
          <span className={classes.tooltiptext}>{'Download'}</span>
        </span>
      </div>
      <Document
        file={{ url: docUrl }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={renderLoader}
        className='paymentDocument'
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export default React.memo(Invoice);
