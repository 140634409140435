import { connect } from 'react-redux';

import {
  makeGlobalSearch,
  getCompanyPaymentDetails,
  getWalletDetail
} from '../../../actions/company';
import { logout } from '../../../actions/authentication';

const mapStateToProps = (state) => {
  return {
    jwt: state.jwt,
    searchResult: state.search,
    profile: state.profile ? state.profile.profile : {},
    paymentDetails: state.paymentDetails,
    packageType:
      state?.profile?.profile?.company_user_mapping?.company?.payments_company_model_config
        ?.model_type,
    walletDetail: state.walletDetail || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    makeGlobalSearch: (query) => dispatch(makeGlobalSearch({ search: query })),
    logout: () => dispatch(logout()),
    getWalletDetail: () => dispatch(getWalletDetail()),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
