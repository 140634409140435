import { connect } from 'react-redux';
import { getAlumniRecordsTrends, getAlumniRequestTrends } from '/src/actions/company';

const mapStateToProps = (state) => {
  return {
    alumniRequestTrend: state.alumniRequestTrend,
    alumniRecordsTrend: state.alumniRecordsTrend
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecordsTrend: () => dispatch(getAlumniRecordsTrends()),
    getRequestTrend: () => dispatch(getAlumniRequestTrends())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
