export default (state, action) => {
  switch (action.type) {
    case 'TOGGLE_EDIT_MODAL':
      return { ...state, showEditModal: !state.showEditModal };
    case 'TOGGLE_DELETE_MODAL':
      return { ...state, showDeleteModal: !state.showDeleteModal };
    case 'TOGGLE_GEN_TOKEN_MODAL':
      return { ...state, showGenTokenModal: !state.showGenTokenModal };
  }
};
