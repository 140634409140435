import AppConstants from '../core-components/AppConstants';
import http from './http';

export const login = (data) => {
  return http.post('auth/login', data);
};

export const forgotPassword = (data) => {
  return http.post('auth/resetpassword', data);
};

export const setPassword = (data) => {
  return http.post('auth/setnewpassword', data);
};

export const checkPassword = (token, resetPassword) => {
  return http.get(`auth/validate-password?token=${token}&resetPassword=${resetPassword}`);
};

export const editCompanyProfile = (data) => {
  return http.put('company/profile', data);
};

export const editPersonalProfile = (data) => {
  return http({
    method: 'put',
    url: `${AppConstants.saDevelopementAPIURL}company/admin`,
    data: data
  });
};

export const googleOrMsLogin = (path, data) => {
  return http.post(path, data);
};

export const logout = () => {
  return http.delete('auth/logout');
};
