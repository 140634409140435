import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from '../ChargesApproval.module.scss';

export default function InsuffFundsModal(props) {
  return (
    <div>
      <Modal
        size='lg'
        onHide={props?.setShowInsuffFunds}
        show={props?.showInsuffFunds}
        dialogClassName='educationModal adcApproveModal'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className={styles.modalBodyHeader}>
              <span>Insufficient Funds</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.addEditEducationModalBody}>
          <div>
            <p className={styles.modalBodyText}>
              You do not have sufficient balance to approve all the additional costs.
            </p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <div className={styles.modalFooter}>
          <div className={styles.modalFooterText}></div>
          <div className={styles.modalFooterButtons}>
            <Button
              className={`${styles.modalButton} ${styles.cancleButton}`}
              variant='outline-primary'
              onClick={props?.setShowInsuffFunds}
            >
              CANCEL
            </Button>
            <Button
              className={`btn btn-primary ml-2 ${styles.modalButton} ${styles.accentColor}`}
              type='button'
              onClick={props?.onClickInsuff}
            >
              Recharge
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
