import React, { useState } from 'react';

import { Card, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../../../core-components/Icon';
import Image from '../../../../core-components/Image';
import ChecksIncluded from './ChecksIncluded';
import styles from './Package.module.scss';
import CreateNameModal from '../CreateSubtypeFriendlyName';
import { convertToIndianNumeration, getPackageUnitIndication } from '../../../../utils/utilities';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setViewBuyPackages } from '../../../../actions/company';

const getBalanceShareText = (balanceSharedWith = []) => {
  let text = '';
  balanceSharedWith.map((item, index) => {
    text += `${Object.values(item).join('')}${index < balanceSharedWith.length - 1 ? ' and ' : ''}`;
  });
  return text;
};

const Package = ({
  pDetails,
  showBorder,
  showBuyBtn,
  showPricing,
  packageType,
  isAadhaarAllowed,
  createFriendlyNameFlow,
  getPackages,
  isShowOnly,
  packages,
  setPackages
}) => {
  const [showCreateNameModal, setShowCreateNameModal] = useState(false);
  const [friendlyName, setFriendlyName] = useState(null);

  const createNameModalHandler = () => {
    setShowCreateNameModal(!showCreateNameModal);
  };

  const updateFriendlyName = (value) => {
    let packagesSubtypes = { ...packages };
    setFriendlyName(value);
    let packageDetail = packages.rows.filter(p => p.subtypeId === pDetails.subtypeId)[0];
    packageDetail['subtypeFriendlyName'] = value;
    const indexOfSubtype = packagesSubtypes.rows.findIndex(pck => pck.subtypeId === pDetails.subtypeId);
    packagesSubtypes.rows[indexOfSubtype] = packageDetail;
    setPackages(packagesSubtypes);
  }
  const renderPackageDetails = () => {
    return (
      <>
        <p className={styles.subtypeName}>{pDetails?.subtypeName}</p>
        <p className={styles.friendlyName}>
          {friendlyName
            ? `${friendlyName}`
            : pDetails?.subtypeFriendlyName
              ? `${pDetails?.subtypeFriendlyName}`
              : <span className={styles.addNickName} onClick={createNameModalHandler}>Add a Nickname</span>}
          {(friendlyName || pDetails?.subtypeFriendlyName) && (
            <OverlayTrigger
              placement='right'
              overlay={
                <Tooltip>
                  <p>Set a name that is easier for you to remember</p>
                </Tooltip>
              }
            >
              <div style={{ marginLeft: '5px' }}>
                <Icon
                  icon={'EditCandidateCategory'}
                  color={'#333333'}
                  height={16}
                  width={16}
                  onClick={createNameModalHandler}
                />
              </div>
            </OverlayTrigger>
          )}
        </p>
        {packageType === 'PREPAID' && (
          <div className={styles.balanceText}>
            {`Current balance: ${convertToIndianNumeration(pDetails?.currentBalance)}`}
            <span className={styles.packgeUnitIndication}>
              {renderPackageUnitIndication(
                pDetails?.leastRechargeUnitsTier,
                pDetails?.currentBalance
              )}
            </span>
          </div>
        )}
        {/* Balance shared with */}
        {pDetails?.balanceSharedWith &&
          pDetails.balanceSharedWith.length &&
          packageType === 'PREPAID' ? (
          <div className={styles.balanceSharedWithContainer}>
            <span style={{ marginRight: '3px' }}>
              <Image name={'InfoIconFilled.svg'} />
            </span>
            <p className={styles.balanceSharedWithContainerDescription}>
              {`Balance shared with ${getBalanceShareText(pDetails.balanceSharedWith)}`}
            </p>
          </div>
        ) : null}
      </>
    );
  };

  const renderChecksIncluded = () => {
    return (
      <>
        <p className={styles.checkIncludedText}>{'CHECKS INCLUDED'}</p>
        <ChecksIncluded config={pDetails?.checks} isAadhaarAllowed={isAadhaarAllowed} />
      </>
    );
  };

  const renderPackageUnitIndication = (leastTier, currentBalance) => {
    const unitIndication = getPackageUnitIndication(leastTier, currentBalance);
    switch (unitIndication) {
      case 'NILL':
        return (
          <>
            <Image name={'nillPackageUnit.svg'} className={styles.unitIndicationIcon} />
            <span className={styles.unitNillIndicationText}>Buy units to proceed</span>
          </>
        );
      case 'LOW':
        return (
          <>
            <Image name={'lowPackageUnit.svg'} className={styles.unitIndicationIcon} />
            <span className={styles.unitLowIndicationText}>You are running low on units</span>
          </>
        );
      default:
        return <Image name={'CheckCircleIcon.svg'} className={styles.unitIndicationIcon} />;
    }
  };
  const renderPricing = () => {
    return (
      !isShowOnly ?
        <>
          <p className={styles.amountInfoContainer}>
            <span className={styles.amount}>
              &#8377;{convertToIndianNumeration(pDetails?.costPerCandidate)}
            </span>
            <span className={styles.amountDescription}>{' per candidate'}</span>
          </p>
          {
            packageType === 'PREPAID' && (
              <p className={`${styles.amountDescription} ${styles.marginBottom6}`}>
                {`Starting at ${convertToIndianNumeration(pDetails?.startingAtCount)} candidates for `}
                &#8377;{convertToIndianNumeration(pDetails?.costPerCandidate)}
              </p>
            )
          }
          {
            packageType === 'PREPAID' && showBuyBtn ? (
              <Link to={{ pathname: '/packages', search: `?_id=${pDetails.subtypeId}` }}>
                <Button variant='primary'>{'Buy now'}</Button>
              </Link>
            ) : null
          }
        </> : null
    );
  };

  return (
    <div className={`${styles.wrapper} ${showBorder ? styles.showBorder : ''}`}>
      <Card className={styles.packageDetailsCard}>
        <Card.Body className={styles.packageDetailsCardBody}>
          <Row className={styles.packageDetailsRow}>
            <Col lg={8} md={8} sm={6} xs={12} className={styles.packageDetailsColLeft}>
              <div className={styles.packageDetailsSection}>{renderPackageDetails()}</div>
              <div className={styles.checksIncludedSection}>{renderChecksIncluded()}</div>
            </Col>
            <Col lg={4} md={4} sm={6} xs={12} className={styles.packageDetailsColRight}>
              {showPricing ? <div>{renderPricing()}</div> : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <CreateNameModal
        show={showCreateNameModal}
        title={`Set Custom Name for ${pDetails?.packageName}`}
        placeholder='Nickname'
        button='Confirm'
        onHide={createNameModalHandler}
        subtypeName={pDetails?.subtypeName || ''}
        friendlyName={friendlyName}
        subTypeId={pDetails?.subtypeId}
        packageId={pDetails?.packageId}
        setFriendlyName={(value) => updateFriendlyName(value)}
        getPackages={getPackages}
        subtypeFriendlyName={pDetails?.subtypeFriendlyName}
      />
    </div>
  );
}

export const mapPropsToValues = (state) => {
  return {
    packages: state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null,
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    setPackages: (data) => dispatch(setViewBuyPackages(data)),
  }
}
export default connect(mapPropsToValues, mapDispatchToProps)(Package);
