import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {invalidateInsuffCredits, getInsuffCredits as getInsuffCreditsAPI } from '../../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    insuffCredits: state.insuffCredits && !isEmpty(state.insuffCredits) ? state.insuffCredits : null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateInsuffCredits: () => dispatch(invalidateInsuffCredits()),
    getInsuffCredits: (data) => dispatch(getInsuffCreditsAPI(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps);
