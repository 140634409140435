import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from '../ChargesApproval.module.scss';
export default function RechargeModal(props) {
  const [loading, setIsLoading] = useState(false);

  const saveHandler = () => {
    setIsLoading(true);
    props?.rejectHandle();
  };

  return (
    <div>
      <Modal
        size='lg'
        onHide={props?.setRejectModal}
        show={props?.rejectModal}
        dialogClassName='educationModal adcApproveModal'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className={styles.modalBodyHeader}>
              <span>Reject cost?</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.addEditEducationModalBody}>
          <div>
            <p className={styles.modalBodyText}>
              This action will reject all additional cost requests associated with this check and
              change the check status to CLOSED. This action cannot be undone.
            </p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer> */}
        <div className={styles.modalFooter}>
          <div className={styles.modalFooterText}></div>
          <div className={styles.modalFooterButtons}>
            <Button
              className={`${styles.modalButton} ${styles.cancleButton}`}
              variant='outline-primary'
              onClick={props?.setRejectModal}
            >
              CANCEL
            </Button>
            <Button
              className={`btn btn-danger ml-2 ${styles.modalButton} ${styles.deleteButton}`}
              type='button'
              onClick={saveHandler}
              disabled={loading}
            >
              Reject
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
