import React, { useState } from 'react';
import { Form, InputGroup, FormControl } from 'react-bootstrap'; // Add FormControl import
import styles from './MobileInput.module.scss';
import countryOptions from '../../../../../utils/countryCodesEn.json';
import { getIn } from 'formik';
import cn from 'classnames';
import { getTrimmedValue } from '../../../../../utils/utilities';
const MobileInput = (props) => {
  const [handleInputFocus, setInputFocus] = useState(false);
  const { dropdownName, form, setFieldValue, field, handleChange, handleBlur, valuex } = props;
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d+$/.test(keyValue)) {
      event.preventDefault();
    }
  };
  return (
    <Form>
      {props.label ? (
        <Form.Label className={styles.formLabel}>
          HR Mobile Number <span>*</span>
        </Form.Label>
      ) : null}
      <InputGroup
        className={
          form && getIn(form.touched, field.name) && getIn(form.errors, field.name)
            ? cn(
                styles.inputGroupErrBorder,
                handleInputFocus ? styles.inputGroupErrBorderFocus : ''
              )
            : cn(styles.inputGroup, handleInputFocus ? styles.inputGroupFocus : '')
        }
      >
        <InputGroup.Prepend className={styles.inputPrepend}>
          <Form.Control
            as='select'
            custom
            className={styles.countrySelect}
            onChange={(e) =>
              form.setFieldValue(
                dropdownName,
                countryOptions.find((country) => country.value === e.target.value).value
              )
            }
            // value={selectedCountry.code}
          >
            {countryOptions.map((country) => (
              <option key={country.label} value={country.value}>
                {country.label}
              </option>
            ))}
          </Form.Control>
        </InputGroup.Prepend>
        <FormControl
          type='tel'
          pattern='[0-9]*'
          placeholder={props.placeholder ? props.placeholder : 'Enter mobile number'}
          name={field.name}
          className={styles.mobileField}
          onFocus={() => {
            setInputFocus(true);
          }}
          onChange={(event) => {
            if (props.onChange) {
              props.onChange(event);
            } else {
              form.setFieldValue(field.name, event.target.value);
            }
          }}
          onBlur={(event) => {
            form.setFieldValue(field.name, getTrimmedValue(event.target.value, false));
            handleBlur(event);
            setInputFocus(false);
          }}
          value={valuex}
          onKeyPress={handleKeyPress}
        />
      </InputGroup>
    </Form>
  );
};

export default MobileInput;
