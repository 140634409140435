import React from 'react';

import { Card, Row, Col } from 'react-bootstrap';
import { convertToIndianNumeration } from '../../../../../utils/utilities';
import styles from './CandidateOption.module.scss';

function CandidateOption(props) {
  let firstOption = {};

  const getDiscountPercentage = (item) => {
    let discount = 0;
    let originalPrice = firstOption.costPerCandidate * item.candidateCount;
    discount =
      (100 * (originalPrice - item.candidateCount * item.costPerCandidate)) / originalPrice;

    return Math.round(discount.toFixed(2));
  };

  const OptionCard = ({ item, index }) => {
    if (index === 0) {
      firstOption = item;
    }

    return (
      <Col lg={6} md={6} sm={12} xs={12}>
        <Card
          data-testid="candidate-option-card"
          className={`${styles.cardContainer} ${props?.selectedOptionIndex === index ? styles.activeCard : ''
            }`}
          onClick={() => {
            props?.handleOptionSelection(index);
          }}
        >
          <Card.Body className={styles.cardBodyContainer}>
            <p
              className={
                props?.selectedOptionIndex === index
                  ? styles.activeCandidateCount
                  : styles.candidateCount
              }
            >
              <span>{`${convertToIndianNumeration(item.candidateCount)} candidates`}</span>
              {index > 0 ? (
                <span className={styles.discountBadge}>{`Save ${getDiscountPercentage(
                  item
                )}%`}</span>
              ) : null}
            </p>
            <p className={styles.costPerCandidate}>
              <span className={styles.amountText}>
                &#8377;{convertToIndianNumeration(item.costPerCandidate)}
              </span>
              &nbsp;&nbsp;{'per candidate'}
            </p>
            <p className={styles.totalAmount}>
              <span>{'Total'}</span>
              <span className={styles.amountText}>
                &nbsp;&nbsp;&#8377;
                {convertToIndianNumeration(item.costPerCandidate * item.candidateCount)}
              </span>
            </p>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div className={styles.candidateOptionsContainer}>
        <p className={styles.heading}>{'How many package units do you want to buy?'}</p>
        <Row>
          {props?.optionList && props.optionList.length
            ? props.optionList.map((item, index) => {
              return <OptionCard item={item} key={index} index={index} />;
            })
            : null}
        </Row>
      </div>
    </>
  );
}

export default CandidateOption;
