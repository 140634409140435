// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J0EuNBTY9K0gDe6HaFCI {
  display: flex;
  align-items: center;
  border: 1px solid #c2cfff;
  border-radius: 4px;
}
.J0EuNBTY9K0gDe6HaFCI .J4x4IdW7fmEDk_BeAZdq {
  width: 85px;
}
.J0EuNBTY9K0gDe6HaFCI .jeQhAmxwdjCLHHA9AuUW {
  box-sizing: border-box;
  border-left: 1px solid #999;
  height: 24px;
  width: 260px;
  align-items: center;
  display: flex;
  padding-left: 12px;
  padding-right: 15px;
}
.J0EuNBTY9K0gDe6HaFCI .jeQhAmxwdjCLHHA9AuUW .FBIJFi0OXn_NbWSg37sQ {
  border: none;
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 0 0 0 14px !important;
  height: 24px;
}
.J0EuNBTY9K0gDe6HaFCI .jeQhAmxwdjCLHHA9AuUW .FBIJFi0OXn_NbWSg37sQ:focus {
  box-shadow: unset;
}

.wY03QvtCSVup6eDuiHCg {
  color: #333333;
}

.B6BtQbhlHBntLOjR5Bq7 {
  color: #333333 !important;
  font-size: 14px;
  height: 45px !important;
  border-color: #c2cfff;
}

.VeoU3opeoCjnh0SoHnHu {
  border: none !important;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/SearchFieldWithDropdown/SearchFieldWithDropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,sBAAA;EACA,2BAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AAEJ;AADI;EACE,YAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,8BAAA;EACA,YAAA;AAGN;AAFM;EACE,iBAAA;AAIR;;AAEA;EACE,cAAA;AACF;;AACA;EACE,yBAAA;EACA,eAAA;EACA,uBAAA;EACA,qBAAA;AAEF;;AAAA;EACE,uBAAA;EACA,2BAAA;AAGF","sourcesContent":[".searchFilter {\n  display: flex;\n  align-items: center;\n  border: 1px solid #c2cfff;\n  border-radius: 4px;\n  .searchFilterType {\n    width: 85px;\n  }\n  .searchInput {\n    box-sizing: border-box;\n    border-left: 1px solid #999;\n    height: 24px;\n    width: 260px;\n    align-items: center;\n    display: flex;\n    padding-left: 12px;\n    padding-right: 15px;\n    .inputText {\n      border: none;\n      color: #666666;\n      font-size: 14px;\n      line-height: 20px;\n      padding: 0 0 0 14px !important;\n      height: 24px;\n      &:focus {\n        box-shadow: unset;\n      }\n    }\n  }\n}\n\n.dropDownPlaceholder {\n  color: #333333;\n}\n.dropDownStyle {\n  color: #333333 !important;\n  font-size: 14px;\n  height: 45px !important;\n  border-color: #c2cfff;\n}\n.dropDownBorderNone {\n  border: none !important;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFilter": `J0EuNBTY9K0gDe6HaFCI`,
	"searchFilterType": `J4x4IdW7fmEDk_BeAZdq`,
	"searchInput": `jeQhAmxwdjCLHHA9AuUW`,
	"inputText": `FBIJFi0OXn_NbWSg37sQ`,
	"dropDownPlaceholder": `wY03QvtCSVup6eDuiHCg`,
	"dropDownStyle": `B6BtQbhlHBntLOjR5Bq7`,
	"dropDownBorderNone": `VeoU3opeoCjnh0SoHnHu`
};
export default ___CSS_LOADER_EXPORT___;
