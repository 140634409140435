import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import styles from './Wallet.module.scss';
import TopSection from './TopSection';
import ArrearsBreakdown from './ArrearsBreakdown';
import AmountComponent from './AmountComponent/AmountComponent';
import Image from '../../core-components/Image';
import cn from 'classnames';
import Loader from '../../core-components/Loader';
export default (props) => {
  const { walletDetail } = props;

  if (props.walletDetail.loading) {
    return (
      <div style={{ padding: '240px' }}>
        <Loader />
      </div>
    );
  }

  const banner = (name, text, classStyle = '') => {
    return (
      <Card className={cn(styles.bannerCardContainer, classStyle)}>
        <div className={styles.bannerDivContainer}>
          <Image name={name} isSA={props.isSA} className={styles.warningRedIcon} />
          <span className={styles.bannerText}>{text}</span>
        </div>
      </Card>
    );
  };

  const getBanner = () => {
    if (
      walletDetail.currentBalance > walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
      walletDetail.currentBalance > walletDetail.averageAdc
    ) {
      return null;
    } else if (
      walletDetail.currentBalance >= walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
      walletDetail.currentBalance < walletDetail.averageAdc
    ) {
      return banner(
        'warningPackage.svg',
        'Current balance is too low. Recharge to avoid verification delays.',
        styles.lowBalance
      );
    } else if (
      walletDetail.currentBalance <
      walletDetail.arrearsTotalAmount + walletDetail.pendingCosts
    ) {
      if (!walletDetail.arrearsTotalAmount) {
        return banner(
          'warningRedIcon.svg',
          'Verifications delayed as balance is insufficient to approve costs. Recharge immediately.',
          styles.criticalLow
        );
      } else {
        return banner(
          'warningRedIcon.svg',
          'Verifications delayed due to arrears. Recharge immediately.',
          styles.criticalLow
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.containerHeader}>Wallet</p>
      <div className={styles.containerSubHeader}>Balances</div>
      {getBanner()}
      <Card className={styles.arrearsCardContainer}>
        <Accordion>
          {/* ------------- Top Section -------------*/}
          <TopSection walletDetail={props.walletDetail} />
          {/* ------------- Breakdown Section -------------*/}
          <Accordion.Collapse eventKey='0'>
            {walletDetail?.arrears && walletDetail.arrears.length ? (
              <ArrearsBreakdown walletDetail={props.walletDetail} />
            ) : (
              <></>
            )}
          </Accordion.Collapse>
        </Accordion>
      </Card>
      {/* ------------- Amount Section -------------*/}
      <AmountComponent {...props} />
    </div>
  );
};
