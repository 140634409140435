import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import Webcam from 'react-webcam';

import Image from '../../../../core-components/Image';

import styles from './BGVCamera.module.scss';

export default (props) => {
  const [userMedia, setUserMedia] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [error, setError] = useState(props.isCameraUploadError);
  const videoConstraints = {
    facingMode: 'environment'
  };

  useEffect(() => {
    setError(props.isCameraUploadError);
  }, [props.isCameraUploadError]);

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUserMedia(false);
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const handleOnUserMedia = React.useCallback(() => setUserMedia(true), [userMedia]);

  const handleRetake = () => {
    setUserMedia(true);
    setImgSrc('');
  };

  const handleBackIconClick = () => {
    if (imgSrc) {
      handleRetake();
    } else {
      setError(false);
      props.handleCameraModal();
    }
  };

  const generateUniqueFileName = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8); // Generates a 6-character random string
    return `${timestamp}-${randomString}.png`;
  };

  const convertBase64ToFile = async () => {
    try {
      const response = await fetch(imgSrc);
      const blob = await response.blob();
      const file = new File([blob], generateUniqueFileName(), { type: 'image/png' });
      const formData = new FormData();
      formData.append('file', file);

      setSubmitting(true);
      if (props.type === 'single') {
        await props.handleSingleUpload(formData, false, setSubmitting, file);
      } else if (props.type === 'multiple') {
        await props.handleMultipleUpload(formData, false, props.index, setSubmitting, file);
      }
    } catch (err) {
      setSubmitting(false);
      console.error(err);
      setError(true);
    }
  };

  return (
    <Modal
      show={props.showBgvCameraModal}
      onHide={props.handleCameraModal}
      size='sm'
      className={error ? 'bgvCameraErrorModalContainer' : 'bgvCameraModalContainer'}
      data-testid="bgv-camera"
    >
      {!error ? (
        <>
          <Modal.Header className={styles.bgvCameraHeaderContainer}>
            <Image name='back_icon.png' onClick={() => handleBackIconClick()} data-testid="back-icon" />
          </Modal.Header>
          <Modal.Body className={styles.bgvCameraBodyContainer}>
            {imgSrc ? (
              <Image src={imgSrc} style={{ objectFit: 'contain', margin: 'auto' }} data-testid="captured-image" />
            ) : (
              <Webcam
                ref={webcamRef}
                audio={false}
                onUserMedia={() => handleOnUserMedia()}
                onUserMediaError={(err) => console.log('onUserMediaError', err)}
                videoConstraints={videoConstraints}
                screenshotFormat='image/png'
                screenshotQuality={1}
                data-testid="webcam"
              />
            )}
          </Modal.Body>
          <Modal.Footer className={styles.bgvCameraFooterContainer}>
            {imgSrc ? (
              <Row>
                <Col sm={12} xs={12} className={styles.bgvCameraFooterTextContainer}>
                  <p>Is the photo clear?</p>
                  <p>
                    It should show the front of your document - nothing blurry or cut-off and
                    no-glare
                  </p>
                </Col>
                <Col sm={12} xs={12} className={styles.bgvCameraFooterButtonContainer}>
                  <Button type='button' className={styles.retakeBtn} onClick={() => handleRetake()} data-testid="retake-button">
                    Retake photo
                  </Button>
                  <Button type='button' className={styles.confirmBtn} onClick={convertBase64ToFile} data-testid="confirm-button">
                    {submitting ? 'Uploading...' : 'Yes, looks good'}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Image name='image_capture.png' onClick={() => capture()} data-testid="capture-button" />
            )}
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header className={styles.bgvCameraErrorHeaderContainer} closeButton>
            <Modal.Title>Can’t upload image</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.bgvCameraErrorBodyContainer} data-testid="error-modal">
            <Row>
              <Col xs={12} sm={12} className={styles.bgvCameraErrorTextContainer}>
                <p>We’ve detected some problems with your image:</p>
                <ul>
                  <li>
                    We weren’t able to detect your document. Please take a clear photo where the
                    document is in focus.
                  </li>
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className={styles.bgvCameraErrorFooterContainer}>
            <Button
              type='button'
              block={true}
              className={styles.confirmBtn}
              onClick={() => handleBackIconClick()}
              data-testid="error-close-button"
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
