import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

import styles from './Star.module.scss';

const renderStar = (isFull, color, isHover, index) => {
  const icon = isFull || (isHover !== -1 && index <= isHover) ? faStar : regularStar;
  return <FontAwesomeIcon color={color} icon={icon} size='xl' />;
};

const Star = ({ isFull, onClick, color, index, setIsHover, isHover }) => (
  <span
    data-testid="star"
    onMouseEnter={() => setIsHover(index)}
    onMouseLeave={() => setIsHover(-1)}
    className={styles.star}
    onClick={onClick}
  >
    {renderStar(isFull, color, isHover, index)}
  </span>
);

export default Star;
