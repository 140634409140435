import React from 'react';
import styles from './ProFeatures.module.scss';
import Image from '../../core-components/Image';
import { Card, Row, Col } from 'react-bootstrap';

export default (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>Pro Features</div>
      <div className={styles.cardContainer}>
        <Card>
          <Card.Body>
            <Row>
              <Col lg={8} md={8} sm={6} xs={12}>
                <div className={styles.whatsAppContainer}>
                  <div>
                    <Image
                      name='icons8-whatsapp-240.svg'
                      className={styles.imageStyle}
                      isSA={props.isSA}
                    />
                  </div>
                  <div>
                    <span className={styles.cardHeadText}>Whatsapp Connect</span>
                    <br />
                    <span className={styles.cardSubtext}>
                      ₹4,000 <span className={styles.cardSecondarySubtext}> per candidate</span>
                    </span>
                  </div>
                </div>
                <div>
                  <span className={styles.cardText}>
                    Send alerts & reminders to candiates over whatsapp
                  </span>
                  <ul className={styles.listText}>
                    <li>Drastically reduces candidates response time by 92% </li>
                    <li>Less involvement for HR-Ops</li>
                  </ul>
                </div>
              </Col>
              <Col className={styles.comingSoonBanner}>
                <div className={styles.comingSoonText}>Coming soon</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col lg={8} md={8} sm={6} xs={12}>
                <div>
                  <Image
                    name='icons8-gmail-240.svg'
                    className={styles.imageStyle}
                    isSA={props.isSA}
                  />
                  <span className={styles.cardHeadText}>Send email as you (HR)</span>
                </div>
                <div>
                  <span className={styles.cardText}>
                    Send alerts & reminders to candiates over whatsapp
                  </span>
                  <ul className={styles.listText}>
                    <li>Drastically reduces candidates response time by 92% </li>
                    <li>Less involvement for HR-Ops</li>
                  </ul>
                </div>
              </Col>
              <Col className={styles.comingSoonBanner}>
                <div className={styles.comingSoonText}>Coming soon</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
