// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ii10BeWALCyizlWB3zAz {
  width: 100%;
  height: 100px;
  background-size: cover;
  margin: 15px auto 0 auto;
  max-width: 1200px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Banners/Razorpay/RazorpayBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".bannerContainer {\n    width: 100%;\n    height: 100px;\n    background-size: cover;\n    margin: 15px auto 0 auto;\n    max-width: 1200px;\n    border-radius: 4px;\n    cursor: pointer;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `ii10BeWALCyizlWB3zAz`
};
export default ___CSS_LOADER_EXPORT___;
