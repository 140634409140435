// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wSbxD0u0Il71yy8tpo4U {
  background: #ffffff;
  color: #2755fe;
  border: none;
  margin-right: 32px;
  box-shadow: none;
  font-size: 16px;
  font-family: Poppins, sans-serif;
}
.wSbxD0u0Il71yy8tpo4U:hover {
  background: #ffffff;
  color: #2755fe;
  border: none;
}
.wSbxD0u0Il71yy8tpo4U:active {
  background: #ffffff !important;
  color: #2755fe !important;
  border: none !important;
}
.wSbxD0u0Il71yy8tpo4U:focus {
  box-shadow: none !important;
}

.fhy4FJWBt5okLQ2JlGXR {
  height: 25px;
  margin-right: 24px;
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/CommonComponent/BgvGoBackBtn/BgvGoBackBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gCAAA;AACF;AAAE;EACE,mBAAA;EACA,cAAA;EACA,YAAA;AAEJ;AAAE;EACE,8BAAA;EACA,yBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,2BAAA;AAEJ;;AACA;EACE,YAAA;EACA,kBAAA;EACA,eAAA;AAEF","sourcesContent":[".goBackBtn {\n  background: #ffffff;\n  color: #2755fe;\n  border: none;\n  margin-right: 32px;\n  box-shadow: none;\n  font-size: 16px;\n  font-family: Poppins, sans-serif;\n  &:hover {\n    background: #ffffff;\n    color: #2755fe;\n    border: none;\n  }\n  &:active {\n    background: #ffffff !important;\n    color: #2755fe !important;\n    border: none !important;\n  }\n  &:focus {\n    box-shadow: none !important;\n  }\n}\n.goBackBtnMobile {\n  height: 25px;\n  margin-right: 24px;\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goBackBtn": `wSbxD0u0Il71yy8tpo4U`,
	"goBackBtnMobile": `fhy4FJWBt5okLQ2JlGXR`
};
export default ___CSS_LOADER_EXPORT___;
