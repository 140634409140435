import React from 'react';
import { Card } from 'react-bootstrap';

import styles from './HRReferredInfoBanner.module.scss';
import Image from '../../../../../core-components/Image';
import { CA_REFERRAL_AMOUNT } from '../../../../../utils/commonConstant';
import { convertToIndianNumeration } from '../../../../../utils/utilities';

export default function HRReferredInfoBanner({ count, email }) {
  return (
    <Card className={styles.successReferralCard}>
      <div className={styles.titleContainer}>
        <span className={styles.titleText}>
          You have referred {count} HR
          {count > 1 ? 's' : ''}
        </span>
        <Image name='tickCircleGreen.svg' className={styles.titleIcon} />
      </div>
      <div className={styles.infoText}>
        Thanks for connecting HR with us. Your referral is under processing. On successful referral,
        you will receive a{' '}
        <span>
          gift card worth{' '}
          <span className={styles.descAmount}>
            &#8377;{convertToIndianNumeration(CA_REFERRAL_AMOUNT)}
          </span>
        </span>{' '}
        on your registered Email: <span>{email || '-'}</span>
      </div>
    </Card>
  );
}
