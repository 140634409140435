import { isEmpty } from 'lodash';

export const isAllStepsCompleted = (item) => {
  return (
    item &&
    !isEmpty(item) &&
    item.hasOwnProperty('step_1') &&
    !isEmpty(item.step_1) &&
    item.hasOwnProperty('step_2') &&
    !isEmpty(item.step_2) &&
    item.hasOwnProperty('step_3') &&
    !isEmpty(item.step_3)
  );
};

const allFieldsFilled = (ref_ad) => {
  return (
    ref_ad?.full_name !== null &&
    ref_ad?.full_name !== '' &&
    ref_ad?.email !== null &&
    ref_ad?.email !== '' &&
    ref_ad?.phone !== null &&
    ref_ad?.phone !== '' &&
    ref_ad?.designation &&
    ref_ad?.designation !== null &&
    ref_ad?.designation !== ''
  );
};
export const isAllRefFilled = (item) => {
  let isFilled = true;
  if (item.hasOwnProperty('step_3') && !isEmpty(item.step_3)) {
    if (item.step_3.refCount === 0) {
      isFilled =
        item.step_3?.manager_info?.full_name !== null &&
        item.step_3?.manager_info?.full_name !== '';
    } else if (item.step_3.refCount > 0) {
      const isFieldsFilled = item.step_3.references_additional.every((ref_ad) =>
        allFieldsFilled(ref_ad)
      );
      isFilled =
        item.step_3.references_additional?.length === item.step_3.refCount && isFieldsFilled;
    }
  }
  return isFilled;
};
