import React from 'react';
import { Form } from 'react-bootstrap';
import styles from '../RecommendedChecksModal.module.scss';
import Image from '../../../../../../core-components/Image';

const CheckItem = (props) => {
  const {
    checkName = '',
    checkInfo = '',
    checkIcon = null,
    toggleItem,
    checkedItems,
    check
  } = props;

  return (
    <div className={styles['check-item-parent']}>
      <div className={styles['checkbox-and-icon-parent']}>
        <div className={styles['item-checkbox']}>
          <Form.Check
            key={check}
            checked={checkedItems[check]}
            onChange={() => toggleItem(check)}
            type='checkbox'
            className={styles['checkbox']}
          />
        </div>
        <div className={styles['item-check-icon']}>
          <Image name={checkIcon} />
        </div>
      </div>
      <div className={styles['checkname-and-info-parent']}>
        <div className={styles['checkname-and-info-wrapper']}>
          <div className={styles['checkname-div']}>
            <div className={styles['checkname']}>{checkName}</div>
          </div>
          <p className={styles['check-info-text']}>{checkInfo}</p>
        </div>
      </div>
    </div>
  );
};

export default CheckItem;
