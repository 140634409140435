import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import { toCamelCase } from '../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  invalidateCertificateUpload as invalidateCertificateUploadAPI,
  invalidateGradesheetUpload as invalidateGradesheetUploadAPI,
  setCertificateUpload as setCertificateUploadAPI,
  setGradesheetUpload as setGradesheetUploadAPI,
  invalidateTemporaryEducationData,
  setBgvSubmission as setBgvSubmissionAPI
} from '../../../../../actions/bgv';

const noOfValidDetail = (educationCheck) => {
  let count = 0;

  for (let i in educationCheck) {
    const keys = Object.keys(educationCheck[i]);

    if (keys.includes('step1') && keys.includes('step2')) {
      count++;
    }
  }
  return count;
};

const checkEducationSaved = (bgvCachedData) => {
  if (
    bgvCachedData &&
    bgvCachedData.data &&
    bgvCachedData.data.cacheData &&
    bgvCachedData.data.cacheData.educationCheck
  ) {
    return noOfValidDetail(bgvCachedData.data.cacheData.educationCheck);
  } else {
    return 0;
  }
};

const fetchNestedStepValue = (bgvCachedData) => {
  const splittedData = bgvCachedData.currentState.split('#');

  if (splittedData.length > 1 && sessionStorage.getItem('reload') === 'false') {
    return parseInt(splittedData[1]);
  } else {
    return 1;
  }
};

const mapStateToProps = (state) => {
  const bgvCachedData = !state.bgvCachedData.data
      ? null
      : toCamelCase({ ...state.bgvCachedData.data }),
    bgvConfig = !isEmpty(state.bgvPackageConfig.data)
      ? { ...state.bgvPackageConfig.data, loading: state.bgvPackageConfig.loading }
      : null,
    educationSaved = checkEducationSaved(toCamelCase(state.bgvCachedData));

  return {
    bgvCachedData,
    bgvConfig,
    educationCheck:
      bgvCachedData && bgvCachedData.cacheData && bgvCachedData.cacheData.educationCheck
        ? bgvCachedData.cacheData.educationCheck
        : null,
    isSkipEducation:
      bgvCachedData && bgvCachedData.isSkipEducation ? bgvCachedData.isSkipEducation : false,
    isLoadingBgvCache: state?.bgvCachedData?.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    invalidateCertificateUpload: () => dispatch(invalidateCertificateUploadAPI()),
    invalidateGradesheetUpload: () => dispatch(invalidateGradesheetUploadAPI()),
    setCertificateUpload: (data) => dispatch(setCertificateUploadAPI(data)),
    setGradesheetUpload: (data) => dispatch(setGradesheetUploadAPI(data)),
    invalidateTemporaryEducationData: () => dispatch(invalidateTemporaryEducationData()),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
