import React from 'react';

import Image from '../../../../core-components/Image';

import styles from './NoRecords.module.scss';

export default (props) => {
  return (
    <div className={styles.noRecordContainer}>
      <Image name={'noRecordsIcon.svg'} />
      {'No records available'}
    </div>
  );
};
