import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classes from './RequestPayment.module.scss';
import DatePicker from 'react-datepicker';
import 'react-multi-email/style.css';
import { Field } from 'formik';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import _, { isEmpty } from 'lodash';
import { CA_ROLES } from './../../utils/CommonConstants';
import Select from 'react-select';
import { getTrimmedValue, validateEmail } from '../../core-components/Utilities/utilities';
import Image from '../../core-components/Image/Image';
import './RequestPaymentModal.scss';
import CustomizedChip from '../../core-components/CustomizedChip/CustomizedChip';

export default function RequestPaymentModal(props) {
  const {
    show,
    setFieldValue,
    values,
    errors,
    onClose,
    isSA,
    isSubmitting,
    handleSubmit,
    zohoBookStatus,
    profile,
    administrators,
    getAdministrators,
    getRequestPaymentUsers,
    invalidateAdministrators,
    invalidateRequestPaymentUsers,
    invalidateZohoZonnection,
    requestPaymentUsers
  } = props;
  const [adminHrContacts, setAdminHrContacts] = useState([]);
  const [showZohoError, setShowZohoError] = useState(false);
  const [lastUsedContacts, setLastUsedContacts] = useState([]);
  const [otherContacts, setOtherContacts] = useState([]);
  const [requestorOptions, setRequestorOptions] = useState([]);
  const companyId = new URLSearchParams(document.location.search).get('company_id');
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentEmailError, setCurrentEmailError] = useState(false);
  const divRef = useRef(null);
  useEffect(() => {
    getAdministrators(50, 0, companyId);
    getRequestPaymentUsers(companyId);
    return () => {
      invalidateAdministrators();
      invalidateRequestPaymentUsers();
      invalidateZohoZonnection();
    };
  }, []);

  useEffect(() => {
    if (
      administrators &&
      !isEmpty(administrators) &&
      !administrators?.loading &&
      requestPaymentUsers &&
      !isEmpty(requestPaymentUsers) &&
      !requestPaymentUsers?.loading &&
      administrators.admins &&
      requestPaymentUsers.paymentUsers
    ) {
      if (isSA && !administrators.loading) {
        const requestorOptionsTemp = administrators?.admins.map((admin) => {
          return {
            value: admin?.id,
            label: admin?.name,
            email: admin?.email
          };
        });
        setRequestorOptions(requestorOptionsTemp);
      }
      // All CA users except Logged In CA user for CA side or selected requestor for SA side
      const recentUsersID = requestPaymentUsers?.paymentUsers?.recentOrderUsers
        .map((u) => u.userIdFk)
        .slice(0, 3);
      const allOtherAdminsAndHR = administrators?.admins?.filter(
        (admin) =>
          admin?.id !== (profile?.id ? profile?.id : values.requestor) &&
          !recentUsersID.includes(admin?.id)
      );
      setAdminHrContacts(
        administrators?.admins.length === 1 && isSA ? administrators?.admins : allOtherAdminsAndHR
      );

      // All last payment requested users except except Logged In CA user for CA side or selected Requestor for SA side
      const lastRequestPaymentUsers = requestPaymentUsers?.paymentUsers?.recentOrderUsers
        .filter((admin) => admin.userIdFk !== (profile?.id ? profile?.id : values.requestor))
        .slice(0, 3);
      setLastUsedContacts(lastRequestPaymentUsers);

      // All other users except Logged In CA user for CA side or selected requestor for SA side and last payment used
      const otherRequestPaymentUsers = requestPaymentUsers?.paymentUsers?.otherContacts;
      const otherAdminRequestee = otherRequestPaymentUsers.filter(
        (admin) => admin.userIdFk !== (profile?.id ? profile?.id : values.requestor)
      );
      setOtherContacts(otherAdminRequestee);
    }
  }, [values.requestor, administrators, requestPaymentUsers]);
  useEffect(() => {
    if (
      !isEmpty(zohoBookStatus) &&
      !zohoBookStatus.loading &&
      !isEmpty(zohoBookStatus.zohoConnection) &&
      !zohoBookStatus.zohoConnection.zohoBooksContactId
    ) {
      setShowZohoError(true);
    }
  }, [zohoBookStatus]);
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
      divRef.current.scrollBy({
        top: event.deltaY,
        left: event.deltaX
      });
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);
  useEffect(() => {
    setFieldValue('externalRequestee', emails);
    return () => {
      setFieldValue('externalRequestee', []);
    };
  }, [emails]);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const isFormValid = () => {
    if (values.dueDate && values.requestee.length > 0) {
      if (
        values.requestee.includes('Other') &&
        values.externalRequestee &&
        values.externalRequestee.length > 0
      ) {
        return true;
      }
      if (values.requestee.filter((item) => item !== 'Other').length > 0) {
        return true;
      }
    }
    return false;
  };
  const isError = () => {
    if (errors && !isEmpty(errors)) {
      return true;
    } else {
      return false;
    }
  };

  const checkEmailBounced = (requestee) => {
    return requestee?.emailRejectType
      ? requestee?.emailRejectType !== 'NA'
      : requestee?.userEmailRejectType
      ? requestee?.userEmailRejectType !== 'NA'
      : false;
  };
  const getRequestee = (requestee) => {
    return (
      <div className={classes.checkboxContainer}>
        <label className={classes.contactCheckbox}>
          <Field type='checkbox' name='requestee' value={requestee.email} />
          <div className={classes.checkboxLabel}>
            <div>
              <div className={classes.checkboxTitle}>
                <span className={classes.name}>
                  {requestee.userIdFk || requestee.id ? requestee.name : requestee.email}
                </span>
                {requestee.userIdFk || requestee.id ? (
                  <span
                    className={`${classes.tags} ${
                      CA_ROLES[requestee.accessLevel] === 'Admin'
                        ? classes.AdminTags
                        : CA_ROLES[requestee.accessLevel] === 'HR'
                        ? classes.HrOPsTags
                        : classes.FinanceTags
                    }`}
                  >
                    {CA_ROLES[requestee.accessLevel]}
                  </span>
                ) : null}
                <span className={classes.bouncedEmailDiv}>
                  {checkEmailBounced(requestee) ? (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip id={'EmailBounced'}>
                          <p>{'Email has bounced'}</p>
                        </Tooltip>
                      }
                    >
                      <span className={classes.mailIcon}>
                        <Image isSA={isSA} name='emailBouncedIcon.svg' />
                      </span>
                    </OverlayTrigger>
                  ) : null}
                </span>
              </div>
              {requestee.userIdFk || requestee.id ? (
                <span className={classes.checkboxSubtitle}>{requestee.email}</span>
              ) : null}
            </div>
          </div>
        </label>
      </div>
    );
  };
  const handleRequestorChange = (e) => {
    setFieldValue('requestor', e?.value);
  };

  const handleFocus = () => {
    if (!values.requestee.includes('Other')) {
      setFieldValue('requestee', [...values.requestee, 'Other']);
    }
  };

  const handleBlur = (e) => {
    if (values.externalRequestee.length === 0 && values.requestee.includes('Other')) {
      setFieldValue('requestee', [...values.requestee.filter((item) => item !== 'Other')]);
    }
  };
  const handleKeyDown = (evt) => {
    if (['Enter', ','].includes(evt.key)) {
      evt.preventDefault();
      var email = currentEmail.trim();
      if (email) {
        if (validateEmail(email)) {
          setEmails([...emails, email]);
          setFieldValue('externalRequestee', emails);
          setCurrentEmail('');
          setCurrentEmailError(false);
        } else {
          setCurrentEmailError(true);
        }
      }
    }
  };
  const handleChange = (evt) => {
    if (currentEmailError) {
      setCurrentEmailError(false);
    }
    const trimmedValue = getTrimmedValue(evt.target.value || '', false) || '';
    setCurrentEmail(trimmedValue);
  };
  const deleteEmailChip = (index) => {
    const emailChips = [...emails];
    emailChips.splice(index, 1);
    setEmails(emailChips);
  };
  const multiEmailClass = cn(classes.multiEmail, currentEmailError ? classes.multiEmailError : '');
  return (
    <Modal
      show={show}
      onHide={() => onClose(0)}
      backdrop='static'
      centered
      dialogClassName={
        classes.paymentRequestModal +
        ' paymentModalV1 ' +
        (lastUsedContacts.length > 0 && ' paymentModalV2')
      }
      backdropClassName={'customBGVModalBackdrop'}
    >
      <Modal.Header closeButton className={classes.modalHeader} closeVariant='primary'>
        <div className={classes.title}>Request Payment</div>
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        <div className={classes.content}>
          Email this purchase request to your organization's finance contact for payment completion.
        </div>
        <div className={`${classes.contentList} mt-2`}>
          <ul>
            <li>
              A proforma invoice and payment link will be sent to the selected contacts including
              yourself
            </li>
            <li>
              On successful payment, a tax invoice will be instantly emailed to all recipients
            </li>
          </ul>
        </div>
        {!isSA && showZohoError ? (
          <div
            onClick={() => {
              window.open('mailto:cs@springverify.com', '_blank');
            }}
            className={classes.zohoWarningContainer}
          >
            <Image name={'warningRedIcon.svg'} isSA={isSA} className={classes.warningRedIcon} />
            <span className={classes.zohoWarning}>
              This option is currently disabled for your organisation. Contact{' '}
              <span className={classes.cta}>SpringVerify</span> to enable it.
            </span>
          </div>
        ) : null}
        {isSA ? (
          <div className={classes.requestorContainer}>
            <label htmlFor=''>
              Select Requestor <span className={classes.required}>*</span>
            </label>
            <Select
              className={classes.requestSelect}
              wrapperClassName={classes.requestSelect}
              classNamePrefix='select'
              defaultValue={values.requestor}
              isSearchable={true}
              onChange={handleRequestorChange}
              name='requestor'
              options={requestorOptions}
            />
          </div>
        ) : null}
        <div
          ref={divRef}
          className={cn(
            isSA
              ? classes.contactPaymentContainerSA
              : showZohoError
              ? classes.contactPaymentContainerError
              : classes.contactPaymentContainer,
            showZohoError ? classes.maskDiv : ''
          )}
        >
          <div className={isSA ? 'ml-5' : ''}>
            <div className={`${classes.dateTitle}`}>
              Select Due Date (Pay-by-date) <span className={classes.required}>*</span>
            </div>
            <div className={`${classes.dateField}`}>
              <DatePicker
                dateFormat={'dd/MM/yyyy'}
                className={classes.datePicker}
                selected={values.dueDate}
                onChange={(date) => setFieldValue('dueDate', date)}
                minDate={new Date()}
                maxDate={addDays(new Date(), 14)}
                popperPlacement='bottom-end' // Adjust the arrow placement
                onFocus={(e) => e.target.blur()}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip
                    style={{
                      color: '#ffffff',
                      fontSize: '8px'
                    }}
                    id={'error'}
                  >
                    Let your finance contact know the absolute last date to complete the payment for
                    this order
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} className={classes.dateInfoIcon} />
              </OverlayTrigger>
            </div>
          </div>
          {values.requestor !== 'None' && (
            <>
              <div className='mt-3'>
                <div className={cn(classes.dateTitle, classes.topTitle)}>
                  {lastUsedContacts.length > 0
                    ? `Select or add multiple contacts to make payments on your behalf.`
                    : `Select or add multiple contacts to send payment request`}
                </div>
              </div>
              {lastUsedContacts.length > 0 &&
                isSA &&
                administrators?.admins.length === 1 &&
                adminHrContacts.map((cp) => getRequestee(cp))}
              {lastUsedContacts.length > 0 ? (
                <div className={classes.subLabel}>Last Used ({lastUsedContacts.length})</div>
              ) : null}
              <div role='group' aria-labelledby='checkbox-group'>
                {lastUsedContacts.length > 0
                  ? lastUsedContacts.map((cp) => getRequestee(cp))
                  : null}
                {lastUsedContacts.length > 0 && otherContacts.length > 0 ? (
                  <div className={classes.subLabel}>Other contacts ({otherContacts.length})</div>
                ) : null}

                <div className={classes.checkboxContainer}>
                  <label className={classes.contactCheckbox}>
                    <Field type='checkbox' name='requestee' value='Other' />
                    <div className={classes.checkboxLabel}>
                      <div className={classes.checkboxTitle}>
                        <span className={classes.name}>New external contacts</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div onFocus={handleFocus} onBlur={handleBlur} className={classes.emailsContainer}>
                  <input
                    placeholder='Add multiple emails by pressing Enter or comma (,)'
                    value={currentEmail}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className={multiEmailClass}
                  />
                  {currentEmailError && (
                    <p className={classes.error}>Please enter a valid email address</p>
                  )}
                </div>
                <div className={classes.emailChipContainer}>
                  {emails.map((email, index) => (
                    <CustomizedChip
                      key={email}
                      variant='outlined'
                      label={email}
                      error={true}
                      className={classes.chips}
                      color={'#388e3c'}
                      deleteIconHoverColor={'#dc3545'}
                      onDelete={() => {
                        deleteEmailChip(index);
                      }}
                    />
                  ))}
                </div>
                {lastUsedContacts.length > 0 && otherContacts.length > 0
                  ? otherContacts.map((cp) => getRequestee(cp))
                  : adminHrContacts.map((cp) => getRequestee(cp))}
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className={classes.paymentRequestModalFooter}>
        <div className={classes.footerContainer}>
          <div>
            {isSA && showZohoError ? (
              <div className={classes.zohoWarningContainer}>
                <span className={classes.zohoWarning}>Zoho Books Not Connected</span>
              </div>
            ) : null}
            &nbsp;
          </div>

          <div>
            <Button
              type='button'
              onClick={() => onClose(0)}
              className={classes.cancelButton}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type='button'
              onClick={handleSubmit}
              disabled={!isFormValid() || isError() || isSubmitting || showZohoError}
              className={classes.sendButton}
            >
              Send request
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
