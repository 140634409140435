import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import validationSchema from './ForgotPassword.validation';
import { errToastMessage, getErrorMessage } from '../../utils/utilities';

import { forgotPassword } from '../../actions/authentication';
import { INACTIVE_USER_ERROR_TEXT } from '../../utils/commonConstant';

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (data) => dispatch(forgotPassword(data))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({ email: '', loginError: '', forgotPasswordFailedError: '' }),
    validationSchema,
    handleSubmit: (values, { setSubmitting, props, setStatus, setFieldValue }) => {
      setFieldValue('loginError', '');
      props
        .forgotPassword(values)
        .then((response) => {
          setSubmitting(false);
          setStatus(true);
        })
        .catch((error) => {
          if (error?.response?.data?.message?.includes(INACTIVE_USER_ERROR_TEXT)) {
            setFieldValue('forgotPasswordFailedError', error?.response?.data?.message);
          }
          if (error?.response?.data?.body && !error?.response?.data?.body?.login_method_password) {
            setFieldValue('loginError', getErrorMessage(error));
          } else {
            errToastMessage(error);
          }
          setSubmitting(false);
          setStatus(false);
        });
    },
    displayName: 'ForgotPasswordForm'
  })
);
