import React from 'react';

import styles from './EmptyTableBody.module.scss';
import Image from '../Image';

function EmptyTableBody(props) {
  return (
    <tbody>
      <tr>
        <td colSpan={7}>
          <div className={styles.noDataWrapper}>
            <Image name={'noPurchaseHistory.svg'} />
            <div className={styles.noDataContent}>{props?.name || 'No item found'}</div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default EmptyTableBody;
