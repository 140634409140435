import * as Yup from 'yup';

const ERROR_MSG = 'Required Field';

export default Yup.object().shape({
  phone: Yup.string().required(ERROR_MSG),
  fullName: Yup.string().when('hasTwoColumnsForName', {
    is: (value) => !value,
    then: Yup.string().required(ERROR_MSG)
  }),
  firstName: Yup.string().when('hasTwoColumnsForName', {
    is: (value) => value,
    then: Yup.string().required(ERROR_MSG)
  }),
  email: Yup.string().required(ERROR_MSG)
});
