import React, { useState } from 'react';

import AddressStep1 from './AddressStep1';
import AddressStep2 from './AddressStep2';
import AddressStep3 from './AddressStep3';

export default (props) => {
  return (
    <>
      {props.currentModalSubStep === 1 && <AddressStep1 {...props} />}
      {props.currentModalSubStep === 2 && <AddressStep2 {...props} />}
      {props.currentModalSubStep === 3 && <AddressStep3 {...props} />}
    </>
  );
};
