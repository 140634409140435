import { mergeWith, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import logger from '../../../../../core-components/Logger';
import { errToastMessage, toCamelCase } from '../../../../../utils/utilities';

import {
  saveFeedback as saveFeedbackAPI,
  getBgvCandidateDetails as getBgvCandidateDetailsAPI,
  invalidateFeedback,
  setFeedback
} from '../../../../../actions/bgv';

const mapStateToProps = (state) => {
  const initialCandidateDetails = !isEmpty(state.initialCandidateDetails.data)
    ? toCamelCase(state.initialCandidateDetails.data)
    : null;
  return {
    initialCandidateDetailsFeedback: initialCandidateDetails,
    isFeedbackSubmitted: state?.feedback?.isFeedbackSubmitted,
    referralData: state.bgvHrReferralData || {},
    initialCandidateDetails: !isEmpty(state.initialCandidateDetails.data)
      ? { ...state.initialCandidateDetails.data, loading: state.initialCandidateDetails.loading }
      : {
          error: state.initialCandidateDetails.error,
          loading: state.initialCandidateDetails.loading
        }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveFeedback: (data) => dispatch(saveFeedbackAPI(data)),
    getBgvCandidateDetails: () => dispatch(getBgvCandidateDetailsAPI()),
    invalidateFeedback: () => dispatch(invalidateFeedback()),
    setFeedback: (data) => dispatch(setFeedback(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        stars: 0,
        message: ''
      };

      return mergeWith({}, storedValues, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      props
        .saveFeedback(values)
        .then((response) => {
          setSubmitting(false);
          props.setFeedback(response.data);
          // logger code for feedback details
          logger.push({
            message: 'Feedback Submitted',
            data: JSON.stringify(values),
            category: `candidate-id-${props.candidateId}`,
            subCategory: 'Feedback Details',
            type: 'info'
          });
        })
        .catch((error) => {
          setSubmitting(false);
          console.log(error);
          errToastMessage('Unable to save feedback, please try after some time.');
        });
    },
    displayName: 'Feedback'
  })
);
