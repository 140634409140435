import React from 'react';

import DetailsStep from './DetailsStep';
import HrDetailsStep from './HrDetailsStep';
import FilesStep from './FilesStep';
import { isEmpty } from 'lodash';

class EmploymentModals extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    step: this.calculateStep()
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  calculateStep() {
    return this.props?.employmentCheck &&
      !isEmpty(this.props?.employmentCheck) &&
      this.props?.employmentCheck[this.props.currentSavingIndex]
      ? this.getStep()
      : 1;
  }
  getStep() {
    let step = 1;
    switch (Object.keys(this.props?.employmentCheck[this.props.currentSavingIndex])?.length) {
      case 1:
        step = 2;
        break;
      case 2:
        step = 3;
        break;
      default:
        break;
    }
    return step;
  }
  render() {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <DetailsStep
            subNextStep={this.nextStep}
            handleChange={this.handleChange}
            {...this.props}
          />
        );
      case 2:
        return (
          <FilesStep
            subNextStep={this.nextStep}
            subPrevStep={this.prevStep}
            handleChange={this.handleChange}
            {...this.props}
          />
        );
      case 3:
        return (
          <HrDetailsStep
            subNextStep={this.nextStep}
            subPrevStep={this.prevStep}
            handleChange={this.handleChange}
            {...this.props}
          />
        );
      default:
        return null;
    }
  }
}

export default EmploymentModals;
