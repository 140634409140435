import React, { useEffect, useState } from 'react';
import { DropdownList } from 'react-widgets';

import Image from '../../core-components/Image/Image';
import SearchFieldWithDropdown from '../../core-components/SearchFieldWithDropdown/SearchFieldWithDropdown';
import styles from '../AlumniVerificationTabs.module.scss';
import { ALUMNI_INPUT_SEARCH_TYPE } from '../utils';
import { getUniqueRecords } from '/src/api/company';

export default function Filters({ filterValues, setFilterValues, fetchRequestList, isSA }) {
  const [exitTypeDropdownValues, setExitTypeDropdown] = useState([{ label: 'All', value: '' }]);
  const [designationDropdown, setDesignationDropdown] = useState([{ label: 'All', value: '' }]);
  const companyId = new URLSearchParams(document?.location?.search).get('id');

  useEffect(() => {
    getUniqueRecords({
      field: ['ALUMNI_DESIGNATION', 'EXIT_TYPE'],
      companyId
    }).then((res) => {
      const { ALUMNI_DESIGNATION = [], EXIT_TYPE = [] } = res?.data?.data;
      setDesignationDropdown((prev) => [
        ...prev,
        ...ALUMNI_DESIGNATION.map((val) => {
          return { label: val, value: val };
        })
      ]);
      const filteredExitTypes = EXIT_TYPE.filter((val) => val);
      setExitTypeDropdown((prev) => [
        ...prev,
        ...filteredExitTypes.map((val) => {
          return { label: val, value: val };
        })
      ]);
    });
  }, []);
  useEffect(() => {
    fetchRequestList(filterValues);
  }, [filterValues]);

  return (
    <div className={styles.row}>
      <div className={styles.dateAndDropdownFilters}>
        <div className={styles.filterItem}>
          <div className={`${styles.entityLabel}`}>Designation</div>
          <DropdownList
            data={designationDropdown}
            placeholder={<span className={styles.dropDownPlaceholder}>All</span>}
            containerClassName={styles.dropDownStyle + ' reactWidgetCustomDp'}
            value={filterValues.designation}
            textField='label'
            name='designation'
            selectIcon={<Image isSA={isSA} name='Mask.svg' />}
            onChange={(e) => {
              setFilterValues((prevState) => ({
                ...prevState,
                designation: e.value
              }));
            }}
          />
        </div>
        <div className={styles.filterItem}>
          <div className={`${styles.entityLabel}`}>Exit Type</div>
          <DropdownList
            data={exitTypeDropdownValues}
            placeholder={<span className={styles.dropDownPlaceholder}>All</span>}
            containerClassName={styles.dropDownStyle + ' reactWidgetCustomDp'}
            value={filterValues.exit_type}
            textField='label'
            name='exit_type'
            selectIcon={<Image isSA={isSA} name='Mask.svg' />}
            onChange={(e) => {
              setFilterValues((prevState) => ({
                ...prevState,
                exit_type: e.value
              }));
            }}
          />
        </div>
      </div>{' '}
      <SearchFieldWithDropdown
        isSA={isSA}
        onChange={(e, type) => {
          setFilterValues((prevState) => ({
            ...prevState,
            name: type === 'name' ? e : null,
            staff_id: type === 'staff_id' ? e : null,
            email: type === 'email' ? e : null,
            phone: type === 'phone' ? e : null
          }));
        }}
        dropdownValues={ALUMNI_INPUT_SEARCH_TYPE}
      />
    </div>
  );
}
