import React from 'react';
import Form from 'react-bootstrap/Form';

import styles from './coreComponents.module.scss';

export default ({ field, form, label, options, isBulkUpload, ...props }) => {
  return (
    <Form.Group style={{ display: 'flex' }} controlId={field && field.name} {...props}>
      {options &&
        options.map((option, i) => {
          return (
            <Form.Check type={'radio'} key={i} id={field.name + option.value}>
              <Form.Check.Input
                type='radio'
                onChange={(e) => {
                  form.setFieldValue(field.name, e.target.value);
                }}
                value={option.value}
                checked={String(option.value) === field.value}
                disabled={option.disable}
              />
              <span
                className={`${styles.option} ${
                  option.disable ? styles.disableOption : styles.enableOption
                }`}
              >
                {option.label}
              </span>
            </Form.Check>
          );
        })}
    </Form.Group>
  );
};
