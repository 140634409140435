import { connect } from 'react-redux';
import { getAdditionalChargeSettings } from '../../../actions/company';


import {
  getApprovalList as getApprovalListAPI,
  getCompanyPaymentDetails
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    approvalList: state.approvalList,
    companyPaymentMode: state.companyPaymentType,
    companyId: state && state?.profile?.profile?.company_user_mapping?.company_id,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    adcSettings: state.adcSettings,
    userID: state?.profile?.profile?.id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getApprovalList: (limit, offset, from_date, to_date, status, costType, candidateName) =>
      dispatch(
        getApprovalListAPI(limit, offset, from_date, to_date, status, costType, candidateName)
      ),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails()),
    getAdcSettingsConfig: (params) => dispatch(getAdditionalChargeSettings(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
