// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g_EafYjQ3x8VnqJ2Pd3Y {
  font-style: normal;
  font-weight: normal;
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
}
.g_EafYjQ3x8VnqJ2Pd3Y .izaVDQ05AxOwsMIocuoP {
  width: 44px;
  margin-right: 8px;
}
.g_EafYjQ3x8VnqJ2Pd3Y .izaVDQ05AxOwsMIocuoP .QSLf3vmlq2PGl90qCPoZ {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.g_EafYjQ3x8VnqJ2Pd3Y .izaVDQ05AxOwsMIocuoP .QSLf3vmlq2PGl90qCPoZ .GtNwSsySN7et7jSdblqF {
  padding: 6px;
}
.g_EafYjQ3x8VnqJ2Pd3Y .UI4uKIdlfPnCBt7NkRd4 .V1QZwGduTfw0pOEvEPCA {
  font-size: 12px;
  line-height: 20px;
  color: #8C8C8C;
}
.g_EafYjQ3x8VnqJ2Pd3Y .UI4uKIdlfPnCBt7NkRd4 .DMBSwAgncWXl6iHzXFZw {
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/InsufficienciesNew/CommentsModal/Comment/Comment.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,iBAAA;AAEJ;AADI;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,oCAAA;EACA,sBAAA;AAGN;AAFM;EACE,YAAA;AAIR;AACI;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACN;AACI;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACN","sourcesContent":[".commentBox{\n  font-style: normal;\n  font-weight: normal;\n  display: flex;\n  margin-top: 12px;\n  margin-bottom: 12px;\n  .commentBoxLogo{\n    width:44px;\n    margin-right: 8px;\n    .commentBoxLogoContainer{\n      height: 32px;\n      width: 32px;\n      border-radius: 50%;\n      border: 1px solid rgba(0, 0, 0, 0.1);\n      box-sizing: border-box;\n      .commentBoxLogoImg{\n        padding:6px;\n      }\n    }\n  }\n  .commentBoxContent{\n    .commentBoxContentHeader{\n      font-size: 12px;\n      line-height: 20px;\n      color: #8C8C8C;\n    }\n    .commentBoxContentComment{\n      font-size: 14px;\n      line-height: 22px;\n      color: #666666;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentBox": `g_EafYjQ3x8VnqJ2Pd3Y`,
	"commentBoxLogo": `izaVDQ05AxOwsMIocuoP`,
	"commentBoxLogoContainer": `QSLf3vmlq2PGl90qCPoZ`,
	"commentBoxLogoImg": `GtNwSsySN7et7jSdblqF`,
	"commentBoxContent": `UI4uKIdlfPnCBt7NkRd4`,
	"commentBoxContentHeader": `V1QZwGduTfw0pOEvEPCA`,
	"commentBoxContentComment": `DMBSwAgncWXl6iHzXFZw`
};
export default ___CSS_LOADER_EXPORT___;
