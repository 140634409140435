// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fgDH00MJJbNOOicTygIj p {
  margin-bottom: 5px;
}
.fgDH00MJJbNOOicTygIj ._mmvyKxKG30_w5Lo0MUh {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.fgDH00MJJbNOOicTygIj .ZOCwwCyeJser2SzU4lDG {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.fgDH00MJJbNOOicTygIj .GnoPcQkMxGyV84tpkuNm {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}
.fgDH00MJJbNOOicTygIj .f2eE9CQFspoYtWGenLme {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.fgDH00MJJbNOOicTygIj .MMFkkBVReCg5uB_y2J6A {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.uGDLeMFZc349Xnmbv_Rf {
  text-align: right;
}
.uGDLeMFZc349Xnmbv_Rf p {
  margin-bottom: 5px;
}
.uGDLeMFZc349Xnmbv_Rf .Wki4XN1IgVcoDYkMLlT6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.uGDLeMFZc349Xnmbv_Rf .EoOLUsCVFfDO8BIt2Etl {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.uGDLeMFZc349Xnmbv_Rf .YvOpdlt8cHwr7gQwWYze {
  color: #389e0d;
}
.uGDLeMFZc349Xnmbv_Rf .TMfNIQWfucYMh9ko4TeF {
  color: #333333;
}
.uGDLeMFZc349Xnmbv_Rf .HWaZcMK6x8Q4u2F9tnvl {
  font-family: roboto;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Packages/Prepaid/Credits/CreditTransactionHistory/DebitDetail/DebitDetail.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;;AAGA;EACE,iBAAA;AAAF;AACE;EACE,kBAAA;AACJ;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AACE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AACE;EACE,cAAA;AACJ;AACE;EACE,cAAA;AACJ;AACE;EACE,mBAAA;AACJ","sourcesContent":[".detailsContainer {\n  p {\n    margin-bottom: 5px;\n  }\n  .packageAddedText {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #333333;\n  }\n  .addedRemovedBy {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #666666;\n  }\n  .transactionId {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 24px;\n    color: #666666;\n  }\n  .addonText {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #000000;\n  }\n  .additionalCharges {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #333333;\n  }\n}\n.priceContainer {\n  text-align: right;\n  p {\n    margin-bottom: 5px;\n  }\n  .date {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #666666;\n  }\n  .amount {\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 21px;\n  }\n  .amountCredit {\n    color: #389e0d;\n  }\n  .amountDebit {\n    color: #333333;\n  }\n  .rupee {\n    font-family: roboto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsContainer": `fgDH00MJJbNOOicTygIj`,
	"packageAddedText": `_mmvyKxKG30_w5Lo0MUh`,
	"addedRemovedBy": `ZOCwwCyeJser2SzU4lDG`,
	"transactionId": `GnoPcQkMxGyV84tpkuNm`,
	"addonText": `f2eE9CQFspoYtWGenLme`,
	"additionalCharges": `MMFkkBVReCg5uB_y2J6A`,
	"priceContainer": `uGDLeMFZc349Xnmbv_Rf`,
	"date": `Wki4XN1IgVcoDYkMLlT6`,
	"amount": `EoOLUsCVFfDO8BIt2Etl`,
	"amountCredit": `YvOpdlt8cHwr7gQwWYze`,
	"amountDebit": `TMfNIQWfucYMh9ko4TeF`,
	"rupee": `HWaZcMK6x8Q4u2F9tnvl`
};
export default ___CSS_LOADER_EXPORT___;
