// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._ynWynG4q0QNyLzK0lyK {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/RedirectionHandler/RedirectionHandler.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":[".loaderContainer {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  left: 0;\n  top: 0;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `_ynWynG4q0QNyLzK0lyK`
};
export default ___CSS_LOADER_EXPORT___;
