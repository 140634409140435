// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y4NqJ1azNueLhYNOZbXo {
  margin-top: 5px;
}
.Y4NqJ1azNueLhYNOZbXo:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/MarketingBanner/MarketingBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAG;EACC,eAAA;AAEJ","sourcesContent":[".imageContainer {\n  margin-top: 5px;\n   &:hover {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `Y4NqJ1azNueLhYNOZbXo`
};
export default ___CSS_LOADER_EXPORT___;
