// React and other libraries imports
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { pdfjs } from 'react-pdf';
import Select from 'react-select';

// Core components and utils imports
import Image from '../../../../../../../core-components/Image';
import { empDropdownArray } from '../../../../../../../utils/commonConstant';
import {
  FileErrorMeaage,
  getFileHeader,
  validateImage
} from '../../../../../../../utils/utilities';
import BGVCamera from '../../../../../CommonComponent/BGVCamera';
import ImagePreviewModal from '../../../../../CommonComponent/ImagePreviewModal';
import {
  RenderErrorUI,
  RenderUploadedFile
} from '../../../Education/EducationModals/AddEditUpload/CommonUploadComponent';
import PasswordProtectedModal from '../../../PasswordProtectedModal';

// Styles import
import styles from './FilesStep.module.scss';

const FilesStep = ({
  show,
  onHide,
  subPrevStep,
  setFieldValue,
  handleSubmit,
  values,
  empDocType,
  educationGradesheetUpload,
  deleteGradesheetUpload,
  isSubmitting,
  setEmpDocType,
  isEdit
}) => {
  const [docUpload, setDocUpload] = useState({
    proof_doc: []
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [showImagePreviewModal, setShowImagePreviewModal] = useState(false);
  const [takePhoto, setTakePhoto] = useState(false);
  const [isCamHavingError, setIsCamHavingError] = useState(false);
  const [showPasswordProtectedModal, setShowPasswordProtectedModal] = useState(false);
  const [maxFileError, setMaxFileError] = useState(false);
  const [passwordProtectedFiles, setPasswordProtectedFiles] = useState([]);
  const [passwordProtectedUploadedLength, setPasswordProtectedUploadedLength] = useState(0); // Store initial password protected length

  useEffect(() => {
    if (passwordProtectedFiles.length) {
      setShowPasswordProtectedModal(true);
    }
  }, [passwordProtectedFiles]);

  const modalHide = () => {
    setShowPasswordProtectedModal(false);
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      // If any of the password protected modal is closed, then we have save unprotected files/ unlocked files
      const passwordProtectedFilesIndices = passwordProtectedFiles.map((item) => item?.index);
      const newArray = docCopy.filter((_, index) => !passwordProtectedFilesIndices.includes(index));
      return {
        ...prevDocUpload,
        proof_doc: newArray
      };
    });
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const skipFileHandler = (index) => {
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy.splice(index, 1);
      return {
        ...prevDocUpload,
        proof_doc: docCopy
      };
    });

    passwordProtectedFilesStateUpdate();
  };

  const submitHandlerPasswordModal = (password = '', link, index) => {
    setDocUpload((prevDocUpload) => {
      const docCopy = [...prevDocUpload.proof_doc];
      docCopy[index] = updateDocCopyAtIndex({ link, password });
      return {
        ...prevDocUpload,
        proof_doc: docCopy
      };
    });
    passwordProtectedFilesStateUpdate();
    setTakePhoto(false);
    setIsCamHavingError(false);
  };

  const handleFileUpload = (formData, isPdf, index, file, resolve, isCam = false) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      if (reader.result) {
        educationGradesheetUpload({ formData, isPdf: isPdf })
          .then((res) => {
            try {
              let docCopy = {
                link: res.link,
                error: '',
                loading: false,
                isPdf: isPdf
              };
              if (isPdf) {
                const loadingTask = pdfjs.getDocument({
                  data: reader?.result,
                  password: ''
                });

                loadingTask.promise
                  .then(() => {
                    resolve({ docCopy, index });
                  })
                  .catch((error) => {
                    if (error.message === 'No password given') {
                      resolve({
                        docCopy: {
                          ...docCopy,
                          fileName: file.name
                        },
                        index,
                        isPasswordProtected: true
                      });
                    }
                  });
              } else {
                resolve(isCam ? false : { docCopy, index });
                if (isCam) {
                  handleCamPhoto();
                  setDocUpload((prevDocUpload) => {
                    const docCopy = [...prevDocUpload.proof_doc];
                    docCopy.push({
                      link: res?.link,
                      error: false,
                      isPdf: false
                    });
                    return {
                      ...prevDocUpload,
                      proof_doc: [...docCopy]
                    };
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            let docCopy = [];
            docCopy[index] = {
              link: '',
              error: 'Upload failed. File format not valid.',
              loading: false,
              isPdf: isPdf,
              password: ''
            };
            resolve(docCopy);
          });
      }
    };
  };

  const handleFileSelect = async (e, type, index) => {
    const numberOfFiles = docUpload.proof_doc.length + e.target.files.length;

    if (numberOfFiles > 10) {
      setMaxFileError(true);
    } else {
      setMaxFileError(false);
      const promises = Array.from(e.target.files).map((file, ind) => {
        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];
          docCopy[index] = updateDocCopyAtIndex({ error: '', loading: true });
          return {
            ...prevDocUpload,
            proof_doc: docCopy
          };
        });
        return new Promise((resolve, reject) => {
          let fileType = '';
          var blob = file;
          var fileReader = new FileReader();
          fileReader.onloadend = () => {
            var arr = new Uint8Array(fileReader.result).subarray(0, 4);
            var header = '';
            for (var i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            // Check the file signature against known types
            fileType = getFileHeader(header);
            const errMessage = validateImage(blob, fileType);
            const isPdf = fileType === 'application/pdf' ? true : false;
            if (!errMessage) {
              let formData = new FormData();
              formData.append(`file`, blob);
              formData.append(`file_type`, 'CHECK_DOCUMENT');
              handleFileUpload(formData, isPdf, index + ind, blob, resolve);
            } else {
              resolve({
                docCopy: updateDocCopyAtIndex({ error: FileErrorMeaage[errMessage], isPdf }),
                index: index + ind
              });
            }
          };

          if (blob instanceof Blob) {
            {
              /** To avoid TyperError issue reported by sentry, need to check if param is an Blob type or not */
            }
            fileReader.readAsArrayBuffer(blob);
          } else {
            console.error(
              `Failed to execute 'readAsArrayBuffer' on 'FileReader': parameter is not of type 'Blob'`
            );
          }
        });
      });
      Promise.all(promises).then((files) => {
        /* Filters all files which are password protected*/
        const filesWithPassword = files.filter((file) => file?.isPasswordProtected);
        setPasswordProtectedFiles(filesWithPassword);
        setPasswordProtectedUploadedLength(filesWithPassword.length);

        setDocUpload((prevDocUpload) => {
          const docCopy = [...prevDocUpload.proof_doc];

          files &&
            files.map((file) => {
              docCopy[file?.index] = updateDocCopyAtIndex({
                link: file?.docCopy?.link,
                error: file?.docCopy?.error,
                isPdf: file?.docCopy?.isPdf
              });
            });

          return {
            ...prevDocUpload,
            proof_doc: [...docCopy]
          };
        });
      });
    }
  };

  const clearError = (type, index) => {
    const empdocCopy = [...docUpload.proof_doc];
    empdocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...empdocCopy]
    });
  };

  const isImageHavingError = () => {
    let isImageHavingError = false;
    for (let value of docUpload.proof_doc) {
      if (!value?.link || value?.error || value?.loading) {
        isImageHavingError = true;
        break;
      }
    }
    return isImageHavingError;
  };

  const handleImagePreview = (url) => {
    setImagePreviewUrl(url || '');
    setShowImagePreviewModal(!showImagePreviewModal);
  };

  const handleDeleteImage = (type, index) => {
    const empdocCopy = [...docUpload.proof_doc];
    empdocCopy.splice(index, 1);
    setDocUpload({
      ...docUpload,
      proof_doc: [...empdocCopy]
    });
    deleteGradesheetUpload(empdocCopy);
  };

  const handleCamPhoto = () => {
    setTakePhoto(!takePhoto);
    setIsCamHavingError(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let cloneDocs = [];

    for (let value of docUpload.proof_doc) {
      if (value.link) {
        cloneDocs.push({ link: value.link, isPdf: value.isPdf, password: value.password });
      }
    }
    setFieldValue('proof_doc', cloneDocs);
    handleSubmit();
  };

  const enablePhotoBtn = () => {
    let isImageHavingError = false;

    if (isMobileOnly) {
      for (let value of docUpload.proof_doc) {
        if (value.error) {
          isImageHavingError = true;
          break;
        }
      }
    }
    return isImageHavingError;
  };

  useEffect(() => {
    const docUploadCopy = Object.assign({}, { ...docUpload });
    let documents = values.proof_doc;

    if (empDocType) {
      setFieldValue('doc_type', empDocType);
    }
    if (documents.length > 0) {
      docUploadCopy.proof_doc = documents.map((item) => {
        return {
          link: item?.link,
          error: '',
          loading: false,
          isPdf: item?.isPdf || item?.is_pdf,
          password: item?.password
        };
      });
    }

    setDocUpload({ ...docUploadCopy });
  }, []);

  /* Utility/common functions */
  const updateDocCopyAtIndex = ({
    link = '',
    error = null,
    loading = false,
    isPdf = true,
    password = null
  }) => {
    return {
      link,
      error,
      loading,
      isPdf,
      password
    };
  };

  const passwordProtectedFilesStateUpdate = () => {
    setPasswordProtectedFiles((prevPasswordFiles) => {
      const remainingProtectedFiles = [...prevPasswordFiles];
      remainingProtectedFiles.pop();
      if (remainingProtectedFiles.length == 0) {
        setShowPasswordProtectedModal(false);
      }
      return remainingProtectedFiles;
    });
  };

  const renderEmpDocHavingLength = () => {
    return docUpload.proof_doc.map((item, index) => {
      return item?.error ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderErrorUI
            handleClick={clearError}
            errorText={item.error}
            index={index}
            type='empdoc'
          />
        </Col>
      ) : item?.loading ? (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <div className={styles.certificateInputFileContainer}>
            <Spinner animation='border' variant='primary' />
          </div>
        </Col>
      ) : item?.link ? (
        <Col
          lg={5}
          md={6}
          sm={10}
          xs={12}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <RenderUploadedFile
            item={item}
            handleImagePreview={handleImagePreview}
            handleDeleteImage={handleDeleteImage}
            type='empdoc'
            index={index}
            isPdf={item.isPdf}
          />
        </Col>
      ) : (
        <Col
          lg={5}
          md={6}
          sm={11}
          xs={11}
          key={`emp_doc${index}`}
          className={isMobileOnly ? styles.uploadContainer : null}
        >
          <label className={styles.certificateInputFileContainer}>
            <div>
              <Image name='certificate_upload_enable.png' />
              <p>Add your employment proof</p>
              <Form.Control
                id='formControlsFile'
                accept='image/*,application/pdf'
                type='file'
                label='File'
                multiple
                onChange={(e) => handleFileSelect(e, 'empdoc', index)}
              />
            </div>
          </label>
        </Col>
      );
    });
  };

  const renderUploadEmpDoc = () => {
    return (
      <Col lg={5} md={6} sm={11} xs={11}>
        <label className={styles.certificateInputFileContainer}>
          <div>
            <Image name='certificate_upload_enable.png' />
            <p>Add your employment proof</p>
            <Form.Control
              id='formControlsFile'
              accept='image/*,application/pdf'
              type='file'
              label='File'
              multiple
              onChange={(e) => handleFileSelect(e, 'empdoc', docUpload.proof_doc.length)}
            />
          </div>
        </label>
      </Col>
    );
  };

  const getTitle = () => {
    const restOfTitle = values.companyName ? `${values.companyName} details` : ' Employment';
    return `${!isEdit ? 'Add' : 'Edit'} ${restOfTitle}`;
  };

  const isValidForm =
    docUpload.proof_doc.length > 0 && !isImageHavingError() && !isEmpty(values.doc_type);

  return (
    <>
      <Modal
        size={showPasswordProtectedModal ? 'sm' : 'lg'}
        show={show}
        onHide={onHide}
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        dialogClassName='bgvModalDialog'
        className='bgvModal'
        backdropClassName={'customBGVModalBackdrop'}
        centered
      >
        <div style={showPasswordProtectedModal ? { display: 'none' } : null}>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <div className={styles.employmentModalTitle}>
                <span> {getTitle()}</span>
              </div>
              <div className={styles.employmentModalSubTitle}>
                <span>{`Step 2 of 3`}</span>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={handleFormSubmit}>
            <Modal.Body className={styles.addEditEducationModalBody}>
              <Row className={styles.employmentProofContainer}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <p className={styles.employmentProofTitle}>Add your Employment proof</p>
                  <p className={styles.employmentProofDescription}>
                    {
                      'Please upload either your relieving letter, salary slip, or your experience certificate as proof. Upload .png, .jpg, .jpeg, or pdf file only.'
                    }
                  </p>
                </Col>
              </Row>
              <Form.Label>
                Select document type<span>*</span>
              </Form.Label>
              <Row>
                <Col sm={12} xs={12} md={10} lg={6} className={styles.customDropdown}>
                  <Select
                    menuPosition={'fixed'}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={empDropdownArray}
                    value={
                      empDropdownArray.filter((object) => object.value === values.doc_type)[0] ||
                      null
                    }
                    onChange={(value) => {
                      setFieldValue('doc_type', value.value);
                      setEmpDocType(value?.value); //setting doc type in redux store
                    }}
                  />
                </Col>
              </Row>
              {values.doc_type !== null && (
                <Row className={styles.degreeCertificateContainer}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <p className={styles.degreeCertificateTitle}>
                      Add your employment proof <span>*</span>
                    </p>
                    {maxFileError && (
                      <p className={styles.maxFileError}>Maximum 10 files can be uploaded</p>
                    )}
                  </Col>
                  {isMobileOnly ? (
                    <Col sm={12} xs={12}>
                      <Row>
                        <Col sm={11} xs={11} className={styles.takePhotoBtnContainer}>
                          <Button
                            className={styles.takePhotoBtn}
                            onClick={() => handleCamPhoto()}
                            disabled={enablePhotoBtn() && docUpload.proof_doc.length}
                          >
                            Take Photo of proof
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ) : null}

                  <Col lg={10} md={12} sm={12} xs={12}>
                    <Row
                      style={isMobileOnly ? { display: 'flex', justifyContent: 'center' } : null}
                    >
                      <>
                        {docUpload.proof_doc.length ? renderEmpDocHavingLength() : null}
                        {docUpload.proof_doc.length < 10 && !isImageHavingError()
                          ? renderUploadEmpDoc()
                          : null}
                      </>
                    </Row>
                  </Col>
                </Row>
              )}
              {showImagePreviewModal && (
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <ImagePreviewModal
                      showImagePreviewModal={showImagePreviewModal}
                      handleImagePreview={handleImagePreview}
                      imagePreviewUrl={imagePreviewUrl}
                    />
                  </Col>
                </Row>
              )}
              {isMobileOnly && takePhoto && (
                <BGVCamera
                  type='multiple'
                  showBgvCameraModal={takePhoto}
                  handleCameraModal={handleCamPhoto}
                  //handleMultipleUpload={handleFileUpload}
                  handleMultipleUpload={(formData, isPdf, index, setCamState, file) => {
                    handleFileUpload(formData, isPdf, index, file, setCamState, true);
                  }}
                  isCameraUploadError={isCamHavingError}
                  index={
                    docUpload.proof_doc.length && !isImageHavingError()
                      ? docUpload.proof_doc.length
                      : docUpload.proof_doc.length && isImageHavingError()
                      ? docUpload.proof_doc.length - 1
                      : docUpload.proof_doc.length
                  }
                />
              )}
            </Modal.Body>
            <Modal.Footer className={styles.employmentSubmitBtn}>
              <Button className={'bgvModalSecondaryBtn'} onClick={(e) => subPrevStep()}>
                Go Back
              </Button>
              <Button
                type='submit'
                className={'bgvModalPrimaryBtn'}
                disabled={isSubmitting || !isValidForm}
              >
                Next
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
      {showPasswordProtectedModal && (
        <PasswordProtectedModal
          submitHandler={submitHandlerPasswordModal}
          subShow={showPasswordProtectedModal}
          checkType={'employment'}
          SubOnHide={modalHide}
          skipFileHandler={skipFileHandler}
          passwordProtectedUploadedLength={passwordProtectedUploadedLength}
          passwordProtectedFiles={passwordProtectedFiles}
        />
      )}
    </>
  );
};

export default FilesStep;
