import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  invalidateCreditTransactionHistory,
  getCreditTransactionHistory as getCreditTransactionHistoryAPI
} from '../../../../../actions/company';
import { hasPaymentGatewayMethod } from '../../../../../utils/utilities';

const mapStateToProps = (state) => {
  return {
    creditTransactionHistory:
      state.creditTransactionHistory && !isEmpty(state.creditTransactionHistory)
        ? state.creditTransactionHistory
        : null,
    insuffCredits:
      state.insuffCredits && !isEmpty(state.insuffCredits) ? state.insuffCredits : null,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    hideViewDetailsButton: state?.paymentCreditTransaction?.paymentCreditTransaction ? !hasPaymentGatewayMethod(state?.paymentCreditTransaction?.paymentCreditTransaction) : true
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCreditTransactionHistory: () => dispatch(invalidateCreditTransactionHistory()),
    getCreditTransactionHistory: (data) => dispatch(getCreditTransactionHistoryAPI(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps);
