import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import { DEFAULT_TAB_MAPPING, TAB_CATEGORY, TAB_TAG } from '../../../utils/commonConstant';
import { getCompanyPaymentDetails } from '../../../actions/company';
import { useDispatch } from 'react-redux';

import styles from './ManageTagsAndCategories.module.scss';
import ManageCategories from './ManageCategories';
import TabComponent from '../../../core-components/TabComponent/TabComponent';
import ManageTags from './ManageTags';

export default function ManageTagsAndCategories({ history, location }) {
  const tabItems = [TAB_TAG, TAB_CATEGORY];
  const dispatch = useDispatch();

  dispatch(getCompanyPaymentDetails());
  const searchParams = new URLSearchParams(location.search);
  const defaultTab = searchParams?.get('tab' || 'tag');
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB_MAPPING[defaultTab] || TAB_TAG);

  const handleOnChangeTab = (tab) => {
    setActiveTab(tab);
    const queryParam =
      Object.keys(DEFAULT_TAB_MAPPING).find((key) => DEFAULT_TAB_MAPPING[key] === tab) || 'tag';
    searchParams.set('tab', queryParam);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return (
    <div className={styles.manageTagAndCategoryContainer}>
      <div className={styles.heading}>{'Manage Tags and Categories'}</div>
      <Card className={styles.cardContainer}>
        <TabComponent
          tabItems={tabItems}
          activeTab={activeTab}
          handleTabChange={handleOnChangeTab}
          history={history}
        />
        {activeTab === 'Tags' && <ManageTags />}
        {activeTab === 'Categories' && <ManageCategories />}
      </Card>
    </div>
  );
}
