import React, { useState, useEffect, useMemo } from 'react';
import { has } from 'lodash';

import { Accordion, Card, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../../../core-components/Image';
import CustomCounter from '../../../../../../../core-components/CustomCounter';
import {
  convertToIndianNumeration,
  filterObjectByKeyCaseInsensitive
} from '../../../../../../../utils/utilities';
import {
  PLAIN_CHECK_ICONS,
  EDUCATION_TYPES_FOR_PACKAGE_CONFIG,
  EDUCATION_TYPES,
  EDUCATION_TYPES_ADDONS,
  CHECKS_LIMIT,
  EDUCATION_TYPES_ADDONS_BY_ADDONS
} from '../../../../../../../utils/commonConstant';
import styles from './Education.module.scss';

function Education(props) {
  const { pDetails, disabled, values, onValueChange, isSpecific, showAllEduTypes, addonDetails } =
    props;

  const [activeAccordion, setActiveAccordion] = useState(null);
  const [verificationMethod, setVerificationMethod] = useState(values?.method); // using condition as after adding adon if we go back and come on addon page data was not selected with selcetd values
  const [verificationType, setVerificationType] = useState(values?.type); // using condition as after adding adon if we go back and come on addon page data was not selected with selcetd values
  const [eduAdcPriceTagValue, setEduAdcPriceTagValue] = useState(props?.price);
  const [eduCheckCount, setEduCheckCount] = useState(0);
  const [counterValue, setCounterValue] = useState(0);

  const filteredObj = useMemo(
    () => filterObjectByKeyCaseInsensitive(addonDetails, 'education'.replace(/_/g, '')),
    [addonDetails]
  );
  const addonsConfiguredLengthIsOne = Object.values(filteredObj).length === 1;
  useEffect(() => {
    if (addonsConfiguredLengthIsOne) {
      setVerificationMethod(EDUCATION_TYPES_ADDONS_BY_ADDONS[Object.keys(filteredObj)[0]]);
    }
    if (pDetails?.config) {
      setEduCheckCount(pDetails.config.education);
    }
  }, [pDetails]);

  useEffect(() => {
    if (values.count === 0) {
      setVerificationMethod('');
      setVerificationType('');
      setCounterValue(0);
    }
  }, [values.count, verificationType]);

  const handleCounterValueChange = (value) => {
    if (value > counterValue) {
      setCounterValue(value);
      setEduCheckCount((prevCount) => prevCount + 1);
    } else if (value < counterValue) {
      setCounterValue(value);
      setEduCheckCount((prevCount) => prevCount - 1);
    }
  };

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  const handleConfigChange = (event, value) => {
    if (isSpecific) {
      let dummy = [...values.config];

      if (event.target.checked) {
        if (!dummy.includes(value)) {
          dummy.push(value);
          onValueChange('config', dummy);
        }
      } else {
        dummy.splice(dummy.indexOf(value), 1);
        onValueChange('config', dummy);
      }
    } else {
      onValueChange(['count'], value);
    }
  };

  const setMethodnOptions = (e, name) => {
    let temp = {
      count: 0,
      config: [],
      method: verificationMethod,
      type: verificationType
    };
    if (name === 'method') {
      temp['method'] = e.target.value;
      if (values.count > 0) {
        temp['count'] = values.count;
      }
      setVerificationMethod(e.target.value);
    } else if (name === 'type') {
      temp['type'] = e.target.value;
      if (values.count > 0) {
        temp['count'] = values.count;
      }
      setVerificationType(e.target.value);
    } else if (name === 'count') {
      temp['count'] = e;
    } else {
      temp['config'] = [...temp['config'], ...e];
      temp['count'] = values.count;
    }

    onValueChange(name, temp);
  };

  const handleConfigChangeForAllTypes = (event, value) => {
    if (event && verificationType === 'SPECIFIC') {
      let dummy = [...values.config];
      if (event.target.checked) {
        if (!dummy.includes(value)) {
          dummy.push(value);
        }
      } else {
        dummy.splice(dummy.indexOf(value), 1);
      }
      setMethodnOptions(dummy, 'config');
    } else {
      setMethodnOptions(value, 'count');
    }
  };

  const hasValue = (name) => {
    return values.config && values.config.includes(name);
  };

  const toggleEduAdcPrice = (type) => {
    const eduTypeName = EDUCATION_TYPES_ADDONS[type];
    const { price } = addonDetails[eduTypeName];
    setEduAdcPriceTagValue(price);
  };
  const priceInfoContainer = (stringText, price) => {
    switch (stringText) {
      case 'start':
        return (
          <span className={styles.startFrom}>
            {'Starts from '}
            <span className={styles.rupee}>&#8377;</span>
            {price && convertToIndianNumeration(price || 0)}
          </span>
        );
      default:
        return (
          <span className={styles.startFrom}>
            <span className={styles.rupee}>&#8377;</span>
            {price && convertToIndianNumeration(price || 0)}
            {' per check'}
          </span>
        );
    }
  };

  const priceInfoHandle = () => {
    if (verificationMethod || addonsConfiguredLengthIsOne || !showAllEduTypes) {
      return priceInfoContainer('', eduAdcPriceTagValue);
    } else {
      // Minimum among all the verification method if no method is selection
      const values = Object.values(filteredObj).map((item) => item.price);
      const minValue = Math.min(...values);
      return priceInfoContainer('start', minValue);
    }
  };

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={!disabled ? '0' : '1'}
            className={styles.addonsContainerHeader}
            onClick={() => (!disabled ? decoratedOnClick(0) : setActiveAccordion(null))}
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={
                    PLAIN_CHECK_ICONS['education'] ? PLAIN_CHECK_ICONS['education'].icon : faBan
                  }
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <span className={disabled ? styles.heading : ''}>{props?.heading}</span>
              <div className={styles.headingRight}>
                {priceInfoHandle()}
                {activeAccordion === 0 ? (
                  <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                ) : !disabled ? (
                  <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                ) : (
                  <Image name={'DisableDownAngle.svg'} svg={'DisableDownAngle.svg'} />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <Row>
                {showAllEduTypes ? (
                  <>
                    <Col lg={4} md={4} sm={4} xs={4} className={styles.subHeadingDiv}>
                      <p style={{ marginBottom: '0' }}>Select quantity</p>
                      <div className={styles.customCounter}>
                        <CustomCounter
                          limit={CHECKS_LIMIT.EDU - eduCheckCount}
                          totalCount={counterValue}
                          value={values.count}
                          index={null}
                          valueChange={(value) => {
                            handleCounterValueChange(value);
                            handleConfigChangeForAllTypes(null, value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.headingDiv}>
                      {'Choose verification type:'} <span className={styles.required}>*</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.headingDiv}>
                      <Form.Group controlId='eduType'>
                        {values &&
                          EDUCATION_TYPES.map((type, index) => (
                            <React.Fragment key={index}>
                              {has(props?.addonDetails, EDUCATION_TYPES_ADDONS[type.name]) ? (
                                <Form.Check
                                  type={'radio'}
                                  className={styles.formCheck}
                                  key={`eduType-${index}`}
                                  inline
                                >
                                  <Form.Check.Input
                                    type={'radio'}
                                    name={type.name}
                                    id={type.name}
                                    disabled={
                                      !isSpecific
                                        ? values?.count === 0
                                        : values?.config?.length === 0
                                    }
                                    onChange={(e) => {
                                      toggleEduAdcPrice(type.name);
                                      setMethodnOptions(e, 'method');
                                    }}
                                    checked={
                                      verificationMethod === type.name ||
                                      addonsConfiguredLengthIsOne
                                    }
                                    value={type.name}
                                  />
                                  <Form.Check.Label htmlFor={type.name}>
                                    {type.label}
                                  </Form.Check.Label>
                                </Form.Check>
                              ) : (
                                ''
                              )}
                            </React.Fragment>
                          ))}
                      </Form.Group>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.subHeadingDiv}>
                      {'Customize your options:'} <span className={styles.required}>*</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.headingDiv}>
                      <Form.Group controlId='eduOption'>
                        {[
                          { label: 'Chronology', name: 'CHRONOLOGICAL' },
                          { label: 'Specific', name: 'SPECIFIC' }
                        ].map((type, index) => {
                          return (
                            <Form.Check
                              type={'radio'}
                              className={styles.formCheck}
                              key={`eduOption-${index}`}
                              inline
                            >
                              <Form.Check.Input
                                type={'radio'}
                                name={type.name}
                                id={type.name}
                                onChange={(e) => setMethodnOptions(e, 'type')}
                                checked={verificationType === type.name}
                                disabled={values.count === 0 && verificationType !== 'SPECIFIC'}
                                value={type.name}
                              />
                              <Form.Check.Label htmlFor={type.name}>{type.label}</Form.Check.Label>
                            </Form.Check>
                          );
                        })}
                      </Form.Group>
                    </Col>
                    {verificationType === 'SPECIFIC' && (
                      <Col lg={8} md={8} sm={8} xs={8}>
                        <Form.Group controlId={'checkboxTypes'}>
                          {EDUCATION_TYPES_FOR_PACKAGE_CONFIG.map((item, index) => {
                            return (
                              <Col lg={6} md={6} sm={6} xs={6} key={index}>
                                <Form.Check type={'checkbox'} className={styles.formCheck}>
                                  <Form.Check.Input
                                    type={'checkbox'}
                                    name={`${index}_check`}
                                    id={`${index}_check`}
                                    disabled={
                                      !hasValue(item.name) && values.count === values.config.length
                                    }
                                    checked={hasValue(item.name)}
                                    onChange={(e) => {
                                      handleConfigChangeForAllTypes(e, item.name);
                                    }}
                                  />
                                  <Form.Check.Label htmlFor={`${index}_check`}>
                                    {item.label}
                                  </Form.Check.Label>
                                </Form.Check>
                              </Col>
                            );
                          })}
                        </Form.Group>
                      </Col>
                    )}
                  </>
                ) : (
                  <>
                    {isSpecific ? (
                      EDUCATION_TYPES_FOR_PACKAGE_CONFIG.map((item, index) => {
                        return !pDetails?.config?.education?.includes(item.name) ? (
                          <Col lg={12} md={12} sm={12} xs={12} key={index}>
                            <Form.Group controlId={`${index}_check`}>
                              <Form.Check
                                name={`${index}_check`}
                                id={`${index}_check`}
                                type='checkbox'
                                checked={hasValue(item.name)}
                                onChange={(e) => handleConfigChange(e, item.name)}
                                label={item.label}
                              />
                            </Form.Group>
                          </Col>
                        ) : null;
                      })
                    ) : (
                      <Col lg={12} md={12} sm={12} xs={12} className={styles.subHeadingDiv}>
                        <div className={styles.customCounter}>
                          <CustomCounter
                            limit={CHECKS_LIMIT.EDU - eduCheckCount}
                            totalCount={counterValue}
                            value={Number(values.count)}
                            index={null}
                            valueChange={(value) => {
                              handleCounterValueChange(value);
                              handleConfigChange(null, value);
                            }}
                          />
                        </div>
                      </Col>
                    )}
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.headingDiv}>
                      {'Choose verification type:'} <span className={styles.required}>*</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.headingDiv}>
                      <Form.Group controlId='eduType'>
                        {values &&
                          EDUCATION_TYPES.map((type, index) => {
                            return (
                              <>
                                {type.name === pDetails?.config?.educationType ? (
                                  <Form.Check
                                    type={'radio'}
                                    className={styles.formCheck}
                                    key={`eduType-${index}`}
                                    inline
                                  >
                                    <Form.Check.Input
                                      type={'radio'}
                                      name={type.name}
                                      id={type.name}
                                      disabled={
                                        !isSpecific
                                          ? values?.count === 0
                                          : values?.config?.length == 0
                                      }
                                      checked={true}
                                      value={type.name}
                                    />
                                    <Form.Check.Label htmlFor={type.name}>
                                      {type.label}
                                    </Form.Check.Label>
                                  </Form.Check>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          })}
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Education;
