// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QrGUddL7FPdH8eKXYtQZ p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.QrGUddL7FPdH8eKXYtQZ .A13FnLbzrR9kSwSKTukB {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding: 1px 8px;
  border-radius: 2px;
  margin-left: 8px;
}
.QrGUddL7FPdH8eKXYtQZ .MabS0EZFyDQbytZsPRBm {
  color: #52c41a;
  border: 1px solid #b7eb8f;
  background: #f6ffed;
}
.QrGUddL7FPdH8eKXYtQZ .EruCDCWb3Mp22cXlNb8i {
  color: #595959;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
}
.QrGUddL7FPdH8eKXYtQZ .jMBaQzN4bK8hvobLf0sW {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.QrGUddL7FPdH8eKXYtQZ .xmp87QQMdy3XKxh84Fxg {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}

.UiDdZFxHLzeZkJAcFJ3k {
  text-align: right;
}
.UiDdZFxHLzeZkJAcFJ3k p {
  margin-bottom: 5px;
}
.UiDdZFxHLzeZkJAcFJ3k .ZWqRkrW9oSHLssB9yiew {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}
.UiDdZFxHLzeZkJAcFJ3k .djH3IXZ2W5470Qx3nqgf {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.UiDdZFxHLzeZkJAcFJ3k .lg8qkPMDqhzmpv0VDXbf {
  color: #389e0d;
}
.UiDdZFxHLzeZkJAcFJ3k .WnZObDsZcx3TWSLPjqt1 {
  color: #333333;
}
.UiDdZFxHLzeZkJAcFJ3k .Q1JIhbQsy9GrHCyfViFy {
  font-family: roboto;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Packages/Prepaid/Credits/CreditTransactionHistory/CreditDetail/CreditDetail.module.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,cAAA;EACA,yBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,cAAA;EACA,yBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;;AAGA;EACE,iBAAA;AAAF;AACE;EACE,kBAAA;AACJ;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AACE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AACE;EACE,cAAA;AACJ;AACE;EACE,cAAA;AACJ;AACE;EACE,mBAAA;AACJ","sourcesContent":[".detailsContainer {\n  p {\n    margin-bottom: 5px;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #333333;\n  }\n  .tag {\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 20px;\n    padding: 1px 8px;\n    border-radius: 2px;\n    margin-left: 8px;\n  }\n  .activeTag {\n    color: #52c41a;\n    border: 1px solid #b7eb8f;\n    background: #f6ffed;\n  }\n  .inactiveTag {\n    color: #595959;\n    border: 1px solid #d9d9d9;\n    background: #f5f5f5;\n  }\n  .addedRemovedBy {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #666666;\n  }\n  .note {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 24px;\n    color: #666666;\n  }\n}\n.priceContainer {\n  text-align: right;\n  p {\n    margin-bottom: 5px;\n  }\n  .date {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #666666;\n  }\n  .amount {\n    font-weight: normal;\n    font-size: 18px;\n    line-height: 21px;\n  }\n  .amountCredit {\n    color: #389e0d;\n  }\n  .amountDebit {\n    color: #333333;\n  }\n  .rupee {\n    font-family: roboto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsContainer": `QrGUddL7FPdH8eKXYtQZ`,
	"tag": `A13FnLbzrR9kSwSKTukB`,
	"activeTag": `MabS0EZFyDQbytZsPRBm`,
	"inactiveTag": `EruCDCWb3Mp22cXlNb8i`,
	"addedRemovedBy": `jMBaQzN4bK8hvobLf0sW`,
	"note": `xmp87QQMdy3XKxh84Fxg`,
	"priceContainer": `UiDdZFxHLzeZkJAcFJ3k`,
	"date": `ZWqRkrW9oSHLssB9yiew`,
	"amount": `djH3IXZ2W5470Qx3nqgf`,
	"amountCredit": `lg8qkPMDqhzmpv0VDXbf`,
	"amountDebit": `WnZObDsZcx3TWSLPjqt1`,
	"rupee": `Q1JIhbQsy9GrHCyfViFy`
};
export default ___CSS_LOADER_EXPORT___;
