// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mN0H5ksyRbQh2A65Sg0E {
  font-family: Poppins !important;
  font-style: normal !important;
}
.mN0H5ksyRbQh2A65Sg0E .h8byperfgYBsPG9U6bug {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}
.mN0H5ksyRbQh2A65Sg0E .rfdbLiMf99c8TywyVlxP {
  margin-left: 32px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z {
  margin-top: 12px;
  margin-left: 32px;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z .X_IvWUWzIkzdVxuuMjMX {
  padding-bottom: 6px;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z .X_IvWUWzIkzdVxuuMjMX label {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #595959 !important;
}
.mN0H5ksyRbQh2A65Sg0E .QK5fGeUuCcBWJ5wsno6Z .X_IvWUWzIkzdVxuuMjMX input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  color: #bfbfbf !important;
}
.mN0H5ksyRbQh2A65Sg0E .LUFsHISPD6l0kdSQd3kt {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #bfbfbf;
}
.mN0H5ksyRbQh2A65Sg0E .LUFsHISPD6l0kdSQd3kt .ZAZabTQr8Bn8ht5iLEMJ {
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/core-components/Packages/Identity/Identity.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,6BAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAAE;EACE,gBAAA;EACA,iBAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AAFM;EACE,8BAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;AAIR;AAFM;EACE,mBAAA;EACA,yBAAA;AAIR;AACE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAAI;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAEN","sourcesContent":[".idPackageComp {\n  font-family: Poppins !important;\n  font-style: normal !important;\n  .checkHeading {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #333333;\n    margin-bottom: 0px;\n  }\n  .subTitle {\n    margin-left: 32px;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 22px;\n    color: #8c8c8c;\n  }\n  .checkContainer {\n    margin-top: 12px;\n    margin-left: 32px;\n    .formCheck {\n      padding-bottom: 6px;\n      label {\n        font-weight: normal !important;\n        font-size: 14px !important;\n        line-height: 22px !important;\n        color: #595959 !important;\n      }\n      input[type='checkbox']:disabled + label {\n        cursor: not-allowed;\n        color: #bfbfbf !important;\n      }\n    }\n  }\n\n  .pCheckHeading {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 22px;\n    color: #bfbfbf;\n    .pCheckSubHeading {\n      font-weight: 500;\n      font-size: 12px;\n      color: #999999;\n      margin-left: 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"idPackageComp": `mN0H5ksyRbQh2A65Sg0E`,
	"checkHeading": `h8byperfgYBsPG9U6bug`,
	"subTitle": `rfdbLiMf99c8TywyVlxP`,
	"checkContainer": `QK5fGeUuCcBWJ5wsno6Z`,
	"formCheck": `X_IvWUWzIkzdVxuuMjMX`,
	"pCheckHeading": `LUFsHISPD6l0kdSQd3kt`,
	"pCheckSubHeading": `ZAZabTQr8Bn8ht5iLEMJ`
};
export default ___CSS_LOADER_EXPORT___;
