import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';

export const GENDER = {
  1: 'Male',
  2: 'Female',
  3: 'Not Specified'
};
export const RowData = (props) => {
  const { data, errorClass, onChangeHandler, type, rowIndex, cellName } = props;
  let result = null;

  const handleChange = (e) => {
    onChangeHandler(e.target.value, rowIndex, cellName);
  };

  const handleKeyDown = (e) => {
    // Check if the pressed key is a number (0-9) or a valid control key (e.g., Backspace, Delete)
    if (
      cellName === 'phone' &&
      !/^\d$/.test(e.key) &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  if (typeof data === 'object') {
    result = (
      <td>
        <div style={{ display: 'flex' }}>
          <input
            type='text'
            defaultValue={data?.value || ''}
            className={errorClass}
            onBlur={handleChange}
            onMouseLeave={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div style={{ marginLeft: '5px' }}>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip
                  style={{
                    color: '#ffffff',
                    fontSize: '8px'
                  }}
                  id={'error'}
                >
                  {data?.error}
                </Tooltip>
              }
            >
              <div>
                <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#808080' }} />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </td>
    );
  } else {
    if (type === 'gender') {
      return <td> {GENDER[data]}</td>;
    }
    result = <td>{data}</td>;
  }
  return result;
};

export const getTableRows = ({ array, values, styles, onChangeHandler }) => {
  let rows = [];
  array?.length >= 0 &&
    array.forEach((row, index) => {
      let rowInfo = (
        <tr className={styles.rowData} key={index}>
          <RowData
            data={row?.staff_id || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='staff_id'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.full_name || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='full_name'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.date_of_birth || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='date_of_birth'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.designation || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='designation'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.date_of_joining || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='date_of_joining'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.last_working_day || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='last_working_day'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.phone_number || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='phone_number'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.personal_email || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='personal_email'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.business_email || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='business_email'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.employment_type || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='employment_type'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.exit_type || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='exit_type'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.exit_reason || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='exit_reason'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.eligible_for_rehire || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='eligible_for_rehire'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.rm_name || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='rm_name'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.rm_designation || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='rm_designation'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.rm_email || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='rm_email'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.rm_phone || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='rm_phone'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.ctc || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='ctc'
            onChangeHandler={onChangeHandler}
          />
        </tr>
      );

      rows.push(rowInfo);
    });
  return rows;
};

// get required symbol
export const getRequiredField = (value) => {
  return (
    <div>
      {value}
      <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
    </div>
  );
};

//get important field
export const getImportantField = (value) => {
  return (
    <div>
      {value}
      <span style={{ color: 'orange', marginLeft: '5px' }}>*</span>
    </div>
  );
};

// get headers for table
export const getHeaders = () => {
  return (
    <>
      <th>{getImportantField('Staff ID')}</th>
      <th>{getRequiredField('Employee Full Name')}</th>
      <th>{getRequiredField('Date of birth')}</th>
      <th>{getRequiredField('Designation')}</th>
      <th>{getRequiredField('Date of joining')}</th>
      <th>{getRequiredField('Last working day')}</th>
      <th>{'Phone Number'}</th>
      <th>{'Personal Email'}</th>
      <th>{'Business Email'}</th>
      <th>{'Employment Type'}</th>
      <th>{'Exit Type'}</th>
      <th>{'Exit Reason'}</th>
      <th>{'Eligible For Rehire'}</th>
      <th>{'RM Name'}</th>
      <th>{'RM Designation'}</th>
      <th>{'RM Email'}</th>
      <th>{'RM Phone'}</th>
      <th>{'CTC'}</th>
    </>
  );
};
