import React, { useState, useEffect } from 'react';

import { Card, Button, Container } from 'react-bootstrap';
import styles from './RequestPayment.module.scss';
import { Redirect } from 'react-router-dom';
import Image from '../../core-components/Image/Image';
import AppConstants from '../../../../../core-components/AppConstants';
import { getOrderDetailsByID } from '../../../../../api/company';
import { toast } from 'react-toastify';
import TextWithCopyButton from '../../Integrations/Utils/TextWithCopyButton';
import { errToastMessage } from '../../utils/Utilities';

export default function SuccessPaymentRequest() {
  const proformaInvoice = new URLSearchParams(document.location.search).get('proforma_number');
  const orderId = new URLSearchParams(document.location.search).get('order_id');
  const [paymentLink, setPaymentLink] = useState(
    `${AppConstants.baseURL}purchase/payment-request?token=`
  );

  useEffect(() => {
    if (orderId) {
      getOrderDetailsByID(orderId)
        .then((res) => {
          const orderDetails = res.data?.data?.payment_orders;
          if (orderDetails[0] && orderDetails[0].status === 'PENDING') {
            setPaymentLink(
              `${AppConstants.baseURL}purchase/payment-request?token=${orderDetails[0]?.order_unique_token}`
            );
          }
        })
        .catch((err) => {
          errToastMessage(err);
        });
    }
  }, []);

  if (!proformaInvoice && !orderId) {
    return (
      <Redirect
        to={{
          pathname: '/transaction'
        }}
      />
    );
  }
  const handleBuyBtn = () => {
    window.open(`${AppConstants.baseURL}orderDetails?order_id=${orderId}`, '_blank');
  };

  return (
    <Card className={`text-center ${styles.card}`}>
      <Card.Body className={`${styles.body}`}>
        <Image name={'orderSent.svg'} isSA={false} className={styles.image} />
        <Card.Title className={styles.title}>{'Order Created & Sent'}</Card.Title>
        <Card.Text className={styles.referenceText}>Reference Number: {proformaInvoice}</Card.Text>
        <Card.Text className={styles.warningText}>
          <span className={styles.noteText}>Note:</span> If you forgot to include someone in the
          order request, simply copy the payment link and share it with someone who can pay on your
          behalf.
        </Card.Text>
        <TextWithCopyButton
          text={paymentLink}
          number={55}
          message='Payment link copied to clipboard'
        />
        <Container className={styles.btnContainer}>
          <Button onClick={handleBuyBtn} variant='' className={styles.button}>
            View order
          </Button>
        </Container>
        <Card.Text className={styles.help}>
          <small className={`text-muted`}>
            Need help ?{' '}
            <span
              className={styles.contactUs}
              onClick={() => window.open('mailto:cs@springverify.com', '_blank')}
            >
              Contact us
            </span>
          </small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
