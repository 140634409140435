import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import styles from './Wallet.module.scss';
import cn from 'classnames';
import { indianNumerationValue } from '../../core-components/Utilities/utilities';
const arrearType = {
  ADD_ON: 'Add-ons',
  ADDITIONAL_CHARGE: 'Auto-Approved Additional Costs',
  UNPAID_BALANCE: 'Unpaid Purchases'
};

function ArrearsBreakdown(props) {
  const { walletDetail } = props;

  const filteredAddons = () => {
    /* {Function to get array of only ADD_ON from the arrear array} */
    let filteredAddons = walletDetail.arrears.filter((val) => {
      return val.type == 'ADD_ON';
    });
    return filteredAddons;
  };

  const getAutoAprovedArrears = () => {
    let totalADCCost = 0;
    totalADCCost = walletDetail?.arrears
      .filter((val) => val.type == 'ADDITIONAL_CHARGE')
      .map((arr) => arr.totalAmount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return totalADCCost;
  };
  return (
    <Card className={styles.arrearsCardBreakdownContainer}>
      <div className={styles.arrearBreakdownContainer}>
        <p className={styles.blackColor}>Arrears:</p>
        {/* ----- Addons section ----- */}
        {filteredAddons().length ? (
          <Row className={styles.row}>
            <Col>
              <div> {arrearType['ADD_ON']}</div>
              {filteredAddons().map((val) => {
                return (
                  <div className={styles.list}>
                    <li>
                      {val.checkType === 'CIBIL' ? 'CREDIT CHECK' : val.checkType} * {val.count}
                    </li>
                  </div>
                );
              })}
            </Col>
            <Col className={styles.amount}>
              {indianNumerationValue(
                filteredAddons().reduce((sum, val) => sum + val.totalAmount, 0)
              )}
            </Col>
          </Row>
        ) : null}

        {/* ----- ADC and Unpaid Balance section ----- */}
        {getAutoAprovedArrears() > 0 ? (
          <Row className={styles.row}>
            <Col>{arrearType['ADDITIONAL_CHARGE']}</Col>
            <Col className={styles.amount}>{indianNumerationValue(getAutoAprovedArrears())}</Col>
          </Row>
        ) : null}
        {walletDetail.arrears.map((val, index) => {
          if (!['ADD_ON', 'ADDITIONAL_CHARGE'].includes(val.type)) {
            return (
              <Row className={styles.row}>
                <Col>{arrearType[val.type]}</Col>
                <Col className={styles.amount}>{indianNumerationValue(val.totalAmount)}</Col>
              </Row>
            );
          }
        })}
        <hr className={styles.hrTag} />
        {/* ----- Total section ----- */}
        <Row className={cn(styles.row, styles.blackColor)}>
          <Col>
            <div className={styles.amountTotalText}>Total</div>
          </Col>
          <Col className={cn(styles.amount, styles.amountTotal)}>
            {indianNumerationValue(walletDetail.arrearsTotalAmount)}
          </Col>
        </Row>
      </div>
    </Card>
  );
}

export default React.memo(ArrearsBreakdown);
