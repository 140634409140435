// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentModalV1.paymentModalV2 .modal-content {
  height: 648px;
}
.paymentModalV1 .modal-content {
  max-height: 648px;
  min-height: 565px;
  width: 720px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/CreditBuyPackagePage/RequestPayment/RequestPaymentModal.scss"],"names":[],"mappings":"AAEI;EACE,aAAA;AADN;AAIE;EACE,iBAAA;EACA,iBAAA;EACA,YAAA;AAFJ","sourcesContent":[".paymentModalV1 {\n  &.paymentModalV2 {\n    .modal-content {\n      height: 648px;\n    }\n  }\n  .modal-content {\n    max-height: 648px;\n    min-height: 565px;\n    width: 720px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
