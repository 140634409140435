export const baseContactForm = {
  hrName: '',
  relation: '',
  countryCode: '+91',
  hrMobile: '',
  hrEmail: '',
  companyName: '',
  linkedIn: '',
  anonymous: false,

  emailExits: false
};
export const referralRelation = [
  'Worked at same organization',
  'Connected over a job opportunity',
  'Friend/Acquaintance',
  'Other'
];
export const getStatusRow = (status) => {
  switch (status) {
    case 'ONGOING':
      return 'Ongoing';
    case 'SUCCESSFUL':
      return 'Successful';
    case 'INVALID_REFERRAL':
      return 'Invalid referral';
    case 'INVALID_DETAILS':
      return 'Invalid details';
    default:
      return '-';
  }
};
export const referralRelationMapping = {
  'Worked at same organization': 'WORKED_AT_SAME_ORGANIZATION',
  'Connected over a job opportunity': 'CONNECTED_OVER_A_JOB_OPPORTUNITY',
  'Friend/Acquaintance': 'FRIEND_ACQUAINTANCE',
  Other: 'OTHER'
};

export const hrContactsResponseMapper = (hrContactsData) => {
  let mappedObjects = [];

  mappedObjects =
    hrContactsData &&
    hrContactsData.length &&
    hrContactsData.map((item) => {
      return {
        name: item?.hrName,
        source: referralRelationMapping[item?.relation],
        phone_number: item?.hrMobile ? `${item.countryCode + item.hrMobile}` : null,
        email_id: item?.hrEmail,
        company_name: item?.companyName || null,
        linked_in_url: item?.linkedIn,
        is_referral_anonymous: item?.anonymous
      };
    });

  return mappedObjects;
};

export const findDuplicateEmails = (arr) => {
  const emailToIndicesMap = {};

  // Initialize the map with empty arrays for each unique email
  for (let i = 0; i < arr.length; i++) {
    const email = arr[i]?.hrEmail.toLowerCase() || null;
    if (email) {
      if (!emailToIndicesMap[email]) {
        emailToIndicesMap[email] = [];
      }
      emailToIndicesMap[email].push(i);
    }
  }

  // Filter the email indices that have more than one occurrence
  const duplicatesIndices = Object.values(emailToIndicesMap).filter(
    (indices) => indices.length > 1
  );
  console.log(emailToIndicesMap, duplicatesIndices);
  let tempErrorObj = {};

  if (duplicatesIndices.length > 0) {
    duplicatesIndices.forEach((indices) => {
      indices.forEach((ind) => {
        tempErrorObj[ind] = 'Same Email ID cannot be added again';
      });
    });
  }
  return tempErrorObj;
};

// Custom Errors for the second and third row
export const hasMobileAndEmailError = (errors, index, submitCount) => {
  return (
    errors &&
    errors?.additionalContact?.length >= index + 1 &&
    ((submitCount > 0 && errors.additionalContact[index]?.mobileErr) ||
      errors.additionalContact[index]?.emailErr ||
      errors.additionalContact[index]?.hrMobile ||
      errors.additionalContact[index]?.hrEmail)
  );
};

export const hasCompanyAndLinkedError = (errors, index, submitCount) => {
  return (
    errors &&
    errors?.additionalContact?.length >= index + 1 &&
    ((submitCount > 0 && errors.additionalContact[index]?.companyNameAndLinkedInErr) ||
      errors.additionalContact[index]?.linkedIn ||
      errors.additionalContact[index]?.companyName)
  );
};
