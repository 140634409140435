import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isArray } from 'lodash';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import validationSchema from './AdminCard.validation';

import {
  addAdministrators,
  editAdministrators,
  getCategories,
  getSecuritySettings as getSecuritySettingsAPI,
  INVALIDATE_CATEGORIES
} from '../../../../actions/company';
import {
  errToastMessage,
  getErrorMessage,
  getIntlPhoneNumberObject
} from '../../../../utils/utilities';

const initialValues = {
  fullName: '',
  email: '',
  mobile: '',
  countryCode: '+91',
  access: 'admin',
  category: [],
  emailExists: false
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    administrators: state.administrators,
    profile: state.profile.profile,
    companyId: state?.profile?.company_user_mapping?.company_id,
    securitySettings: state.securitySettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    getCategories: () => dispatch(getCategories()),
    addAdministrators: (data) => dispatch(addAdministrators(data)),
    editAdministrators: (data) => dispatch(editAdministrators(data)),
    getSecuritySettings: (companyId) => dispatch(getSecuritySettingsAPI(companyId))
  };
};

const mapCategoriesId = (userCategories = []) => {
  const userCategoryId = [];
  if (isArray(userCategories) && userCategories?.length > 0) {
    for (let value of userCategories) {
      userCategoryId.push({
        label: value['userCategory']['categoryName'],
        value: value['userCategoryId']
      });
    }
  }
  return userCategoryId;
};

const newUserCategoriesMapping = (selectedValues, allCategories) => {
  if (!selectedValues?.length || !allCategories?.length) {
    return [];
  }

  const selectedSet = new Set(selectedValues);
  return allCategories
    .filter(({ id }) => selectedSet.has(id))
    .map(({ categoryName, id }) => ({ label: categoryName, value: id }));
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const query = new URLSearchParams(window?.location?.search);
      const isNewUserEdit = query.get('isNewUserEdit');
      let admin =
        props?.editAdminData === null
          ? null
          : {
              fullName: props?.editAdminData?.name,
              email: props?.editAdminData?.email,
              mobile: props?.editAdminData?.mobile,
              countryCode: props?.editAdminData?.countryCode,
              access:
                props?.editAdminData?.accessLevel === 5
                  ? 'admin'
                  : props?.editAdminData?.accessLevel === 10
                  ? 'hr'
                  : '',
              category: mapCategoriesId(props?.editAdminData?.userCategoryMappings)
            };
      if (props?.editAdminData) {
        if (props?.editAdminData?.mobile?.length > 10) {
          let phoneNumberObj = getIntlPhoneNumberObject(props?.editAdminData?.mobile);
          if (phoneNumberObj) {
            admin['mobile'] = phoneNumberObj.nationalNumber;
            admin['countryCode'] = `+${phoneNumberObj.countryCallingCode}`;
          }
        } else {
          admin['mobile'] = props?.editAdminData?.mobile;
          admin['countryCode'] = props?.editAdminData?.countryCode;
        }
      }
      if (isNewUserEdit) {
        const data = secureLocalStorage.getItem('NEW_USER');
        admin = {
          fullName: data?.name || '',
          email: data?.email || '',
          access: data?.user_type || '',
          category: newUserCategoriesMapping(
            data?.user_category_ids,
            props?.categories?.userCategories
          )
        };

        if (data?.mobile?.length > 10) {
          let phoneNumberObj = getIntlPhoneNumberObject(data?.mobile);
          if (phoneNumberObj) {
            admin['mobile'] = phoneNumberObj.nationalNumber;
            admin['countryCode'] = `+${phoneNumberObj.countryCallingCode}`;
          }
        }
      }
      return mergeWith(
        {
          isValidPhone: true
        },
        initialValues,
        admin,
        (a, b) => (b === null ? a : b)
      );
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setSubmitting, setFieldError, resetForm, dirty, props }) => {
      let categoriesCopy = values.category || [];
      const userCategoryId = categoriesCopy.map((category) => category.value);

      if (!props?.isEditAdmin) {
        const formData = {
          email: values.email,
          name: values.fullName,
          mobile: values.mobile ? values?.countryCode + values?.mobile : '',
          user_type: values.access,
          user_category_ids: userCategoryId
        };
        props
          .addAdministrators(formData)
          .then(() => {
            resetForm();
            setSubmitting(false);
            toast.success('User added successfully');
            props.showAdminCardHandler(true);
          })
          .catch((error) => {
            if (
              error?.response?.data?.msg?.includes('An admin with this email') ||
              error?.response?.data?.message?.includes('An admin with this email')
            ) {
              setFieldError('email', 'Email already exists');
            } else if (
              error?.response?.data?.msg === 'Email is not in the correct format' ||
              error?.response?.data?.message === 'Email is not in the correct format'
            ) {
              setFieldError('email', 'Enter valid email address');
            }
            errToastMessage('User could not be added.');
            setSubmitting(false);
          });
      } else {
        const formData = {
          admin_id: props?.editAdminData?.id || props?.editAdminData?.companyUserMapping.userId,
          name: values.fullName,
          mobile: values.mobile ? values?.countryCode + values?.mobile : '',
          country_code: values.countryCode,
          email: values.email,
          user_type: values.access,
          user_category_ids: userCategoryId
        };
        props
          .editAdministrators(formData)
          .then(() => {
            resetForm();
            setSubmitting(false);
            toast.success('User updated successfully');
            props.showAdminCardHandler(true);
          })
          .catch((error) => getErrorMessage(error));
      }
    },
    displayName: 'Admin Card'
  })
);
