import React from 'react';
import MaterialChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const CustomizedChip = (props) => {
  const StyledChip = withStyles({
    root: {
      color: 'black',
      backgroundColor: `${props.color} !important`,
      '&:hover': {
        backgroundColor: props.color,
        filter: 'brightness(120%)'
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: props.color,
        borderColor: props.color
      }
    },
    outlined: {
      color: props.color,
      border: `1px solid ${props.color}`,
      backgroundColor: `transparent !important`
    },
    icon: {
      color: props.variant === 'outlined' ? props.color : 'white'
    },
    deleteIcon: {
      color: props.variant === 'outlined' ? props.color : 'white',
      '&:hover': {
        color: props.deleteIconHoverColor || props.color,
        zoom: '120%',
        filter: 'brightness(120%)'
      }
    }
  })(MaterialChip);

  return <StyledChip {...props} />;
};

export default CustomizedChip;
