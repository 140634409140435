import React, { useEffect } from 'react';
import { DropdownList } from 'react-widgets';

import Image from '../../core-components/Image/Image';
import styles from '../AlumniVerificationTabs.module.scss';
import { DATA_SYNC_MODE, DATA_SYNC_STATUS } from '../utils';

export default function Filters({ filterValues, setFilterValues, fetchRequestList, isSA }) {
  useEffect(() => {
    fetchRequestList(filterValues);
  }, [filterValues]);

  return (
    <div className={styles.row}>
      <div className={styles.dateAndDropdownFilters}>
        <div className={styles.filterItem}>
          <div className={`${styles.entityLabel}`}>Mode</div>
          <DropdownList
            data={DATA_SYNC_MODE}
            placeholder={<span className={styles.dropDownPlaceholder}>All</span>}
            containerClassName={styles.dropDownStyle + ' reactWidgetCustomDp'}
            value={filterValues.mode}
            textField='label'
            name='entity'
            selectIcon={<Image isSA={isSA} name='Mask.svg' />}
            onChange={(e) => {
              setFilterValues((prevState) => ({
                ...prevState,
                mode: e.value
              }));
            }}
          />
        </div>
        <div className={styles.filterItem}>
          <div className={`${styles.entityLabel}`}>Status</div>
          <DropdownList
            data={DATA_SYNC_STATUS}
            placeholder={<span className={styles.dropDownPlaceholder}>All</span>}
            containerClassName={styles.dropDownStyle + ' reactWidgetCustomDp'}
            value={DATA_SYNC_STATUS.find((item) => item.value === filterValues.status)}
            textField='label'
            name='entity'
            selectIcon={<Image isSA={isSA} name='Mask.svg' />}
            onChange={(e) => {
              setFilterValues((prevState) => ({
                ...prevState,
                status: e.value
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
}
