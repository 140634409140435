import React from 'react';

import { addAlumniRecord, getAlumniRecord, getAlumniTableData } from '../../../../api/company';
import AppConstants from '../../../../core-components/AppConstants';

import AlumniRecords from '../../frontend-common/AlumniVerification/AlumniRecords';

const AlumniRecordsContainer = (props) => {
  return (
    <AlumniRecords
      addAlumniRecord={addAlumniRecord}
      getAlumniRecord={getAlumniRecord}
      getAlumniTableData={getAlumniTableData}
      AppConstants={AppConstants}
    />
  );
};

export default AlumniRecordsContainer;
