import * as Yup from 'yup';

export default Yup.object().shape({
  // identityNumber: Yup.string()
  //   .matches(/^[2-9]{1}[0-9]{11}$/, {
  //     message: 'Please enter valid ID number',
  //     excludeEmptyString: true
  //   })
  //   .min(3, 'Please enter valid ID number')
});
