import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../../../../../core-components/Button';
import Image from '../../../../../core-components/Image';
import styles from './Confirmation.module.scss';
import RazorpayButton from '../../../../../core-components/Razorpay/index';
import { loggly_payment_type } from '../../../../../utils/commonConstant';
import { convertToIndianNumeration } from '../../../../../utils/utilities';

export default ({
  price,
  onConfirm,
  onHide,
  paymentsObj,
  creditBalance,
  useCreditBalance,
  selectedOption,
  setShowLoader,
  ...props
}) => {
  const renderCredits = () => {
    if (useCreditBalance) {
      let totalAmount = selectedOption?.candidateCount * selectedOption?.costPerCandidate;

      if (totalAmount >= creditBalance) return convertToIndianNumeration(creditBalance);
      if (totalAmount < creditBalance) return convertToIndianNumeration(totalAmount);
    } else return 0;
  };
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Body className={styles.body}>
        <Row>
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image name='confirmationSymbol.svg' />
          </Col>
          <Col lg={11} md={11} sm={11} xs={11}>
            <p className={styles.title}>{'Confirm purchase ?'}</p>
            {creditBalance > 0 && (
              <p>
                <b className={styles.rupee}> &#8377;{renderCredits()}</b>{' '}
                {'credits will be used for this purchase'}
              </p>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <Button className={styles.cancelBtn} onClick={() => onHide()}>
          {'Cancel'}
        </Button>
        {price < 1 ? (
          <Button className={styles.confirmBtn} onClick={() => onConfirm()}>
            {'Confirm purchase'}
          </Button>
        ) : (
          <RazorpayButton
            {...paymentsObj}
            setShowLoader={setShowLoader}
            onHide={onHide}
            displayValue={false}
            type={loggly_payment_type.PACKAGE_BOUGHT}
            setTransactionId={props?.setTransactionId}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
