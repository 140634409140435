import React, { useEffect, useState } from 'react';
import styles from '../../Integrations.module.scss';
import Select from 'react-select';
import { Container, Button, Modal, Row, Col } from 'react-bootstrap';
import _, { isEmpty, capitalize, union } from 'lodash';
import Image from '../../../core-components/Image';
import { DropdownList } from 'react-widgets';
import {
  deleteIntegrations,
  getLeverStages,
  getLeverTags,
  getViewBuyPackages
} from '../../../../../../api/company';
import { toast } from 'react-toastify';
import { errToastMessage } from '../../../utils/Utilities';

export default function LeverHome(props) {
  const { setFieldValue, values, handleSubmit, subtypeMapping, leverConfig, status, isSubmitting } =
    props;
  const [stages, setStages] = useState([]); //TODO: get previous stages value to show in dropdown array values, from API
  const [allTags, setAllTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [calledAPIs, setCalledAPIs] = useState({
    tags: false,
    stages: false
  });
  const [dropdownTags, setDropdownTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState({});
  const [packages, setPackages] = useState([]);
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  const handleDelete = () => {
    setLoading(true);
    deleteIntegrations('LEVER', companyId)
      .then((resp) => {
        toast.success('Removed connection successfully.');
        setTimeout(() => {
          backHandler();
        }, 2000);
      })
      .catch((error) => {
        errToastMessage(error);
        setLoading(false);
      });
  };
  const fetchLeverStages = () => {
    getLeverStages(companyId)
      .then((resp) => {
        const data = resp?.data;
        if (data) {
          if (leverConfig?.stageDetails?.stageId) {
            const selectedStage = data.data.data.filter(
              (stage) => stage.id === leverConfig?.stageDetails?.stageId
            );
            setFieldValue('stayingPeriod', selectedStage.length > 0 ? selectedStage[0] : '');
          }
          setStages(data.data.data);
        }
        setCalledAPIs({ stages: true });
      })
      .catch((error) => {
        errToastMessage(error);
        setCalledAPIs({ stages: true });
      });
  };
  if (status && status?.trigger && status?.tags) {
    setTimeout(() => {
      if (companyId) {
        window.open(`/integrations?company_id=${companyId}`, '_self');
      } else {
        window.open(`/integrations`, '_self');
      }
    }, 1000);
  }
  const onClickStagesHandler = () => {
    //TODO: call getAPI for stages, that will be used for stages, use it to fetch new stages
    fetchLeverStages();
  };

  const onClickTagsHandler = () => {
    fetchLeverTags();
  };

  const fetchLeverTags = () => {
    getLeverTags(companyId)
      .then((response) => {
        let allTags = response.data.data.data.map((row) => row.text);
        setAllTags(allTags);
        setCalledAPIs({ tags: true });
        setTagsLoading(false);
      })
      .catch((error) => {
        errToastMessage(error);
        setCalledAPIs({ tags: true });
        setTagsLoading(false);
      });
  };

  // get subtypes
  useEffect(() => {
    getViewBuyPackages({ limit: 50, offset: 0, company_id: companyId })
      .then((result) => {
        setPackages(result.data.data.rows);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  }, []);

  useEffect(() => {
    if (!calledAPIs.stages) {
      fetchLeverStages();
    }
    if (!calledAPIs.tags) {
      fetchLeverTags();
    }
    setFieldValue('tags', subtypeMapping);
    setSelectedTags(subtypeMapping);
  }, [leverConfig, subtypeMapping]);

  useEffect(() => {
    const selectedTagsObj = { ...selectedTags };
    const allTagsArr = Object.values(selectedTagsObj).map((obj) => !isEmpty(obj) && obj.tags);
    setDropdownTags(_.difference(allTags, _.union(...allTagsArr)));
  }, [selectedTags, allTags]);

  const backHandler = () => {
    if (companyId) {
      window.open(`/integrations?company_id=${companyId}`, '_self');
    } else {
      window.open(`/integrations`, '_self');
    }
  };

  const tagDropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#666666',
      borderRadius: '4px',
      padding: '10px 20px',
      border: 'none',
      background: state.isFocused ? '#ffffff' : ''
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ebedf2',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#333333',
      display: 'flex'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#333333'
      };
    },
    valueContainer: (base) => ({
      ...base
    }),
    container: (base) => ({
      ...base
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: '10'
    })
  };
  const tagsSeparator = ({ innerProps }) => {
    return <span style={{ display: 'none' }} {...innerProps} />;
  };

  const setTagsValue = (packageId, subtypeId, value) => {
    let allSubtypesWithTags = { ...selectedTags };
    allSubtypesWithTags[subtypeId] =
      value && value.length > 0 ? { packageId: packageId, tags: [...value] } : {};
    setSelectedTags(allSubtypesWithTags);
    setFieldValue('tags', allSubtypesWithTags);
  };

  const isFormValid = () => {
    return (
      !isEmpty(values.stayingPeriod) &&
      Object.values(values?.tags).filter((tag) => tag && !isEmpty(tag)).length === packages.length
    );
  };
  const checkForTagsError = (tags) => {
    if (tags?.length > 0 && !allTags.includes(...tags) && !tagsLoading) {
      return <span className={styles.errorInline}>Tags not available</span>;
    }
    return null;
  };
  const checkIfTagsNotAvailable = () => {
    const selectedTagsObj = { ...selectedTags };
    const allTagsArr = Object.values(selectedTagsObj).map((obj) => !isEmpty(obj) && obj?.tags);
    return _.difference(_.union(...allTagsArr), allTags).length > 0;
  };
  return (
    <Container>
      <div className={styles.settingPageContainer}>
        <Row className='justify-content-between'>
          <span className={styles.settingPageHeaderText}> Select Stage</span>
        </Row>
        <div className={styles.settingPageHeaderSubText}>
          Select the stage in Lever that will initiate background checks automatically for
          candidates
        </div>
      </div>
      <Row className={styles.dropdownContainer}>
        <DropdownList
          style={{ width: '300px' }}
          data={stages}
          dataKey='id'
          textField='text'
          value={values.stayingPeriod} //TODO: need to update to coming value from get API
          name='stayingPeriod'
          selectIcon={<Image name='dropdownArrow.svg' />}
          onChange={(value) => {
            setFieldValue('stayingPeriod', value);
          }}
        />

        <div onClick={() => onClickStagesHandler()} className={styles.refreshContainer}>
          <Image className={styles.refreshImageContainer} name='reset.png' />
          <span className={styles.refreshText}>Refresh</span>
        </div>
      </Row>

      <div className={styles.secondPageSubSectionContainer}>
        <Row>
          <span className={styles.settingPageSubSectionHeader}> Setup Package Association</span>
        </Row>
        <div className={styles.settingPageSubSectionList}>
          Match the relevant Tags in Lever to the packages on SpringVerify
        </div>
      </div>

      <div className={styles.packageSelectorHeaderContainer} xs={2} md={4} lg={6}>
        <Col md={3} className={styles.packageNameHeader}>
          <div className={styles.packageTextStyle}>PACKAGE NAME</div>
        </Col>
        <Col className={styles.refreshPackageContainer}>
          <div className={styles.packageTextStyle}>SELECT TAG</div>
          <div className={styles.refreshPackageButton} onClick={() => onClickTagsHandler()}>
            <Image className={styles.refreshImageContainer} name='reset.png' />
            <span className={styles.refreshText}>Refresh</span>
          </div>
        </Col>
      </div>
      {packages.map((packageRow) => (
        <Row xs={2} md={4} lg={6} className='my-2'>
          <Col md={3} className={styles.packageName}>
            <div className={styles.text}>
              <span className={styles.packageNameText}>{packageRow.package_name}</span>
              <span className={styles.packageSubtypeNameText}>{packageRow.subtype_name}</span>
            </div>
          </Col>
          <Col>
            <Select
              className={styles.tagsDropDown}
              isMulti
              placeholder='No tags'
              name='tags'
              components={{ tagsSeparator }}
              options={dropdownTags}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              styles={tagDropdownStyles}
              isClearable={false}
              noOptionsMessage={() => (
                <span className={styles.dropDownNoOptionMessage}>
                  No tags available. Please create relevant tags on Lever and refresh.
                </span>
              )}
              value={values?.tags[packageRow.subtype_id]?.tags}
              onChange={(e) => {
                setTagsValue(packageRow.package_id, packageRow.subtype_id, e);
              }}
            />
            {checkForTagsError(values?.tags[packageRow.subtype_id]?.tags)}
          </Col>
        </Row>
      ))}

      <div className={styles.footerButtonContainer}>
        <Button className={styles.goBackBtn} onClick={() => props.history.push('/integrations')}>
          Go back
        </Button>
        <div>
          <Button
            className={styles.cancelBtn}
            onClick={handleDelete}
            disabled={!leverConfig || isEmpty(leverConfig) || loading || isSubmitting}
          >
            Delete Connection
          </Button>
          <Button
            className={styles.activeBtn}
            onClick={handleSubmit}
            disabled={!isFormValid() || loading || isSubmitting || checkIfTagsNotAvailable()}
          >
            Save
          </Button>
        </div>
      </div>
    </Container>
  );
}
