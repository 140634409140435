// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yC9LgWbtGqvW3xgtjQW6 {
  background: #1f1630;
  padding-bottom: 20px;
  min-width: 1200px;
  font-family: Poppins, sans-serif;
}

.kDwlBWWW7FgEUbZAntXV {
  min-height: 25vh;
}

.PhKqeXgwM3FxkSyoD7Rq {
  min-height: 47vh;
}

.EEerTVunQq8GnMq_bySG {
  min-height: 40vh;
}`, "",{"version":3,"sources":["webpack://./src/core-components/Layout/MainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,oBAAA;EACA,iBAAA;EACA,gCAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,gBAAA;AAEF","sourcesContent":[".footerDiv {\n  background: #1f1630;\n  padding-bottom: 20px;\n  min-width: 1200px;\n  font-family: Poppins, sans-serif;\n}\n\n.footerDivHeight25 {\n  min-height: 25vh;\n}\n\n.footerDivHeight47 {\n  min-height: 47vh;\n}\n.footerDivHeight80 {\n  min-height: 40vh;\n}\n\n.middleDiv {\n  // min-height: 68vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerDiv": `yC9LgWbtGqvW3xgtjQW6`,
	"footerDivHeight25": `kDwlBWWW7FgEUbZAntXV`,
	"footerDivHeight47": `PhKqeXgwM3FxkSyoD7Rq`,
	"footerDivHeight80": `EEerTVunQq8GnMq_bySG`
};
export default ___CSS_LOADER_EXPORT___;
