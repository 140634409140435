import * as Yup from 'yup';
import { getTrimmedValue, isValidPhoneNumber } from '../../../../../../../utils/utilities';
import { getNameValidationSchema } from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  stayingPeriod: Yup.string().required('Please select Period of stay.'),
  otherFullName: Yup.string().when('isCandidateAvailable', {
    is: 'no',
    then: getNameValidationSchema({
      message: 'Full name',
      isRequired: true
    })
  }),
  otherRelation: Yup.string().when(
    ['relation', 'isCandidateAvailable'],
    (relation, isCandidateAvailable, schema) => {
      if (isCandidateAvailable === 'no' && relation === 'Other') {
        return schema.required('Please enter relation.');
      }
      return schema;
    }
  ),

  relativeCountryCode: Yup.string().when('isCandidateAvailable', {
    is: 'no',
    then: Yup.string().required('Country code is required.')
  }),

  relativePhoneNumber: Yup.string().when('isCandidateAvailable', {
    is: 'no',
    then: Yup.string()
      .transform((currentVal) => getTrimmedValue(currentVal, false))
      .required('Please enter phone number.')
      .test('phone-number', 'Please enter a valid phone number.', function (value) {
        const { relativeCountryCode, relativePhoneNumber } = this.parent;
        const combinedPhoneNumber = `${relativeCountryCode}${relativePhoneNumber}`;
        return isValidPhoneNumber(combinedPhoneNumber);
      })
  }),
  relation: Yup.string().when('isCandidateAvailable', {
    is: 'no',
    then: Yup.string()
      .transform((currentVal) => getTrimmedValue(currentVal, false))
      .required('Please confirm your relationship with the person.')
  })
});
