import React from 'react';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Field, ErrorMessage } from 'formik';
import { DropdownList } from 'react-widgets';

import AdminModals from '../AdminModals';
import CategoryModals from '../../CategoryModals';
import Button from '../../../../core-components/Button';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField/ValidatedFormInputField';
import {
  adminEmailValidation as adminEmailValidationAPI,
  getAllowedDomains
} from '../../../../api/company';
import { getTrimmedValue, isTrimmedPhoneValid } from '../../../../utils/utilities';
import countryNames from '../../../../utils/countryNamesEn.json';
import countryOptions from '../../../../utils/countryCodesEn.json';
import styles from './AdminCard.module.scss';
import { isEmpty } from 'lodash';
import Image from '../../../../core-components/Image';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import CustomValidatedSelect from '../../../../core-components/CustomValidatedSelect';
import ChangePasswordModal from './ChangePasswordModal';
import secureLocalStorage from 'react-secure-storage';
import AppHelper from '../../../../core-components/AppHelper';
import { allowOnlyNumbersRegex } from '../../../../utils/ValidationSchema';
export default class extends React.Component {
  state = {
    modalShow: false,
    accessLevels: ['Admin', 'HR'],
    categories: [],
    whitelistedDomains: [],
    modalTitle: null,
    modalSubTitle: null,
    modalShowStatus: false,
    modalButton: null,
    modalPlaceholder: null,
    selectValue: [],
    whiteListedError: '',
    showChangePasswordModal: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCategories();
    this.fetchAllowedDomains();
    this.props.getSecuritySettings(this.props.companyId);
  }

  fetchAllowedDomains = () => {
    let whitelistedDomains = [];
    getAllowedDomains().then((res) => {
      res?.data?.data?.map((element) => {
        whitelistedDomains.push(element.domain);
      });
    });
    this.setState({
      whitelistedDomains: whitelistedDomains
    });
  };

  fetchCategories = () => {
    let categoriesList = [];

    return this.props.getCategories().then(() => {
      this.props.categories &&
        this.props.categories.userCategories &&
        Array.isArray(this.props.categories.userCategories) &&
        this.props?.categories?.userCategories?.map((category) => {
          categoriesList.push({ label: category.categoryName, value: category.id });
        });
      this.setState({
        categories: categoriesList
      });
    });
  };

  modalToggle = (title, subTitle, buttonText, placeholder) => {
    this.setState({
      modalShow: true,
      modalTitle: title,
      modalSubTitle: subTitle,
      modalButton: buttonText,
      modalPlaceholder: placeholder
    });
  };

  modalHide = (value) => {
    if (value === 'reload category') {
      this.setState({
        modalShowStatus: true,
        modalShow: false
      });
      this.fetchCategories();
    } else {
      this.setState({
        modalShow: false
      });
    }
  };

  modalHideStatus = () => {
    this.setState({
      modalShowStatus: false
    });
  };

  handleHideChangePasswordModal = () => {
    this.setState({
      showChangePasswordModal: false
    });
  };

  getAccessValue = () => {
    return typeof this.props.values.access !== 'string'
      ? ''
      : this.props.values.access === 'hr'
      ? this.props.values.access.toUpperCase()
      : this.props.values.access === 'admin'
      ? `${this.props.values.access.charAt(0).toUpperCase()}${this.props.values.access.slice(1)}`
      : '';
  };

  render() {
    const {
      handleSubmit,
      dirty,
      isSubmitting,
      errors,
      touched,
      setFieldValue,
      values,
      editAdminData,
      profile
    } = this.props;

    const title = this.props?.title;
    const existingEmailValidation =
      title === 'Edit User' &&
      editAdminData?.userEmailRejectType !== 'NA' &&
      editAdminData?.userEmailRejectType !== '' &&
      this.props?.editAdminData?.id !== this.props?.profile?.id &&
      values?.email === editAdminData?.email
        ? true
        : false;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#666666',
        borderRadius: '4px',
        padding: '10px 20px',
        border: 'none',
        background: state.isFocused ? '#ffffff' : ''
      }),
      control: (provided) => ({
        ...provided,
        border: !!errors.category && touched.category ? '1px solid #dc3545' : '1px solid #ebedf2',
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: '100%',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        display: 'flex'
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#333333'
        };
      },
      valueContainer: (base) => ({
        ...base
      }),
      container: (base) => ({
        ...base,
        width: '100%'
      })
    };

    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={{ display: 'none' }} {...innerProps} />;
    };

    const setValue = (fieldname, value, withSpace) => {
      setFieldValue(fieldname, getTrimmedValue(value, withSpace));
    };

    const handleAddNewAdmin = () => {
      if (title === 'Add New User') {
        const categoriesCopy = values.category
          ? values.category.map((category) => category.value)
          : [];
        const formData = {
          email: values.email,
          name: values.fullName,
          mobile: values.mobile ? `${values.countryCode}${values.mobile}` : '',
          user_type: values.access,
          user_category_ids: categoriesCopy
        };
        secureLocalStorage.setItem('NEW_USER', formData);
        const env = AppHelper.getEnv();
        const companyId = profile?.company_user_mapping?.company_id || '';
        const url =
          env === 'local'
            ? `http://localhost:3003/v1/ca/email-preferences?companyId=${companyId}`
            : `/v1/email-preferences?companyId=${companyId}`;
        window.location.href = url;
      }
    };

    const query = new URLSearchParams(window?.location?.search);
    const isNewUserEdit = query.get('isNewUserEdit');

    return (
      <div>
        <div className={styles.pageHeader}>
          <h4>{title}</h4>
        </div>
        <Card className={styles.adminCard}>
          <Form className={styles.adminForm} onSubmit={handleSubmit}>
            <label htmlFor='fullName'>
              Full Name <span style={{ color: 'red' }}>*</span>
            </label>
            <Field
              required={true}
              component={ValidatedFormInputField}
              type='text'
              data-testid='name-field'
              name='fullName'
              placeholder='Name'
              errors={title === 'Edit User' && errors.fullName}
              onBlur={(e) => {
                this.props.handleBlur(e);
                setValue('fullName', values?.fullName, true);
              }}
            />

            <div style={{ position: 'relative' }}>
              <label htmlFor='email'>
                Email address <span style={{ color: 'red' }}>*</span>
              </label>
              <Field
                component={ValidatedFormInputField}
                type='text'
                name='email'
                data-testid='email-field'
                placeholder='example@mail.com'
                required={true}
                disabled={
                  title === 'Edit User' &&
                  (editAdminData?.userEmailRejectType === 'NA' ||
                    editAdminData?.userEmailRejectType === '' ||
                    this.props?.editAdminData?.id === this.props?.profile?.id)
                    ? true
                    : false
                }
                onBlur={(e) => {
                  this.props.handleBlur(e);
                  setValue('email', values?.email, false);
                  if (values.email && !errors.email && !existingEmailValidation) {
                    adminEmailValidationAPI(editAdminData?.id, values?.email)
                      .then((response) => {
                        if (response.data.emailExists) {
                          setFieldValue('emailExists', true);
                        } else {
                          setFieldValue('emailExists', false);
                        }
                      })
                      .catch((error) => {
                        setFieldValue('emailExists', false);
                      });
                  }
                  if (values.email && !errors.email && this.state.whitelistedDomains.length) {
                    const getDomain = '@' + values.email.split('@')[1];
                    if (!this.state.whitelistedDomains.includes(getDomain) && getDomain) {
                      this.setState({ whiteListedError: 'Enter the email with correct domain.' });
                    } else {
                      this.setState({ whiteListedError: '' });
                    }
                  }
                }}
              />
              <div className={styles.emailBouncedIcon}>
                {title === 'Edit User' &&
                editAdminData?.userEmailRejectType !== 'NA' &&
                editAdminData?.userEmailRejectType !== '' ? (
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip id={'EmailBounced'}>
                        <p>{'Email has bounced'}</p>
                      </Tooltip>
                    }
                  >
                    <span className={styles.mailIcon}>
                      <Image name='emailBouncedIcon.svg' />
                    </span>
                  </OverlayTrigger>
                ) : (
                  ''
                )}
              </div>
              {!errors.email && (
                <div className={styles.validationErrorMsg}>
                  {this.state.whiteListedError
                    ? this.state.whiteListedError
                    : existingEmailValidation
                    ? 'Please provide a new email'
                    : values?.emailExists
                    ? 'Email already exists'
                    : ''}
                </div>
              )}
            </div>

            <Form.Group className={styles.mb32}>
              <Form.Label>Mobile</Form.Label>
              <Form.Row>
                {!isMobileOnly ? (
                  <Field
                    type='text'
                    name='countryCode'
                    defaultValue={values.countryCode}
                    options={countryNames}
                    className='countryCodeDropDown mb-0'
                    filterLabel={countryOptions}
                    component={CustomValidatedSelect}
                    onChange={(e) => {
                      if (values.mobile && e) {
                        isTrimmedPhoneValid(e, values.mobile, setFieldValue, 'isValidPhone');
                      }
                    }}
                    onBlur={(e) => {
                      this.props?.handleBlur(e);
                      setValue('mobile', values?.mobile, false);
                    }}
                  />
                ) : (
                  <Form.Group
                    as={Col}
                    className={styles.mobCountryCodeDropDown + ' mb-0'}
                    controlId='formGridPhoneNumber'
                  >
                    <div
                      className={styles.mobileCountryCodes}
                      onClick={() =>
                        this.modalToggle('countryCode', 'mobCountryCodeLabel', values.countryCode)
                      }
                    >
                      {this.state.mobCountryCodeLabel}
                      <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                    </div>
                    {this.state.modalShow && (
                      <CountryCodeModal
                        subShow={this.state.modalShow}
                        SubOnHide={this.modalHide}
                        title={this.state.modalTitle}
                        subTitle={this.state.modalSubTitle}
                        modalLabel={this.state.modalLabel}
                        defaultValue={this.state.defaultValue}
                        handleCodeChange={this.setCountryCodeValue}
                        {...this.props}
                      />
                    )}
                  </Form.Group>
                )}

                <Form.Group as={Col} controlId='formGridPhoneNumber' className='mb-0'>
                  <Field
                    type='text'
                    name='mobile'
                    data-testid='mobile-field'
                    className={
                      'mb-0 ' +
                      (values.phoneNumberExists || !values.isValidPhone ? styles.emailError : null)
                    }
                    component={ValidatedFormInputField}
                    fieldWithoutSpace={false}
                    regex={allowOnlyNumbersRegex}
                    onBlur={(e) => {
                      this?.props?.handleBlur(e);

                      setValue('mobile', values?.mobile, false);

                      isTrimmedPhoneValid(
                        values.countryCode,
                        values.mobile,
                        setFieldValue,
                        'isValidPhone'
                      );
                    }}
                    isHyphenNotRequired
                  />
                  {!values.isValidPhone && (
                    <div className={styles.emailErrorMsg}>
                      {!values.isValidPhone ? 'Please provide a valid mobile number.' : ''}
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
            </Form.Group>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Access</Form.Label>
              <DropdownList
                data={this.state.accessLevels}
                value={this.getAccessValue()}
                className={`${styles.accessDropdown} ${
                  !(
                    editAdminData?.isAdminDeletionAllowed === false &&
                    this.getAccessValue() === 'HR'
                  )
                    ? styles.mb32
                    : ''
                }`}
                containerClassName={'adminCardAccess'}
                emptyList={''}
                data-testid='access-dropdown-field'
                onChange={(value) => {
                  setFieldValue('access', value.toLowerCase());
                }}
                disabled={
                  title === 'Add New User'
                    ? false
                    : this.props.profile.email === values.email
                    ? true
                    : false
                }
              />
              {editAdminData?.isAdminDeletionAllowed === false &&
                this.getAccessValue() === 'HR' && (
                  <div className={styles.errorMessage}>
                    Access level cannot be changed to HR as Important notification are enabled for
                    ADMIN
                  </div>
                )}
            </Form.Group>
            <AdminModals
              show={this.state.modalShowStatus}
              onHide={this.modalHideStatus}
              content={'Category created successfully'}
            />
            <CategoryModals
              {...this.props}
              show={this.state.modalShow}
              onHide={this.modalHide}
              title={this.state.modalTitle}
              subTitle={this.state.modalSubTitle}
              button={this.state.modalButton}
              placeholder={this.state.modalPlaceholder}
            />
            <Form.Group className={styles.mb32}>
              <Form.Label>Category</Form.Label>
              <div style={{ display: 'flex' }}>
                <Select
                  isMulti
                  placeholder='No Category'
                  name='category'
                  components={{ IndicatorSeparator }}
                  options={this.state.categories}
                  noOptionsMessage={() => 'No Category found'}
                  styles={customStyles}
                  className={'mb-0'}
                  isClearable={false}
                  value={this.props.values.category}
                  onChange={(value) => {
                    if (value === null) setFieldValue('category', []);
                    else setFieldValue('category', value);
                  }}
                />
                <div style={{ minWidth: 'fit-content' }}>
                  <Button
                    onClick={() =>
                      this.modalToggle('Create Category', 'Name', 'Create', 'Category Name')
                    }
                    className={styles.addBtn}
                  >
                    Add new category
                  </Button>
                </div>
              </div>
              {!!errors.category && touched.category && (
                <span style={{ color: '#dc3545', fontSize: '12px' }}>
                  <ErrorMessage name='category' />
                </span>
              )}
            </Form.Group>
            {this.props?.editAdminData?.id === this.props?.profile?.id && (
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <div className='w-100 d-flex align-items-center'>
                  <button
                    disabled={!this.props?.securitySettings?.loginMethodPassword}
                    className={styles.ChangePasswordBtn}
                    type='button'
                    onClick={() => {
                      this.setState({ showChangePasswordModal: true });
                    }}
                  >
                    Change password
                  </button>
                  {!this.props?.securitySettings?.loginMethodPassword &&
                    !this.props?.securitySettings?.loading && (
                      <OverlayTrigger
                        placement='right'
                        overlay={
                          <Tooltip className='tooltip-fw'>
                            <div className={styles.tooltipContent}>
                              Change password is disabled as 'Password Login' is disabled for your
                              company.
                            </div>
                          </Tooltip>
                        }
                      >
                        <Image className={styles.changePasswordInfoIcon} name='info.svg' />
                      </OverlayTrigger>
                    )}
                </div>
              </Form.Group>
            )}

            <div className={styles.adminCardFooter}>
              <Button
                data-testid='cancel-btn'
                className={styles.cancelButton}
                onClick={() => this.props?.showAdminCardHandler(false)}
              >
                {'Cancel'}
              </Button>
              <Button
                data-testid='submit-btn'
                className={styles.adminFormButton}
                type={title === 'Add New User' ? 'button' : 'submit'}
                onClick={handleAddNewAdmin}
                disabled={
                  (!dirty && !isNewUserEdit) ||
                  isSubmitting ||
                  !values.isValidPhone ||
                  !isEmpty(errors) ||
                  existingEmailValidation ||
                  values.emailExists ||
                  this.state.whiteListedError ||
                  (editAdminData?.isAdminDeletionAllowed === false &&
                    this.getAccessValue() === 'HR')
                }
              >
                {title === 'Add New User' ? 'Next' : 'Save'}
              </Button>
            </div>
          </Form>
        </Card>
        {this.state.showChangePasswordModal && (
          <ChangePasswordModal
            show={this.state.showChangePasswordModal}
            onHide={this.handleHideChangePasswordModal}
          />
        )}
      </div>
    );
  }
}
