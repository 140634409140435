import { connect } from 'react-redux';
import { getCompanyPaymentDetails } from '../../../actions/company';

const mapStateToProps = (state) => {
  return { paymentDetails: state.paymentDetails, profile: state.profile.profile };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentDetails: (data) => dispatch(getCompanyPaymentDetails())
  };
};
export default connect(mapStateToProps, mapDispatchToProps);
