import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../../../utils/utilities';
import { mergeWith } from 'lodash';
import {
  getEmailPreferences as getEmailPreferencesAPI,
  invalidateEmailPreferences as invalidateEmailPreferencesAPI
} from '../../../../actions/company';
import { postEmailPreferences as postEmailPreferencesAPI } from '../../../../api/company';

const mapStateToProps = (state) => {
  return {
    emailPreferences: state.emailPreferences,
    profile: state.profile.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateEmailPreferences: () => dispatch(invalidateEmailPreferencesAPI()),
    getEmailPreferences: (adminId, companyId) =>
      dispatch(getEmailPreferencesAPI(adminId, companyId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};
      if (props.emailPreferences?.data) {
        propsValue = {
          adminId: props.emailPreferences.data.adminId,
          companyId: props.emailPreferences.data.companyId,
          bounceEmail: props.emailPreferences.data.bounceEmail,
          digest: props.emailPreferences.data.digest ? true : false,
          digestValues: props.emailPreferences.data.digest
        };
      }
      let initialValues = {
        adminId: new URLSearchParams(document.location.search).get('id') || null,
        companyId: new URLSearchParams(document.location.search).get('companyId') || null,
        bounceEmail: true,
        digest: true,
        digestValues: {
          frequency: 'WEEKLY',
          information: 'CANDIDATE'
        }
      };

      return mergeWith({}, initialValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, dirty, props }) => {
      let finalValues = {
        adminId: values.adminId,
        companyId: values.companyId,
        bounceEmail: values.bounceEmail,
        digest: values.digest
          ? {
              frequency: values.digestValues.frequency,
              information: values.digestValues.information
            }
          : null
      };
      postEmailPreferencesAPI(finalValues)
        .then(() => {
          setSubmitting(false);
          toast.success('Email Preferences Saved');
          props.history.push('/myTeam');
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
    },
    displayName: 'Email Preferences'
  })
);
