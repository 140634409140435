import React, { useState } from 'react';

import { Spinner, Form, Col } from 'react-bootstrap';

import Pagination from '../../../../../../core-components/Pagination';
import Image from '../../../../../../core-components/Image';
import CreditDetail from './CreditDetail/index';
import DebitDetail from './DebitDetail/index';

import styles from './CreditTransactionHistory.module.scss';

function CreditTransactionHistory(props) {
  const {
    data,
    loading,
    limit,
    handleLimit,
    handleOffset,
    pageSizeOptions,
    count,
    paginate,
    offset
  } = props;

  const renderDetail = (transaction = null) => {
    if (transaction) {
      if (transaction?.type === 'CREDIT') {
        return <CreditDetail transaction={transaction} />;
      } else if (transaction?.type === 'DEBIT') {
        return <DebitDetail transaction={transaction} />;
      } else {
        return null;
      }
    }
    return null;
  };

  return (
    <div className={styles.transactionHistoryContainer} data-testid="transaction-detail-0">
      <h5>{'Transaction history'}</h5>

      {loading ? (
        <div className={styles.transactionHistoryLoading} data-testid="loading-spinner">
          <Spinner animation='border' variant='primary' />
        </div>
      ) : data && data.length ? (
        <div className={styles.transactionHistoryTable}>
          {data.map((transaction, index) => {
            return (
              <div className={styles.transactionDetail} key={index}>
                {renderDetail(transaction)}
              </div>
            );
          })}

          <Form.Row className={styles.paginationWrapper}>
            <Col className={styles.showPagination} sm={4} md={4} lg={4}>
              Show{' '}
              <select
                className={styles.selectPagination}
                value={limit}
                onChange={(event) => {
                  handleOffset(0);
                  handleLimit(parseInt(event.target.value));
                  paginate(parseInt(event.target.value), 0);
                }}
                data-testid="limit-select"
              >
                {pageSizeOptions}
              </select>{' '}
              items of {count}
            </Col>
            <Col sm={8} md={8} lg={8}>
              <Pagination
                forcePage={offset}
                onPageChange={({ selected }) => {
                  handleOffset(selected);
                  paginate(limit, selected);
                }}
                pageCount={count / limit}
              />
            </Col>
          </Form.Row>
        </div>
      ) : (
        <div className={styles.noTransactionFound}>
          <div style={{ textAlign: 'center' }}>
            <Image name={'NoTransactionFound.svg'} svg={'NoTransactionFound.svg'} />
            <p>{'No transaction history'}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreditTransactionHistory;
