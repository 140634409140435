import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../core-components/Loader/Loader';
import styles from '../Login.module.scss';
import { useMsal } from '@azure/msal-react';
import { PublicClientApplication, Cru, InteractionType } from '@azure/msal-browser';
import { errToastMessage, redirectToPreviousPathAfterLogin } from '../../../utils/utilities';

const MsRedirection = (props) => {
  const { instance } = useMsal();

  const { location, googleMicrosoftLoginAPI, history } = props;
  const hash = location.hash;
  const params = new URLSearchParams(hash.substr(1));
  const code = params.get('code');
  const error = params.get('error');

  useEffect(() => {
    const handleLogin = (code) => {
      const config = instance.getConfiguration();
      const storageKey = 'msal.' + config?.auth?.clientId + '.request.params';
      const requestData = window.sessionStorage.getItem(storageKey);
      let session = '';
      try {
        const decodedString = JSON.parse(window.atob(requestData));
        session = window.btoa(decodedString?.codeVerifier);
      } catch (err) {
        console.error('err: ', err);
        history.push('/signIn');
        console.error('err: ', err);
        errToastMessage('Something went wrong.');
      }
      googleMicrosoftLoginAPI('auth/microsoft-login', { code, session })
        .then(() => {
          sessionStorage.removeItem(storageKey);
          redirectToPreviousPathAfterLogin(props?.history);
        })
        .catch((err) => {
          history.push('/signIn');
          if (err?.data?.message) errToastMessage(err.data.message);
        });
    };

    if (code) {
      handleLogin(code);
    }
  }, [code]);

  useEffect(() => {
    if (error) {
      if (error !== 'access_denied') errToastMessage(error);
      history.push('/signIn');
    }
  }, [error]);

  return (
    <>
      <div className={styles.overlayLoader}>
        <Loader></Loader>
      </div>
    </>
  );
};

export default withRouter(MsRedirection);
