import React, { useState, useEffect } from 'react';

import { Card, Button, Container } from 'react-bootstrap';
import CustomSpinner from '../CustomSpinner';
import styles from './PaymentResults.module.scss';
import Image from '../Image';
import { Link } from 'react-router-dom';

export default function PaymentResults(props) {
  const [timeLeft, setTimeLeft] = useState(5);

  let myInterval;
  useEffect(() => {
    if (props?.isAdcFlow && props?.paymentStatus === 'completed') {
      if (timeLeft === 0) {
        props?.hideModal();
        return;
      }
      myInterval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [timeLeft, props?.paymentStatus]);

  const checkForRedirectToApproval = () => {
    if (props?.isRedirectTOApprovalList === true) {
      window.open('/approval', '_self');
    }
    if ((props.setCurrentStep, props.currentStep)) props.setCurrentStep(props.currentStep - 1);
    props.setPaymentStatus && props.setPaymentStatus(false);
  };
  return (
    <Card
      className={`text-center ${styles.card} ${props.isBulkUpload ? styles.bulkUploadCard : ''}`}
    >
      {props.showCross && props?.paymentStatus === 'failed' ? (
        <div className={styles.crossBtn} onClick={props.hideModal}>
          X
        </div>
      ) : null}
      <Card.Body className={`${styles.body}`}>
        {props.paymentStatus === 'completed' ? (
          <>
            <Image name={'PaymentSuccess.svg'} />
            <Card.Title className={`text-success ${styles.payment_title}`}>
              Thank you for your purchase
            </Card.Title>
            {props?.isAdcFlow ? (
              <Card.Body>
                Please don't refresh.{' '}
                <p>
                  Redirecting to{' '}
                  <Link onClick={() => props?.hideModal()}>Additional Cost Review</Link> in{' '}
                  {timeLeft}s
                </p>
              </Card.Body>
            ) : null}
          </>
        ) : props.paymentStatus === 'pending' ? (
          <>
            <CustomSpinner
              className='bgvLoader'
              as='div'
              animation='border'
              size='md'
              role='status'
              aria-hidden='true'
            />
            <Card.Title className={styles.payment_title}>Processing Payment</Card.Title>
          </>
        ) : (
          <>
            <Image name={'PaymentFail.svg'} />
            <Card.Title className={`text-danger ${styles.payment_title}`}>
              Payment failed
            </Card.Title>
          </>
        )}
        <Card.Text className={styles.status_description}>{props.description}</Card.Text>
        {props.orderId && (
          <Card.Text className='text-muted'>Transaction#:{props.orderId}</Card.Text>
        )}
        <Container className={styles.btnContainer}>
          {props.paymentStatus === 'completed' ? props.buttons : ''}
          {props.paymentStatus === 'failed' && (
            <Button variant='primary' onClick={() => checkForRedirectToApproval()}>
              {'Retry'}
            </Button>
          )}
        </Container>
        <Card.Text className={styles.help}>
          <small className={`text-muted`}>
            Need help ?{' '}
            <Link onClick={() => window.open('mailto:cs@springverify.com', '_blank')}>
              Contact us
            </Link>
          </small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
