import * as Yup from 'yup';
import { getTrimmedValue } from './Utilities';
import validator from 'validator';

const NULLABLE = Yup.string().nullable();
const REQUIRED = (message) => {
  if (!message) return Yup.string().required('This is a required field.');

  return Yup.string().required(`Please enter a ${message}`);
};

/**
 * * Regular expressions
 */

const ALPHANUMERIC_STRICT = /^[a-zA-Z0-9]/;
const ALPHABATIC_STRING =
  /(?!\.)(?!\-)(?!.*\.$)(?!.*\-$)(?!\s)(?!.*\.\.)(?!.*\-\-)(?!.*\-\.)(?!.*\.\-)(?!.*\-\s)^(([a-zA-Z]-?)*[a-zA-Z\.\s]){2,}$/;
const ALPHANUMERIC_DOT_COMMA_HYPHEN_PATTERN = /^[a-zA-Z0-9\s.,\-]*$/;
const ALPHANUMERIC_UNDERSCORE_SPACE_HYPHEN_PERIOD_REGEX = /^[a-zA-Z0-9_ \-.]+$/;

/**
 * Helper methods
 */
const STRING_CHECK = (message) => {
  return Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(ALPHABATIC_STRING, `Please enter a valid ${message}`);
};

const concatenateSchema = ({
  schema,
  isNullable,
  isRequired,
  isRequiredMessage,
  isPlusSignAllowed = false
}) => {
  if (isRequired)
    return schema.concat(isRequiredMessage ? REQUIRED(isRequiredMessage) : REQUIRED());

  if (isNullable) return schema.concat(NULLABLE);

  return schema;
};

/**
 * @param {Custom message to show at the end of warning message} message
 * @param {Set to true if field is required} isRequired
 * @param {Set to true if field is nullable} isNullable
 */
export const getNameValidationSchema = ({ message, isRequired, isRequiredMessage, isNullable }) => {
  let schema = STRING_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getEmailValidationSchema = ({
  isRequired,
  isNullable,
  msg = 'Please enter a valid email address',
  isPlusSignAllowed = true
}) => {
  let schema = Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .test({
      message: msg,
      test: (value) => {
        if (value) {
          const isEmailValid = validator.isEmail(value);
          const isFirstCharAlphaNumeric = value[0].match(ALPHANUMERIC_STRICT) !== null;
          const isLastCharOfUsernameAlphaNumeric = isEmailValid
            ? value[value.search(/@/) - 1].match(ALPHANUMERIC_STRICT) !== null
            : false;
          let doesNotContainPlusInUsername = true;
          if (!isPlusSignAllowed)
            doesNotContainPlusInUsername = /^[^+]*$/.test(value.split('@')[0]);
          if (isPlusSignAllowed)
            return (
              isEmailValid &&
              doesNotContainPlusInUsername &&
              isFirstCharAlphaNumeric &&
              isLastCharOfUsernameAlphaNumeric
            );
          return (
            isEmailValid &&
            doesNotContainPlusInUsername &&
            isFirstCharAlphaNumeric &&
            isLastCharOfUsernameAlphaNumeric
          );
        }
        return true;
      }
    });

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isPlusSignAllowed: isPlusSignAllowed
  });

  return schema;
};

/**
 *
 * @param {Booolean} isRequired
 * @param {Boolean} isNullable
 * @returns
 */
export const getCtcValidationSchema = ({ isRequired = false, isNullable = false }) => {
  let schema = Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(ALPHANUMERIC_DOT_COMMA_HYPHEN_PATTERN, `Please enter a valid CTC value.`);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired
  });

  return schema;
};

/**
 *
 * @param {Booolean} isRequired
 * @param {Boolean} isNullable
 * @returns
 */
export const getExitReasonSchema = ({ isRequired = false, isNullable = false }) => {
  let schema = Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(ALPHANUMERIC_DOT_COMMA_HYPHEN_PATTERN, `Please enter a valid exit reason value.`);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired
  });

  return schema;
};

/**
 *
 * @param {Booolean} isRequired
 * @param {Boolean} isNullable
 * @returns
 */
export const getDesignationSchema = ({ isRequired = false, isNullable = false }) => {
  let schema = Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(ALPHANUMERIC_UNDERSCORE_SPACE_HYPHEN_PERIOD_REGEX, `Please enter a valid exit designation value.`);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired
  });

  return schema;
};
