import { convertColumnsToRows } from './utils';
const noSpaceKeys = [
  'date_of_birth',
  'date_of_joining',
  'last_working_day',
  'phone_number',
  'rm_phone',
  'rm_email',
  'personal_email',
  'business_email'
];

export default function makeData(data = {}, count = -1, flow) {
  if (data) {
    const { resultArray, errorArray } = convertColumnsToRows(data, count, flow);
    const columnData = Object.keys(data)
      .map((key) => {
        if (!data[key].selectedHeader || data[key].selectedHeader.value === -1) return null;
        else
          return {
            id: key,
            label: data[key].name,
            subLabel: data[key].selectedHeader.label,
            accessor: key,
            dataType: [
              'date_of_birth',
              'date_of_joining',
              'last_working_day',
              'phone_number',
              'rm_phone'
            ].includes(key)
              ? 'number'
              : 'text',
            options: noSpaceKeys.includes(key) ? ['nospace'] : [],
            // width: ["string", "email", "phone"].includes(data[key].type)
            //   ? 130
            //   : 100,
            required: data[key].required
          };
      })
      .filter((item) => item !== null);
    return {
      columns: columnData,
      data: resultArray,
      errorArray,
      skipReset: false,
      flow,
      showOnlyError: false
    };
  }
}
