import React from 'react';

import styles from '../Integrations.module.scss';
import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';
import InputArea from './InputArea';

export default function ZohoRecruit(props) {
  return (
    <IntegrationLayout>
      <div className={styles.parentDivTopTextArea}>
        <TopTextArea title={'Zoho Recruit'} icon={'zoho_recruit.png'} />
        <InputArea history={props.history} />
      </div>
    </IntegrationLayout>
  );
}
