// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w6Eo_vrlpCk7oet6bKR8 {
  line-height: 24px;
}
.w6Eo_vrlpCk7oet6bKR8 .YYNYtmZWWXtZGQEbMkh9 .PjDIYDggAumcLD4nBSLw {
  text-align: center;
}
.w6Eo_vrlpCk7oet6bKR8 .YYNYtmZWWXtZGQEbMkh9 .A8t3FSRnzIXvhFLi5iLV {
  padding-left: 0;
}
.w6Eo_vrlpCk7oet6bKR8 .xtUoRoSWcQsMK4f3T36e {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
.w6Eo_vrlpCk7oet6bKR8 .V_B9tRaYnnYyeDzP25Gc {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.w6Eo_vrlpCk7oet6bKR8 .DY5k7B0Y5gBf1Z3l6DhB button {
  border-radius: 2px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.w6Eo_vrlpCk7oet6bKR8 .DY5k7B0Y5gBf1Z3l6DhB .mJJFRKBaIQbmw1tRm0uD {
  background-color: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.w6Eo_vrlpCk7oet6bKR8 .DY5k7B0Y5gBf1Z3l6DhB .ITK5DcvE1T02oXN49eUn {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
.w6Eo_vrlpCk7oet6bKR8 .DY5k7B0Y5gBf1Z3l6DhB .w41k5yXkEqFbzwth538Q {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
}
.w6Eo_vrlpCk7oet6bKR8 .DY5k7B0Y5gBf1Z3l6DhB .igznWayg5mCLntu678SX {
  background: #ffffff;
  color: #2755fe;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/core-components/ConfirmationModal/Confirm.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACI;EACE,kBAAA;AACN;AACI;EACE,eAAA;AACN;AAEE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGI;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADN;AAGI;EACE,yBAAA;EACA,cAAA;EACA,eAAA;AADN;AAGI;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;AADN;AAGI;EACE,mBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;AADN;AAGI;EACE,mBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AADN","sourcesContent":[".confirmationModal {\n  line-height: 24px;\n  .confirModalBody {\n    .questionImage {\n      text-align: center;\n    }\n    .confirmTextContainer {\n      padding-left: 0;\n    }\n  }\n  .confirmModalHeading {\n    font-weight: 600;\n    font-size: 16px;\n    color: #262626;\n  }\n  .confirmText {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 22px;\n    color: #595959;\n  }\n  .confirmModalFooter {\n    button {\n      border-radius: 2px;\n      padding: 5px 16px;\n      font-weight: 500;\n      font-size: 16px;\n      line-height: 24px;\n    }\n    .activeConfirmBtn {\n      background-color: #2755fe;\n      color: #ffffff;\n      cursor: pointer;\n    }\n    .disabledConfirmBtn {\n      background: #f5f5f5;\n      color: #bfbfbf;\n      border: 1px solid #d9d9d9;\n      cursor: not-allowed;\n    }\n    .cancelConfirmBtn {\n      background: #ffffff;\n      color: #2755fe;\n      border: none;\n      box-shadow: none;\n    }\n    .disabledCancelConfirmBtn {\n      background: #ffffff;\n      color: #2755fe;\n      border: none;\n      box-shadow: none;\n      cursor: not-allowed;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": `w6Eo_vrlpCk7oet6bKR8`,
	"confirModalBody": `YYNYtmZWWXtZGQEbMkh9`,
	"questionImage": `PjDIYDggAumcLD4nBSLw`,
	"confirmTextContainer": `A8t3FSRnzIXvhFLi5iLV`,
	"confirmModalHeading": `xtUoRoSWcQsMK4f3T36e`,
	"confirmText": `V_B9tRaYnnYyeDzP25Gc`,
	"confirmModalFooter": `DY5k7B0Y5gBf1Z3l6DhB`,
	"activeConfirmBtn": `mJJFRKBaIQbmw1tRm0uD`,
	"disabledConfirmBtn": `ITK5DcvE1T02oXN49eUn`,
	"cancelConfirmBtn": `w41k5yXkEqFbzwth538Q`,
	"disabledCancelConfirmBtn": `igznWayg5mCLntu678SX`
};
export default ___CSS_LOADER_EXPORT___;
