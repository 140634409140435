import { connect } from 'react-redux';

import {
  getAdministrators,
  getCompanyPaymentDetails,
  INVALIDATE_ADMINISTRATORS
} from '../../../actions/company';
const mapStateToProps = (state) => {
  return {
    administrators: state.administrators,
    profile: state.profile.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdministrators: (limit, offset) => {
      return dispatch(getAdministrators(limit, offset));
    },
    invalidateAdministrators: () => dispatch({ type: INVALIDATE_ADMINISTRATORS }),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
