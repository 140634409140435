import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../../../../core-components/Icon';
import Image from '../../../../../core-components/Image';
import styles from '../ManageApiToken.module.scss';

const ICON_NAME = {
  copyIcon: 'CopyToClipboard',
  editIcon: 'EditLarge',
  deleteIcon: 'DeleteLarge'
};

export default (props) => {
  /* @tooltipText is needed only in case of copy icon */
  const { id, onClickHandler, dataIndex } = props;
  const [tooltipText, setTooltipText] = useState(props?.tooltipText);
  const isCopyIcon = id === 'copyIcon';

  return (
    <OverlayTrigger placement='bottom' overlay={<Tooltip id={id}>{tooltipText}</Tooltip>}>
      <div
        className={styles.icon}
        onClick={() => {
          /* In case of copy icon update tooltip text to Copied on click*/
          if (isCopyIcon) setTooltipText('Copied');
          onClickHandler(dataIndex);
        }}
      >
        {isCopyIcon ? (
          <Image data-testid="icon" style={{ width: '21px', height: '21px' }} name={'CopyToken.svg'} />
        ) : (
          <Icon icon={ICON_NAME[id]} color={'#4B5C6B'} width={22} height={22} />
        )}
      </div>
    </OverlayTrigger>
  );
};
