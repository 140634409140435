import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default ({
  component: Comp,
  path,
  pageTitle = 'SpringVerify',
  noMatchFound = false,
  ...rest
}) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return (
          <>
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            <Comp {...props} />
          </>
        );
      }}
    />
  );
};
