import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    walletDetail: state.walletDetail || {},
    selectedTiers:
      state.selectedTiers && !isEmpty(state.selectedTiers) ? state.selectedTiers : null,
    packages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) && !state.viewBuyPackages.loading ? state.viewBuyPackages.rows : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
