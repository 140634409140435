import React from 'react';
import { Modal } from 'react-bootstrap';
import DarkLogo from './sampleImage/dark.png';
import LightLogo from './sampleImage/light.png';
import { GrFormClose } from 'react-icons/gr';

const SampleModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className='modalContainer' style={styles.modalContainer}>
        <div className='close-btn' style={styles.closeBtn} onClick={onHide}>
          <GrFormClose />
        </div>
        <div className='title' style={styles.title}>
          Sample Images
        </div>
        <div className='subtitle' style={styles.subtitle}>
          Refer the format shown for the logos to be uploaded
        </div>
        <div className='logo-container' style={styles.logoContainer}>
          <div className='logo' style={styles.logo}>
            <div className='logo-image' style={styles.logoImage}>
              <img src={DarkLogo} alt='Logo 1' style={styles.logoImageImg} />
            </div>
            <div className='logo-title' style={styles.logoTitle}>
              Dark Logo
            </div>
          </div>
          <div className='logo' style={styles.logo}>
            <div className='logo-image' style={{ ...styles.logoImage, ...styles.logoImageLight }}>
              <img src={LightLogo} alt='Logo 2' style={styles.logoImageImg} />
            </div>
            <div className='logo-title' style={styles.logoTitle}>
              Light Logo
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    height: '342px'
  },
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '20px',
    cursor: 'pointer',
    fontSize: '25px'
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: '24px'
  },
  subtitle: {
    width: '45%',
    margin: '0 auto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'center',
    color: 'gray',
    marginBottom: '20px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px'
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoImage: {
    width: '150px',
    height: '120px',
    borderRadius: '8px',
    background: '#f2f2f2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoImageLight: {
    background: '#6a778b'
  },
  logoImageImg: {
    maxWidth: '100px',
    maxHeight: '100px'
  },
  logoTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#666666',
    marginTop: '8px'
  }
};

export default SampleModal;
