// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TfwgR57nV1fuSPUsmf3S {
  text-align: center;
  padding-top: 64px;
}
.TfwgR57nV1fuSPUsmf3S .DCt9yG2wask7iPcA_Hd4 {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}`, "",{"version":3,"sources":["webpack://./src/core-components/EmptyTableBody/EmptyTableBody.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;AACE;EACE,iBAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".noDataWrapper {\n  text-align: center;\n  padding-top: 64px;\n\n  .noDataContent {\n    padding-top: 16px;\n    padding-bottom: 94px;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: #666666;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noDataWrapper": `TfwgR57nV1fuSPUsmf3S`,
	"noDataContent": `DCt9yG2wask7iPcA_Hd4`
};
export default ___CSS_LOADER_EXPORT___;
