import { connect } from 'react-redux';
import { compose } from 'redux';
import { googleMicrosoftLogin } from '../../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    googleMicrosoftLoginAPI: (path, token) => dispatch(googleMicrosoftLogin(path, token))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
