import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import logger from '../../../../../../../core-components/Logger';
import {
  getErrorMessage,
  compareObjects,
  errToastMessage
} from '../../../../../../../utils/utilities';

import {
  idUpload as bgvIdUploadAPI,
  saveBgvCache as saveBgvCacheAPI,
  educationGradesheetUpload as educationGradesheetUploadAPI,
  setEmpDocType as setEmpDocTypeAPI,
  setGradesheetUploadedUpdatedWithPassword as updateGradeSheetWithPasswordAction,
  deleteGradesheetUpload as deleteGradesheetUploadAPI
} from '../../../../../../../actions/bgv';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    bgvIdUpload: (data) => dispatch(bgvIdUploadAPI(data)),
    educationGradesheetUpload: (data) => dispatch(educationGradesheetUploadAPI(data)),
    setEmpDocType: (docType) => dispatch(setEmpDocTypeAPI(docType)),
    deleteGradesheetUpload: (data) => dispatch(deleteGradesheetUploadAPI(data))
  };
};

const mapStateToProps = (state) => {
  return {
    cached_data: !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data },
    document: state.idDetails.data,
    empDocType: state.empDocType || ''
  };
};
const formattedSaveObject = (props, values) => {
  let finalCacheObj = {};
  let saveObject = {};
  if (props?.cached_data?.cache_data?.employment_check?.data[props.currentSavingIndex]) {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props.cached_data.cache_data,
        employment_check: {
          data: [...props.cached_data.cache_data.employment_check.data]
        }
      }
    };
    delete values.companyName;
    saveObject.cache_data.employment_check.data[props.currentSavingIndex].step_2 = {
      ...values,
      proof_doc: [...values.proof_doc]
    };
  }
  saveObject.current_state = '4#3';
  finalCacheObj['saveObject'] = {
    ...saveObject
  };
  return finalCacheObj;
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        doc_type: null,
        proof_doc: []
      };
      if (
        props?.cached_data &&
        !isEmpty(props.cached_data) &&
        props?.cached_data?.cache_data['employment_check'] &&
        props?.cached_data?.cache_data['employment_check']?.data &&
        props?.cached_data?.cache_data['employment_check']?.data?.length > 0 &&
        props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex] &&
        props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex].step_2
      ) {
        let empData =
          props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex];
        propsValue = { ...empData.step_2 };
      } else {
        propsValue = {};
      }
      if (
        props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex].step_1
      ) {
        const empData =
          props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex];
        propsValue = { ...propsValue, companyName: empData.step_1?.company_name };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    // validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      if (!compareObjects(values, propsValue)) {
        const finalCacheObj = formattedSaveObject(props, values);
        props
          .setBgvCache(finalCacheObj)
          .then(() => {
            logger.push({
              message: `Employment step2 is ${props.isEdit ? 'Edited' : 'Saved'}`,
              data: JSON.stringify(finalCacheObj),
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'Employment details#2',
              type: 'info'
            });
            setSubmitting(false);
            props.subNextStep();
          })
          .catch((error) => {
            console.error(error);
            setSubmitting(false);
            errToastMessage(error);
          });
      } else {
        setSubmitting(false);
        props.subNextStep();
      }
    },
    displayName: 'Contact Details'
  })
);
