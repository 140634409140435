import React from 'react';
import Image from '../../core-components/Image';
import styles from '../Integrations.module.scss';
import { integrationText, integrationUrl, knowledgeBase } from './constants';
function TopTextArea(props) {
  const { title, icon, isIconUrl } = props;

  return (
    <div className={`mb-2 ${styles.topAreaTextContainer}`}>
      <div className={styles.imageAndTitleContainer}>
        {isIconUrl ? (
          <img width={'60px'} height={'60px'} src={icon} alt='' />
        ) : (
          <Image width={'60px !important'} height={'60px'} name={icon} />
        )}

        <div className={styles.topAreaHeaderTextContainer}>
          <div className={styles.topHeaderContainer}>
            <div className={styles.topHeaderText}>{title}</div>
          </div>
          {/* TODO: need to ask for URL for other integrations */}
          <a
            className={styles.topHeaderSubtext}
            href={integrationUrl[title]}
            target='_blank'
            rel='noreferrer'
          >
            {integrationText[title]}
          </a>
        </div>
      </div>
      {knowledgeBase[title] && (
        <div className={styles.imageAndTitleContainer}>
          <a
            className={`mt-4 ${styles.topHeaderRedirectText}`}
            href={knowledgeBase[title]}
            target='_blank'
            rel='noreferrer'
          >
            Read me before connecting
          </a>
        </div>
      )}
    </div>
  );
}

export default TopTextArea;
