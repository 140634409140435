import * as Yup from 'yup';
import { getTrimmedValue, isValidPhoneNumber } from '../../../../../../../utils/utilities';
import {
  getEmailValidationSchema,
  getHrDesignationSchema,
  getLinkedinUrlValidation,
  getNameValidationSchema
} from '../../../../../../../utils/ValidationSchema';

// Define phone validation
const phoneValidation = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .test({
    name: 'phone',
    message: 'Please add a valid Phone number',
    test: function (value) {
      let intlPhoneNumber = `${this.parent.country_code}${value}`;
      return value ? isValidPhoneNumber(intlPhoneNumber) : true;
    }
  });

const referencesSchema = Yup.object().shape({
  full_name: getNameValidationSchema({ message: 'Full Name', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: true }),
  phone: phoneValidation.required('Please enter a valid phone number'),
  country_code: Yup.string().required('Please enter a valid country code'),
  designation: getHrDesignationSchema({ message: 'Designation', isRequired: true }),
  linkedin_url: getLinkedinUrlValidation({ isRequired: false, isNullable: true })
});

const referencesArraySchema = Yup.array()
  .of(referencesSchema)
  .test(
    'unique-email',
    'Same Email ID cannot be added for more than one reference',
    function (list) {
      const seen = new Map();
      let errors = [];
      list.forEach((item, index) => {
        const email = item.email ? item.email.trim().toLowerCase() : '';
        if (email && seen.has(email)) {
          const prevIndex = seen.get(email);
          errors.push(
            this.createError({
              path: `${this.path}[${prevIndex}].email`,
              message: 'Same Email ID cannot be added for more than one reference'
            })
          );
          errors.push(
            this.createError({
              path: `${this.path}[${index}].email`,
              message: 'Same Email ID cannot be added for more than one reference'
            })
          );
        } else {
          if (email) seen.set(email, index);
        }
      });
      if (errors.length) throw new Yup.ValidationError(errors);
      return true;
    }
  )
  .test(
    'unique-phone',
    'Same Phone number cannot be added for more than one reference',
    function (list) {
      const seen = new Map();
      let errors = [];
      list.forEach((item, index) => {
        const phone = item.phone ? `${item.country_code}${item.phone.trim()}` : '';
        if (phone && seen.has(phone)) {
          const prevIndex = seen.get(phone);
          errors.push(
            this.createError({
              path: `${this.path}[${prevIndex}].phone`,
              message: 'Same Phone number cannot be added for more than one reference'
            })
          );
          errors.push(
            this.createError({
              path: `${this.path}[${index}].phone`,
              message: 'Same Phone number cannot be added for more than one reference'
            })
          );
        } else {
          if (phone) seen.set(phone, index);
        }
      });
      if (errors.length) throw new Yup.ValidationError(errors);
      return true;
    }
  );

export default Yup.object().shape({
  hr_info: Yup.object().shape({
    full_name: getNameValidationSchema({ message: 'Full Name' }).required(
      'Please provide their Full Name'
    ),
    email: getEmailValidationSchema({ isNullable: true }),
    phone: phoneValidation
  }),
  manager_info: Yup.object().shape({
    full_name: getNameValidationSchema({ message: 'Full Name' }).when('refCount', {
      is: (refCount) => refCount === 0,
      then: getNameValidationSchema({ message: 'Full Name' }).required(
        'Please provide their Full name'
      )
    }),
    email: getEmailValidationSchema({ isNullable: true }),
    phone: phoneValidation
  }),
  references_additional: referencesArraySchema
});
