import { connect } from 'react-redux';
import { compose } from 'redux';
import { logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
