import * as Yup from 'yup';
import {
  getEmailValidationSchema,
  getHrDesignationSchema,
  getLinkedinUrlValidation,
  getNameValidationSchema
} from '../../../../utils/ValidationSchema';
import { getTrimmedValue, isValidPhoneNumber } from '../../../../utils/utilities';

const phoneValidation = (hrEmailValidate) =>
  Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .test({
      name: 'hrMobile',
      message: 'Please provide a valid Mobile number',
      test: function (value) {
        let intlPhoneNumber = `${this.parent.countryCode + '' + value}`;
        if (value) {
          return isValidPhoneNumber(intlPhoneNumber);
        } else {
          return hrEmailValidate;
        }
      }
    });

export default Yup.object().shape({
  additionalContact: Yup.array().of(
    Yup.lazy((value) =>
      Yup.object().shape({
        hrName: getNameValidationSchema({ message: 'Name', isRequired: true }),
        relation: Yup.string().required('Please choose an option'),
        hrEmail: getEmailValidationSchema({
          isRequired: !value.hrMobile, // Conditional based on hrMobile
          isNullable: null,
          msg: 'Please provide a valid Email ID',
          isPlusSignAllowed: false
        }),
        hrMobile: phoneValidation(value.hrEmail),
        linkedIn: !value.companyName
          ? getLinkedinUrlValidation({ isRequired: true })
          : getLinkedinUrlValidation({ isRequired: false }),
        companyName: !value.linkedIn ? Yup.string().required() : Yup.string().notRequired(),
        mobileErr: Yup.boolean().test(
          'mobile-check',
          'Please provide a valid Mobile number',
          function () {
            return this.parent.hrEmail || this.parent.hrMobile;
          }
        ),
        emailErr: Yup.boolean().test('email-check', 'Please provide a valid Email ID', function () {
          return this.parent.hrEmail || this.parent.hrMobile;
        }),

        companyNameAndLinkedInErr: Yup.boolean().test(
          'name-linkedin-check',
          'Please provide either valid company name or LinkedIn URL',
          function () {
            return this.parent.companyName || this.parent.linkedIn;
          }
        )
      })
    )
  )
});
