import React from 'react';

import Image from '../Image';

import styles from './Banner.module.scss';
import { has } from 'lodash';

export default (props) => {
  const textColor = has(props, 'whiteTextColor') ? styles.whiteTextColor : styles.textColor;

  return (
    <div
      className={
        props.clsName
          ? `${styles[props.clsName]} ${textColor}}`
          : `${styles.bannerContainer} ${styles.textCenter} ${textColor}`
      }
    >
      <Image
        name={props.name ? props.name : 'warningPackage.svg'}
        className={styles.image}
        style={{ marginTop: props.margin }}
      />{' '}
      {props.children}
    </div>
  );
};
