import React from 'react';
import Form from 'react-bootstrap/Form';

import styles from './ValidatedFormTextArea.module.scss';

import TextArea from '../TextArea';

export default React.forwardRef(
  ({ field, form, label, regex, max, handleChange, ...props }, ref) => {
    return (
      <Form.Group
        style={{ marginBottom: '32px', width: '100%' }}
        controlId={field && field.name}
        className={props.className}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <div className={props?.containerClass}>
          <TextArea
            data-testid='form'
            {...field}
            {...props}
            isInvalid={form && !!form.errors[field.name]}
            onChange={(event) => {
              if (props.onChange) {
                props.onChange(event);
              } else {
                form.setFieldValue(field.name, event.target.value.replace(regex, '').slice(0, max));
                handleChange && handleChange(event.target.value.replace(regex, '').slice(0, max));
              }
            }}
            ref={ref}
          />
          {props?.showCharCount && (
            <p
              className={
                props.charCountClass +
                ' ' +
                (props?.charsCount !== 0 &&
                (props?.charsCountError ||
                  props?.charsCount >= props?.maxChars ||
                  (props?.charsCount < 10 && props?.charsCount > 0))
                  ? props?.errCountClass
                  : '')
              }
            >
              Characters {props?.charsCount || 0} out of {props?.maxChars}
            </p>
          )}
        </div>
        <div className={styles.inputError}>
          {form && form.errors[field.name] ? form.errors[field.name] : ''}
        </div>
      </Form.Group>
    );
  }
);
