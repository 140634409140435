/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Table, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import Dropdown from '../../core-components/Dropdown';
import CategoryModal from '../CategoryModal/CategoryModal';
import Loader from '../../core-components/Loader';
import ValidatedRadioFields from '../../core-components/RadioOptions';
import styles from './StepTwo.module.scss';
import {
  twoColumnsForName,
  dropDownValuesHandler,
  continueHandler,
  formatCandidateDetails,
  formatEditedDataForApi,
  getRadioOptions,
  getDataWithError,
  getDataWithoutError,
  getIsValid,
  checkEmptyFields
} from '../utils/BulkUploadUtilities';
import { getHeaders, getTableRows } from './TableRowsUtils';
import { getTrimmedValue } from '../../core-components/Utilities/utilities';

export default function StepTwo(props) {
  const {
    errors,
    values,
    setFieldValue,
    handleSubmit,
    handleStepChange,
    categories,
    rows,
    companyId
  } = props;

  const {
    isEmailOptional,
    tableData,
    loadingData,
    filterValue,
    hasTwoColumnsForName,
    totalCount,
    errorCount,
    noErrorCount,
    filteredDataCandidatesWithError,
    filteredDataCandidatesWithOutError,
    buttonClicked,
    dropDownValues
  } = values;

  const [showCategoryModal, setShowCategoryModal] = useState(false);

  // when button is clicked first time then set filter value to ='all'
  useEffect(() => {
    if (buttonClicked && !filterValue) {
      setFieldValue('filterValue', 'all');
    }
  }, [tableData]);

  /**
   * in this useEffect,
   *  if hasTwoColumnsForName checkbox checked -> then add fullName value to list back and make it empty in state
   *  else hasTwoColumnsForName checkbox un-checked -> then add firstName and lastName value to list back and make them empty in state
   */
  useEffect(() => {
    const dropDownListValues = twoColumnsForName({ values, dropDownValues, setFieldValue });
    setFieldValue('dropDownValues', dropDownListValues);
  }, [values.hasTwoColumnsForName]);

  // if filter value changes get data according to it
  useEffect(() => {
    let newDataCandidates = [...tableData];
    if (filterValue === 'withError') {
      setFieldValue('filteredDataCandidatesWithError', getDataWithError(newDataCandidates));
    } else if (filterValue === 'withoutError') {
      setFieldValue('filteredDataCandidatesWithOutError', getDataWithoutError(newDataCandidates));
    }
  }, [filterValue]);

  // function to hanlde values for  drop-down list
  const handleDropDownValues = (fieldName, valueSelected) => {
    setFieldValue('buttonClicked', false);
    const dropDownListValues = dropDownValuesHandler({
      values,
      fieldName,
      dropDownValues,
      valueSelected
    });
    setFieldValue('dropDownValues', dropDownListValues);
  };

  /**
   * if button is clicked to preview-data, and error is there,
   *  then show error else not
   */
  const getErrorMessage = () => {
    const isError = checkEmptyFields(values);
    const noneValueError = getIsValid(tableData, values, true);
    if (noneValueError) {
      return <span>One of the required field is 'None'</span>;
    }
    return isError ? <span>Please select all required fields</span> : '';
  };

  const applyAutoTrim = (arr) => {
    const ph = values?.phone;
    const empId = values?.empId;
    const email = values?.email;

    const indices = [ph, empId, email].map((value) => (value ? arr[0]?.indexOf(value) : -1));
    for (let i = 1; i < arr.length; i++) {
      // Apply auto trim only if the element is a string
      for (let j = 0; j < arr[i].length; j++) {
        if (typeof arr[i][j] === 'string') {
          // Check if j is one of the specified indices
          const withSpace = indices.includes(j) ? false : true;
          arr[i][j] = getTrimmedValue(arr[i][j], withSpace);
        }
      }
    }

    return arr;
  };

  // final submit function
  const submitHandler = (e) => {
    e.preventDefault();

    // setting this state to check whether button was clicked or not
    setFieldValue('buttonClicked', true);
    const data = !buttonClicked
      ? formatCandidateDetails(applyAutoTrim(rows), values)
      : formatEditedDataForApi(tableData);
    setFieldValue('finalData', data);

    const noneValueError = getIsValid(tableData, values, true);
    !noneValueError && data?.length ? handleSubmit() : null;
  };

  // navigating to add candidate flow => package listng page (continue from step - 2 )
  const handleContinue = (categoryTypeId) => {
    continueHandler({ props, categoryTypeId, tableData, companyId });
  };

  // seting new value for editable fields in table
  const onChangeHandler = (newValue, index, type) => {
    const rowsCopy =
      filterValue === 'withError' ? [...filteredDataCandidatesWithError] : [...tableData];
    let rowData = rowsCopy[index];

    if (typeof rowData[type] === 'object') {
      rowData[type].value = getTrimmedValue(newValue, false);
    }
    rowsCopy[index] = rowData;

    filterValue === 'withError'
      ? setFieldValue('filteredDataCandidatesWithError', rowsCopy)
      : setFieldValue('tableData', rowsCopy);
  };

  // creating rows data
  const getRowData = (array) => {
    const rows = getTableRows({ array, values, styles, onChangeHandler });
    return <>{rows}</>;
  };

  const getFinalRowData = () => {
    if (['all', 'withError', 'withoutError'].includes(filterValue)) {
      switch (filterValue) {
        case 'withError':
          return getRowData(filteredDataCandidatesWithError);
        case 'withoutError':
          return getRowData(getDataWithoutError(filteredDataCandidatesWithOutError));
        case 'all':
          return getRowData(tableData);
      }
    } else {
      return getRowData(tableData);
    }
  };

  const Data = ({ children }) => {
    return props.isCA ? (
      <Card className={styles.addCandidateCard} style={{ marginTop: '-190px' }}>
        {children}
      </Card>
    ) : (
      <div className={styles.addCandidateCard}>{children}</div>
    );
  };

  const stepChangeHandler = () => {
    handleStepChange(1);
  };
  return (
    <>
      <Data>
        <div className={styles.addCandidate}>
          <Row>
            <Col md={2} lg={2} sm={12}>
              <h5 className={styles.heading}>Bulk Candidate Addition</h5>
              <div>
                <div className={styles.columns}>Select columns</div>
                <div className={styles.secondheader}>
                  <div className={styles.filters}>
                    <Form onSubmit={submitHandler}>
                      <div className={styles.filterItems}>
                        <Dropdown
                          data={dropDownValues}
                          name={values.hasTwoColumnsForName ? 'firstName' : 'fullName'}
                          label={values.hasTwoColumnsForName ? 'First Name' : 'Full Name'}
                          value={values.hasTwoColumnsForName ? values.firstName : values.fullName}
                          onChange={setFieldValue}
                          handleDropDownValues={handleDropDownValues}
                          required={true}
                        />
                      </div>
                      {values.hasTwoColumnsForName && (
                        <div className={styles.filterItems}>
                          <Dropdown
                            data={dropDownValues}
                            name='lastName'
                            label='Last name'
                            value={values.lastName}
                            onChange={setFieldValue}
                            handleDropDownValues={handleDropDownValues}
                          />
                        </div>
                      )}
                      <div className={styles.label}>
                        <Form.Check
                          name='full_Name'
                          type='checkbox'
                          id='full_Name'
                          onChange={() => {
                            setFieldValue('hasTwoColumnsForName', !values.hasTwoColumnsForName);
                          }}
                          checked={values?.hasTwoColumnsForName}
                        />
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '14px',
                            paddingLeft: '2px'
                          }}
                        >
                          File has 2 columns for name
                        </span>
                      </div>

                      <div className={styles.filterItems}>
                        <Dropdown
                          data={dropDownValues}
                          name='phone'
                          label='Phone'
                          value={values.phone}
                          onChange={setFieldValue}
                          handleDropDownValues={handleDropDownValues}
                          required={true}
                        />
                      </div>
                      <div className={styles.filterItems}>
                        <Dropdown
                          data={dropDownValues}
                          name='empId'
                          label='Employee ID'
                          value={values.empId}
                          onChange={setFieldValue}
                          handleDropDownValues={handleDropDownValues}
                        />
                      </div>
                      <div className={styles.filterItems}>
                        <Dropdown
                          data={dropDownValues}
                          name='email'
                          label='Email'
                          value={values.email}
                          onChange={setFieldValue}
                          handleDropDownValues={handleDropDownValues}
                          required={true}
                        />
                      </div>

                      <Button className={`mr-2 ${styles.approveBtn}`} type='submit'>
                        {!buttonClicked ? 'Preview Data' : 'Reload Data'}
                      </Button>
                      <div className={styles.errorLabel}>{getErrorMessage()}</div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={10}>
              <Row style={{ marginLeft: '35px', marginBottom: '10px' }}>
                <Field
                  name='filterValue'
                  component={ValidatedRadioFields}
                  options={getRadioOptions(
                    buttonClicked,
                    totalCount,
                    errorCount,
                    noErrorCount,
                    tableData
                  )}
                />
              </Row>
              <div>
                <Table className={styles.candidatesTable} bordered={true}>
                  <thead>
                    <tr
                      className={`${styles.header}  ${
                        buttonClicked && tableData?.length > 0
                          ? styles.dataHeader
                          : styles.noDataHeader
                      }`}
                    >
                      {getHeaders(hasTwoColumnsForName, isEmailOptional)}
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr>
                        <td>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      getFinalRowData()
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.buttons}>
          <span className={styles.footerLabel}>
            Package, add-ons and check configurations can be selected in the next step
          </span>
          <Button
            className={`mr-2 ${styles.backButton}`}
            variant='outline-primary'
            onClick={stepChangeHandler}
          >
            Back
          </Button>
          <Button
            className={`mr-2 ${
              getDataWithError(tableData, true) === 0 && tableData.length
                ? styles.nextButton
                : styles.disableNextButton
            }`}
            onClick={() => setShowCategoryModal(!showCategoryModal)}
            disabled={getIsValid(tableData, values)}
          >
            Next
          </Button>
        </div>
      </Data>
      {showCategoryModal ? (
        <CategoryModal
          open={showCategoryModal}
          handleClose={() => setShowCategoryModal(!showCategoryModal)}
          handleContinue={handleContinue}
          categories={categories}
        />
      ) : null}
    </>
  );
}
