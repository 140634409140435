import React from 'react';
import AppConstants from '../../core-components/AppConstants';
import styles from './UnAuthorized.module.scss';

export default () => {
  return (
    <div className={styles.unAuthorized}>
      <img
        src='https://springverify-assets-id.s3.amazonaws.com/static-content/images/springverify-icon-mail.png'
        className={styles.imgStyle}
        alt='SpringVerify Logo'
      />
      <div style={{ marginTop: '30px' }}>
        <h6 className={styles.labelStyle}>You cannot access this resource.</h6>
      </div>
    </div>
  );
};
