import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

import Icon from '../../../../core-components/Icon';
import {
  bgvUrl as bgvUrlAPI,
  sendConsentUrl as sendConsentUrlAPI,
  resendVerificationLink as resendVerificationLinkAPI
} from '../../../../api/company';

import styles from './DropdownMenu.module.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div ref={ref} className={className} style={style} ari-labelledby={labeledBy}>
        <div>{React.Children.toArray(children)}</div>
      </div>
    );
  }
);
const copyToClipBoard = (value) => {
  var dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  toast.info('Copied to Clipboard');
};

export default ({ ...props }) => {
  const statArr = [11];

  const isSendConsentLinkOptionDisabled = () => {
    if (props?.candidateInfo?.emailRejectType !== 'NA') {
      /* If the email bounce flag is true we disable the option to send Consent and BGV link to candidate. */
      return true;
    }

    return (
      props?.candidateInfo?.companyCandidateMapping?.consentLetterUrl ||
      !props?.candidateInfo?.email ||
      !statArr.includes(parseInt(props?.status))
    );
  };

  const isSendBgvLinkDisabled = () => {
    if (props?.candidateInfo?.emailRejectType !== 'NA') {
      /* If the email bounce flag is true we disable the option to send Consent and BGV link to candidate. */
      return true;
    }

    return props?.status !== 3 || !props?.candidateInfo?.email;
  };

  return (
    <div className={styles.dropdownMenuWrapper}>
      <Dropdown className={styles.mainDropdown}>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          <Icon icon={'MenuDots'} color={'#666666'} width={'24'} height={'24'} />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu} className={styles.dropdownMenu}>
          <Dropdown.Item
            className={
              isSendConsentLinkOptionDisabled()
                ? styles.disabledDropdownParticularCard
                : styles.dropdownParticularCard
            }
            onClick={() => {
              if (
                !props?.candidateInfo?.companyCandidateMapping?.consentLetterUrl &&
                props?.candidateInfo?.email &&
                statArr.includes(parseInt(props?.status))
              ) {
                sendConsentUrlAPI({ candidate_id: props?.candidateInfo?.id }).then((response) => {
                  toast.success('Consent Link sent successfully');
                });
              }
            }}
          >
            {'Resend Consent Link'}
          </Dropdown.Item>
          <Dropdown.Item
            className={
              isSendBgvLinkDisabled()
                ? styles.disabledDropdownParticularCard
                : styles.dropdownParticularCard
            }
            onClick={() => {
              if (props?.status === 3 && props?.candidateInfo?.email) {
                props?.onSelect('resendVerification');
              }
            }}
          >
            {'Resend Candidate Form'}
          </Dropdown.Item>
          <Dropdown.Item
            className={
              parseInt(props?.candidateInfo?.companyCandidateMapping?.status) !== 3
                ? styles.disabledDropdownParticularCard
                : styles.dropdownParticularCard
            }
            onClick={() => {
              parseInt(props?.candidateInfo?.companyCandidateMapping?.status) === 3 &&
                bgvUrlAPI(props?.candidateInfo?.id)
                  .then((response) => {
                    window.open(response.data.data, '_target');
                  })
                  .catch((err) => {
                    console.error('Error: ', err);
                  });
            }}
          >
            {'Open Candidate Form'}
          </Dropdown.Item>
          <Dropdown.Item
            className={
              parseInt(props?.candidateInfo?.companyCandidateMapping?.status) !== 3 ||
              !props?.candidateInfo?.email
                ? styles.disabledDropdownParticularCard
                : styles.dropdownParticularCard
            }
            onClick={() => {
              parseInt(props?.candidateInfo?.companyCandidateMapping?.status) === 3 &&
                props?.candidateInfo?.email &&
                resendVerificationLinkAPI({
                  candidateId: props?.candidateInfo.id?.toString(),
                  companyId: props?.candidateInfo?.companyCandidateMapping?.companyId?.toString(),
                  blockMail: true
                }).then((response) => {
                  copyToClipBoard(response.data.data.link || '');
                });
            }}
          >
            {'Copy link to Candidate Form'}
          </Dropdown.Item>
          <Dropdown.Item
            className={
              parseInt(props?.status) !== 3
                ? styles.disabledDropdownParticularCard
                : styles.dropdownParticularCard
            }
            onClick={() => {
              if (parseInt(props?.status) === 3) {
                props?.onSelect('editDetails');
              }
            }}
          >
            {'Edit Candidate Details'}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
