import cn from 'classnames';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import TabComponent from '../core-components/TabComponent/TabComponent';
import AlumniRecordTabContainer from './AlumniRecordTab';
import styles from './AlumniVerificationTabs.module.scss';
import DataSyncLogTabContainer from './DataSyncLogTab';
import RecentRequestTable from './RecentRequestTable';
import {
  DEFAULT_TAB_MAPPING,
  TAB_ALUMNI_RECORDS,
  TAB_DATA_SYNC_LOG,
  TAB_RECENT_REQUEST,
  TAB_CONFIGURATION
} from './utils';

export default function AlumniVerificationTabs({
  history,
  location,
  isSA = false,
  ConfigurationTab = null
}) {
  let tabItems = [TAB_RECENT_REQUEST, TAB_ALUMNI_RECORDS, TAB_DATA_SYNC_LOG];
  const searchParams = new URLSearchParams(location.search);
  const defaultTab = searchParams?.get('tab') || 'recent_request';

  const [activeTab, setActiveTab] = useState(DEFAULT_TAB_MAPPING[defaultTab] || TAB_RECENT_REQUEST);

  if (isSA) {
    tabItems = [...tabItems, TAB_CONFIGURATION];
  }

  const handleOnChangeTab = (tab) => {
    setActiveTab(tab);
    const queryParam =
      Object.keys(DEFAULT_TAB_MAPPING).find((key) => DEFAULT_TAB_MAPPING[key] === tab) ||
      'recent_request';
    searchParams.set('tab', queryParam);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  return (
    <div className={cn(styles.alumniTabsContainer)}>
      <Card className={styles.cardContainer}>
        <TabComponent
          tabItems={tabItems}
          activeTab={activeTab}
          handleTabChange={handleOnChangeTab}
          history={history}
          isSA={isSA}
        />
        {activeTab === 'Alumni Records' && <AlumniRecordTabContainer isSA={isSA} />}
        {activeTab === 'Data Sync Log' && <DataSyncLogTabContainer isSA={isSA} />}
        {activeTab === 'Recent Requests' && <RecentRequestTable isSA={isSA} />}
        {activeTab === 'Configuration' && isSA ? <ConfigurationTab /> : null}
      </Card>
    </div>
  );
}
