import React from 'react';

import DashboardOptions from './DashboardOptions';
import Button from '../../../core-components/Button';

import styles from './Dashboard.module.scss';
import { Link } from 'react-router-dom';
import AddCandidateButton from '../../../core-components/AddCandidate/AddCandidateButton';

export default class Dashboard extends React.Component {
  componentDidMount() {
    this.props?.getStatusCount();
    this.props?.getPaymentDetails();
  }
  getSortedOptions = (options) => {
    return Object.keys(options)
      .sort()
      .reduce((accumulator, currentValue) => {
        accumulator[currentValue] = options[currentValue];
        return accumulator;
      }, {});
  };
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.subHeading}>
          <h4 className={styles.header}>{'Dashboard'}</h4>
          <AddCandidateButton />
        </div>
        <DashboardOptions
          options={
            this.props?.statusCount.data && this.getSortedOptions(this.props?.statusCount.data)
          }
          {...this.props}
        />
      </div>
    );
  }
}
