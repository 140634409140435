import React, { useState, useEffect } from 'react';

import { Accordion, Card, ListGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBan } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../../../core-components/Image';
import CustomCounter from '../../../../../../../core-components/CustomCounter';

import {
  PLAIN_CHECK_ICONS,
  IDENTITES_FOR_PACKAGE_CONFIG,
  CHECKS_LIMIT
} from '../../../../../../../utils/commonConstant';
import { convertToIndianNumeration } from '../../../../../../../utils/utilities';
import styles from './Identity.module.scss';

function Identity(props) {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [identityCheckCount, setIdentityCheckCount] = useState({ any: 0, check: 0 });
  const [valuesCheckCount, setValuesCheckCount] = useState(0);

  const { values, onValueChange, pDetails, addonDetails, isAadhaarAllowed } = props;
  const IDCount = isAadhaarAllowed ? CHECKS_LIMIT.ID : CHECKS_LIMIT.ID - 1;
  const setCounts = () => {
    const obj = {
      any: 0,
      check: 0
    };

    for (let i = 0; i < pDetails?.length; i++) {
      if (pDetails[i].includes('ANY_')) {
        obj['any'] = parseInt(pDetails[i].split('_')[1]);
      } else {
        obj['check'] = obj['check'] + 1;
      }
    }

    obj['total'] = obj['any'] + obj['check'];
    setIdentityCheckCount(obj);
  };

  useEffect(() => {
    if (pDetails) {
      setCounts();
    }
  }, [pDetails]);

  useEffect(() => {
    if (values) {
      setValuesCheckCount(values.any + values.config?.length);
    }
  }, [values?.config, values?.any]);

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  const handleAnyConfigChange = (value) => {
    onValueChange('any', value);
  };

  const handleCheckConfigChange = (event) => {
    const arr = [...values.config];

    if (event.target.checked) {
      arr.push(event.target.value);
    } else {
      arr.splice(arr.indexOf(event.target.value), 1);
    }

    onValueChange('config', arr);
  };
  const hasValue = (name) => {
    return values?.config?.includes(name);
  };

  const isDisabled = () => {
    return values?.config?.length + values?.any === props?.limit;
  };

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={styles.addonsContainerHeader}
            onClick={() => decoratedOnClick(0)}
            data-testid="accordion-toggle"
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS['identity'] ? PLAIN_CHECK_ICONS['identity'].icon : faBan}
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <span>{props?.heading}</span>
              <div className={styles.headingRight}>
                <span className={styles.startFrom}>
                  <span className={styles.rupee}>&#8377;</span>
                  {convertToIndianNumeration(props?.price)}
                  {' per check'}
                </span>
                {activeAccordion === 0 ? (
                  <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                ) : (
                  <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <ListGroup className={styles.listGroup}>
                {identityCheckCount.total <= CHECKS_LIMIT.ID &&
                  identityCheckCount.any !== CHECKS_LIMIT.ID &&
                  identityCheckCount.check <= CHECKS_LIMIT.ID
                  ? IDENTITES_FOR_PACKAGE_CONFIG.map((item, index) => {
                    return (
                      !pDetails.includes(item.name) && (
                        <ListGroup.Item className={styles.listGroupItem} key={index}>
                          <Form.Group controlId={`${item.name}`} className={styles.formGroup}>
                            <Form.Check
                              name={`${item.name}`}
                              id={`${item.name}`}
                              type='checkbox'
                              value={item.name}
                              disabled={
                                (!hasValue(item.name) && isDisabled()) ||
                                (item.name === 'AADHAAR' && !isAadhaarAllowed)
                              }
                              checked={values?.config?.includes(item.name)}
                              onChange={(e) => {
                                handleCheckConfigChange(e);
                              }}
                              label={<span className={styles.label}>{`${item.label}`}</span>}
                            />
                          </Form.Group>
                        </ListGroup.Item>
                      )
                    );
                  })
                  : null}
                <ListGroup.Item className={styles.listGroupItem}>
                  <ListGroup className={styles.listGroupInside}>
                    <ListGroup.Item className={styles.listGroupItem}>
                      <p className={styles.otherText}>{'Any ID'}</p>
                    </ListGroup.Item>
                    <ListGroup.Item className={styles.listGroupItem}>
                      <CustomCounter
                        limit={IDCount - identityCheckCount.total}
                        totalCount={valuesCheckCount}
                        value={values?.any}
                        index={null}
                        valueChange={(value) => {
                          handleAnyConfigChange(value);
                        }}
                        data-testid="increment"
                      />
                    </ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Identity;
