import { Field } from 'formik';
import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import {
  deleteIntegrations,
  getIntegrations,
  postIntegrations
} from '../../../../../../../api/company';
import Button from '../../../../core-components/Button';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField';
import WarningModal from '../../../WarningModal';
import styles from './InputArea.module.scss';
import { generateRecruiteeAPIKeySteps, recruiteeSourceName } from '../../../Utils/constants';
import TextWithCopyButton from '../../../Utils/TextWithCopyButton';
import Image from '../../../../core-components/Image/Image';
import { errToastMessage } from '../../../../utils/Utilities';

function InputArea(props) {
  const { values, setFieldValue } = props;
  const [tokenKey, setTokenKey] = useState('');
  const [isWebHookDetected, setIsWebHookDetected] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  useEffect(() => {
    props.setIntegrationDataAPI(companyId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      props.setIntegrationDataAPI(companyId);
    };

    const intervalId = setInterval(fetchData, 10000); // Call the API every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const integrationData = props?.integrationData;
    if (integrationData?.recruitee) {
      setTokenKey(integrationData?.recruitee?.token);
      if (integrationData.recruitee?.webhookEnabled) {
        props.setFieldValue('recruiteeApiKey', integrationData?.recruitee.apiKey);
        props.setFieldValue('recruiteeCompanyId', integrationData?.recruitee?.companyId);
        setIsWebHookDetected(true);
      }
    }
  }, [props.integrationData]);

  /** API Calls **/
  const postIntegrationsApi = () => {
    const payload = {
      company_id: null,
      source: recruiteeSourceName[0],
      details: {
        api_key: values?.recruiteeApiKey
      }
    };
    postIntegrations(payload)
      .then((resp) => {
        toast.success('Connected successfully.');
        props.setIntegrationDataAPI(companyId);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations(recruiteeSourceName[0])
      .then((resp) => {
        setTokenKey(false);
        setIsWebHookDetected(false);
        setFieldValue('recruiteeApiKey', '');
        toast.success('Removed connection successfully.');
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onConnectHandler = () => {
    if (!values.recruiteeApiKey) {
      toast.info('Cannot connect to Recruitee with empty API key value.');
      return;
    }
    if (!tokenKey && !isWebHookDetected) {
      postIntegrationsApi();
    }
  };

  const backStepHandler = () => {
    /** Opens the Integrations home page **/
    props.history.push('/integrations');
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  return (
    <>
      {!tokenKey && !isWebHookDetected ? (
        <Container>
          <Row className='mt-5'>
            <h6 className='font-weight-bold'>Step 1: Authentication via API key </h6>
          </Row>
          <div className={styles.listItems}>
            <div>
              1.{' '}
              <a href='https://app.recruitee.com/#/settings/api_tokens' target='_blank'>
                <u>Generate an API</u>{' '}
              </a>
              key on Recruitee
            </div>
          </div>
          <Row className='mt-4'>
            <h6 className='font-weight-bold'>
              Incase you are not able to access the link, follow the steps below
            </h6>
          </Row>
          <div className={styles.listItems}>
            {generateRecruiteeAPIKeySteps.map((step, index) => {
              return (
                <div>
                  {index + 1}. {step}
                </div>
              );
            })}
          </div>
          <Row className='mt-4'>
            <h6 className='font-weight-bold'>Step 2: Enter the API key and click Connect</h6>
          </Row>
          <Form className='mt-2'>
            <Row>
              <Col className={`col-sm-2 ${styles.inputLabel}`}>
                <span> API Token*</span>
              </Col>
            </Row>
            <Row>
              <Col className='col-sm-4'>
                <Field
                  disabled={tokenKey}
                  component={ValidatedFormInputField}
                  type='text'
                  placeholder='Enter API Key here'
                  name='recruiteeApiKey'
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <div className={styles.step1FooterContainer}>
              <Button className={styles.goBackBtn} onClick={() => backStepHandler()}>
                Go Back
              </Button>
              <Button
                disabled={!values.recruiteeApiKey}
                className={`${tokenKey ? styles.connectedButton : styles.connectButton}`}
                onClick={onConnectHandler}
              >
                Connect
              </Button>
            </div>
          </Row>
        </Container>
      ) : tokenKey && !isWebHookDetected ? (
        <Container>
          <div className='step1FooterContainer'>
            <Row className='mt-5'>
              <div>
                <h6 className='font-weight-bold'>
                  Step 3: Enable Integration on{' '}
                  <a href='https://app.recruitee.com/#/settings/webhooks/overview'>
                    <u>Recruitee</u>{' '}
                  </a>
                  and reload the page
                </h6>
                <div className={styles.generateTokenButton}>
                  <div className={styles.generateTokenButton__heading}>Generated Token</div>
                  <TextWithCopyButton text={tokenKey} />
                </div>
              </div>
            </Row>
            <Row>
              <div className={styles.step1FooterContainer}>
                <Button
                  className={styles.goBackBtn}
                  onClick={() => {
                    backStepHandler();
                  }}
                >
                  Go Back
                </Button>

                <Button
                  className={styles.connectButton}
                  onClick={onConnectHandler}
                  disabled={!isWebHookDetected}
                >
                  Connect
                </Button>
              </div>
            </Row>
          </div>
        </Container>
      ) : (
        <Container>
          <Form className='mt-5'>
            <div className={styles.rowDisplay}>
              <span className='pt-1'> API Token</span>
            </div>
            <div className={styles.rowDisplay}>
              <Field
                disabled={tokenKey}
                component={ValidatedFormInputField}
                type='text'
                placeholder='Enter API Key here'
                name='recruiteeApiKey'
              />
              <span className={styles.iconBg}>
                <Image name='lockGreyColor.png' className={styles.tooltipLockIcon} />
              </span>
            </div>
          </Form>
          <div className={styles.rowDisplay}>
            <div className={styles.step1FooterContainer}>
              <Button className={styles.goBackBtn} onClick={() => backStepHandler()}>
                Go Back
              </Button>
              {tokenKey ? (
                <Button
                  className={styles.removeButton}
                  variant='outline-danger'
                  onClick={() => onRemoveConnectionHandler()}
                >
                  Disconnect
                </Button>
              ) : null}
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default InputArea;
