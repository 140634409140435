import React, { useEffect, useState } from 'react';
import styles from './HRMSorUpload.module.scss';
import { Card } from 'react-bootstrap';
import Image from '../../../../core-components/Image';
import Zoho from '../HRMSConnectBar/images/zoho.png';
import Humaans from '../../frontend-common/images/humaans.png';
import HrCloud from '../../frontend-common/images/hrcloud.png';
import GreytHR from '../../frontend-common/images/GreytHR.png';
import BambooHR from '../../frontend-common/images/bamboohr.png';
import Keka from '../../frontend-common/images/keka.png';
import Repute from '../../frontend-common/images/repute.png';
import Personio from '../../frontend-common/images/perssonio.png';
import Hibob from '../../frontend-common/images/hibob.png';
import HaileyHR from '../../frontend-common/images/haileyhr.png';
import Gusto from '../../frontend-common/images/Gusto.png';
import hrpartner from '../../frontend-common/images/HRPartner.png';
import AlexisHR from '../../frontend-common/images/alexishr.png';
import Breathe from '../../frontend-common/images/Breathe.png';
import Charlie from '../../frontend-common/images/Charlie.png';
import Factorial from '../../frontend-common/images/factorialHRMS.png';
import ClayHR from '../../frontend-common/images/clayhr.png';
import PeopleHR from '../../frontend-common/images/peoplehr.png';
import Namely from '../../frontend-common/images/namely.png';
import IntelliHR from '../../frontend-common/images/intellihr.png';
import authenticate from '@truto/truto-link-sdk';
import { toast } from 'react-toastify';
import { errToastMessage } from '../../../../utils/utilities';
import {
  postAlumniIntegrations,
  getTRUTOLinkToken,
  getHRMSDetails,
  disconnectHRMS
} from '../../../../api/company';
import AddManualModal from '../../../../core-components/AddManualModal/AddManualModal';
import ConfirmationModal from '../../../../core-components/ConfirmationModal';
import { Spinner } from 'react-bootstrap';

const HRMSorUpload = (props) => {
  const [linkToken, setLinkToken] = useState('');
  const [isAddManualModalOpen, setIsAddManualModalOpen] = useState(false);
  const [HRMSConnectStatus, setHRMSConnectStatus] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const logo = {
    ZOHOPEOPLE: Zoho,
    HUMAANS: Humaans,
    HRCLOUD: HrCloud,
    GREYTHR: GreytHR,
    BAMBOOHR: BambooHR,
    KEKA: Keka,
    REPUTE: Repute,
    PERSONIO: Personio,
    HIBOB: Hibob,
    HAILEYHR: HaileyHR,
    GUSTO: Gusto,
    HRPARTNER: hrpartner,
    ALEXISHR: AlexisHR,
    BREATHE: Breathe,
    CHARLIE: Charlie,
    FACTORIAL: Factorial,
    CLAYHR: ClayHR,
    PEOPLEHR: PeopleHR,
    NAMELY: Namely,
    INTELLIHR: IntelliHR
  };

  useEffect(() => {
    setShowLoader(true);
    const token = getTRUTOLinkToken();
    setLinkToken(token);
    getHRMSDetails()
      .then((res) => {
        setHRMSConnectStatus(res?.data?.data?.integrationDetails);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }, []);

  useEffect(() => {
    getTRUTOLinkToken()
      .then((response) => {
        setLinkToken(response?.data?.link_token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const addIntegrationAPI = ({ integrationId, integration }) => {
    const payload = {
      source: integration.toUpperCase(),
      details: { integration_id: integrationId },
      integration_type: 'ALUMNI_VERIFICATION'
    };
    postAlumniIntegrations(payload)
      .then((resp) => {
        toast.success(`${integration.toUpperCase()} Integration added successfully.`);
        window.location.reload();
      })
      .catch((error) => {
        errToastMessage(error);
        setShowLoader(false);
      });
  };

  const connectTruto = () => {
    setShowLoader(true);
    const options = {
      integrations: [
        'zohopeople',
        'humaans',
        'hrcloud',
        'greythr',
        'keka',
        'repute',
        'bamboohr',
        'personio',
        'gusto',
        'haileyhr',
        'hibob',
        'alexishr',
        'breathe',
        'factorial',
        'hrpartner',
        'charlie',
        'clayhr',
        'peoplehr',
        'namely',
        'intellihr'
      ]
    };
    authenticate(linkToken, options)
      .then((response) => {
        addIntegrationAPI({
          integration: response?.integration,
          integrationId: response?.integrated_account_id
        });
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  const disconnectTruto = () => {
    setShowLoader(true);
    setShowWarningModal(false);
    disconnectHRMS(HRMSConnectStatus?.source, HRMSConnectStatus?.type).then((res) => {
      toast.success('HRMS disconnected successfully');
      window.location.reload();
    });
  };

  const clickHandler = () => {
    if (HRMSConnectStatus) {
      setShowWarningModal(true);
    } else {
      connectTruto();
    }
  };

  return (
    <>
      <Card className={styles.hrmsContainerWrapper}>
        <div className={styles.containerFlex}>
          <div className={styles.headingContainer}>
            <span className={styles.headingText}>
              Connect HRMS or Manually upload your Ex-employee details
            </span>
          </div>
          <div className={styles.outerContainer}>
            <div className={styles.connectContainer}>
              <div className={styles.connectBox} onClick={() => clickHandler()}>
                {showLoader ? (
                  <div
                    style={{
                      height: '80px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Spinner
                      animation='border'
                      variant='dark'
                      hidden={false}
                      style={{ verticalAlign: 'middle' }}
                    />
                  </div>
                ) : (
                  <>
                    {logo[HRMSConnectStatus?.source] ? (
                      <img src={logo[HRMSConnectStatus?.source]} style={{ height: '80px' }} />
                    ) : (
                      <Image height='80px' name='plug-connect.svg' />
                    )}
                  </>
                )}
                <span className={styles.connectText}>
                  {HRMSConnectStatus
                    ? `Connected to ${HRMSConnectStatus?.source}`
                    : 'Connect your HRMS'}
                </span>
              </div>
              <div>
                <span className={styles.orText}>Or</span>
              </div>
              <div className={styles.connectBox} onClick={() => setIsAddManualModalOpen(true)}>
                <Image height='80px' name='excel-add.svg' />
                <span className={styles.connectText}>Add manually</span>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {isAddManualModalOpen && (
        <AddManualModal
          onAddSingle={() => {
            props?.history.push('/alumni-verification/record?flow=ADD');
          }}
          onAddBulk={() => {
            props?.history.push('/alumni-verification/bulk-upload');
          }}
          onHide={() => setIsAddManualModalOpen(false)}
        />
      )}

      {showWarningModal && (
        <ConfirmationModal
          onClose={() => setShowWarningModal(false)}
          show={showWarningModal}
          heading={`Disconnect ${HRMSConnectStatus?.source} ?`}
          enableBtn={true}
          sBtnText={'Disconnect'}
          onSubmit={() => {
            disconnectTruto();
          }}
        />
      )}
    </>
  );
};

export default HRMSorUpload;
