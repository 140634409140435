import { connect } from 'react-redux';
import { getAlumniSettings, editAlumniSettings, getBrandSettings } from '/src/actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    alumniSettings: state.alumniSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAlumniSettings: (companyId) => dispatch(getAlumniSettings(companyId)),
    editAlumniSettings: (data) => dispatch(editAlumniSettings(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
