import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, has } from 'lodash';

import validationSchema from './AddressStep2.validation';
import logger from '../../../../../../../core-components/Logger';
import { toCamelCase, toSnakeCase } from '../../../../../../../utils/utilities';

import { saveBgvCache as saveBgvCacheAPI } from '../../../../../../../actions/bgv';

const mapStateToProps = (state) => {
  return {
    cachedData: !state.bgvCachedData.data ? null : toCamelCase({ ...state.bgvCachedData.data })
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data))
  };
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      if (
        has(props.cachedData.cacheData, 'addressCheck') &&
        !isEmpty(props.cachedData.cacheData.addressCheck) &&
        props.cachedData.cacheData.addressCheck.length >= props.index &&
        !isEmpty(props.cachedData.cacheData.addressCheck[props.index]) &&
        has(props.cachedData.cacheData.addressCheck[props.index], 'step2')
      ) {
        propsValue = props.cachedData.cacheData.addressCheck[props.index]['step2'];
      } else {
        propsValue = {};
      }

      let storedValues = {
        latitude: !isEmpty(propsValue) ? propsValue.latitude : '',
        longitude: !isEmpty(propsValue) ? propsValue.docType : '',
        stayingPeriod: !isEmpty(propsValue) ? propsValue.stayingPeriod : '',
        isCurrentPermanentSame: !isEmpty(propsValue) ? propsValue.isCurrentPermanentSame : false,
        isCandidateAvailable: !isEmpty(propsValue) ? propsValue.isCandidateAvailable : 'yes',
        otherFullName: !isEmpty(propsValue) ? propsValue.otherFullName : '',
        relation: !isEmpty(propsValue) ? propsValue.relation : '',
        otherRelation: !isEmpty(propsValue) ? propsValue.otherRelation : '',
        relativeCountryCode: propsValue?.relativeCountryCode
          ? propsValue?.relativeCountryCode
          : '+91',
        relativePhoneNumber: !isEmpty(propsValue) ? propsValue.relativePhoneNumber : '',
        otherPhoneNumber: !isEmpty(propsValue) ? propsValue.otherPhoneNumber : '',
        mapAddress: '',
        placeId: ''
      };

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setFieldError, setSubmitting, resetForm, props }) => {
      if (!values.stayingPeriod) {
        setFieldError('stayingPeriod', 'please select time of stay');
        setSubmitting(false);
      } else if (!values.isCandidateAvailable) {
        setFieldError('isCandidateAvailable', 'Please confirm if you will be available');
        setSubmitting(false);
      } else if (values.isCandidateAvailable === 'no' && !values.otherFullName) {
        setFieldError('otherFullName', 'Please enter the name of person ');
        setSubmitting(false);
      } else if (values.isCandidateAvailable === 'no' && !values.relation) {
        setFieldError('relation', 'Please confirm your relationship with the person');
        setSubmitting(false);
      } else {
        let valuesCopy = Object.assign({}, values);

        if (valuesCopy?.isCandidateAvailable === 'yes') {
          delete valuesCopy['otherRelation'];
          delete valuesCopy['relativeCountryCode'];
          delete valuesCopy['relation'];
          delete valuesCopy['relativePhoneNumber'];
          delete valuesCopy['otherPhoneNumber'];
          delete valuesCopy['otherFullName'];
        }

        const addressCheck = [...props.cachedData.cacheData.addressCheck];

        addressCheck[props.index]['step2'] = {
          ...valuesCopy
        };
        addressCheck[props.index]['currentStep'] = 3;

        const saveObject = toSnakeCase({
          ...props.cachedData,
          cacheData: {
            ...props.cachedData.cacheData,
            addressCheck: [...addressCheck]
          },
          currentState: '3#3'
        });
        const finalCacheObject = {
          saveObject
        };

        props.setBgvCache(finalCacheObject).then(() => {
          logger.push({
            message: `${props.addressType} Address step2  ${
              props.title === 'Edit' ? 'Edited' : 'Saved'
            }`,
            data: JSON.stringify(finalCacheObject),
            category: `candidate-id-${props.candidateId}`,
            subCategory: `${props.addressType} Address #2`,
            type: 'info'
          });

          props.nextSubStep();
        });
      }
    },
    displayName: 'addressStep2'
  })
);
