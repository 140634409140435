import { connect } from 'react-redux';
import {
  setIntegration as setIntegrationAPI,
  getViewBuyPackages as getViewBuyPackagesAPI,
  invalidateIntegrationData,
  invalidateFrequentIntegrations
} from '../../../../../actions/company';
import { isEmpty } from 'lodash';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    integrationData: state?.integrationData || {},
    frequentIntegrationData: state?.frequentIntegration || {},
    viewBuyPackages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIntegrationAPI: (companyId) => dispatch(setIntegrationAPI(companyId)),
    getViewBuyPackages: (data) => dispatch(getViewBuyPackagesAPI(data)),
    invalidateIntegrationData: () => dispatch(invalidateIntegrationData()),
    invalidateFrequentIntegrations: () => dispatch(invalidateFrequentIntegrations())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
