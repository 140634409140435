import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import validationSchema from './ResetPassword.validation';
import storage from '../../api/localStorage';
import { jwtParser, getErrorMessage, errToastMessage } from '../../utils/utilities';
import {
  updateJwt,
  setPassword,
  updateCompanyPaymentType,
  updateProfile
} from '../../actions/authentication';

const mapDispatchToProps = (dispatch) => {
  return {
    setPassword: (data) => dispatch(setPassword(data)),
    updateJwt: (data) => dispatch(updateJwt(data, 'company')),
    updateCompanyPaymentType: (data) => dispatch(updateCompanyPaymentType(data)),
    updateProfile: (data) => dispatch(updateProfile(data))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({ password: '', confirmPassword: '' }),
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, props }) => {
      props
        .setPassword(values)
        .then((response) => {
          const modelType = jwtParser(response.data.data.data.token);
          setSubmitting(false);
          setStatus(true);
          storage.setToken(response.data.data.data.token, 'company');
          props.updateJwt(response.data.data.data.token);
          storage.setAccessLevel(response.data.data.data.user.access_level === 5 ? 'admin' : 'hr');
          let company = response.data.data.data.user;
          localStorage.setItem('company', JSON.stringify(company));
          props.updateCompanyPaymentType(modelType.data.modelType);
          response.data.data.data.user && props.updateProfile(company);
          props.history.push('/dashboard');
        })
        .catch((error) => {
          console.error(error);
          errToastMessage(error);
        });
    },
    displayName: 'ResetPasswordForm'
  })
);
