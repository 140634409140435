import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    companyId: state && state?.profile?.profile?.company_user_mapping?.company_id,
  };
};

export default connect(mapStateToProps, {});
