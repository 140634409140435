import { createStore, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from '../reducers/company-reducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['viewBuyPackages', 'createCandConfig', 'referralData']
};

const store = createStore(
  persistReducer(persistConfig, reducer),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
