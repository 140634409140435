import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deleteIntegrations } from '../../../../../api/company';
import { getErrorMessage } from '../../core-components/Utilities/utilities';
import styles from '../Integrations.module.scss';
import { errToastMessage } from '../../utils/Utilities';

export default function ErrorPage(props) {
  const { show, onHide, redirectUrl } = props;
  const [isLoading, setIsLoading] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  const handleDelete = () => {
    setIsLoading(true);
    deleteIntegrations('LEVER', companyId)
      .then((resp) => {
        toast.success('Removed connection successfully.');
        onHide();
      })
      .catch((error) => {
        errToastMessage(error);
        onHide();
      });
  };
  return (
    <Modal
      size='lg'
      centered
      show={show}
      onHide={onHide}
      backdropClassName={'confirmationModalBackdrop'}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 'bold' }}>Authentication Failed</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginBottom: '1rem' }}>
        Unable to connect to Lever as the authentication is invalid. Please connect again to enable
        the integration.
      </Modal.Body>
      <Modal.Footer>
        <Button className={styles.cancelBtn} onClick={handleDelete} disabled={isLoading}>
          Delete connection
        </Button>
        <Button className={styles.activeBtn} href={redirectUrl} disabled={isLoading}>
          Re-connect
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
