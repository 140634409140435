import React, { useState, useEffect } from 'react';

import { Card, Button, Container } from 'react-bootstrap';
import styles from './Payments.module.scss';
import { Link } from 'react-router-dom';
import Loader from '../core-components/Loader';
import Image from '../core-components/Image';

export default function Payments(props) {
  const [timeLeft, setTimeLeft] = useState(5);
  const { hideModal, paymentStatus, setPaymentStatus } = props;
  let myInterval;
  useEffect(() => {
    if (props?.paymentStatus === 'completed') {
      if (timeLeft === 0) {
        hideModal(true);
        return;
      }
      myInterval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [timeLeft, props?.paymentStatus]);

  const retryPayment = () => {
    setPaymentStatus(null);
    hideModal(false);
    // if ((props.setCurrentStep, props.currentStep)) props.setCurrentStep(props.currentStep - 1);
    // props.setPaymentStatus && props.setPaymentStatus(false);
  };
  return (
    <Card className={`text-center ${styles.card}`}>
      {props.showCross && props?.paymentStatus === 'failed' ? (
        <div className={styles.crossBtn} onClick={hideModal}>
          X
        </div>
      ) : null}
      <Card.Body className={`${styles.body}`}>
        {props.paymentStatus === 'completed' ? (
          <>
            <Image name={'PaymentSuccess.svg'} isSA={props.isSA} />
            <Card.Title className={`text-success ${styles.payment_title}`}>
              Thank you for your purchase
            </Card.Title>
            <Card.Text className={styles.status_description}>
              Payment has been completed successfully
            </Card.Text>
          </>
        ) : props.paymentStatus === 'pending' ? (
          <>
            <Loader
              className='bgvLoader'
              as='div'
              animation='border'
              size='md'
              role='status'
              aria-hidden='true'
            />
            <Card.Title className={styles.payment_title}>Processing Payment</Card.Title>
          </>
        ) : (
          <>
            <Image isSA={props.isSA} name={'PaymentFail.svg'} />
            <Card.Title className={`text-danger ${styles.payment_title}`}>
              Payment failed
            </Card.Title>
          </>
        )}
        {props.orderId && (
          <Card.Text className='text-muted'>Transaction#:{props.orderId}</Card.Text>
        )}
        <Container className={styles.btnContainer}>
          {props.paymentStatus === 'completed' ? props.buttons : ''}
          {props.paymentStatus === 'failed' && (
            <Button variant='primary' onClick={() => retryPayment()}>
              {'Retry'}
            </Button>
          )}
          {props?.isAdcFlow ? (
            <Card.Body>
              Please don't refresh.{' '}
              <p>
                Redirecting to{' '}
                <Link to='/approval' onClick={() => props?.hideModal()}>
                  Additional Cost Review
                </Link>{' '}
                in {timeLeft}s
              </p>
            </Card.Body>
          ) : (
            <Card.Body>
              Please don't refresh.{' '}
              <p>
                Redirecting to{' '}
                <Link to='/buy' onClick={() => props?.hideModal()}>
                  Buy Page
                </Link>{' '}
                in {timeLeft}s
              </p>
            </Card.Body>
          )}
        </Container>
        <Card.Text className={styles.help}>
          <small className={`text-muted`}>
            Need help ?{' '}
            <Link onClick={() => window.open('mailto:cs@springverify.com', '_blank')}>
              Contact us
            </Link>
          </small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
