import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import styles from './DebitDetail.module.scss';
import { convertToIndianNumeration } from '../../../../../../../utils/utilities';

function DebitDetail(props) {
  const { transaction } = props;

  return (
    <div>
      <Row>
        <Col lg={8} md={8} sm={8} xs={8} className={styles.detailsContainer}>
          {transaction?.tag === 'PACKAGE_BOUGHT' ? (
            <p className={styles.packageAddedText}>
              {transaction?.numberOfPackageUnits} units of {transaction?.packageName} package
            </p>
          ) : null}

          {transaction?.tag === 'ADDON_BOUGHT' ? (
            <p className={styles.addonText}>
              Add-ons for{' '}
              <Link to={`/viewCandidate?candidateId=${transaction?.candidateId}`}>
                {transaction?.candidateName}
              </Link>
            </p>
          ) : null}

          {transaction?.userName ? (
            <p className={styles.addedRemovedBy}>{`Added by ${transaction?.userName !== 'SA' ? transaction?.userName : 'SpringVerify'
              }`}</p>
          ) : null}

          {transaction?.tag === 'ADDITIONAL_CHARGE' ? (
            <p className={styles.additionalCharges}>
              Additional charges
              {transaction?.candidateName && transaction?.candidateId && (
                <Link to={`/viewCandidate?candidateId=${transaction?.candidateId}`}>
                  for {transaction?.candidateName}
                </Link>
              )}
            </p>
          ) : null}

          {transaction?.transactionId ? (
            <p className={styles.transactionId}>{`Transaction #${transaction?.transactionId}`}</p>
          ) : null}
        </Col>

        <Col lg={4} md={4} sm={4} xs={4} className={styles.priceContainer}>
          <p className={styles.date}>{moment(transaction?.createdAt).format('DD MMM YYYY')}</p>
          <p className={`${styles.amount} ${styles.amountDebit}`}>
            {'- '}
            <span className={styles.rupee}>&#8377;</span>
            {transaction?.creditsAmount < 0
              ? convertToIndianNumeration(-1 * transaction?.creditsAmount)
              : convertToIndianNumeration(transaction?.creditsAmount)}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default DebitDetail;
