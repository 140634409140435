import React from 'react';
import { useReducer, useEffect, useState } from 'react';
import { Table, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';

import Header from '../../Header';
import Footer from '../../Footer';
import Button from '../../../../core-components/Button';
import Image from '../../../../core-components/Image';
import Loader from '../../../../core-components/Loader';
import Pagination from './Components/Pagination';
import styles from './ManageApiToken.module.scss';
import ActionItem from './Components/ActionItem';
import DataReducer from './Reducers/DataReducer';
import ShowModalReducer from './Reducers/ShowModalReducer';
import EditTokenNameModal from './Modals/EditTokenNameModal/EditTokenNameModal';
import { copyToClipboard, errToastMessage } from '../../../../utils/utilities';
import DeleteTokenModal from './Modals/DeleteTokenModal/DeleteTokenModal';
import { getExternalApiTokenList } from '../../../../api/company';
import GenerateJwtTokenModal from './Modals/GenerateJwtTokenModal/GenerateJwtTokenModal';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;
const DATA_REDUCER = DataReducer;
const SHOW_MODAL_REDUCER = ShowModalReducer;

/* Set updateData true when some token is deleted or edited */
const initialDataState = {
  loading: true,
  data: { count: 0, rows: [] },
  limit: DEFAULT_PAGE_SIZE,
  offset: DEFAULT_PAGE_NUMBER
};

/* Toggle this boolean values to open/close the modals */
const initialModalStates = {
  showGenTokenModal: false,
  showEditModal: false,
  showDeleteModal: false
};

/* Error message generator used in Edit/Generate Modals */
const getErrorMessageForInvalidTokenName = (value) => {
  const EMPTY_MESSAGE = 'Token name cannot be empty';
  const INVALID_MESSAGE = 'Token name is not in valid format';

  const length = value.length;

  if (length) {
    return INVALID_MESSAGE;
  }

  return EMPTY_MESSAGE;
};

/* Trim long token values to fit in a single line */
const getTrimmedTokenValue = (value) => {
  const newValue = `${value.substring(0, 30)}...`;
  return newValue;
};

export default (props) => {
  /* State variables */
  /* @selectedTokenDataIndex stores the currently selected row item data used in Edit/Delete modal*/

  const [dataState, dataDispatch] = useReducer(DATA_REDUCER, initialDataState);
  const [modalStates, modalStatesDispatch] = useReducer(SHOW_MODAL_REDUCER, initialModalStates);
  const [selectedTokenDataIndex, setSelectedTokenDataIndex] = useState('');

  const data = dataState.data.rows;

  /* API call on mount */
  useEffect(() => {
    getJwtTokenList();

    return () => {
      dataDispatch({ type: 'SET_DATA', value: {} });
    };
  }, []);

  const getJwtTokenList = (limit = DEFAULT_PAGE_SIZE, offset = DEFAULT_PAGE_NUMBER) => {
    dataDispatch({ type: 'SET_LOADING', value: true });

    let payload = { limit: limit, offset: offset };
    getExternalApiTokenList(payload)
      .then((resp) => {
        dataDispatch({ type: 'SET_DATA', value: resp.data.data });
        dataDispatch({ type: 'SET_LOADING', value: false });
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  /* Onclick Handlers */
  const generateTokenHandler = () => {
    modalStatesDispatch({ type: 'TOGGLE_GEN_TOKEN_MODAL' });
  };

  /* Copy token name to clipboard */
  const copyToClipboardHandler = (dataIndex) => {
    copyToClipboard(data[dataIndex].token);
  };

  const openEditModalHandler = (dataIndex) => {
    setSelectedTokenDataIndex(dataIndex);
    modalStatesDispatch({ type: 'TOGGLE_EDIT_MODAL' });
  };

  const openDeleteModalHandler = (dataIndex) => {
    setSelectedTokenDataIndex(dataIndex);
    modalStatesDispatch({ type: 'TOGGLE_DELETE_MODAL' });
  };

  /* Handles pagination */
  const paginate = (pageSize = DEFAULT_PAGE_SIZE, pageNumber = DEFAULT_PAGE_NUMBER) => {
    /* Get the updates list data */
    getJwtTokenList(pageSize, pageNumber);
  };

  /* UI components */
  const getLoader = () => {
    return (
      <div>
        <Loader />
      </div>
    );
  };

  /* Copy, Edit and Delete icons */
  const getActionElements = (index) => {
    /* Opens respective modal onClick() */
    /* @data: respective token data  item*/
    return (
      <div className={styles.icons}>
        <ActionItem
          onClickHandler={copyToClipboardHandler}
          id={'copyIcon'}
          tooltipText={'Copy'}
          dataIndex={index}
        />
        <ActionItem
          onClickHandler={openEditModalHandler}
          id={'editIcon'}
          tooltipText={'Edit'}
          dataIndex={index}
        />
        <ActionItem
          onClickHandler={openDeleteModalHandler}
          id={'deleteIcon'}
          tooltipText={'Delete'}
          dataIndex={index}
        />
      </div>
    );
  };

  /* Show when token data list is empty */
  const getEmptyPageContents = () => {
    return (
      <div className={styles.noDataWrapper}>
        <Image name={'NoData.svg'} />
        <div className={styles.noDataContent}>{'No API token found!'}</div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.parentDivWrapper}>
        <>
          <div className={styles.tabHeader}>
            Generate an API key through the SpringVerify portal.
            <div className={styles.knowMore}>
              <a
                href='https://support.springworks.in/portal/en/kb/articles/springverify-india-api-documentation'
                target='_blank'
              >
                {'Know more'}
              </a>
            </div>
          </div>
          <Card className={styles.bodyCard}>
            <div className={styles.header}>
              <div className={styles.heading}>{'Generate Token'}</div>
              <Button className={styles.generateTokenButton} onClick={generateTokenHandler}>
                {'+ Generate token'}
              </Button>
            </div>
            <Card className={styles.tableCard}>
              <Table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th className={styles.subHeader}>#</th>
                    <th className={styles.subHeader}>Created on</th>
                    <th className={styles.subHeaderLeftAligned}>Name</th>
                    <th className={styles.subHeader}>API Token</th>
                    <th className={styles.subHeader}>Action</th>
                  </tr>
                </thead>
                {data?.length > 0 && !dataState.loading ? (
                  <tbody>
                    {data &&
                      data.map((rowData, i) => {
                        return (
                          <tr key={i}>
                            <td>{dataState?.offset * 10 + (i + 1)}</td>
                            <td>{moment(rowData?.created_at).format('DD-MM-YYYY')}</td>
                            <td className={styles.subHeaderLeftAligned}>{rowData?.name}</td>
                            <td>{getTrimmedTokenValue(rowData?.token)}</td>
                            <td>{getActionElements(i)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={7}>
                        {dataState.loading ? getLoader() : getEmptyPageContents()}
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {!dataState.loading ? (
                <Pagination
                  dispatch={dataDispatch}
                  offset={dataState.offset}
                  limit={dataState.limit}
                  count={dataState.data.count}
                  paginate={paginate}
                />
              ) : null}
            </Card>
          </Card>
        </>
      </div>
      {modalStates.showEditModal ? (
        <EditTokenNameModal
          dispatch={modalStatesDispatch}
          show={modalStates.showEditModal}
          index={selectedTokenDataIndex}
          data={dataState?.data?.rows}
          offset={dataState.offset}
          limit={dataState.limit}
          dataDispatch={dataDispatch}
          getJwtTokenList={getJwtTokenList}
          getErrorMessageForInvalidTokenName={getErrorMessageForInvalidTokenName}
        />
      ) : null}

      <DeleteTokenModal
        dispatch={modalStatesDispatch}
        show={modalStates.showDeleteModal}
        index={selectedTokenDataIndex}
        data={dataState?.data?.rows}
        offset={dataState.offset}
        limit={dataState.limit}
        dataDispatch={dataDispatch}
        getJwtTokenList={getJwtTokenList}
      />
      {modalStates.showGenTokenModal ? (
        <GenerateJwtTokenModal
          dispatch={modalStatesDispatch}
          dataDispatch={dataDispatch}
          show={modalStates.showGenTokenModal}
          getJwtTokenList={getJwtTokenList}
          getErrorMessageForInvalidTokenName={getErrorMessageForInvalidTokenName}
        />
      ) : null}
    </div>
  );
};
