import React from 'react';
import {
  faPrint,
  faExternalLinkAlt,
  faReply,
  faEllipsisV
} from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import Image from '../../../../../core-components/Image';
import moment from 'moment';
const _ = require('lodash');
import styles from './EmailPreview.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {
  const { emailContentData, profile } = props;

  return (
    <Card className={styles.emailPreviewContainer}>
      <div className={styles.headingTitle}>EMAIL PREVIEW</div>
      <div className={styles.headingContent}>
        {emailContentData?.hrWarningNoteStatus
          ? `OVERDUE - Complete your Background Check for ${
              emailContentData?.isBrandEnabled && emailContentData?.friendlyName
                ? emailContentData?.friendlyName
                : profile?.company_user_mapping?.company?.name
            } Immediately`
          : `IMPORTANT - ${
              emailContentData?.isBrandEnabled && emailContentData?.friendlyName
                ? emailContentData?.friendlyName
                : profile?.company_user_mapping?.company?.name
            }: Background Check Request `}
        <span>
          <FontAwesomeIcon icon={faPrint} className={styles.fontAwesomeIcon} />
          <FontAwesomeIcon icon={faExternalLinkAlt} className={styles.fontAwesomeIcon} />
        </span>
      </div>
      <div className={styles.emailHeader}>
        <Image name='sv_logo_desktop.svg' className={styles.svLogo} />
        <div>
          <div>SpringVerify India</div>
          <div className={styles.emailIcon}>
            {profile?.email}{' '}
            <img
              src='https://www.gstatic.com/images/icons/material/system_gm/1x/arrow_drop_down_black_20dp.png'
              alt=''
            />{' '}
          </div>
        </div>
        <div className={styles.emailIcons}>
          <div className={styles.emailIcon}>{moment().format('ddd, MMM DD, LT')}</div>
          <img
            className={styles.starIcon}
            src='https://ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/star_baseline_nv700_20dp.png'
            alt=''
          />
          <FontAwesomeIcon icon={faReply} className={styles.replyIcon} />
          <FontAwesomeIcon icon={faEllipsisV} className={styles.moreIcon} />
        </div>
      </div>
      <div className={styles.emailContentHeader}>
        <div className={styles.logoNameHeader}>
          <Image name='sv_logo_desktop.svg' />
          <Image name='SpringVerify.png' className={styles.springVerifyTextImg} />
        </div>
        {emailContentData?.logoUrl &&
        emailContentData?.logoEnabled &&
        emailContentData?.isImgLoaded &&
        emailContentData?.useLogoInInviteMail ? (
          <img className={styles.brandLogo} src={emailContentData?.logoUrl} alt='brandLogo' />
        ) : (
          <div>
            <p>{profile?.company_user_mapping?.company?.name}</p>
            <div className={styles.friendlyName}>
              {emailContentData?.isBrandEnabled && emailContentData?.friendlyName}
            </div>
          </div>
        )}
      </div>
      <div className={styles.emailContentBody}>
        {emailContentData?.hrWarningNoteStatus && (
          <div className={styles.overdueBanner}>BACKGROUND CHECK SUBMISSION OVERDUE</div>
        )}
        <div className={styles.emailPreviewContent}>
          <p>Dear {profile?.name},</p>
          <p>
            {profile?.company_user_mapping?.company?.name}{' '}
            {emailContentData?.friendlyName &&
              emailContentData?.isBrandEnabled &&
              `(${emailContentData?.friendlyName})`}{' '}
            has requested a background check for you. You are required to complete the document
            submission process by{' '}
            <span style={{ textDecoration: 'underline' }}>
              {moment().add(emailContentData?.formCompletionDays, 'days').format('dddd')},{' '}
              {moment().add(emailContentData?.formCompletionDays, 'days').format('DD-MMM-YYYY')}.
            </span>
          </p>

          {emailContentData?.hrInviteNoteStatus && (
            <>
              <p className={styles.hrNote}>Note from your HR Team:</p>
              <p>
                {emailContentData?.hrInviteNoteDefaultOrCustom == 1
                  ? emailContentData?.defaultHrInviteNote
                  : emailContentData?.hrInviteNote}
              </p>
            </>
          )}

          <p className={styles.hrWarningNote}>
            {emailContentData?.hrWarningNoteStatus &&
              (emailContentData?.hrWarningNoteDefaultOrCustom == 1
                ? emailContentData?.defaultHrWarningNote
                : emailContentData?.hrWarningNote)}
          </p>

          <div style={{ textAlign: 'center' }}>
            <p className={styles.button}>Start your background check now</p>
          </div>
        </div>
      </div>
      <div className={styles.emailContentFooter}>
        <p>
          It is recommended that you use Chrome to open the given link. In case you are facing any
          issue in submitting the form, please{' '}
          <span
            className={styles.contactUs}
            onClick={() => window.open('mailto:cs@springverify.com', '_blank')}
          >
            contact us
          </span>
          .
        </p>
        <div>Regards,</div>
        <div>SpringVerify BGV Team</div>
      </div>
    </Card>
  );
};
