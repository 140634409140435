import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  invalidateInsuffCredits,
  getInsuffCredits as getInsuffCreditsAPI,
  invalidateCreditTransactionHistory,
  getCreditTransactionHistory as getCreditTransactionHistoryAPI,
  getPurchaseOrders as getPurchaseOrderAPI,
  invalidatePurchaseOrders as invalidatePurchaseOrdersAPI,
  getCompanyPaymentDetails
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    creditTransactionHistory:
      state.creditTransactionHistory && !isEmpty(state.creditTransactionHistory)
        ? state.creditTransactionHistory
        : null,
    insuffCredits:
      state.insuffCredits && !isEmpty(state.insuffCredits) ? state.insuffCredits : null,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    packageType:
      state?.profile?.profile?.company_user_mapping?.company?.payments_company_model_config
        ?.model_type,
    pendingOrders: state?.purchaseOrders ? state?.purchaseOrders : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCreditTransactionHistory: () => dispatch(invalidateCreditTransactionHistory()),
    getCreditTransactionHistory: (data) => dispatch(getCreditTransactionHistoryAPI(data)),
    invalidateInsuffCredits: () => dispatch(invalidateInsuffCredits()),
    getPendingOrders: () => dispatch(getPurchaseOrderAPI()),
    getInsuffCredits: (data) => dispatch(getInsuffCreditsAPI(data)),
    invalidatePurchaseOrders: () => dispatch(invalidatePurchaseOrdersAPI()),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
