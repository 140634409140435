import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { FOR_WHITE_LISTED_DOMAIN } from '../core-components/Utilities/utilities';

const validationSchema = Yup.object().shape({
  autoLogoutToogle: Yup.boolean(),
  autoLogout: Yup.mixed().when('autoLogoutToogle', {
    is: (trued) => trued == true,
    then: Yup.number()
      .typeError('Provide a valid input')
      .min(1, 'Enter a number between 1 to 48')
      .max(48, 'Enter a number between 1 to 48'),
    otherwise: Yup.mixed().optional()
  }),
  passwordExpiryToogle: Yup.boolean(),
  passwordExpiry: Yup.mixed().when('passwordExpiryToogle', {
    is: (trued) => trued == true,
    then: Yup.number()
      .typeError('Provide a valid input')
      .min(15, 'Enter a number between 15 to 60')
      .max(60, 'Enter a number between 15 to 60')
  }),
  domainWhitelistToogle: Yup.boolean(),
  domainWhitelist: Yup.array().when('domainWhitelistToogle', {
    is: true,
    then: Yup.array()
      .of(Yup.string().matches(FOR_WHITE_LISTED_DOMAIN, 'Invalid domain'))
      .test({
        message: 'Maximum number of domains reached',
        test: (arr) => arr.length < 21
      })
  })
});

export default validationSchema;
