import React from 'react';

import moment from 'moment';
import { Row, Col } from 'react-bootstrap';

import styles from './CreditDetail.module.scss';
import { convertToIndianNumeration } from '../../../../../../../utils/utilities';

function CreditDetail(props) {
  const { transaction } = props,
    creditObj = {
      REFUND: 'Refund',
      FREE_CREDITS: 'Free credits',
      RECHARGE: 'Recharge'
    };

  const getText = (tag = '') => {
    if (['REFUND', 'FREE_CREDITS', 'RECHARGE'].includes(tag)) {
      return <span className={`${styles.tag} ${styles.activeTag}`}>{creditObj[tag]}</span>;
    }
    if (tag === 'VERIFICATION') {
      return <span className={`${styles.tag} ${styles.inactiveTag}`}>{'For verification'}</span>;
    }
    return null;
  };

  const getInfo = (transaction = null) => {
    if (transaction) {
      if (
        ['CREDIT', 'REFUND', 'FREE_CREDITS', 'RECHARGE', 'PACKAGE_BOUGHT'].includes(transaction.tag)
      ) {
        return (
          <>
            {transaction.userName ? (
              <p className={styles.addedRemovedBy}>{`Added by ${transaction.userName !== 'SA' ? transaction.userName : 'SpringVerify'
                }`}</p>
            ) : null}

            {transaction.note ? <p className={styles.note}>{transaction.note}</p> : null}
          </>
        );
      }

      if (transaction.tag === 'VERIFICATION') {
        return (
          <>
            {transaction.removedBy ? (
              <p className={styles.addedRemovedBy}>{`Removed by ${transaction.removedBy !== 'SA' ? transaction.removedBy : 'SpringVerify'
                }`}</p>
            ) : null}

            {transaction.note ? <p className={styles.note}>{transaction.note}</p> : null}
          </>
        );
      }
    }
    return null;
  };
  return (
    <div>
      <Row>
        <Col lg={8} md={8} sm={8} xs={8} className={styles.detailsContainer}>
          <p>
            <span>{'Credits'}</span>
            {transaction?.tag !== 'CREDIT' ? getText(transaction.tag) : null}
          </p>

          {getInfo(transaction)}
        </Col>

        <Col lg={4} md={4} sm={4} xs={4} className={styles.priceContainer}>
          <p className={styles.date}>
            {transaction.tag === 'VERIFICATION'
              ? moment(transaction.removedAt).format('DD MMM YYYY')
              : moment(transaction.createdAt).format('DD MMM YYYY')}
          </p>
          <p
            className={`${styles.amount} ${transaction.tag === 'VERIFICATION' ? styles.amountDebit : styles.amountCredit
              }`}
          >
            {transaction.tag === 'VERIFICATION' ? '-' : '+'}{' '}
            <span className={styles.rupee}>&#8377;</span>
            {convertToIndianNumeration(transaction.creditsAmount)}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default CreditDetail;
