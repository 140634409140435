import React from 'react';
import { Button, Card } from 'react-bootstrap';
import styles from '../IntegrationMain.module.scss';
import Image from '../../../core-components/Image';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const innerHtml = (html) => {
  return { __html: html };
};

const IntegrationCard = (props) => {
  const {
    integrationKey,
    cardDetails,
    comingSoonIntegration = false,
    integrationCardView,
    integrationData,
    history
  } = props;

  const buttonClickHandler = () => {
    history.push(`/integrations/${cardDetails.id}`);
  };

  return (
    <Card className={integrationCardView.cardView ? styles.gridHeight : styles.listHeight}>
      <Card.Body
        className={integrationCardView.cardView ? styles.gridViewInner : styles.listViewInner}
      >
        <div className={styles.cardBody}>
          <div className={styles.cardHeaderContainer}>
            <div className={styles.cardImage}>
              {/* check if connected as well */}
              {integrationData?.integrationId && integrationKey.toLowerCase() == 'repute' ? (
                <img
                  src={cardDetails?.integrationData?.hrmsLogoUrl || cardDetails?.icon}
                  alt={cardDetails?.title}
                />
              ) : (
                <Image name={cardDetails?.icon} />
              )}
            </div>
            <div className={styles.titleContainer}>
              <div className={styles.titleBox}>
                <span className={styles.titleText}>
                  {integrationData?.integrationId && integrationKey.toLowerCase() == 'repute'
                    ? cardDetails?.integrationData?.hrmsName || cardDetails?.title
                    : cardDetails?.title}
                </span>
                {comingSoonIntegration && (
                  <div className={cn(styles.comingSoonTitle, styles.comingSoonPill)}>
                    Coming soon
                  </div>
                )}
                {integrationData && (
                  <div className={cn(styles.connectedTitle, styles.connectedPill)}>
                    <AiOutlineCheckCircle size={17} className={styles.icon} />
                    Connected
                  </div>
                )}
              </div>
              <span className={styles.subtitleText}>
                {cardDetails?.type} {cardDetails?.type ? '|' : ''} {cardDetails?.subtitle}
              </span>
            </div>
          </div>
          <div
            className={cn(styles.cardDetails, styles.details)}
            dangerouslySetInnerHTML={innerHtml(cardDetails?.details)}
          ></div>
        </div>
        <div className={styles.cardAction}>
          {!comingSoonIntegration && (
            <Button
              onClick={buttonClickHandler}
              className={cn(styles.actionButton, integrationData ? styles.outline : styles.primary)}
              variant={integrationData ? 'outline-primary' : 'primary'}
            >
              {integrationData ? 'Configure' : 'Connect'}
            </Button>
          )}
        </div>
        {integrationData && (
          <div className={cn(styles.connected, styles.connectedPill)}>
            <AiOutlineCheckCircle size={17} className={styles.icon} />
            <span>Connected</span>
          </div>
        )}
        {comingSoonIntegration && (
          <div className={cn(styles.comingSoon, styles.comingSoonPill)}>Coming soon</div>
        )}
      </Card.Body>
    </Card>
  );
};

IntegrationCard.propTypes = {
  cardDetails: PropTypes.shape({
    comingSoon: PropTypes.bool,
    details: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  comingSoonIntegration: PropTypes.bool,
  integrationKey: PropTypes.string,
  integrationCardView: PropTypes.shape({
    cardView: PropTypes.bool,
    listView: PropTypes.bool
  }),
  integrationData: PropTypes.shape({
    integration_id: PropTypes.string,
    sync_job_id: PropTypes.string,
    schedule_job_id: PropTypes.string,
    stage_details: PropTypes.any | null,
    auth_failure: PropTypes.bool,
    token: PropTypes.string,
    webhook_enabled: PropTypes.bool
  }),
  history: PropTypes.any
};

export default IntegrationCard;
