import React, { useEffect, useState } from 'react';

import { Row, Container, Col } from 'react-bootstrap';

import Image from '../../../core-components/Image';
import { jwtParser } from '../../../utils/utilities';

import styles from './BgvHeader.module.scss';
import useWindowSize from '../../../utils/useWindowSize';
import { Helmet } from 'react-helmet';

export default (props) => {
  const token = new URLSearchParams(document.location.search).get('token') || null;
  const parsedJwtDetail = jwtParser(token);
  const windowSize = useWindowSize();

  const zoho = window?.$zoho;

  if (zoho) {
    zoho.salesiq?.visitor?.name(parsedJwtDetail?.data?.companyName);
    zoho.salesiq?.visitor?.email(parsedJwtDetail?.data?.email);
    if (windowSize?.width <= 600) {
      zoho.salesiq?.floatbutton?.visible('hide');
    } else {
      zoho.salesiq?.floatbutton?.visible('show');
    }
  }

  useEffect(() => {
    return () => {
      if (zoho) zoho.salesiq?.floatbutton?.visible('show');
    };
  }, []);

  const handleIframeDomChange = () => {
    const myframe = document.getElementById('siqiframe');
    if (myframe) {
      const body = myframe?.contentWindow?.document?.body;
      const elements = body?.querySelectorAll('#faqbox');
      if (elements?.length) {
        setTimeout(() => {
          const catEle = body?.querySelectorAll('#faqdiv');
          const catList = catEle[0]?.querySelectorAll('.siq-categ-list');
          if (catList?.length)
            catList.forEach((child) => {
              var textContent = child.textContent;
              if (textContent.includes('Client Knowledge')) {
                child.style.display = 'none';
              }
            });
        }, 0);
      }
    }
  };

  useEffect(() => {
    const checkForIframe = () => {
      const iframe = document.getElementById('siqiframe');
      if (iframe) {
        const observer = new MutationObserver(handleIframeDomChange);
        const observerConfig = { subtree: true, childList: true };
        observer.observe(iframe.contentDocument.documentElement, observerConfig);
      } else {
        setTimeout(checkForIframe, 1000);
      }
    };
    const currentUrl = window.location.href;
    if (currentUrl.includes('candidate/bgv?token')) checkForIframe();
  }, []);

  return (
    <>
      <Helmet>
        <style>{`
         @media only screen and (max-width: 600px) {
            .zsiq_float,
            .cky-btn-revisit-wrapper {
              visibility: hidden !important;
            }
          }
        `}</style>
      </Helmet>
      <div className={styles.bgvHeaderContainer}>
        <Container>
          <Row className={styles.bgvHeader}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={styles.bgvHeaderItem}>
                <div className={styles.headerItemLeft}>
                  <Image className={styles.logoImg} name='sv_logo_desktop.svg' />
                  <span className={styles.companyName}>
                    BGV for
                    {' ' + parsedJwtDetail?.data?.companyName}
                  </span>
                </div>
                <button className={styles.sidebarToggle} onClick={props?.openSidebar}>
                  <Image name='hamburgerIcon.svg' />
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
