import React from 'react';
import { Accordion, Badge, Col, Row, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './Wallet.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import {
  indianNumerationValue,
  convertToIndianNumeration
} from '../../core-components/Utilities/utilities';

import cn from 'classnames';
import Image from '../../core-components/Image/Image';

function TopSection(props) {
  const { walletDetail } = props;

  const getTextColor = () => {
    if (
      walletDetail.currentBalance > walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
      walletDetail.currentBalance > walletDetail.averageAdc
    ) {
      return '';
    } else if (
      walletDetail.currentBalance > walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
      walletDetail.currentBalance < walletDetail.averageAdc
    ) {
      return styles.criticalLowColorText;
    } else if (
      walletDetail.currentBalance <
      walletDetail.arrearsTotalAmount + walletDetail.pendingCosts
    ) {
      return styles.lowBalanceColorText;
    }
  };

  //For wallet summary page
  const renderWalletTag = () => {
    if (
      walletDetail.currentBalance > walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
      walletDetail.currentBalance > walletDetail.averageAdc
    ) {
      return '';
    } else if (
      walletDetail.currentBalance < walletDetail.arrearsTotalAmount + walletDetail.pendingCosts ||
      walletDetail.currentBalance === 0
    ) {
      return (
        <Badge pill className={styles.criticalPill}>
          Critical balance
        </Badge>
      );
    } else if (
      walletDetail.currentBalance >= walletDetail.arrearsTotalAmount + walletDetail.pendingCosts &&
      walletDetail.currentBalance < walletDetail.averageAdc
    ) {
      return (
        <Badge pill className={styles.lowPill}>
          Low balance
        </Badge>
      );
    }
  };
  return (
    <Card className={styles.arrearsCardSubContainer}>
      <Row className={styles.arrearsRowContainer}>
        <Col>
          <div className={`mb-2 ${styles.row}`}>
            <div className={styles.heading}>Current Balance</div>
            {walletDetail?.lockedCompanyCredits && (
              <OverlayTrigger
                placement='bottom-start'
                overlay={
                  <Tooltip>
                    <div className={styles.tooltipLockedAmount}>
                      <div className={styles.locketText}>
                        Credits Locked : <span className={styles.rupeeSym}>&#8377;</span>
                        {convertToIndianNumeration(walletDetail.lockedCompanyCredits || 0)}
                      </div>
                      <div>
                        Some of your credits are unavailable as they are locked against pending
                        orders that are awaiting payment. If the orders is cancelled/ expires, then
                        the credits are returned to your wallet.
                      </div>
                    </div>
                  </Tooltip>
                }
              >
                <div>
                  <Image name='lockKeyhole.png' className={styles.tooltipLockIcon} />
                </div>
              </OverlayTrigger>
            )}
          </div>
          <div className={styles.row}>
            <span className={cn(styles.amount, styles.currentBalanceAmount, getTextColor())}>
              &#8377;
              {convertToIndianNumeration(
                walletDetail.currentBalance < 0 ? 0 : walletDetail.currentBalance
              )}
            </span>
            {props.id == 'summary' && renderWalletTag()}
          </div>
        </Col>
        {walletDetail.arrearsTotalAmount ? (
          <Col>
            <div style={{ display: 'flex' }} className='mb-2'>
              <div className={styles.heading}>Arrears</div>
              <OverlayTrigger
                placement='bottom-start'
                overlay={
                  <Tooltip className={styles.tooltip}>
                    <p> These are the overdue payments meant to be paid for the past purchases</p>
                  </Tooltip>
                }
              >
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} className={styles.tooltipIcon} />
                </div>
              </OverlayTrigger>
            </div>
            <div style={{ display: 'flex' }} className={styles.accordionContainerHeader}>
              <span className={styles.amount}>
                {indianNumerationValue(walletDetail.arrearsTotalAmount)}
              </span>
              <Accordion.Toggle as={'span'} eventKey='0' onClick={() => {}}>
                <span className={styles.viewBreakDown}>View</span>
              </Accordion.Toggle>
            </div>
          </Col>
        ) : null}
        <Col>
          {walletDetail.pendingCosts ? (
            <>
              <Row className='mb-2'>
                <div className={styles.heading}>Unapproved Costs</div>
                <OverlayTrigger
                  placement='bottom-start'
                  overlay={
                    <Tooltip className={styles.tooltip}>
                      <p>
                        These are pending additional costs that are leading to delays in background
                        verification of your candidates
                      </p>
                    </Tooltip>
                  }
                >
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} className={styles.tooltipIcon} />
                  </div>
                </OverlayTrigger>
              </Row>
              <Row>
                <span className={styles.amount}>
                  {indianNumerationValue(walletDetail.pendingCosts)}
                </span>
              </Row>
            </>
          ) : null}
        </Col>
        {/* Added this condition for fulfilling design */}
        {walletDetail.arrearsTotalAmount ? null : <Col></Col>}
      </Row>
    </Card>
  );
}

export default React.memo(TopSection);
