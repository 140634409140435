import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { mergeWith } from 'lodash';

import { errToastMessage } from '../../../utils/utilities';

import {
  getCategories,
  INVALIDATE_CATEGORIES,
  addCategory,
  editCategory,
  deleteCategory,
  deleteAdministrators,
  deleteCandidate,
  getTags,
  addTag,
  editTag,
  deleteTag,
  INVALIDATE_TAGS,
  getCompanyPaymentDetails
} from '../../../actions/company';

import validationSchema from './CategoryModal.validation';
import {
  resendVerificationLink as resendVerificationLinkAPI,
  deleteCandidate as deleteCandidateAPI
} from '../../../api/company';

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    tags: state.tags
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (limit, offset) => {
      return dispatch(getCategories(limit, offset));
    },
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    addCategory: (data) => dispatch(addCategory(data)),
    editCategory: (data) => dispatch(editCategory(data)),
    deleteCategory: (id) => dispatch(deleteCategory(id)),
    deleteAdministrators: (id) => dispatch(deleteAdministrators(id)),
    deleteCandidate: (id) => dispatch(deleteCandidate(id)),
    invalidateTags: () => dispatch({ type: INVALIDATE_TAGS }),
    getTags: (limit, offset) => {
      return dispatch(getTags(limit, offset));
    },
    addTag: (data) => dispatch(addTag(data)),
    editTag: (data) => dispatch(editTag(data)),
    deleteTag: (id) => dispatch(deleteTag(id)),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

//Handling CRUD opetaion of tag and category
const handleTagCategoryOperation = (props, formData, setSubmitting, resetForm, setStatus) => {
  switch (props?.title) {
    case 'Edit Category':
      formData = Object.assign({}, { name: formData.category, id: formData.categoryId });
      props
        ?.editCategory(formData)
        .then(() => {
          props?.onHide('reload category');
          resetForm();
          setSubmitting(false);
          setStatus(true);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
      break;
    case 'Edit Tag':
      props
        ?.editTag(formData)
        .then(() => {
          props?.onHide('reload tag');
          resetForm();
          setSubmitting(false);
          setStatus(true);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
      break;
    case 'Create Category':
      formData = Object.assign({}, { name: formData.category });
      props
        ?.addCategory(formData)
        .then(() => {
          props?.onHide('reload category');
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
      break;
    case 'Create Tag':
      props
        ?.addTag(formData)
        .then(() => {
          props?.onHide('reload tag');
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
      break;
    case 'Delete Category':
      props
        ?.deleteCategory(props.categoryId)
        .then(() => {
          props?.onHide('reload category');
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
      break;
    case 'Delete Tag':
      props
        ?.deleteTag(props?.categoryId)
        .then(() => {
          props?.onHide('reload tag');
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage(error);
        });
      break;
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let initialValue = {
        category: '',
        modalTile: props?.title // this variable to hanlde validation for category fiel in validation file
      };
      return mergeWith({}, initialValue, props?.category, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, dirty, props }
    ) => {
      if (
        props?.button === 'Save' &&
        (props?.title === 'Edit Category' || props?.title === 'Edit Tag')
      ) {
        const formData = values;
        formData.categoryId = props?.categoryId;
        handleTagCategoryOperation(props, formData, setSubmitting, resetForm, setStatus);
      } else if (
        values.category !== ' ' &&
        (props?.title === 'Create Category' || props?.title === 'Create Tag')
      ) {
        handleTagCategoryOperation(props, values, setSubmitting, resetForm, setStatus);
      } else if (props?.title?.match('Admin')) {
        props
          ?.deleteAdministrators(props.adminId)
          .then(() => {
            props.onHide('reload');
          })
          .catch((error) => {
            errToastMessage(error);
            setSubmitting(false);
          });
      } else if (props?.title === 'Delete Category' || props?.title === 'Delete Tag') {
        handleTagCategoryOperation(props, values, setSubmitting, resetForm, setStatus);
      } else if (
        props.toDelete &&
        props.toDelete.isDelete &&
        props?.title === 'Delete Candidate '
      ) {
        deleteCandidateAPI(props.candidateDetails.id)
          .then((response) => {
            setSubmitting(false);
            props.toDelete.isDelete = false;
            props.history.push('/candidates', 'deleted');
          })
          .catch((error) => {
            setSubmitting(false);
            props.toDelete.isDelete = false;
            errToastMessage(error);
          });
      } else if (props?.button === 'Send') {
        resendVerificationLinkAPI({
          candidateId: props.candidate.companyCandidateMapping.candidateId.toString(),
          companyId: props.candidate.companyCandidateMapping.companyId.toString(),
          blockMail: false
        })
          .then((response) => {
            props?.onHide('emailSent');
            resetForm();
            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Add Category'
  })
);
