import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

const mapStateToProps = (state) => {
  return {
    profile: state.profile || null
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, null);
