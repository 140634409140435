// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yZ0Pu_Tkp1S1w2GQcaSO {
  color: #2755FE;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/CustomSpinner/CustomSpinner.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ","sourcesContent":[".bgvLoader {\n    color: #2755FE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgvLoader": `yZ0Pu_Tkp1S1w2GQcaSO`
};
export default ___CSS_LOADER_EXPORT___;
