import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Table } from 'react-bootstrap';
import Input from '../../../../core-components/Input/Input';
import styles from './ReferAndEarnTable.module.scss';
import { debounce } from 'lodash';
import moment from 'moment';
import Pagination from '../../../../core-components/Pagination/Pagination';
import Image from '../../../../core-components/Image/Image';
import { getTrimmedValue } from '../../../../utils/utilities';
import EmptyTableBody from '../../../../core-components/EmptyTableBody/EmptyTableBody';
import { getStatusRow } from '../ReferAndEarnUtils';
import ContentLoader from 'react-content-loader';

export default function ReferAndEarnTable(props) {
  const { referralData, referralDataCount } = props;
  const [searchTerm, setIsSearchTerm] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;

  do {
    const value = pageSizes[index] > referralDataCount ? referralDataCount : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < referralDataCount);

  useEffect(() => {
    fetchReferralList({
      limit,
      offset,
      name: getTrimmedValue(searchTerm, true) || null
    });
  }, [offset, limit]);

  const fetchReferralList = (
    where = {
      limit: limit,
      offset: offset,
      name: null
    }
  ) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    props?.getReferrals(where);
  };

  const applySearch = debounce((value) => {
    fetchReferralList({
      limit,
      offset,
      name: getTrimmedValue(value, true) || null
    });
    return;
  }, 500);

  const debounceApplySearch = useCallback((e) => {
    setIsSearchTerm(e.target.value);
    return applySearch(getTrimmedValue(e.target.value));
  }, []);

  const getLinkedCol = (url) => {
    if (!url || !url.includes('.com/')) {
      return '-';
    }
    return `linkedin.com/${url.split('.com/')[1]}`;
  };
  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };

  return (
    <div>
      <Row className={styles.row}>
        <div className={styles.title}>Referral history ({referralDataCount})</div>
        <div className={styles.searchInput}>
          <Image style={{ width: '20px' }} name='searchInputIcon.svg' />
          <Input
            placeholder={'Search Name'}
            className={styles.inputText}
            value={searchTerm}
            onBlur={(e) => {
              setIsSearchTerm(getTrimmedValue(e.target.value));
            }}
            onChange={debounceApplySearch}
          />
          {searchTerm ? (
            <Image
              onClick={() => {
                fetchReferralList({
                  limit,
                  offset,
                  name: null
                });
                setIsSearchTerm('');
              }}
              style={{ width: '20px' }}
              name='closeIcon.svg'
            />
          ) : null}
        </div>
      </Row>

      <Table className={styles.referralTable}>
        <thead>
          <tr>
            <th style={{ width: '120px' }}>Date</th>
            <th>HR name</th>
            <th className=''>HR Contact info</th>
            <th className=''>HR LinkedIn</th>
            <th>Status</th>
            <th className=''>Reward</th>
          </tr>
        </thead>
        {referralDataCount > 0 ? (
          <tbody>
            {loading
              ? Array.apply(null, { length: pageSize })
                .map(Number.call, Number)
                .map((loadData, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <ContentLoader height={20} width={60}>
                          <rect x='0' y='0' width='60' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={60}>
                          <rect x='0' y='0' width='60' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={60}>
                          <rect x='0' y='0' width='60' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={60}>
                          <rect x='0' y='0' width='60' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={60}>
                          <rect x='0' y='0' width='60' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={60}>
                          <rect x='0' y='0' width='60' height='14' />
                        </ContentLoader>
                      </td>
                    </tr>
                  );
                })
              : referralData.map((val) => {
                return (
                  <tr>
                    <td style={{ width: '120px' }}>
                      {moment(val?.createdAt).utc().format('DD-MM-YYYY')}
                    </td>
                    <td className={styles.wrapCell}>{val?.name}</td>
                    <td>
                      <>
                        <div>{val?.phoneNumber}</div>
                        <div className={styles.wrapCell25}>{val?.emailId}</div>
                      </>
                    </td>
                    <td className={styles.wrapCell}>{getLinkedCol(val?.linkedInUrl)}</td>
                    <td>{getStatusRow(val?.status)}</td>
                    <td>
                      {val.rewardCreditedOn
                        ? `Sent on ${moment(val?.rewardCreditedOn).utc().format('DD-MM-YYYY')}`
                        : '-'}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        ) : (
          <EmptyTableBody name='No Referral history found' />
        )}
      </Table>
      {referralDataCount > 0 && (
        <Form.Row className={styles.paginationWrapper}>
          <Col className={styles.showPagination}>
            Show{' '}
            <select
              data-testid="select-pagination"
              className={styles.selectPagination}
              value={limit}
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                setPageSize(parseInt(event.target.value));
                paginate(0, 0);
              }}
            >
              {pageSizeOptions}
            </select>{' '}
            items of {referralDataCount}
          </Col>
          <Col>
            <Pagination
              forcePage={pageNumber}
              onPageChange={({ selected }) => {
                paginate(selected, selected);
              }}
              pageCount={Math.ceil(referralDataCount / pageSize)}
            />
          </Col>
          <Col />
        </Form.Row>
      )}
    </div>
  );
}
