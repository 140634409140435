import React from 'react';
import styles from './AlumniVerification.module.scss';
import { Button } from 'react-bootstrap';
import AppConstants from '../../../../core-components/AppConstants';

const SVGComponent = (props) => (
  <svg
    width={213}
    height={5}
    viewBox='0 0 213 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M62 2C62 2 60.0452 6.17127 30.4999 2.99998C0.954571 -0.171299 0.999416 3.40979 0.999416 3.40979'
      stroke='#2755FE'
      strokeWidth={1.5}
      strokeLinecap='round'
    />
  </svg>
);

const AlumniVerification = () => {
  const onAddRecordClickHandler = () => {
    window.open(`${AppConstants.baseURL}alumni-verification`, '_self');
  };

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.rsContainer}>
        <div className={styles.svInIcon}>
          <img width={180} height={200} src={require('../../../../images/csv-data2.svg')} />
        </div>
        <div className={styles.lsContainer}>
          <div>
            {' '}
            Put ex-employee verification on auto-pilot.{' '}
            <span>
              Earn <span className='rupee'>&#8377;&#8377;&#8377;</span>
            </span>{' '}
            for every request
          </div>
          <div className={styles.wavyLine}>
            <SVGComponent />
          </div>
        </div>
        <div className={styles.walletIcon}>
          <img width={50} height={80} src={require('../../../../images/amount-wallet.svg')} />
        </div>
        <Button className={styles.addBtn} onClick={onAddRecordClickHandler}>
          Add record
        </Button>
      </div>
    </div>
  );
};

export default AlumniVerification;
