import * as Yup from 'yup';
import { getNameValidationSchema } from '../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getNameValidationSchema({
    message: 'Full name'
  }).when('isConsentForm', {
    is: true,
    then: getNameValidationSchema({
      message: 'Full name',
      isRequired: true
    })
  }),
  place: Yup.string().when('isConsentForm', {
    is: true,
    then: Yup.string().required('')
  })
});
