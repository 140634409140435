import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import styles from './CustomSwitch.module.scss';

const CustomSwitch = ({ field, form: { setFieldValue }, label, ...props }) => {
  const [checked, setChecked] = useState(props.defaultValue);
  return (
    <Form.Group
      style={{ marginBottom: '32px' }}
      controlId={field && field.name}
      className={props.className}
    >
      <label htmlFor='icon-switch' className={styles.switch}>
        {label}
        <Switch
          data-testid='switch'
          checked={checked}
          onChange={(event) => {
            setChecked(event);
            setFieldValue(field.name, event);
          }}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={'#2755FE'}
          className={'react-switch'}
          height={20}
          width={45}
        />
      </label>
    </Form.Group>
  );
};

export default CustomSwitch;
