import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty, has } from 'lodash';
import { Table, Col, Form } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';

import Image from '../../../../../core-components/Image';
import Pagination from '../../../../../core-components/Pagination';

import styles from './PurchaseHistory.module.scss';
import { convertToIndianNumeration } from '../../../../../utils/utilities';
import { TRANSACTION_TYPE } from '../../../../../utils/commonConstant';
import AppConstants from '../../../../../core-components/AppConstants';

export default (props) => {
  const pageSizes = [10, 25, 50, 100];

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [isData, setIsData] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props?.invalidatePurchaseHistory();
    props?.getPurchaseHistory(pageSize, pageNumber);
  }, []);

  useEffect(() => {
    if (!isEmpty(props.purchaseHistory) && has(props.purchaseHistory, 'rows')) {
      setData(props.purchaseHistory.rows);
      setCount(props.purchaseHistory.count);
      setLoading(props.purchaseHistory.loading);
      setIsData(props.purchaseHistory.count === 0 ? false : true);
    }
  }, [props.purchaseHistory]);

  const paginate = (limitValue, offsetValue) => {
    props?.getPurchaseHistory(limitValue, offsetValue);
  };

  const pageSizeOptions = [];
  let index = 0;
  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const isRowClickable = (rowData) => {
    return (
      rowData?.purchaseItem === 'RECHARGE' ||
      rowData?.purchaseItem === 'CREDIT_REFUND' ||
      rowData?.purchaseItem === 'PACKAGE_BOUGHT'
    );
  };

  const getRowStyle = (rowData) => {
    return isRowClickable(rowData) ? styles.mainField : styles.transactionRowDisable;
  };

  const getUser = (data) => {
    switch (data?.purchaseItem) {
      case 'RECHARGE':
        if (data?.paymentOrder?.requestedBy?.userId) {
          return data?.paymentOrder?.paidBy?.userId
            ? data?.paymentOrder?.paidBy?.isCa
              ? data?.paymentOrder?.paidBy?.userName
              : 'External User (via SV)'
            : 'External User';
        } else {
          return data?.paymentOrder?.paidBy?.userId
            ? data?.paymentOrder?.paidBy?.userName
            : data?.isCa
            ? data?.purchasedBy
            : 'Customer (via SV)';
        }
      default:
        return data?.isCa ? data?.purchasedBy : 'SpringVerify';
    }
  };
  const getStyleForDeletedUser = (data) => {
    return data?.paymentOrder?.paidBy?.deletedAt || data?.isUserDeleted ? styles.deletedUser : null;
  };
  return (
    <div className={styles.purchaseHistoryWrapper}>
      <div className={styles.purchaseHistoryCard}>
        <div className={styles.tableCard}>
          <div className={styles.tabInfoText}>Record of all your purchases.</div>
          <Table className={styles.purchaseHistoryTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>TXN Type</th>
                <th>User</th>
                <th className={styles.alignRight}>Amount</th>
                <th className={styles.alignRight}>Actions</th>
              </tr>
            </thead>
            {isData ? (
              <tbody>
                {loading
                  ? Array.apply(null, { length: pageSize })
                      .map(Number.call, Number)
                      .map((loadData, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <ContentLoader height={20} width={20}>
                                <rect x='0' y='0' width='20' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.mainField}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.amountLoaderCell}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })
                  : data &&
                    data.map((rowData, i) => {
                      return (
                        <tr
                          key={i}
                          className={
                            isRowClickable(rowData)
                              ? styles.pointerTypeCursor
                              : styles.pointerTypeRestrict
                          }
                          onClick={(e) => {
                            if (isRowClickable(rowData)) {
                              window.open(
                                `${AppConstants.baseURL}transactionDetails?id=${rowData?.transactionId}`,
                                '_blank'
                              );
                            }
                          }}
                        >
                          <td>
                            {moment(rowData?.purchaseDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                          </td>
                          <td className={getRowStyle(rowData)}>
                            <p>{TRANSACTION_TYPE[rowData?.purchaseItem]} </p>
                          </td>
                          <td
                            className={`${getRowStyle(rowData)} ${getStyleForDeletedUser(rowData)}`}
                          >
                            {getUser(rowData)}
                          </td>
                          <td className={styles.alignRight}>
                            <p className={styles.amount}>
                              &#8377;{convertToIndianNumeration(rowData?.credits)}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <div className={styles.noDataWrapper}>
                      <Image name={'noPurchaseHistory.svg'} />
                      <div className={styles.noDataContent}>{'No Purchase History'}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {isData ? (
            <Form.Row className={styles.paginationWrapper}>
              <Col className={styles.showPagination}>
                Show{' '}
                <select
                  className={styles.selectPagination}
                  value={pageSize}
                  onChange={(event) => {
                    setPageNumber(0);
                    setPageSize(parseInt(event.target.value));

                    paginate(event.target.value, 0);
                  }}
                >
                  {pageSizeOptions}
                </select>{' '}
                items of {count}
              </Col>
              <Col>
                <Pagination
                  forcePage={pageNumber}
                  onPageChange={({ selected }) => {
                    setPageNumber(selected);

                    paginate(pageSize, selected);
                  }}
                  pageCount={count / pageSize}
                />
              </Col>
              <Col />
            </Form.Row>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};
