import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { getIn } from 'formik';

import Input from '../Input';
import styles from './PasswordValidateFormInputField.module.scss';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default React.forwardRef(
  ({ field, form, label, regex, max, handleChange, fieldWithoutSpace, ...props }, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleMouseOver = () => {
      setShowTooltip(true);
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    const handleBlur = (event) => {
      let value = event.target.value;

      form.handleBlur(event);
      form.setFieldValue(field.name, value);
    };

    return (
      <Form.Group
        controlId={field && field.name}
        className={`${props.className} ${styles.wrapper} `}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <div
          className={`${isFocused
            ? form && getIn(form.errors, field.name) && getIn(form.touched, field.name)
              ? styles.error
              : styles.containerFocus
            : styles.container
            }`}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          <Input
            style={{ border: 'none', outline: 'none', boxShadow: 'none', borderColor: '#ced4da' }}
            data-testid='input'
            {...field}
            {...props}
            type={passwordVisible ? 'text' : 'password'}
            isInvalid={
              form && getIn(form.errors, field.name) && getIn(form.touched, field.name)
                ? true
                : false
            }
            onChange={(event) => {
              if (props.onChange) {
                props.onChange(event);
              } else {
                form.setFieldValue(field.name, event.target.value.replace(regex, '').slice(0, max));
                handleChange && handleChange(event.target.value.replace(regex, '').slice(0, max));
              }
            }}
            ref={ref}
            onBlur={(event) => {
              if (props.onBlur) {
                props.onBlur(event);
              } else {
                handleBlur(event);
              }
            }}
          />
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip data-testid='tooltip'>
                <p>{passwordVisible ? `Hide password` : `Show password`}</p>
              </Tooltip>
            }
            show={showTooltip}
          >
            <div className={styles.iconDiv} data-testid='icon-div' onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? (
                <Visibility data-testid='visibility-icon' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} />
              ) : (
                <VisibilityOff data-testid='visibility-icon' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} />
              )}
            </div>
          </OverlayTrigger>
        </div>
        <div className={styles.inputError}>
          {(form && getIn(form.errors, field.name) && getIn(form.touched, field.name)) ||
            props?.errors
            ? (form && getIn(form.errors, field.name)) || props?.errors
            : null}
        </div>
      </Form.Group>
    );
  }
);