import React from 'react';
import { Modal } from 'react-bootstrap';

import Image from '../../../../core-components/Image';
import AppConstants from '../../../../core-components/AppConstants';

export default (props) => {
  const token = new URLSearchParams(document.location.search).get('token');
  return (
    <Modal
      centered
      show={props.showImagePreviewModal}
      onHide={props.handleImagePreview}
      className='imagePreviewModal'
      data-testid="modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Image
          data-testid="image"
          src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.imagePreviewUrl}`}
          style={{ maxWidth: '100%' }}
        />
      </Modal.Body>
    </Modal>
  );
};
