import React, { useEffect } from 'react';

import styles from '../Integrations.module.scss';
import InputArea from './Components/InputArea';
import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';

export default function Recruitee(props) {
  return (
    <IntegrationLayout>
      <div className={styles.parentDivTopTextArea}>
        <TopTextArea title={'Recruitee'} icon={'recruitee.png'} />
      </div>
      <div className={`${styles.parentDivInputArea} mb-5`}>
        <InputArea history={props.history} />
      </div>
    </IntegrationLayout>
  );
}
