export const identityDropdownToArray = (values) => {
    let tempValue = values.map((idType) => {
        return idType.label;
    });
    return tempValue;
};

export const identityDropdownToObject = (values) => {
    let tempValue = values.map((idType, index) => {
        return { label: idType };
    });
    return tempValue;
};