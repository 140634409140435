// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a_8VD94fNepTzQzoj5ip {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #666 !important;
}

.exTZh16HOPl6h7ZcicAk {
  border: 1px solid #dfe1e5 !important;
  border-radius: 4px;
}
.exTZh16HOPl6h7ZcicAk:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.KFSNUIkLqAvkWMeL0grI {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  border-color: #80bdff;
  border-radius: 4px;
}

.b1Q0jmUNUdG7D0gVItQV {
  border: 1px solid #dc3545;
  border-radius: 4px;
}

.ptE1ryv6A0VwUR_gc9j0 {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.kLMfsd1_4XC5QXs5_X1n {
  border: none;
  background-color: rgba(39, 85, 254, 0.05);
}
.kLMfsd1_4XC5QXs5_X1n:focus {
  outline: 0;
  box-shadow: none;
}

.Ph8GcgHKUADFaHj23aOr {
  width: 27%;
}
.Ph8GcgHKUADFaHj23aOr:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.HHTnvu8d_IEn2LobeaGz {
  border: none !important;
}
.HHTnvu8d_IEn2LobeaGz:focus {
  border: none !important;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ReferAndEarn/Form/MobileInput/MobileInput.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,2BAAA;EACA,sBAAA;AACF;;AACA;EACE,oCAAA;EACA,kBAAA;AAEF;AADE;EACE,2DAAA;AAGJ;;AAAA;EACE,2DAAA;EACA,qBAAA;EACA,kBAAA;AAGF;;AADA;EACE,yBAAA;EACA,kBAAA;AAIF;;AAFA;EACE,gDAAA;AAKF;;AAFA;EACE,YAAA;EACA,yCAAA;AAKF;AAJE;EACE,UAAA;EACA,gBAAA;AAMJ;;AAHA;EACE,UAAA;AAMF;AALE;EACE,gDAAA;AAOJ;;AAJA;EACE,uBAAA;AAOF;AANE;EACE,uBAAA;EACA,gBAAA;AAQJ","sourcesContent":[".formLabel {\n  font-size: 14px !important;\n  font-weight: 600 !important;\n  color: #666 !important;\n}\n.inputGroup {\n  border: 1px solid #dfe1e5 !important;\n  border-radius: 4px;\n  &:focus {\n    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;\n  }\n}\n.inputGroupFocus {\n  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;\n  border-color: #80bdff;\n  border-radius: 4px;\n}\n.inputGroupErrBorder {\n  border: 1px solid #dc3545;\n  border-radius: 4px;\n}\n.inputGroupErrBorderFocus {\n  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);\n}\n\n.countrySelect {\n  border: none;\n  background-color: rgba(39, 85, 254, 0.05);\n  &:focus {\n    outline: 0;\n    box-shadow: none;\n  }\n}\n.inputPrepend {\n  width: 27%;\n  &:focus {\n    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);\n  }\n}\n.mobileField {\n  border: none !important;\n  &:focus {\n    border: none !important;\n    box-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLabel": `a_8VD94fNepTzQzoj5ip`,
	"inputGroup": `exTZh16HOPl6h7ZcicAk`,
	"inputGroupFocus": `KFSNUIkLqAvkWMeL0grI`,
	"inputGroupErrBorder": `b1Q0jmUNUdG7D0gVItQV`,
	"inputGroupErrBorderFocus": `ptE1ryv6A0VwUR_gc9j0`,
	"countrySelect": `kLMfsd1_4XC5QXs5_X1n`,
	"inputPrepend": `Ph8GcgHKUADFaHj23aOr`,
	"mobileField": `HHTnvu8d_IEn2LobeaGz`
};
export default ___CSS_LOADER_EXPORT___;
