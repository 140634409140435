import * as Yup from 'yup';

export const PINCODE_RULES_FOR_INDIA = /^[1-9][0-9]{5}$/;
export const PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA =
  /^(?!0+$)[0-9A-Za-z]{1,5}(\s?[0-9A-Za-z]{1,5})*$/;
export const getPincodeValidationSchema = () => {
  let schema = Yup.string()
    .required('Pincode is required.')
    .when('country', {
      is: 'India',
      then: Yup.string().matches(PINCODE_RULES_FOR_INDIA, 'Please enter a valid pincode.'),
      otherwise: Yup.string().matches(
        PINCODE_RULES_FOR_OTHER_COUNTRIES_THAN_INDIA,
        'Please enter a valid pincode.'
      )
    });

  return schema;
};

const customSchema = ({ type, min, isRequired }) => {
  if (isRequired)
    return Yup.string()
      .min(min, `Please enter a valid  ${type}`)
      .required(`Please enter a valid ${type}`);

  return Yup.string().min(min, `Please enter a valid ${type}`);
};

export default Yup.object().shape({
  houseNo: customSchema({ type: 'address', min: 3, isRequired: true }),
  addressType: Yup.string().required('Please select address type'),
  colony: customSchema({ type: 'address', min: 3, isRequired: true }),
  landmark: customSchema({ type: 'landmark', min: 2, isRequired: true }),
  pincode: getPincodeValidationSchema(),
  city: Yup.string().required('')
});
