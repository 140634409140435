import React, { useEffect, useState, useRef } from 'react';

import styles from '../../Integrations.module.scss';
import { Container, Row } from 'react-bootstrap';

import TopTextArea from '../../Utils/TopTextArea';
import IntegrationLayout from '../../Utils/Layout';
import Button from '../../../core-components/Button';
import WarningModal from '../../WarningModal';
import ConfirmationModal from '../../../core-components/ConfirmationModal';
import AppConstants from '../../../../../../core-components/AppConstants';

import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../../utils/Utilities';
import { greytHRAPIKEYGeneration } from '../../Utils/constants';
import {
  deleteIntegrations,
  postIntegrations,
  getKNITAccessToken
} from '../../../../../../api/company';

export default function GreytHR(props) {
  const [integrationData, setIntegrationData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);

  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  const knitRef = useRef(null);

  const newSessionFn = (e) => {
    e?.preventDefault();

    getKNITAccessToken({ source: 'GREYTHR', category: 'HRIS' })
      .then((r) => {
        knitRef?.current?.setAttribute('authsessiontoken', r.data.msg.token);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);
  }, [props?.integrationData]);

  useEffect(() => {
    props.setIntegrationAPI();
    props.getViewBuyPackages({ limit: 50, offset: 0 });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const sendn8nSlackNotification = () => {
    const payload = {
      integration_name: 'GreytHR',
      company_name: props.profile.profile.company_user_mapping.company.name,
      integration_method: 'KNIT',
      company_id: props.profile.profile.company_user_mapping.company.id
    };

    const url =
      AppConstants.env === 'production'
        ? 'https://springworks.app.n8n.cloud/webhook/external/integration/failed-slack-notificaion'
        : 'https://springworks.app.n8n.cloud/webhook-test/external/integration/failed-slack-notificaion-non-prod';

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };

  const handlePostIntegration = () => {
    console.log('here');
    setShowModal(true);
    sendn8nSlackNotification();
  };

  const addIntegrationAPI = ({ integrationId }) => {
    const payload = {
      source: 'GERYTHR',
      details: integrationId
    };
    postIntegrations(payload)
      .then((resp) => {
        toast.success('greytHR Integration added successfully.');
        props.setIntegrationAPI();
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations('GREYTHR')
      .then((resp) => {
        props.setIntegrationAPI();
        toast.success('Removed connection successfully.');
        setTriggerUseEffect(!triggerUseEffect);
        setIsOpen(false);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onFinishFn = (e) => {
    e.preventDefault();
    if (!Object.keys(e['detail']['integrationDetails']).includes('errors')) {
      addIntegrationAPI({ integrationId: e['detail']['integrationDetails'].integrationId });
    }
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  useEffect(() => {
    knitRef.current?.addEventListener('onNewSession', (event) => {
      newSessionFn(event);
    });

    knitRef.current?.addEventListener('onFinish', (event) => {
      onFinishFn(event);
    });

    newSessionFn();
    return () => {
      knitRef.current?.removeEventListener('onNewSession', (event) => {
        newSessionFn(event);
      });
      knitRef.current?.removeEventListener('onFinish', (event) => {
        onFinishFn(event);
      });
    };
  }, [triggerUseEffect]);

  return (
    <>
      <IntegrationLayout>
        <div className={styles.parentDivTopTextArea}>
          <TopTextArea title={'greytHR'} icon={'GreytHR.png'} />
          <Container>
            <div className='mt-5'>
              {greytHRAPIKEYGeneration.map((step, index) => {
                return (
                  <Row>
                    <h6>
                      <span className='font-weight-bold'>Step {index + 1}:</span> {step}
                    </h6>
                  </Row>
                );
              })}
            </div>
          </Container>
        </div>
        <Container>
          <div className={`${styles.parentDivInputArea} mb-5`}>
            <div className={styles.rowDisplay}>
              <Row>
                <div className={styles.step1FooterContainer}>
                  <Button className={styles.goBackKNITBtn} onClick={() => backStepHandler()}>
                    Go Back
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        </Container>
      </IntegrationLayout>
    </>
  );
}
