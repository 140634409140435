import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../../../../core-components/Button';

export default ({ title, onConfirm, onHide, ...props }) => {
  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      {...props}
    >
      <Modal.Body>
        <Row>
          <p className='h5'>{`No ${title}?`}</p>
        </Row>
        <Row>
          <p className='font-normal mt-2'>
            Your account has no packages assigned. You must have at least one package assigned to
            enable the integration.
          </p>
        </Row>
        <Row>
          <p className='text-sm mt-2'>
            Please contact cs@springverify.com to setup a package for you.
          </p>
        </Row>
      </Modal.Body>
      <Modal.Footer className='mt-3'>
        <Button onClick={() => onConfirm()} variant='primary'>
          {'OK'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
