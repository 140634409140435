import { connect } from 'react-redux';
import { getAlumniRecordOverview, getAlumniRequestOverview } from '/src/actions/company';

const mapStateToProps = (state) => {
  return {
    alumniRequestOverview: state.alumniRequestOverview,
    alumniRecordOverview: state.alumniRecordOverview
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecordOverview: () => dispatch(getAlumniRecordOverview()),
    getRequestOverview: (data) => dispatch(getAlumniRequestOverview(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
