import { connect } from 'react-redux';

import {
  invalidateNewInsufficiencies as invalidateNewInsufficienciesAPI,
  getNewInsufficiencies as getNewInsufficienciesAPI
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    insufficienciesNewData: state.insufficienciesNew
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewInsufficiencies: (data) => dispatch(getNewInsufficienciesAPI(data)),
    invalidateNewInsufficiencies: () => dispatch(invalidateNewInsufficienciesAPI())
  };
};

export { mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps);

