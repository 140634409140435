// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lYw50fQhbJW_ygtFnb4d p {
  color: #666666;
  font-weight: normal;
  font-size: 14px;
}

.juSSb8qVQS8A4IdHaZSd {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/BgvBodyContainer/BgvStepBodyContianer/Education/SkipEducationModal/SkipEducationModal.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,mBAAA;EACA,eAAA;AAAJ;;AAGA;EACE,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAAF","sourcesContent":[".addEditEducationModalBody {\n  p {\n    color: #666666;\n    font-weight: normal;\n    font-size: 14px;\n  }\n}\n.activeBtn {\n  background: #2755fe;\n  color: #ffffff;\n  cursor: pointer;\n  padding: 5px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addEditEducationModalBody": `lYw50fQhbJW_ygtFnb4d`,
	"activeBtn": `juSSb8qVQS8A4IdHaZSd`
};
export default ___CSS_LOADER_EXPORT___;
