/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import styles from './AddAlumni.module.scss';
import Button from '../../core-components/Button';
import AddManuallyModal from '../AddManuallyModal/AddManuallyModal';

const AddAlumni = (props) => {
  const companyId = new URLSearchParams(document.location.search).get('id');
  const [isAddManualModalOpen, setIsAddManualModalOpen] = useState(false);
  return (
    <>
      <Button
        label={'Add Manually'}
        color={'primary'}
        className={styles.customBtnStyle}
        onClick={() => setIsAddManualModalOpen(true)}
      >
        Add Manually
      </Button>
      {isAddManualModalOpen && (
        <AddManuallyModal
          onAddSingle={() => {
            setIsAddManualModalOpen(false);
            if (props?.isSA) {
              props?.history.push({
                pathname: '/company/alumniVerification',
                search: `?id=${companyId}&flow=add`
              });
            } else {
              props?.history.push({
                pathname: '/alumni-verification/record',
                search: '?flow=ADD'
              });
            }
          }}
          onAddBulk={() => {
            setIsAddManualModalOpen(false);
            if (props?.isSA) {
              props?.history.push({
                pathname: '/company/alumniVerification',
                search: `?id=${companyId}&flow=bulk`
              });
            } else {
              props?.history.push({
                pathname: '/alumni-verification/bulk-upload'
              });
            }
          }}
          onHide={() => setIsAddManualModalOpen(false)}
        />
      )}
    </>
  );
};

export default AddAlumni;
