import React from 'react';
import styles from './UpsellSuccessModal.module.scss';
import { Button, Modal } from 'react-bootstrap';
import Image from '../../../../../core-components/Image';

const UpsellSuccessModal = (props) => {
  return (
    <div data-testid='modal-container'>
      <Modal show={props.show} centered onHide={props.onClose} data-testid='modal'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <div className={styles.checkIcon} data-testid='check-icon'>
            <Image name='checkGreenFilled.svg' />
          </div>
          <div className={styles.thankYouText} data-testid='thank-you-text'>
            Thank you for your response.
          </div>
          <div className={styles.reachOutText} data-testid='reach-out-text'>
            Your CSM will reach out to you to discuss this further!
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.footer}>
            <Button
              onClick={props.onClose}
              className={styles.cancelBtn}
              variant='outline-primary'
              data-testid='cancel-button'
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpsellSuccessModal;
