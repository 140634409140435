import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../core-components/Loader/Loader';
import styles from '../Login.module.scss';
import { errToastMessage, redirectToPreviousPathAfterLogin } from '../../../utils/utilities';

const GoogleRedirection = (props) => {
  const { location, googleMicrosoftLoginAPI, history } = props;
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const error = queryParams.get('error');

  useEffect(() => {
    const handleLogin = async (code) => {
      googleMicrosoftLoginAPI('auth/google-login', { code })
        .then(() => {
          redirectToPreviousPathAfterLogin(props?.history);
        })
        .catch((err) => {
          history.push('/signIn');
          if (err?.data?.message) errToastMessage(err.data.message);
        });
    };

    if (code) {
      handleLogin(code);
    }
  }, [code]);

  useEffect(() => {
    if (error) {
      if (error !== 'access_denied') errToastMessage(error);
      history.push('/signIn');
    }
  }, [error]);

  return (
    <>
      <div className={styles.overlayLoader}>
        <Loader></Loader>
      </div>
    </>
  );
};

export default withRouter(GoogleRedirection);
