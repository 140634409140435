// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JVswQsJdOcbtOHYeMeyw .Q_c39EOBciELJRxJkVRA {
  display: block;
  border: none;
  height: 100vh;
  width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/core-components/RenderLink/RenderLink.module.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAAJ","sourcesContent":[".RenderLink {\n  .iframe {\n    display: block;\n    border: none;\n    height: 100vh;\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RenderLink": `JVswQsJdOcbtOHYeMeyw`,
	"iframe": `Q_c39EOBciELJRxJkVRA`
};
export default ___CSS_LOADER_EXPORT___;
