import { connect } from 'react-redux';
import { getAdditionalChargeSettings, updateAdditionalChargeSettings } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    adcSettings: state.adcSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getADC: () => dispatch(getAdditionalChargeSettings()),
    updateADC: (data) => dispatch(updateAdditionalChargeSettings(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
