import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({ height = '97%' }) => {
  return (
    <div
      style={{
        width: '100%',
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5,
        backgroundColor: 'white',
        opacity: 0.5,
        position: 'absolute',
        boxSizing: 'border-box',
        top: 0,
        left: 0
      }}
    >
      <Spinner animation='border' variant='primary' />
    </div>
  );
};

export default Loader;
