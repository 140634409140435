import * as Yup from 'yup';

const ERROR_MSG = 'Required Field';
export default Yup.object().shape({
  fullName: Yup.string().required(ERROR_MSG),
  dateOfJoining: Yup.string().required(ERROR_MSG),
  lastWorkingDay: Yup.string().required(ERROR_MSG),
  designation: Yup.string().required(ERROR_MSG),
  dateOfBirth: Yup.string().required(ERROR_MSG)
});
