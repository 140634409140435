import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import styles from '../AdditionalCharges.module.scss';
import Button from '../../../../../core-components/Button';
import AppConstants from '../../../../../core-components/AppConstants';

export default (props) => {
  const { handleSubmit, onHide } = props;


  return (
    <Modal
      size='md'
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      data-testid="modal-form"
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <div className={styles.heading}>Setting changed to auto-approve mode</div>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <p className={styles.body}>
            These settings will be applicable to new costs. Any existing costs that are pending will
            still need to be approved manually from{' '}
            <a href={`${AppConstants.baseURL}approval`} target='_blank'>
              Approve Charges
            </a>
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className={styles.okButton} size='sm' type='submit' onClick={() => onHide()}>
            OK
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
