export const SV_ERRORS = {
  /**
   * @type {SpringVerifyInternalError}
   */
  password_expired: {
    code: 401,
    description: 'Your current password has expired. Use "Forgot password" to set a new password.',
    sv_error_code: 'password_expired'
  },
  password_missing_to_expire: {
    code: 419,
    description: `You haven't set the password yet. Please set new password to proceed.`,
    sv_error_code: 'password_missing_to_expire'
  },
  /**
   * @type {SpringVerifyInternalError}
   */
  auto_logout: {
    code: 401,
    description: `Session expired. Please log in again.`,
    sv_error_code: 'auto_logout'
  },
  /**
   * @type {SpringVerifyInternalError}
   */
  token_expired: {
    code: 401,
    description: `Token expired. Please log in again.`,
    sv_error_code: 'token_expired'
  }
};
