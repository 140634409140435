import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import Connect from './images/connect.png';
import Zoho from './images/zoho.png';
import Humaans from '../../frontend-common/images/humaans.png';
import HrCloud from '../../frontend-common/images/hrcloud.png';
import GreytHR from '../../frontend-common/images/GreytHR.png';
import BambooHR from '../../frontend-common/images/bamboohr.png';
import Keka from '../../frontend-common/images/keka.png';
import Repute from '../../frontend-common/images/repute.png';
import Personio from '../../frontend-common/images/perssonio.png';
import Hibob from '../../frontend-common/images/hibob.png';
import HaileyHR from '../../frontend-common/images/haileyhr.png';
import Gusto from '../../frontend-common/images/Gusto.png';
import hrpartner from '../../frontend-common/images/HRPartner.png';
import AlexisHR from '../../frontend-common/images/alexishr.png';
import Breathe from '../../frontend-common/images/Breathe.png';
import Charlie from '../../frontend-common/images/Charlie.png';
import Factorial from '../../frontend-common/images/factorialHRMS.png';
import ClayHR from '../../frontend-common/images/clayhr.png';
import PeopleHR from '../../frontend-common/images/peoplehr.png';
import Namely from '../../frontend-common/images/namely.png';
import IntelliHR from '../../frontend-common/images/intellihr.png';

import Button from 'react-bootstrap/Button';
import authenticate from '@truto/truto-link-sdk';
import { postAlumniIntegrations, getTRUTOLinkToken } from '../../../../api/company';
import { FiMoreHorizontal, FiCopy } from 'react-icons/fi';
import { AiOutlineCheckCircle, AiOutlineEdit, AiOutlineExclamationCircle } from 'react-icons/ai';
import { GiCancel } from 'react-icons/gi';
import { errToastMessage } from '../../../../utils/utilities';
import AddManualModal from '../../../../core-components/AddManualModal/AddManualModal';
import {
  manualSync,
  manualSyncSuperQueryIntegrations,
  disconnectHRMS,
  getHRMSDetails
} from '../../../../api/company';
import { toast } from 'react-toastify';
import AddBulkExEmp from '../../frontend-common/AddBulkExEmp';
import { Spinner } from 'react-bootstrap';
import ConfirmationModal from '../../../../core-components/ConfirmationModal';
import { isEqual } from 'lodash';

const HRMSConnectBar = (props) => {
  const [datax, setDataX] = useState([]);
  const { HRMSSyncData, getHRMSSyncData, getAlumniTablesData, getRecordOverview } = props;
  const [isAddManualModalOpen, setIsAddManualModalOpen] = useState(false);
  const [isBulkAddModalOpen, setIsBulkAddModalOpen] = useState(false);
  const [HRMSConnectStatus, setHRMSConnectStatus] = useState(false);
  const [isHRMSConnecting, setIsHRMSConnecting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [newHRMSConnection, setNewHRMSConnection] = useState(false);

  const HRMSMap = {
    ZOHOPEOPLE: {
      name: 'Zoho People',
      logo: Zoho
    },
    HUMAANS: {
      name: 'Humaans',
      logo: Humaans
    },
    HRCLOUD: {
      name: 'HR Cloud',
      logo: HrCloud
    },
    GREYTHR: {
      name: 'GreytHR',
      logo: GreytHR
    },
    BAMBOOHR: {
      name: 'BambooHR',
      logo: BambooHR
    },
    KEKA: {
      name: 'Keka',
      logo: Keka
    },
    REPUTE: {
      name: 'Repute',
      logo: Repute
    },
    PERSONIO: {
      name: 'Personio',
      logo: Personio
    },
    HIBOB: {
      name: 'Hibob',
      logo: Hibob
    },
    HAILEYHR: {
      name: 'HaileyHR',
      logo: HaileyHR
    },
    GUSTO: {
      name: 'Gusto',
      logo: Gusto
    },
    ALEXISHR: {
      name: 'AlexisHR',
      logo: AlexisHR
    },
    BREATHE: {
      name: 'Breathe',
      logo: Breathe
    },
    CHARLIE: {
      name: 'Charlie',
      logo: Charlie
    },
    FACTORIAL: {
      name: 'Factorial',
      logo: Factorial
    },
    HRPARTNER: {
      name: 'HR Partner',
      logo: hrpartner
    },
    PEOPLEHR: { name: 'PeopleHR', logo: PeopleHR },
    CLAYHR: { name: 'ClayHR', logo: ClayHR },
    NAMELY: { name: 'Namely', logo: Namely },
    INTELLIHR: { name: 'IntelliHR', logo: IntelliHR }
  };

  const SuperQueryBasesAlumniIntegrations = ['BREATHE', 'CHARLIE', 'FACTORIAL', 'HRPARTNER'];

  useEffect(() => {
    getHRMSSyncData();
    getHRMSConnectionDetails();
  }, []);

  const getHRMSConnectionDetails = () => {
    getHRMSDetails()
      .then((res) => {
        setHRMSConnectStatus(res?.data?.data?.integrationDetails);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (HRMSSyncData.data) {
      setDataX(HRMSSyncData.data);
    }
  }, [HRMSSyncData]);

  const data = {
    lastSync: datax.rows?.[0]?.createdAt ? new Date(datax.rows?.[0]?.createdAt) : null,
    name: HRMSMap[HRMSConnectStatus?.source]?.name || 'N/A',
    logo: HRMSMap[HRMSConnectStatus?.source]?.logo || Zoho,
    details: {
      added: datax.rows?.[0]?.description.added || 0,
      updated: datax.rows?.[0]?.description.updated || 0,
      failed: datax.rows?.[0]?.description.skipped + datax.rows?.[0]?.description.failed || 0,
      dublicate: datax.rows?.[0]?.description.duplicate || 0
    }
  };

  const formatCurrentDate = (date) => {
    if (!(date instanceof Date)) {
      return 'N/A';
    }

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    if (date.toDateString() === today.toDateString()) {
      return `Today ${date.toLocaleString('en-US', options)}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday ${date.toLocaleString('en-US', options)}`;
    } else {
      return `${date.toLocaleString('en-US', options)} ${date.toLocaleDateString('en-US')}`;
    }
  };

  const addIntegrationAPI = async ({ integrationId, integration }) => {
    try {
      await getHRMSSyncData(); // To get latest sync data
      const payload = {
        source: integration.toUpperCase(),
        details: { integration_id: integrationId },
        integration_type: 'ALUMNI_VERIFICATION'
      };
      postAlumniIntegrations(payload)
        .then((resp) => {
          toast.success(
            `${integration.toUpperCase()} Integration added and data sync triggered successfully.`
          );
          getHRMSConnectionDetails();
          setIsHRMSConnecting(false);
          stopRecordsReload();
          setNewHRMSConnection(true);
          handleHRMSDataRefresh();
        })
        .catch((error) => {
          errToastMessage(error);
          setIsHRMSConnecting(false);
        });
    } catch (err) {
      console.error('err: ', err);
    }
  };

  const connectTruto = async () => {
    try {
      setIsHRMSConnecting(true);
      const response = await getTRUTOLinkToken();
      const linkToken = response.data.link_token;

      const options = {
        integrations: [
          'zohopeople',
          'humaans',
          'hrcloud',
          'greythr',
          'keka',
          'repute',
          'bamboohr',
          'personio',
          'gusto',
          'haileyhr',
          'hibob',
          'alexishr',
          'breathe',
          'factorial',
          'hrpartner',
          'charlie',
          'clayhr',
          'peoplehr',
          'namely',
          'intellihr'
        ]
      };
      const authResponse = await authenticate(linkToken, options);
      await addIntegrationAPI({
        integration: authResponse.integration,
        integrationId: authResponse.integrated_account_id
      });
    } catch (error) {
      console.error(error);
      setIsHRMSConnecting(false);
    }
  };

  const disconnectTruto = () => {
    setShowWarningModal(false);
    setIsHRMSConnecting(true);
    disconnectHRMS(HRMSConnectStatus?.source, HRMSConnectStatus?.type).then((res) => {
      window.location.reload();
      toast.success('HRMS disconnected successfully');
      setIsHRMSConnecting(false);
    });
  };

  const [recordsIntervalId, setRecordsIntervalId] = useState(null);

  useEffect(() => {
    if (recordsIntervalId) {
      const timer = setInterval(() => {
        handleRefreshHRMSConnection();
      }, 30000);
      return () => clearInterval(timer);
    }
  }, [recordsIntervalId]);

  const handleHRMSDataRefresh = () => {
    const id = setInterval(() => {}, 30000);
    setRecordsIntervalId(id);
    setTimeout(() => {
      clearInterval(id);
      setRecordsIntervalId(null);
      setNewHRMSConnection(false);
    }, 300000);
  };

  const handleRefreshHRMSConnection = () => {
    const HRMS_Data = { ...HRMSSyncData };
    getHRMSSyncData().then((res) => {
      const currentData = HRMS_Data?.data?.rows[0];
      const newData = res?.data?.rows[0];
      if (newData?.id !== currentData?.id) {
        stopRecordsReload();
        handleRefreshTableData(newData?.description);
      }
    });
  };

  const stopRecordsReload = () => {
    clearInterval(recordsIntervalId);
    setRecordsIntervalId(null);
    setNewHRMSConnection(false);
  };

  const handleRefreshTableData = (d) => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentTab = urlParams.get('tab') || '';
    const tabs = {
      alumni_records: 'ALUMNI_RECORDS',
      sync_logs: 'DATA_SYNC',
      recent_request: 'RECENT_REQUEST'
    };
    const where = {
      limit: 10,
      offset: 0
    };
    getRecordOverview();
    getAlumniTablesData(where, tabs[currentTab] || 'RECENT_REQUEST')
      .then((resp) => {
        const { added = 0, updated = 0, duplicate = 0, failed = 0, skipped = 0 } = d || {};
        const totalRecords = added + updated + duplicate + failed + skipped;
        toast.dismiss();
        if ((added || updated) && !duplicate && !failed && !skipped) {
          toast.success(
            `${
              newHRMSConnection ? 'Data' : 'Manual'
            } sync completed successfully. Data has been updated.`
          );
          return;
        }
        if (!added && !updated && (duplicate || failed || skipped)) {
          errToastMessage(
            `${
              newHRMSConnection ? 'Data' : 'Manual'
            } sync failed. Check email for HRIS data fixes to proceed.`
          );
          return;
        }
        if ((added || updated) && (duplicate || failed || skipped)) {
          errToastMessage(
            `${
              duplicate + failed + skipped
            }/${totalRecords} records failed. Check email for HRIS data fixes to proceed.`
          );
          return;
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  const triggerManualSync = async () => {
    try {
      await getHRMSSyncData(); // To get latest sync data
      // Based on SuperQuery Integrations call the respective API
      if (SuperQueryBasesAlumniIntegrations.includes(HRMSConnectStatus?.source)) {
        manualSyncSuperQueryIntegrations(HRMSConnectStatus?.source).then((res) => {
          setIsHRMSConnecting(false);
          handleHRMSDataRefresh();
          toast.success('Manual Sync triggered successfully');
        });
      } else {
        manualSync(HRMSConnectStatus?.source).then((res) => {
          setIsHRMSConnecting(false);
          handleHRMSDataRefresh();
          toast.success('Manual Sync triggered successfully');
        });
      }
    } catch (err) {
      console.error('err: ', err);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {!loading && (
          <>
            <div className={styles.logo}>
              <img src={HRMSConnectStatus ? data?.logo : Connect} />
            </div>
            <div className={styles.body}>
              <div className={styles.header}>
                {HRMSConnectStatus ? `Connected to ${data?.name}` : `Sync with HRMS`}
              </div>

              {HRMSConnectStatus ? (
                <div className={styles.info}>
                  <div>
                    Last synced:{' '}
                    <span style={{ color: 'black' }}>{formatCurrentDate(data?.lastSync)}</span>
                  </div>
                  |
                  {!data.details ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <AiOutlineExclamationCircle
                        style={{
                          color: '#666666',
                          fontSize: '16px',
                          marginRight: '5px'
                        }}
                      />
                      No records found
                    </div>
                  ) : (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AiOutlineCheckCircle
                          style={{
                            color: 'green',
                            fontSize: '16px',
                            marginRight: '5px'
                          }}
                        />
                        Added:
                        <span style={{ color: 'black', marginLeft: '4px' }}>
                          {data.details.added}
                        </span>
                      </div>
                      |
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AiOutlineEdit
                          style={{
                            color: '#0844A6',
                            fontSize: '16px',
                            marginRight: '5px'
                          }}
                        />
                        Updated:
                        <span style={{ color: 'black', marginLeft: '4px' }}>
                          {data.details.updated}
                        </span>
                      </div>
                      |
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiCopy
                          style={{
                            color: '#3fb5cc',
                            fontSize: '16px',
                            marginRight: '5px'
                          }}
                        />
                        Duplicate:
                        <span style={{ color: 'black', marginLeft: '4px' }}>
                          {data.details.dublicate}
                        </span>
                      </div>{' '}
                      |
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <GiCancel
                          style={{
                            color: '#E97070',
                            fontSize: '16px',
                            marginRight: '5px'
                          }}
                        />
                        Failed:
                        <span style={{ color: 'black', marginLeft: '4px' }}>
                          {data.details.failed}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className={styles.info}>
                  Automate adding exit records by connecting your HRMS
                </div>
              )}
            </div>
            <div className={styles.btnContainer}>
              <div className={styles.buttonGroup}>
                {HRMSConnectStatus ? (
                  <>
                    <Button
                      className={styles.connect}
                      onClick={() => !isHRMSConnecting && setIsAddManualModalOpen(true)}
                    >
                      Add Manually
                    </Button>
                    <div className={styles.dropdownButton}>
                      {isHRMSConnecting ? (
                        <div className={styles.spinnerContainer}>
                          <Spinner
                            className={styles.spinner}
                            animation='border'
                            variant='dark'
                            size='sm'
                            hidden={false}
                            style={{ verticalAlign: 'middle' }}
                          />
                        </div>
                      ) : (
                        <Button className={styles.more}>
                          <FiMoreHorizontal className={styles.horizontalButton} />
                        </Button>
                      )}
                      <div className={styles.dropdown}>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            setIsHRMSConnecting(true);

                            triggerManualSync();
                          }}
                        >
                          Sync Manually
                        </div>
                        <div className={styles.dropdownItem} onClick={() => connectTruto()}>
                          Reconnect
                        </div>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            setShowWarningModal(true);
                          }}
                        >
                          Disconnect
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      className={styles.connect}
                      onClick={() => !isHRMSConnecting && connectTruto()}
                    >
                      Connect HRMS
                      <Spinner
                        className={styles.spinner}
                        animation='border'
                        variant='light'
                        size='sm'
                        hidden={!isHRMSConnecting}
                        style={{ marginLeft: '5px', verticalAlign: 'middle' }}
                      />
                    </Button>
                    <div className={styles.orText}>Or</div>
                    <Button
                      className={styles.addManual}
                      onClick={() => !isHRMSConnecting && setIsAddManualModalOpen(true)}
                    >
                      Add Manually
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {isAddManualModalOpen && (
        <AddManualModal
          onAddSingle={() => {
            props?.history.push('/alumni-verification/record?flow=ADD');
          }}
          onHide={() => setIsAddManualModalOpen(false)}
          onAddBulk={() => {
            setIsAddManualModalOpen(false);
            props?.history.push('/alumni-verification/bulk-upload');
          }}
        />
      )}
      {isBulkAddModalOpen && (
        <AddBulkExEmp
          show={isBulkAddModalOpen}
          history={props.history}
          onClose={() => {
            setIsBulkAddModalOpen(false);
            setIsAddManualModalOpen(false);
          }}
        />
      )}
      {showWarningModal && (
        <ConfirmationModal
          onClose={() => setShowWarningModal(false)}
          show={showWarningModal}
          heading={`Disconnect ${HRMSConnectStatus?.source} ?`}
          enableBtn={true}
          sBtnText={'Disconnect'}
          onSubmit={() => {
            disconnectTruto();
          }}
        />
      )}
    </>
  );
};

export default HRMSConnectBar;
