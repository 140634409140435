import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Image from '../core-components/Image';
import { convertToIndianNumeration } from '../core-components/Utilities/utilities';
import Button from '../core-components/Button';
import styles from './CreditBuyPackagePage.module.scss';

const ConfirmPurchaseModal = ({
  price,
  onConfirm,
  onHide,
  paymentsObj,
  creditBalance,
  useCreditBalance,
  selectedOption,
  setShowLoader,
  ...props
}) => {
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Body className={styles.body}>
        <Row>
          <Col lg={1} md={1} sm={1} xs={1}>
            <Image name='confirmationSymbol.svg' isSA={props.isSA} />
          </Col>
          <Col lg={11} md={11} sm={11} xs={11}>
            <p className={styles.title}>{'Confirm purchase ?'}</p>
            {creditBalance > 0 && (
              <p>
                <b className={styles.amount}> &#8377;{convertToIndianNumeration(creditBalance)}</b>{' '}
                {'credits will be used for this purchase'}
              </p>
            )}
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <Button className={styles.cancelBtn} onClick={() => onHide()}>
          {'Cancel'}
        </Button>
        <Button className={styles.confirmBtn} onClick={() => onConfirm()}>
          {'Confirm purchase'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPurchaseModal;
