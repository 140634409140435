import _, { intersection, isEmpty, orderBy, sortBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import CustomSpinner from '../../core-components/CustomSpinner';
import Image from '../../core-components/Image';
import {
  getPackageUnitIndication,
  toCamelCaseTemp
} from '../../core-components/Utilities/utilities';
import styles from './Packages.module.scss';
import SubtypesModal from './modals/SubtypesModal';
import { getPaymentsPackagesReviewOrder } from '../../../../../api/company';
import Tiers from './Tiers';
import Banner from '../../core-components/Banner2';
import scrollIntoView from 'scroll-into-view-if-needed';

export default (props) => {
  const { packages, getPackages, createCandConfig, setActiveTiers, selectedTiers, paymentDetails } =
    props;
  const [groupedPackages, setGroupedPackages] = useState([]);
  const [isSubtypesModalOpen, setISubtypesModalOpen] = useState(false);
  const [subtypes, setSubtypes] = useState([]);
  const [activeAccord, setActiveAccord] = useState([]);
  const [activePackage, setActivePackage] = useState(selectedTiers ? selectedTiers : {});
  const [tiers, setTiers] = useState({});
  const [insufficientPackages, setInsufficientPackages] = useState([]);
  const [tiersLoading, setTiersLoading] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('company_id');

  useEffect(() => {
    getPackages({ limit: 50, offset: 0, companyId });
  }, []);
  useEffect(() => {
    if (packages && packages.rows && packages.rows.length > 0) {
      const groupedArray = _(packages.rows)
        .groupBy('packageId')
        .map((item) => item)
        .value();
      if (groupedArray?.length > 0) {
        let insufficient_packages = groupedArray.filter((pckg) => pckg[0].currentBalance === 0);
        let sufficient_packages = groupedArray.filter(
          (pckg) => pckg[0].currentBalance > Math.floor(pckg[0].leastRechargeUnitsTier * 0.2)
        );
        let low_packages = groupedArray.filter(
          (pckg) =>
            pckg[0].currentBalance !== 0 &&
            pckg[0].currentBalance <= Math.floor(pckg[0].leastRechargeUnitsTier * 0.2)
        );
        insufficient_packages = orderBy(
          insufficient_packages,
          (obj) => obj[0].costPerCandidate,
          'desc'
        );
        sufficient_packages = orderBy(
          sufficient_packages,
          (obj) => obj[0].costPerCandidate,
          'desc'
        );
        low_packages = orderBy(low_packages, (obj) => obj[0].costPerCandidate, 'desc');
        const allPackages = [
          ...insufficient_packages,
          ..._.orderBy(low_packages, ['currentBalance']),
          ..._.orderBy(sufficient_packages, ['currentBalance'])
        ];
        setInsufficientPackages(insufficient_packages);
        setActiveAccord([...insufficient_packages.map((pck) => pck[0].packageId)]);
        setGroupedPackages(allPackages);
      }
    }
  }, [packages]);
  const renderPackageUnitIndication = (leastTier, currentBalance) => {
    const unitIndication = getPackageUnitIndication(leastTier, currentBalance);
    switch (unitIndication) {
      case 'NILL':
        return (
          <Badge pill className={styles.criticalPill}>
            Critical balance
          </Badge>
        );
      case 'LOW':
        return (
          <Badge pill className={styles.lowPill}>
            Low balance
          </Badge>
        );
      default:
        return '';
    }
  };
  // Need to refractor
  const handleToggleClick = (packageId) => {
    // check for actice packages here
    let activePacs = [...activeAccord];
    const activePackagesIdsArray = intersection(
      Object.keys(activePackage).map((ap) => parseInt(ap)),
      activePacs
    );
    if (activePacs.includes(packageId)) {
      activePacs.indexOf(packageId);
      activePacs.splice(activePacs.indexOf(packageId), 1);
      setActiveAccord(activePacs);
    } else {
      const lastActive = activePacs.length > 0 ? activePacs[activePacs.length - 1] : null;
      if (
        lastActive &&
        !activePackagesIdsArray.includes(lastActive) &&
        ![
          ...groupedPackages
            .filter((pck) => pck[0].currentBalance === 0)
            .map((pck) => pck[0].packageId)
        ].includes(lastActive)
      ) {
        activePacs.pop();
      }
      activePacs.push(packageId);
      setActiveAccord(activePacs);
      moveToSection(packageId);
      if (isEmpty(tiers) || !tiers.hasOwnProperty(packageId)) {
        let pacWithTier = {
          [packageId]: {
            loading: true
          }
        };
        setTiers({ ...tiers, ...pacWithTier });
        getPaymentsPackagesReviewOrder(packageId, companyId)
          .then((response) => {
            let data = toCamelCaseTemp(response.data.data);
            data['data'] = sortBy(data.data, (obj) => obj.candidateCount);
            pacWithTier[packageId] = { data: data.data, loading: false };
            setTiers({ ...tiers, ...pacWithTier });
            setTiersLoading(false);
            const violation = document.getElementById(packageId);
            // Even if all you do is this
            scrollIntoView(violation, {
              behavior: 'smooth',
              block: 'nearest',
              scrollMode: 'if-needed'
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };
  const handleTierIncrease = (tier, pac) => {
    const arrayPac = { ...activePackage };
    tier['package_id'] = pac;
    if (arrayPac.hasOwnProperty(pac)) {
      arrayPac[pac].push(tier);
    } else {
      arrayPac[pac] = [tier];
    }
    setActivePackage(arrayPac);
  };
  const handleTierReduce = (tier, pac) => {
    const arrayPac = { ...activePackage };
    if (arrayPac.hasOwnProperty(pac) && arrayPac[pac].length > 1) {
      const itemIndex = arrayPac[pac].findIndex((i) => i.id === tier.id);
      arrayPac[pac].splice(itemIndex, 1);
    } else if (arrayPac.hasOwnProperty(pac) && arrayPac[pac].length === 1) {
      delete arrayPac[pac];
    } else {
      // need to check scenario
    }
    setActivePackage(arrayPac);
  };

  useEffect(() => {
    setActiveTiers(activePackage);
  }, [activePackage]);

  useEffect(() => {
    let pacWithTier = {};
    insufficientPackages.length > 0 &&
      insufficientPackages.map((ac) => {
        pacWithTier[ac[0].packageId] = {
          loading: true
        };
      });
    insufficientPackages.map((pac) => {
      if (isEmpty(tiers) || !tiers.hasOwnProperty(pac[0].packageId)) {
        setTiers({ ...tiers, ...pacWithTier });
        getPaymentsPackagesReviewOrder(pac[0].packageId, companyId)
          .then((response) => {
            let data = toCamelCaseTemp(response.data.data);
            data['data'] = sortBy(data.data, (obj) => obj.candidateCount);
            pacWithTier[pac[0].packageId] = { data: data.data, loading: false };
            setTiers({ ...tiers, ...pacWithTier });
            setTiersLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }, [insufficientPackages]);
  const moveToSection = (id) => {
    setTimeout(() => {
      const violation = document.getElementById(id);
      scrollIntoView(violation, {
        behavior: 'smooth',
        block: 'nearest',
        scrollMode: 'if-needed'
      });
    }, 0);
  };
  const getRechargeCount = (packageId) => {
    const initialValue = 0;
    const sumWithInitial = activePackage[packageId]
      .map((ap) => ap.candidateCount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
    return sumWithInitial;
  };

  const getPackageBanner = () => {
    let PACKAGE_INDICATOR = '';
    if (
      paymentDetails &&
      !paymentDetails.loading &&
      paymentDetails.packageBalance &&
      paymentDetails.packageBalance.count === 0
    ) {
      PACKAGE_INDICATOR = 'NO_PACKAGE';
    } else if (
      paymentDetails &&
      paymentDetails.packageBalance?.rows &&
      paymentDetails.packageBalance.rows?.some((value) => value.packageBalance === 0)
    ) {
      PACKAGE_INDICATOR = 'CRITICAL_BALANCE';
    } else if (
      paymentDetails &&
      paymentDetails.packageBalance?.rows &&
      paymentDetails.packageBalance.rows?.some((value) => value.packageBalance <= 2)
    ) {
      PACKAGE_INDICATOR = 'LOW_BALANCE';
    }
    return PACKAGE_INDICATOR;
  };
  const isCardActive = (packageId) => {
    return activePackage.hasOwnProperty(packageId) || activeAccord.includes(packageId);
  };
  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>Packages</div>
      {getPackageBanner() === 'NO_PACKAGE' ? (
        <Banner isSA={props.isSA} clsName='noPackageBanner'>
          There are no packages currently assigned to your account. Please contact our{' '}
          <a href='mailto:cs@springverify.com' target='_blank'>
            {' '}
            support team
          </a>
        </Banner>
      ) : getPackageBanner() === 'CRITICAL_BALANCE' ? (
        <Banner isSA={props.isSA} clsName='criticalPackageBalanceBanner' name='warningRedIcon.svg'>
          Some packages do not have enough balance to add candidates. Recharge immediately!
        </Banner>
      ) : getPackageBanner() === 'LOW_BALANCE' ? (
        <Banner isSA={props.isSA} clsName='lowPackageBalanceBanner'>
          Some packages have low balance. Recharge ASAP to avoid delays in adding candidates.
        </Banner>
      ) : null}
      {getPackageBanner() !== 'NO_PACKAGE' ? (
        <div className='mb-2'>
          <span className={styles.sectionSubTitle}>View / Recharge packages</span>
        </div>
      ) : null}
      <div className='pb-4'>
        {packages && packages.loading ? (
          <div className='d-flex justify-content-center'>
            <CustomSpinner
              className='bgvLoader'
              as='div'
              animation='border'
              size='md'
              role='status'
              aria-hidden='true'
            />
          </div>
        ) : (
          <>
            {groupedPackages?.map((pac, index) => (
              <div key={index} id={pac[0].packageId}>
                <Card
                  className={`${styles.accordionCard} ${
                    isCardActive(pac[0].packageId) ? styles.cardActive : ''
                  } rounded`}
                >
                  <div
                    as={Card.Header}
                    className={`${styles.accordionHeader} ${styles.accordionTitle}`}
                    onClick={() => {
                      handleToggleClick(pac[0].packageId);
                    }}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <div>
                          <span className={styles.sectionTitle}>{pac[0].packageName}</span>
                        </div>
                        <div>
                          <span className={styles.currentBalance}>
                            Current Balance: {pac[0].currentBalance}
                          </span>
                          <span className='ml-2'>
                            {renderPackageUnitIndication(
                              pac[0]?.leastRechargeUnitsTier,
                              pac[0]?.currentBalance
                            )}
                          </span>
                        </div>
                        <div>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setSubtypes(pac);
                              setISubtypesModalOpen(true);
                            }}
                            className={styles.viewCheck}
                          >
                            View checks
                          </span>
                        </div>
                      </div>
                      {!activeAccord.includes(pac[0].packageId) &&
                      !activePackage.hasOwnProperty(pac[0].packageId) ? (
                        <div className='d-flex align-items-center'>
                          <span className={styles.rechargeText}>Recharge</span>
                          <span className='ml-2'>
                            <Image
                              isSA={props.isSA}
                              className={styles.goBackBtnMobile}
                              name='leftArrow.svg'
                            />
                          </span>
                        </div>
                      ) : !activeAccord.includes(pac[0].packageId) &&
                        Object.keys(activePackage)
                          .map((ap) => parseInt(ap))
                          .includes(pac[0].packageId) ? (
                        <div className='d-flex align-items-center'>
                          <span className={styles.rechargeText}>
                            {getRechargeCount(pac[0].packageId)} candidates selected
                          </span>
                          <span className='ml-2'>
                            <Image
                              isSA={props.isSA}
                              className={styles.goBackBtnMobile}
                              name='leftArrow.svg'
                            />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {activeAccord.includes(pac[0].packageId) ? (
                    <div className={styles.tierDetails}>
                      <Card.Body className={styles.tierCard}>
                        <Container className={styles.tierContainer}>
                          {tiers[pac[0].packageId]?.loading ? (
                            <div className='d-flex justify-content-center'>
                              <CustomSpinner
                                className='bgvLoader'
                                as='div'
                                animation='border'
                                size='md'
                                role='status'
                                aria-hidden='true'
                              />
                            </div>
                          ) : (
                            <Row>
                              {!tiersLoading &&
                                !isEmpty(tiers) &&
                                tiers.hasOwnProperty(pac[0].packageId) &&
                                tiers[pac[0].packageId]?.data?.map((tier, index) => (
                                  <Col key={index} xs={6} className={styles.tierCol}>
                                    <Tiers
                                      packageId={pac[0].packageId}
                                      handleIncrease={() =>
                                        handleTierIncrease(tier, pac[0].packageId)
                                      }
                                      handleReduce={() => handleTierReduce(tier, pac[0].packageId)}
                                      item={tier}
                                      index={index}
                                      selectedTiers={selectedTiers}
                                      firstOption={tiers[pac[0].packageId].data[0]}
                                    />
                                  </Col>
                                ))}
                            </Row>
                          )}
                        </Container>
                      </Card.Body>
                    </div>
                  ) : null}
                </Card>
              </div>
            ))}
          </>
        )}
      </div>
      {isSubtypesModalOpen ? (
        <SubtypesModal
          show={isSubtypesModalOpen}
          subtypes={subtypes}
          handleClose={() => setISubtypesModalOpen(false)}
          isAadhaarAllowed={createCandConfig?.includeAadhaarInIdCheck === 1 ? true : false}
          isSA={props.isSA}
        />
      ) : null}
    </div>
  );
};
