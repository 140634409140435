// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y3QtTOOaBNr56YzyELkz {
  display: grid;
  width: 165px;
  margin: auto;
  margin-top: 258px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}
.y3QtTOOaBNr56YzyELkz img {
  margin: auto;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ViewCandidate/NoRecords/NoRecords.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EAKA,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHF;AAJE;EACE,YAAA;EACA,mBAAA;AAMJ","sourcesContent":[".noRecordContainer {\n  display: grid;\n  width: 165px;\n  margin: auto;\n  margin-top: 258px;\n  img {\n    margin: auto;\n    margin-bottom: 24px;\n  }\n  font-family: Poppins, sans-serif;\n  font-size: 16px;\n  line-height: 24px;\n  color: #666666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noRecordContainer": `y3QtTOOaBNr56YzyELkz`
};
export default ___CSS_LOADER_EXPORT___;
