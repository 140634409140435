import React, { useState } from 'react';
import TableView from '../Components/Table/TableView.jsx';
import abc from './sample.js';
import makeData from '../Components/Table/makeData.js';
import '../bulkuploader.css';
const Step3 = ({ columns, setCurrentStep, setFinalData, flow, config, history, companyId }) => {
  const data = makeData(columns, -1, flow);
  return (
    <div
      className='step3'
      style={{
        width: '100%',
        padding: '0px 30px'
      }}
    >
      {columns && (
        <TableView
          data={data}
          setCurrentStep={setCurrentStep}
          setFinalData={setFinalData}
          flow={flow}
          config={config}
          history={history}
          companyIdCA={companyId}
        />
      )}
    </div>
  );
};

export default Step3;
