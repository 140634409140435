import React, { useState } from 'react';
import {
  Card,
  Breadcrumb,
  Button,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Row,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import styles from './AddCredits.module.scss';
import Banner from '../../../../../../core-components/Banner';
import { convertToIndianNumeration } from '../../../../../../utils/utilities';

export function AddCredits(props) {
  const [manualRecharge, setManualRecharge] = useState(null);
  const [error, setError] = useState(true);

  const { offset, insuffCredits, obj, availableBalance, setAmount, amount, companyBalance } = props;

  const handleChange = (value) => setAmount(value),
    balance =
      availableBalance < 0
        ? Math.abs(companyBalance + availableBalance)
        : companyBalance <= 0
        ? Math.abs(companyBalance)
        : availableBalance;

  const getRechargeOptions = () =>
    Array(3)
      .fill(null)
      .map((_, i) =>
        balance == 0
          ? (i + 1) * 500
          : i
          ? Math.ceil(Math.abs(balance) / 500) * 500 + i * 500
          : Math.abs(balance)
      );
  const Warning = () => {
    return (
      <div className={styles.actionsContainer}>
        <p>
          {obj['VERIFICATION'] > 0 && (obj['ADDITIONAL_CHARGE'] > 0 || obj['ADD_ON'] > 0) ? (
            <>
              {'Please add '} <span className={styles.rupee}>&#8377;</span>
              {`${convertToIndianNumeration(balance)} to start ${
                insuffCredits.all[offset]?.insuffType?.count
              } pending verifications & clear dues`}
            </>
          ) : (
            <>
              {obj['VERIFICATION'] > 0 && (
                <>
                  {'Please add '} <span className={styles.rupee}>&#8377;</span>
                  {`${convertToIndianNumeration(balance)} to start ${
                    insuffCredits.all[offset]?.insuffType?.count
                  } pending verifications`}
                </>
              )}

              {(obj['ADDITIONAL_CHARGE'] > 0 || obj['ADD_ON'] > 0) && (
                <>
                  {'Please add '} <span className={styles.rupee}>&#8377;</span>
                  {`${convertToIndianNumeration(balance)} to clear dues`}
                </>
              )}
            </>
          )}
        </p>
      </div>
    );
  };

  const validateAmount = (amt) => {
    if (availableBalance <= 0 && companyBalance > 0 && amt < Math.abs(balance)) {
      setError(true);
    } else if (availableBalance <= 0 && companyBalance <= 0 && amt < Math.abs(balance)) {
      setError(true);
    } else if (companyBalance === 0 && amt < Math.abs(balance)) {
      setError(true);
    } else if (!amt || amt <= 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleAmountChange = (e) => {
    if (!Number.isInteger(Number(e.target.value)) || e.target.value.includes('.')) {
      return;
    } else if (e?.target?.value?.trim()) {
      validateAmount(parseInt(e.target.value));
    }
    props?.setAmount(e.target.value.trim() > '0' ? parseInt(e.target.value) : '');
  };

  const getRechargeAmount = () => {
    let cost = 0;
    if (availableBalance <= 0 && companyBalance > 0) {
      cost = balance;
    } else if (availableBalance <= 0 && companyBalance <= 0) {
      cost = balance;
    } else if (companyBalance === 0) {
      cost = balance;
    }
    return Math.abs(cost);
  };
  const getErrorMsg = () => {
    const val = getRechargeAmount();
    if (!amount) {
      return <p className={styles.error}>Please enter amount</p>;
    } else if (!Number.isInteger(amount)) {
      return <p className={styles.error}>Please enter valid amount</p>;
    } else if (Number.parseInt(amount) < 0 || Number.parseInt(amount) === 0) {
      return <p className={styles.error}>Please enters valid amount</p>;
    }
  };
  return (
    <Container className={styles.container}>
      <Card className={styles.card}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item
            className={styles.breadcrumb_item}
            onClick={() => {
              props.setCurrentStep(1);
              setAmount(0);
            }}
          >
            Credits
          </Breadcrumb.Item>
          <Breadcrumb.Item className={styles.breadcrumb_item} active>
            Add credits
          </Breadcrumb.Item>
          <Breadcrumb.Item className={styles.breadcrumb_item}>Review order</Breadcrumb.Item>
        </Breadcrumb>

        <Card.Body className={styles.body}>
          <Card.Title className={styles.title}>Add credits</Card.Title>

          {insuffCredits.all[offset] &&
          insuffCredits.all[offset].insuffType &&
          insuffCredits.all[offset].insuffType.count ? (
            <Row>
              <Banner clsName={'addCreditsWarning'} margin='-15px'>
                <Warning />
              </Banner>
            </Row>
          ) : null}

          <Card.Text className={styles.balance}>
            Available balance:{' '}
            <span className={styles.rupee}>{companyBalance < 0 && '-'}&#8377;</span>
            <span style={{ fontWeight: 'bold' }}>
              {convertToIndianNumeration(companyBalance < 0 ? companyBalance * -1 : companyBalance)}
            </span>
          </Card.Text>

          <Card.Text className={styles.amount}>Choose an amount: </Card.Text>
          <Row className={styles.radioButtonContainer}>
            <ToggleButtonGroup
              type='radio'
              name={'options'}
              size={'lg'}
              value={props?.amount}
              onChange={handleChange}
            >
              {getRechargeOptions().map((price, i) => (
                <ToggleButton
                  key={i}
                  value={price}
                  variant={`${props?.amount == price ? 'outline-primary' : 'outline-dark'}`}
                  className={styles.btn}
                  onClick={() => {
                    setManualRecharge(false);
                    setError(false);
                  }}
                >
                  {`${convertToIndianNumeration(price)}${!i && balance < 0 ? '(min)' : ''}`}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            {manualRecharge ? (
              <div>
                <InputGroup size='sm' className={styles.input}>
                  <InputGroup.Prepend className={styles.rupee}>
                    <InputGroup.Text id='inputGroup-sizing-default'>&#8377;</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label='Large'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Enter an amount'
                    type={'text'}
                    value={amount}
                    onChange={handleAmountChange}
                  />
                </InputGroup>

                {error ? getErrorMsg() : null}
              </div>
            ) : (
              <Button
                variant='outline-dark'
                className={styles.other}
                onClick={() => {
                  setManualRecharge(true);
                  props?.setAmount('');
                }}
                size={'lg'}
              >
                Other
              </Button>
            )}
          </Row>
          <Button
            variant='primary'
            disabled={error || !amount}
            onClick={() => props?.setCurrentStep(3)}
            className={styles.continue}
          >
            {'Continue'}
          </Button>
        </Card.Body>
      </Card>
      <Notes />
    </Container>
  );
}

export const Notes = () => {
  return (
    <Card className={styles.note}>
      <Card.Body className={styles.card_body}>
        <Card.Title className={styles.h5}>NOTE</Card.Title>
        <ul>
          <li>Credits can't be transferred to any account or be withdrawn in the form of cash</li>

          <li>
            Credits can only be used by your company to purchase background checks for your
            candidates
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};
