import { connect } from 'react-redux';
import {
  fetchUsageLogs,
  setUsageLogs,
  invalidateUsageLogs
} from './../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    usageLogs: state.usageLogs,
    companyId: state?.profile?.profile?.company_user_mapping?.company_id ? state?.profile?.profile?.company_user_mapping?.company_id : ''
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsageLogs: (companyId, limit = 10, offset = 0) => dispatch(fetchUsageLogs(companyId, limit, offset)),
    setUsageLogs: () => dispatch(setUsageLogs()),
    invalidateUsageLogs: () => dispatch(invalidateUsageLogs())
  };
};
export default connect(mapStateToProps, mapDispatchToProps);
