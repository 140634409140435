import { mergeWith, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { errToastMessage, toCamelCase } from '../../../../../utils/utilities';
import validationSchema from './ReferAndEarn.validation';
import {
  getBgvCandidateDetails as getBgvCandidateDetailsAPI,
  saveHrRefer
} from '../../../../../actions/bgv';
import { referHr } from '../../../../../api/bgv';
const mapStateToProps = (state) => {
  const initialCandidateDetails = !isEmpty(state.initialCandidateDetails.data)
    ? toCamelCase(state.initialCandidateDetails.data)
    : null;
  return {
    initialCandidateDetailsFeedback: initialCandidateDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBgvCandidateDetails: () => dispatch(getBgvCandidateDetailsAPI()),
    saveHrRefer: (data) => dispatch(saveHrRefer(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        contacts: [
          {
            name: '',
            source: '',
            country_code: '+91',
            phone_number: '',
            email_id: '',
            is_referred_anonymous: false,
            is_valid_phone: true,
            email_existance: false,
            email_duplicate: false
          }
        ]
      };

      return mergeWith({}, storedValues, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      const payload = {};
      payload['contacts'] = values.contacts.map((contact) => {
        return {
          name: contact.name,
          email_id: contact?.email_id,
          source: contact?.source.value,
          phone_number: contact?.phone_number
            ? `${contact?.country_code}${contact?.phone_number}`
            : '',
          is_referred_anonymous: contact.is_referred_anonymous
        };
      });
      referHr(payload)
        .then((response) => {
          props.saveHrRefer(payload.contacts);
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          errToastMessage('Unable to save referral, please try after some time.');
        });
    },
    displayName: 'ReferAndEarn'
  })
);
