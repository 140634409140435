import { connect } from 'react-redux';
import { getCompanyPaymentDetails } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails(true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
