import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';

import Image from '../../../../core-components/Image';

import styles from './BGVConfirmDialog.module.scss';

export default (props) => {
  return (
    <Modal
      data-testid="bgv-modal"
      size='md'
      centered
      show={props.showConfirmation}
      onHide={() => props.handleHide()}
      className={styles.consentConfirmModal}
      backdropClassName={'customBGVModalBackdrop'}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <Row className={styles.confirModalBody}>
          <Col lg={2} md={2} sm={2} xs={2} className={styles.questionImage}>
            <Image name='bgv_final_confirm.svg' />
          </Col>
          <Col lg={10} md={10} sm={10} xs={10} className={styles.confirmTextContainer}>
            <p className={styles.confirmModalHeading}>
              <span>Ready to submit the form?</span>
            </p>
            <p className={styles.confirmText}>
              Please make sure that all the information you’ve provided is correct before you submit
              the form
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className={styles.confirmModalFooter}>
        <Button
          type='button'
          className={props.enableBtn ? styles.cancelConfirmBtn : styles.disabledCancelConfirmBtn}
          disabled={!props.enableBtn}
          onClick={() => props.handleHide()}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={() => props.handleConfirmSubmit(props)}
          className={props.enableBtn ? styles.activeConfirmBtn : styles.disabledConfirmBtn}
          disabled={!props.enableBtn}
        >
          {props.isSubmitting ? 'Submitting...' : 'Submit form'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
