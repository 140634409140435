import React, { useEffect, useMemo, useState } from 'react'
import Image from '../../../../core-components/Image';
import { Button, Modal } from 'react-bootstrap';
import pluralize from 'pluralize';
import styles from './AdcInfoModal.module.scss';
import { Skeleton } from '@material-ui/lab';

const images = require.context('../../../../images/memes/', true, /\.(png|jpe?g|svg|gif)$/);

const AdcInfoModal = ({ isOpen, onClose, adcNotification, navigateToAdc }) => {

    const { currentAdcDelayInDays = null, manualPendingAdcDelayInDays = null, allAdcDelayInDays = null } = adcNotification;
    const [imageUrl, setImageUrl] = useState('');
    const [isMemeLoading, setMemeLoading] = useState(true);

    const delayCountsLength = Object.keys(adcNotification).filter(key => typeof adcNotification[key] === "number" && adcNotification[key] > 0)?.length;
    const textCenter = delayCountsLength <= 2 ? 'text-center' : '';

    useEffect(() => {
        const fetchImages = () => {
            const imageNames = images.keys();
            const randomIndex = Math.floor(Math.random() * imageNames.length);
            const randomImageUrl = images(imageNames[randomIndex]);
            setImageUrl(randomImageUrl);
        };
        fetchImages();
    }, [])


    return (
        <>
            <Modal
                size='lg'
                show={isOpen}
                dialogClassName={styles.AdcInfoModal}
                onHide={onClose}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop='static'
            >
                <Modal.Header className={styles.AdcModalHeader}>
                    <Image name="clock.svg" />
                    <button className={styles.CloseModalBtn} onClick={onClose} type='button'> <Image name='close.svg' /> </button>
                </Modal.Header>

                <Modal.Body className={styles.AdcModalBody}>
                    <p className={styles.AdcModalContent}>
                        Set additional costs on Auto-Approval mode for <br /> faster candidate verification.
                    </p>
                    <div className='d-flex justify-content-center w-100'>
                        <Button variant="outline-primary" onClick={navigateToAdc} className={styles.SetAdcBtn}>Set now</Button>
                    </div>

                    {
                        (delayCountsLength <= 0 && imageUrl) &&
                        <div className={styles.MemesContainer} >
                            <div className={styles.delayInfoText}>Manually approving additional costs leads to 15-30 days of delays per check.</div>
                            <div className={styles.MemeMask} style={{ background: 'url(' + imageUrl + ')' }}>
                                {
                                    isMemeLoading && <Skeleton animation='wave' className={styles.memeSkeleton} variant="rounded" width={286} height={256} />
                                }
                                <img src={imageUrl} alt='meme' onLoad={() => { setMemeLoading(false) }} />
                            </div>
                        </div>
                    }
                    {
                        delayCountsLength > 0 &&
                        <div className={styles.ApprovalDelayInfoContainer}>
                            <p className={styles.LostText}>You lost</p>

                            <ul className={styles.ApprovalDelayStats}>
                                {
                                    currentAdcDelayInDays ? <li className={textCenter} >
                                        <span>
                                            <Image name="astonishedEmoji.svg" />
                                        </span>
                                        <h6>{currentAdcDelayInDays} {pluralize('day', currentAdcDelayInDays)}</h6>
                                        <p>on this charge</p>
                                    </li> : <></>
                                }
                                {
                                    manualPendingAdcDelayInDays ? <li className={textCenter}>
                                        <span>
                                            <Image name="screamEmoji.svg" />
                                        </span>
                                        <h6>{manualPendingAdcDelayInDays} {pluralize('day', manualPendingAdcDelayInDays)}</h6>
                                        <p>on all pending charge</p>
                                    </li> : <></>
                                }
                                {
                                    allAdcDelayInDays ? <li className={textCenter}>
                                        <span>
                                            <Image name="explodingHeadEmoji.svg" />
                                        </span>
                                        <h6>{allAdcDelayInDays} {pluralize('day', allAdcDelayInDays)}</h6>
                                        <p>on all charges till date</p>
                                    </li> : <></>
                                }
                            </ul>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdcInfoModal