import React from 'react';
import Image from 'react-bootstrap/Image';
import { ReactSVG } from 'react-svg';

export default (props) => {
  return props.svg ? (
    <ReactSVG src={require(`../../images/${props.name}`)} {...props} />
  ) : props.name ? (
    <Image src={require(`../../images/${props.name}`)} {...props} data-testid={props.testId} />
  ) : (
    <Image {...props} data-testid={props.testId} />
  );
};
