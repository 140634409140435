import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ChangePassword.module.scss';
import { withRouter } from 'react-router-dom';
import Button from '../../../../../core-components/Button/Button';
import Loader from '../../../../../core-components/Loader/Loader';

export default withRouter((props) => {
  const { expirePassword = {}, reqExpirePassword, history } = props;
  const { loading = false } = expirePassword;

  const handleResetPassword = async () => {
    const body = {};

    try {
      await reqExpirePassword(body);
      localStorage.clear();
      sessionStorage.clear();
      // eslint-disable-next-line no-unused-expressions
      history?.push('/signIn?flow=changePassword');
    } catch (err) {
      console.error('err: ', err);
    }
  };

  return (
    <>
      <Modal
        show={props?.show}
        size='lg'
        onHide={props?.onHide}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        dialogClassName={styles.CPmodal}
      >
        <Modal.Header className={styles.modalHeader} closeButton>
          <div className={styles.modalTitle}>Change Password?</div>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <p className={styles.content}>
            Your current password will expire and you will be logged out from SpringVerify. You will
            receive an email to set a new password.
          </p>
          <h6 className={styles.confirmHeader}>Are you sure?</h6>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button className={styles.cancelButton} onClick={props.onHide}>
            Cancel
          </Button>
          <Button className={styles.submitButton} onClick={handleResetPassword}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
      {loading && (
        <div className={styles.overlayLoader}>
          <Loader />
        </div>
      )}
    </>
  );
});
