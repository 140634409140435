import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PurchaseConfirmModal from '../../../Packages/Prepaid/ConfirmationModal';
import CompanyTds from '../../../CompanyTds';
import { buyPackage, finalizeRazorpayPayment } from '../../../../../api/company';
import ConfimrationTdsModal from '../../../../../core-components/ConfirmationModalTds';
import {
  calculateTDS,
  getErrorMessage,
  calculateTax,
  convertToIndianNumeration,
  hasDot,
  errToastMessage
} from '../../../../../utils/utilities';
import Image from '../../../../../core-components/Image';
import styles from './Summary.module.scss';

function Summary(props) {
  const { setShowLoader } = props;

  const [useCreditBalance, setUseCreditBalance] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [showPurchaseConfirmModal, setShowPurchaseConfirmModal] = useState(false);
  const [showConfirmationTdsModal, setShowConfirmationTdsModal] = useState(false);
  const [tdsValue, setTdsValue] = useState('NONE');

  // TDS config for this company selected fro SA side
  const tdsFromSAConfig = props?.paymentDetails?.tds || null;
  const renderCredit = () => {
    return (
      <>
        <div className={styles.creditBalanceContainer}>
          <Form.Group controlId='useCreditBalance' className={styles.formGroup}>
            <Form.Check
              name='useCreditBalance'
              id='useCreditBalance'
              type='checkbox'
              checked={useCreditBalance}
              onChange={(e) => {
                setUseCreditBalance(e.target.checked);
                useCreditsCheckBoxHandler();
                props?.invalidatePaymentCreditTransaction();
              }}
              label={
                <>
                  <div className={styles.creditBalanceText}>
                    Use credit balance of <span className={styles.rupee}>&#8377;</span>
                    {convertToIndianNumeration(props?.creditBalance)}
                  </div>
                </>
              }
            />
          </Form.Group>
        </div>
      </>
    );
  };

  const useCreditsCheckBoxHandler = () => {
    if (getValidity()) {
      setTdsValue('NONE');
    }
  };
  // razarpay flow without TDS options
  const handlePurchase = (payableAmount = 0) => {
    setPayableAmount(payableAmount);
    setShowPurchaseConfirmModal(true);
  };

  // doing payment without TDS flow
  const paymentWithoutTds = (showTax, payableAmount) => {
    handlePurchase(!showTax ? 0 : payableAmount);
  };

  // doing payment with TDS flow
  const paymentWithTds = (payableAmount) => {
    setPayableAmount(payableAmount);
    setShowConfirmationTdsModal(!showConfirmationTdsModal);
  };

  // calculate totalAmt,taxAmount,payableAmount, tdsAmount
  const getSummaryDetails = () => {
    let totalAmt =
      props.selectedOption?.candidateCount * props.selectedOption?.costPerCandidate || 0;
    let taxAmount = props?.paymentDetails?.tax;
    let tax = calculateTax(totalAmt, taxAmount);
    let showTax = true;
    let payableAmount = totalAmt + tax;
    return { totalAmt, taxAmount, tax, showTax, payableAmount };
  };
  const renderSummary = () => {
    let { totalAmt, taxAmount, tax, showTax, payableAmount } = getSummaryDetails();
    if (useCreditBalance) {
      totalAmt = totalAmt - props?.creditBalance;
    }
    if (props?.creditBalance < 0) {
      totalAmt = totalAmt + Math.abs(props?.creditBalance);
    }
    if (totalAmt <= 0) {
      showTax = false;
    } else {
      tax = calculateTax(totalAmt, taxAmount);
      payableAmount = tax + totalAmt;
    }
    let tdsAmount = calculateTDS(totalAmt, tdsValue === 'NONE' ? 0 : tdsValue); // calculate tds on total amount

    return (
      <div className={styles.summaryDetailsContainer}>
        <p className={styles.heading}>{'Summary'}</p>
        <div className={styles.packageDetailsContainer}>
          <p className={styles.packageNameContainer}>
            <span className={styles.subTypeName}>{props.selectedPackage?.subtypeName}</span>
            <span className={styles.costContainer}>
              <span className={styles.rupee}>&#8377;</span>
              {convertToIndianNumeration(
                props.selectedOption?.candidateCount * props.selectedOption?.costPerCandidate
              ) + '.00' || 0}
            </span>
          </p>

          <p className={styles.candidateCount}>{`${
            convertToIndianNumeration(props.selectedOption?.candidateCount) || 0
          } candidates`}</p>

          <p className={styles.costPerCandidate}>
            <span className={styles.rupee}>&#8377;</span>
            {`${
              convertToIndianNumeration(props.selectedOption?.costPerCandidate) || 0
            } per candidate`}
          </p>

          {props?.creditBalance < 0 ? (
            <p className={styles.summaryCreditBalance}>
              <span className={`${styles.fontWeightNormal} ${styles.taxText}`}>
                {'Previous Unpaid Balance'}
              </span>
              <span className={`${styles.fontWeightNormal} ${styles.taxText}`}>
                <span className={styles.rupee}>&#8377;</span>
                {`${convertToIndianNumeration(Math.abs(props?.creditBalance)) + '.00'}`}
              </span>
            </p>
          ) : null}
          {showTax ? (
            <p className={styles.summaryCreditBalance}>
              <span className={`${styles.fontWeightNormal} ${styles.taxText}`}>
                {`Tax (${props?.paymentDetails?.tax}%)`}
              </span>
              <span className={`${styles.fontWeightNormal} ${styles.taxText}`}>
                <span className={styles.rupee}>&#8377;</span>
                {convertToIndianNumeration(tax || 0) || 0}
                {!hasDot(tax) ? '.00' : ''}
              </span>
            </p>
          ) : null}

          {/*  to show tds label , tdsCost > 0 and tds value shoul be 2% or 10%  */}
          {tdsAmount > 0 && tdsValue != 'NONE' ? (
            <p className={styles.summaryCreditBalance}>
              <span
                className={`${styles.fontWeightNormal} ${styles.taxText}`}
              >{`TDS (${tdsValue}%)`}</span>
              <span className={`${styles.fontWeightNormal} ${styles.taxText}`}>
                <span className={styles.rupee}>-&#8377;</span>
                {convertToIndianNumeration(tdsAmount || 0) || 0}
                {!hasDot(tdsAmount) ? '.00' : ''}
              </span>
            </p>
          ) : null}

          {useCreditBalance ? (
            <p className={styles.summaryCreditBalance}>
              <span className={styles.fontWeightNormal}>{'Credit balance'}</span>
              <span className={styles.fontWeightNormal}>
                {'-'}
                <span className={styles.rupee}>&#8377;</span>
                {showTax
                  ? convertToIndianNumeration(props?.creditBalance)
                  : convertToIndianNumeration(
                      props.selectedOption?.candidateCount * props.selectedOption?.costPerCandidate
                    )}
              </span>
            </p>
          ) : null}
        </div>

        <div className={styles.addBtnContainer}>
          <Button
            variant={'primary'}
            className={styles.payBtn}
            onClick={() =>
              tdsValue === 'NONE'
                ? paymentWithoutTds(showTax, payableAmount)
                : paymentWithTds(payableAmount - tdsAmount)
            }
          >
            <Image name={'lock_guard_white.svg'} />
            &nbsp;
            <span>{'Pay'}</span>&nbsp;
            <span className={styles.rupee}>&#8377;</span>
            {!showTax ? 0 : convertToIndianNumeration(payableAmount - tdsAmount) || 0}
            {!showTax ? '' : !hasDot(payableAmount - tdsAmount) ? '.00' : ''}
          </Button>
        </div>
      </div>
    );
  };

  const onPaymentSuccess = () => {
    props?.setPaymentStatus('completed');
    setShowPurchaseConfirmModal(false);
    props?.setCandidateCount(props.selectedOption?.candidateCount);
  };

  //Wont trigger Razorpay if package can be bought with just credits
  const buyPackageWithCredits = () => {
    buyPackage({
      recharge_options_id: props.selectedOption?.id,
      use_credits: true
    })
      .then(() => {
        onPaymentSuccess();
      })
      .catch((error) => {
        console.log(error);
        errToastMessage(error);
      });
  };

  const finalizeRazorpayPaymentApi = (payload) => {
    finalizeRazorpayPayment(payload).catch((error) => {
      console.log(getErrorMessage(error));
    });
  };

  // razarpay payment object
  const getrazarPaymentObject = () => {
    return {
      createOrder: buyPackage,
      createOrderPayload: {
        recharge_options_id: props.selectedOption?.id,
        use_credits: props?.creditBalance < 0 ? true : useCreditBalance,
        tds_percentage: tdsValue === 'NONE' ? 0 : parseInt(tdsValue)
      },
      payableAmount: payableAmount.toFixed(2) || 0,
      buttonText: 'Confirm',
      displayValue: tdsValue != 'NONE' ? '' : payableAmount.toFixed(2) || 0,
      onSuccess: finalizeRazorpayPayment,
      onFailure: finalizeRazorpayPaymentApi,
      setPaymentStatus: props?.setPaymentStatus,
      onPaymentSuccess: onPaymentSuccess,
      setOrderId: props?.setOrderId,
      setPurchasedPackageName: props?.setPurchasedPackageName
    };
  };
  /** this funtion does the below validity check
   * if compnay balance >= purchase amount and user using credits then NONE will get selected and other options disable
   *                      if user not using credits then dont set TDS value to NONE and dont disable TDS radio options
   *
   * if company balance < purchase amount, enable TDS options
   */
  const getValidity = () => {
    let { totalAmt, taxAmount, tax, payableAmount } = getSummaryDetails();
    let creditBalance = props?.creditBalance || 0;
    if (useCreditBalance) {
      totalAmt = totalAmt - creditBalance;
    }
    if (creditBalance < 0) {
      totalAmt = totalAmt + Math.abs(creditBalance);
    }
    if (totalAmt <= 0) {
      payableAmount = 0;
    } else {
      tax = calculateTax(totalAmt, taxAmount);
      payableAmount = tax + totalAmt;
    }

    let result = false;

    if (creditBalance >= payableAmount) {
      if (useCreditBalance && payableAmount !== 0) {
        result = false;
      }
      if (useCreditBalance && payableAmount === 0) {
        result = true;
      } else if (!useCreditBalance) {
        result = false;
      }
    } else if (creditBalance < payableAmount) {
      result = false;
    }
    return result;
  };
  return (
    <>
      <div className={styles.creditTdsContainer}>
        {props?.creditBalance && props.creditBalance > 0 ? renderCredit() : null}
        {
          //render TDS options only if 2% or 10%  is selected from SA side for that company not for NONE selection
          tdsFromSAConfig != null && tdsFromSAConfig != '' && tdsFromSAConfig !== 'NONE' && (
            <CompanyTds
              disableOption={getValidity()}
              setTdsValue={setTdsValue}
              useCreditBalance={useCreditBalance}
            />
          )
        }
      </div>
      {renderSummary()}
      {/* 
          if credit balance is -ve then,  pass use_credits and useCreditBalance as true by default
        */}
      {showPurchaseConfirmModal ? (
        <PurchaseConfirmModal
          useCreditBalance={props?.creditBalance < 0 ? true : useCreditBalance}
          price={parseFloat(payableAmount.toFixed(2))}
          show={showPurchaseConfirmModal}
          onHide={() => {
            setShowPurchaseConfirmModal(false);
          }}
          setShowLoader={setShowLoader}
          onConfirm={() => buyPackageWithCredits()}
          creditBalance={parseFloat(props?.creditBalance)}
          selectedOption={props?.selectedOption}
          paymentsObj={getrazarPaymentObject()}
          setTransactionId={props?.setTransactionId}
        />
      ) : null}
      {showConfirmationTdsModal && (
        <ConfimrationTdsModal
          setTransactionId={props?.setTransactionId}
          show={showConfirmationTdsModal}
          onClose={() => setShowConfirmationTdsModal(!showConfirmationTdsModal)}
          paymentsObj={getrazarPaymentObject()}
          setShowLoader={setShowLoader}
        />
      )}
    </>
  );
}

export default Summary;
