import React, { useEffect } from 'react';
import { OverlayTrigger, Form, Tooltip } from 'react-bootstrap';
import { Field } from 'formik';
import Image from '../../../core-components/Image';
import ValidatedRadioFields from '../../../core-components/ValidatedRadioFields';
import { TDS_TOOLTIP } from '../../../utils/commonConstant';
import { getTdsOptions } from '../../../utils/utilities';
import styles from './CompanyTds.module.scss';
export default (props) => {
  const { useCreditBalance, setFieldValue, setTdsValue, tdsCost, values, disableOption } = props;

  /**
   * if use selects use credit balance checkbox  then set companyTds variable to NONE by default and disable other options
   */
  useEffect(() => {
    if (disableOption) {
      setFieldValue('companyTds', 'NONE');
      setTdsValue('NONE');
    }
  }, [disableOption]);

  return (
    <div className={styles.tdsConainer}>
      <span>
        Apply TDS?
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip id={'tdsTooltip'}><p>{TDS_TOOLTIP}</p></Tooltip>}
        >
          <Image name='InfoIcon.svg' style={{ marginLeft: '5px' }} />
        </OverlayTrigger>
        {/* this cost only comes from add candidate flow */}
        {parseInt(tdsCost) > 0 && (
          <span
            className={`${styles.tdsCost}    ${values?.tdsFromSAConfig === 'BOTH' ? styles.tdsCostBoth : styles.tdsCostNotBoth
              }`}
          >
            -&#8377;{tdsCost}
          </span>
        )}
      </span>
      <div className={styles.tdsOptions}>
        <Form>
          <Field
            name='companyTds'
            component={ValidatedRadioFields}
            handleChangeValue={setTdsValue}
            options={getTdsOptions(values?.tdsFromSAConfig, disableOption)}
          />
        </Form>
      </div>
    </div>
  );
};
