// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CRKKzoUEmhMaULCfrupN {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  padding: 6px 0;
  max-width: 350px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.CRKKzoUEmhMaULCfrupN .I7cUckj7B9yG3xRcMniA {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 14px;
}
.CRKKzoUEmhMaULCfrupN .RnJl4yvc2IV1Ksr0hjVI {
  float: right;
  font-family: roboto;
  color: #999999;
  font-size: 14px;
}
.CRKKzoUEmhMaULCfrupN .wvK4y_HyLVAvCOeGAxBN {
  margin-right: -58px;
}
.CRKKzoUEmhMaULCfrupN .IXHX8QVZx4h33mgRz_3Q {
  margin-right: -116px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/CompanyTds/CompanyTds.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAEJ;AACE;EACE,YAAA;EAEA,mBAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,oBAAA;AAFJ","sourcesContent":[".tdsConainer {\n  color: #333333;\n  font-weight: 400;\n  font-size: 14px;\n  padding: 6px 0;\n  max-width: 350px;\n  margin-left: 20px;\n  margin-top: 10px;\n  display: flex;\n  flex-direction: column;\n  .tdsOptions {\n    display: flex;\n    flex-direction: row;\n    margin-left: 20px;\n    margin-top: 14px;\n  }\n\n  .tdsCost {\n    float: right;\n\n    font-family: roboto;\n    color: #999999;\n    font-size: 14px;\n  }\n\n  .tdsCostBoth {\n    margin-right: -58px;\n  }\n\n  .tdsCostNotBoth {\n    margin-right: -116px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tdsConainer": `CRKKzoUEmhMaULCfrupN`,
	"tdsOptions": `I7cUckj7B9yG3xRcMniA`,
	"tdsCost": `RnJl4yvc2IV1Ksr0hjVI`,
	"tdsCostBoth": `wvK4y_HyLVAvCOeGAxBN`,
	"tdsCostNotBoth": `IXHX8QVZx4h33mgRz_3Q`
};
export default ___CSS_LOADER_EXPORT___;
