import React from 'react';
import styles from './AlumniUrlComponent.module.scss';
import { Card } from 'react-bootstrap';
import TextWithCopyButton from '../../Integrations/Utils/TextWithCopyButton';
import AppHelper from '../../../../../core-components/AppHelper';

export default function AlumniUrlComponent(props) {
  const url = props?.isSA
    ? props?.alumniDetails?.alumniVerificationUrl
    : props?.paymentDetails?.alumniVerificationUrl;

  return (
    <Card className={styles.urlContainerWrapper}>
      <div className={styles.containerFlex}>
        <div className={styles.urlItem}>
          <div className={styles.urlText}>
            <span>Alumni Verification URL: </span>
            <TextWithCopyButton
              isSA={props?.isSA}
              text={
                url
                  ? `https://${url}.${
                      AppHelper.getEnv() === 'prod'
                        ? ''
                        : AppHelper.getEnv().replace('accept', 'acceptance') + '-'
                    }exemp.in.springverify.com`
                  : 'No URL is present'
              }
              number={76}
              className={styles.textWithCopyButton}
            />
          </div>
          <div className={styles.urlNote}>
            <span className={styles.noteText}>Note:</span> Send this URL to anyone who wants to
            verify an Alumni. You can also customise this page to suit your brand.
          </div>
        </div>
        <div
          onClick={() => {
            if (props?.isSA) {
              window.open(
                `company/settings?tab=ALUMNI_VERIFICATION&company_id=${props?.companyDetails?.id}`,
                '_blank'
              );
            } else {
              window.open(`settings?tab=avt`, '_blank');
            }
          }}
          className={styles.customizeItem}
        >
          Customize this page
        </div>
      </div>
    </Card>
  );
}
