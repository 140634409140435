export default (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.value };
    case 'SET_LOADING':
      return { ...state, loading: action.value };
    case 'SET_PAGINATION':
      return { ...state, limit: action.limit, offset: action.offset };
    case 'EDIT_ROW_DATA': {
      let newData = state.data;
      newData[action.rowIndex] = action.value;

      return { ...state, data: newData };
    }
    case 'DELETE_TOKEN_DATA': {
      let newData = state.data;
      newData.splice(action.rowIndex, 1);

      return { ...state, data: newData };
    }
  }
};
