import React, { useEffect, useState } from 'react';
import { Card, Form, Row } from 'react-bootstrap';
import {
  calculateTDS,
  getTdsOptions,
  indianNumerationValue
} from '../../core-components/Utilities/utilities';
import styles from './Summary.module.scss';
import cn from 'classnames';

export default (props) => {
  const { values, setFieldValue } = props;
  const [calculatedTDSAmount, setCalculatedTDSAmount] = useState(0);

  useEffect(() => {
    let amount = calculateTDS(
      props.totalAmount.withoutTax,
      values?.tdsPercentage === 'NONE' ? 0 : parseInt(values?.tdsPercentage)
    );
    setCalculatedTDSAmount(amount);
  }, [values, props.totalAmount]);

  if (props?.paymentDetails?.tds == 'NONE' || !props?.paymentDetails?.tds) {
    return null;
  }
  return (
    <Card className={styles.rightContainerTds}>
      <div className={cn(styles.tdsContainer, props.mask ? styles.maskDiv : '')}>
        <div className={styles.summaryTitles}>APPLY TDS</div>
        <hr className={styles.hrTag} />
        <div className={styles.tdsOptions}>
          <Form style={{ display: 'flex' }}>
            {getTdsOptions(props?.paymentDetails?.tds || 'NONE', props.tdsRadioDisabled).map(
              (option, i) => {
                return (
                  <Form.Check
                    name='tdsPercentage'
                    className={styles.option}
                    custom
                    onChange={(e) => {
                      setFieldValue('tdsPercentage', e.target.value);
                    }}
                    value={option.value}
                    key={i}
                    type='radio'
                    id={option.value}
                    label={<p className={styles.tdsOptionText}>{option.label}</p>}
                    checked={String(option.value) === values.tdsPercentage}
                    disabled={option.disable}
                  />
                );
              }
            )}
          </Form>
          <div className={styles.amount}>{indianNumerationValue(calculatedTDSAmount, 2)}</div>
        </div>
        <div className={styles.selectedItem}>
          <div className={styles.summaryTitles}>TOTAL PAYABLE</div>
          <div className={styles.totalAmount}>
            {indianNumerationValue(props.totalAmount.withTax - calculatedTDSAmount, 2)}
          </div>
        </div>
      </div>
    </Card>
  );
};
