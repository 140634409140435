import React, { useEffect, useState } from 'react';
import classes from './ReferAndEarn.module.scss';
import { Card } from 'react-bootstrap';
import ReferAndEarnBanner from './Banners/ContactFormPageBanner/ReferAndEarnBanner';
import ReferAndEarnTable from './Table';
import { CA_REFERRAL_AMOUNT } from '../../../utils/commonConstant';
import ReferAndEarnInput from './Form';
import HRReferredInfoBanner from './Banners/HRReferredInfoBanner/HRReferredInfoBanner';

export default function ReferAndEarn(props) {
  const [showReferredInfo, setShowReferredInfo] = useState(0);

  const handleShowReferredInfo = (count) => {
    setShowReferredInfo(count);
  };

  useEffect(() => {
    if (!props?.paymentDetails?.isReferralEnabled) {
      props.history.push('/dashboard');
    }
    props.getPaymentDetails();
  }, []);
  return (
    <div className={classes.referralWrapper}>
      <div className={classes.header}>
        <div className={classes.heading}>{'Refer and Earn'}</div>
      </div>
      <Card className={classes.referCard}>
        {showReferredInfo > 0 && (
          <HRReferredInfoBanner count={showReferredInfo} email={props?.profile?.email} />
        )}
        <ReferAndEarnBanner amount={CA_REFERRAL_AMOUNT} />
        <ReferAndEarnInput showReferredInfo={handleShowReferredInfo} />
      </Card>
      {/* <Card className={classes.referCard}>
        <ReferAndEarnTable />
      </Card> */}
    </div>
  );
}
