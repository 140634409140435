import React from 'react';
import Image from '../../../../../core-components/Image';
import styles from './PendingOrdersTable.module.scss';

function EmptyTableBody() {
  return (
    <tbody>
      <tr>
        <td colSpan={5}>
          <div className={styles.noDataWrapper}>
            <Image name={'noPurchaseHistory.svg'} />
            <div className={styles.noDataContent}>{'No Pending Order'}</div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

export default EmptyTableBody;
