// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rt8DuZVW5aubrjfPwGlj {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .Rt8DuZVW5aubrjfPwGlj {
    width: 1200px;
  }
}
.Rt8DuZVW5aubrjfPwGlj .uG0_zcwuWf3mm6d2fHtM {
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.Rt8DuZVW5aubrjfPwGlj .uG0_zcwuWf3mm6d2fHtM .cYAXqwkNe3x88HO2Hklt {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/AlumniVerification/AlumniVerification.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;AACF;AAAE;EAHF;IAII,aAAA;EAGF;AACF;AAFE;EACE,kBAAA;EACA,gCAAA;AAIJ;AAHI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAKN","sourcesContent":[".alumniWrapperWrapper {\n  max-width: 1200px;\n  margin: auto;\n  @media only screen and (max-width: 1200px) {\n    width: 1200px;\n  }\n  .subHeading {\n    margin-top: -190px;\n    font-family: Poppins, sans-serif;\n    .header {\n      font-weight: 500;\n      font-size: 24px;\n      line-height: 36px;\n      color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alumniWrapperWrapper": `Rt8DuZVW5aubrjfPwGlj`,
	"subHeading": `uG0_zcwuWf3mm6d2fHtM`,
	"header": `cYAXqwkNe3x88HO2Hklt`
};
export default ___CSS_LOADER_EXPORT___;
