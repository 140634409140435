import { connect } from 'react-redux';
import { getCompanyPaymentDetails } from '../../../actions/company';
const mapStateToProps = (state) => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
