import _, { flattenDeep, isEmpty } from 'lodash';

// Function to calculate the package combined sum that are selected by the user
export const getPackageAmountTotal = (selectedTiers) => {
  const initialValue = 0;
  let sumWithInitial = 0;
  const arraysTiers = selectedTiers && !isEmpty(selectedTiers) ? Object.values(selectedTiers) : [];
  const flattenedTiers = flattenDeep(arraysTiers);
  sumWithInitial = flattenedTiers
    .map((ap) => ap.candidateCount * ap.costPerCandidate)
    .reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  return sumWithInitial;
};

export const getAmountAndTextArray = (
  {
    currentBalance,
    arrearsTotalAmount,
    arrears,
    recommendedAmount,
    pendingCosts,
    recommendedAmountMessage
  },
  type // passing this type parameter for default selection of amount for radio button
) => {
  const amountArray = []; // Array that stores all amounts and respective texts based on the condition except Custom
  let amount = null; //This will be updated in particular if-else based on the condition and helps in the default selection of radio button on wallet page
  if (arrears && arrearsTotalAmount && arrears.length) {
    amountArray.push({
      value: getArrearsAmount(arrearsTotalAmount, currentBalance),
      text: 'Clear all arrears',
      id: 'option1',
      selected: false
    });
    amount = getArrearsAmount(arrearsTotalAmount, currentBalance);
  } else {
    amountArray.push({ value: 0, text: 'No recharge', id: 'option1', selected: false });
    amount = 0;
  }

  if (pendingCosts > 0 && currentBalance < pendingCosts) {
    if (!arrearsTotalAmount && arrears.length === 0) {
      amountArray.push({
        value: pendingCosts - (currentBalance > 0 ? currentBalance : 0),
        text: 'Required to approve all Additional Cost Requests',
        id: 'option2',
        selected: false
      });
      amount = pendingCosts - (currentBalance > 0 ? currentBalance : 0);
    } else {
      amountArray.push({
        value: pendingCosts + getArrearsAmount(arrearsTotalAmount, currentBalance),
        text: 'Required to approve all Additional Cost Requests and clear arrears',
        id: 'option2',
        selected: false
      });
      amount = pendingCosts + getArrearsAmount(arrearsTotalAmount, currentBalance);
    }
  }

  if (recommendedAmount) {
    amountArray.push({
      value: recommendedAmount,
      text: recommendedAmountMessage,
      id: 'option3',
      selected: false
    });
  }

  return type === 'forAmount' ? `${amount}` : amountArray;
};

export const getArrearsAmount = (arrearsTotalAmount, currentBalance) => {
  // When current balance is negative we have to take zero since negative amount is already being sent in the arrearTotalAmount as unpaid purchases.
  return arrearsTotalAmount - (currentBalance > 0 ? currentBalance : 0);
};

export const getPercentageText = (amount) => {
  var text = '';
  switch (amount) {
    case '50000':
      text = '80';
      break;
    case '100000':
      text = '75';
      break;
    case '200000':
      text = '70';
      break;
    case '300000':
      text = '65';
      break;
    case '400000':
      text = '60';
      break;
    case '500000':
      text = '55';
      break;
    case '600000':
      text = '50';
      break;
    default:
      text = '50';
  }
  return text;
};
