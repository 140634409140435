import React from 'react';
import styles from './CommonStyles.module.scss';
import { BsUpload } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { GrFormClose } from 'react-icons/gr';

const ImageUpload = ({
  style,
  title,
  subtitle,
  logo,
  setLogo,
  setLogoFile,
  setLogoError,
  isSa = false,
  onLogoDelete,
  skipValidation = false
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const imageUrl = URL.createObjectURL(acceptedFiles[0]);
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(acceptedFiles[0].type)) {
          setLogoError('Please provide a valid company logo format (.png, .jpeg, .jpg)');
          return;
        }
        const imageElement = new Image();
        imageElement.src = imageUrl;
        imageElement.onload = function () {
          const imageHeight = imageElement.naturalHeight;
          if (!skipValidation && imageHeight < 60) {
            setLogoError('Upload an image of size more than 60px');
            return;
          }
          setLogoFile(acceptedFiles[0]);
          setLogo(imageUrl);
          if (skipValidation && imageHeight < 60)
            setLogoError('Upload an image of size more than 60px');
          else setLogoError(null);
          return;
        };
      }
    }
  });

  return (
    <div className={isSa ? `${styles.imageUpload} ${styles.widthMax}` : styles.imageUpload}>
      {logo !== '' && (
        <div
          className={styles.logoClose}
          onClick={() => {
            onLogoDelete();
          }}
        >
          <div className={styles.closex}>
            <GrFormClose />
          </div>
        </div>
      )}
      <div
        {...getRootProps()}
        className={styles.logo}
        style={{
          ...style,
          backgroundImage: `url(${logo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <input {...getInputProps()} style={{ display: 'none' }} accept='image/jpeg, image/png' />
        {logo === '' && (
          <>
            <div className={styles.logoIcon}>
              <BsUpload />
            </div>
            <div>{title}</div>
            <div
              style={{
                fontSize: '12px',
                fontWeight: 400,
                color: style.stColor
              }}
            >
              {subtitle}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
