import React from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';
import Modal from 'react-bootstrap/Modal';
import { isEmpty } from 'lodash';

import ValidatedFormInputField from '../../../core-components/ValidatedFormInputField';
import Button from '../../../core-components/Button';
import Image from '../../../core-components/Image';
import validationSchema from './CategoryModal.validation';

import styles from './CategoryModals.module.scss';
import { getTrimmedValue } from '../../../utils/utilities';
import classNames from 'classnames';

const TITLE = ['Edit Category', 'Edit Tag', 'Create Category', 'Create Tag'];
const BUTTON_LABEL = ['Create', 'Save'];
const RESEND_EMAIL_BUTTON = 'Resend Verification Email?';

export default (props) => {
  const {
    handleSubmit,
    isSubmitting,
    errors,
    info,
    title,
    values,
    subTitle,
    dirty,
    touched,
    setTouched
  } = props;

  const isValidForm =
    BUTTON_LABEL.includes(props?.button) && TITLE.includes(props?.title)
      ? !isSubmitting && values.category && isEmpty(errors)
      : !isSubmitting;

  const getErr = () => {
    if (touched?.category && errors?.category) {
      return title?.includes('Tag') ? 'Please enter a tag' : 'Please enter a category';
    }
    return '';
  };

  return (
    <Modal
      size='md'
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{title}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Label>
            {subTitle}
            {props?.title === 'Create Category' ||
            props?.title === 'Edit Category' ||
            props?.title === 'Edit Tag' ||
            props?.title === 'Create Tag' ? (
              <span> *</span>
            ) : (
              ''
            )}
          </Form.Label>
          {props?.button !== 'Delete' && props?.button !== 'Send' ? (
            <Form.Row>
              <Field
                type='text'
                placeholder={props?.placeholder}
                name='category'
                className={classNames(styles.inputField, { [styles.errInput]: getErr() })}
                errors={touched?.category && errors?.category}
                onKeyUp={() => {
                  setTouched({ category: true });
                }}
                onBlur={() => {
                  if (
                    props?.title === 'Create Category' ||
                    props?.title === 'Create Tag' ||
                    props?.title === 'Edit Tag' ||
                    props?.title === 'Edit Category'
                  ) {
                    props?.setFieldValue('category', getTrimmedValue(values?.category, true));
                  }
                }}
              />
              {getErr() && <div className={styles.catErr}>{getErr()}</div>}
            </Form.Row>
          ) : props?.title?.match('Admin') ||
            props?.button === 'Send' ||
            title === 'Delete Candidate ' ? (
            <div className={styles.deleteInfo}></div>
          ) : (
            <div className={styles.deleteInfo}>
              <Image name='InfoIcon.svg' style={{ paddingBottom: '3px' }} />
              <div className={styles.deleteInfoContent}>{info}</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props?.onHide} disabled={isSubmitting} className={styles.cancelButton}>
            Cancel
          </Button>
          {title === 'Delete Candidate ' ? (
            <Button
              type='submit'
              disabled={isSubmitting}
              onClick={() => {
                props.toDelete.isDelete = true;
              }}
              className={props?.button === 'Delete' ? styles.deleteButton : styles.submitButton}
            >
              {props?.button}
            </Button>
          ) : (
            <Button
              type='submit'
              disabled={
                props.button === 'Delete' || props.title === RESEND_EMAIL_BUTTON
                  ? !isValidForm
                  : !isValidForm || !dirty
              }
              className={props.button === 'Delete' ? styles.deleteButton : styles.submitButton}
            >
              {props?.button}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
