// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l99n4amgpSaAzN5yGKir {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.l99n4amgpSaAzN5yGKir .wOhwg1j3a5bt1dRU7l_o {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.l99n4amgpSaAzN5yGKir .V9wP7GkvPeOeNN8SswYm {
  font-family: roboto;
}

.LSe60IvcplXWIzH039wQ {
  border: none;
  padding: 25px 30px 18px !important;
}
.LSe60IvcplXWIzH039wQ .J97PV8cmVKWjFneMuJpV {
  background: #ffffff;
  border: 1px solid #2755fe;
  box-sizing: border-box;
  border-radius: 2px;
  height: 32px;
  color: #2755fe;
}
.LSe60IvcplXWIzH039wQ .nFz0zlDSicOcQEXVYLDo {
  background: #2755fe;
  height: 32px;
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Packages/Prepaid/ConfirmationModal/Confirmation.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;AAAE;EACE,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;;AACA;EACE,YAAA;EACA,kCAAA;AAEF;AADE;EACE,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AAGJ;AADE;EACE,mBAAA;EACA,YAAA;EACA,kBAAA;AAGJ","sourcesContent":[".body {\n  font-family: Poppins;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 22px;\n  color: #595959;\n  .title {\n    font-family: Poppins;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #262626;\n  }\n  .rupee {\n    font-family: roboto;\n  }\n}\n.modalFooter {\n  border: none;\n  padding: 25px 30px 18px !important;\n  .cancelBtn {\n    background: #ffffff;\n    border: 1px solid #2755fe;\n    box-sizing: border-box;\n    border-radius: 2px;\n    height: 32px;\n    color: #2755fe;\n  }\n  .confirmBtn {\n    background: #2755fe;\n    height: 32px;\n    border-radius: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `l99n4amgpSaAzN5yGKir`,
	"title": `wOhwg1j3a5bt1dRU7l_o`,
	"rupee": `V9wP7GkvPeOeNN8SswYm`,
	"modalFooter": `LSe60IvcplXWIzH039wQ`,
	"cancelBtn": `J97PV8cmVKWjFneMuJpV`,
	"confirmBtn": `nFz0zlDSicOcQEXVYLDo`
};
export default ___CSS_LOADER_EXPORT___;
