import * as Yup from 'yup';
import { getCustomNameValidationSchema } from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  universityName: getCustomNameValidationSchema({
    message: 'Please remove specials characters',
    isRequired: true,
    isRequiredMessage: 'University/Board'
  }),
  collegeName: getCustomNameValidationSchema({
    message: 'Please remove specials characters',
    isRequired: true,
    isRequiredMessage: 'College/Institution/School'
  }),
  registrationNumber: getCustomNameValidationSchema({
    message: 'Please remove specials characters',
    isRequired: true,
    isRequiredMessage: 'Registration Number'
  }),
  startDate: Yup.mixed().test({
    name: 'startDate',
    message: 'Please enter a valid date',
    test: (value) => {
      return value !== null ? true : false;
    }
  }),
  endDate: Yup.mixed().test({
    name: 'endDate',
    message: 'Please enter a valid date',
    test: (value) => {
      return value !== null ? true : false;
    }
  })
});
