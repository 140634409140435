// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R1F_FBGqxsnj3P9uVOx9 {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}`, "",{"version":3,"sources":["webpack://./src/core-components/ValidatedFormTextArea/ValidatedFormTextArea.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,cAAA;EACA,cAAA;AACJ","sourcesContent":[".inputError {\n    width: 100%;\n    margin-top: 0.25rem;\n    font-size: 80%;\n    color: #dc3545;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": `R1F_FBGqxsnj3P9uVOx9`
};
export default ___CSS_LOADER_EXPORT___;
