// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gMZw1bu_FdUYGYB_w9EG {
  display: inline-flex;
  width: 100%;
}

.aEpe_1AZ7xg8MWezcHTQ {
  width: 50%;
  margin-left: 10px;
}

.V8GQlBzoKtKUeMQgWPIx {
  margin-left: 16px;
  margin-right: 56px;
}

._7QXUeZSBrOYSj988zjcj {
  border: 1px solid red;
}

.IvWGwSVKxDfTVfcnv9do {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}

.x9983aM7ENF41wFjjoRj {
  padding-top: 0.5rem;
}

.iuKMzrIyhjvpnk4RDxV4 {
  background-color: #2755fe;
  color: white;
  padding: 0.5rem;
  border: none;
}

.txxiyCJllEBjn1SqGxPT {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.iuKMzrIyhjvpnk4RDxV4:hover {
  background-color: #1f44ca;
}

.txxiyCJllEBjn1SqGxPT:hover {
  background-color: #11a294;
}

.OdJoIw8q5xQZI92VAzot {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.mShM4jhlLLE07EIRncbd {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.OMr1Kaye1ZvwrXW6YIYw {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/Integrations/RazorPayX/Components/InputArea/InputArea.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,4BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gCAAA;EACA,yBAAA;EACA,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".formMainDiv {\n  display: inline-flex;\n  width: 100%;\n}\n\n.formField {\n  width: 50%;\n  margin-left: 10px;\n}\n\n.parentDivConnectButton {\n  margin-left: 16px;\n  margin-right: 56px;\n}\n\n.removeButton {\n  border: 1px solid red;\n}\n\n.validationErrorMsg {\n  font-size: 13px;\n  color: #dc3545;\n  margin-top: -30px !important;\n}\n\n.inputLabel {\n  padding-top: 0.5rem;\n}\n\n.connectButton {\n  background-color: #2755fe;\n  color: white;\n  padding: 0.5rem;\n  border: none;\n}\n\n.connectedButton {\n  background-color: #1bae9f;\n  color: white;\n  padding: 0.5rem;\n  border: none;\n}\n\n.connectButton:hover {\n  background-color: #1f44ca;\n}\n\n.connectedButton:hover {\n  background-color: #11a294;\n}\n\n.step1FooterContainer {\n  display: flex;\n  justify-content: flex-start;\n  gap: 1rem;\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n}\n\n.goBackBtn {\n  font-family: Poppins, sans-serif;\n  background-color: #ffffff;\n  color: #2755fe;\n  border: 1px solid #2755fe;\n}\n\n.listItems {\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: #333333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formMainDiv": `gMZw1bu_FdUYGYB_w9EG`,
	"formField": `aEpe_1AZ7xg8MWezcHTQ`,
	"parentDivConnectButton": `V8GQlBzoKtKUeMQgWPIx`,
	"removeButton": `_7QXUeZSBrOYSj988zjcj`,
	"validationErrorMsg": `IvWGwSVKxDfTVfcnv9do`,
	"inputLabel": `x9983aM7ENF41wFjjoRj`,
	"connectButton": `iuKMzrIyhjvpnk4RDxV4`,
	"connectedButton": `txxiyCJllEBjn1SqGxPT`,
	"step1FooterContainer": `OdJoIw8q5xQZI92VAzot`,
	"goBackBtn": `mShM4jhlLLE07EIRncbd`,
	"listItems": `OMr1Kaye1ZvwrXW6YIYw`
};
export default ___CSS_LOADER_EXPORT___;
