import { connect } from 'react-redux';
import { initializeRepute } from '../../../../../actions/authentication';

const mapDispatchToProps = (dispatch) => {
  return {
    initializeRepute: (data) => dispatch(initializeRepute(data))
  };
};

export default connect(null, mapDispatchToProps);
