import React from 'react';

import Image from '../../../core-components/Image';
import AppConstants from '../../../core-components/AppConstants';
import styles from './Footer.module.scss';

export default (props) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.footerParent}>
      <div className={styles.footerMain}>
        <div className={styles.aboutSection}>
          <div className={styles.heading}>{'About'}</div>
          <div className={styles.content}>
            {
              'We are building products for a human-centric approach to HR. SpringVerify background verification leverages cutting edge tech to make the process seamless, accurate and pain-free.'
            }
          </div>
        </div>
        <div className={styles.quickLinkSection}>
          <div className={styles.heading}>{'Quick Links'}</div>
          <div className={styles.linksList}>
            <div>
              <a href={`${AppConstants.baseURL}dashboard`}>
                <div className={styles.link}>{'Dashboard'}</div>
              </a>
              <a href={`${AppConstants.baseURL}candidate/create`}>
                <div className={styles.link}>{'Add Candidate'}</div>
              </a>
              <a href={`${AppConstants.baseURL}candidates?_type=candidatesAdded`}>
                <div className={styles.link}>{'My Candidates'}</div>
              </a>
              <a href={`${AppConstants.baseURL}candidates/?_type=verificationsDone`}>
                <div className={styles.link}>{'Verified Candidates'}</div>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.linkSection}>
          <div className={styles.link}>
            <a
              href={'https://s3.amazonaws.com/assets-springrole/SpringRole-Terms-of-Service.pdf'}
              target={'_blank'}
            >
              {'Terms and Conditions'}
            </a>
          </div>
          <div className={styles.link}>
            <a href={'https://in.springverify.com/privacy-policy/'} target={'_blank'}>
              {'Privacy Policy'}
            </a>
          </div>
          <div className={styles.footerFindUsSection}>
            <div className={styles.heading}>{'Find Us'}</div>
            <a href='https://www.linkedin.com/products/springrole-springverify/' target='_blank'>
              <Image name='linkedin.svg' />
            </a>
            <a
              href='https://www.g2.com/products/springrole-india-pvt-ltd-springverify/reviews'
              target='_blank'
              style={{ marginLeft: '5px' }}
            >
              <Image name='g2-reviews.svg' />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerBase}>
        <div className={styles.logoSection}>
          <div className={styles.logo}>
            <Image name='sv_logo_desktop.svg' />
          </div>
          <div className={styles.content}>{currentYear}&copy;</div>
          <div className={styles.name}>
            <span>{'SpringRole India Pvt. Ltd.'}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
