import React, { useState, useEffect } from 'react';

import { has } from 'lodash';
import { Button } from 'react-bootstrap';

import { AddCredits } from '../../../Packages/Prepaid/Credits/AddCredits';
import ReviewOrder from '../../../Packages/Prepaid/Credits/AddCredits/ReviewOrder';

import styles from './CreditsFlow.module.scss';

import PaymentResults from '../../../../../core-components/PaymentResults';
import AppConstants from '../../../../../core-components/AppConstants';
import { convertToIndianNumeration } from '../../../../../utils/utilities';
import Loader from '../../../../../core-components/Loader';

const getInsuffVerification = (insuffType = null) => {
  const obj = {
    VERIFICATION: 0,
    ADDITIONAL_CHARGE: 0,
    ADD_ON: 0
  };

  if (insuffType && insuffType.type?.length) {
    for (let i = 0; i < insuffType.type.length; i++) {
      obj[insuffType.type[i]] = obj[insuffType.type[i]] + 1;
    }
  }

  return obj;
};

function CreditsFlow(props) {
  const [currentStep, setCurrentStep] = useState(2);
  const [amount, setAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [addedBalance, setAddedBalance] = useState(0);
  const [warningObj, setWarningObj] = useState({
    VERIFICATION: 0,
    ADDITIONAL_CHARGE: 0,
    ADD_ON: 0
  });
  const [transactionId, setTransactionId] = useState('');

  //Execute every time on the updation of insuffCredits
  useEffect(() => {
    if (
      has(props?.insuffCredits, 'all') &&
      has(props?.insuffCredits, 'loading') &&
      props?.insuffCredits?.loading === false
    ) {
      setWarningObj(getInsuffVerification(props?.insuffCredits?.all[0]?.insuffType));
    }
  }, [props?.insuffCredits]);

  useEffect(() => {
    if (paymentStatus == 'failed' || paymentStatus == 'completed') setCurrentStep(4);
  }, [paymentStatus]);

  const renderStep = (step) => {
    switch (step) {
      case 1: {
        props?.addCreditsButtonHandler();
        return null;
      }
      case 2:
        return (
          <AddCredits
            data-testid="add-credits-component"
            setCurrentStep={setCurrentStep}
            amount={amount}
            setAmount={setAmount}
            availableBalance={props?.insuffCredits?.all[0]?.balance}
            offset={0}
            obj={warningObj}
            insuffCredits={props?.insuffCredits}
            companyBalance={props?.insuffCredits?.all[0]?.creditBalance}
          />
        );
      case 3:
        return (
          <ReviewOrder
            data-testid="review-order-component"
            amount={amount}
            setCurrentStep={setCurrentStep}
            setPaymentStatus={setPaymentStatus}
            setOrderId={setOrderId}
            setAddedBalance={setAddedBalance}
            paymentDetails={props?.paymentDetails}
            setTransactionId={setTransactionId}
          />
        );
      case 4:
        return (
          <PaymentResults
            data-testid="payment-results-component"
            paymentStatus={paymentStatus}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            orderId={orderId}
            setPaymentStatus={setPaymentStatus}
            description={
              <>
                {paymentStatus == 'pending' && <p>This might take a few seconds</p>}
                {paymentStatus == 'completed' &&
                  `
                    ₹${convertToIndianNumeration(
                    addedBalance
                  )} has been added to your credit balance and any pending
                    verifications have started. Current balance is ₹${convertToIndianNumeration(
                    parseFloat(props?.insuffCredits?.all[0]?.creditBalance) +
                    parseFloat(addedBalance)
                  )}
                  `}
                {paymentStatus == 'failed' &&
                  `Something went wrong with the payment gateway. Please try again in a few
                    minutes.`}
              </>
            }
            buttons={
              <>
                <Button
                  data-testid="view-credits-button"
                  variant='primary'
                  onClick={() => {
                    window.open(`${AppConstants.baseURL}transaction`, '_self');
                  }}
                >
                  {'View credits'}
                </Button>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    window.open(
                      `${AppConstants.baseURL}transactionDetails?id=${transactionId}`,
                      '_blank'
                    );
                  }}
                >
                  {'View details'}
                </Button>
              </>
            }
          />
        );
    }
  };
  return <div>{renderStep(currentStep)}</div>;
}

export default CreditsFlow;
