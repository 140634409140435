import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

import { IDENTITES_FOR_PACKAGE_CONFIG, PLAIN_CHECK_ICONS } from '../../../utils/commonConstant';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import styles from './Identity.module.scss';

export default (props) => {
  const { values, pConfig, handleChange, limit, isAadhaarAllowed } = props;

  const valueChange = (event, name) => {
    let dummy = [...values.config];
    if (event.target.checked) {
      if (!dummy.includes(name)) {
        dummy.push(name);
        handleChange('config', dummy);
        handleChange('any', values.any - 1);
      }
    } else {
      dummy.splice(dummy.indexOf(name), 1);
      handleChange('config', dummy);
      handleChange('any', values.any + 1);
    }
  };

  const getIdLabel = (label, name) => {
    let returnVal = label;
    if (pConfig.includes(name)) {
      returnVal = (
        <span className={styles.pCheckHeading}>
          {label} <span className={styles.pCheckSubHeading}>INCLUDED</span>
        </span>
      );
    }
    return returnVal;
  };

  const isDisabled = (value) => {
    return (
      (pConfig.includes(value) || values.config.length >= limit) && !values.config.includes(value)
    );
  };

  const hasValue = (name) => {
    return values.config.includes(name) || pConfig.includes(name);
  };

  return (
    <Row className={styles.idPackageComp}>
      <Col xl={12} md={12} lg={12} sm={12} xs={12}>
        <h6 className={styles.checkHeading}>
          <span style={{ marginRight: '16px', fontSize: '16px' }} title={'Identity'}>
            <FontAwesomeIcon
              data-testid='icon'
              icon={PLAIN_CHECK_ICONS['identity'] ? PLAIN_CHECK_ICONS['identity'].icon : faBan}
              color='#2755FE'
            />
          </span>
          {`Identity`}
        </h6>
        <span className={styles.subTitle}>{`Choose ${limit} ID’s`}</span>
      </Col>
      <Col xl={11} md={11} lg={11} sm={11} xs={11} className={styles.checkContainer}>
        <Form.Check
          disabled
          type={'checkbox'}
          label={`Any ${values.any} ID's`}
          checked={true}
          className={styles.formCheck}
        />
        {IDENTITES_FOR_PACKAGE_CONFIG &&
          IDENTITES_FOR_PACKAGE_CONFIG.map((id, index) => {
            return (
              <Form.Check
                type={'checkbox'}
                className={styles.formCheck}
                key={`id_package_${index}`}
              >
                <Form.Check.Input
                  type={'checkbox'}
                  isValid
                  disabled={isDisabled(id.name) || (!isAadhaarAllowed && id.name === 'AADHAAR')}
                  checked={hasValue(id.name)}
                  name={id.name}
                  id={`idPackage-${index}`}
                  onChange={(e) => {
                    valueChange(e, id.name);
                  }}
                />
                <Form.Check.Label htmlFor={`idPackage-${index}`}>
                  {getIdLabel(id.label, id.name)}
                </Form.Check.Label>
              </Form.Check>
            );
          })}
      </Col>
    </Row>
  );
};
