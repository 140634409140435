import { connect } from 'react-redux';
import { getCandidateFormConfig } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    formConfig: state.formConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getCandidateFormConfig: () => dispatch(getCandidateFormConfig()) };
};

export default connect(mapStateToProps, mapDispatchToProps);
