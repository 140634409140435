import React from 'react';
import Image from '../Image';
import styles from './ErrorComponent.module.scss';

export default (props) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerLogo}>
          <Image name='sv_logo_desktop.svg' />
        </div>
      </div>
      <div className={styles.errorContainer}>
        <h3>This link is to be accessed only by the candidate and not an admin.</h3>
        <p>
          <a
            data-testid='back-btn'
            className={styles.anchorText}
            onClick={() => props.history.push('/dashboard')}
          >
            click here to go back to homepage
          </a>
        </p>
      </div>
    </>
  );
};
