import { LogglyTracker } from 'loggly-jslogger';
import AppHelper from '../core-components/AppHelper';
class CustomLogger extends LogglyTracker {
  constructor() {
    super();
    this.logglyKey = '6d2cfdee-91e3-46aa-81c5-4ba4a567ec43';
    this.tag = `springverify-client-${AppHelper.getEnv()}`;
    this.sendConsoleErrors = true;
  }
  push(data) {
    if (this.shouldExclude(data)) {
      return;
    }
    super.push(data);
  }
  shouldExclude(data) {
    if (
      data.payload &&
      data.payload.includes('ResizeObserver loop completed with undelivered notifications.')
    ) {
      return true;
    }
    return false;
  }
}
const logger = new CustomLogger();
export default logger;
