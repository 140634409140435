import React, { useState, useEffect } from 'react';
import localStorage from '../../api/localStorage';
import AppConstants from '../AppConstants';
import Loader from '../../core-components/Loader';
import { getDocumentMimetype } from '../../api/company';

import styles from './RenderDocuments.module.scss';

export default () => {
  const token = localStorage.getToken();
  const uuid = new URLSearchParams(document.location.search).get('uuid') || null;
  const checkType = new URLSearchParams(document.location.search).get('checkType') || null;
  const [loader, setLoader] = useState(true);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    getDocumentMimetype(uuid)
      .then((response) => {
        if (response?.data?.data?.startsWith('image')) setIsImage(true);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  }, []);

  return (
    <div className={styles.renderDocuments}>
      {loader ? (
        <div id='loader' style={{ textAlign: 'center', height: '100vh' }}>
          <Loader style={{ textAlign: 'center' }} />
        </div>
      ) : !isImage ? (
        <iframe
          src={`${AppConstants.developmentAPIURL}auth/image?uuid=${uuid}&checkType=${checkType}&token=${token}`}
          className={styles.iframe}
        ></iframe>
      ) : (
        <div className={styles.imgDiv}>
          <img
            src={`${AppConstants.developmentAPIURL}auth/image?uuid=${uuid}&checkType=${checkType}&token=${token}`}
            className={styles.img}
            onError={() => {
              window.open(`${AppConstants.baseURL}unauthorized`, '_self');
            }}
          ></img>
        </div>
      )}
    </div>
  );
};
