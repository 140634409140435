// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e35Ya6s7UpTQHPfSAUOw {
  font-family: Poppins !important;
  font-style: normal !important;
}
.e35Ya6s7UpTQHPfSAUOw .kMD1D7MQ6FDInAAd6R8Y {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 0px;
}
.e35Ya6s7UpTQHPfSAUOw .mG3Zs_9qqmiSvbSavSu0 {
  margin-left: 34px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 {
  margin-top: 12px;
  margin-left: 20px;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 .JuIOuu5q8N1TLbBToIsn {
  padding-bottom: 6px;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 .JuIOuu5q8N1TLbBToIsn .c7ovA7GiCsOJc1FJx3RO {
  margin-right: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.e35Ya6s7UpTQHPfSAUOw .jGihdCXHafxhQQL3tnx0 .vtl1h6tpAE5w7ASf_UTS {
  margin-left: 14px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #f5222d;
}`, "",{"version":3,"sources":["webpack://./src/core-components/Packages/Reference/Reference.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,6BAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAAE;EACE,gBAAA;EACA,iBAAA;AAEJ;AADI;EACE,mBAAA;AAGN;AAFM;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAIR;AADI;EACE,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAGN","sourcesContent":[".idPackageComp {\n  font-family: Poppins !important;\n  font-style: normal !important;\n  .checkHeading {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 21px;\n    color: #333333;\n    margin-bottom: 0px;\n  }\n  .subTitle {\n    margin-left: 34px;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 22px;\n    color: #8c8c8c;\n  }\n  .checkContainer {\n    margin-top: 12px;\n    margin-left: 20px;\n    .refContainer {\n      padding-bottom: 6px;\n      .refText {\n        margin-right: 12px;\n        font-weight: normal;\n        font-size: 14px;\n        line-height: 22px;\n        color: #595959;\n      }\n    }\n    .refError {\n      margin-left: 14px;\n      font-weight: normal;\n      font-size: 14px;\n      line-height: 22px;\n      color: #f5222d;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"idPackageComp": `e35Ya6s7UpTQHPfSAUOw`,
	"checkHeading": `kMD1D7MQ6FDInAAd6R8Y`,
	"subTitle": `mG3Zs_9qqmiSvbSavSu0`,
	"checkContainer": `jGihdCXHafxhQQL3tnx0`,
	"refContainer": `JuIOuu5q8N1TLbBToIsn`,
	"refText": `c7ovA7GiCsOJc1FJx3RO`,
	"refError": `vtl1h6tpAE5w7ASf_UTS`
};
export default ___CSS_LOADER_EXPORT___;
