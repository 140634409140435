import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';

import validationSchema from './BasicDetails.validation';
import { getAddCandidateConfig as getAddCandidateConfigAPI } from '../../../../actions/company';
import {
  getIntlPhoneNumber,
  getIntlPhoneNumberObject,
  toCamelCaseTemp
} from '../../../../utils/utilities';

const mapStateToProps = (state) => {
  return {
    createCandConfig: state.createCandConfig || null,
    profile: state.profile && !isEmpty(state.profile) ? toCamelCaseTemp(state.profile) : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getAddCandidateConfig: (companyId) => dispatch(getAddCandidateConfigAPI(companyId)) };
};
const formatObject = (formData) => {
  let finalData = { ...formData };

  finalData.phone = `${formData.countryCode + '' + formData.phone}`;
  finalData.altPhone = formData.altPhone
    ? `${formData.altCountryCode + '' + formData.altPhone}`
    : '';
  finalData.isConsentUndertakingLetter =
    formData?.isConsentUndertakingLetter === '1' ? true : false;
  finalData.invite = formData?.invite === '1' ? true : false;
  if (finalData.resumeLink) {
    finalData['resume'] = finalData.resumeLink;
  }

  if (finalData.consentLink) {
    finalData['consent'] = finalData.consentLink;
  }

  if (finalData.isConsentUndertakingLetter) {
    finalData['consentDoc'] = undefined;
  }

  if (formData?.invite === '1') {
    if (finalData?.hrInviteNote?.trim()) {
      finalData['hrInviteNote'] = finalData.hrInviteNote.trim();
    } else {
      finalData['hrInviteNote'] = undefined;
    }
  } else {
    finalData['hrInviteNote'] = undefined;
  }

  return finalData;
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let baseValues = {};
      baseValues = {
        name: '',
        email: '',
        emailExists: false,
        alternateEmail: '',
        phone: '',
        phoneNumberExists: false,
        isValidPhone: true,
        altPhone: '',
        employeeId: '',
        countryCode: '+91',
        altCountryCode: '+91',
        isAltValidPhone: true,
        employeeIdExists: false,
        uanNumber: '',
        resume: '',
        resumeLink: '',
        resumeFile: null,
        resumeDetails: {},
        category_id: '',
        category_name: '',
        tags: [],
        consent: '',
        consentLink: '',
        consentFile: null,
        consentDoc: true,
        invite: '2',
        isConsentUndertakingLetter:
          props?.createCandConfig && props?.createCandConfig?.consentType === 'UNDERTAKING_LETTER'
            ? '1'
            : '2',
        formConfig: {
          isEmailOptional:
            props.createCandConfig && props.createCandConfig.isEmailOptional
              ? props.createCandConfig.isEmailOptional === 1
              : false,
          isResumeOptional:
            props.createCandConfig && props.createCandConfig.isResumeOptional
              ? props.createCandConfig.isResumeOptional === 1
              : false
        },
        companyName: '',
        hrInviteNote: ''
      };

      let propsValue = {};

      propsValue.hrInviteNote = props?.createCandConfig?.hrInviteNoteStatus
        ? props?.createCandConfig?.hrInviteNote
        : '';

      propsValue.companyName = props?.createCandConfig?.companyName;

      if (props.savedValues && !isEmpty(props.savedValues)) {
        propsValue = { ...propsValue, ...props.savedValues };
        propsValue.isConsentUndertakingLetter = props?.savedValues?.isConsentUndertakingLetter
          ? '1'
          : '2';
        propsValue.invite = props?.savedValues?.invite ? '1' : '2';
      }

      if (props?.savedValues?.phone?.length > 10) {
        let phoneNumberObj = getIntlPhoneNumberObject(props?.savedValues?.phone);
        if (phoneNumberObj) {
          propsValue['phone'] = phoneNumberObj.nationalNumber;
          propsValue['countryCode'] = `+${phoneNumberObj.countryCallingCode}`;
        }
      } else {
        propsValue['phone'] = props?.savedValues?.phone;
        propsValue['countryCode'] = props?.savedValues?.countryCode;
      }
      if (props?.savedValues?.altPhone?.length > 10) {
        let altPhoneNumberObj = getIntlPhoneNumberObject(props?.savedValues?.altPhone);
        if (altPhoneNumberObj) {
          propsValue['altPhone'] = altPhoneNumberObj.nationalNumber;
          propsValue['altCountryCode'] = `+${altPhoneNumberObj.countryCallingCode}`;
        }
      } else {
        propsValue['altPhone'] = props?.savedValues?.altPhone;
        propsValue['altCountryCode'] = props?.savedValues?.altCountryCode;
      }

      return mergeWith(baseValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: false,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      const finalValues = formatObject(values);
      props.handleStepSubmit(['step1'], [finalValues], 2);
    },
    displayName: 'Create Candidate'
  })
);
