// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mQlTAwduT1qy_Wg8uBKf {
  width: 181px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.VXSDpuKVYT0HWWiQ1WOl {
  width: 181px;
  height: 45px;
  border: 1px solid #c2cfff;
  border-radius: 4px;
}
.VXSDpuKVYT0HWWiQ1WOl .td93d1SbgluWyI_H5_aT {
  font-size: 14px;
  color: #333333;
  display: flex;
  padding-top: 12px;
  place-content: space-between;
}
.VXSDpuKVYT0HWWiQ1WOl .td93d1SbgluWyI_H5_aT .k3r8_7Jh2jnB7gnvhZ3V {
  font-size: 13px;
  margin-top: 2px;
  padding-left: 1px;
}
.VXSDpuKVYT0HWWiQ1WOl .td93d1SbgluWyI_H5_aT .iD3nKVigZ4CkYhd6aRX2 {
  width: 150px;
}

.HfqcZ9E0AlJ_VfUFlIXw {
  font-size: 14px;
  margin-top: 1px;
}

.pPHgHRXR1dgCFo2fih4V {
  font-size: 14px;
  line-height: 20px;
}

.zY0ZPiLsZQDMNa6sGxvt {
  padding-left: 2px;
}

.hCVdfjQRWrhOKIK4dk3v {
  padding-inline: 8px;
}

.INPgtirypL_c5FCAS4Ej {
  padding-right: 2px;
}

.hCVdfjQRWrhOKIK4dk3v {
  padding-inline: 8px;
}

.ri19VtdfMhUrqcNu9bYl {
  z-index: 9999;
  position: absolute;
  margin: 7px;
}`, "",{"version":3,"sources":["webpack://./src/core-components/DateRangePicker/DateRangePicker.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAEF;AADE;EACE,eAAA;EACA,cAAA;EACA,aAAA;EACA,iBAAA;EACA,4BAAA;AAGJ;AAFI;EACE,eAAA;EACA,eAAA;EACA,iBAAA;AAIN;AAFI;EACE,YAAA;AAIN;;AAAA;EACE,eAAA;EACA,eAAA;AAGF;;AADA;EACE,eAAA;EACA,iBAAA;AAIF;;AAFA;EACE,iBAAA;AAKF;;AAHA;EACE,mBAAA;AAMF;;AAHA;EACE,kBAAA;AAMF;;AAHA;EACE,mBAAA;AAMF;;AAJA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;AAOF","sourcesContent":[".dateFilterField {\n  width: 181px;\n  height: 45px;\n  display: flex;\n  flex-direction: column;\n  justify-content: end;\n}\n.dateInputField {\n  width: 181px;\n  height: 45px;\n  border: 1px solid #c2cfff;\n  border-radius: 4px;\n  .dateTextField {\n    font-size: 14px;\n    color: #333333;\n    display: flex;\n    padding-top: 12px;\n    place-content: space-between;\n    .dateText {\n      font-size: 13px;\n      margin-top: 2px;\n      padding-left: 1px;\n    }\n    .dateTextWidth {\n      width: 150px;\n    }\n  }\n}\n.textDateRange {\n  font-size: 14px;\n  margin-top: 1px;\n}\n.filterLabelText {\n  font-size: 14px;\n  line-height: 20px;\n}\n.paddingLeft {\n  padding-left: 2px;\n}\n.topTextPadding {\n  padding-inline: 8px;\n}\n\n.paddingRight {\n  padding-right: 2px;\n}\n\n.topTextPadding {\n  padding-inline: 8px;\n}\n.dateRangePickerDiv {\n  z-index: 9999;\n  position: absolute;\n  margin: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateFilterField": `mQlTAwduT1qy_Wg8uBKf`,
	"dateInputField": `VXSDpuKVYT0HWWiQ1WOl`,
	"dateTextField": `td93d1SbgluWyI_H5_aT`,
	"dateText": `k3r8_7Jh2jnB7gnvhZ3V`,
	"dateTextWidth": `iD3nKVigZ4CkYhd6aRX2`,
	"textDateRange": `HfqcZ9E0AlJ_VfUFlIXw`,
	"filterLabelText": `pPHgHRXR1dgCFo2fih4V`,
	"paddingLeft": `zY0ZPiLsZQDMNa6sGxvt`,
	"topTextPadding": `hCVdfjQRWrhOKIK4dk3v`,
	"paddingRight": `INPgtirypL_c5FCAS4Ej`,
	"dateRangePickerDiv": `ri19VtdfMhUrqcNu9bYl`
};
export default ___CSS_LOADER_EXPORT___;
