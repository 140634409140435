import React, { useState } from 'react';
import classes from './SlackConnectBanner.module.scss';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import Image from '../../../../core-components/Image';
import { connectSlack, connectTeams } from '../../../../api/company';
import { toast } from 'react-toastify';
import { errToastMessage } from '../../../../utils/utilities';

export default function SlackConnectBanner(props) {
  const [loading, setLoading] = useState(false);
  const manageList = [
    'https://in-springverify-assets.s3.amazonaws.com/images/Priyanka.png',
    'https://in-springverify-assets.s3.amazonaws.com/images/Anjana.jpg',
    'https://in-springverify-assets.s3.amazonaws.com/images/Rinki.jpeg'
  ];
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.divContainer}>
        <div className={classes.firstCol}>
          <Image name={'slacknew.svg'} className={classes.firstColImg} />
        </div>
        <div className={classes.headingCol}>
          <span className={classes.slackText}>Do you use Slack?</span> Talk to your Account Manager
          from your Slack.
        </div>
        <div className={classes.imgDivContainer}>
          {manageList.map((img) => {
            return <img className={classes.profileImg} src={img} />;
          })}
        </div>
        <div className={classes.ctaContainer}>
          <Button
            onClick={async () => {
              setLoading(true);
              const res = await connectSlack();
              if (res?.data?.data?.ok) {
                props.getPaymentDetails();
                props?.getBanner();
                toast.success(res?.data?.data?.message);
              } else {
                setLoading(false);
                errToastMessage(res?.data?.data?.message);
              }
            }}
            disabled={loading}
            className={classes.connectBtn}
          >
            <span>Connect now</span>
          </Button>
          <span
            onClick={async () => {
              setLoading(true);
              if (!loading) {
                const res = await connectTeams();
                if (res?.data?.data?.ok) {
                  props.getPaymentDetails();
                  props?.getBanner();
                  toast.success(res?.data?.data?.message);
                } else {
                  setLoading(false);
                  errToastMessage(res?.data?.data?.message);
                }
              }
            }}
            className={classes.teamCTA}
          >
            No, I use MS Teams
          </span>
        </div>
      </div>
    </Card>
  );
}
