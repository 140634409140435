import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, has } from 'lodash';

import validationSchema from './AddIdModal.validation';
import logger from '../../../../../../core-components/Logger';
import { toCamelCase, toSnakeCase } from '../../../../../../utils/utilities';

import {
  idUpload as bgvIdUploadAPI,
  getOcr as getOcrAPI,
  saveBgvCache as saveBgvCacheAPI
} from '../../../../../../actions/bgv';

import { identityDropdownToObject } from '../Identity.mapper';

const mapStateToProps = (state) => {
  return {
    cachedData: !state.bgvCachedData.data ? null : toCamelCase({ ...state.bgvCachedData.data }),
    uploadedID: state?.idDetails?.data || state?.idDetails?.url,
    ocrDetails: state.idDetails.data, //this should get ocr details
    errorOCr: state.idDetails,
    config: !state?.bgvPackageConfig?.data ? null : { ...state?.bgvPackageConfig?.data }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    bgvIdUpload: (data) => dispatch(bgvIdUploadAPI(data)),
    getOcr: (data) => dispatch(getOcrAPI(data))
  };
};

let propsValue = {},
  previousId = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      if (
        props.cachedData &&
        has(props.cachedData.cacheData, 'idCheck') &&
        props?.cachedData?.cacheData?.idCheck?.length >= props.idCheckIndex &&
        !isEmpty(props.cachedData.cacheData.idCheck[props.idCheckIndex])
      ) {
        propsValue = props.cachedData.cacheData['idCheck'][props.idCheckIndex];
      } else {
        propsValue = {};
      }
      let storedValues = {
        idType: !isEmpty(propsValue) ? propsValue.idType : '',
        singleDoc: !isEmpty(propsValue) ? propsValue.singleDoc : false,
        identityNumber: !isEmpty(propsValue) ? propsValue.identityNumber : '',
        frontDocUrl: !isEmpty(propsValue) ? propsValue.frontDocUrl : '',
        backDocUrl: !isEmpty(propsValue) ? propsValue.backDocUrl : '',
        frontDocPassword: '',
        backDocPassword: '',
        frontPdf: !isEmpty(propsValue) ? propsValue.frontPdf : false,
        backPdf: !isEmpty(propsValue) ? propsValue.backPdf : false,
        frontId: !isEmpty(propsValue) ? propsValue.frontId : null,
        backId: !isEmpty(propsValue) ? propsValue.frontId : null,
        dob: !isEmpty(propsValue) ? propsValue.dob : null,
        fatherName: !isEmpty(propsValue) ? propsValue.dob : null,
        identityCandidateName: !isEmpty(propsValue) ? propsValue.identityCandidateName : null,
        requestId: !isEmpty(propsValue) ? propsValue.requestId : null
      };
      previousId = !isEmpty(propsValue) ? propsValue.idType.name : null;
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props, setFieldValue }) => {
      // //calling cache API if previous value and new value is diff otherwise we will move to the next step

      let valuesCopy = Object.assign({}, values),
        finalCacheData = {};
      const finalStepObj = {};
      // const addressCheck = { ...props.cachedData.cacheData.addressCheck };
      //if idCheck exists

      if (
        props?.cachedData &&
        props?.cachedData?.cacheData &&
        has(props.cachedData.cacheData, 'idCheck') &&
        props?.cachedData?.cacheData?.idCheck?.length > props.idCheckIndex &&
        !isEmpty(props.cachedData.cacheData.idCheck[props.idCheckIndex])
      ) {
        let idCheck = [...props.cachedData.cacheData.idCheck];

        idCheck[props.idCheckIndex] = {
          ...valuesCopy
        };

        const saveObject = toSnakeCase({
          ...props.cachedData,
          cacheData: {
            ...props.cachedData.cacheData,
            idCheck: [...idCheck]
          },
          currentState: '1'
        });

        finalCacheData = {
          saveObject
        };
      } else if (
        props?.cachedData &&
        props?.cachedData?.cacheData &&
        has(props.cachedData.cacheData, 'idCheck') &&
        props?.cachedData?.cacheData?.idCheck?.length
      ) {
        let idCheck = [...props.cachedData.cacheData.idCheck];

        idCheck[props.idCheckIndex] = {
          ...valuesCopy
        };

        const saveObject = toSnakeCase({
          ...props.cachedData,
          cacheData: {
            ...props.cachedData.cacheData,
            idCheck: [...idCheck]
          },
          currentState: '1'
        });

        finalCacheData = {
          saveObject
        };
      } else {
        finalStepObj['idCheck'] = [];
        // finalStepObj.idCheck[props.idCheckIndex] = {};
        finalStepObj.idCheck[props.idCheckIndex] = { ...valuesCopy };
        const saveObject = toSnakeCase({
          ...props.cachedData,
          cacheData: { ...props?.cachedData?.cacheData, ...finalStepObj },
          currentState: '1'
        });

        finalCacheData = {
          saveObject
        };
      }

      if (Array.isArray(props.dropdownValue)) {
        let updatedDropdown = props.dropdownValue;

        for (let j = 0; j < updatedDropdown.length; j++) {
          if (updatedDropdown[j] === values.idType.name) {
            updatedDropdown.splice(j, 1);
            j--;
          }
        }

        if (props.title === 'Edit' && previousId !== values.idType.name) {
          updatedDropdown.push(previousId);
        }

        finalCacheData.saveObject['identity_dropdown'] = identityDropdownToObject(updatedDropdown);
      }

      props.setBgvCache(finalCacheData).then(() => {
        logger.push({
          message: `Identity modal  ${props.title === 'Edit' ? 'Edited' : 'Saved'}`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${props.candidateId}`,
          subCategory: 'Identity modal',
          type: 'info'
        });
        props.onHide();
        setSubmitting(false);
      });
    },
    displayName: 'Identity'
  })
);
