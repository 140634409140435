import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';

import {
  getCategories,
  INVALIDATE_CATEGORIES,
  fetchCandidateDetails,
  editCandidate,
  editCandidateCategory,
  editCandidateTag,
  getCompanyPaymentDetails,
  getTags,
  INVALIDATE_TAGS
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    tags: state.tags,
    candidateInfo: state.candidateInfo,
    profile: state.profile,
    paymentDetails: state.paymentDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    getCategories: () => dispatch(getCategories()),
    invalidateTags: () => dispatch({ type: INVALIDATE_TAGS }),
    getTags: () => dispatch(getTags()),
    fetchCandidateDetails: (candidateId) => dispatch(fetchCandidateDetails(candidateId)),
    editCandidate: (data) => dispatch(editCandidate(data)),
    editCandidateCategory: (data) => dispatch(editCandidateCategory(data)),
    editCandidateTag: (data) => dispatch(editCandidateTag(data)),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      return {
        categoryId: '',
        categoryEdited: false,
        tagId: [],
        tagEdited: false,
        edit: '',
        categoryError: '',
        tagsError: ''
      };
    },
    enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, props, setFieldValue, setFieldError }) => {
      if (values.edit === 'category') {
        const formData = {
          userCategoryId:
            values.categoryId && values.categoryId !== 'None' ? [parseInt(values.categoryId)] : [],
          candidateId: props?.candidateInfo?.companyCandidateMapping?.candidateId
        };
        props
          ?.editCandidateCategory(formData)
          .then(() => {
            setSubmitting(false);
            setFieldValue('categoryEdited', false);
          })
          .catch((err) => {
            console.error('err: ', err);
            setFieldValue('categoryEdited', true);
            setFieldValue('categoryError', err?.message || '');
            setSubmitting(false);
          });
      } else if (values.edit === 'tag') {
        let tag =
          values.tagId && values.tagId.length > 0 ? values.tagId.map((tagId) => tagId.value) : [];
        const formData = {
          categoryId: tag,
          candidateId: props?.candidateInfo?.companyCandidateMapping?.candidateId
        };
        props
          ?.editCandidateTag(formData)
          .then(() => {
            setSubmitting(false);
            setFieldValue('tagEdited', false);
          })
          .catch((err) => {
            console.error('err: ', err);
            setFieldValue('tagEdited', true);
            setFieldValue('tagsError', err?.message || '');
          });
      }
    },
    displayName: 'Edit Candidate Category and Tag'
  })
);
