import React from 'react';

import Image from '../../../../core-components/Image';

import styles from './BgvSecureText.module.scss';

export default () => {
  return (
    <div className={styles.secureTextMobile}>
      <Image name='secure_icon.svg' />
      <span>secure form bgv</span>
    </div>
  );
};
