import React, { useState } from 'react';
import moment from 'moment';

import { Modal, Table } from 'react-bootstrap';

import Image from '../../../../core-components/Image';
import Comment from './Comment';

import styles from './CommentsModal.module.scss';

export default (props) => {
  return (
    <Modal
      {...props}
      show={props?.show?.open}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.show && props?.show?.data?.issue}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Status Badge */}
        <div
          className={
            props.show.data?.status === 'UNRESOLVED'
              ? styles.badgeUnresolved
              : props.show.data?.status === 'RESOLVED'
              ? styles.badgeClose
              : styles.badgeOpen
          }
        >
          <div className={styles.badgeLogo}>
            {props.show.data?.status === 'UNRESOLVED' ? (
              <Image className={styles.badgeLogoImg} name='insuffModalUnresolved.svg' />
            ) : props.show.data?.status === 'RESOLVED' ? (
              <Image className={styles.badgeLogoImg} name='insuffModalClosed.svg' />
            ) : (
              <Image className={styles.badgeLogoImg} name='insuffModalOpen.svg' />
            )}
          </div>
          <div className={styles.badgeTitle}>
            {props.show.data?.status === 'UNRESOLVED'
              ? 'Unresolved'
              : props.show.data?.status === 'RESOLVED'
              ? 'Resolved'
              : 'Open'}
          </div>
        </div>
        {/* Details */}
        <Table borderless className={styles.contentTable}>
          <tbody className={styles.commentsTableDetails}>
            <tr>
              <th>{'Candidate'}</th>
              <td>{props.show.data?.candidate?.name}</td>
            </tr>
            <tr>
              <th>{'Check'}</th>
              <td>{props.show.data?.checkType}</td>
            </tr>
            <tr>
              <th>{'Identifier'}</th>
              <td>{props.show.data?.identifier?.type}</td>
            </tr>
            <tr>
              <th>{'Time of Insuff'}</th>
              <td>{`${props.show.data?.daysInInsufficiency} days`}</td>
            </tr>
            <tr>
              <th>{'Updated'}</th>
              <td>{`${moment(props.show.data?.updatedAt).format('MMM DD, YYYY, h:mm A')}`}</td>
            </tr>
          </tbody>
        </Table>

        {/* comments lists */}
        <Comment manualInsufficiencyId={props.show?.data?.manualInsufficiencyId} />
      </Modal.Body>
      <Modal.Footer className={styles.footer} />
    </Modal>
  );
};
