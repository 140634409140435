import _, { has, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { convertToIndianNumeration } from '../../core-components/Utilities/utilities';
import { Card, Form, Row } from 'react-bootstrap';
import { calculateTax, indianNumerationValue } from '../../core-components/Utilities/utilities';
import styles from './Summary.module.scss';
import Tds from './Tds';
import { getPackageAmountTotal } from '../UtilComponents/UtilitiesFunction';
import cn from 'classnames';

export default (props) => {
  // props.values belong to the parent component not from formik
  const { values, packages, selectedTiers } = props;
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [totalAmount, setTotalAmount] = useState({ withTax: 0, withoutTax: 0 });
  const [maskDiv, setMaskDiv] = useState(false);

  useEffect(() => {
    let totalAfterTax = 0;
    let totalBeforeTax = 0;
    totalBeforeTax =
      getWalletAmountTotal() + getPackageAmountTotal(selectedTiers) - walletAmountUsed();
    totalAfterTax = totalBeforeTax + calculateTax(totalBeforeTax, getTax());
    setTotalAmount({ withoutTax: totalBeforeTax, withTax: totalAfterTax });
    setMaskDiv(props.values.amount == 0 && getPackageAmountTotal(props?.selectedTiers) == 0);
  }, [values, selectedPackages, props.paymentDetails]);

  useEffect(() => {
    setSelectedPackages(selectedTiers && !isEmpty(selectedTiers) ? Object.keys(selectedTiers) : []);
  }, [selectedTiers]);

  /* ------------- Function to get how much wallet balance amount used  -------------*/
  const walletAmountUsed = () => {
    let packageTotalAmount = getPackageAmountTotal(selectedTiers);
    if (props.values.useCurrentWalletBalance) {
      if (packageTotalAmount >= props.walletDetail.currentBalance) {
        // If all package combined amount is greater than current balance means we can use all the wallet balance
        return props.walletDetail.currentBalance;
      } else {
        return packageTotalAmount;
      }
    } else {
      // props.values.useCurrentWalletBalance is false means not using current balance
      return 0;
    }
  };

  /* ----- Function to get the total amount of the wallet selected from wallet page--------*/
  const getWalletAmountTotal = () => {
    let amount = values.amount === 'custom' ? values.customAmount : values.amount;
    amount = typeof amount === 'string' ? amount.replace(/,/g, '') : amount?.toString();
    return parseInt(amount);
  };

  /* ----------------------- Function to get the tax slab ---------------------------*/
  const getTax = () => {
    return props?.paymentDetails?.tax || 0;
  };

  const getSelectedRow = (text, amount) => {
    return (
      <div style={{ display: 'flex' }} className={styles.selectedItem}>
        <div>{text}</div>
        <div className={styles.amount}>{indianNumerationValue(amount, 2)}</div>
      </div>
    );
  };

  const getUnSelectedRow = () => {
    return (
      <div style={{ display: 'flex' }} className={styles.unSelectedItem}>
        <div>No Selection</div>
        <div className={styles.amount}>{indianNumerationValue()}</div>
      </div>
    );
  };

  const getPackageName = (packageId) => {
    const filteredPackage = packages?.filter((p) => p.packageId == packageId);
    return filteredPackage?.length > 0 ? filteredPackage[0].packageName : '';
  };

  const getTierSelections = (tierId) => {
    return _(selectedTiers[tierId])
      .groupBy('id')
      .map((item) => item)
      .value();
  };
  const getTierNameCount = (tiers) => {
    return (
      <li>
        {tiers[0].candidateCount} Candidates {tiers.length > 1 ? `x ${tiers.length}` : ''}
      </li>
    );
  };
  const getPackageAmount = (tier) => {
    const initialValue = 0;
    const sumWithInitial =
      selectedTiers &&
      selectedTiers.hasOwnProperty(tier) &&
      selectedTiers[tier]
        .map((ap) => ap.candidateCount * ap.costPerCandidate)
        .reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
    return convertToIndianNumeration(sumWithInitial, 2);
  };

  return (
    <>
      {props.walletDetail.loading ? (
        <Card style={{ padding: '180px' }}></Card>
      ) : (
        <>
          <Card className={styles.root}>
            <div className={cn(styles.container, maskDiv ? styles.maskDiv : '')}>
              {/* ------------------- Use Current Balance checkbox ------------------ */}
              {(!values.amount || values.amount == '0') &&
              props.walletDetail.currentBalance > 0 &&
              props.currentStep != 1 &&
              selectedPackages &&
              selectedPackages.length > 0 ? (
                <div className={styles.subContainer}>
                  <Form.Check
                    name='useCurrentWalletBalance'
                    id='useCurrentWalletBalance'
                    type='checkbox'
                    checked={props.values.useCurrentWalletBalance}
                    onChange={(e) => {
                      props.setFieldValue('useCurrentWalletBalance', e.target.checked);
                    }}
                    label={
                      <>
                        <div className={styles.creditBalanceText}>
                          Use wallet balance of{' '}
                          <span className={styles.creditBalanceAmount}>
                            &#8377;{convertToIndianNumeration(props.walletDetail.currentBalance)}
                          </span>
                        </div>
                      </>
                    }
                  />
                  <div>
                    {getPackageAmountTotal(selectedTiers) >= props.walletDetail.currentBalance &&
                    values.useCurrentWalletBalance ? (
                      <span className={styles.creditBalanceText}>
                        <span className={styles.creditBalanceNotRecommended}>NOT RECOMMENDED:</span>{' '}
                        Using your wallet for this purchase will reduce the balance to 0 leading
                        approval to delays.
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <hr className={styles.hrTagWalletBalance} />
                </div>
              ) : null}
              {/* ------------------------ Wallet Summary ------------------------- */}
              <div className={styles.subContainer}>
                <div className={styles.summaryTitles}>WALLET</div>
                <hr className={styles.hrTag} />
                {values.amount !== '0'
                  ? getSelectedRow('Recharge Amount', getWalletAmountTotal())
                  : getUnSelectedRow()}
              </div>
              {/* -------------------------- Packages Summary -------------------- */}
              <div className={styles.subContainer}>
                <div className={styles.summaryTitles}>PACKAGES</div>
                <hr className={styles.hrTag} />
                {selectedPackages && selectedPackages.length > 0
                  ? selectedPackages.map((pckg, index) => (
                      <div key={index}>
                        <div className='d-flex justify-content-between align-items-center'>
                          <span className={styles.packageName}>{getPackageName(pckg)}</span>
                          <span className={styles.packageAmount}>
                            &#8377;{!isEmpty(selectedTiers) ? getPackageAmount(pckg) : '0'}
                          </span>
                        </div>
                        <div>
                          <ul className={styles.subtypesSelected}>
                            {!isEmpty(selectedTiers) &&
                              getTierSelections(pckg).map((tier, index) => getTierNameCount(tier))}
                          </ul>
                        </div>
                      </div>
                    ))
                  : getUnSelectedRow()}
              </div>
              {/* ------------------------ PRO Feature ------------------------ */}
              {/* <div className={styles.subContainer}>
                <div className={styles.summaryTitles}>PRO FEATURES</div>
                <hr className={styles.hrTag} />
                {getUnSelectedRow()}
              </div> */}
              {/* ----------------------- Wallet Balance Used ------------------ */}
              {props.values.useCurrentWalletBalance && (
                <div className={styles.walletBalanceSubContainer}>
                  <div style={{ display: 'flex' }} className={styles.selectedItem}>
                    <div className={styles.summaryTitlesSmallSize}>Wallet balance used</div>
                    <div className={styles.summaryAmount}>
                      -{indianNumerationValue(walletAmountUsed(), 2)}
                    </div>
                  </div>
                </div>
              )}
              {/* ---------------------- Tax Calculation -------------------- */}
              <div className={styles.taxSubContainer}>
                <div style={{ display: 'flex' }} className={styles.selectedItem}>
                  <div className={styles.summaryTitlesSmallSize}>Tax({getTax() + '%'})</div>
                  <div
                    className={
                      has(props, 'robotoRupeeFont') ? props?.robotoRupeeFont : styles.summaryAmount
                    }
                  >
                    {indianNumerationValue(calculateTax(totalAmount.withoutTax, getTax()), 2)}
                  </div>
                </div>
              </div>
              <hr className={styles.hrTagSubContainer} />

              {/* ----------------------- Total ----------------------- */}
              <div className={styles.totalSubContainer}>
                <div style={{ display: 'flex' }} className={styles.selectedItem}>
                  <div className={cn(styles.summaryTitles, styles.summaryTitlesTotal)}>
                    {props?.paymentDetails?.tds == 'NONE' ? 'TOTAL PAYABLE' : 'TOTAL'}
                  </div>
                  <div className={styles.totalAmount}>
                    {indianNumerationValue(totalAmount.withTax, 2)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {/* ----------------- Apply TDS section -----------------*/}
          <Tds
            paymentDetails={props.paymentDetails}
            values={values}
            totalAmount={totalAmount}
            setFieldValue={props.setFieldValue}
            mask={maskDiv}
            tdsRadioDisabled={
              getPackageAmountTotal(selectedTiers) <= props.walletDetail.currentBalance &&
              values.useCurrentWalletBalance
            }
          />
        </>
      )}
    </>
  );
};
