import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { useState } from 'react';

import Image from '../../../core-components/Image';

import styles from './BgvFooter.module.scss';

export default () => {
  const currentYear = new Date().getFullYear();
  const [zoomLevel, setZoomLevel] = useState(Math.round(window.devicePixelRatio * 100));

  window.addEventListener('resize', () => {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    setZoomLevel(browserZoomLevel);
  });
  const getClassName = () => {
    if (zoomLevel < 80) {
      return styles.footerDivHeight10;
    }
  };
  return (
    <Container data-testid="footer-container" fluid className={`${styles.bgvFooterContainer} ${getClassName()}`}>
      <div className={styles.bgvFooterItems}>
        <div className={styles.aboutContainer}>
          <div className={styles.aboutUs}>About us</div>
          <div className={styles.paragraph}>
            We are building products for a human-centric approach to HR. SpringVerify background
            verification leverages cutting edge tech to make the process seamless, accurate and
            pain-free.
          </div>
          <div className={styles.copyright}>
            <span className={styles.copyRightText}>{currentYear} </span>
            <span className={styles.andCopy}>&copy;</span>
            <span className={styles.linkText}>SpringRole</span>
          </div>
        </div>
        <div className={styles.bgvFooterItemCopyright}>
          <div className={styles.valuttext}>
            <a href='https://compliance.springworks.in' target='_blank'>
              View our Trust Vault
            </a>
          </div>
          <div className={styles.logosx}>
            <div className={styles.bgvFooterImage}>
              <Image name='iso27701.svg' />
            </div>
            <div className={styles.bgvFooterImage}>
              <Image name='iso27001.svg' />
            </div>
            <div className={styles.bgvFooterImage}>
              <Image name='soc2.svg' />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
