import React, { useState } from 'react';
import styles from './UpsellBanner.module.scss';
import Image from '../../../../core-components/Image';
import { Button } from 'react-bootstrap';
import UpsellCarousel from './components/UpsellCarousel/UpsellCarousel';
import UpsellModals from '../../Upsell/UpsellModals';
import { updateExploreNowEvent } from '../../../../api/company';

const UpsellBanner = (props) => {
  const [showUpsellModal, setShowUpsellModal] = useState(false);

  const onClickExploreNowHandler = () => {
    setShowUpsellModal(true);
    recommendationEventsApi();
  };

  const recommendationEventsApi = () => {
    const data = {
      upsell_recommendation_id: props?.upsellRecommendationData?.id,
      event: 'CLICKED'
    };

    updateExploreNowEvent(data).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div className={styles.bannerContainer}>
      <div onClick={onClickExploreNowHandler} className={styles.lsContainer}>
        <Image name='sv_logo_desktop.svg' className={styles.svInIcon} />
        <h2>Recommended checks for your employees</h2>
      </div>
      <div className={styles.rsContainer}>
        <div className={styles.carouselContainer}>
          <UpsellCarousel
            onClickExploreNowHandler={onClickExploreNowHandler}
            checksList={props?.upsellRecommendationData?.checks}
          />
        </div>
        <Button onClick={onClickExploreNowHandler} className={styles.exploreBtn}>
          Explore now!
        </Button>
      </div>
      {showUpsellModal && (
        <UpsellModals
          getRecommendationsChecksApi={props?.getRecommendationsChecksApi}
          checksList={props?.upsellRecommendationData?.checks}
          upsellRecommendationId={props?.upsellRecommendationData?.id}
          onClose={() => setShowUpsellModal(false)}
        />
      )}
    </div>
  );
};

export default UpsellBanner;
