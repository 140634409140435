import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import { isEmpty, mergeWith } from 'lodash';
import {
  getWalletDetail as getWalletDetailAPI,
  invalidateSelectedTiers
} from '../../../../actions/company';
import { getAmountAndTextArray } from './UtilComponents/UtilitiesFunction';

const mapStateToProps = (state) => {
  return {
    selectedTiers:
      state.selectedTiers && !isEmpty(state.selectedTiers) ? state.selectedTiers : null,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    walletDetail: state.walletDetail || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWalletDetail: (companyId) => dispatch(getWalletDetailAPI(companyId)),
    invalidateSelectedTiers: () => dispatch(invalidateSelectedTiers())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        amount: getAmountAndTextArray(props.walletDetail, 'forAmount'),
        customAmount: '',
        tdsPercentage: null || 'NONE',
        useCurrentWalletBalance: false
      };

      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    handleSubmit: {},
    displayName: 'CreditBuyPage'
  })
);
