import React from 'react';
import Modal from 'react-bootstrap/Modal';

import EditCandidate from '../../EditCandidate';

export default (props) => {
  return (
    <Modal
      size='lg'
      show={props?.modalShow}
      onHide={props?.onModalHide}
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      data-testid="edit-modal"
    >
      <Modal.Body className={'editModalBody'} style={{ padding: 0 }}>
        <EditCandidate editTitle={'Edit'} candidateDetails={props?.candidate} data-testid="edit-candidate"  {...props} />
      </Modal.Body>
    </Modal>
  );
};
