import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';

import validationSchema from './CompanyProfile.validation';
import { editCompanyProfile } from '../../../../actions/authentication';

const initialValues = {
  company_url: '',
  city: '',
  logoUrl: ''
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editCompanyProfile: (data) => dispatch(editCompanyProfile(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      return mergeWith(
        {},
        initialValues,
        props?.profile?.profile?.company_user_mapping?.company,
        (a, b) => (b === null ? a : b)
      );
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, props }) => {
      const formData = {
        company_url: values.company_url,
        city: values.city,
        logoUrl: values.logo_url
      };
      props.editCompanyProfile(formData).then(() => {
        setSubmitting(false);
        setStatus(false);
      });
    },
    displayName: 'Edit Company Pofile'
  })
);
