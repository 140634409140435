import React, { useEffect, useState } from 'react';
import classes from './WalletPackageSummary.module.scss';
import { Badge, Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import Loader from '../../../core-components/Loader/Loader';
import cn from 'classnames';
import Image from './../../../core-components/Image';
import { convertToIndianNumeration } from '../../../utils/utilities';
import _, { isEmpty, orderBy } from 'lodash';
import Package from './Package';
import SubtypesModal from '../frontend-common/CreditBuyPackagePage/Packages/modals/SubtypesModal';
import { Link } from 'react-router-dom';
import { getBannerText } from '../Header/utils';
import TopSection from '../frontend-common/CreditBuyPackagePage/Wallet/TopSection';
import ArrearsBreakdown from '../frontend-common/CreditBuyPackagePage/Wallet/ArrearsBreakdown';
import Banner from '../../../core-components/Banner/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AppConstants from '../../../core-components/AppConstants';
const WalletPackageSummary = (props) => {
  const { walletDetail, getPackages, packages, createCandConfig, getPaymentDetails } = props;
  const [groupedPackages, setGroupedPackages] = useState([]);
  const [isSubtypesModalOpen, setISubtypesModalOpen] = useState(false);
  const [subtypes, setSubtypes] = useState([]);
  useEffect(() => {
    getPackages({ limit: 50, offset: 0 });
    getPaymentDetails();
  }, []);
  useEffect(() => {
    if (packages && packages.rows && packages.rows.length > 0) {
      const groupedArray = _(packages.rows)
        .groupBy('packageId')
        .map((item) => item)
        .value();
      if (groupedArray?.length > 0) {
        let insufficient_packages = groupedArray.filter((pckg) => pckg[0].currentBalance === 0);
        let sufficient_packages = groupedArray.filter(
          (pckg) => pckg[0].currentBalance > Math.floor(pckg[0].leastRechargeUnitsTier * 0.2)
        );
        let low_packages = groupedArray.filter(
          (pckg) =>
            pckg[0].currentBalance !== 0 &&
            pckg[0].currentBalance <= Math.floor(pckg[0].leastRechargeUnitsTier * 0.2)
        );
        insufficient_packages = orderBy(
          insufficient_packages,
          (obj) => obj[0].costPerCandidate,
          'desc'
        );
        sufficient_packages = orderBy(
          sufficient_packages,
          (obj) => obj[0].costPerCandidate,
          'desc'
        );
        low_packages = orderBy(low_packages, (obj) => obj[0].costPerCandidate, 'desc');
        const allPackages = [
          ...insufficient_packages,
          ..._.orderBy(low_packages, ['currentBalance']),
          ..._.orderBy(sufficient_packages, ['currentBalance'])
        ];
        setGroupedPackages(allPackages);
      }
    }
  }, [packages]);

  if (
    (walletDetail && walletDetail?.loading) ||
    (packages && isEmpty(packages)) ||
    packages?.loading
  ) {
    return (
      <div style={{ padding: '240px' }}>
        <Loader />
      </div>
    );
  }
  const banner = (name, text, classStyle = '') => {
    return (
      <Card className={cn(classes.bannerCardContainer, classStyle)}>
        <div className={classes.bannerDivContainer}>
          <Image name={name} isSA={props.isSA} className={classes.warningRedIcon} />
          <span className={classes.bannerText}>{text}</span>
        </div>
      </Card>
    );
  };

  const getWalletPackageBalanceBanner = () => {
    const { text, color } = getBannerText(walletDetail, packages?.rows);

    if (text) {
      return (
        <Card
          className={cn(
            classes.bannerCardContainer,
            color == 'Yellow' ? classes.lowBalance : classes.criticalLow
          )}
          onClick={() => {
            if (props?.historyProps?.history) {
              props.historyProps.history.push('/buy');
            }
          }}
        >
          <div className={classes.bannerDivContainer}>
            <Image
              name={color == 'Yellow' ? 'warningPackage.svg' : 'warningRedIcon.svg'}
              className={classes.warningRedIcon}
            />
            <span className={classes.bannerText}>{text}</span>
          </div>
        </Card>
      );
    }
    return null;
  };
  const getPendingOrderBanner = () => {
    const isCountGreater = walletDetail.pendingOrderCount > 1;

    if (walletDetail.pendingOrderCount > 0) {
      return (
        <Card
          className={classes.pendingOrderBanner}
          onClick={() => {
            window.open(`${AppConstants.baseURL}transaction?tab=po`, '_blank');
          }}
        >
          <div className={classes.bannerDivContainer}>
            There {isCountGreater ? 'are ' : 'is'}
            {isCountGreater ? (
              <span className={classes.bannerText}>
                {walletDetail.pendingOrderCount} pending orders
              </span>
            ) : (
              <span className={classes.bannerText}> 1 pending order</span>
            )}{' '}
            created by your team using "Request Payment" that {isCountGreater ? 'are' : 'is'} yet to
            be paid. Review {isCountGreater ? 'them' : 'it'} before creating{' '}
            {isCountGreater ? 'new orders' : 'a new order'} to avoid duplication.
          </div>
        </Card>
      );
    }
    return null;
  };
  const handleViewChecks = (pck) => {
    setSubtypes(pck);
    setISubtypesModalOpen(true);
  };
  return (
    <>
      <div className={classes.summaryWrapper}>
        <div className={classes.header}>
          <div className={classes.heading}>{'Balances / Buy'}</div>
        </div>
        <Card className={classes.walletCard}>
          {getPendingOrderBanner()}
          {getWalletPackageBalanceBanner()}
          <div className={classes.walletContainer}>
            <span className={classes.subheading}>
              Wallet Balance
              <OverlayTrigger
                key={`infoTooltipDesc`}
                placement='right'
                style
                overlay={
                  <Tooltip id={`infoTooltipDesc`} className={classes.tooltipText}>
                    <p>Wallet amount is used to buy add-ons, approve ADCs, and clear arrears.</p>
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} className={classes.tooltipIcon} />
              </OverlayTrigger>
            </span>
            <div className={classes.currentBalanceContainer}>
              <Accordion>
                {/* ------------- Top Section -------------*/}
                <TopSection walletDetail={walletDetail} id={'summary'} />
                {/* ------------- Breakdown Section -------------*/}
                <Accordion.Collapse eventKey='0'>
                  {walletDetail?.arrears && walletDetail.arrears.length ? (
                    <ArrearsBreakdown walletDetail={walletDetail} />
                  ) : (
                    <></>
                  )}
                </Accordion.Collapse>
              </Accordion>
            </div>
          </div>
          <div className={classes.packageContainer}>
            <span className={classes.subheading}>
              Package Balance
              <OverlayTrigger
                key={`infoTooltipDesc`}
                placement='right'
                style
                overlay={
                  <Tooltip id={`infoTooltipDesc`} className={classes.tooltipText}>
                    <p>Package units are used to add the candidates for verification.</p>
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} className={classes.tooltipIcon} />
              </OverlayTrigger>
            </span>
            <Row>
              {groupedPackages.length ? (
                groupedPackages.map((pck, index) => (
                  <Col sm={6}>
                    <Package
                      onViewCheckClick={() => handleViewChecks(pck)}
                      packageDetails={pck}
                      key={index}
                    />
                  </Col>
                ))
              ) : (
                <Col sm={12} className={classes.noPackageBanner}>
                  <Banner isSA={props.isSA} clsName='noPackageBanner'>
                    There are no packages currently assigned to your account. Please contact our
                    &nbsp;
                    <a href='mailto:cs@springverify.com' target='_blank'>
                      {' '}
                      support team
                    </a>
                  </Banner>
                </Col>
              )}
            </Row>
          </div>
        </Card>
        {isSubtypesModalOpen ? (
          <SubtypesModal
            show={isSubtypesModalOpen}
            subtypes={subtypes}
            handleClose={() => setISubtypesModalOpen(false)}
            isAadhaarAllowed={createCandConfig?.includeAadhaarInIdCheck === 1 ? true : false}
            isSA={props.isSA}
          />
        ) : null}
      </div>
      <div className={classes.bottomNav}>
        <span className={classes.rechargeText}>
          Maintain a healthy balance to auto-approve all verifications
        </span>
        <Link to={{ pathname: '/buy' }}>
          <Button className={classes.rechargeButton}>Recharge Now</Button>
        </Link>
      </div>
    </>
  );
};

export default WalletPackageSummary;
