import { toast } from 'react-toastify';
import {
  getBgvConfig as getBgvConfigAPI,
  getBgvCandidateDetails as getBgvCandidateDetailsAPI,
  getBgvCache as getBgvCacheAPI,
  setBgvCache as setBgvCacheAPI,
  idUpload as idUploadAPI,
  getOcr as getOcrAPI,
  setFeedback as setFeedbackAPI,
  generateBgvConsent as generateBgvConsentAPI,
  saveConsent as saveConsentAPI,
  setBgvSubmission as setBgvSubmissionAPI,
  getBgvPackageConfig as getBgvPackageConfigAPI,
  saveHrRefer as saveHrReferAPI,
  unlockPdf
} from '../api/bgv';

import { errToastMessage, getErrorMessage, toCamelCase } from '../utils/utilities';

export const INVALIDATE_BGV_CONFIG = 'INVALIDATE_BGV_CONFIG';
export const REQUEST_BGV_CONFIG = 'REQUEST_BGV_CONFIG';
export const SET_BGV_CONFIG = 'SET_BGV_CONFIG';

export const INVALIDATE_BGV_CANDIDATE_DETAILS = 'INVALIDATE_BGV_CANDIDATE_DETAILS';
export const REQUEST_BGV_CANDIDATE_DETAILS = 'REQUEST_BGV_CANDIDATE_DETAILS';
export const SET_BGV_CANDIDATE_DETAILS = 'SET_BGV_CANDIDATE_DETAILS';

export const INVALIDATE_BGV_CACHE = 'INVALIDATE_BGV_CACHE';
export const REQUEST_BGV_CACHE = 'REQUEST_BGV_CACHE';
export const SET_BGV_CACHE = 'SET_BGV_CACHE';

export const SET_ID_UPLOAD = 'SET_ID_UPLOAD';
export const SET_OCR = 'SET_OCR';
export const SET_OCR_ERROR = 'SET_OCR_ERROR';

export const INVALIDATE_CERTIFICATE_UPLOAD = 'INVALIDATE_CERTIFICATE_UPLOAD';
export const SET_CERTIFICATE_UPLOAD = 'SET_CERTIFICATE_UPLOAD';
export const SET_CERTIFICATE_UPLOAD_UPDATED_WITH_PASSWORD =
  'SET_CERTIFICATE_UPLOAD_UPDATED_WITH_PASSWORD';

export const INVALIDATE_GRADESHEET_UPLOAD = 'INVALIDATE_GRADESHEET_UPLOAD';
export const SET_GRADESHEET_UPLOAD = 'SET_GRADESHEET_UPLOAD';
export const SET_GRADESHEET_UPLOAD_UPDATED_WITH_PASSWORD =
  'SET_GRADESHEET_UPLOAD_UPDATED_WITH_PASSWORD';
export const SET_SINGLE_GRADESHEET_UPLOAD = 'SET_SINGLE_GRADESHEET_UPLOAD';
export const SET_SINGLE_GRADESHEET_UPLOAD_UPDATED = 'SET_SINGLE_GRADESHEET_UPLOAD_UPDATED';
export const DELETE_GRADESHEET_UPLOAD = 'DELETE_GRADESHEET_UPLOAD';

export const SET_BGV_FEEDBACK = 'SET_BGV_FEEDBACK';
export const INVALIDATE_BGV_FEEDBACK = 'INVALIDATE_BGV_FEEDBACK';

export const SET_TEMPORARY_EDUCATION = 'SET_TEMPORARY_EDUCATION';
export const INVALIDATE_TEMPORARY_EDUCATION = 'INVALIDATE_TEMPORARY_EDUCATION';

export const SET_BGV_CONSENT = 'SET_BGV_CONSENT';
export const INVALIDATE_BGV_CONSENT = 'INVALIDATE_BGV_CONSENT';

export const INVALIDATE_EMP_DOC_TYPE = 'INVALIDATE_EMP_DOC_TYPE';
export const SET_EMP_DOC_TYPE = 'SET_EMP_DOC_TYPE';

export const SET_ADDRESS_DOC_TYPE = 'SET_ADDRESS_DOC_TYPE';
export const INVALIDATE_ADDRESS_DOC_TYPE = 'INVALIDATE_ADDRESS_DOC_TYPE';

export const SET_ADDRESS_FRONT_DOC = 'SET_ADDRESS_FRONT_DOC';
export const INVALIDATE_ADDRESS_FRONT_DOC = 'INVALIDATE_ADDRESS_FRONT_DOC';
export const SET_ADDRESS_BACK_DOC = 'SET_ADDRESS_BACK_DOC';
export const INVALIDATE_ADDRESS_BACK_DOC = 'INVALIDATE_ADDRESS_BACK_DOC';
export const SET_ADDRESS_SAME_DOC = 'SET_ADDRESS_SAME_DOC';
export const INVALIDATE_ADDRESS_SAME_DOC = 'INVALIDATE_ADDRESS_SAME_DOC';

export const SET_BGV_PACKAGE_CONFIG = 'SET_BGV_PACKAGE_CONFIG';
export const INVALIDATE_BGV_PACKAGE_CONFIG = 'INVALIDATE_BGV_PACKAGE_CONFIG';
export const REQUEST_BGV_PACKAGE_CONFIG = 'REQUEST_BGV_PACKAGE_CONFIG';

export const REQUEST_HR_REFER = 'REQUEST_HR_REFER';
export const SAVE_HR_REFER = 'SAVE_HR_REFER';
export const INVALIDATE_HR_REFER = 'INVALIDATE_HR_REFER';
export const SKIP_HR_REFER = 'SKIP_HR_REFER';

export const invalidateBgvConfig = () => {
  return {
    type: INVALIDATE_BGV_CONFIG
  };
};

export const requestBgvConfig = () => {
  return {
    type: REQUEST_BGV_CONFIG
  };
};

export const setBgvConfig = (data) => {
  return {
    type: SET_BGV_CONFIG,
    data
  };
};

export const invalidateBgvCandidateDetails = () => {
  return {
    type: INVALIDATE_BGV_CANDIDATE_DETAILS
  };
};

export const requestBgvCandidateDetails = () => {
  return {
    type: REQUEST_BGV_CANDIDATE_DETAILS
  };
};

export const setBgvCandidateDetails = (data) => {
  return {
    type: SET_BGV_CANDIDATE_DETAILS,
    data
  };
};

export const invalidateBgvCache = () => {
  return {
    type: INVALIDATE_BGV_CACHE
  };
};

export const requestBgvCache = () => {
  return {
    type: REQUEST_BGV_CACHE
  };
};

export const setBgvCache = (data) => {
  return {
    type: SET_BGV_CACHE,
    data
  };
};

export const setIdUpload = (data) => {
  return {
    type: SET_ID_UPLOAD,
    data
  };
};

export const setOcr = (data) => {
  return {
    type: SET_OCR,
    data
  };
};

export const invalidateCertificateUpload = () => {
  return {
    type: INVALIDATE_CERTIFICATE_UPLOAD
  };
};

export const setCertificateUpload = (data) => {
  return {
    type: SET_CERTIFICATE_UPLOAD,
    data
  };
};
export const setCertificateUploadUpdatedWithPassword = (data) => {
  // Added this because on clicking of Go Back button, store should be updated with password field
  return {
    type: SET_CERTIFICATE_UPLOAD_UPDATED_WITH_PASSWORD,
    data
  };
};

export const invalidateGradesheetUpload = () => {
  return {
    type: INVALIDATE_GRADESHEET_UPLOAD
  };
};

export const setGradesheetUpload = (data) => {
  return {
    type: SET_GRADESHEET_UPLOAD,
    data
  };
};

export const setGradesheetUploadedUpdatedWithPassword = (data) => {
  // Added this because on clicking of Go Back button, store should be updated with password field
  return {
    type: SET_GRADESHEET_UPLOAD_UPDATED_WITH_PASSWORD,
    data
  };
};

export const setSingleGradeUpload = (data) => {
  return {
    type: SET_SINGLE_GRADESHEET_UPLOAD,
    data
  };
};
export const setSingleGradeUploadUpdated = (data) => {
  return {
    type: SET_SINGLE_GRADESHEET_UPLOAD_UPDATED,
    data
  };
};

export const deleteGradesheetUpload = (data) => {
  return {
    type: DELETE_GRADESHEET_UPLOAD,
    data
  };
};

export const setFeedback = (data) => {
  return {
    type: SET_BGV_FEEDBACK,
    data
  };
};

export const invalidateFeedback = () => {
  return {
    type: INVALIDATE_BGV_FEEDBACK
  };
};

export const setOcrError = (data) => {
  return {
    type: SET_OCR_ERROR,
    data
  };
};

// setting/invalidating temporary data in the case of edit in educaiton section
export const setTemporaryEducationData = (data) => {
  return {
    type: SET_TEMPORARY_EDUCATION,
    data
  };
};

export const invalidateTemporaryEducationData = () => {
  return {
    type: INVALIDATE_TEMPORARY_EDUCATION
  };
};

export const setConsentData = (data) => {
  return {
    type: SET_BGV_CONSENT,
    data
  };
};

export const invalidateConsentData = (data) => {
  return {
    type: INVALIDATE_BGV_CONSENT,
    data
  };
};

export const invalidateEmpDocType = () => {
  return {
    type: INVALIDATE_EMP_DOC_TYPE
  };
};

export const setEmpDocType = (data) => {
  return {
    type: SET_EMP_DOC_TYPE,
    data
  };
};

//doc type for address step 3
export const setAddressDocType = (data) => {
  return {
    type: SET_ADDRESS_DOC_TYPE,
    data
  };
};

export const invalidateAddressDocType = () => {
  return {
    type: INVALIDATE_ADDRESS_DOC_TYPE
  };
};

export const setAddressFrontDoc = (data) => {
  return {
    type: SET_ADDRESS_FRONT_DOC,
    data
  };
};
export const setAddressBackDoc = (data) => {
  return {
    type: SET_ADDRESS_BACK_DOC,
    data
  };
};
export const setAddressSameDoc = (data) => {
  return {
    type: SET_ADDRESS_SAME_DOC,
    data
  };
};

export const invalidateAddressFrontDoc = () => {
  return {
    type: INVALIDATE_ADDRESS_FRONT_DOC
  };
};

export const invalidateAddressBackDoc = () => {
  return {
    type: INVALIDATE_ADDRESS_BACK_DOC
  };
};
export const invalidateAddressSameDoc = () => {
  return {
    type: INVALIDATE_ADDRESS_SAME_DOC
  };
};

export const requestBgvPackageConfig = () => {
  return {
    type: REQUEST_BGV_PACKAGE_CONFIG
  };
};
export const setBgvPackageConfig = (data) => {
  return {
    type: SET_BGV_PACKAGE_CONFIG,
    data
  };
};

export const invalidateBgvPackageConfig = () => {
  return {
    type: INVALIDATE_BGV_PACKAGE_CONFIG
  };
};

export const requestHrRefer = () => {
  return {
    type: REQUEST_HR_REFER
  };
};

export const setHrRefer = (data) => {
  return {
    type: SAVE_HR_REFER,
    data
  };
};

export const invalidateHrRefer = () => {
  return {
    type: INVALIDATE_HR_REFER
  };
};
export const skipHrRefer = () => {
  return {
    type: SKIP_HR_REFER
  };
};

export const getBgvConfig = () => (dispatch) => {
  return getBgvConfigAPI()
    .then((response) => {
      dispatch(setBgvConfig(response.data));
    })
    .catch((error) => console.log(error));
};

export const getBgvCandidateDetails = () => (dispatch) => {
  dispatch(requestBgvCandidateDetails());
  return getBgvCandidateDetailsAPI()
    .then((response) => {
      dispatch(setBgvCandidateDetails(response.data));
    })
    .catch((error) => {
      console.error(error);
      dispatch(setBgvCandidateDetails({ error: getErrorMessage(error) }));
    });
};

export const getBgvCache = () => (dispatch) => {
  dispatch(requestBgvCache());
  return getBgvCacheAPI()
    .then((response) => {
      dispatch(setBgvCache(response.data));
    })
    .catch((error) => console.log(error));
};

export const saveBgvCache = (data) => (dispatch) => {
  sessionStorage.setItem('reload', 'false');
  dispatch(invalidateBgvCache());
  return setBgvCacheAPI(data)
    .then((response) => {
      dispatch(setBgvCache(response.data));
    })
    .catch((error) => console.log(error));
};

export const idUpload = (data) => (dispatch) => {
  return idUploadAPI(data)
    .then((response) => {
      dispatch(setIdUpload(response.data));
    })
    .catch((error) => {
      console.error(error);
      errToastMessage(error?.response?.data?.message);
      throw error.response.data.message;
    });
};

export const educationCertificateUpload = (data) => (dispatch) => {
  return idUploadAPI(data.formData)
    .then((response) => {
      dispatch(setCertificateUpload({ link: response.data.data, isPdf: data.isPdf }));
      dispatch(setSingleGradeUpload({ link: response.data, type: 'certificate' }));
    })
    .catch((error) => {
      console.log(error);
      throw error.response.data.message;
    });
};

export const educationGradesheetUpload = (data) => (dispatch) => {
  return idUploadAPI(data.formData)
    .then((response) => {
      dispatch(setSingleGradeUpload({ link: response.data, index: data.index }));
      return { link: response.data.data, isPdf: data.isPdf, index: data.index };
    })
    .catch((error) => {
      console.log(error);
      throw error.response.data.message;
    });
};

export const getOcr = (data) => (dispatch) => {
  return getOcrAPI(data)
    .then((response) => {
      const ocrData = toCamelCase(response.data);
      dispatch(setOcr(ocrData));
    })
    .catch((error) => {
      const ocrError = true;
      dispatch(setOcrError(ocrError));
    });
};

export const saveFeedback = (data) => () => {
  return setFeedbackAPI(data);
};

export const generateBgvConsent = (data) => () => {
  return generateBgvConsentAPI(data);
};

export const saveConsent = (data) => () => {
  return saveConsentAPI(data);
};

export const setBgvSubmission = (data) => () => {
  return setBgvSubmissionAPI(data);
};

export const getBgvPackageConfig = () => (dispatch) => {
  dispatch(requestBgvPackageConfig());
  return getBgvPackageConfigAPI()
    .then((response) => {
      dispatch(setBgvPackageConfig(response.data));
    })
    .catch((error) => Promise.reject(error));
};

export const pdfUnlock = (data, checkType) => (dispatch) => {
  return unlockPdf(data)
    .then((response) => {
      switch (checkType) {
        case 'address':
        case 'id':
          dispatch(setIdUpload(response.data.data));
          break;
        case 'employment':
          dispatch(setSingleGradeUploadUpdated(response.data.data));
          break;
        case 'education':
          dispatch(setSingleGradeUploadUpdated(response.data.data));
          break;
        default:
          break;
      }
      return response.data.data.url;
    })
    .catch((error) => {
      throw error.response.data.message;
    });
};
export const saveHrRefer = (data) => (dispatch) => {
  dispatch(setHrRefer(data));
};
export const skipHrReferral = () => (dispatch) => {
  dispatch(skipHrRefer());
};
