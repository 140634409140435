import React from 'react';

import { isEmpty, isArray, has } from 'lodash';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { toast } from 'react-toastify';

import EducationSaved from './EducationSaved/EducationSaved';
import EducationModals from './EducationModals/index';
import BgvSecureText from '../../../CommonComponent/BgvSecureText/BgvSecureText';
import BgvGoBackBtn from '../../../CommonComponent/BgvGoBackBtn/BgvGoBackBtn';
import BGVConfirmDialog from '../../../CommonComponent/BGVConfirmDialog';
import ProgressBar from '../../../../../core-components/ProgressBar';
import logger from '../../../../../core-components/Logger';

import { formValuesToAPI } from '../../../CommonComponent/Bgv.mapper';
import { errToastMessage, toSnakeCase } from '../../../../../utils/utilities';
import { EducationArr } from '../../../../../utils/commonConstant';

import styles from './Education.module.scss';
import SkipEducationModal from './SkipEducationModal/SkipEducationModal';

let indexToRemove = null;
class Education extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      modalSequenceId: [1, 2],
      subStep: 1,
      currentSavingIndex: 0,
      isEdit: false,
      showConfirmation: false,
      educationLevel: null,
      educationConfig: null,
      isEducationTypeArray: false,
      noOfEducationToSave: 0,
      isSkipEducation: false,
      showSkipEducationModal: false
    };
  }

  nextSubStep = () => {
    this.setState({
      subStep: 2
    });
  };

  prevSubStep = () => {
    this.setState({
      subStep: 1
    });
  };
  getCurrentSubStep = (educationCheck) => {
    return educationCheck.hasOwnProperty('step1') && educationCheck.hasOwnProperty('step2') ? 1 : 2;
  };
  // For specific education
  newShowStep1Modal = (index = null, level = null, isEdit = false) => {
    this.setState(
      {
        isEdit,
        educationLevel: level,
        modalShow: !this.state.modalShow,
        currentSavingIndex: index,
        subStep:
          this?.props?.educationCheck &&
          this?.props?.educationCheck[index] &&
          !isEmpty(this.props.educationCheck[index])
            ? this.getCurrentSubStep(this.props.educationCheck[index])
            : 1
      },
      () => {
        // Invalidating uploaded certificate, gradesheet, education details from store
        this.props.invalidateCertificateUpload();
        this.props.invalidateGradesheetUpload();
        this.props.invalidateTemporaryEducationData();

        if (this.state.modalShow) {
          if (
            this?.props.educationCheck &&
            this?.props.educationCheck?.length &&
            this?.props.educationCheck[this.state.currentSavingIndex] &&
            !isEmpty(this.props.educationCheck[this.state.currentSavingIndex])
          ) {
            const value = this.props.educationCheck[this.state.currentSavingIndex];

            if (value.step2 && !isEmpty(value.step2.certificate)) {
              this.props.setCertificateUpload(value.step2.certificate);
            }

            if (value.step2 && value.step2.gradeSheet && value.step2.gradeSheet.length) {
              for (let i in value.step2.gradeSheet) {
                this.props.setGradesheetUpload({
                  ...value.step2.gradeSheet[parseInt(i)],
                  index: parseInt(i)
                });
              }
            }
          }
        }
      }
    );
  };

  // For normal education
  setModalHide = (isEdit = false, index = null) => {
    this.setState(
      {
        modalShow: !this.state.modalShow,
        subStep:
          isEdit &&
          this?.props?.educationCheck &&
          this?.props?.educationCheck[index] &&
          !isEmpty(this.props.educationCheck[index])
            ? this.getCurrentSubStep(this.props.educationCheck[index])
            : 1,
        currentSavingIndex:
          isEdit && index !== null
            ? index
            : this.checkEducationSaved(this.props.bgvCachedData.cacheData),
        isEdit
      },
      () => {
        // Invalidating uploaded certificate, gradesheet, education details from store
        this.props.invalidateCertificateUpload();
        this.props.invalidateGradesheetUpload();
        this.props.invalidateTemporaryEducationData();

        if (this.state.modalShow) {
          if (
            this.props.educationCheck &&
            this.props.educationCheck.length &&
            this.props.educationCheck[this.state.currentSavingIndex]
          ) {
            const value = this.props.educationCheck[this.state.currentSavingIndex];

            if (value.step2 && !isEmpty(value.step2.certificate)) {
              this.props.setCertificateUpload(value.step2.certificate);
            }

            if (value.step2 && value.step2.gradeSheet && value.step2.gradeSheet.length) {
              for (let i in value.step2.gradeSheet) {
                this.props.setGradesheetUpload({
                  ...value.step2.gradeSheet[parseInt(i)],
                  index: parseInt(i)
                });
              }
            }
          }
        }
      }
    );
  };

  enableButton = (educationCheck) => {
    let makeEnable = true;
    educationCheck?.length > 0 &&
      educationCheck.forEach((edu) => {
        if (
          !has(edu, 'step1') ||
          !has(edu, 'step2') ||
          (has(edu, 'step1') && isEmpty(edu.step1)) ||
          (has(edu, 'step2') && isEmpty(edu.step2))
        ) {
          makeEnable = false;
        }
      });
    return makeEnable;
  };

  handleEducationDelete = (index) => {
    let finalStepObj = [...this.props.educationCheck];

    if (this.state.isEducationTypeArray) {
      finalStepObj[index] = {};
    } else {
      finalStepObj.splice(index, 1); //deleting array of particular index
    }
    let cacheData = { ...this.props.bgvCachedData.cacheData };

    if (finalStepObj.length) {
      cacheData = { ...cacheData, educationCheck: [...finalStepObj] };
    } else {
      cacheData = { ...cacheData };
      delete cacheData['educationCheck'];
    }

    let saveObject = toSnakeCase({
      ...this.props.bgvCachedData,
      cacheData: { ...cacheData },
      currentState: '5',

      // setting isSkipEducation to false if all the education is deleted
      isSkipEducation: !finalStepObj.length
        ? false
        : this.props.bgvCachedData.isSkipEducation || false
    });

    //formatting cache data to save
    const finalCacheData = {
      saveObject
    };

    this.props
      .setBgvCache(finalCacheData)
      .then(() => {
        // logger code
        const loggerObj = {
          message: `${index} education is deleted`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${this.props.candidateId}`,
          subCategory: 'Education',
          type: 'info'
        };

        logger.push(loggerObj);
      })
      .catch((error) => {
        console.error(error);

        // logger code
        const loggerObj = {
          message: `${index} education is not deleted`,
          data: JSON.stringify(finalCacheData),
          category: `candidate-id-${this.props.candidateId}`,
          subCategory: 'Education',
          type: 'error'
        };

        logger.push(loggerObj);
      });
  };

  handleEducationEdit = (index, educationLevel = null) => {
    if (this.state.isEducationTypeArray) {
      this.newShowStep1Modal(index, educationLevel, true);
    } else {
      this.setModalHide(true, index);
    }
  };

  handleConfirmModal = () => {
    this.setState({ showConfirmation: !this.state.showConfirmation });
  };

  handleSubmit = () => {
    this.handleConfirmModal();
  };

  handleConfirmSubmit = () => {
    if (this.props.finalSubmitCheck === 5) {
      try {
        this.props.validateFinalSubmit(
          formValuesToAPI(toSnakeCase(this.props.bgvCachedData.cacheData))
        );
      } catch (error) {
        console.error(error);
        errToastMessage('The form was not succesfully submitted. Please try again.');
      }
    } else {
      this.props.nextStep(true);
    }
  };

  handleFurtherEducation = (e) => {
    const isEnable = this.enableButton(this.props.educationCheck),
      loggerObj = {
        message: `Education Main Page Further Checkbox - ${e.target.checked}`,
        data: JSON.stringify(this.props.educationCheck),
        category: `candidate-id-${this.props.candidateId}`,
        subCategory: 'Education',
        type: 'info'
      };

    logger.push(loggerObj);
    if (
      this.checkEducationSaved(this.props.bgvCachedData.cacheData) > 0 &&
      this.checkEducationSaved(this.props.bgvCachedData.cacheData) !==
        this.state.noOfEducationToSave
    ) {
      if (e.target.checked) {
        this.handleSkipEducationModal();

        let finalStepObj = (this.props.educationCheck && [...this.props.educationCheck]) || [];

        if (!isEnable) {
          finalStepObj = finalStepObj.filter((obj) => {
            return (
              has(obj, 'step1') && has(obj, 'step2') && !isEmpty(obj.step1) && !isEmpty(obj.step2)
            );
          });
        }

        let cacheData = { ...this.props.bgvCachedData.cacheData };

        if (finalStepObj.length) {
          cacheData = { ...cacheData, educationCheck: [...finalStepObj] };
        } else {
          cacheData = { ...cacheData };
          delete cacheData['educationCheck'];
        }

        let saveObject = toSnakeCase({
          ...this.props.bgvCachedData,
          cacheData: { ...cacheData },
          currentState: '5',
          isSkipEducation: true
        });

        this.saveFurtherCheck(saveObject);
      } else {
        let saveObject = toSnakeCase({
          ...this.props.bgvCachedData,
          currentState: '5',
          isSkipEducation: false
        });

        this.saveFurtherCheck(saveObject);
      }
      this.setState({ isSkipEducation: e.target.checked });
    }
  };

  saveFurtherCheck = (saveObject) => {
    //formatting cache data to save
    const finalCacheData = {
      saveObject
    };

    this.props
      .setBgvCache(finalCacheData)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  formatConfig = (config = []) => {
    let arr = [];

    Object.keys(EducationArr).map((item) => {
      if (config.includes(item)) {
        arr.push(item);
      }
    });

    return arr;
  };

  handleSkipEducationModal = () => {
    this.setState({ showSkipEducationModal: !this.state.showSkipEducationModal });
  };
  isAllEducationFilled = (educationCheck) => {
    if (!isEmpty(educationCheck)) {
      const educheckArray = educationCheck.map((d) => {
        return (
          d.hasOwnProperty('step1') &&
          !isEmpty(d.step1) &&
          d.hasOwnProperty('step2') &&
          !isEmpty(d.step2)
        );
      });
      return educheckArray?.every((check) => check === true);
    } else {
      return false;
    }
  };
  noOfValidDetail = (educationCheck) => {
    let count = 0;
    for (let i in educationCheck) {
      const keys = Object.keys(educationCheck[i]);

      if (keys.includes('step1')) {
        count++;
      }
    }
    return count;
  };

  checkEducationSaved = (bgvCachedData) => {
    if (bgvCachedData.educationCheck) {
      return this.noOfValidDetail(bgvCachedData.educationCheck);
    } else {
      return 0;
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isSkipEducation !== this.props.isSkipEducation) {
      this.setState({ isSkipEducation: this.props.isSkipEducation });
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    let educationConfig =
      (this.props.bgvConfig &&
        this.props.bgvConfig.config &&
        this.props.bgvConfig.config.education) ||
      null;

    const isArr = isArray(educationConfig);

    if (isArr) {
      educationConfig = this.formatConfig(educationConfig);
    }

    this.setState(
      {
        educationConfig,
        isEducationTypeArray: isArr,
        noOfEducationToSave: isArr ? educationConfig.length : parseInt(educationConfig) || 0,
        isSkipEducation: this.props.isSkipEducation
      },
      () => {
        const { educationCheck } = this.props;

        if (educationCheck && educationCheck.length > this?.state?.noOfEducationToSave) {
          let diffCount = educationCheck.length - this.state.noOfEducationToSave;
          errToastMessage(
            `Please delete ${diffCount} education qualification under Education section to proceed`
          );
        }

        // logger code
        const loggerObj = {
          message: 'Education Main Page',
          data: JSON.stringify(this.props.bgvConfig),
          category: `candidate-id-${this.props.candidateId}`,
          subCategory: 'Education',
          type: 'info'
        };

        logger.push(loggerObj);
      }
    );
  }

  componentWillUnmount() {
    this.props.invalidateTemporaryEducationData();
    this.props.invalidateCertificateUpload();
    this.props.invalidateGradesheetUpload();
  }

  render() {
    const { educationCheck } = this.props;
    const isEnable =
      this.state.isSkipEducation ||
      (educationCheck &&
        educationCheck.length === this.state.noOfEducationToSave &&
        this.enableButton(educationCheck)) ||
      this.state.noOfEducationToSave === 0
        ? true
        : false;

    const indexing =
      this.props.totalSteps
        .map((val) => {
          return val?.id;
        })
        .indexOf(5) + 1;
    const cachedEducation = this.props?.bgvCachedData?.cacheData;
    const savedEducationCount = this.checkEducationSaved(cachedEducation);

    const isNoEducationSaved = savedEducationCount < 1;
    const isAllEducationSaved = savedEducationCount === this.state?.noOfEducationToSave;
    const isExcessEducationSaved = savedEducationCount > this.state?.noOfEducationToSave;

    return (
      <>
        <Card className={styles.educationCard}>
          <Card.Body className={styles.educationCardBody}>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                {!isMobileOnly ? (
                  <>
                    <span className={styles.educationHeading}>Education</span>
                    <div className={styles.educationProgressContainer}>
                      <span className={styles.stepText}>
                        {indexing} of {this.props.totalSteps.length}
                      </span>
                      <ProgressBar max={this.props.totalSteps.length} min={0} now={indexing} />
                    </div>
                  </>
                ) : (
                  <>
                    <BgvSecureText />
                    <div className={styles.educationProgressContainerMobile}>
                      <span className={styles.educationHeading}>Education</span>
                      <span className={styles.stepTextMobile}>
                        {indexing} of {this.props.totalSteps.length}
                      </span>
                    </div>
                    <ProgressBar max={this.props.totalSteps.length} min={0} now={indexing} />
                  </>
                )}

                {(!educationCheck && this.state.noOfEducationToSave === 0) ||
                (educationCheck &&
                  educationCheck.length === 0 &&
                  this.state.noOfEducationToSave === 0) ? (
                  <p className={styles.noEducationHeadingInfo}>
                    {'Education details are not required'}
                  </p>
                ) : this.state.isEducationTypeArray ? (
                  <p className={styles.addHeadingInfo}>{'Add your education details'}</p>
                ) : (
                  <p className={styles.addHeadingInfo}>
                    {`Add your ${
                      this.state.noOfEducationToSave > 1
                        ? this.state.noOfEducationToSave + ' highest qualifications'
                        : ' highest qualification'
                    }`}
                  </p>
                )}

                {/* Non specific saved education */}
                {!this.state.isEducationTypeArray &&
                  educationCheck &&
                  educationCheck.length &&
                  educationCheck.map((item, i) => {
                    const keys = Object.keys(educationCheck[i]);
                    return (
                      keys.includes('step1') && (
                        <EducationSaved
                          item={item}
                          index={i}
                          key={`education_saved_${i}`}
                          showhr={i !== 0 ? true : false}
                          handleEducationDelete={this.handleEducationDelete}
                          handleEducationEdit={this.handleEducationEdit}
                        />
                      )
                    );
                  })}

                {/* render add education button, saved education when config is of type array */}
                {this.state.educationConfig && this.state.isEducationTypeArray ? (
                  this.state.educationConfig?.map((item, index) => {
                    return (
                      <Row key={index} className={styles.addEducationRow}>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <p>
                            <span className={styles.grade}>{EducationArr[item]}</span>
                            <span className={styles.mandatory}>*</span>
                          </p>
                        </Col>

                        {educationCheck &&
                        educationCheck.length &&
                        educationCheck[index] &&
                        educationCheck[index]['step1'] &&
                        educationCheck[index]['step1'].educationLevel === item ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <EducationSaved
                              item={educationCheck[index]}
                              index={index}
                              key={`education_saved_${index}`}
                              showhr={index !== this.state.educationConfig.length - 1}
                              handleEducationDelete={this.handleEducationDelete}
                              handleEducationEdit={this.handleEducationEdit}
                              isSpecific={this.state.isEducationTypeArray}
                            />
                          </Col>
                        ) : (
                          <Col lg={5} md={10} sm={12} xs={12} style={{ marginBottom: '40px' }}>
                            <Button
                              className={styles.addEducationBtn}
                              onClick={() => this.newShowStep1Modal(index, item)}
                            >
                              {'+  Add Education'}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    );
                  })
                ) : !this.state.isSkipEducation &&
                  this.state.noOfEducationToSave >
                    this.checkEducationSaved(this.props.bgvCachedData.cacheData) ? (
                  <Row style={{ marginTop: '30px' }}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Row>
                        <Col lg={5} md={10} sm={12} xs={12}>
                          <Button
                            className={styles.addEducationBtn}
                            onClick={() => this.setModalHide()}
                          >
                            {'+  Add Education'}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>

            {/* footer part */}
            <Row className={styles.educationFooterContainer}>
              {!this.state.isEducationTypeArray &&
              this.state.noOfEducationToSave > (educationCheck?.length || 0) &&
              this.isAllEducationFilled(educationCheck) ? (
                <Col sm={12} xs={12} md={12} lg={12} className={styles.checkBoxContainer}>
                  <Form.Check
                    checked={this.state.isSkipEducation}
                    disabled={isNoEducationSaved || isAllEducationSaved || isExcessEducationSaved}
                    type={'checkbox'}
                    id='isSkipEducation'
                    name='isSkipEducation'
                    label={`I don't have any further education qualifications than what is provided.`}
                    onChange={this.handleFurtherEducation}
                  />
                </Col>
              ) : null}

              <Col sm={12} xs={12} md={12} lg={12} className={styles.educationSubmitBtn}>
                <BgvGoBackBtn handleClick={this.props.prevStep} />
                {this.props.finalSubmitCheck === 5 ? (
                  <Button
                    type='button'
                    className={isEnable ? styles.activeBtn : styles.disabledBtn}
                    disabled={!isEnable}
                    onClick={() => this.handleSubmit()}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    type='button'
                    className={
                      isEnable && !this.props.isLoadingBgvCache && !this.props.internalLoading
                        ? styles.activeBtn
                        : styles.disabledBtn
                    }
                    disabled={
                      !isEnable || this.props.isLoadingBgvCache || this.props.internalLoading
                    }
                    onClick={() => this.props.nextStep(true)}
                  >
                    Next
                  </Button>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* Modal for normal education */}
        {this.state.modalShow && (
          <EducationModals
            isSpecific={this.state.isEducationTypeArray}
            educationLevel={this.state.educationLevel}
            currentSubStep={this.state.subStep}
            nextSubStep={this.nextSubStep}
            prevSubStep={this.prevSubStep}
            totalStep={this.state.modalSequenceId.length}
            currentSavingIndex={this.state.currentSavingIndex}
            isEdit={this.state.isEdit}
            show={this.state.modalShow}
            onHide={() => this.setModalHide()}
            {...this.props}
          />
        )}
        {/* show={this.state.newModalShow}
        onHide={() => this.newShowStep1Modal()}
        currentSubStep={this.state.subStep}
            nextSubStep={this.nextSubStep}
            prevSubStep={this.prevSubStep}
            totalStep={this.state.modalSequenceId.length}
            currentSavingIndex={this.state.currentSavingIndex}
            isEdit={this.state.isEdit} */}

        {/* Confirm Dialog */}
        {this.state.showConfirmation && (
          <BGVConfirmDialog
            showConfirmation={this.state.showConfirmation}
            handleConfirmSubmit={this.handleConfirmSubmit}
            handleHide={this.handleConfirmModal}
            enableBtn={isEnable}
            {...this.props}
          />
        )}
        {/* Skip education Dialog */}
        {this.state.showSkipEducationModal && (
          <SkipEducationModal
            show={this.state.showSkipEducationModal}
            onHide={this.handleSkipEducationModal}
            {...this.props}
          />
        )}
      </>
    );
  }
}

export default Education;
