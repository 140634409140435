// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=file] {
  display: none;
}

.geW6xKWJZwYmEeoIR72q {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  margin-bottom: 32px;
}
.geW6xKWJZwYmEeoIR72q .FGRe_t5ebfBP427OQmHl {
  float: right;
  line-height: 38px !important;
  margin-bottom: 0 !important;
  background: #ebedf2;
  border: 1px solid #e7e9ef;
  border-radius: 0px 4px 4px 0px;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
.geW6xKWJZwYmEeoIR72q .dzdorFoW0JKYIrjGFcnc {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  font-family: Poppins, sans-serif;
  padding: 12px;
}
.geW6xKWJZwYmEeoIR72q .yUIBMynDQFIBkJz2vsA5 {
  border: none;
}

.jywJ_EDIt0R9a88zn25Q {
  margin: 10px;
}

.UiKOXaUWIT_CUrDQbFa4 {
  font-size: 13px;
  color: #dc3545;
  margin-top: -23px !important;
  margin-bottom: 50px !important;
}`, "",{"version":3,"sources":["webpack://./src/core-components/ValidatedFileSelect/ValidatedFileSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,qBAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;EACA,4BAAA;EACA,2BAAA;EACA,mBAAA;EACA,yBAAA;EACA,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AAAE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gCAAA;EACA,aAAA;AAEJ;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,YAAA;AACF;;AACA;EACE,eAAA;EACA,cAAA;EACA,4BAAA;EACA,8BAAA;AAEF","sourcesContent":["input[type='file'] {\n  display: none;\n}\n\n.fileInput {\n  border: 1px solid #ebedf2;\n  border-radius: 4px;\n  width: 100%;\n  display: inline-block;\n  margin-bottom: 32px;\n  .customFileUpload {\n    float: right;\n    line-height: 38px !important;\n    margin-bottom: 0 !important;\n    background: #ebedf2;\n    border: 1px solid #e7e9ef;\n    border-radius: 0px 4px 4px 0px;\n    width: 80px;\n    text-align: center;\n    cursor: pointer;\n  }\n  .fileName {\n    font-size: 14px;\n    line-height: 16px;\n    color: #333333;\n    font-family: Poppins, sans-serif;\n    padding: 12px;\n  }\n  .consentFile {\n    border: none;\n  }\n}\n\n.consentDocFile {\n  margin: 10px;\n}\n.errorMsg {\n  font-size: 13px;\n  color: #dc3545;\n  margin-top: -23px !important;\n  margin-bottom: 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `geW6xKWJZwYmEeoIR72q`,
	"customFileUpload": `FGRe_t5ebfBP427OQmHl`,
	"fileName": `dzdorFoW0JKYIrjGFcnc`,
	"consentFile": `yUIBMynDQFIBkJz2vsA5`,
	"consentDocFile": `jywJ_EDIt0R9a88zn25Q`,
	"errorMsg": `UiKOXaUWIT_CUrDQbFa4`
};
export default ___CSS_LOADER_EXPORT___;
