import React from 'react';
import styles from './AutoPilot.module.scss';
import { Button, Card } from 'react-bootstrap';
import Image from './../../../../core-components/Image/Image';
import AppConstants from '../../../../core-components/AppConstants';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';

const hrmsStep = [
  {
    text: `Connect your HRMS, or Upload data`,
    image: 'csv-data.svg',
    height: '120px',
    textWidth: '199px'
  },
  {
    text: 'Automated and instant response to BGV requests',
    image: 'response-bgv.svg',
    height: '95px',
    textWidth: '226px'
  },
  {
    text: 'Earn SpringVerify credits after every request served',
    image: 'amount-wallet.svg',
    height: '101px',
    textWidth: '226px'
  }
];
const AutoPilot = () => {
  return (
    <Card className={styles.autoPilotWrapper}>
      <div className={styles.containerFlex}>
        <div className={styles.headingContainer}>
          <span className={styles.headingText}>Put ex-employee verification on auto-pilot</span>
          <span className={styles.headingPill}>Takes less than 5 minutes</span>
        </div>
        <div className={styles.subtextContainer}>
          <Image name='sparkle.svg' />
          <span className={styles.subtext}>
            Free upto 100% of HR time in confirming background check requests.{' '}
          </span>
        </div>
        <div className={styles.subtextContainer}>
          <Image name='sparkle.svg' />
          <span className={styles.subtext}>
            Go from cost-center to profit-center in just minutes! Earn{' '}
            <span className={styles.rupeeSym}>&#8377;&#8377;&#8377;</span> for every request
          </span>
        </div>
        <div className={styles.stepContainer}>
          {hrmsStep.map((data, index) => (
            <>
              <div className={styles.stepBox}>
                <span className={styles.stepText} style={{ width: data.textWidth }}>
                  {data.text}
                </span>
                <Image
                  className={[1, 2].includes(index) ? ` ${styles.marginTop25}` : styles.stepIcon}
                  height={data.height}
                  name={data.image}
                  testId={`image-${data.image}`}
                />
              </div>
              {[0, 1].includes(index) && (
                <div className={styles.lineContainer}>
                  <Image className={styles.text} height='100%' name='arrow-left.svg' />
                </div>
              )}
            </>
          ))}
        </div>
        {/* <div className={styles.footer}>
          <Button onClick={onClickRemindLater} className={styles.remindLaterBtn}>
            Remind later
          </Button>
          <Button onClick={onClickSetup} className={styles.setupBtn}>
            Setup
          </Button>
        </div> */}
      </div>
    </Card>
  );
};

export default withRouter(AutoPilot);
