// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fRG2Ornhk0HNuxDgCoMo {
  padding-top: 0.5rem;
}

.kv3QxUVB5ajmLLlxLHrw {
  margin: 40px 0px;
}

.aPo2reGyLqEUWWz2X4x8 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.uWYvizD4DvWSkuu0e7st {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.KbLjodYKbwxxcdDWhApR {
  font-family: Poppins, sans-serif;
  background-color: #ffffff;
  color: #2755fe;
  border: 1px solid #2755fe;
}

.kdy6YW9h7UVRT6Rk0BZk {
  background-color: #037bff;
  color: white;
  padding: 0.5rem;
  border: none;
}

.F5bOOkVV7iTrkPmlqU_f {
  background-color: #1bae9f;
  color: white;
  padding: 0.5rem;
  border: none;
}

.kdy6YW9h7UVRT6Rk0BZk:hover {
  background-color: #076dda;
}

.F5bOOkVV7iTrkPmlqU_f:hover {
  background-color: #11a294;
}

.cTO4T59y3LMdP5OzU_xB {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}

.Wd8RSM7K7pzkwlbtlQmK {
  border: 1px solid red;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/Integrations/Sprinto/InputArea/InputArea.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,eAAA;EACA,WAAA;EACA,mBAAA;AAGF;;AADA;EACE,aAAA;EACA,2BAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AAIF;;AAFA;EACE,gCAAA;EACA,yBAAA;EACA,cAAA;EACA,yBAAA;AAKF;;AAFA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAKF;;AAHA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AAMF;;AAJA;EACE,yBAAA;AAOF;;AALA;EACE,yBAAA;AAQF;;AALA;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAQF;;AANA;EACE,qBAAA;AASF","sourcesContent":[".inputLabel {\n  padding-top: 0.5rem;\n}\n.generateTokenButton {\n  margin: 40px 0px;\n}\n.generateTokenButton__heading {\n  font-size: 16px;\n  color: #333;\n  margin-bottom: 10px;\n}\n.step1FooterContainer {\n  display: flex;\n  justify-content: flex-start;\n  gap: 1rem;\n  margin-top: 1rem;\n  margin-bottom: 2rem;\n}\n.goBackBtn {\n  font-family: Poppins, sans-serif;\n  background-color: #ffffff;\n  color: #2755fe;\n  border: 1px solid #2755fe;\n}\n\n.connectButton {\n  background-color: #037bff;\n  color: white;\n  padding: 0.5rem;\n  border: none;\n}\n.connectedButton {\n  background-color: #1bae9f;\n  color: white;\n  padding: 0.5rem;\n  border: none;\n}\n.connectButton:hover {\n  background-color: #076dda;\n}\n.connectedButton:hover {\n  background-color: #11a294;\n}\n\n.listItems {\n  font-family: 'Poppins';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: #333333;\n}\n.removeButton {\n  border: 1px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `fRG2Ornhk0HNuxDgCoMo`,
	"generateTokenButton": `kv3QxUVB5ajmLLlxLHrw`,
	"generateTokenButton__heading": `aPo2reGyLqEUWWz2X4x8`,
	"step1FooterContainer": `uWYvizD4DvWSkuu0e7st`,
	"goBackBtn": `KbLjodYKbwxxcdDWhApR`,
	"connectButton": `kdy6YW9h7UVRT6Rk0BZk`,
	"connectedButton": `F5bOOkVV7iTrkPmlqU_f`,
	"listItems": `cTO4T59y3LMdP5OzU_xB`,
	"removeButton": `Wd8RSM7K7pzkwlbtlQmK`
};
export default ___CSS_LOADER_EXPORT___;
