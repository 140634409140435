import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { fileUpload as fileUploadAPI } from '../../api/company';
import { errToastMessage, getErrorMessage } from '../../utils/utilities';
import { fileTypes, validDocTypes } from '../../utils';
import AppConstants from '../../core-components/AppConstants';
import localStorage from '../../api/localStorage';

import styles from './ValidatedFileSelect.module.scss';

export default (props) => {
  const { field, form, candidateId, fileValue } = props;
  const [fileName, setFileName] = useState(fileValue ? fileValue.name : '');
  const [showError, setShowError] = useState(false);
  const [showConsentError, setConsentShowError] = useState(false);
  const handleChange = (e) => {
    if (e?.currentTarget?.files.length) {
      setFileName(e?.currentTarget?.files[0]?.name);
      const file = e?.currentTarget?.files[0];
      const reader = new FileReader();

      if (props.uploadToServer && props.uploadToServer === 's3Upload') {
        props.setIsUploading(true);
        let request = {
          candidateId: candidateId,
          file: file,
          fileType: fileTypes[props?.field?.name]
        };
        if (props?.fetchResumeDetails) {
          request = {
            ...request,
            fetchResumeDetails: true
          };
        }
        fileUploadAPI(request)
          .then((response) => {
            form.setFieldValue(field.name, file);
            form.setFieldValue(props.files, file);
            form.setFieldValue(props.linkFieldName, response.data.data);
            if (
              props?.fetchResumeDetails &&
              Object.keys(response?.data?.resumeDetails)?.length > 0
            ) {
              form.setFieldValue('resumeDetails', response?.data?.resumeDetails);
            }
            props.setIsUploading(false);
            if (props?.field?.name === 'resume') {
              setShowError(false);
              props.handleErrorCallback(false);
            }
            if (props?.field?.name === 'consent') {
              setConsentShowError(false);
              props.handleErrorCallback(false);
            }
          })
          .catch((error) => {
            form.setFieldValue(field.name, '');
            if (getErrorMessage(error).includes('Invalid')) {
              if (props?.field?.name === 'resume') {
                setShowError(true);
                props.handleErrorCallback(true);
              }
              if (props?.field?.name === 'consent') {
                setConsentShowError(true);
                props.handleErrorCallback(true);
              }
            } else {
              errToastMessage(error);
            }
          });
      } else {
        reader.readAsDataURL(file);
        form.setFieldValue(field.name, file);
      }
    }
  };

  return (
    <>
      {!props.defaultField ? (
        <div className={styles.fileInput}>
          <label className={styles.customFileUpload}>
            <input
              accept={
                props?.field?.name === 'consent' ? validDocTypes.consent : validDocTypes.resume
              }
              type='file'
              onChange={handleChange}
            />
            Browse
          </label>
          {/* passing showlabel = true as prop from add bulk candidate file only*/}
          {props?.showLabel ? (
            <div className={styles.fileName}>{fileName ? fileName : ''}</div>
          ) : (
            <div style={{ display: 'flex' }} className={styles.fileName}>
              <div style={{ width: '75%' }}> {fileName ? fileName : 'Upload File'}</div>
              {props.resumeProps ? (
                <div style={{ width: '25%' }}>
                  <div className={styles.resumeLink}>
                    <a
                      href={
                        props.resumeProps?.resumeFormat === 'DOCX'
                          ? `${AppConstants.developmentAPIURL}auth/docs?company_id=${
                              props?.resumeProps?.companyId
                            }&candidate_id=${
                              props?.candidateId
                            }&type=resume&token=${localStorage.getToken()}`
                          : `${AppConstants.baseURL}candidate/resume?candidate_id=${props?.candidateId}`
                      }
                      target='_blank'
                    >
                      {`View Resume (${props.resumeProps?.resumeFormat})`}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.consentDocFile}>
          <input
            type='file'
            accept={props?.field?.name === 'consent' ? validDocTypes.consent : validDocTypes.resume}
            onChange={handleChange}
            style={{
              display: 'block',
              boxSizing: 'border-box',
              width: '100%'
            }}
          />
        </div>
      )}
      {showError && (
        <div className={styles.errorMsg}>Please provide a valid resume format(.pdf,.doc,.docx)</div>
      )}
      {showConsentError && (
        <div className={styles.errorMsg}>
          Please provide a valid consent format (.pdf, .doc, .docx, .png, .jpeg, .jpg)
        </div>
      )}
    </>
  );
};
