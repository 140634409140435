import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { mergeWith } from 'lodash';

import validationSchema from './CreateSubtypeFriendlyName.validation';
import { getErrorMessage } from '../../../core-components/Utilities/utilities';
import { createFriendlyName } from '../../../../../../api/company';
import { errToastMessage } from '../../../utils/Utilities';
const mapStateToProps = (state) => {
  return {
    companyId: state?.profile?.profile?.company_user_mapping?.company_id || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let initialValue = {
        friendlyName: props?.subtypeFriendlyName || '',
        errorForfriendlyName: { error: false, errorMsg: '' } // varaible for checking BE validations for frienldyName
      };
      return mergeWith({}, initialValue, props?.category, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      const companyId = new URLSearchParams(document.location.search).get('company_id');
      const finalPayloads = {
        subtypeId: props?.subTypeId,
        packageId: props?.packageId,
        friendlyName: values?.friendlyName,
        companyId: props?.companyId || companyId
      };
      //update response when api is ready
      createFriendlyName(finalPayloads)
        .then((response) => {
          props.setFriendlyName(values.friendlyName);
          props.onHide();
        })
        .catch((error) => {
          console.log(error);
          setFieldValue('errorForfriendlyName', { error: false, errorMsg: '' });
          setSubmitting(false);
          errToastMessage(error);
        });
    },
    displayName: 'Add friendly name'
  })
);
