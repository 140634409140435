// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VYoH7jlphetp1FDV1q7g {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.VYoH7jlphetp1FDV1q7g .ruvWXisSveOZc0Zgq_l7 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
}
.VYoH7jlphetp1FDV1q7g .hXasa1YlMX3mDxK0dL2h {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}
.VYoH7jlphetp1FDV1q7g .eIO_0_QELTLGn5CCw_SP {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  margin-top: 50px;
  align-items: center;
  gap: 15px;
  padding: 10px 15px;
  background-color: antiquewhite;
  border-radius: 10px;
}
.VYoH7jlphetp1FDV1q7g .eIO_0_QELTLGn5CCw_SP img {
  cursor: pointer;
}
.VYoH7jlphetp1FDV1q7g .yg12GkrHplaEyJk_98GZ {
  display: flex;
  gap: 28px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/BulkUploader/Finish/Finish.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAEI;EACE,eAAA;AAAN;AAIE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AAFJ","sourcesContent":[".finishContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  .finishHeading {\n    font-size: 24px;\n    font-weight: 600;\n    color: #000000;\n    margin-bottom: 20px;\n  }\n\n  .finishText {\n    font-size: 20px;\n    font-weight: 400;\n    color: #000000;\n    margin-bottom: 20px;\n  }\n\n  .downloadBtnContainer {\n    font-size: 18px;\n    font-weight: 400;\n    display: flex;\n    margin-top: 50px;\n    align-items: center;\n    gap: 15px;\n    padding: 10px 15px;\n    background-color: antiquewhite;\n    border-radius: 10px;\n    img {\n      cursor: pointer;\n    }\n  }\n\n  .go_back {\n    display: flex;\n    gap: 28px;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finishContainer": `VYoH7jlphetp1FDV1q7g`,
	"finishHeading": `ruvWXisSveOZc0Zgq_l7`,
	"finishText": `hXasa1YlMX3mDxK0dL2h`,
	"downloadBtnContainer": `eIO_0_QELTLGn5CCw_SP`,
	"go_back": `yg12GkrHplaEyJk_98GZ`
};
export default ___CSS_LOADER_EXPORT___;
