// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wlXhiK79lvC2SnhCF0gq {
  padding-inline: 25px !important;
}

.ZeTq27LwYz5GDk96j_xT {
  display: flex;
}
.ZeTq27LwYz5GDk96j_xT .L2DyLBBipfibsLN2Xrla {
  font-size: 12px;
  color: #9b9a9a;
  margin-right: 55px;
  margin-top: 8px;
}

.h7uOK6bjaRpL3nzUEPDb {
  padding: 0px 90px;
}

.PV_Pbr4gwd5m0_DrsDwl {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  margin-right: 8px;
}

.Ue0kLCOImeDDFNjU8qWo {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
}

.KBELYFvrSMkVtA8hQFmG {
  margin-left: -5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.oe_k4sKBurQFr6nmPRNq {
  font-size: 10.9px;
  margin-top: -20px;
  margin-bottom: 15px;
  color: red;
}

.xLA02vPukSj2Dpm1wyKW {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 106px;
  margin-right: 0px;
}
.xLA02vPukSj2Dpm1wyKW:hover {
  background: #2755fe;
  color: #ffffff;
}

.u0adP1rgBnjkxP_mDC51 {
  background: #f5f5f5;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 106px;
  margin-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/UtilsComponents/EditEmail/EditEmail.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;AACF;;AAEA;EACE,aAAA;AACF;AAAE;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AAEJ;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AACF;;AACA;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;EAEA,UAAA;AAEF;;AAAA;EACE,mBAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;AAGF;AAFE;EACE,mBAAA;EACA,cAAA;AAIJ;;AADA;EACE,mBAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;AAIF","sourcesContent":[".paddingFix {\n  padding-inline: 25px !important;\n}\n\n.footer {\n  display: flex;\n  .footerLabel {\n    font-size: 12px;\n    color: #9b9a9a;\n    margin-right: 55px;\n    margin-top: 8px;\n  }\n}\n\n.content {\n  padding: 0px 90px;\n}\n\n.title {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n  margin-right: 8px;\n}\n\n.labelHeading {\n  font-weight: 600;\n  font-size: 17px;\n  line-height: 24px;\n}\n.titleHeading {\n  margin-left: -5px;\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 24px;\n}\n.errorMsg {\n  font-size: 10.9px;\n  margin-top: -20px;\n  margin-bottom: 15px;\n\n  color: red;\n}\n.submitButton {\n  background: #2755fe;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: #ffffff;\n  width: 106px;\n  margin-right: 0px;\n  &:hover {\n    background: #2755fe;\n    color: #ffffff;\n  }\n}\n.disableBtn {\n  background: #f5f5f5;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: #ffffff;\n  width: 106px;\n  margin-right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingFix": `wlXhiK79lvC2SnhCF0gq`,
	"footer": `ZeTq27LwYz5GDk96j_xT`,
	"footerLabel": `L2DyLBBipfibsLN2Xrla`,
	"content": `h7uOK6bjaRpL3nzUEPDb`,
	"title": `PV_Pbr4gwd5m0_DrsDwl`,
	"labelHeading": `Ue0kLCOImeDDFNjU8qWo`,
	"titleHeading": `KBELYFvrSMkVtA8hQFmG`,
	"errorMsg": `oe_k4sKBurQFr6nmPRNq`,
	"submitButton": `xLA02vPukSj2Dpm1wyKW`,
	"disableBtn": `u0adP1rgBnjkxP_mDC51`
};
export default ___CSS_LOADER_EXPORT___;
