import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Button from '../../../../core-components/Button';
import Image from '../../../../core-components/Image';

import styles from './AdminModals.module.scss';
import AppConstants from '../../../../core-components/AppConstants';

export default (props) => {
  const { handleSubmit, isSubmitting, addAnotherCandidateHandler, banners = [], path = '' } = props;
  const isShowBuyRentLaptop = banners.includes('upsellLaptop') ? true : false;
  console.log({ path });

  return (
    <Modal
      size={'md'}
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className={styles.modalBody}>
          <Image
            name={
              props?.content?.includes('Admin') ? 'GreenTickModal.svg' : 'greenWhiteTickModal.svg'
            }
          />
          <div
            className={path === 'candidateDetailsPage' ? styles.bgvLinkSentText : styles.content}
          >
            {props?.content}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <div className={styles.buttonFooter}>
          <Button className={`${styles.okayButton}`} onClick={() => props?.onHide()}>
            {'Okay'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
