import AppHelper from './AppHelper';

let localConfig = require('../config/local.json');
let devConfig = require('../config/dev.json');
let dev2Config = require('../config/dev-2.json');
let dev3Config = require('../config/dev-3.json');
let prodConfig = require('../config/prod.json');
let acceptanceConfig = require('../config/acceptance.json');
let acceptanceConfig2 = require('../config/acceptance2.json');

let appVar = AppHelper.isLocallyServed()
  ? localConfig
  : AppHelper.isDev2()
  ? dev2Config
  : AppHelper.isDev3()
  ? dev3Config
  : AppHelper.isAcceptance()
  ? acceptanceConfig
  : AppHelper.isAcceptance2()
  ? acceptanceConfig2
  : process.env.NODE_ENV === 'development'
  ? devConfig
  : prodConfig;

const AppConstants = { ...appVar };

export default AppConstants;
