import React from 'react';
import { Router } from 'react-router-dom';

import ContentArea from '../ContentArea';
import history from '../../utils/history';

export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const svCode = urlParams.get('sv_code');
  if (svCode) {
    try {
      const token = window.atob(svCode);
      localStorage.setItem('token', token);
      const url = new URL(window.location.href);
      url.searchParams.delete('sv_code');
      window.history.replaceState({}, document.title, url.toString());
    } catch (err) {
      console.error('SV Code Error:::', err);
    }
  }

  return (
    <React.Fragment>
      <Router history={history}>
        <ContentArea />
      </Router>
    </React.Fragment>
  );
};
