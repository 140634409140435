import React, { useEffect, useState } from 'react';
import RecordsOverview from './RecordsOverview/RecordsOverview';
import RequestOverview from './RequestOverview/RequestOverview';

const Overview = (props) => {
  const [recordOverview, setRecordOverview] = useState([]);
  const { alumniRecordOverview, getRecordOverview } = props;

  useEffect(() => {
    getRecordOverview();
  }, []);

  useEffect(() => {
    if (alumniRecordOverview?.data) {
      setRecordOverview(alumniRecordOverview?.data);
    }
  }, [alumniRecordOverview]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <RequestOverview {...props} />
      <RecordsOverview data={recordOverview} />
    </div>
  );
};

export default Overview;
