// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VyVaRVb23yvA20Imuv_n {
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.5);
}

.A1Xo84B5crPEpeaJ5NYf {
  color: rgba(0, 0, 0, 0.5);
}

.BrKHOsBLKlIPBx71wMna {
  font-size: 16px;
}

.iz7tP7G0990zGyeFEJYm {
  border: solid 1px;
}

.Yzls3HmMRtOVoRB7Mg37 {
  display: flex;
  gap: 6px;
  margin-left: 460px;
}

.jDGvqNnW_5Jp8IucD3uQ {
  color: #fff;
  border-radius: 2px;
  min-width: 90px;
  background-color: #2755fe;
  border-color: #2755fe;
  cursor: pointer;
}
.jDGvqNnW_5Jp8IucD3uQ:hover {
  background-color: #1d41c5;
  border-color: #1d41c5;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/Integrations/GreenHouse/Components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,oCAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,eAAA;AAGF;;AADA;EACE,iBAAA;AAIF;;AAFA;EACE,aAAA;EACA,QAAA;EACA,kBAAA;AAKF;;AAHA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AAMF;AALE;EACE,yBAAA;EACA,qBAAA;AAOJ","sourcesContent":[".backdrop {\n  opacity: 0.6;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n.note {\n  color: rgba(0, 0, 0, 0.5);\n}\n.createMannuallyconfirmText {\n  font-size: 16px;\n}\n.btnOutline {\n  border: solid 1px;\n}\n.btnContainer {\n  display: flex;\n  gap: 6px;\n  margin-left: 460px;\n}\n.onCreateAutomaticallyBtn {\n  color: #fff;\n  border-radius: 2px;\n  min-width: 90px;\n  background-color: #2755fe;\n  border-color: #2755fe;\n  cursor: pointer;\n  &:hover {\n    background-color: #1d41c5;\n    border-color: #1d41c5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `VyVaRVb23yvA20Imuv_n`,
	"note": `A1Xo84B5crPEpeaJ5NYf`,
	"createMannuallyconfirmText": `BrKHOsBLKlIPBx71wMna`,
	"btnOutline": `iz7tP7G0990zGyeFEJYm`,
	"btnContainer": `Yzls3HmMRtOVoRB7Mg37`,
	"onCreateAutomaticallyBtn": `jDGvqNnW_5Jp8IucD3uQ`
};
export default ___CSS_LOADER_EXPORT___;
