import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import styles from '../ChargesApproval.module.scss';

export default function RechargeModal(props) {
  const getModalHeading = () => {
    // if this variable approveSingleADC.approve is true , it means we are approving single ADC
    return props?.approveSingleADC?.approve
      ? 'Approve Additional Cost?'
      : 'Approve All Additional Costs?';
  };

  const getModalMessage = () => {
    // if this variable approveSingleADC.approve is true , it means we are approving single ADC
    return props?.approveSingleADC?.approve
      ? 'This action will approve the additional cost for approval and the required amount will be deducted.'
      : 'This action will approve all the additional costs pending for approval and the required funds will be deducted.';
  };

  return (
    <div>
      <Modal
        size='lg'
        onHide={props?.hiddeApproveAllModal}
        show={props?.showApproveModal}
        dialogClassName='educationModal adcApproveModal'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <span className={styles.modalBodyHeader}>{getModalHeading()}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.addEditEducationModalBody}>
          <div>
            <p className={styles.modalBodyText}>{getModalMessage()}</p>
          </div>
        </Modal.Body>

        <div className={styles.modalFooter}>
          <div className={styles.modalFooterText}></div>
          <div>
            <Button
              className={`${styles.modalButton} ${styles.cancleButton}`}
              variant='outline-primary'
              onClick={props?.hiddeApproveAllModal}
            >
              CANCEL
            </Button>
            <Button
              className={`btn btn-success ml-2 ${styles.approveBtn} ${styles.modalButton} `}
              type='button'
              onClick={
                /* if this variable approveSingleADC.approve  is true , it means we are approving single ADC */
                props?.approveSingleADC?.approve
                  ? props?.approveSingleAdcHandler
                  : props?.handleApproveAll
              }
            >
              <div>
                {/* if this variable approveSingleADC.approve is true , it means we are approving single ADC */}
                <span> {props?.approveSingleADC?.approve ? 'Approve' : 'Approve All'}</span>
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
