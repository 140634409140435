import { UPLOAD_STATE } from '../../frontend-common/core-components/Utilities/CommonConstants';
import Image from '../../frontend-common/core-components/Image/Image';
import { Card } from 'react-bootstrap';
import styles from './FileImporter.module.scss';
import React from 'react';

const getIconName = (uploadState) => {
  switch (uploadState) {
    case UPLOAD_STATE.UPLOADING:
      return 'loader-black.svg';
    case UPLOAD_STATE.UPLOADED:
      return 'upload-success-green.svg';
    case UPLOAD_STATE.NETWORK_ERROR:
    case UPLOAD_STATE.FILE_TOO_LARGE:
    case UPLOAD_STATE.FILE_ERROR:
    case UPLOAD_STATE.UPLOAD_FAILED:
      return 'upload-failed-red.svg';
    default:
      return 'upload-grey.svg';
  }
};

const getFileUploadHeaderText = (uploadState) => {
  switch (uploadState) {
    case UPLOAD_STATE.UPLOADING:
      return 'Uploading and analyzing resume...';
    case UPLOAD_STATE.UPLOADED:
      return 'File Uploaded';
    case UPLOAD_STATE.NETWORK_ERROR:
      return 'Network Error!';
    case UPLOAD_STATE.FILE_TOO_LARGE:
      return 'File too large';
    case UPLOAD_STATE.FILE_ERROR:
      return 'File not supported';
    case UPLOAD_STATE.UPLOAD_FAILED:
      return 'Upload failed';
    default:
      return '';
  }
};

const FileNameCard = ({ fileName, onClose, onDelete }) => {
  return (
    <Card className={styles.fileNameCard}>
      <div className={styles.body}>
        <Image name='csv.svg' />
        <div className={styles.fileNameText}>{fileName}</div>
        <div onClick={onDelete}>
          <Image name='close-grey.svg' />
        </div>
      </div>
    </Card>
  );
};

const FileInfoComponent = ({
  uploadState,
  fileName = 'File Name not found',
  onDeleteUploadedFile,
  onTryAgainHandler,
  fileErrorMessage
}) => {
  switch (uploadState) {
    case UPLOAD_STATE.NOT_UPLOADED:
    case UPLOAD_STATE.UPLOADING:
      return (
        <div className={styles.fileInfoContainer}>
          {/* <div className={styles.fileInfoText}>File size 1 MB max</div>
          <div className={styles.fileInfoText}>File types (.csv/ .xls/ .xlsx)</div> */}
        </div>
      );
    case UPLOAD_STATE.UPLOADED:
      return <FileNameCard fileName={fileName} onDelete={onDeleteUploadedFile} />;
    case UPLOAD_STATE.NETWORK_ERROR:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.errorInfoText}>{fileErrorMessage}</div>
          <div onClick={onTryAgainHandler} className={styles.tryAgainText}>
            Try again
          </div>
        </div>
      );
    case UPLOAD_STATE.FILE_TOO_LARGE:
    case UPLOAD_STATE.FILE_ERROR:
    case UPLOAD_STATE.UPLOAD_FAILED:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.errorInfoText}>{fileErrorMessage}</div>
          <div onClick={onTryAgainHandler} className={styles.tryAgainText}>
            Try again
          </div>
        </div>
      );
  }
};

export { getIconName, getFileUploadHeaderText, FileInfoComponent, UPLOAD_STATE };
