import React, { useState, useEffect } from 'react';

import styles from '../Integrations.module.scss';
import { Container, Row } from 'react-bootstrap';

import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';
import InputArea from './InputArea';

import { sprintoDetails } from '../Utils/constants';

export default function Sprinto(props) {
  const [integrationData, setIntegrationData] = useState({});
  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);
  }, [props?.integrationData]);

  return (
    <IntegrationLayout>
      <div className={styles.parentDivTopTextArea}>
        <TopTextArea title={'Sprinto'} icon={'sprinto.png'} />
        <Container>
          <div className='mt-5'>
            {sprintoDetails.map((step, index) => {
              return (
                <Row>
                  <h6>
                    <span className='font-weight-bold'>Step {index + 1}:</span> {step}
                  </h6>
                </Row>
              );
            })}
          </div>
        </Container>
        <InputArea history={props.history} />
      </div>
    </IntegrationLayout>
  );
}
