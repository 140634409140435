import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

import { errToastMessage, getErrorMessage } from '../../../utils/utilities';
import { exportCandidates } from '../../../api/company';
import { getCompanyPaymentDetails } from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {};
      return mergeWith(
        {
          dateRange: 'all',
          startDate: null,
          endDate: null,
          isFailure: '',
          isSuccess: false,
          isValid: true,
          isValidDates: true,
          document: null,
          message: ''
        },
        storedValues,
        (a, b) => (b === null ? a : b)
      );
    },
    enableReinitialize: true,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, setFieldValue, resetForm, props }
    ) => {
      if (values.dateRange === 'custom' && (values.startDate == null || values.endDate == null)) {
        values.isValid = false;
        setSubmitting(false);
      } else {
        values.isValid = true;
      }

      setFieldValue('isSuccess', false);
      setFieldValue('isFailure', '');
      setFieldValue('document', null);

      let requestData = {
        days: !['custom', 'all'].includes(values.dateRange) ? values.dateRange : null,
        startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null,
        endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null
      };

      if (values.dateRange !== 'custom') {
        requestData.startDate = null;
        requestData.endDate = null;
      }
      if (values.isValid) {
        exportCandidates(requestData)
          .then((response) => {
            setSubmitting(false);
            if (response?.data?.data?.downloadLink) {
              let documentLink = response?.data?.data?.downloadLink;
              setFieldValue('isSuccess', true);
              setFieldValue('isFailure', false);
              setFieldValue('document', documentLink);
              setFieldValue('message', response?.data?.message || 'Export successful');
              window.open(documentLink);
            } else {
              setFieldValue('isSuccess', false);
              setFieldValue('isFailure', true);
              setFieldValue('message', response?.data?.message || 'Export Failed');
            }
          })
          .catch((error) => {
            errToastMessage(error);
            setSubmitting(false);
          });
      }
    },
    displayName: 'Export Cases'
  })
);
