import React, { useEffect, useState } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import styles from './StepOne.module.scss';
import cn from 'classnames';
import Image from '../../core-components/Image/Image';
import moment from 'moment';
import {
  ADMIN_URL,
  UPLOAD_STATE,
  SAMPLE_FILE_ALUMNI
} from './../../core-components/Utilities/CommonConstants';
import { validateBulkUploadFile } from '../../../../../api/company';
import { bulkAccept } from './../../core-components/Utilities/utilities';
import AppConstants from '../../../../../core-components/AppConstants';

import { useDropzone } from 'react-dropzone';
const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));

const getIconName = (uploadState) => {
  switch (uploadState) {
    case UPLOAD_STATE.UPLOADING:
      return 'loader-black.svg';
    case UPLOAD_STATE.UPLOADED:
      return 'upload-success-green.svg';
    case UPLOAD_STATE.NETWORK_ERROR:
    case UPLOAD_STATE.FILE_TOO_LARGE:
    case UPLOAD_STATE.FILE_ERROR:
    case UPLOAD_STATE.UPLOAD_FAILED:
      return 'upload-failed-red.svg';
    default:
      return 'upload-grey.svg';
  }
};

const getFileUploadHeaderText = (uploadState) => {
  switch (uploadState) {
    case UPLOAD_STATE.UPLOADING:
      return 'Uploading...';
    case UPLOAD_STATE.UPLOADED:
      return 'File Uploaded';
    case UPLOAD_STATE.NETWORK_ERROR:
      return 'Network Error!';
    case UPLOAD_STATE.FILE_TOO_LARGE:
      return 'File too large';
    case UPLOAD_STATE.FILE_ERROR:
      return 'File not supported';
    case UPLOAD_STATE.UPLOAD_FAILED:
      return 'Upload failed';
    default:
      return '';
  }
};

const FileNameCard = ({ fileName, onClose, onDelete }) => {
  return (
    <Card className={styles.fileNameCard}>
      <div className={styles.body}>
        <Image name='csv.svg' />
        <div className={styles.fileNameText}>{fileName}</div>
        <div onClick={onDelete}>
          <Image name='close-grey.svg' />
        </div>
      </div>
    </Card>
  );
};

const FileInfoComponent = ({
  uploadState,
  fileName = 'File Name not found',
  onDeleteUploadedFile,
  onTryAgainHandler,
  fileErrorMessage
}) => {
  switch (uploadState) {
    case UPLOAD_STATE.NOT_UPLOADED:
    case UPLOAD_STATE.UPLOADING:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.fileInfoText}>File size 1 MB max</div>
          <div className={styles.fileInfoText}>File types (.csv/ .xls/ .xlsx)</div>
        </div>
      );
    case UPLOAD_STATE.UPLOADED:
      return <FileNameCard fileName={fileName} onDelete={onDeleteUploadedFile} />;
    case UPLOAD_STATE.NETWORK_ERROR:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.errorInfoText}>{fileErrorMessage}</div>
          <div onClick={onTryAgainHandler} className={styles.tryAgainText}>
            Try again
          </div>
        </div>
      );
    case UPLOAD_STATE.FILE_TOO_LARGE:
    case UPLOAD_STATE.FILE_ERROR:
    case UPLOAD_STATE.UPLOAD_FAILED:
      return (
        <div className={styles.fileInfoContainer}>
          <div className={styles.errorInfoText}>{fileErrorMessage}</div>
          <div onClick={onTryAgainHandler} className={styles.tryAgainText}>
            Try again
          </div>
        </div>
      );
  }
};

const StepOne = (props) => {
  const [uploadState, setUploadState] = useState(UPLOAD_STATE.NOT_UPLOADED);
  const [file, setFile] = useState(null);
  const [iconName, setIconName] = useState('upload-grey.svg');
  const [alumniRecordSheetData, setAlumniRecordSheetData] = useState({ column: [], data: [] });
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const companyId = new URLSearchParams(document.location.search).get('id');

  /**
   * UseEffects
   */

  useEffect(() => {
    setIconName(getIconName(uploadState));
  }, [uploadState]);

  /**
   * Handlers
   */

  const onSubmitHandler = async () => {
    try {
      const response = await props.bulkUploadExEmp(alumniRecordSheetData);
    } catch (error) {
      console.error(error);
    }
    props.onClose(false);
    //TODO: Go to the Step 2 of bulk upload
  };

  const onDeleteUploadedFileHandler = () => {
    setFile(null);
    setAlumniRecordSheetData({ column: [], data: [] });
    setUploadState(UPLOAD_STATE.NOT_UPLOADED);
  };

  // Helper function to format date as "YYYY-MM-DD"
  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const uploadedFile = acceptedFiles[0];
        setFile(uploadedFile);
        if ((uploadedFile.size / (1024 * 1024)).toFixed(2) > 1) {
          setUploadState(UPLOAD_STATE.FILE_TOO_LARGE);
          setFileErrorMessage(
            `Your file ${uploadedFile.name} was not uploaded due to large file size`
          );
          return;
        }
        let column, data;
        let formData = new FormData();
        formData.append('bulk_upload_file', uploadedFile);
        formData.append('file_type', 'ALUMNI_BULK_UPLOAD');
        if (isSA) {
          formData.append('company_id', companyId);
        }
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          try {
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const rowData = XLSX.utils.sheet_to_json(ws, { header: 1 });
            // Filter out empty rows
            const cleanedData = rowData.filter((row) =>
              row.some((cell) => cell !== null && cell !== '' && cell)
            );

            /**
             * Format dates
             * If some cell has date values then the above logic is converting it to a date object
             * which is later causing issues in the API, so we need to convert it to a string to retain
             * original value.
             */
            const formattedData = cleanedData.map((row) =>
              row.map((cell) => (cell instanceof Date ? formatDate(cell) : cell))
            );

            column = formattedData[0];
            data = formattedData.slice(1);
            setUploadState(UPLOAD_STATE.UPLOADING);
            validateBulkUploadFile(formData)
              .then((response) => {
                if (response?.data?.data?.errors?.length > 0) {
                  const error = new Error();
                  error.response = response;
                  throw error;
                }
                setFile(uploadedFile);
                setUploadState(UPLOAD_STATE.UPLOADED);
                setAlumniRecordSheetData({ column: column, data: data });
                toast.success(response.data.message);
              })
              .catch((error) => {
                setFileErrorMessage(
                  error.response?.data?.data?.errors?.length > 0
                    ? error.response?.data?.data?.errors[0]
                    : error.response?.data?.message
                );
                setAlumniRecordSheetData({ column: [], data: [] });
                setFile(null);
                if (error?.response?.status !== 200) {
                  setUploadState(UPLOAD_STATE.NETWORK_ERROR);
                } else {
                  setUploadState(UPLOAD_STATE.UPLOAD_FAILED);
                }
              });
          } catch (error) {
            setUploadState(UPLOAD_STATE.FILE_ERROR);
            setFileErrorMessage('File type must be (.csv/ .xls/ .xlsx)');
          }
        };
        if (rABS) reader.readAsBinaryString(uploadedFile);
        else reader.readAsArrayBuffer(uploadedFile);
      }
    }
  });
  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadState(UPLOAD_STATE.FILE_ERROR);
      setFileErrorMessage('File type must be (.csv/ .xls/ .xlsx)');
    }
  }, [fileRejections]);
  const onTryAgainHandler = () => {
    setUploadState(UPLOAD_STATE.NOT_UPLOADED);
    setAlumniRecordSheetData({ column: [], data: [] });
    setFile(null);
  };

  return (
    <div>
      <Modal size='lg' show={props?.show} centered onHide={() => props.onClose(true)}>
        <Modal.Header closeButton className={isSA ? styles.customHeader : ''}>
          <Modal.Title className={styles.headerTitle}>Upload your Alumni data manually</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.rowOne}>
            {uploadState === UPLOAD_STATE.NOT_UPLOADED ? (
              <div {...getRootProps()} className={styles.uploadFileContainer}>
                <Image name={iconName} />
                <div className={cn(styles.titleText, styles.upload)}>
                  <span className={styles.containerNotUploadedInfoText}>
                    {`Drag and drop files or `}
                    <span className={styles.browseText}>browse</span> {`them`}
                  </span>
                </div>
                <input {...getInputProps()} style={{ display: 'none' }} accept={bulkAccept} />
                <FileInfoComponent
                  uploadState={uploadState}
                  onDeleteUploadedFile={onDeleteUploadedFileHandler}
                  fileName={file ? file?.name : 'File Name not found'}
                  onTryAgainHandler={onTryAgainHandler}
                  fileErrorMessage={fileErrorMessage}
                />
              </div>
            ) : (
              <div className={styles.uploadFileContainer}>
                <Image name={iconName} />
                <div className={cn(styles.titleText, styles.uploaded)}>
                  <span>{getFileUploadHeaderText(uploadState)}</span>
                </div>
                <FileInfoComponent
                  uploadState={uploadState}
                  onDeleteUploadedFile={onDeleteUploadedFileHandler}
                  fileName={file ? file?.name : 'File Name not found'}
                  onTryAgainHandler={onTryAgainHandler}
                  fileErrorMessage={fileErrorMessage}
                />
              </div>
            )}
          </div>
          <div>
            <ul className={styles.listBody}>
              <li className={styles.listText}>
                Download sample file{' '}
                <a href={SAMPLE_FILE_ALUMNI} target='_blank' className={styles.activeLinkText}>
                  Download <Image name='download-blue.svg' />
                </a>
              </li>
              <li className={styles.listText}>Add your data in the same format as sample file</li>
              <li className={styles.listText}>Only (.csv/ .xls/ .xlsx) files accepted</li>
              <li className={styles.listText}>Make sure your file is max of 1 MB</li>
              <li className={styles.listText}>
                format for dates should be <b>YYYY-MM-DD</b>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button
            onClick={() => props.onClose(true)}
            variant='outline-primary'
            className={styles.cancelButton}
          >
            Go Back
          </Button>
          <Button
            onClick={onSubmitHandler}
            disabled={uploadState !== UPLOAD_STATE.UPLOADED}
            variant='primary'
            className={styles.saveButton}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StepOne;
