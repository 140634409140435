// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uddtElo0XNenR7ijIiyQ {
  margin-bottom: 10px;
}

.lyW2m7VvozLfjPLc9qUR {
  border: none;
  padding: 0;
  display: flex;
  align-items: baseline;
  background-color: inherit;
}

.SvJ5TU4bvRe_ITSWLHBg svg {
  margin-right: 8px;
  height: 14px;
  width: 14px;
}
.SvJ5TU4bvRe_ITSWLHBg svg path {
  fill: #2755fe;
}

.twiGz92mCIFPZQnzBRdT {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.RbcSIUqgiIv8ndfNmjAj {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}

.V4zCOx7gQfDBCvkwE37w {
  display: flex;
  align-items: center;
}

.wp1RbOcXTojHY9fMD_be svg {
  margin-right: 8px;
  height: 14px;
  width: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ViewBuyPackage/PackageListing/ChecksIncluded.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AACA;EACE,YAAA;EACA,UAAA;EACA,aAAA;EACA,qBAAA;EACA,yBAAA;AAEF;;AACE;EACE,iBAAA;EACA,YAAA;EACA,WAAA;AAEJ;AADI;EACE,aAAA;AAGN;;AACA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEF;;AAAA;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAGF;;AADA;EACE,aAAA;EACA,mBAAA;AAIF;;AADE;EACE,iBAAA;EACA,YAAA;EACA,WAAA;AAIJ","sourcesContent":[".checkListContainer {\n  margin-bottom: 10px;\n}\n.listGroupItem {\n  border: none;\n  padding: 0;\n  display: flex;\n  align-items: baseline;\n  background-color: inherit;\n}\n.checkSvg {\n  svg {\n    margin-right: 8px;\n    height: 14px;\n    width: 14px;\n    path {\n      fill: #2755fe;\n    }\n  }\n}\n.checkTextHeading {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n}\n.checkText {\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #555555;\n}\n.listingText {\n  display: flex;\n  align-items: center;\n}\n.checkItemSvg {\n  svg {\n    margin-right: 8px;\n    height: 14px;\n    width: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListContainer": `uddtElo0XNenR7ijIiyQ`,
	"listGroupItem": `lyW2m7VvozLfjPLc9qUR`,
	"checkSvg": `SvJ5TU4bvRe_ITSWLHBg`,
	"checkTextHeading": `twiGz92mCIFPZQnzBRdT`,
	"checkText": `RbcSIUqgiIv8ndfNmjAj`,
	"listingText": `V4zCOx7gQfDBCvkwE37w`,
	"checkItemSvg": `wp1RbOcXTojHY9fMD_be`
};
export default ___CSS_LOADER_EXPORT___;
