import * as Yup from 'yup';
import { getCustomNameValidationSchema } from '../../../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  collegeName: getCustomNameValidationSchema({
    message: 'Please enter a valid College/Institution/School (No special characters)',
    isRequired: true,
    isRequiredMessage: 'College/Institution/School'
  }),
  universityName: getCustomNameValidationSchema({
    message: 'Please enter a valid University/Board (No special characters)',
    isRequired: true,
    isRequiredMessage: 'University/Board'
  }),
  registrationNumber: getCustomNameValidationSchema({
    message: 'Please enter a valid registration number',
    isRequired: true,
    isRequiredMessage: 'registration number'
  }),
  educationLevel: getCustomNameValidationSchema({
    message: 'Please enter a valid education level'
  }),
  degreeType: getCustomNameValidationSchema({
    message: 'Please enter a valid attendance type',
    isRequired: true,
    isRequiredMessage: 'attendance type'
  }),
  degree: getCustomNameValidationSchema({
    message: 'Please enter a valid degree (No special characters)'
  }),
  areasOfStudy: getCustomNameValidationSchema({
    message: 'Please enter a valid area of study (No special characters)'
  }),
  startDate: Yup.mixed().test({
    name: 'startDate',
    message: 'Please enter a valid date',
    test: (value) => {
      return value !== null ? true : false;
    }
  }),
  endDate: Yup.mixed().test({
    name: 'endDate',
    message: 'Please enter a valid date',
    test: (value) => {
      return value !== null ? true : false;
    }
  })
});
