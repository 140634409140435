/* eslint-disable no-unused-expressions */
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Button from '../../../core-components/Button';
import styles from './Transactions.module.scss';
import TransactionsReducer from './Reducers/TransactionsReducer';
import CreditsFlow from './Components/Credits';
import { errToastMessage, getErrorMessage } from '../../../utils/utilities';
import PurchaseHistory from '../Packages/Prepaid/PurchaseHistory';
import UsageLogs from './Components/UsageLogs';
import PendingOrders from './Components/PendingOrders';
import { isEmpty } from 'lodash';

const REDUCER = TransactionsReducer;

/* Get currency value with comma separators */
const getAvailableBalanceValue = (val) => {
  let res = 0;

  if (val < 0) {
    return <p className={styles.amount}> &#8377;{res}</p>;
  }

  if (val) {
    res = parseInt(val);
    res = res.toLocaleString('en-IN');
  }

  return <p className={styles.amount}> &#8377;{res}</p>;
};

const INITIAL_STATE = {
  loading: true,
  activeTab: 'PURCHASE_HISTORY',
  showCreditsFlow: false,
  purchaseHistoryTableData: {},
  usageLogTableData: {},
  pendingOrders: {}
};

function Transactions(props) {
  const searchParams = new URLSearchParams(location.search);
  const defaultTab = searchParams?.get('tab');
  const {
    getPendingOrders,
    pendingOrders,
    invalidatePurchaseOrders,
    creditTransactionHistory,
    getPaymentDetails
  } = props;
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);

  useEffect(() => {
    callCreditTransactionHistoryAPI();
    callInsuffCreditsAPI();
    getPendingOrders();
    getPaymentDetails();
    return () => {
      invalidatePurchaseOrders();
    };
  }, []);

  useEffect(() => {
    if (pendingOrders && !isEmpty(pendingOrders) && !pendingOrders.loading) {
      setPendingOrdersCount(pendingOrders.pendingOrdersCount);
      dispatch({ type: 'SET_LOADING_FALSE' });
    }
    if (defaultTab) {
      // Page Tab Query Params
      const tabs = { ph: 0, ul: 1, po: 2 };
      tabSwitchHandler(tabs[defaultTab]);
    } else {
      if (pendingOrders && !isEmpty(pendingOrders) && !pendingOrders.loading) {
        if (pendingOrders.pendingOrdersCount > 0) {
          tabSwitchHandler(2);
        }
      }
    }
  }, [pendingOrders, defaultTab]);

  const callCreditTransactionHistoryAPI = (limit = 10, offset = 0) => {
    let payload = {
      limit: limit,
      offset: offset
    };

    props?.invalidateCreditTransactionHistory();
    props
      ?.getCreditTransactionHistory(payload)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  const callInsuffCreditsAPI = (limit = 10, offset = 0) => {
    let payload = {
      limit: limit,
      offset: offset
    };

    props?.invalidateInsuffCredits();
    props
      ?.getInsuffCredits(payload)
      .then(() => {})
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  };

  const tabSwitchHandler = (index) => {
    /**
     * Index values:
     * * '0' is for Purchase History tab
     * * '1' is for Usage tab
     * * '2' is for Pending Orders tab
     */

    switch (index) {
      case 0:
        dispatch({ type: 'SHOW_PURCHASE_HISTORY_TAB' });
        addQueryParams('ph');
        break;
      case 1:
        dispatch({ type: 'SHOW_USAGE_LOG_TAB' });
        addQueryParams('ul');
        break;
      case 2:
        dispatch({ type: 'SHOW_PENDING_ORDERS' });
        addQueryParams('po');
        break;
    }
  };

  const addQueryParams = (query) => {
    searchParams.set('tab', query);
    props.history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const addCreditsButtonHandler = () => {
    dispatch({ type: 'TOGGLE_CREDITS_FLOW_VISIBILITY', value: !state.showCreditsFlow });
  };

  const renderTabContent = () => {
    switch (state.activeTab) {
      case 'PURCHASE_HISTORY':
        return <PurchaseHistory history={props?.history} />;
      case 'USAGE_LOG':
        return <UsageLogs />;
      case 'PENDING_ORDERS':
        return <PendingOrders />;
      default:
        break;
    }
  };
  return (
    <div>
      {!state.showCreditsFlow && (
        <div className={styles.parentDivWrapper}>
          <>
            <div className={styles.header}>
              <div className={styles.heading}>{'Transactions'}</div>
            </div>
            <Card className={styles.bodyCard}>
              {!state.loading && (
                <>
                  <div className={styles.tabStyle}>
                    <div
                      onClick={() => tabSwitchHandler(0)}
                      className={
                        state.activeTab === 'PURCHASE_HISTORY'
                          ? styles.tabItemActive
                          : styles.tabItem
                      }
                    >
                      <div className={styles.tabInnerElement}>Purchase History</div>
                    </div>
                    <div
                      onClick={() => tabSwitchHandler(1)}
                      className={
                        state.activeTab === 'USAGE_LOG' ? styles.tabItemActive : styles.tabItem
                      }
                    >
                      <div className={styles.tabInnerElement}>Usage Log</div>
                    </div>
                    <div
                      onClick={() => tabSwitchHandler(2)}
                      className={
                        state.activeTab === 'PENDING_ORDERS' ? styles.tabItemActive : styles.tabItem
                      }
                    >
                      <div className={styles.tabInnerElement}>
                        Pending Orders ({pendingOrdersCount})
                      </div>
                    </div>
                  </div>
                  {renderTabContent()}
                </>
              )}
            </Card>
          </>
        </div>
      )}
      {state.showCreditsFlow ? (
        <CreditsFlow
          addCreditsButtonHandler={addCreditsButtonHandler}
          paymentDetails={props?.paymentDetails}
          insuffCredits={props?.insuffCredits}
        />
      ) : null}
    </div>
  );
}

export default Transactions;
