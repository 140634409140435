// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Oiwwzt2aot45dWvhh6cK {
  font-size: 18px;
  color: #4c4c4c;
  margin-bottom: 28px;
  display: flex;
}

.OdxLrdE08iHHNimXP70x {
  margin-right: 24px;
  background-color: #fff;
  padding: 0 12px 12px 4px;
  font-size: 14px;
  color: #4c4c4c;
}
.OdxLrdE08iHHNimXP70x label {
  color: #666666;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
}
.OdxLrdE08iHHNimXP70x label span {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}

.HN_OX0noWF7ZvvFa_Es2 {
  display: list-item;
  list-style-type: square;
  margin-left: 50px;
}

.uEWbSbLH06R_4jhD5vmo {
  display: list-item;
  list-style-type: square;
  margin-left: 59px;
}

.RLUlrBhoaqQbRxDIcW2I {
  margin-left: 22px;
}

.t5DVt4av_LgWo0dZigd6 {
  color: #333333 !important;
}`, "",{"version":3,"sources":["webpack://./src/core-components/CustomValidatedRadioFields/CustomValidatedRadioFields.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,wBAAA;EACA,eAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;AAEJ;AADI;EACE,gCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAGN;;AAEA;EACE,kBAAA;EACA,uBAAA;EACA,iBAAA;AACF;;AACA;EACE,kBAAA;EACA,uBAAA;EACA,iBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AAAA;EACE,yBAAA;AAGF","sourcesContent":[".question {\n  font-size: 18px;\n  color: #4c4c4c;\n  margin-bottom: 28px;\n  display: flex;\n}\n\n.option {\n  margin-right: 24px;\n  background-color: #fff;\n  padding: 0 12px 12px 4px;\n  font-size: 14px;\n  color: #4c4c4c;\n  label {\n    color: #666666;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 21px;\n    display: flex;\n    span {\n      font-family: Poppins, sans-serif;\n      font-style: normal;\n      font-weight: normal;\n      font-size: 14px;\n      line-height: 21px;\n      color: #999999;\n    }\n  }\n}\n\n.listItem {\n  display: list-item;\n  list-style-type: square;\n  margin-left: 50px;\n}\n.firstListItem {\n  display: list-item;\n  list-style-type: square;\n  margin-left: 59px;\n}\n\n.listItemText {\n  margin-left: 22px;\n}\n.boldLabel {\n  color: #333333 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": `Oiwwzt2aot45dWvhh6cK`,
	"option": `OdxLrdE08iHHNimXP70x`,
	"listItem": `HN_OX0noWF7ZvvFa_Es2`,
	"firstListItem": `uEWbSbLH06R_4jhD5vmo`,
	"listItemText": `RLUlrBhoaqQbRxDIcW2I`,
	"boldLabel": `t5DVt4av_LgWo0dZigd6`
};
export default ___CSS_LOADER_EXPORT___;
