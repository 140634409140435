import { connect } from 'react-redux';
import { setActiveTiers } from '../../../../../../actions/company';
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTiers: (data) => dispatch(setActiveTiers(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
