// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dvXCWccmP38RLp3ID3JN {
  margin-bottom: 32px;
}
.dvXCWccmP38RLp3ID3JN .CK0OaeLJ3NmYJY39w0bG {
  border: none;
  cursor: pointer;
}
.dvXCWccmP38RLp3ID3JN .CK0OaeLJ3NmYJY39w0bG .etJmwszVtpAJSWnllmjd {
  display: flex;
  justify-content: space-between;
  background: #f8f9fa;
  border: none;
}
.dvXCWccmP38RLp3ID3JN .CK0OaeLJ3NmYJY39w0bG .LoHVHY4PLDJjqcclnnLA {
  padding-left: 0;
  padding-right: 0;
}
.dvXCWccmP38RLp3ID3JN .CK0OaeLJ3NmYJY39w0bG .LoHVHY4PLDJjqcclnnLA .lDys53a82MuZ9ITraj1g .JZYbAGK3eI_Jl3EcpP7R {
  display: flex;
  background: #e6f7ff;
  border: 1px solid #e6f7ff;
  color: #262626;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}
.dvXCWccmP38RLp3ID3JN .CK0OaeLJ3NmYJY39w0bG .LoHVHY4PLDJjqcclnnLA .lDys53a82MuZ9ITraj1g .JZYbAGK3eI_Jl3EcpP7R .f9w65CoXBNlmVYtL23wx {
  font-family: roboto;
}
.dvXCWccmP38RLp3ID3JN .CK0OaeLJ3NmYJY39w0bG .LoHVHY4PLDJjqcclnnLA .lDys53a82MuZ9ITraj1g .JZYbAGK3eI_Jl3EcpP7R p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/CreateCandidate/SelectedPackage/Addons/Addons.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAAE;EACE,YAAA;EACA,eAAA;AAEJ;AADI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;AAGN;AADQ;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AAGV;AAFU;EACE,mBAAA;AAIZ;AAFU;EACE,SAAA;AAIZ","sourcesContent":[".addonsContainer {\n  margin-bottom: 32px;\n  .addonsContainerCard {\n    border: none;\n    cursor: pointer;\n    .addonsContainerHeader {\n      display: flex;\n      justify-content: space-between;\n      background: #f8f9fa;\n      border: none;\n    }\n    .cardBody {\n      padding-left: 0;\n      padding-right: 0;\n      .alertContainer {\n        .alert {\n          display: flex;\n          background: #e6f7ff;\n          border: 1px solid #e6f7ff;\n          color: #262626;\n          font-weight: normal;\n          font-size: 14px;\n          margin: 0;\n          .rupee {\n            font-family: roboto;\n          }\n          p {\n            margin: 0;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `dvXCWccmP38RLp3ID3JN`,
	"addonsContainerCard": `CK0OaeLJ3NmYJY39w0bG`,
	"addonsContainerHeader": `etJmwszVtpAJSWnllmjd`,
	"cardBody": `LoHVHY4PLDJjqcclnnLA`,
	"alertContainer": `lDys53a82MuZ9ITraj1g`,
	"alert": `JZYbAGK3eI_Jl3EcpP7R`,
	"rupee": `f9w65CoXBNlmVYtL23wx`
};
export default ___CSS_LOADER_EXPORT___;
