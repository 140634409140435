import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import Image from '../../../core-components/Image/Image';

const Redirect = (props) => {
  const { history } = props;

  const urlHashRef = useRef('');

  useEffect(() => {
    if (window.location.hash) urlHashRef.current = window.location.hash;
  }, []);

  useEffect(() => {
    const handleNavigate = () => {
      if (urlHashRef.current) {
        history.replace(
          {
            pathname: history.location.pathname,
            hash: urlHashRef.current
          },
          undefined,
          { shallow: true }
        );
      }
    };
    const intervalTimer = setInterval(handleNavigate, 1000);
    handleNavigate();

    return () => {
      clearInterval(intervalTimer);
    };
  }, [history.location.pathname]);

  const style = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  };

  return (
    <div style={style}>
      <Image name='ms-login-loading.png' data-testid='Loading' style={{ maxWidth: '100%' }} />
    </div>
  );
};

export default withRouter(Redirect);
