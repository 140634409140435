import { withFormik } from 'formik';
import { isEmpty, mergeWith } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = (state) => {
  return {
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null,
    profile: state?.profile && !isEmpty(state?.profile) ? state?.profile : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyTds: ''
      };

      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {},
    displayName: 'apply company Tds buy pkg'
  })
);
