import React from 'react';
import { Button } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

import Image from '../../../../core-components/Image';

import styles from './BgvGoBackBtn.module.scss';

export default (props) => {
  return !isMobileOnly ? (
    <Button type='button' className={styles.goBackBtn} onClick={props.handleClick} data-testid="go-back-image">
      Go Back
    </Button>
  ) : (
    <Image className={styles.goBackBtnMobile} name='goBack.svg' onClick={props.handleClick} />
  );
};
