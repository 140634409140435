// Import libraries and packages
import React, { useState } from 'react';
import { Card, Container, Row, Col, Button, Table, Accordion } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp, IoIosSearch } from 'react-icons/io';

// Import styles
import styles from './BuyRentLaptop.module.scss';

// Import utils and other components
import laptopData from './laptopsLists.json';
import LaptopIcon from '../../../images/laptop-icon.png';
import LaptopRequestModal from './Modals/LaptopRequestModal';
import BuyLaptopModal from './Modals/BuyLaptopModal';
import SuccessModal from './Modals/SuccessModal';
import { convertToIndianNumeration } from '../../../utils/utilities';

/**
 * Represents a card component for displaying laptop details.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.laptop - The laptop object containing the details.
 * @param {Function} props.setData - The function to set the data.
 * @param {Function} props.setIsBuyModalOpen - The function to set the buy modal open state.
 * @returns {JSX.Element} The rendered LaptopCard component.
 */
const LaptopCard = ({ laptop, setData, setIsBuyModalOpen }) => {
  const onClickBuyButton = () => {
    setData(laptop);
    setIsBuyModalOpen(true);
  };

  return (
    <Card className={styles.laptopCard}>
      <div className={styles.laptopCardHeader}>
        <div className={styles.laptopIcon}>
          <img src={LaptopIcon} alt='laptop' />
        </div>
        <div className={styles.laptopDetailsSection}>
          <span className={styles.laptopName}>{laptop.model}</span>
          <p className={`${styles.laptopSpecification} ${styles.lineHeight28}`}>
            Display: <span className={styles.laptopSpecificationValue}> {laptop.display}</span>
            <br />
            Processor: <span className={styles.laptopSpecificationValue}> {laptop.processor}</span>
            <br />
            RAM: <span className={styles.laptopSpecificationValue}> {laptop.ram}</span>
            <br />
            Storage: <span className={styles.laptopSpecificationValue}> {laptop.storage}</span>
          </p>
        </div>
      </div>

      <Card.Body className={styles.laptopCardBody}>
        <div className={styles.laptopSpecification}>Price</div>
        <div className={styles.priceSection}>
          <div className={styles.priceContainer}>
            <span className={styles.price}>
              <span className='rupee'>₹</span>
              {convertToIndianNumeration(parseInt(laptop.price))}
            </span>
            {laptop.mrp && (
              <span className={styles.mrp}>
                <span className='rupee'>₹{convertToIndianNumeration(parseInt(laptop.mrp))}</span>
              </span>
            )}
          </div>
        </div>
        <Button onClick={onClickBuyButton} className={styles.buyButton} variant='primary' block>
          Buy
        </Button>
      </Card.Body>
    </Card>
  );
};

/**
 * Renders the BuyRentLaptopPage component.
 *
 * @returns {JSX.Element} The rendered BuyRentLaptopPage component.
 */
function BuyRentLaptopPage() {
  const [isTermsAndConditionsAccordionOpen, setIsTermsAndConditionsAccordionOpen] = useState(false);
  const [currentLaptopData, setCurrentLaptopData] = useState(null);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [isRaiseRequestModalOpen, setIsRaiseRequestModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleToggle = () => {
    setIsTermsAndConditionsAccordionOpen(!isTermsAndConditionsAccordionOpen);
  };

  const handleToggleRaiseRequestModal = () => {
    setIsRaiseRequestModalOpen(!isRaiseRequestModalOpen);
  };

  const onCloseBuyLaptopModal = () => {
    setIsBuyModalOpen(false);
  };

  const onCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setCurrentLaptopData(null);
  };

  return (
    <Container fluid className={styles.pageContainer}>
      <Card className={styles.containerCard}>
        <Card.Header className={styles.cardHeader}>
          <span>Buy laptops</span>
        </Card.Header>
        <Card.Body>
          <Row className={styles.laptopList}>
            {laptopData.map((laptop, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={4} xl={4}>
                <LaptopCard
                  laptop={laptop}
                  setData={setCurrentLaptopData}
                  setIsBuyModalOpen={setIsBuyModalOpen}
                />
              </Col>
            ))}
          </Row>
          <div className={styles.requestSection}>
            <div className={styles.requestText}>
              <span className={styles.searchIconBox}>
                <IoIosSearch />{' '}
              </span>{' '}
              Didn't find the laptop or a specific model you are looking for?
            </div>
            <Button
              variant='link'
              className={styles.raiseRequestBtn}
              onClick={handleToggleRaiseRequestModal}
            >
              Raise a request
            </Button>
          </div>
        </Card.Body>
      </Card>

      <Card className={styles.faqCard}>
        <Card.Header className={styles.faqHeader}>
          <span>Frequently Asked Questions</span>
        </Card.Header>
        <Card.Body className={styles.faqCardBody}>
          <Table borderless>
            <tbody>
              <tr>
                <td>
                  <strong>Why Us:</strong>
                </td>
                <td>
                  Better prices, better service with onsite visits and software installation with
                  MDM
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Agreement:</strong>
                </td>
                <td>This will be a tripartite agreement between SpringVerify, you and vendor</td>
              </tr>
              <tr>
                <td>
                  <strong>Warranty:</strong>
                </td>
                <td>Warranty of 3 years</td>
              </tr>
              <tr>
                <td className={styles.noBorder}>
                  <strong>Delivery cost:</strong>
                </td>
                <td className={styles.noBorder}>All delivery costs to be borne by you</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Accordion defaultActiveKey='1' className={styles.termsAccordion}>
        <Card className={styles.termsCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={`d-flex justify-content-between align-items-center ${styles.termsHeader}`}
            onClick={handleToggle}
          >
            <div className={styles.termsTitle}>Terms & Conditions</div>
            <Button variant='link' className={styles.accordionToggle}>
              <span className={styles.caret}>
                {!isTermsAndConditionsAccordionOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </span>
            </Button>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.termsCardBody}>
              <ul className={styles.termsList}>
                <li>Taxes : 18% Extra</li>
                <li>Delivery : 4-5 working days from the date of PO</li>
                <li>Payment : 100% Advance</li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      {isRaiseRequestModalOpen && (
        <LaptopRequestModal
          show={isRaiseRequestModalOpen}
          handleClose={handleToggleRaiseRequestModal}
        />
      )}
      {isBuyModalOpen && (
        <BuyLaptopModal
          show={isBuyModalOpen}
          onHide={onCloseBuyLaptopModal}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
          laptop={currentLaptopData}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessModal
          show={isSuccessModalOpen}
          laptop={currentLaptopData}
          onHide={onCloseSuccessModal}
        />
      )}
    </Container>
  );
}

export default BuyRentLaptopPage;
