import React from 'react';
import { Modal } from 'react-bootstrap';
import AppConstants from '../../../../../core-components/AppConstants';
import Button from '../../core-components/Button';
import styles from './LoginDisableConfirmationModal.module.scss';

export default function LoginDisableConfirmationModal(props) {
  return (
    <div>
      <Modal
        size='lg'
        show={props?.disableLoginMethodConfirmationModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        keyboard={false}
        onHide={() => props?.handleDisableLoginMethod(true)}
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            <h4>Are you sure?</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.bodyDiv}>
            <div className={styles.heading1}>
              {props.isCA
                ? 'Some of the security changes you have made will cause all users (including yourself) to be logged out. All users will have to login again.'
                : 'Some of the security changes you have made will cause all CA users (including all admins and HR) to be logged out. All CA users will have to login again.'}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.passwordSubmitBtn}>
          <Button className={styles.backBtn} onClick={() => props.handleDisableLoginMethod(true)}>
            Back
          </Button>
          <Button
            className={styles.btn}
            onClick={(e) => {
              props.handleFormSubmit(e);
              props.handleDisableLoginMethod(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
