import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = (state) => {
  return {
    createCandConfig: state.createCandConfig || null,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
