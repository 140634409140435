import React, { useState, useEffect } from 'react';
import { startCase, has, isEmpty } from 'lodash';
import moment from 'moment';

import { Table, Card, Form, Col, Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import Input from '../../../core-components/Input';

import Header from '../Header';
import Image from '../../../core-components/Image';
import Pagination from '../../../core-components/Pagination';
import Footer from '../Footer';
import Comments from './CommentsModal';

import styles from './Insufficiencies.module.scss';
import { useDebounce } from '../../../utils/utilities';

export default (props) => {
  const pageSizes = [10, 25, 50, 100];

  const pageNumber = 0;

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [isData, setIsData] = useState(true);
  const [loading, setLoading] = useState(true);

  const [openCount, setOpenCount] = useState(null);
  const [closeCount, setCloseCount] = useState(null);
  const [unresolvedCount, setUnresolvedCount] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState({ open: false, data: null });

  const [status, setStatus] = useState('OPEN');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [candidateName, setCandidateName] = useState(null);
  const [timeInSuffSort, setTimeInsuffSort] = useState('asc');
  const [updatedTimeSort, setUpdatedTimeSort] = useState(null);

  const useDebounceSearchTerm = useDebounce(candidateName, 1500);

  const handleCommentModal = (data = null) => {
    setShowCommentModal({ ...showCommentModal, open: !showCommentModal.open, data });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    callInsufficiencyAPI(limit, offset, status, candidateName, timeInSuffSort, updatedTimeSort);
  }, []);

  useEffect(() => {
    callInsufficiencyAPI(limit, offset, status, candidateName, timeInSuffSort, updatedTimeSort);
  }, [useDebounceSearchTerm]);

  const callInsufficiencyAPI = (
    limit = 10,
    offset = 0,
    status = 'OPEN',
    candidateName = null,
    sortByInsuffTime = 'asc',
    sortByUpdatedTime = null
  ) => {
    setLoading(true);
    let data = {
      limit,
      offset,
      status,
      candidateName,
      sortByInsuffTime,
      sortByUpdatedTime
    };
    props?.getNewInsufficiencies(data);
  };

  useEffect(() => {
    if (has(props?.insufficienciesNewData, 'data')) {
      setData(props?.insufficienciesNewData?.data);
      setIsData(!isEmpty(props?.insufficienciesNewData?.data));
      setOpenCount(props?.insufficienciesNewData?.openCount);
      setCloseCount(props?.insufficienciesNewData?.closedCount);
      setUnresolvedCount(props?.insufficienciesNewData?.unresolvedCount);
      status === 'RESOLVED'
        ? setCount(props?.insufficienciesNewData?.closedCount)
        : status === 'UNRESOLVED'
        ? setCount(props?.insufficienciesNewData?.unresolvedCount)
        : setCount(props?.insufficienciesNewData?.openCount);
      setLoading(false);
    }
  }, [props.insufficienciesNewData]);

  const paginate = (limitValue, offsetValue) => {
    callInsufficiencyAPI(
      limitValue,
      offsetValue,
      status,
      candidateName,
      timeInSuffSort,
      updatedTimeSort
    );
  };

  const getTooltip = (icon, text) => {
    return (
      <OverlayTrigger
        placement={'right'}
        overlay={
          <Tooltip id={`${text}-right`}>
            <p>{text}</p>
          </Tooltip>
        }
      >
        <Image name={icon} />
      </OverlayTrigger>
    );
  };

  const getTitleBox = (data) => {
    return (
      <div className={styles.tableTitleBox}>
        <div className={styles.tableTitleLogo}>
          {status === 'RESOLVED'
            ? getTooltip('insuffTableClosed.svg', 'Resolved issue')
            : status === 'UNRESOLVED'
            ? getTooltip('insuffTableUnresolved.svg', 'Unresolved issue')
            : getTooltip('insuffTableOpen.svg', 'Open issue')}
        </div>
        <div className={styles.tableTitleContent}>
          <div className={styles.tableTitleContentInfo} onClick={() => handleCommentModal(data)}>
            {data.issue}
          </div>
          <div className={styles.tableTitleContentCount}>
            <Image name='insuffCommentLogo.svg' />{' '}
            <span style={{ marginLeft: '5px' }}>{data.commentsCount}</span>
          </div>
        </div>
      </div>
    );
  };

  const searchCandidate = (value) => {
    setLoading(true);
    setCandidateName(value);
  };
  const searchComponent = () => {
    return (
      <div className={styles.searchInput}>
        <Image name='searchInputIcon.svg' style={{ width: '20px' }} />
        <Input
          placeholder={'Candidate'}
          className={styles.inputText}
          value={candidateName}
          onChange={(event) => {
            searchCandidate(event.target.value);
          }}
        />
        {candidateName ? (
          <Image
            onClick={() => {
              setIsData(true);
              setLoading(true);
              setCandidateName('');
              searchCandidate('');
            }}
            name='closeIcon.svg'
          />
        ) : null}
      </div>
    );
  };

  const pageSizeOptions = [];
  let index = 0;
  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const handleAscendingSort = (title) => {
    if (title === 'Time in insuff') {
      setTimeInsuffSort('asc');
      setUpdatedTimeSort(null);
      callInsufficiencyAPI(limit, offset, status, candidateName, 'asc', null);
    } else if (title === 'Updated') {
      setUpdatedTimeSort('asc');
      setTimeInsuffSort(null);
      callInsufficiencyAPI(limit, offset, status, candidateName, null, 'asc');
    }
  };

  const handleDescendingSort = (title) => {
    if (title === 'Time in insuff') {
      setTimeInsuffSort('desc');
      setUpdatedTimeSort(null);
      callInsufficiencyAPI(limit, offset, status, candidateName, 'desc', null);
    } else if (title === 'Updated') {
      setUpdatedTimeSort('desc');
      setTimeInsuffSort(null);
      callInsufficiencyAPI(limit, offset, status, candidateName, null, 'desc');
    }
  };

  const titleWithSort = (title, state) => {
    return (
      <div className={styles.tableHeaderSort}>
        <div>{title}</div>
        <div className={styles.tableHeaderSortImg}>
          {state === 'asc' ? (
            <Image name='insuffTableUpArrowActive.svg' />
          ) : (
            <Image
              name='insuffTableUpArrowInactive.svg'
              onClick={() => {
                handleAscendingSort(title);
              }}
            />
          )}
          {state === 'desc' ? (
            <Image name='insuffTableDownArrowActive.svg' />
          ) : (
            <Image
              name='insuffTableDownArrowInactive.svg'
              onClick={() => {
                handleDescendingSort(title);
              }}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className={styles.insufficienciesWrapper}>
        <div className={styles.heading}>{'Insufficiencies'}</div>
        <Card className={styles.insufficienciesCard}>
          <Navbar className={styles.navHeader}>
            <Nav className='mr-auto'>
              <Nav.Link
                style={{
                  color: status === 'OPEN' && '#2755FE',
                  borderBottom: status === 'OPEN' && '1px solid #2755FE'
                }}
                onClick={() => {
                  setStatus('OPEN');
                  callInsufficiencyAPI(
                    10,
                    0,
                    'OPEN',
                    candidateName,
                    timeInSuffSort,
                    updatedTimeSort
                  );
                }}
              >
                {status === 'OPEN' ? (
                  <Image style={{ marginTop: '-4px' }} name='insuffOpenActive.svg' />
                ) : (
                  <Image style={{ marginTop: '-4px' }} name='insuffOpenDefault.svg' />
                )}{' '}
                {`${openCount} open`}
              </Nav.Link>
              <Nav.Link
                style={{
                  color: status === 'RESOLVED' && '#2755FE',
                  borderBottom: status === 'RESOLVED' && '1px solid #2755FE'
                }}
                onClick={() => {
                  setStatus('RESOLVED');
                  callInsufficiencyAPI(
                    10,
                    0,
                    'RESOLVED',
                    candidateName,
                    timeInSuffSort,
                    updatedTimeSort
                  );
                }}
              >
                {status === 'RESOLVED' ? (
                  <Image style={{ marginTop: '-4px' }} name='insuffClosedActive.svg' />
                ) : (
                  <Image style={{ marginTop: '-4px' }} name='insuffClosedDefault.svg' />
                )}{' '}
                {`${closeCount} Resolved `}
              </Nav.Link>
              <Nav.Link
                style={{
                  color: status === 'UNRESOLVED' && '#2755FE',
                  borderBottom: status === 'UNRESOLVED' && '1px solid #2755FE'
                }}
                onClick={() => {
                  setStatus('UNRESOLVED');
                  callInsufficiencyAPI(
                    10,
                    0,
                    'UNRESOLVED',
                    candidateName,
                    timeInSuffSort,
                    updatedTimeSort
                  );
                }}
              >
                {status === 'UNRESOLVED' ? (
                  <Image style={{ marginTop: '-4px' }} name='insuffUnresolvedActive.svg' />
                ) : (
                  <Image style={{ marginTop: '-4px' }} name='insuffUnresolvedDefault.svg' />
                )}{' '}
                {`${unresolvedCount} unresolved`}
              </Nav.Link>
              {searchComponent()}
            </Nav>
          </Navbar>

          <Card className={styles.tableCard}>
            <Table className={styles.insufficienciesTable}>
              <thead>
                <tr>
                  <th>Issue</th>
                  <th>Candidate</th>
                  <th>Check</th>
                  <th>Identifier</th>
                  <th>{titleWithSort('Time in insuff', timeInSuffSort)}</th>
                  <th>{titleWithSort('Updated', updatedTimeSort)}</th>
                </tr>
              </thead>
              {isData ? (
                <tbody>
                  {loading
                    ? Array.apply(null, { length: limit })
                        .map(Number.call, Number)
                        .map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <ContentLoader height={20} width={20}>
                                  <rect x='0' y='0' width='20' height='14' />
                                </ContentLoader>
                              </td>
                              <td>
                                <ContentLoader height={20} width={80}>
                                  <rect x='0' y='0' width='80' height='14' />
                                </ContentLoader>
                              </td>
                              <td>
                                <ContentLoader height={20} width={80}>
                                  <rect x='0' y='0' width='80' height='14' />
                                </ContentLoader>
                              </td>
                              <td>
                                <ContentLoader height={20} width={80}>
                                  <rect x='0' y='0' width='80' height='14' />
                                </ContentLoader>
                              </td>
                              <td>
                                <ContentLoader height={20} width={80}>
                                  <rect x='0' y='0' width='80' height='14' />
                                </ContentLoader>
                              </td>
                              <td>
                                <ContentLoader height={20} width={80}>
                                  <rect x='0' y='0' width='80' height='14' />
                                </ContentLoader>
                              </td>
                            </tr>
                          );
                        })
                    : data &&
                      data.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{getTitleBox(data)}</td>
                            <td>
                              <p>{data.candidate?.name}</p>
                            </td>
                            <td>
                              <p>{startCase(data.checkType)}</p>
                            </td>
                            <td>{startCase(data.identifier?.type)}</td>
                            <td>{`${data.daysInInsufficiency} days`}</td>
                            <td>
                              <OverlayTrigger
                                placement={'bottom'}
                                overlay={
                                  <Tooltip id={`${data.updatedAt}-right-${index}`}>
                                    <p>{moment(data.updatedAt).format('MMM DD,YYYY,h:mm A')}</p>
                                  </Tooltip>
                                }
                              >
                                <div>{moment(data.updatedAt).fromNow()}</div>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={5}>
                      <div className={styles.noDataWrapper}>
                        <Image name={'NoData.svg'} />
                        <div className={styles.noDataContent}>{'No candidates found'}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>

            {isData ? (
              <Form.Row className={styles.paginationWrapper}>
                <Col className={styles.showPagination}>
                  Show{' '}
                  <select
                    className={styles.selectPagination}
                    value={limit}
                    onChange={(event) => {
                      setOffset(0);
                      setLimit(parseInt(event.target.value));
                      paginate(parseInt(event.target.value), 0);
                    }}
                  >
                    {pageSizeOptions}
                  </select>{' '}
                  items of {count}
                </Col>
                <Col>
                  <Pagination
                    forcePage={pageNumber}
                    onPageChange={({ selected }) => {
                      setOffset(selected);
                      paginate(limit, selected);
                    }}
                    pageCount={count / limit}
                  />
                </Col>
                <Col />
              </Form.Row>
            ) : (
              <div />
            )}
          </Card>
        </Card>
      </div>

      {showCommentModal.open ? (
        <Comments show={showCommentModal} onHide={handleCommentModal} />
      ) : null}
    </div>
  );
};
