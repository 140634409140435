import React from 'react';
import { Field } from 'formik';
import moment from 'moment/moment';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import Header from '../Header';
import Footer from '../Footer';
import Button from '../../../core-components/Button';
import CustomValidatedRadioFields from '../../../core-components/CustomValidatedRadioFields';
import DateRangePicker from '../../../core-components/DateRangePicker/DateRangePicker';
import Image from '../../../core-components/Image';

import styles from './ExportExcel.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default class ExportExcel extends React.Component {
  state = {};
  componentDidMount() {
    this.props.getPaymentDetails();
  }
  render() {
    const { handleSubmit, isSubmitting, values } = this.props;
    return (
      <div className={styles.exportExcel}>
        <div className={styles.cardSection}>
          <div className={styles.pageHeader}>
            <h4>Export Cases</h4>
          </div>

          <Card className={styles.exportExcelCard}>
            <Card.Body className={styles.cardBody}>
              <Form onSubmit={handleSubmit}>
                <Form.Label className={styles.dateLabel}>Date Range</Form.Label>
                <div className={styles.customRangeMessage}>
                  <FontAwesomeIcon icon={faCheck} className={styles.checkItemSvg} />
                  If there are more than 1K records for the selected date range, try downloading
                  with a custom date range.
                </div>
                <div className={styles.radioBtn}>
                  <Form.Row>
                    <Form.Group>
                      <Field
                        name='dateRange'
                        component={CustomValidatedRadioFields}
                        options={[
                          { value: 'all', label: 'All', additionalLabel: null },
                          {
                            value: '7',
                            label: 'Last 7 days',
                            additionalLabel: `${moment().format('DD MMM YYYY')} - ${moment()
                              .subtract(7, 'd')
                              .format('DD MMM YYYY')}`
                          },
                          {
                            value: '30',
                            label: 'Last 30 days',
                            additionalLabel: `${moment().format('DD MMM YYYY')} - ${moment()
                              .subtract(30, 'd')
                              .format('DD MMM YYYY')}`
                          },
                          {
                            value: '60',
                            label: 'Last 60 days',
                            additionalLabel: `${moment().format('DD MMM YYYY')} - ${moment()
                              .subtract(60, 'd')
                              .format('DD MMM YYYY')}`
                          },
                          {
                            value: '90',
                            label: 'Last 90 days',
                            additionalLabel: `${moment().format('DD MMM YYYY')} - ${moment()
                              .subtract(90, 'd')
                              .format('DD MMM YYYY')}`
                          },
                          {
                            value: 'custom',
                            label: 'Custom',
                            additionalLabel: null
                          }
                        ]}
                      />
                      {values.dateRange === 'custom' ? (
                        <div className={styles.datePicker}>
                          <Field name='customDate' component={DateRangePicker} />
                        </div>
                      ) : null}
                    </Form.Group>
                  </Form.Row>
                </div>
                {!values.isValid && (
                  <div className={styles.errorMsg}>
                    {!values.isValidPhone ? 'Please select a valid range.' : ''}
                  </div>
                )}
                <div className={styles.btnSection}>
                  <Button
                    className={styles.exportBtn}
                    type='submit'
                    disabled={
                      isSubmitting ||
                      (values.dateRange == 'custom' &&
                        (values.startDate == null || values.endDate == null))
                    }
                  >
                    {isSubmitting ? 'Exporting' : 'Export'}
                    {isSubmitting && (
                      <Spinner
                        className={styles.spinner}
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>
              </Form>
              {values.isSuccess && (
                <div>
                  <div className={styles.successAlert}>
                    <Image className={styles.successAlertImage} name={'greenTickAlert.svg'} />
                    {values?.message}
                  </div>
                  <div className={styles.successText}>
                    Your export has finished! It should have already started downloading. But if it
                    hasn’t you can
                    <a href={values?.document}> download it here</a>
                  </div>
                </div>
              )}
              {values.isFailure && (
                <div>
                  <div className={styles.failureAlert}>
                    <Image className={styles.successAlertImage} name={'redTickAlert.svg'} />
                    {values?.message}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
