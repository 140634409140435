import React from 'react';
import Form from 'react-bootstrap/Form';
import { getIn } from 'formik';

import Input from '../Input';
import styles from './ValidateFormInputField.module.scss';
import { has } from 'lodash';

export default React.forwardRef(
  ({ field, form, label, regex, max, handleChange, fieldWithoutSpace, ...props }, ref) => {
    // Handle Blur to remove extra space from the field value

    const handleBlur = (event) => {
      let value = has(props, 'noAutoTrimmedRequired')
        ? event.target.value
        : event.target.value.replace(/\s\s+/g, ' ');

      if (!has(props, 'noAutoTrimmedRequired')) value = value.trim();

      if (has(props, 'trimWithoutSpace')) value = value.replace(/\s/g, '');

      form.handleBlur(event);
      form.setFieldValue(field.name, value);
    };

    return (
      <Form.Group
        style={{ marginBottom: '32px', width: '100%' }}
        controlId={field && field.name}
        className={props.className}
      >
        {label && <Form.Label>{label}</Form.Label>}
        <Input
          data-testid='input'
          {...field}
          {...props}
          // isInvalid={form && form.touched[field.name] && !!form.errors[field.name]}
          isInvalid={
            form && getIn(form.errors, field.name) && getIn(form.touched, field.name) ? true : false
          }
          onChange={(event) => {
            if (props.onChange) {
              props.onChange(event);
            } else {
              form.setFieldValue(field.name, event.target.value.replace(regex, '').slice(0, max));
              handleChange && handleChange(event.target.value.replace(regex, '').slice(0, max));
            }
          }}
          ref={ref}
          onBlur={(event) => {
            if (props.onBlur) {
              props.onBlur(event);
            } else {
              handleBlur(event);
            }
          }}
        />
        <div className={styles.inputError}>
          {(form && getIn(form.errors, field.name) && getIn(form.touched, field.name)) ||
          props?.errors
            ? (form && getIn(form.errors, field.name)) || props?.errors
            : null}
        </div>
      </Form.Group>
    );
  }
);
