// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._GvCHDtQJdD5GSgJh26W {
  font-weight: 600 !important;
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/CreditBuyPackagePage/Packages/modals/SubtypeModal.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,oBAAA;AACF","sourcesContent":[".header {\n  font-weight: 600 !important;\n  margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `_GvCHDtQJdD5GSgJh26W`
};
export default ___CSS_LOADER_EXPORT___;
