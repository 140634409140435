// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H99tDiS1s2qODzGVhWyi .LE8r3NfanWrTRE2l2dxq {
  font-size: 16px;
}
.H99tDiS1s2qODzGVhWyi .I3YW8fBT42ryTt6HTjBA {
  font-size: 16px;
  padding: 20px 20px 20px 0px;
}
.H99tDiS1s2qODzGVhWyi .I3YW8fBT42ryTt6HTjBA .vEKU_B2jEPbCTXw_mgaA {
  padding: 0px 9px;
  color: #2755fe;
}
.H99tDiS1s2qODzGVhWyi .I3YW8fBT42ryTt6HTjBA .vEKU_B2jEPbCTXw_mgaA:hover {
  padding: 0px 9px;
  color: #2755fe;
  cursor: pointer;
}

.kLYCCKiWxFlqIDeCumXa {
  color: #fff;
  margin: 10px 10px 6px 0px;
  width: 136px;
  height: 48px;
  background-color: #007bff;
  border-color: #007bff;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/SecuritySettings/DomainLockModal/DomainLockModal.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;EACA,2BAAA;AAAJ;AACI;EACE,gBAAA;EACA,cAAA;AACN;AACI;EACE,gBAAA;EACA,cAAA;EACA,eAAA;AACN;;AAIA;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,eAAA;AADF","sourcesContent":[".bodyDiv {\n  .heading1 {\n    font-size: 16px;\n  }\n  .heading2 {\n    font-size: 16px;\n    padding: 20px 20px 20px 0px;\n    .aTag {\n      padding: 0px 9px;\n      color: #2755fe;\n    }\n    .aTag:hover {\n      padding: 0px 9px;\n      color: #2755fe;\n      cursor: pointer;\n    }\n  }\n}\n\n.btn {\n  color: #fff;\n  margin: 10px 10px 6px 0px;\n  width: 136px;\n  height: 48px;\n  background-color: #007bff;\n  border-color: #007bff;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyDiv": `H99tDiS1s2qODzGVhWyi`,
	"heading1": `LE8r3NfanWrTRE2l2dxq`,
	"heading2": `I3YW8fBT42ryTt6HTjBA`,
	"aTag": `vEKU_B2jEPbCTXw_mgaA`,
	"btn": `kLYCCKiWxFlqIDeCumXa`
};
export default ___CSS_LOADER_EXPORT___;
