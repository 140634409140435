import React from 'react';
import Select from 'react-select';
import { Card, Form } from 'react-bootstrap';
import { Field } from 'formik';

import AdminModals from '../../Administrators/AdminModals';
import CategoryModals from '../../CategoryModals';
import Button from '../../../../core-components/Button';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField/ValidatedFormInputField';
import { isValidPhoneNumber } from '../../../../utils/utilities';

import styles from '../Profile.module.scss';
import { isEmpty } from 'lodash';

export default class extends React.Component {
  state = {
    modalShow: false,
    categories: [],
    modalTitle: null,
    modalSubTitle: null,
    modalShowStatus: false,
    modalButton: null,
    modalPlaceholder: null,
    isAdmin: localStorage.accessLevel === 'admin'
  };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = () => {
    let categoriesList = [];

    return this.props?.getCategories().then(() => {
      this.props.categories &&
        this.props.categories.userCategories &&
        Array.isArray(this.props.categories.userCategories) &&
        this.props.categories.userCategories.map((category) => {
          categoriesList.push({ label: category.categoryName, value: category.id });
        });
      this.setState({
        categories: categoriesList
      });
    });
  };

  modalToggle = (title, subTitle, buttonText, placeholder) => {
    this.setState({
      modalShow: true,
      modalTitle: title,
      modalSubTitle: subTitle,
      modalButton: buttonText,
      modalPlaceholder: placeholder
    });
  };

  modalHide = (value) => {
    if (value === 'reload category') {
      this.setState({
        modalShowStatus: true,
        modalShow: false
      });
      this.fetchCategories();
    } else {
      this.setState({
        modalShow: false
      });
    }
  };

  modalHideStatus = () => {
    this.setState({
      modalShowStatus: false
    });
  };

  handleSelectChange = (value) => {
    this.setState({
      selectValue: value && value.length ? [...value] : []
    });
  };

  render() {
    const { handleSubmit, isSubmitting, setFieldValue, values, errors } = this.props;
    const profileInfo = this.props.profile.profile;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#666666',
        borderRadius: '4px',
        padding: '10px 20px',
        border: 'none',
        background: state.isFocused ? '#ffffff' : ''
      }),
      control: (provided) => ({
        ...provided,
        width: '304px',
        border: '1px solid #ebedf2',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        display: 'flex',
        height: '44px'
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#333333'
        };
      },
      valueContainer: (base) => ({
        ...base,
        height: '44px'
      }),
      container: (base) => ({
        ...base,
        width: '304px'
      }),
      menu: (provided, state) => ({
        ...provided,
        zIndex: '10'
      })
    };
    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={{ display: 'none' }} {...innerProps} />;
    };

    return (
      <Card className={styles.cardInner}>
        <div className={styles.cardHead}>
          <div className={styles.cardHeading}>{'Personal Profile'}</div>
          <div className={styles.cardHeadBtn}>
            {values.isPersonalEdit ? null : (
              <Button
                className={styles.editBtn}
                onClick={() => {
                  setFieldValue('isPersonalEdit', !values.isPersonalEdit);
                }}
              >
                {'Edit'}
              </Button>
            )}
          </div>
        </div>
        <Form className={styles.Form} onSubmit={handleSubmit}>
          <div className={styles.cardParticular}>
            <div className={styles.cardParticularTitle}>{'Full Name'}</div>
            {values.isPersonalEdit ? (
              <div className={styles.cardParticularFieldValue}>
                <Field component={ValidatedFormInputField} type='text' name='name' />
              </div>
            ) : (
              <div className={styles.cardParticularValue}>{profileInfo.name}</div>
            )}
          </div>
          <div className={styles.cardParticular}>
            <div className={styles.cardParticularTitle}>{'Email address'}</div>
            <div className={styles.cardParticularValue}>{profileInfo.email}</div>
          </div>
          <div className={styles.cardParticular}>
            <div className={styles.cardParticularTitle}>{'Mobile'}</div>
            {values.isPersonalEdit ? (
              <div className={styles.cardParticularFieldValue}>
                <Field
                  component={ValidatedFormInputField}
                  type='number'
                  name='mobile'
                  className={!values.isValidPhone ? styles.validationError : null}
                  onBlur={(e) => {
                    this.props?.handleBlur(e);
                    if (values.mobile && !errors.mobile) {
                      if (!isValidPhoneNumber(values.mobile)) {
                        setFieldValue('isValidPhone', false);
                      } else {
                        setFieldValue('isValidPhone', true);
                      }
                    }
                  }}
                  isHyphenNotRequired
                />
                {!errors.mobile && !values.isValidPhone && (
                  <div className={styles.validationErrorMsg}>
                    {!values.isValidPhone ? 'Please provide a valid Phone Number.' : ''}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.cardParticularValue}>{profileInfo.mobile}</div>
            )}
          </div>
          <div className={styles.cardParticular}>
            <div className={styles.cardParticularTitle}>{'Access'}</div>
            <div className={styles.cardParticularValue}>
              {profileInfo?.company_user_mapping?.access_level === 5 ? 'Admin' : 'HR'}
            </div>
          </div>
          <AdminModals
            show={this.state.modalShowStatus}
            onHide={this.modalHideStatus}
            content={'Category created successfully'}
          />
          <CategoryModals
            show={this.state.modalShow}
            onHide={this.modalHide}
            title={this.state.modalTitle}
            subTitle={this.state.modalSubTitle}
            button={this.state.modalButton}
            placeholder={this.state.modalPlaceholder}
            {...this.props}
          />
          <div className={styles.cardParticular}>
            <div className={styles.cardParticularTitle}>{'Category'}</div>
            {this.state.isAdmin && values.isPersonalEdit ? (
              <div className={styles.cardParticularFieldValue}>
                <Form.Group>
                  <div style={{ display: 'flex' }}>
                    <Select
                      isMulti
                      placeholder='No Category'
                      name='category'
                      components={{ IndicatorSeparator }}
                      options={this.state.categories}
                      noOptionsMessage={() => 'No Category found'}
                      styles={customStyles}
                      isClearable={false}
                      value={values.category}
                      onChange={(value) => {
                        setFieldValue('category', value);
                      }}
                    />
                    <Button
                      onClick={() =>
                        this.modalToggle('Create Category', 'Name', 'Create', 'Category Name')
                      }
                      className={styles.addBtn}
                    >
                      {'+'}
                    </Button>
                  </div>
                </Form.Group>
              </div>
            ) : (
              <div className={styles.cardParticularValue}>
                {values.category && values.category.length > 0
                  ? values.category.map((c) => c.label).join(', ')
                  : 'No Category'}
              </div>
            )}
          </div>
          {values.isPersonalEdit ? (
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.updateBtn}
                type='submit'
                disabled={isSubmitting || !isEmpty(this.props?.errors) || !values?.isValidPhone}
              >
                {'Update'}
              </Button>
              <Button
                className={styles.cancelBtn}
                onClick={() => {
                  setFieldValue('isPersonalEdit', !values.isPersonalEdit);
                  window.location.reload(true);
                }}
              >
                {'Cancel'}
              </Button>
            </div>
          ) : null}
        </Form>
      </Card>
    );
  }
}
