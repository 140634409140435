import * as Yup from 'yup';
import {
  ADC_ALWAYS_APPROVE,
  ADC_APPROVAL_REQUIRED,
  ADC_APPROVE_TILL_LIMIT,
  ADC_CUSTOM_CONFIG
} from '../../../../utils/commonConstant';

const validationSchema = Yup.object().shape({
  pass_through: Yup.object().shape({
    type: Yup.mixed()
      .oneOf([ADC_ALWAYS_APPROVE, ADC_APPROVAL_REQUIRED, ADC_APPROVE_TILL_LIMIT, ADC_CUSTOM_CONFIG])
      .required('Select One'),
    amount_limit: Yup.number().when('type', {
      is: ADC_APPROVE_TILL_LIMIT,
      then: Yup.number('Approve amount is required')
        .min(1, 'Invalid amount')
        .max(50000, '50,000 is the maximum amount allowed')
        .min(500, '500 is the minimum amount allowed')
        .required('Approve amount is required')
    })
  }),
  international: Yup.object().shape({
    type: Yup.mixed()
      .oneOf([ADC_ALWAYS_APPROVE, ADC_APPROVAL_REQUIRED, ADC_CUSTOM_CONFIG])
      .required('Select One')
  })
});

export default validationSchema;
