import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import validationSchema from './LoginForm.validation';
import { errToastMessage, getErrorMessage } from '../../utils/utilities';

import { login, googleMicrosoftLogin } from '../../actions/authentication';
import {
  EXPIRED_PASSWORD_ERROR_TEXT,
  PASSWORD_NOT_SET_ERROR_TEXT,
  INACTIVE_USER_ERROR_TEXT
} from '../../utils/commonConstant';

const intialValues = {
  email: '',
  password: '',
  type: 'company',
  loginError: ''
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data, type) => dispatch(login(data, type)),
    googleMicrosoftLogin: (path, data) => dispatch(googleMicrosoftLogin(path, data))
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => intialValues,
    validationSchema,
    handleSubmit: (values, { setStatus, setFieldValue, setSubmitting, props }) => {
      setFieldValue('loginError', '');
      props
        .login(values)
        .then((response) => {
          setSubmitting(false);
          const { location } = props;
          sessionStorage.clear();
          if (location.state && location.state.from && location.state.from.pathname !== '/logout') {
            props.history.push(location.state.from);
          }
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.message;


          switch (true) {
            case errorMessage?.includes(INACTIVE_USER_ERROR_TEXT):
              setFieldValue('loginError', errorMessage);
              break;
            case errorMessage?.includes('Your current password is expired.'):
              setFieldValue('loginError', EXPIRED_PASSWORD_ERROR_TEXT);
              break;
            case errorMessage?.includes('Password has not been set for this account.'):
              setFieldValue('loginError', PASSWORD_NOT_SET_ERROR_TEXT);
              break;
            case error?.response?.data?.body && !error?.response?.data?.body?.login_method_password:
              setFieldValue('loginError', getErrorMessage(error));
              break;
          }

          errToastMessage(error);
          setSubmitting(false);
        });
    },
    displayName: 'LoginForm'
  })
);
