import { connect } from 'react-redux';

import { getCompanyPaymentDetails, getTags, INVALIDATE_TAGS } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    tags: state.tags
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTags: (limit, offset) => {
      return dispatch(getTags(limit, offset));
    },
    invalidateTags: () => dispatch({ type: INVALIDATE_TAGS }),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export { mapDispatchToProps, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps);
