import React from 'react';
import Image from '../Image';
import styles from './Banner.module.scss';

export default (props) => {
  return (
    <div
      className={
        props.clsName
          ? `${styles[props.clsName]}`
          : `${styles.bannerContainer} ${styles.textCenter}`
      }
    >
      <Image
        name={props.name ? props.name : 'warningPackage.svg'}
        isSA={props.isSA}
        className={styles.image}
        style={{ marginTop: props.margin }}
      />{' '}
      {props.children}
    </div>
  );
};
