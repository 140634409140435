import React from 'react';
import AddBulk from '../frontend-common/AddBulkCandidate';
import Header from '../Header';
import Footer from '../Footer';
export default (props) => {
  return (
    <>
      <AddBulk isCA={true} {...props} />
    </>
  );
};
