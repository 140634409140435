import { connect } from 'react-redux';

import { getCandidates, invalidateCandidates } from '../../actions/company';
import { logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {
    candidates: state.candidates,
    profile: state.profile,
    paymentDetails: state.paymentDetails,
    packageType:
      state?.profile?.profile?.company_user_mapping?.company?.payments_company_model_config
        ?.model_type
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCandidates: () => dispatch(invalidateCandidates()),
    getCandidates: (limit, offset) => dispatch(getCandidates(limit, offset)),
    logout: () => dispatch(logout())
  };
};

export { mapDispatchToProps, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps);
