import React, { useState } from 'react';
import RecommendedChecksModal from './RecommendedChecksModal';
import UpsellSuccessModal from './UpsellSuccessModal';

const UpsellModals = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showRecommendedChecksModal, setShowRecommendedChecksModal] = useState(true);

  const toggleSuccessModal = () => {
    setShowSuccessModal(true);
    setShowRecommendedChecksModal(false);
  };

  const onSubmitHandler = () => {
    setShowSuccessModal(false);
    props?.getRecommendationsChecksApi();
    props.onClose();
  };

  return (
    <div>
      <RecommendedChecksModal
        show={showRecommendedChecksModal}
        toggleSuccessModal={toggleSuccessModal}
        upsellRecommendationId={props?.upsellRecommendationId}
        onClose={props.onClose}
        checksList={props?.checksList}
      />
      <UpsellSuccessModal show={showSuccessModal} onClose={onSubmitHandler} />
    </div>
  );
};

export default UpsellModals;
