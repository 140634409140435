import React from 'react';
import Content from './images/content.png';
import SideBar from './images/sidebar.png';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import styles from './AlumniVerification.module.scss';

const Mockup = ({
  isLogoEnabled,
  isBrandNameEnabled,
  isQuoteEnabled,
  legalName,
  brandName,
  brandQuote,
  brandLogo,
  titleBgColor,
  titleTextColor
}) => {
  return (
    <div style={{ height: '338px', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div
        className='header'
        style={{
          background: titleBgColor,
          height: '42px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '5px',
          color: titleTextColor
        }}
      >
        <div className='left' style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MdKeyboardArrowLeft
              style={{
                color: titleTextColor,
                fontSize: '10px',
                marginLeft: '10px'
              }}
            />
            DASHBOARD
          </div>
          {isLogoEnabled && brandLogo && (
            <div className='logo' style={{ marginLeft: '10px', height: '70%' }}>
              <img src={brandLogo} alt='logo' style={{ height: '100%', width: 'auto' }} />
            </div>
          )}
          {isBrandNameEnabled && (
            <div
              className='title'
              style={{ marginLeft: '4px', fontSize: '8px', display: 'flex', alignItems: 'center' }}
            >
              {brandName}
            </div>
          )}
        </div>
        <div className='right' style={{ marginRight: '18px', fontSize: '8px' }}>
          {legalName}
        </div>
      </div>
      <div
        className='body'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '296px'
        }}
      >
        <div className='leftBar' style={{ padding: '10px 15px' }}>
          <img
            src={Content}
            alt='content'
            style={{
              height: '224px',
              width: 'auto',
              maxWidth: '100%',
              objectFit: 'contain'
            }}
          />
          <hr className={styles.hr33} />
          {isQuoteEnabled && (
            <>
              <div
                className='quote'
                style={{ fontSize: '7px', fontStyle: 'italic', textAlign: 'left' }}
              >
                "{brandQuote}"
              </div>
              <div className='hr' style={{ fontSize: '8px', fontWeight: '400', textAlign: 'left' }}>
                - {isBrandNameEnabled && brandName ? brandName : legalName} HR
              </div>
            </>
          )}
        </div>

        <div className='rightBar' style={{ height: '100%' }}>
          <img
            src={SideBar}
            alt='sidebar'
            style={{
              height: '100%',
              width: 'auto',
              objectFit: 'contain'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Mockup;
