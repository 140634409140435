// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MM8poDqWzjWj98OuM5BF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.5rem;
}
.MM8poDqWzjWj98OuM5BF .uTVzHHhCPF25VHXVHLqa {
  display: flex;
}
.MM8poDqWzjWj98OuM5BF .u8MycsGZTY__HjbnFHsR {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins, sans-serif;
  color: #333333;
  cursor: pointer;
  padding: 24px 16px 24px 16px;
}
.MM8poDqWzjWj98OuM5BF .u8MycsGZTY__HjbnFHsR:first-child {
  padding-left: 24px;
}
.MM8poDqWzjWj98OuM5BF .u8MycsGZTY__HjbnFHsR .aSIZwcT6HtHl82_cCaUs {
  color: #2755fe;
  border-bottom: 3px solid #2755fe;
  padding-bottom: 6px;
}

.VZ9bzotQ2U0RvcibemuQ {
  background: white;
  border: 1px solid #2755fe;
  color: #2755fe;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/TabComponent/TabComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,qBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,4BAAA;AAEJ;AAAI;EACE,kBAAA;AAEN;AACI;EACE,cAAA;EACA,gCAAA;EACA,mBAAA;AACN;;AAIA;EACE,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;AADF","sourcesContent":[".tabComponentWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-right: 1.5rem;\n  .tabContainer {\n    display: flex;\n  }\n  .tabStyles {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    font-family: Poppins, sans-serif;\n    color: #333333;\n    cursor: pointer;\n    padding: 24px 16px 24px 16px;\n\n    &:first-child {\n      padding-left: 24px;\n    }\n\n    .tabActive {\n      color: #2755fe;\n      border-bottom: 3px solid #2755fe;\n      padding-bottom: 6px;\n    }\n  }\n}\n\n.alumniBtn {\n  background: white;\n  border: 1px solid #2755fe;\n  color: #2755fe;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabComponentWrapper": `MM8poDqWzjWj98OuM5BF`,
	"tabContainer": `uTVzHHhCPF25VHXVHLqa`,
	"tabStyles": `u8MycsGZTY__HjbnFHsR`,
	"tabActive": `aSIZwcT6HtHl82_cCaUs`,
	"alumniBtn": `VZ9bzotQ2U0RvcibemuQ`
};
export default ___CSS_LOADER_EXPORT___;
