import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAlumniTablesData } from '/src/actions/company';

const mapStateToProps = (state) => {
  return {
    dataSyncLogs: state?.alumniTableData?.rows || {},
    dataSyncLogsCount: state?.alumniTableData?.count || 0
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataSyncLogsData: (data) => dispatch(getAlumniTablesData(data, 'DATA_SYNC'))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
