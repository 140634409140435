import React from 'react';
import { useState } from 'react';
import Footer from '../../components/CompanyAdmin/Footer';
import Header from '../../components/CompanyAdmin/Header';
import styles from './MainLayout.module.scss'; ///

export default (props) => {
  const { children } = props;
  const [zoomLevel, setZoomLevel] = useState(Math.round(window.devicePixelRatio * 100));

  window.addEventListener('resize', () => {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    setZoomLevel(browserZoomLevel);
  });

  const getClassName = () => {
    if (zoomLevel >= 50) {
      return styles.footerDivHeight25;
    } else if (zoomLevel >= 33) {
      return styles.footerDivHeight47;
    } else {
      return styles.footerDivHeight80;
    }
  };
  return (
    <>
      <div className={styles.headerDiv}>
        <Header historyProps={children?.props} pageTitle={props?.pageTitle} />
      </div>
      <div className={styles.middleDiv}>{children}</div>
      {/* <div className={`${styles.footerDiv} ${getClassName()}`}>
        <Footer />
      </div> */}
    </>
  );
};
