import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import moment from 'moment';

import Image from '../../../../../../core-components/Image';

import { obj } from '../../../../../../utils/commonConstant';

import styles from './EducationSaved.module.scss';
import { isAllStepsCompleted } from '../EducationHelper';

export default ({
  item,
  index,
  showhr,
  handleEducationDelete,
  handleEducationEdit,
  isSpecific
}) => {
  const [showBadge, setShowBadge] = useState(false);
  const wrapperRef = useRef(null);

  const { step1 } = item;
  const { datesAttended, educationLevel } = step1;

  let startDate = moment(datesAttended.startDate, 'MM/YYYY', true).isValid()
    ? moment(datesAttended.startDate, 'MM/YYYY').format('MMM YYYY')
    : 'Invalid Date';
  let endDate = moment(datesAttended.endDate, 'MM/YYYY', true).isValid()
    ? moment(datesAttended.endDate, 'MM/YYYY').format('MMM YYYY')
    : 'Invalid Date';
  const handleDeleteBadge = (index) => {
    handleEducationDelete(index);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowBadge(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  return (
    <>
      {!isSpecific && showhr && <hr />}

      <Row className={styles.savedEducationInfo} style={isSpecific ? { marginTop: 0 } : null}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col lg={9} md={9} sm={9} xs={9}>
              {!isSpecific && <p className={styles.educationLevel}>{obj[step1.educationLevel]}</p>}
              <p className={styles.universityName}>{step1.universityName}</p>
              <p className={styles.timePeriod}>
                {startDate} - {endDate}
              </p>
            </Col>
            <Col lg={3} md={3} sm={3} xs={3} className={styles.actionBtnContainer}>
              {showBadge ? (
                <Badge
                  onClick={() => {
                    handleDeleteBadge(index);
                  }}
                  pill
                  variant='danger'
                  className={styles.deleteBadge}
                  data-testid='deleteBadge'
                  ref={wrapperRef}
                >
                  {'Delete?'}
                </Badge>
              ) : (
                <>
                  <Image
                    className={styles.editImage}
                    data-testid='editImage'
                    name='BgvIdEdit.png'
                    onClick={() => handleEducationEdit(index, educationLevel)}
                  />
                  <Image
                    className={styles.deleteImage}
                    name='BgvIdDelete.png'
                    data-testid='deleteImage'
                    onClick={() => {
                      setShowBadge(true);
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
          {!isAllStepsCompleted(item) && (
            <span className={styles.incompleteText}>
              Please provide complete details for this Education
            </span>
          )}
        </Col>
      </Row>

      {isSpecific && showhr && <hr />}
    </>
  );
};
