import React from 'react';
import styles from './addManualModal.module.scss';
import { GrFormClose } from 'react-icons/gr';
import Single from './images/single.png';
import Bulk from './images/bulk.png';

const AddManualModal = ({ onHide, onAddBulk, onAddSingle }) => {
  return (
    <div className={styles['modal-overlay']}>
      <div className={styles.modalContainer}>
        <div className={styles.close} onClick={onHide}>
          <GrFormClose />
        </div>
        <div className={styles.header}>
          <div className={styles.title}>Add Manually</div>
        </div>

        <div className={styles.body}>
          <div className={styles.box} onClick={onAddSingle}>
            <div className={styles.boxIcon}>
              <img src={Single} />
            </div>
            <div className={styles.boxText}>Add Single Record</div>
            <div className={styles.boxSubText}>Add details for one record at a time</div>
          </div>
          <div className={styles.orText}>Or</div>
          <div className={styles.box} onClick={onAddBulk}>
            <div className={styles.boxIcon}>
              <img src={Bulk} />
            </div>
            <div className={styles.boxText}>Upload in Bulk</div>
            <div className={styles.boxSubText}>Add multiple records through sheet</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManualModal;
