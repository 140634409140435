import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppHelper from '../core-components/AppHelper';
import AppConstants from '../core-components/AppConstants';
import React from 'react';
import { LogLevel } from '@azure/msal-browser';
const microsoftEnvironmentConstant = require('../config/microsoft-auth.json');

const msalConfig = {
  auth: {
    clientId: microsoftEnvironmentConstant[AppHelper.getEnv()].clientId,
    authority: microsoftEnvironmentConstant[AppHelper.getEnv()].authority,
    redirectUri: `${AppConstants.baseURL}ms-redirect`,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const instance = new PublicClientApplication(msalConfig);

export default function MsAuthProvider({ children }) {
  return <MsalProvider instance={instance}>{children}</MsalProvider>;
}
