import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { convertToIndianNumeration } from '../../../core-components/Utilities/utilities';
import styles from './Tiers.modules.scss';
const Tiers = ({
  item,
  handleReduce,
  handleIncrease,
  selectedTiers,
  packageId,
  index,
  firstOption
}) => {
  const [isTierActive, setIsTierActive] = useState(false);
  const getDiscountPercentage = (item) => {
    let discount = 0;
    let originalPrice = firstOption?.costPerCandidate * item?.candidateCount;
    discount =
      (100 * (originalPrice - item?.candidateCount * item?.costPerCandidate)) / originalPrice;
    return Math.round(discount.toFixed(2));
  };
  const isTierIncluded = () => {
    if (
      selectedTiers &&
      selectedTiers[packageId] &&
      selectedTiers[packageId].findIndex((a) => a.id === item.id) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getTierCount = () => {
    return (
      (selectedTiers &&
        selectedTiers[packageId] &&
        selectedTiers[packageId].filter((a) => a.id === item.id).length) ||
      0
    );
  };
  return (
    <Card
      className={`${styles.cardContainer} ${isTierIncluded() ? styles.cardActive : ''}`}
      onMouseEnter={() => setIsTierActive(true)}
      onMouseLeave={() => setIsTierActive(false)}
    >
      <Card.Body className={styles.cardBodyContainer}>
        <div className='d-flex flex-row justify-content-between'>
          <span className={styles.title}>{`${convertToIndianNumeration(
            item.candidateCount
          )} candidates`}</span>
          <div
            className={isTierActive || isTierIncluded() ? styles.tierActive : styles.tierInactive}
          >
            <span className={`${styles.tierButton} mr-2`} onClick={handleReduce}>
              -
            </span>
            <span className={styles.tierCountButton}>{getTierCount()}</span>
            <span className={`${styles.tierButton} ml-2`} onClick={handleIncrease}>
              +
            </span>
          </div>
        </div>
        <div className={`${styles.subtitle} mt-2`}>
          <span className={styles.amountText}>
            &#8377;{convertToIndianNumeration(item.costPerCandidate)}
          </span>
          &nbsp;&nbsp;{'per candidate'}
        </div>
        <div className='d-flex justify-content-end'>
          {getDiscountPercentage(item) !== 0 && index !== 0 ? (
            <span className={styles.discountTag}>{getDiscountPercentage(item)}% off</span>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Tiers;
