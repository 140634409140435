// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fqu1UAsKXuHtY_dfeSCO {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  padding: 20px 24px;
  height: 110px;
  margin-bottom: 24px;
  font-family: Poppins, sans-serif;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 {
  width: 875px;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 .tqrI4GqcGztdZnF0qSqv {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 ._uNtALMhGA2V5OvyxrvA {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .EFAPPSbr3bqM6y6AzLN4 ._uNtALMhGA2V5OvyxrvA .QohbFAnwQG3CnlvYa68G {
  font-weight: 500;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .azPRmGta3BrBKIy3gD0H {
  color: #2755fe;
  font-size: 16px;
  font-weight: 500;
}
.Fqu1UAsKXuHtY_dfeSCO .jmisobRmNQXgJ76To2Ft .azPRmGta3BrBKIy3gD0H:hover {
  cursor: pointer;
}

.bQ0okiIozaVmdtAbK3mr {
  max-width: 653px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/AlumniVerification/AlumniUrlComponent/AlumniUrlComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gDAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gCAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEJ;AADI;EACE,YAAA;AAGN;AAFM;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAIR;AAFM;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AAIR;AAHQ;EACE,gBAAA;AAKV;AADI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAGN;AAFM;EACE,eAAA;AAIR;;AACA;EACE,gBAAA;AAEF","sourcesContent":[".urlContainerWrapper {\n  background: #ffffff;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  padding: 20px 24px;\n  height: 110px;\n  margin-bottom: 24px;\n  font-family: Poppins, sans-serif;\n  .containerFlex {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    .urlItem {\n      width: 875px;\n      .urlText {\n        color: #333;\n        font-size: 18px;\n        font-weight: 500;\n        margin-bottom: 12px;\n      }\n      .urlNote {\n        color: #333;\n        font-size: 14px;\n        font-weight: 400;\n        .noteText {\n          font-weight: 500;\n        }\n      }\n    }\n    .customizeItem {\n      color: #2755fe;\n      font-size: 16px;\n      font-weight: 500;\n      &:hover {\n        cursor: pointer;\n      }\n    }\n  }\n}\n.textWithCopyButton {\n  max-width: 653px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"urlContainerWrapper": `Fqu1UAsKXuHtY_dfeSCO`,
	"containerFlex": `jmisobRmNQXgJ76To2Ft`,
	"urlItem": `EFAPPSbr3bqM6y6AzLN4`,
	"urlText": `tqrI4GqcGztdZnF0qSqv`,
	"urlNote": `_uNtALMhGA2V5OvyxrvA`,
	"noteText": `QohbFAnwQG3CnlvYa68G`,
	"customizeItem": `azPRmGta3BrBKIy3gD0H`,
	"textWithCopyButton": `bQ0okiIozaVmdtAbK3mr`
};
export default ___CSS_LOADER_EXPORT___;
