// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g3jtaKKwGDzKbUtYissU {
  border-color: #ff0000 !important;
  color: #c2cfff !important;
}

.T9lAIXOoANmnUGbH6QsY {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #666666 !important;
}

.mc3VF2BNF7i5jYtESy9s {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.uE9ogXftIXuzQqO4_w2o {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/CustomSelect/CustomSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,gCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AACA;EACE,YAAA;AAEF","sourcesContent":[".error {\n  border-color: #ff0000 !important;\n  color: #c2cfff !important;\n}\n\n.normal {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #666666 !important;\n}\n\n.placeholder {\n  font-family: Poppins, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 21px;\n  color: #333333;\n}\n.searchIcon {\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `g3jtaKKwGDzKbUtYissU`,
	"normal": `T9lAIXOoANmnUGbH6QsY`,
	"placeholder": `mc3VF2BNF7i5jYtESy9s`,
	"searchIcon": `uE9ogXftIXuzQqO4_w2o`
};
export default ___CSS_LOADER_EXPORT___;
