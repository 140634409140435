import React from 'react';
import Form from 'react-bootstrap/Form';

import styles from './CustomValidatedRadioFields.module.scss';

export default ({ field, form, label, options, ...props }) => {
  return (
    <Form.Group controlId={field && field.name} {...props}>
      {label && <Form.Label className={styles.question}>{label}</Form.Label>}

      {options &&
        options.map((option, i) => {
          return (
            <Form.Check
              className={styles.option}
              custom
              key={i}
              type='radio'
              id={field.name + option.value}
            >
              <Form.Check.Input
                data-testid='radio-btn'
                type='radio'
                onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                value={option.value}
                checked={String(option.value) === field.value}
              />
              <Form.Check.Label>
                <span className={String(option.value) === field.value ? styles.boldLabel : ''}>
                  {option.label}
                </span>
                {option.additionalLabel ? (
                  <span className={i === 1 ? styles.firstListItem : styles.listItem}>
                    <span className={styles.listItemText}>{option.additionalLabel}</span>
                  </span>
                ) : null}
              </Form.Check.Label>
            </Form.Check>
          );
        })}
    </Form.Group>
  );
};
