import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { DropdownList } from 'react-widgets';
import Image from '../Image';
import Input from '../Input/Input';
import { getTrimmedValue } from '../Utilities/utilities';
import styles from './SearchFieldWithDropdown.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';

export default function SearchFieldWithDropdown({ dropdownValues, onChange, isSA }) {
  const [searchTerm, setIsSearchTerm] = useState('');
  const [dropdownTerm, setIsDropdownTerm] = useState(dropdownValues[0].label || 'Select');

  const debounceApplySearch = useCallback((e, dropdownTerm) => {
    setIsSearchTerm(e.target.value || '');
    const dropdownValue = dropdownValues.find((item) => item.label === dropdownTerm);
    return applySearch(getTrimmedValue(e.target.value || '', true), dropdownValue.value || '');
  }, []);

  const applySearch = debounce((value, dropdownTerm) => {
    onChange(getTrimmedValue(value, true), dropdownTerm);
    return;
  }, 500);

  return (
    <div className={styles.searchFilter}>
      <div className={styles.searchFilterType}>
        <DropdownList
          data={dropdownValues}
          placeholder={<span className={styles.dropDownPlaceholder}>Select</span>}
          containerClassName={cn(
            styles.dropDownStyle,
            styles.dropDownBorderNone,
            'reactWidgetCustomDp'
          )}
          value={dropdownTerm}
          textField='label'
          name='searchType'
          selectIcon={<Image isSA={isSA} name='Mask.svg' />}
          onChange={(e) => {
            if (searchTerm) {
              onChange();
            }
            setIsSearchTerm('');
            setIsDropdownTerm(e.label);
          }}
        />
      </div>
      <div className={styles.searchInput}>
        <Image isSA={isSA} style={{ width: '20px' }} name='searchInputIcon.svg' />
        <Input
          placeholder={`Search by ${dropdownTerm}`}
          className={styles.inputText}
          value={searchTerm}
          onChange={(e) => {
            debounceApplySearch(e, dropdownTerm);
          }}
        />
        {searchTerm ? (
          <Image
            isSA={isSA}
            onClick={() => {
              setIsSearchTerm('');
              onChange(null);
            }}
            style={{ width: '20px' }}
            name='closeIcon.svg'
          />
        ) : null}
      </div>
    </div>
  );
}

SearchFieldWithDropdown.propTypes = {
  dropdownValues: PropTypes.array,
  onChange: PropTypes.func
};

SearchFieldWithDropdown.defaultProps = {
  dropdownValues: [{ label: '', value: '' }],
  onChange: () => {}
};
