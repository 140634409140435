import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './FormSubmitSpinner.css';

export default function FormSubmitSpinner(props) {
  return (
    <div className={'loader-wrapper'}>
      <Spinner animation='border' {...props} />
    </div>
  );
}
