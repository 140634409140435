// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t0a0XMJzjlb3QFEsuyIv {
  text-align: center;
  padding-top: 64px;
}
.t0a0XMJzjlb3QFEsuyIv .eP_l9qoTzPgE2xxtZYtd {
  padding-top: 16px;
  padding-bottom: 94px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/EmptyTableBody/EmptyTableBody.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;AACE;EACE,iBAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".noDataWrapper {\n  text-align: center;\n  padding-top: 64px;\n\n  .noDataContent {\n    padding-top: 16px;\n    padding-bottom: 94px;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    color: #666666;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noDataWrapper": `t0a0XMJzjlb3QFEsuyIv`,
	"noDataContent": `eP_l9qoTzPgE2xxtZYtd`
};
export default ___CSS_LOADER_EXPORT___;
