import * as Yup from 'yup';
import {
  getNameValidationSchema,
  getURLValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  company_url: getURLValidationSchema({ isRequired: true }),
  city: getNameValidationSchema({ message: 'city', isRequired: true })
});
