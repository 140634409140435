import * as Yup from 'yup';
import { getTrimmedValue } from '../../../core-components/Utilities/utilities';

export default Yup.object().shape({
  friendlyName: Yup.string()
    .transform((currentValue) => getTrimmedValue(currentValue))
    .required('Field cannot be empty')
    .min(1, 'Min. character limit is 1')
    .max(20, 'Max. character limit is 20')
});
