import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import NoRecords from '../NoRecords';
import Image from '../../../../core-components/Image';
import { CHECK_STATUSES } from '../../../../utils';

import SelectedChecks from '../SelectedChecks';

import styles from './Overview.module.scss';
import { getStatus } from '../../../../utils/utilities';
export default class extends React.Component {
  state = {
    IDENTITY_TYPE: {
      1: 'Any',
      2: 'PAN Card',
      3: 'Driving Licence',
      4: 'Passport',
      5: 'Voter ID',
      6: 'Aadhaar'
    },
    isData: null,
    isCheckModalOpen: false,
    addressData: [],
    employmentData: []
  };

  componentDidMount() {
    let candidate = this.props?.candidate;
    if (
      candidate?.candidatesIds?.length === 0 &&
      candidate?.candidateDrugs?.length === 0 &&
      candidate?.candidatesAddresses?.length === 0 &&
      candidate?.candidatesCreditchecks?.length === 0 &&
      candidate?.candidatesEmployments?.length === 0 &&
      candidate?.candidatesEducations?.length === 0 &&
      candidate?.candidatesWorldchecks?.length === 0 &&
      candidate?.candidateHistories?.length === 0 &&
      candidate?.candidatesRefchecks?.length === 0
    ) {
      this.setState({
        isData: false
      });
    } else {
      this.setState({
        isData: true
      });
    }
    this.setState({
      addressData: this?.props?.candidate?.candidatesAddresses?.filter(
        (value) => value.status !== 11
      ),
      employmentData: this?.props?.candidate?.candidatesEmployments?.filter(
        (value) => value.status !== 11
      )
    });
  }
  render() {
    const completedStatusArray = [1, 2, 12];
    return (
      <>
        <div className={styles.overViewContainer}>
          <Row>
            <Col md={9} lg={9} sm={9} xs={9} className={styles.overviewHeading}>
              {'Overview'}
            </Col>
            <Col md={3} lg={3} sm={3} xs={3}>
              <Button
                variant='light'
                className={styles.buttonText}
                onClick={() => {
                  this.setState({
                    isCheckModalOpen: true
                  });
                }}
              >
                Checks selected
              </Button>
            </Col>
          </Row>
          {this.state.isData === false ? (
            <NoRecords />
          ) : (
            <div className={styles.overviewContent}>
              {this.props?.candidate?.candidatesIds?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'identityIconHover.svg'} />
                    {'Identity'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this.props?.candidate?.candidatesIds?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);
                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>{this.state.IDENTITY_TYPE[value?.type]}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.props?.candidate?.candidateHistories?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'courtRecordIconHover.svg'} />
                    {'Court Record'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidateHistories?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);
                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>
                            {value?.candidatesAddress?.documentType}
                          </div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {this?.state?.addressData?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'addressIconHover.svg'} />
                    {'Address'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidatesAddresses?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);
                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>
                            {value?.type === 'permanent_address'
                              ? 'Permanent Address'
                              : value.type === 'current_address'
                              ? 'Current Address'
                              : value.type}
                          </div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.props?.candidate?.candidatesEducations?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'educationIconHover.svg'} />
                    {'Education'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidatesEducations?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);

                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>{value?.instituteName}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this?.state?.employmentData?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'employmentIconHover.svg'} />
                    {'Employment'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidatesEmployments?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);

                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>{value?.companyName}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.props?.candidate?.candidatesRefchecks?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'referenceIconHover.svg'} />
                    {'Reference'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidatesRefchecks?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);

                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>{value?.employerName}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.props?.candidate?.candidatesWorldchecks?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'worldIconHover.svg'} />
                    {'World'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidatesWorldchecks?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);

                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>{value?.candidateName}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.props?.candidate?.candidatesCreditchecks?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'cibilCreditScoreIconHover.svg'} />
                    {'Credit Check'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidatesCreditchecks?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);

                      return (
                        <div className={styles.cardContent}>
                          <div className={styles.type}>{'PAN Card'}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {this.props?.candidate?.candidateDrugs?.length !== 0 ? (
                <div className={styles.verificationCard}>
                  <div className={styles.cardHeader}>
                    <Image name={'drugIconHover.svg'} />
                    {'Drug'}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    {this?.props?.candidate?.candidateDrugs?.map((value, index) => {
                      let { status } = value;
                      status = getStatus(status);
                      return (
                        <div className={styles.cardContent}>
                          {/* <div className={styles.type}>{value?.panelType}</div> */}
                          <div className={styles.type}>{value?.panelType}</div>
                          <div
                            className={styles.status}
                            style={{ color: CHECK_STATUSES.values[status].color }}
                          >
                            <svg
                              width='8'
                              height='8'
                              viewBox='0 0 8 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                cx='4'
                                cy='4'
                                r='4'
                                fill={CHECK_STATUSES.values[status].color}
                              />
                            </svg>
                            {CHECK_STATUSES.values[status].value}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
        {this.state.isCheckModalOpen ? (
          <SelectedChecks
            show={this.state.isCheckModalOpen}
            packageDetails={this.props?.packageDetails}
            onHide={() => {
              this.setState({
                isCheckModalOpen: false
              });
            }}
          />
        ) : null}
      </>
    );
  }
}
