import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';
import Modal from 'react-bootstrap/Modal';

import Image from '../../../../../core-components/Image';
import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import countryNames from '../../../../../utils/countryNamesEn.json';

import styles from './CountryCodesModal.module.scss';

export default (props) => {
  const { handleSubmit } = props;
  const [filteredInput, setFilteredInput] = useState(countryNames);

  const handleChange = (value) => {
    if (value) {
      let result = countryNames.filter(
        (country) =>
          country.label.toLowerCase().includes(value.toLowerCase()) ||
          country.value.toLowerCase().includes(value.toLowerCase())
      );
      if (result.length > 0) {
        setFilteredInput(result);
      } else {
        setFilteredInput(null);
      }
    } else {
      setFilteredInput(countryNames);
    }
  };
  const handleClick = (value) => {
    let result;
    if (value) {
      result = countryNames.filter((country) => country.value === value);
      if (result) {
        props.handleCodeChange(props, result[0]);
        setFilteredInput(countryNames);
        props.SubOnHide();
      }
    }
  };
  const handleClose = () => {
    setFilteredInput(countryNames);
    props.SubOnHide();
  };

  return (
    <Modal
      size='lg'
      show={props.subShow}
      onHide={(e) => handleClose()}
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      dialogClassName='countryCodesModal'
      backdropClassName={'customBGVModalBackdrop'}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{props.modalLabel}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className={styles.searchInput}>
            <Image className={styles.searchIcon} name='searchInputIcon.svg' />
            <Field
              type='text'
              autoCorrect='off'
              autoComplete='off'
              name='countryCode'
              className={styles.countryCodeSearch}
              component={ValidatedFormInputField}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          {filteredInput ? (
            filteredInput.map((data, index) => {
              return (
                <div
                  className={
                    props.defaultValue === data.value
                      ? styles.selectedCountryName
                      : styles.countryName
                  }
                  key={index}
                  onClick={(e) => handleClick(e.target.getAttribute('data-countrycode'))}
                  data-countrycode={data.value}
                >
                  {data.label + '(' + data.value + ')'}
                </div>
              );
            })
          ) : (
            <div className={styles.noResults}>
              <Image className={styles.noResultsImg} name='noResults.svg' />
              {'No results found'}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Form>
    </Modal>
  );
};
