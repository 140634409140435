// React and other libraries imports
import React, { useCallback, useEffect, useState } from 'react';
import { Card, FormControl } from 'react-bootstrap';
import { Field, FieldArray, Form, getIn } from 'formik';
import cn from 'classnames';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import { debounce } from 'lodash';

// Core components and utils imports
import Image from '../../../../../core-components/Image';
import {
  convertToIndianNumeration,
  getTrimmedValue,
  validateEmail
} from '../../../../../utils/utilities';
import { REFER_SOURCE } from '../../../../../utils/commonConstant';
import Checkbox from '../../../../../core-components/Checkbox';
import MobileInput from '../../../../CompanyAdmin/ReferAndEarn/Form/MobileInput/MobileInput';
import store from '../../../../../store';
import { skipHrReferral } from '../../../../../actions/bgv';
import { referralEmailValidate } from '../../../../../api/bgv';
import { checkEmailValidation } from '../../../../../utils/ValidationSchema';

// Styles import
import styles from './ReferAndEarn.module.scss';

const ReferAndEarn = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    props.getBgvCandidateDetails();
  }, []);
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isValid,
    handleSubmit,
    setFieldValue
  } = props;
  const handleSkip = () => {
    if (!isValid) {
      store.dispatch(skipHrReferral());
    }
  };

  const checkEmailExistance = debounce((email, index) => {
    if (checkEmailValidation(email)) {
      referralEmailValidate(email)
        .then((res) => {
          const resp = res.data;
          if (resp.isDomainRegistered || resp.isReferralExists) {
            setFieldValue(`contacts[${index}].email_existance`, true);
          } else {
            setFieldValue(`contacts[${index}].email_existance`, false);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    } else {
      setFieldValue(`contacts[${index}].email_existance`, false);
      setIsLoading(false);
    }
  }, 100);
  const debounceEmailInput = useCallback((email, index) => {
    setIsLoading(true);
    return checkEmailExistance(email, index);
  }, []);
  const emailExistanceError = () => {
    return values.contacts.some((val) => val.email_existance === true);
  };
  const emailDuplicateError = () => {
    return values.contacts.some((val) => val.email_duplicate === true);
  };
  const checkDuplicateEmail = (index, email) => {
    if (email === '') {
      return;
    }
    const emailValues = values.contacts
      .map((v) => v.email_id)
      .filter((email, emailIndex) => emailIndex !== index);
    const duplicateError = emailValues.includes(email);
    if (duplicateError) {
      setFieldValue(`contacts[${index}].email_duplicate`, true);
    } else {
      values.contacts.map((email, ind) => {
        return setFieldValue(`contacts[${ind}].email_duplicate`, false);
      });
    }
  };
  const hasEmptyEmailOrMobile = values.contacts.some(
    (contact) => !contact.email_id || !contact.phone_number
  );

  const invalidForm = () => {
    return (
      !isValid ||
      values.contacts.length === 0 ||
      emailExistanceError() ||
      emailDuplicateError() ||
      isLoading ||
      props.isSubmitting ||
      hasEmptyEmailOrMobile
    );
  };
  const [emailFocusedIndex, setEmailFocusedIndex] = useState(null);

  return (
    <>
      <Card className='col-lg-12'>
        <Card.Body className={styles.referCardBody}>
          <span className={styles.pageHeading}>You're almost done here!</span>
          <div className={styles.pageBanner}>
            <div className={styles.pageItemContainer}>
              <div>
                <div className={styles.iconContainerRound}>
                  <Image className={styles.icon} name='Announcement.svg' />
                </div>
                <div className=''>
                  <span className={styles.amountText}>Get &nbsp;</span>
                  <span className={styles.rupee}>&#8377;</span>
                  <span className={styles.amount}>{convertToIndianNumeration(1000)}</span>
                </div>
                <div>
                  <span className={styles.bannerSubtitle}>for every HR you refer!</span>
                </div>
              </div>
              <div className={styles.giftboxContainer}>
                <Image className={styles.iconGiftbox} name='giftbox.svg' />
              </div>
              <div className={styles.stepCardContainer}>
                <div className={styles.stepCard}>
                  <div className={styles.cardText}>
                    <span className={styles.cardHeader}>Step 1</span>
                    <span className={styles.cardDetails}>Refer your HR connects to us</span>
                  </div>
                  <div>
                    <Image className={styles.stepIcon} name='referperson.svg' />
                  </div>
                </div>
                <div className={styles.stepCard}>
                  <div className={styles.cardText}>
                    <span className={styles.cardHeader}>Step 2</span>
                    <p className={styles.cardDetails}>
                      Win <span className={styles.rupeeText}>₹</span>1,000 for every potential
                      customer
                    </p>
                  </div>
                  <div>
                    <Image className={styles.stepIcon} name='giftcard.svg' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.referForm}>
            <Form noValidate autoComplete='off' className={styles.formContainer}>
              <FieldArray name='contacts'>
                {({ push, remove }) => (
                  <div>
                    {values.contacts.map((p, index) => {
                      const name = `contacts[${index}].name`;
                      const touchedName = getIn(touched, name);
                      const errorName = getIn(errors, name);
                      const source = `contacts[${index}].source`;
                      const touchedSource = getIn(touched, source);
                      const errorSource = getIn(errors, source);
                      const countryCode = `contacts[${index}].country_code`;
                      const phoneNumber = `contacts[${index}].phone_number`;
                      const touchedPhone = getIn(touched, phoneNumber);
                      const errorPhone = getIn(errors, phoneNumber);
                      const emailId = `contacts[${index}].email_id`;
                      const touchedEmail = getIn(touched, emailId);
                      const errorEmail = getIn(errors, emailId);
                      const isAnonymous = `contacts[${index}].is_referred_anonymous`;

                      return (
                        <div key={p.id}>
                          <div className={styles.contactRow}>
                            <div className={styles.contactText}>CONTACT {index + 1}</div>
                            {index !== 0 && (
                              <div
                                className={styles.removeBtn}
                                margin='normal'
                                type='button'
                                color='secondary'
                                variant='standard'
                                onClick={() => remove(index)}
                              >
                                <Image className={styles.removeIcon} name='removeCircle.svg' />
                                <span className={styles.removeText}>Remove</span>
                              </div>
                            )}
                          </div>

                          <div className={styles.formRow}>
                            <div className={styles.formField}>
                              <label htmlFor='' className={styles.fieldLabel}>
                                HR Name <span className={styles.required}>*</span>
                              </label>
                              <FormControl
                                margin='normal'
                                name={name}
                                value={p.name}
                                required
                                placeholder='Enter name'
                                className={
                                  Boolean(touchedName && errorName)
                                    ? cn(styles.inputGroupErrBorder)
                                    : cn(styles.inputGroup)
                                }
                                error={Boolean(touchedName && errorName)}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldValue(name, getTrimmedValue(e.target.value, true));
                                }}
                              />
                              {Boolean(touchedName && errorName) ? (
                                <span className={styles.errorText}>{errorName}</span>
                              ) : null}
                            </div>
                            <div className={styles.formField}>
                              <label htmlFor='' className={styles.fieldLabel}>
                                How do you know this HR? <span className={styles.required}>*</span>
                              </label>
                              <Select
                                menuPosition={'fixed'}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                options={REFER_SOURCE}
                                value={p.source}
                                name={source}
                                placeholder='Select an option'
                                getOptionLabel={(l) => l.label}
                                getOptionValue={(v) => v.value}
                                onChange={(value) => {
                                  setFieldValue(source, value);
                                }}
                              />
                            </div>
                          </div>
                          <div className={styles.formRow}>
                            <div className={styles.formField}>
                              <label htmlFor='' className={styles.fieldLabel}>
                                HR Mobile <span className={styles.required}>*</span>
                              </label>
                              <div className={styles.phoneFormField}>
                                <Field
                                  name={phoneNumber}
                                  dropdownName={countryCode}
                                  component={MobileInput}
                                  label={false}
                                  handleBlur={handleBlur}
                                  placeholder='Enter HR mobile number'
                                  valuex={values.contacts[index]?.phone_number}
                                />
                                {touchedPhone && errorPhone && (
                                  <span className={styles.errorText}>
                                    {'Please enter a valid Mobile number.'}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className={styles.formField}>
                              <label htmlFor='' className={styles.fieldLabel}>
                                HR Email ID <span className={styles.required}>*</span>
                              </label>
                              <FormControl
                                className={
                                  Boolean(
                                    (touchedEmail && errorEmail) ||
                                      p.email_existance ||
                                      p.email_duplicate
                                  )
                                    ? cn(
                                        styles.inputGroupErrBorder,
                                        emailFocusedIndex === index
                                          ? styles.inputGroupErrBorderFocus
                                          : ''
                                      )
                                    : cn(styles.inputGroup)
                                }
                                margin='normal'
                                variant='outlined'
                                name={emailId}
                                value={p.email_id}
                                onChange={(e) => {
                                  handleChange(e);
                                  debounceEmailInput(e.target.value, index);
                                }}
                                onFocus={() => {
                                  setEmailFocusedIndex(index);
                                }}
                                onBlur={(e) => {
                                  checkDuplicateEmail(index, e.target.value);
                                  handleBlur(e);
                                  setFieldValue(emailId, getTrimmedValue(e.target.value, false));
                                  setEmailFocusedIndex(null);
                                }}
                                placeholder='Enter official email ID'
                              />
                              {!errorEmail && p.email_existance && (
                                <span className={styles.errorText}>
                                  This domain is already registered with us
                                </span>
                              )}
                              {!errorEmail && p.email_duplicate && (
                                <span className={styles.errorText}>
                                  Same Email ID cannot be added again
                                </span>
                              )}
                              {touchedEmail &&
                                ((errorEmail && (
                                  <span className={styles.errorText}>
                                    Please enter a valid Email ID
                                  </span>
                                )) ||
                                  (!p.email_duplicate &&
                                    validateEmail(values.contacts[index]?.email_id) && (
                                      <span className={styles.errorText}>
                                        Provide an official work HR email
                                      </span>
                                    )))}
                            </div>
                          </div>
                          {/* <div className={styles.formRow}>
                            <Field
                              custom
                              name={isAnonymous}
                              className={'custom-checkbox-style ' + styles.cBoxMargin}
                              component={Checkbox}
                              label='Refer anonymously'
                            />
                          </div>
                          {p.is_referred_anonymous && (
                            <div className={styles.anonymousText}>
                              Note: Choosing this option could lower referral success since your
                              name and details won't be used for reference.
                            </div>
                          )}*/}
                          {values.contacts.length > 0 && index !== values.contacts.length - 1 && (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                border: '0.50px rgba(153, 153, 153, 0.70) dashed',
                                marginBottom: '1rem',
                                marginTop: p.is_referred_anonymous ? '1rem' : '0'
                              }}
                            ></div>
                          )}
                        </div>
                      );
                    })}
                    <button
                      type='button'
                      className={cn(
                        styles.addBtn,
                        values.contacts.length === 15 ? styles.disabled : ''
                      )}
                      onClick={() =>
                        values.contacts.length < 15
                          ? push({
                              name: '',
                              source: '',
                              country_code: '+91',
                              phone_number: '',
                              email_id: '',
                              is_referred_anonymous: false,
                              is_valid_phone: false
                            })
                          : {}
                      }
                    >
                      <Image
                        className={styles.addIcon}
                        name={
                          values.contacts.length === 15 ? 'addCircleDisabled.svg' : 'addCircle.svg'
                        }
                      />
                      <span
                        className={cn(
                          styles.addText,
                          values.contacts.length === 15 ? styles.disabled : ''
                        )}
                      >
                        Add more contacts
                      </span>
                    </button>
                  </div>
                )}
              </FieldArray>
              <div className={styles.actionFooter}>
                <Button
                  type='button'
                  className={cn(styles.btn, invalidForm() ? styles.disabledBtn : styles.activeBtn)}
                  disabled={invalidForm()}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <span
                  className={cn(
                    styles.blankBtn,
                    !isValid ? styles.activeSkipBtn : styles.disabledSkipBtn
                  )}
                  disabled={isValid}
                  onClick={handleSkip}
                >
                  Skip
                </span>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReferAndEarn;
