import React, { useState } from 'react';
import Star from './Star';
import styles from './Stars.module.scss';

const Stars = ({ count, handleClick, color, getColorForStars }) => {
  /* '-1' value represents the default value */
  const [isHover, setIsHover] = useState(-1);

  return (
    <span className={styles.stars}>
      {[...Array(5).keys()].map((i) => (
        <Star
          isHover={isHover}
          setIsHover={setIsHover}
          index={i + 1}
          color={isHover !== -1 ? getColorForStars(isHover) : color}
          key={i}
          isFull={i < count}
          onClick={() => handleClick(i + 1)}
        />
      ))}
    </span>
  );
};

export default Stars;
