export const STATUSES = {
  values: [
    { value: 'In Progress', color: '#0d47a1' },
    { value: 'Completed', color: '#21d549' },
    { value: 'Candidate Added', color: '#000000' },
    { value: 'Awaiting Input', color: '#c2c2c2' },
    { value: 'Processing', color: '#0099cc' },
    { value: 'Completed with exception', color: '#ff8800' },
    { value: 'Completed with exception', color: '#ff8800' },
    { value: 'Insufficiency', color: '#ff8800' },
    { value: 'Closed', color: '#979797' },
    { value: 'On Hold', color: '#979797' },
    { value: 'Cancelled', color: '#333333' },
    { value: 'Consent Missing', color: '#FF8800' },
    { value: 'Insufficient Funds', color: '#979797' }
  ]
};
export const CHECK_STATUSES = {
  values: [
    { value: 'Ready to Initiate', color: '#76c5fc', textColor: '#76c5fc' },
    { value: 'Verified', color: '#50da69', textColor: '#50da69' },
    { value: 'Unable to Verify', color: '#edd450', textColor: '#edd450' },
    { value: 'Awaiting Input', color: '#c2c2c2', textColor: '#c2c2c2' },
    { value: 'In Progress', color: '#5d72c6', textColor: '#5d72c6' },
    { value: 'On Hold', color: '#333333', textColor: '#333333' },
    { value: 'Closed', color: '#979797', textColor: '#ffffff' },
    { value: 'N/A', color: '#979797', textColor: '#ffffff' },
    { value: 'Cancelled', color: '#979797', textColor: '#ffffff' },
    { value: 'Form Submitted', color: '#76c5fc', textColor: '#76c5fc' },
    { value: 'Completed (Auto)', color: '#5d72c6', textColor: '#5d72c6' },
    { value: 'NA(Auto)', color: '#979797', textColor: '#353935' },
    { value: 'Insuff-closed', color: '#979797', textColor: '#353935' },
    { value: 'Insufficiency', color: '#e88c1c', textColor: '#e88c1c' },
    { value: 'Discrepancy', color: '#e54700', textColor: '#e54700' },
    { value: 'Null', color: '#000000', textColor: '#000000' }
  ]
};

export const ADDRESS_TYPES = {
  'Permanent Address': 'Permanent Address',
  permanent_address: 'Permanent Address',
  current_address: 'Current Address',
  'Current Address': 'Current Address'
};

export const validDocTypes = {
  consent: '.png,.jpg,.jpeg,.doc,.docx,.pdf',
  resume: '.doc,.docx,.pdf'
};
export const fileTypes = {
  checkDocument: 'CHECK_DOCUMENT',
  resume: 'CANDIDATE_RESUME',
  consent: 'CANDIDATE_CONSENT',
  bulkFlow: 'CANDIDATE_BULK_UPLOAD',
  logoFile: 'COMPANY_LOGO'
};
