import React from 'react';

import Package from '../PackageListing/Package';

function PackageDetails(props) {
  return (
    <>
      <div>
        <Package pDetails={props.package} />
      </div>
    </>
  );
}

export default PackageDetails;
