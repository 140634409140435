import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import Button from '../../core-components/Button';
import styles from './StepOne.module.scss';
import * as XLSX from 'xlsx';
import { getInstructionItems } from '../StepTwo/TableRowsUtils';
import { getNonEmptyRows } from '../utils/BulkUploadUtilities';
import Banner from '../../core-components/Banner/Banner';
import { bulkAccept } from '../../core-components/Utilities/utilities';
export default (props) => {
  const [isUplaoding, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [fileName, setFileName] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const { companyId } = props; // this companyId is for CA side

  const saCompanyId = props?.isSA
    ? new URLSearchParams(document.location.search).get('company_id')
    : '';

  const make_cols = (refstr) => {
    let o = [],
      C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  };
  const fileHandler = (event) => {
    setSuccessMessage(null);
    setErrorMessage([]);
    setIsFormValid(false);
    let file = event.target.files[0];
    setFileName(file.name);
    let formData = new FormData();
    formData.append('bulk_upload_file', file);
    formData.append('file_type', 'CANDIDATE_BULK_UPLOAD');
    const id = props?.isSA ? saCompanyId : companyId;
    formData.append('company_id', id);
    setIsUploading(true);
    props
      .uploadCandidateCSV(formData)
      .then((response) => {
        props.setBulkUploadDataAPI({ type: 'empty', data: {} });
        setIsUploading(false);
        setSuccessMessage(response.data.message);
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const rowData = XLSX.utils.sheet_to_json(ws, { header: 1 });
          setData(rowData);
          setCols(make_cols(ws['!ref']));
          setIsFormValid(true);
          /* Update state */
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      })
      .catch((error) => {
        setIsUploading(false);
        setErrorMessage(
          error?.response?.data?.errors ||
          error?.response?.data?.message ||
          'Something wrong with the uploaded file. Please verify once'
        );
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const rows = getNonEmptyRows(data); // getting non-empty rows
    const trimmedRows = rows;

    props.handleStepChange(trimmedRows, cols);
  };
  const onInputClick = (event) => {
    event.target.value = '';
  };

  const Data = ({ children }) => {
    return props.isCA ? (
      <Card className={props?.isCA ? styles.addCandidateCard : ''}>{children}</Card>
    ) : (
      <div className={props?.isCA ? styles.addCandidateCard : ''}>{children}</div>
    );
  };
  return (
    <>
      <div className={styles.cardSection}>
        <Data className={props?.isCA ? styles.addCandidateCard : ''}>
          <Container>
            <div className={styles.addCandidate}>
              <Row>
                <Col md={4}>
                  <h3 className={styles.heading}>Bulk Candidate Addition</h3>
                </Col>
                <Col md={8} className={styles.form}>
                  <Row>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span className={styles.instructionLabel}>
                        Add candidates in bulk by uploading a spreadsheet (.csv/ .xls/ .xlsx)
                      </span>
                      <Banner />
                      <span className={styles.instructionHeading}>
                        Before using this feature, make sure that your file has
                      </span>
                      <div>{getInstructionItems(styles)}</div>
                    </div>
                  </Row>
                  <Row className={styles.uploadFile}>
                    Upload file
                    <div className={styles.fileInput}>
                      <label className={styles.customFileUpload}>
                        <input
                          className={styles.stepOneFile}
                          type='file'
                          onChange={fileHandler}
                          onClick={onInputClick}
                          accept={bulkAccept}
                        />
                        Browse
                      </label>
                      <div className={styles.fileName}>{fileName}</div>
                    </div>
                    {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
                    {errorMessage.length > 0 && Array.isArray(errorMessage) ? (
                      errorMessage.map((err) => <p className={styles.errors}>{err}</p>)
                    ) : (
                      <p className={styles.errors}>{errorMessage}</p>
                    )}
                  </Row>
                  <Row className={styles.buttn}>
                    <Button onClick={handleSubmit} className={styles.accentColor} disabled={!isFormValid}>
                      {isUplaoding ? 'Uploading' : 'Continue'}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </Data>
      </div>
    </>
  );
};
