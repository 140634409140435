import { connect } from 'react-redux';

import { getStatusCount, getCompanyPaymentDetails } from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    statusCount: state.statusCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStatusCount: () => dispatch(getStatusCount()),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
