// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dm2AQwQA1vw6JPaCaAKv .uj8UsAXI2M1YQNs_SyZK {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dm2AQwQA1vw6JPaCaAKv .k73ENCUShxIgVvSx3s6G {
  font-family: roboto;
}`, "",{"version":3,"sources":["webpack://./src/core-components/Razorpay/Razorpay.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".addBtnContainer {\n  .payBtn {\n    width: 100%;\n    background: #2755fe;\n    border-radius: 2px;\n    font-weight: normal;\n    font-size: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .rupee {\n    font-family: roboto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addBtnContainer": `dm2AQwQA1vw6JPaCaAKv`,
	"payBtn": `uj8UsAXI2M1YQNs_SyZK`,
	"rupee": `k73ENCUShxIgVvSx3s6G`
};
export default ___CSS_LOADER_EXPORT___;
