import React from 'react';

export default function Header({
  column: { id, label, subLabel, required, getResizerProps, getHeaderProps }
}) {
  return id !== 999999 ? (
    <>
      <div
        {...getHeaderProps({
          style: { display: 'inline-block' }
        })}
        className='th noselect'
      >
        <div className='th-content'>
          <span>
            {label}
            {required ? <span style={{ color: '#D3455B' }}>*</span> : ''}
          </span>
          <div className='subLabel'>{subLabel}</div>
        </div>
        <div {...getResizerProps()} className='resizer' />
      </div>
    </>
  ) : (
    <></>
  );
}
