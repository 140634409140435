// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TNt4GwDHNj3YdldVnwF8 {
  padding: 16px 46px;
  background-color: rgba(66, 132, 91, 0.1019607843);
  border-radius: 4px;
  gap: 10px;
  border: none;
  margin-bottom: 1.25rem;
}
.TNt4GwDHNj3YdldVnwF8 .rWP64zjiryu1TNQ4r2FH {
  display: flex;
  align-items: center;
}
.TNt4GwDHNj3YdldVnwF8 .rWP64zjiryu1TNQ4r2FH .q1a2wde6m7HXtKYNogom {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}
.TNt4GwDHNj3YdldVnwF8 .rWP64zjiryu1TNQ4r2FH .aFBZ7qBEM2JQ6MRjQFSd {
  height: 20px;
  width: 20px;
  margin-left: 4px;
}
.TNt4GwDHNj3YdldVnwF8 .C5Omb1H6osMEqBjT8M6Q {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
  font-weight: 400;
}
.TNt4GwDHNj3YdldVnwF8 .C5Omb1H6osMEqBjT8M6Q span {
  font-family: "Roboto";
  font-weight: 500;
  color: #333333;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ReferAndEarn/Banners/HRReferredInfoBanner/HRReferredInfoBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iDAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;AAEJ;AADI;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;AAGN;AADI;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAGN;AAAE;EACE,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AAEJ;AADI;EACE,qBAAA;EACA,gBAAA;EACA,cAAA;AAGN","sourcesContent":[".successReferralCard {\n  padding: 16px 46px;\n  background-color: #42845b1a;\n  border-radius: 4px;\n  gap: 10px;\n  border: none;\n  margin-bottom: 1.25rem;\n  .titleContainer {\n    display: flex;\n    align-items: center;\n    .titleText {\n      font-family: Poppins;\n      font-size: 16px;\n      font-weight: 600;\n      line-height: 24px;\n      letter-spacing: 0em;\n      text-align: left;\n      color: #333333;\n    }\n    .titleIcon {\n      height: 20px;\n      width: 20px;\n      margin-left: 4px;\n    }\n  }\n  .infoText {\n    font-family: 'Poppins';\n    font-size: 14px;\n    line-height: 21px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #666666;\n    font-weight: 400;\n    span {\n      font-family: 'Roboto';\n      font-weight: 500;\n      color: #333333;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successReferralCard": `TNt4GwDHNj3YdldVnwF8`,
	"titleContainer": `rWP64zjiryu1TNQ4r2FH`,
	"titleText": `q1a2wde6m7HXtKYNogom`,
	"titleIcon": `aFBZ7qBEM2JQ6MRjQFSd`,
	"infoText": `C5Omb1H6osMEqBjT8M6Q`
};
export default ___CSS_LOADER_EXPORT___;
