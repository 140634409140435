import {
  login as loginAPI,
  forgotPassword as forgotPasswordAPI,
  setPassword as setPasswordAPI,
  editCompanyProfile as editCompanyProfileAPI,
  editPersonalProfile as editPersonalProfileAPI,
  logout as logoutAPI,
  googleOrMsLogin as googleOrMsLoginAPI
} from '../api/authentication';

import { initializeReputeConnection as initializeReputeConnectionAPI } from '../api/company';

import store from '../store';
import storage from '../api/localStorage';
import { jwtParser } from '../utils/utilities';
export const UPDATE_JWT = 'UPDATE_JWT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const INVALIDATE_PROFILE = 'INVALIDATE_PROFILE';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';

export const updateJwt = (token, type) => {
  return {
    type: UPDATE_JWT,
    data: {
      token,
      type
    }
  };
};

export const invalidateProfile = () => {
  return {
    type: INVALIDATE_PROFILE
  };
};

export const updateProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    data
  };
};

export const updateCompanyPaymentType = (data) => {
  return {
    type: UPDATE_PAYMENT_TYPE,
    data
  };
};

export const login =
  (data, type = 'COMPANY') =>
  (dispatch) => {
    return loginAPI(data).then((response) => {
      const modelType = jwtParser(response.data.data.token);
      let token;
      let access_level;
      if (type === 'COMPANY') {
        token = response?.data?.data?.token;
        access_level =
          response?.data?.data?.user?.company_user_mapping?.access_level === 5 ? 'admin' : 'hr'; //{5: Admin, 10: HR}
      } else {
        token = response?.data?.data?.token;
        access_level = 'admin';
      }
      storage.setToken(token, type);
      dispatch(updateJwt(token, type));
      storage.setAccessLevel(access_level);
      let company = response.data.data.user;
      localStorage.setItem('company', JSON.stringify(company));
      dispatch(updateCompanyPaymentType(modelType.data.modelType));
      response.data.data.user && dispatch(updateProfile(company));
      return response.data;
    });
  };

export const initializeRepute =
  (data, type = 'COMPANY') =>
  (dispatch) => {
    return initializeReputeConnectionAPI(data).then((response) => {
      if (response?.data?.data?.status == 404) {
        return {
          error: true
        };
      } else {
        const modelType = jwtParser(response.data.data.token);
        let token;

        token = response?.data?.data?.token;
        const access_level =
          response?.data?.data?.user?.company_user_mapping?.access_level === 5 ? 'admin' : 'hr'; //{5: Admin, 10: HR}
        storage.setToken(token, type);
        dispatch(updateJwt(token, type));
        storage.setAccessLevel(access_level);
        let company = response.data.data.user;
        localStorage.setItem('company', JSON.stringify(company));
        dispatch(updateCompanyPaymentType(modelType.data.modelType));
        response.data.data.user && dispatch(updateProfile(company));
        return response.data;
      }
    });
  };

export const googleMicrosoftLogin =
  (path, data, type = 'COMPANY') =>
  (dispatch) => {
    return googleOrMsLoginAPI(path, data)
      .then((response) => {
        const data = response.data.data;
        const modelType = jwtParser(data.token);
        let token;
        let access_level;
        if (type === 'COMPANY') {
          token = data?.token;
          access_level = data?.user?.company_user_mapping?.access_level === 5 ? 'admin' : 'hr'; //{5: Admin, 10: HR}
        } else {
          token = data?.token;
          access_level = 'admin';
        }
        storage.setToken(token, type);
        dispatch(updateJwt(token, type));
        storage.setAccessLevel(access_level);
        let company = data.user;
        localStorage.setItem('company', JSON.stringify(company));
        dispatch(updateCompanyPaymentType(modelType.data.modelType));
        data.user && dispatch(updateProfile(company));
        return data;
      })
      .catch((err) => {
        throw err?.response;
      });
  };

export const forgotPassword = (data) => () => {
  return forgotPasswordAPI(data);
};

export const setPassword = (data) => () => {
  return setPasswordAPI(data);
};

//as update company profile is returning only updated data and there is no API for get complete profile,
//that's why replacing the redux state for only the updated data
export const editCompanyProfile = (data) => (dispatch) => {
  return editCompanyProfileAPI(data).then((response) => {
    const company = store?.getState()?.profile?.profile?.company_user_mapping?.company;
    company.company_url = response?.data?.data?.company?.company_url;
    company.city = response?.data?.data?.company?.city;
    company.logo_url = response?.data?.data?.company?.logo_url;
    dispatch(updateProfile(store.getState()?.profile?.profile));
  });
};

//as update personal profile is returning only updated data and there is no API for get complete profile,
//that's why replacing the redux state for only the updated data
export const editPersonalProfile = (data) => (dispatch) => {
  return editPersonalProfileAPI(data).then((response) => {
    let categoryList = [];
    let categoryListCopy = data.user_category_ids || [];
    categoryListCopy.map((category) => {
      let cat = store
        .getState()
        .categories.userCategories.filter((value) => value.id === category)[0];
      if (cat) {
        categoryList.push(cat);
      }
    });
    response.data.data.userCategoryMappingResponse.map((result) => {
      if (result?.deleted_at === null) {
        let c = store
          .getState()
          .categories.userCategories.filter((value) => value.id === result.user_category_id)[0];
        result['user_category'] = {
          category_name: c.categoryName,
          company_id: c.companyId,
          created_at: c.createdAt,
          deleted_at: c.deletedAt,
          id: c.id,
          updated_at: c.updatedAt,
          user_id: c.userId,
          uuid: c.uuid
        };
        categoryList.push(result);
      }
    });
    store.getState().profile.profile.name = response.data.data.user.name;
    store.getState().profile.profile.mobile = response.data.data.user.mobile;
    store.getState().profile.profile.user_category_mappings = categoryList;
    dispatch(updateProfile(store.getState().profile.profile));
  });
};

export const logout = () => (dispatch) => {
  dispatch(invalidateProfile());
  return logoutAPI();
};
