import { withFormik } from 'formik';
import _, { isEmpty, mergeWith } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import {
  getAdministrators,
  invalidateAdminstrators,
  getRequestPaymentUsers,
  invalidateRequestPaymentUsers,
  invalidateZohoZonnection
} from '../../../../../actions/company';
import { requestPayment } from '../../../../../api/company';
import AppConstants from '../../../../../core-components/AppConstants';
import { toSnakeCase, getErrorMessage, errToastMessage } from './../../utils/Utilities';
import validatorSchema from './RequestPayment.validation';
const mapStateToProps = (state) => {
  return {
    administrators: state.administrators,
    profile: state.profile.profile,
    requestPaymentUsers: state.requestPaymentUsers,
    zohoBookStatus: state.zohoConnection
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdministrators: (limit, offset, companyId) =>
      dispatch(getAdministrators(limit, offset, companyId)),
    getRequestPaymentUsers: (companyId) => dispatch(getRequestPaymentUsers(companyId)),
    invalidateAdministrators: () => dispatch(invalidateAdminstrators()),
    invalidateRequestPaymentUsers: () => dispatch(invalidateRequestPaymentUsers()),
    invalidateZohoZonnection: () => dispatch(invalidateZohoZonnection())
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const { administrators, profile, paymentPayload, isSA } = props;
      let allOtherAdminsAndHR = [];
      if (
        administrators &&
        !isEmpty(administrators) &&
        !administrators?.loading &&
        administrators?.admins
      ) {
        allOtherAdminsAndHR = administrators?.admins?.filter((admin) => admin?.id !== profile?.id);
      }
      let initialValue = {
        dueDate: new Date(),
        requestee: allOtherAdminsAndHR.length === 0 ? ['Other'] : [],
        externalRequestee: [],
        requestor: isSA ? 'None' : profile?.id,
        orderDetails: paymentPayload
      };
      return mergeWith({}, initialValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema: validatorSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      const companyId = new URLSearchParams(document.location.search).get('company_id');
      let requestee = [];
      let allRequestee = values?.requestee;
      if (values?.requestee.includes('Other')) {
        allRequestee = new Set([...values?.requestee, ...values?.externalRequestee]);
      }
      const allRequesteeFiltered = Array.from(allRequestee);
      const adminRequestee = _.filter(props.administrators.admins, (admin) =>
        allRequesteeFiltered.includes(admin?.email)
      );
      const otherRequestee = _.difference(
        allRequesteeFiltered,
        adminRequestee.map((admin) => admin?.email)
      );
      if (otherRequestee.includes('Other')) {
        otherRequestee.splice(otherRequestee.indexOf('Other'), 1);
      }
      otherRequestee.map((other) => {
        requestee.push({
          user_id: null,
          email: other
        });
      });

      // const allRequestee = [...adminRequestee, ...otherRequestee];
      adminRequestee.map((req) => {
        requestee.push({
          user_id: req?.id,
          email: req?.email
        });
      });

      const payload = { ...values };
      payload['dueDate'] = moment(payload.dueDate).format('YYYY-MM-DD');
      payload['requestee'] = requestee;
      payload['requestor'] = parseInt(payload?.requestor);
      payload['companyId'] = companyId;

      const finalPayloads = toSnakeCase(payload);
      //update response when api is ready
      requestPayment(finalPayloads)
        .then((res) => {
          toast.success('Payment Request Sent');
          const piNumber = res?.data?.data?.piNumber;
          // TO Add orderId in the response so that we can open View details in modal
          const orderID = res?.data?.data?.orderId;
          props.onClose(1, piNumber, orderID);
        })
        .catch((err) => {
          console.error(err);
          setSubmitting(false);
          errToastMessage('Error. Please try again');
          props.onClose(0);
        });
    },
    displayName: 'Request Payment'
  })
);
