// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BHCU_CCeM43gDqyHQmKE {
  padding: 24px;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
}
.BHCU_CCeM43gDqyHQmKE .fSIIagA65X90AJ68XMbe {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
}
.BHCU_CCeM43gDqyHQmKE .hl4Ore3o0Nyb7epAC8kM {
  color: #666666;
}
.BHCU_CCeM43gDqyHQmKE .lCP6Ob6rbrbqBAN0ZkK7 {
  color: #faad14;
}
.BHCU_CCeM43gDqyHQmKE h5 {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333333;
}
.BHCU_CCeM43gDqyHQmKE .XODu2HgyzfW8w8ma0eW5 {
  font-family: roboto;
}
.BHCU_CCeM43gDqyHQmKE .vRMqn1zEstLZQDXhqHbV {
  margin-top: 50px;
}
.BHCU_CCeM43gDqyHQmKE .vRMqn1zEstLZQDXhqHbV .hFzfZLulbYAtvnULP71H {
  width: 100%;
  background: #2755fe;
  border-radius: 2px;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Packages/Prepaid/Credits/AvailableBalance/AvailableBalance.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gDAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;AAGN","sourcesContent":[".availableBalanceContainer {\n  padding: 24px;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  .availableBalanceText {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 21px;\n    margin-bottom: 0;\n  }\n  .positiveBalance {\n    color: #666666;\n  }\n  .negativeBalance {\n    color: #faad14;\n  }\n  h5 {\n    font-weight: 500;\n    font-size: 32px;\n    line-height: 48px;\n    color: #333333;\n  }\n  .rupee {\n    font-family: roboto;\n  }\n  .actionContainer {\n    margin-top: 50px;\n    .addCreditBtn {\n      width: 100%;\n      background: #2755fe;\n      border-radius: 2px;\n      color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"availableBalanceContainer": `BHCU_CCeM43gDqyHQmKE`,
	"availableBalanceText": `fSIIagA65X90AJ68XMbe`,
	"positiveBalance": `hl4Ore3o0Nyb7epAC8kM`,
	"negativeBalance": `lCP6Ob6rbrbqBAN0ZkK7`,
	"rupee": `XODu2HgyzfW8w8ma0eW5`,
	"actionContainer": `vRMqn1zEstLZQDXhqHbV`,
	"addCreditBtn": `hFzfZLulbYAtvnULP71H`
};
export default ___CSS_LOADER_EXPORT___;
