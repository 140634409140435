// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xs9I8M2hXCI3_LsrX4Vw {
  max-width: 1200px;
  height: 1315px;
  background: #ffffff;
  border: none !important;
  margin: -250px auto 48px;
  font-family: Poppins, sans-serif;
  color: #333333;
}
@media only screen and (max-width: 1200px) {
  .xs9I8M2hXCI3_LsrX4Vw {
    width: 1200px;
  }
}
.xs9I8M2hXCI3_LsrX4Vw .OdWGIfbvyTXJghrE8xnY {
  position: fixed;
  top: 50%;
  left: 50%;
}

.vAs5lZ3cr_2vIeDTKbA3 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.cKH4DMDMEDUL9boyUVsE {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.UEETDB7Qip5PqBqvDYq7 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: lightgray;
  border: 1px solid lightgray;
}

.aIBaQiMHzQkcgXXBBpsQ {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  border: 1px solid gray;
}

.XNffskHfa1NUMFNgQsCB {
  width: 1220px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/CompanyAdmin.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,wBAAA;EACA,gCAAA;EACA,cAAA;AACF;AAAE;EARF;IASI,aAAA;EAGF;AACF;AAFE;EACE,eAAA;EACA,QAAA;EACA,SAAA;AAIJ;;AAAA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AAGF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAGF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,2BAAA;EACA,2BAAA;AAGF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,sBAAA;AAGF;;AAAA;EACE,aAAA;EACA,cAAA;AAGF","sourcesContent":[".loadingviewCandidateCard {\n  max-width: 1200px;\n  height: 1315px;\n  background: #ffffff;\n  border: none !important;\n  margin: -250px auto 48px;\n  font-family: Poppins, sans-serif;\n  color: #333333;\n  @media only screen and (max-width: 1200px) {\n    width: 1200px;\n  }\n  .viewCandidateLoader {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n  }\n}\n\n.dotContainer {\n  margin-top: 10px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.dotSelection {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n}\n\n.dot {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: lightgray;\n  border: 1px solid lightgray;\n}\n\n.selectedDot {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: gray;\n  border: 1px solid gray;\n}\n\n.cContainer {\n  width: 1220px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingviewCandidateCard": `xs9I8M2hXCI3_LsrX4Vw`,
	"viewCandidateLoader": `OdWGIfbvyTXJghrE8xnY`,
	"dotContainer": `vAs5lZ3cr_2vIeDTKbA3`,
	"dotSelection": `cKH4DMDMEDUL9boyUVsE`,
	"dot": `UEETDB7Qip5PqBqvDYq7`,
	"selectedDot": `aIBaQiMHzQkcgXXBBpsQ`,
	"cContainer": `XNffskHfa1NUMFNgQsCB`
};
export default ___CSS_LOADER_EXPORT___;
