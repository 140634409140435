import { connect } from 'react-redux';
import { expirePassword } from '../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state?.profile?.profile,
    expirePassword: state?.expirePassword
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reqExpirePassword: (data) => dispatch(expirePassword(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
