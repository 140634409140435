import React from 'react';
import { Modal } from 'react-bootstrap';
import Package from '../PackageListing/Package';
import styles from './SubtypeModal.module.scss';
const SubtypesModal = ({ show, handleClose, subtypes, isAadhaarAllowed, isSA }) => {
  return (
    <Modal show={show} onHide={handleClose} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title className={styles.header}>
          Variations available under {subtypes[0].packageName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subtypes.map((packg, index) => {
          return (
            <Package
              key={index}
              index={index}
              pDetails={packg}
              showPricing={false}
              showBuyBtn={false}
              showBorder={false}
              handleBuy={() => {}}
              packageType={''}
              isShowOnly={true}
              isAadhaarAllowed={isAadhaarAllowed}
              createFriendlyNameFlow={true}
              isSA={isSA}
            />
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default SubtypesModal;
