import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty, mergeWith } from 'lodash';
import { withFormik } from 'formik';
import { toCamelCaseWithSpaces } from '../../../../../../utils/utilities';
import {
  setIntegration as setIntegrationAPI,
  getViewBuyPackages as getViewBuyPackagesAPI
} from '../../../../../../actions/company';
import { saveFieldMapping, putIntegrations } from '../../../../../../api/company';
import { toast } from 'react-toastify';
import { errToastMessage } from '../../../utils/Utilities';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    integrationData: state?.integrationData || {},
    viewBuyPackages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIntegrationAPI: (companyId) => dispatch(setIntegrationAPI(companyId)),
    getViewBuyPackages: (data) => dispatch(getViewBuyPackagesAPI(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        identifierValue: 'Job Roles',
        tags: {},
        companyId: props.profile.profile.company_user_mapping.company.id,
        integrationData: props.integrationData
      };

      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting }) => {
      let subtypesMapping = [];
      for (const [key, value] of Object.entries(values.tags)) {
        if (!isEmpty(value)) {
          subtypesMapping.push({
            fieldName: values.identifierValue,
            subtype_id: parseInt(key),
            package_id: value.packageId,
            fieldValue: value.tags.join(';')
          });
        }
      }

      const mappingPayload = {
        integrationName: 'REPUTE',
        company_id: values.companyId,
        mappingData: subtypesMapping
      };

      const payload = {
        integrationName: 'REPUTE',
        company_id: values.companyId,
        fieldsToUpdate: {
          details: JSON.stringify({
            package_identifier: values.identifierValue,
            integration_id: values.integrationData.repute.integrationId,
            sync_job_id: values.integrationData.repute.syncJobId,
            schedule_job_id: values.integrationData.repute.scheduleJobId,
            hrms_logo_url: values.integrationData.repute.hrmsLogoUrl,
            hrms_name: values.integrationData.repute.hrmsName
          })
        }
      };

      putIntegrations(payload)
        .then((res) => {
          saveFieldMapping(mappingPayload)
            .then((res) => {
              toast.success('Package Mapping Successful.');
            })
            .catch((err) => {
              errToastMessage('Could not map packages with tags');
              setSubmitting(false);
            });
        })
        .catch((err) => {
          errToastMessage('Could not trigger integration');
          setSubmitting(false);
        });
    }
  })
);
