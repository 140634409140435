import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { pdfUnlock as pdfUnlockAPI } from '../../../../../actions/bgv';

const mapStateToProps = (state) => {
  return {
    uploadedID: state?.idDetails?.data,
    uploadedSingleGradesheet: state?.singleGradeUpload?.link?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pdfUnlock: (data, checkType) => dispatch(pdfUnlockAPI(data, checkType))
  };
};

const getLastFile = (protectedFiles) => {
  return protectedFiles && protectedFiles[protectedFiles.length - 1];
};

const getUploadedImagePath = (props) => {
  switch (props.checkType) {
    case 'address':
    case 'id':
      return props.uploadedID;
    case 'employment':
      return getLastFile(props.passwordProtectedFiles)?.docCopy?.link;
    case 'education':
      return getLastFile(props.passwordProtectedFiles)?.docCopy?.link;
  }

  return '';
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let initialValue = {
        pdfUrl: getUploadedImagePath(props),
        pdfName:
          (props.passwordProtectedFiles &&
            getLastFile(props.passwordProtectedFiles)?.docCopy?.fileName) ||
          '',
        password: '',
        error: '',
        submitting: false,
        maxSubmittingCount: 0
      };
      return mergeWith({}, initialValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props, setFieldValue }) => {
      setFieldValue('submitting', true);

      if (values.maxSubmittingCount === 3) {
        props.submitHandler(
          values.password,
          values.pdfUrl,
          getLastFile(props?.passwordProtectedFiles)?.index
        );
        return;
      }

      let data = {
        pdf_url: values.pdfUrl,
        password: values.password
      };
      props
        .pdfUnlock(data, props.checkType)
        .then((unlockedPdfUrl) => {
          setFieldValue('submitting', false);
          setFieldValue('pdfUrl', unlockedPdfUrl);
          props.submitHandler(
            '',
            unlockedPdfUrl,
            getLastFile(props?.passwordProtectedFiles)?.index
          );
        })
        .catch((err) => {
          setFieldValue('submitting', false);
          setFieldValue('maxSubmittingCount', ++values.maxSubmittingCount);
          if (err == 'WrongPassword') {
            setFieldValue('error', 'Incorrect Password. Please re-enter the correct password.');
          } else {
            setFieldValue('error', err);
          }
        });
    },
    displayName: 'Password Protected'
  })
);
