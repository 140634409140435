import * as Yup from 'yup';
import { getEmailValidationSchema } from '../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  email: Yup.string().when('formConfig', (formConfig, schema) =>
    getEmailValidationSchema({
      isRequired: !formConfig.is_email_optional,
      isNullable: !formConfig.is_email_optional
    })
  ),
  alternate_email: Yup.string().when('formConfig', (formConfig, schema) =>
    getEmailValidationSchema({})
  ),
  phone_number: Yup.string().required('Phone Number cannot be blank')
});
