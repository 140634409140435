import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';
import Modal from 'react-bootstrap/Modal';
import { isEmpty } from 'lodash';

import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField';
import Button from '../../../../core-components/Button';
import styles from './EditEmail.module.scss';
import { getTrimmedValue } from '../../../../utils/utilities';

export default (props) => {
  const { handleSubmit, values, setFieldValue, errors, isSubmitting } = props;
  const isValid = !isSubmitting && isEmpty(errors) && values?.email && !values?.emailError?.error;
  const onHideHandler = () => {
    setFieldValue('email', '');
    props.resetForm();
    props?.onHide();
  };
  const setValue = (fieldname, value, withSpace) => {
    const trimmedVal = getTrimmedValue(value, withSpace);
    setFieldValue(fieldname, trimmedVal);
    return trimmedVal;
  };
  useEffect(() => {
    if (values.email == props.email) {
      setFieldValue('emailError', { error: true, errorMsg: 'Please provide a new email' });
    } else {
      setFieldValue('emailError', { error: false, errorMsg: '' });
    }
  }, [values.email]);

  return (
    <Modal
      size='lg'
      show={props?.show}
      onHide={onHideHandler}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName={styles.content}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter' className={styles.title}>
          <span className={styles.titleHeading}>Edit candidate Email</span>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <p>
              <span className={styles.labelHeading}>Email</span>
            </p>
            <Field
              type='text'
              placeholder={'Enter text here'}
              component={ValidatedFormInputField}
              name='email'
              value={values?.email}
              onBlur={(e) => {
                setValue('email', values?.email, false);
                props?.handleBlur(e);
              }}
            />
            <span className={styles.errorMsg}>
              {values?.emailError?.error && values?.emailError?.errorMsg}
            </span>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className={styles.paddingFix}>
          <div className={styles.footer}>
            <p className={styles.footerLabel}>
              <span style={{ fontWeight: 'bold' }}>Note:</span> Ensure that the email you are adding
              is valid and active. Incorrect/Inactive email addresses will drastically affect TATs.
            </p>
            <div>
              <Button
                type='submit'
                disabled={!isValid}
                className={!isValid ? styles.disableBtn : styles.submitButton}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
