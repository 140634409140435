// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PpAPAvEeuD_SFhmisdLa {
  display: flex;
  align-content: center;
  align-items: center;
}
.PpAPAvEeuD_SFhmisdLa .hL03poLm4575J4JuOxTB {
  position: relative;
}
.PpAPAvEeuD_SFhmisdLa .hL03poLm4575J4JuOxTB button {
  background-color: transparent !important;
  border-color: transparent !important;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ {
  top: 56px !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  padding: 8px 4px 0px;
  width: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .T2dwkPDREfqvnOWUKl9H {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
  margin-bottom: 8px;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .T2dwkPDREfqvnOWUKl9H:hover {
  background: #ebefff;
  color: #2755fe;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .BlF48zzxSV7TZVt8Babu {
  cursor: not-allowed;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 12px;
  margin-bottom: 8px;
  color: inherit;
  opacity: 0.5;
}
.PpAPAvEeuD_SFhmisdLa .wTPkKPPNr3PoxtHpjOeJ .BlF48zzxSV7TZVt8Babu:hover {
  background: "transparent";
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ViewCandidate/DropdownMenu/DropdownMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAAI;EACE,wCAAA;EACA,oCAAA;AAEN;AAEE;EACE,oBAAA;EACA,qBAAA;EACA,mBAAA;EACA,0BAAA;EACA,oBAAA;EACA,WAAA;EACA,mBAAA;EACA,2CAAA;AAAJ;AACI;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACN;AAAM;EACE,mBAAA;EACA,cAAA;AAER;AACI;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AACN;AAAM;EACE,yBAAA;AAER","sourcesContent":[".dropdownMenuWrapper {\n  display: flex;\n  align-content: center;\n  align-items: center;\n\n  .mainDropdown {\n    position: relative;\n    button {\n      background-color: transparent !important;\n      border-color: transparent !important;\n    }\n  }\n\n  .dropdownMenu {\n    top: 56px !important;\n    left: auto !important;\n    right: 0 !important;\n    transform: none !important;\n    padding: 8px 4px 0px;\n    width: auto;\n    background: #ffffff;\n    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);\n    .dropdownParticularCard {\n      font-size: 16px;\n      line-height: 24px;\n      padding: 4px 12px;\n      margin-bottom: 8px;\n      &:hover {\n        background: #ebefff;\n        color: #2755fe;\n      }\n    }\n    .disabledDropdownParticularCard {\n      cursor: not-allowed;\n      font-size: 16px;\n      line-height: 24px;\n      padding: 4px 12px;\n      margin-bottom: 8px;\n      color: inherit;\n      opacity: 0.5;\n      &:hover {\n        background: 'transparent';\n      }\n    }\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownMenuWrapper": `PpAPAvEeuD_SFhmisdLa`,
	"mainDropdown": `hL03poLm4575J4JuOxTB`,
	"dropdownMenu": `wTPkKPPNr3PoxtHpjOeJ`,
	"dropdownParticularCard": `T2dwkPDREfqvnOWUKl9H`,
	"disabledDropdownParticularCard": `BlF48zzxSV7TZVt8Babu`
};
export default ___CSS_LOADER_EXPORT___;
