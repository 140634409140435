import React from 'react';

import { Modal, Row, Col, Button } from 'react-bootstrap';

import styles from './SkipEducationModal.module.scss';

export default (props) => {
  return (
    <>
      <Modal
        size='md'
        show={props.show}
        onHide={props.onHide}
        dialogClassName='educationModal'
        backdropClassName={'customBGVModalBackdrop'}
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            <span>{'Skip Education'}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.addEditEducationModalBody}>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <p>
                {`Are you sure ? Your employer mandates you to enter the education qualifications
                  that you have.`}
              </p>
              <p>
                {`Checking this box signifies that you do not have any further
                  education qualifications`}
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className={styles.educationSubmitBtn}>
          <div>
            <Button className={styles.activeBtn} type='button' onClick={() => props.onHide()}>
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
