import React from 'react';
import { Form, Col } from 'react-bootstrap';
import styles from '../ManageApiToken.module.scss';
import Pagination from '../../../../../core-components/Pagination';
import { useState } from 'react';

const PAGE_SIZES = [10, 20, 50, 100];

export default (props) => {
  const { count, paginate, dispatch, offset, limit } = props;
  const pageCount = Math.ceil(count / limit);

  const pageSizeOptions = [];
  let index = 0;
  do {
    const value = PAGE_SIZES[index] > count ? count : PAGE_SIZES[index];
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (PAGE_SIZES[index++] < count);

  return (
    <Form.Row className={styles.paginationWrapper}>
      <Col className={styles.showPagination}>
        Show
        <select
          data-testid="selectPagination"
          className={styles.selectPagination}
          value={limit}
          onChange={(event) => {
            paginate(parseInt(event.target.value), 0);
            dispatch({
              type: 'SET_PAGINATION',
              offset: 0,
              limit: parseInt(event.target.value)
            });
          }}
        >
          {pageSizeOptions}
        </select>
        items of {count}
      </Col>
      <Col>
        <Pagination
          forcePage={offset}
          onPageChange={({ selected }) => {
            paginate(limit, parseInt(selected));
            dispatch({
              type: 'SET_PAGINATION',
              offset: parseInt(selected),
              limit: limit
            });
          }}
          pageCount={pageCount}
        />
      </Col>
      <Col></Col>
    </Form.Row>
  );
};

