function isLocallyServed() {
  return window.location.hostname.includes('local') || window.location.hostname.includes('ngrok');
}

function isDev2() {
  return window.location.hostname.includes('dev-2-portal');
}

function isDev3() {
  return window.location.hostname.includes('dev-3-portal');
}

function isAcceptance() {
  return window.location.hostname.includes('acceptance-portal');
}

function isAcceptance2() {
  return window.location.hostname.includes('acceptance-2-portal');
}

function getEnv() {
  return isLocallyServed()
    ? 'local'
    : isDev2()
    ? 'dev-2'
    : isDev3()
    ? 'dev-3'
    : isAcceptance()
    ? 'accept'
    : isAcceptance2()
    ? 'accept-2'
    : process.env.NODE_ENV === 'development'
    ? 'dev'
    : 'prod';
}

const AppHelper = {
  isLocallyServed,
  isDev2,
  isDev3,
  isAcceptance,
  isAcceptance2,
  getEnv
};

export default AppHelper;
