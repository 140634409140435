import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Card, Col, Container, Row, Breadcrumb } from 'react-bootstrap';

import styles from './PurchaseDetails.module.scss';

import AppConstants from '../../../../../../core-components/AppConstants';
import {
  convertToIndianNumeration,
  errToastMessage,
  hasDot,
  toCamelCase
} from '../../../../../../utils/utilities';
import localStorage from '../../../../../../api/localStorage';
import Loader from '../../../../../../core-components/Loader';
import { toast } from 'react-toastify';
import { getPurchaseHistoryByID } from '../../../../../../api/company';
import { PAYMENT_MODE_OBJ } from '../../../../../../utils/commonConstant';

export default (props) => {
  const [purchaseDetails, setPurchaseDetails] = useState(null);
  const token = localStorage.getToken();
  const baseUrl = AppConstants.developmentAPIURL;

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const transactionIdFromUrlParams = query.get('id');
    if (transactionIdFromUrlParams) {
      getPurchaseHistoryByID(transactionIdFromUrlParams)
        .then((res) => {
          setPurchaseDetails(toCamelCase(res.data?.data?.rows[0]));
        })
        .catch((err) => {
          errToastMessage(err);
        });
    }
  }, []);

  const calculatePerCandidateCost = (numberOfCandidates = 10, credits) => {
    return (credits / numberOfCandidates) | 0;
  };
  let url = `/auth/invoice?invoiceId=${purchaseDetails?.invoiceId}&companyId=${props.companyId}&token=`;

  const calculateTotal = () => {
    var total = convertToIndianNumeration(
      parseFloat(purchaseDetails?.credits || 0) +
        parseFloat(purchaseDetails?.taxAmount || 0) -
        parseFloat(purchaseDetails?.tdsAmount || 0)
    );

    return hasDot(total) ? total : total + '.00' || 0;
  };

  // tax and tds details for company
  const taxTds = () => {
    return (
      <>
        {purchaseDetails?.taxAmount ? (
          <Row className='mt-2'>
            <Col xs={6} sm={6} md={6} lg={6}>
              Tax ({purchaseDetails?.taxPercentage || 0}%)
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
              <span className={styles.amount}>
                &#8377;{convertToIndianNumeration(parseFloat(purchaseDetails?.taxAmount) || 0) || 0}
                {hasDot(purchaseDetails?.taxAmount) ? '' : '.00'}
              </span>
            </Col>
          </Row>
        ) : null}
        {purchaseDetails.tdsPercentage ? (
          <Row className='mt-2'>
            <Col xs={6} sm={6} md={6} lg={6}>
              TDS ({purchaseDetails?.tdsPercentage || 0}%)
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
              <span className={styles.amount} style={{ marginLeft: '-5px' }}>
                -&#8377;{convertToIndianNumeration(parseFloat(purchaseDetails?.tdsAmount) || 0)}
                {hasDot(parseFloat(purchaseDetails?.tdsAmount)) ? '' : '.00'}
              </span>
            </Col>
          </Row>
        ) : null}
      </>
    );
  };
  const getRequestedUser = (data) => {
    switch (data?.purchaseItem) {
      case 'RECHARGE':
        return data?.paymentOrder?.requestedBy?.isRequestedBySa
          ? `${data?.paymentOrder?.requestedBy.userName} (via SV)`
          : data?.paymentOrder?.requestedBy.userName;
      default:
        return data?.isCa ? data?.purchasedBy : 'SpringVerify';
    }
  };
  const getPurchasedUser = (data) => {
    switch (data?.purchaseItem) {
      case 'RECHARGE':
        if (data?.paymentOrder?.requestedBy?.userId) {
          return data?.paymentOrder?.paidBy?.userId
            ? data?.paymentOrder?.paidBy?.isCa
              ? data?.paymentOrder?.paidBy?.userName
              : 'External User (via SV)'
            : 'External User';
        } else {
          return data?.paymentOrder?.paidBy?.userId
            ? data?.paymentOrder?.paidBy?.userName
            : data?.isCa
            ? data?.purchasedBy
            : 'Customer (via SV)';
        }
      default:
        return data?.isCa ? data?.purchasedBy : 'SpringVerify';
    }
  };
  const summaryDetails = () => {
    if (purchaseDetails?.purchaseItem === 'PACKAGE_BOUGHT') {
      return (
        <Container className={styles.summary}>
          <Row className={styles.packageName}>
            <Col xs={6} sm={6} md={6} lg={6}>
              {purchaseDetails?.packageName}
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
              <span className={styles.amount}>
                &#8377;{convertToIndianNumeration(purchaseDetails?.credits || 0)}
              </span>
            </Col>
          </Row>
          <Row className={styles.candidates}>
            <Col xs={12} sm={12} md={12} lg={12}>
              {`${convertToIndianNumeration(purchaseDetails?.numberOfCandidates) || 0} candidates`}
            </Col>
          </Row>
          <Row className={styles.candidates}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <span className={styles.amount}>
                &#8377;
                {`${calculatePerCandidateCost(
                  purchaseDetails?.numberOfCandidates,
                  purchaseDetails?.credits
                )} per candidate`}
              </span>
            </Col>
          </Row>
          {purchaseDetails?.paymentMode === 'PAYMENT_GATEWAY' ? <>{taxTds()}</> : null}
        </Container>
      );
    } else {
      return (
        <Container className={styles.summary}>
          <Row className={styles.total}>
            <Col xs={6} sm={6} md={6} lg={6}>
              Amount
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
              <span className={styles.amount}>
                &#8377;{convertToIndianNumeration(purchaseDetails?.credits || 0) + '.00'}
              </span>
            </Col>
          </Row>
          {taxTds()}
        </Container>
      );
    }
  };
  const getStyleForDeletedUser = () => {
    const isUserCa = purchaseDetails?.isCa;
    const isUserDeleted = purchaseDetails?.isUserDeleted;

    const isUserCaAndDeleted = isUserCa && isUserDeleted;

    return isUserCaAndDeleted ? styles.deletedUser : null;
  };

  return purchaseDetails ? (
    <div className={styles.purchaseDetailsWrapper}>
      <div className={styles.heading}>{'Transaction Details'}</div>
      <Card className={styles.purchaseDetailsCard}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item href={`${AppConstants.baseURL}transaction`}>
            Purchase History
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Transaction Details</Breadcrumb.Item>
        </Breadcrumb>
        <Card style={{ marginBottom: '39px' }}>
          <Container>
            <Row>
              <Col lg={8}>
                <div className={styles.purchaseTypeHeading}>
                  {purchaseDetails?.purchaseItem === 'RECHARGE'
                    ? `PURCHASE (${purchaseDetails?.purchaseItem})`
                    : 'REFUND TO CUSTOMER'}
                </div>
              </Col>
              <Col lg={4}>
                <div className={styles.summaryHeading}>Summary</div>
              </Col>
            </Row>
            <Row>
              <Col xs={8} sm={8} md={8} lg={8}>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={styles.infoCard}>
                      <span className={styles.title}>Date</span>
                      <span className={styles.text}>
                        {moment(purchaseDetails?.purchaseDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                      </span>
                    </div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={styles.infoCard}>
                      <span className={styles.title}>Paid By</span>
                      <span className={`${styles.text} ${getStyleForDeletedUser()}`}>
                        {getPurchasedUser(purchaseDetails)}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={styles.infoCard}>
                      <span className={styles.title}>Mode</span>
                      <span className={styles.text}>
                        {PAYMENT_MODE_OBJ[purchaseDetails?.paymentMode]}
                      </span>
                    </div>
                  </Col>
                  {purchaseDetails?.purchaseItem === 'RECHARGE' &&
                    purchaseDetails?.paymentOrder?.requestedBy?.userId && (
                      <Col xs={4} sm={4} md={4} lg={4}>
                        <div className={styles.infoCard}>
                          <span className={styles.title}>Requested By</span>
                          <span className={`${styles.text} ${getStyleForDeletedUser()}`}>
                            {getRequestedUser(purchaseDetails)}
                          </span>
                        </div>
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    {purchaseDetails?.purchaseItem === 'RECHARGE' &&
                      purchaseDetails?.invoiceNumber &&
                      purchaseDetails?.invoiceUrl &&
                      purchaseDetails?.invoiceId && (
                        <div className={styles.infoCard}>
                          <span className={styles.title}>Invoice</span>
                          <span className={styles.text}>
                            <a
                              href={`${AppConstants.baseURL}invoice?invoiceId=${purchaseDetails.invoiceId}`}
                              target={'_blank'}
                              style={{
                                color: '#2755fe'
                              }}
                            >
                              {purchaseDetails.invoiceNumber}
                            </a>
                          </span>
                        </div>
                      )}
                    {purchaseDetails?.purchaseItem === 'CREDIT_REFUND' &&
                      purchaseDetails?.creditNoteNumber && (
                        <div className={styles.infoCard}>
                          <span className={styles.title}>Credit Note</span>
                          <span className={styles.text}>
                            {purchaseDetails?.invoiceUrl && purchaseDetails?.invoiceId ? (
                              <a
                                href={`${AppConstants.baseURL}invoice?invoiceId=${purchaseDetails.invoiceId}`}
                                target={'_blank'}
                              >
                                {purchaseDetails?.creditNoteNumber}
                              </a>
                            ) : (
                              purchaseDetails?.creditNoteNumber
                            )}
                          </span>
                        </div>
                      )}
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={styles.infoCard}>
                      <span className={styles.title}>Payment Reference</span>
                      <span className={styles.text}>{purchaseDetails?.paymentReference}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Card className={styles.summaryCard}>
                  <Card.Body className={styles.infoCard}>
                    <Card.Text className={styles.text}>
                      {summaryDetails()}
                      <Row className={`${styles.total} mt-2`}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                          TOTAL
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
                          <span className={styles.amount}>
                            &#8377;
                            {calculateTotal()}
                          </span>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Card>
      </Card>
    </div>
  ) : (
    <Loader />
  );
};
