import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { editExternalApiTokenName } from '../../../../../../api/company';
import Button from '../../../../../../core-components/Button';
import Input from '../../../../../../core-components/Input';
import { isValidTokenName } from '../../../../../../utils/ValidationSchema';
import styles from '../../ManageApiToken.module.scss';
import { errToastMessage, getTrimmedValue } from '../../../../../../utils/utilities';

export default (props) => {
  const {
    data,
    show,
    dispatch,
    dataDispatch,
    index,
    getJwtTokenList,
    offset,
    limit,
    getErrorMessageForInvalidTokenName
  } = props;
  const [newName, setNewName] = useState(data[index]?.name);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setNewName(data[index]?.name);
  }, [data[index]]);

  const onChangeHandler = (e) => {
    const value = e?.target?.value;
    setNewName(value);
    setIsValid(isValidTokenName(value));
  };

  const onCloseHandler = () => {
    dispatch({ type: 'TOGGLE_EDIT_MODAL' });
  };

  const onSaveHandler = () => {
    if (newName && newName !== data[index]?.name) {
      const rowData = data[index];
      let payload = { id: rowData?.id, name: newName?.trim() };

      onCloseHandler();
      editExternalApiTokenName(payload)
        .then((resp) => {
          toast.success('Settings updated successfully');
          getJwtTokenList(limit, offset);
        })
        .catch((error) => {
          onCloseHandler();
          errToastMessage('Token with same name already exists');
        });
    }
  };

  return (
    <Modal
      show={show}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      onHide={onCloseHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Token Name</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body style={{ marginBottom: '25px' }}>
          <Form.Row>
            <Form.Label htmlFor='tokenName'>Name</Form.Label>
            <Input
              id='tokenName'
              style={!isValid ? { borderColor: 'red' } : null}
              value={newName}
              onChange={onChangeHandler}
              type='text'
              onBlur={() => {
                setIsValid(isValidTokenName(getTrimmedValue(newName, true)));
                setNewName(getTrimmedValue(newName, true));
              }}
            />
            {!isValid ? (
              <div style={{ color: 'red', fontSize: '14px' }}>
                {getErrorMessageForInvalidTokenName(newName)}
              </div>
            ) : null}
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCloseHandler} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={onSaveHandler}
            className={styles.submitButton}
            disabled={!newName || newName === data[index]?.name || !isValid}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
