import { connect } from 'react-redux';
import { compose } from 'redux';
import { getReferrals as getReferralsAPI } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    referralData: state?.referralData?.data?.referrals || {},
    referralDataCount: state?.referralData?.data?.count || 0
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReferrals: (data) => dispatch(getReferralsAPI(data))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
