import React, { useState, lazy, Suspense, memo, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Field } from 'formik';
import { toast } from 'react-toastify';
import { useGoogleLogin } from '@react-oauth/google';
import AppConstants from '../../core-components/AppConstants';
import styles from './Login.module.scss';
import { errToastMessage, getErrorMessage } from '../../utils/utilities';
import {
  EXPIRED_PASSWORD_ERROR_TEXT_LOGIN,
  EXPIRED_PASSWORD_ERROR_TEXT,
  PASSWORD_NOT_SET_ERROR_TEXT,
  INACTIVE_USER_ERROR_TEXT
} from '../../utils/commonConstant';
import { useMsal } from '@azure/msal-react';
import Loader from '../../core-components/Loader/Loader';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import Image from '../../core-components/Image';

const ValidatedFormInputField = lazy(() =>
  import('../../core-components/ValidatedFormInputField/ValidatedFormInputField')
);
const Button = lazy(() => import('../../core-components/Button'));

const LoginForm = (props) => {
  const loginMethod = new URLSearchParams(document.location.search).get('method') || null;
  const flow = new URLSearchParams(document.location.search).get('flow') || '';
  const redirectTo = new URLSearchParams(document.location.search).get('redirectTo') || '';

  const params = new URLSearchParams(props.location.search);
  const isPasswordExpired = params.get('password_expired');
  const [isLoading, setLoading] = useState(false);
  const [googleOrMsLoginErr, setGoogleOrMsLoginErr] = useState('');
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: ''
  });
  const { instance } = useMsal();

  useEffect(() => {
    if (flow === 'changePassword') {
      errToastMessage('Password reset email sent.');
      setGoogleOrMsLoginErr(EXPIRED_PASSWORD_ERROR_TEXT);
      setTimeout(() => {
        props?.history?.push({ search: '' });
      }, 1000);
    }
  }, [flow]);

  const { handleSubmit, isSubmitting, errors, values, setFieldValue } = props;
  const msReqBody = {
    scopes: ['User.Read'],
    redirectUri: `${AppConstants.baseURL}redirect`
  };

  const handleMicrosoftLogin = async () => {
    setGoogleOrMsLoginErr('');
    setLoading(true);
    try {
      const loginResponse = await instance.acquireTokenPopup(msReqBody);
      window.sessionStorage.clear();
      handleSuccess(null, loginResponse);
    } catch (error) {
      getErrorMessage(error);
      if (error?.toString().includes('popup_window_error')) {
        instance['browserStorage'].clear();
        handleMicrosoftRedirectionLogin();
        return;
      }
      setLoading(false);
    }
  };

  const passwordExpired = sessionStorage.getItem('passwordExpired');

  useEffect(() => {
    if (passwordExpired) {
      props.history.push('/signIn?password_expired=true');
    }
  }, []);
  const handleMicrosoftRedirectionLogin = () => {
    try {
      instance.acquireTokenRedirect({ scopes: ['User.Read'] });
    } catch (error) {
      console.error(error);
    }
  };
  const handleSuccess = async (googleData, microsoftData = null) => {
    setGoogleOrMsLoginErr('');
    props?.resetForm();
    setLoading(true);
    const url = microsoftData != null ? 'auth/microsoft-login' : 'auth/google-login';
    const reqBody = {};
    if (googleData?.code) {
      reqBody.code = googleData.code;
      reqBody.post_message = true;
    } else if (googleData?.credential) {
      reqBody.token = googleData.credential;
    } else if (microsoftData?.accessToken) {
      reqBody.token = microsoftData.accessToken;
    }
    props
      .googleMicrosoftLogin(url, reqBody)
      .then((res) => {
        setLoading(false);
        props.history.push('/dashboard');
      })
      .catch((err) => {
        props?.resetForm();
        setLoading(false);
        const data = err?.data;
        if (
          (data?.body && !data?.body?.login_method_google) ||
          (data?.body && !data?.body?.login_method_microsoft)
        ) {
          setGoogleOrMsLoginErr(data?.message || '');
        } else {
          errToastMessage(data?.message);
        }
      });
  };

  const handleFailure = (e) => {
    setLoading(false);
    console.error(e.error);
  };

  useEffect(() => {
    if (loginMethod === 'g') {
      setLoading(true);
      setTimeout(googleRedirectionLogin, 1000);
    } else if (loginMethod === 'ms') {
      setLoading(true);
      handleMicrosoftRedirectionLogin();
    }
  }, [loginMethod]);

  const googleRedirectionLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleSuccess(codeResponse);
    },
    ux_mode: 'redirect',
    flow: 'auth-code',
    redirect_uri: `${AppConstants.baseURL}google-redirect`,
    onError: (error) => handleFailure(error)
  });

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const handleEmailChange = (e) => {
    const value = e?.target?.value?.replace(/\s/g, '');
    setFieldValue('email', value);
  };

  const handleEmailBlur = (e) => {
    const trimmedValue = e?.target?.value?.trim();
    setFieldValue('email', trimmedValue);
  };

  return (
    <div className={styles.loginWrapper}>
      <Suspense fallback={<div>Loading...</div>}>
        <div className={styles.loginCard} data-testid='login-box'>
          <div className={styles.loginWelcomeNote}>
            <div className={styles.h1x}>Welcome to</div>
            <div className={styles.h2x}>SpringVerify!</div>
          </div>
          <div className={styles.authContainer}>
            <div
              className={styles.microsoftLoginContainer}
              onClick={googleRedirectionLogin}
              style={{ backgroundColor: '#1a73e8' }}
            >
              <Image
                name='googleIcon.png'
                style={{ backgroundColor: 'white', borderRadius: '3px' }}
              />
              <div className={styles.microsoftLoginText}>Sign in with Google</div>
            </div>
            <div
              className={styles.microsoftLoginContainer}
              onClick={handleMicrosoftRedirectionLogin}
              style={{ backgroundColor: '#2f2f2f' }}
            >
              <Image
                name='microsoftIcon.png'
                style={{ backgroundColor: 'white', borderRadius: '3px' }}
              />
              <div className={styles.microsoftLoginText}>Sign in with Microsoft</div>
            </div>
            <div className={styles.separator} style={{ margin: '10px 0px' }}>
              OR
            </div>
          </div>
          <Form autoComplete='off'>
            <Field
              data-testid='email-field'
              component={ValidatedFormInputField}
              type='text'
              name='email'
              placeholder='Enter Email'
              label='Email ID'
              autoComplete='off'
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
            <Field
              data-testid='password-field'
              component={ValidatedFormInputField}
              type='password'
              name='password'
              placeholder='Enter Password'
              label='Password'
              autoComplete='off'
            />
            <div className='d-flex w-100 justify-content-end'>
              <Button
                data-testid='forgot-password-button'
                onClick={() => props.history.push('/forgot-password')}
                className={styles.forgotPasswordBtn}
              >
                Forgot password?
              </Button>
            </div>
            <div>
              <Button
                type='submit'
                disabled={
                  isSubmitting ||
                  !values.email ||
                  !values.password ||
                  (props.values.loginError === PASSWORD_NOT_SET_ERROR_TEXT ||
                  props.values.loginError === EXPIRED_PASSWORD_ERROR_TEXT ||
                  props.values.loginError.includes(INACTIVE_USER_ERROR_TEXT)
                    ? isEqual(loginInfo.email, values.email)
                    : props.values.loginError || (errors && !isEmpty(errors)))
                }
                className={styles.signInBtn}
                onClick={() => {
                  setGoogleOrMsLoginErr('');
                  setLoginInfo({
                    email: values.email,
                    password: values.password
                  });

                  handleSubmit();
                }}
                data-testid='login-button'
              >
                Sign In
              </Button>
            </div>
            {props.values.loginError && (
              <div className={styles.errorMsg}>{props.values.loginError}</div>
            )}
            {!props.values.loginError && isPasswordExpired && (
              <div className={styles.errorMsg}>{EXPIRED_PASSWORD_ERROR_TEXT_LOGIN}</div>
            )}
            {googleOrMsLoginErr && <div className={styles.errorMsg}>{googleOrMsLoginErr}</div>}
          </Form>
        </div>
      </Suspense>
      {isLoading && (
        <div className={styles.overlayLoader}>
          <Loader></Loader>
        </div>
      )}
    </div>
  );
};

export default memo(withRouter(LoginForm));
