import React, { useEffect, useState } from 'react';
import { connectSlack } from '../../../api/company';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

export default function SlackConnect() {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');

  const handleSlackConnect = async () => {
    const res = await connectSlack();
    setLoading(false);
    setMsg(res?.data?.data?.message);
    if (res?.data?.data?.ok) {
      toast.success(res?.data?.data?.message, {
        onClose: () => {
          window.location.href = '/dashboard';
        }
      });
    } else {
      toast.error(res?.data?.data?.message, {
        onClose: () => {
          window.location.href = '/dashboard';
        }
      });
    }
  };

  useEffect(() => {
    handleSlackConnect();
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: 99,
        top: 0,
        opacity: 0.8,
        backdropFilter: 'blur(5px)'
      }}
    >
      {loading ? (
        <Spinner animation='border' variant='light' />
      ) : (
        <p style={{ color: 'white', fontSize: '18px' }}>
          {`${msg}. You will be automatically redirected to the dashboard.`}
        </p>
      )}
    </div>
  );
}
