import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import styles from './Razorpay.module.scss';
import Image from '../Image';
import logger from '../Logger';
import { getErrorMessage } from '../Utilities/utilities';
import AppConstants from '../../../../../core-components/AppConstants';
import { errToastMessage } from '../../utils/Utilities';

function RazorpayButton({
  payableAmount,
  createOrder,
  createOrderPayload,
  buttonText,
  displayValue,
  buttonIcon,
  onSuccess,
  onFailure,
  setPaymentStatus,
  onPaymentSuccess,
  setOrderId,
  disable,
  setPurchasedPackageName,
  onHide,
  setShowLoader,
  type,
  setTransactionId,
  setCreditTxnDetails,
  invalidateCreditTxnDetails,
  isSA
}) {
  const [loading, setLoading] = useState(false);
  let orderID = '';
  const initializeRazorpayFlow = () => {
    setLoading(true);
    invalidateCreditTxnDetails();
    createOrder(createOrderPayload)
      .then((svResponse) => {
        let createOrderResponse = svResponse.data.data;
        orderID = createOrderResponse?.razorpayResp?.razor_pay_order_id
          ? createOrderResponse?.razorpayResp?.razor_pay_order_id
          : createOrderResponse?.razorPayResp?.razor_pay_order_id;
        setOrderId ? setOrderId(orderID) : null;
        let rzpPayload = {
          order_id: orderID,
          payableAmountWithTax: createOrderResponse?.razorpayResp?.inr
            ? createOrderResponse?.razorpayResp?.inr
            : createOrderResponse?.razorPayResp?.inr
        };
        invokeRazorpayModal(rzpPayload);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message?.includes('exceeds')) {
          errToastMessage(
            'Max transaction limit allowed is 20 Lakhs. Contact cs@springverify.com to complete this purchase'
          );
        } else {
          errToastMessage(getErrorMessage(error) || error.message);
        }
        setLoading(false);
      });
  };

  const invokeRazorpayModal = ({ order_id, payableAmountWithTax }) => {
    openPortal({
      order_id: order_id,
      amount: payableAmountWithTax,
      modal: {
        //Called when popup is dismissed
        ondismiss: () => {
          onFailure({
            razorpay_order_id: orderID
          });
          setPaymentStatus('failed');
          logger.push({
            message: `Credit transaction failed`,
            data: JSON.stringify({ order_id: order_id, amount: payableAmountWithTax }),
            category: `razorpay-payment-failure:-${order_id}`,
            type: type
          });
        }
      },
      handler: (response) => {
        let formattedResponse = {
          amount: payableAmountWithTax,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature
        };
        createOrderPayload.company_id
          ? (formattedResponse.company_id = createOrderPayload.company_id)
          : null;
        typeof onHide === 'function' && onHide();

        if (setShowLoader !== undefined) setShowLoader(true);

        onSuccess(formattedResponse)
          .then((res) => {
            if (setShowLoader !== undefined) setShowLoader(false);
            if (onPaymentSuccess) onPaymentSuccess();
            setPaymentStatus('completed');

            // Different response for purchase-complete & finalize-razorpay API
            const creditTransaction =
              res?.data?.data?.resp?.creditTransaction ||
              res?.data?.data?.creditTxn?.paymentCreditTransaction;

            if (creditTransaction) {
              setCreditTxnDetails(creditTransaction);
            }

            if (setTransactionId) {
              if (creditTransaction?.internal_order_id) {
                setTransactionId(creditTransaction?.internal_order_id);
              } else {
                if (Array.isArray(creditTransaction) && creditTransaction?.length > 1) {
                  const response = creditTransaction?.filter(
                    (value) =>
                      value.source === 'RECHARGE' ||
                      (value.source === 'CREDIT_REFUND' && value.methdod === 'PAYMENT_GATEWAY')
                  );
                  setTransactionId(response[0]?.internal_order_id);
                } else if (creditTransaction?.length === 1) {
                  setTransactionId(creditTransaction[0]?.internal_order_id);
                }
              }
            }
            typeof setPurchasedPackageName === 'function' &&
              setPurchasedPackageName(res?.data?.data?.packageDetailsResp?.packageName);
            logger.push({
              message: `Credit Purchase data for CompanyId ${res?.data?.data?.resp?.createLog?.company_id}`,
              data: JSON.stringify(res?.data?.data?.resp),
              category: `razorpay-payment-success-${formattedResponse.razorpay_payment_id}`,
              type: type
            });
          })
          .catch((res) => {
            setPaymentStatus('failed');
            logger.push({
              message: `Credit transaction failed for CompanyId ${res?.data?.data?.resp?.createLog?.company_id}`,
              data: JSON.stringify(res?.data?.data?.resp),
              category: `razorpay-payment-failure`,
              type: type
            });
          });
      }
    });
  };

  const openPortal = (options) => {
    options = { ...options, ...AppConstants.razorpayDefault };
    var rzp = new Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', function (response) {
      setPaymentStatus('pending');
    });
  };

  return (
    <div className={styles.addBtnContainer}>
      <Button
        variant={'primary'}
        disabled={disable || loading}
        className={styles.payBtn}
        onClick={() => initializeRazorpayFlow()}
      >
        {buttonIcon && <Image className='mr-1' name={buttonIcon} isSA={isSA} />}
        {buttonText ? <span>{buttonText} </span> : <span>{'Pay'}</span>}
        {displayValue && (
          <span className={styles.rupee} style={{ marginLeft: '5px' }}>
            &#8377;
            {displayValue || 0}
          </span>
        )}
      </Button>
    </div>
  );
}

export default RazorpayButton;
