import React from 'react';

import Button from '../../../../core-components/Button';
import { fileUpload as fileUploadAPI } from '../../../../api/company';
import BrandPage from '../../frontend-common/AlumniVerification/BrandSettingsPage/BrandPage';

const Brand = (props) => {
  const { brandSettings, getBrandSettings, editBrandSettings, profile } = props;

  return (
    <BrandPage
      Button={Button}
      fileUploadAPI={fileUploadAPI}
      isSa={false}
      brandSettings={brandSettings}
      getBrandSettings={getBrandSettings}
      editBrandSettings={editBrandSettings}
      profile={profile}
    />
  );
};

export default Brand;
