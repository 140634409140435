import * as Yup from 'yup';
import {
  getEmailValidationSchema,
  getNameValidationSchema,
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  fullName: getNameValidationSchema({ message: 'full name', isRequired: true }),
  email: getEmailValidationSchema({ isRequired: true }),
});
