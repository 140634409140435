import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Table, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import Dropdown from '../../core-components/Dropdown';
import Loader from '../../core-components/Loader';
import ValidatedRadioFields from '../../core-components/RadioOptions';
import styles from './StepTwo.module.scss';
import {
  dropDownValuesHandler,
  formatEditedDataForApi,
  getRadioOptions,
  getDataWithError,
  getDataWithoutError,
  getIsValid,
  checkEmptyFields,
  formatExEmpData
} from '../utils/BulkUploadUtilities';
import { getHeaders, getTableRows } from './TableRowsUtils';
import { getErrorMessage, getTrimmedValue } from '../../core-components/Utilities/utilities';
import AppConstants from '../../../../../core-components/AppConstants';
import { toast } from 'react-toastify';
import { ADMIN_URL } from '../../core-components/Utilities/CommonConstants';
import { errToastMessage } from '../../utils/Utilities';
const companyId = new URLSearchParams(document.location.search).get('id');
const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));

const StepTwo = (props) => {
  const {
    values,
    setFieldValue,
    handleSubmit,
    bulkData,
    bulkUploadExEmp,
    handleStepOne,
    resetForm
  } = props;
  const {
    tableData,
    loadingData,
    filterValue,
    totalCount,
    errorCount,
    noErrorCount,
    filteredDataCandidatesWithError,
    filteredDataCandidatesWithOutError,
    buttonClicked,
    dropDownValues
  } = values;
  useEffect(() => {
    resetForm();
  }, [bulkData]);
  // when button is clicked first time then set filter value to ='all'
  useEffect(() => {
    if (buttonClicked && !filterValue) {
      setFieldValue('filterValue', 'all');
    }
  }, [tableData]);
  // if filter value changes get data according to it
  useEffect(() => {
    let newDataExEmp = [...tableData];
    if (filterValue === 'withError') {
      setFieldValue('filteredDataCandidatesWithError', getDataWithError(newDataExEmp));
    } else if (filterValue === 'withoutError') {
      setFieldValue('filteredDataCandidatesWithOutError', getDataWithoutError(newDataExEmp));
    }
  }, [filterValue]);

  // function to hanlde values for  drop-down list
  const handleDropDownValues = (fieldName, valueSelected) => {
    setFieldValue('buttonClicked', false);
    const dropDownListValues = dropDownValuesHandler({
      values,
      fieldName,
      dropDownValues,
      valueSelected
    });
    setFieldValue('dropDownValues', dropDownListValues);
  };

  /**
   * if button is clicked to preview-data, and error is there,
   *  then show error else not
   */
  const getInternalErrorMessage = () => {
    const isError = checkEmptyFields(values);
    const noneValueError = getIsValid(tableData, values, true);
    if (noneValueError) {
      return <span>One of the required field is 'None'</span>;
    }
    return isError ? <span>Please select all required fields</span> : '';
  };

  // final submit function
  const submitHandler = (e) => {
    e.preventDefault();
    // setting this state to check whether button was clicked or not
    setFieldValue('buttonClicked', true);
    const data = !buttonClicked
      ? formatExEmpData(bulkData.data, bulkData.column, values)
      : formatEditedDataForApi(tableData);
    setFieldValue('finalData', data);

    const noneValueError = getIsValid(tableData, values, true);
    console.log(noneValueError, data, buttonClicked, props);
    // eslint-disable-next-line no-unused-expressions
    if (!noneValueError && data?.length > 0 && !checkEmptyFields(values)) {
      handleSubmit();
    }
  };

  // seting new value for editable fields in table
  const onChangeHandler = (newValue, index, type) => {
    const rowsCopy =
      filterValue === 'withError' ? [...filteredDataCandidatesWithError] : [...tableData];
    let rowData = rowsCopy[index];

    if (typeof rowData[type] === 'object') {
      rowData[type].value = getTrimmedValue(newValue, false);
    }
    rowsCopy[index] = rowData;

    filterValue === 'withError'
      ? setFieldValue('filteredDataCandidatesWithError', rowsCopy)
      : setFieldValue('tableData', rowsCopy);
  };

  // creating rows data
  const getRowData = (array) => {
    const rows = getTableRows({ array, values, styles, onChangeHandler });
    return <>{rows}</>;
  };

  const getFinalRowData = () => {
    if (['all', 'withError', 'withoutError'].includes(filterValue)) {
      switch (filterValue) {
        case 'withError':
          return getRowData(filteredDataCandidatesWithError);
        case 'withoutError':
          return getRowData(getDataWithoutError(filteredDataCandidatesWithOutError));
        case 'all':
          return getRowData(tableData);
      }
    } else {
      return getRowData(tableData);
    }
  };

  const Data = ({ children }) => {
    return props.isCA ? (
      <Card className={styles.exEmpCard} style={{ marginTop: '-190px' }}>
        {children}
      </Card>
    ) : (
      <div className={styles.exEmpCard}>{children}</div>
    );
  };

  const handleBulkUpload = async () => {
    try {
      const payload = {
        alumni: values?.finalData.map((exEmp) => {
          return {
            full_name: exEmp.full_name,
            date_of_birth: exEmp.date_of_birth,
            date_of_joining: exEmp.date_of_joining,
            last_working_day: exEmp.last_working_day,
            phone_number: exEmp?.phone_number?.toString(),
            personal_email: exEmp.personal_email,
            business_email: exEmp.business_email,
            rm_name: exEmp?.rm_name,
            rm_designation: exEmp?.rm_designation,
            rm_email: exEmp.rm_email,
            rm_phone: exEmp?.rm_phone?.toString(),
            designation: exEmp.designation,
            staff_id: exEmp.staff_id,
            employment_type: exEmp.employment_type,
            exit_type: exEmp.exit_type,
            exit_reason: exEmp.exit_reason,
            eligible_for_rehire: exEmp.eligible_for_rehire,
            ctc: exEmp.ctc
          };
        })
      };
      if (isSA) {
        payload['company_id'] = companyId;
      }
      const response = await bulkUploadExEmp(payload);
      if (response) {
        toast.success('Alumni records added successfully.');
        window.location.href = isSA
          ? `${AppConstants.baseURL}company/alumniVerification?id=${companyId}`
          : `${AppConstants.baseURL}alumni-verification?tab=alumni_records`;
      }
    } catch (error) {
      console.log(error);
      errToastMessage(error);
    }
  };
  return (
    <Data>
      <div className={styles.exEmp}>
        <Row>
          <Col md={2} lg={2} sm={12}>
            <h5 className={styles.heading}>Bulk Alumni Addition</h5>
            <div>
              <div className={styles.columns}>Select columns</div>
              <div className={styles.secondheader}>
                <div className={styles.filters}>
                  <Form onSubmit={submitHandler}>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='staffId'
                        label='Staff ID'
                        value={values.staffId}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                        required={true}
                        color='orange'
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name={'fullName'}
                        label={'Employee Full Name'}
                        value={values.fullName}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                        required={true}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='dateOfBirth'
                        label='Date of birth'
                        value={values.dateOfBirth}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                        required={true}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='designation'
                        label='Designation'
                        value={values.designation}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                        required={true}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='dateOfJoining'
                        label='Date of joining'
                        value={values.dateOfJoining}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                        required={true}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='lastWorkingDay'
                        label='Last working day'
                        value={values.lastWorkingDay}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                        required={true}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='phoneNumber'
                        label='Phone number'
                        value={values.phoneNumber}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='personalEmail'
                        label='Personal email'
                        value={values.personalEmail}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='businessEmail'
                        label='Business Email'
                        value={values.businessEmail}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='employmentType'
                        label='Employment Type'
                        value={values.employmentType}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='exitType'
                        label='Exit Type'
                        value={values.exitType}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='exitReason'
                        label='Exit Reason'
                        value={values.exitReason}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='eligibleForRehire'
                        label='Eligible For Rehire'
                        value={values.eligibleForRehire}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='rmName'
                        label='RM Name'
                        value={values.rmName}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='rmDesignation'
                        label='RM Designation'
                        value={values.rmDesignation}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='rmEmail'
                        label='RM Email'
                        value={values.rmEmail}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='rmPhone'
                        label='RM Phone'
                        value={values.rmPhone}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <div className={styles.filterItems}>
                      <Dropdown
                        data={dropDownValues}
                        name='ctc'
                        label='CTC'
                        value={values.ctc}
                        onChange={setFieldValue}
                        handleDropDownValues={handleDropDownValues}
                      />
                    </div>
                    <Button className={`mr-2 ${styles.approveBtn}`} type='submit'>
                      {!buttonClicked ? 'Preview Data' : 'Reload Data'}
                    </Button>
                    <div className={styles.errorLabel}>{getInternalErrorMessage()}</div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
          <Col md={10}>
            <Row style={{ marginLeft: '35px', marginBottom: '10px' }}>
              <Field
                name='filterValue'
                component={ValidatedRadioFields}
                options={getRadioOptions(
                  buttonClicked,
                  totalCount,
                  errorCount,
                  noErrorCount,
                  tableData
                )}
              />
            </Row>
            <div className={styles.tableContainer}>
              <Table className={styles.exEmpTable} bordered={true}>
                <thead>
                  <tr
                    className={`${styles.header}  ${
                      buttonClicked && tableData?.length > 0
                        ? styles.dataHeader
                        : styles.noDataHeader
                    }`}
                  >
                    {getHeaders()}
                  </tr>
                </thead>
                <tbody>
                  {loadingData ? (
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    getFinalRowData()
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.buttons}>
        <Button
          className={`mr-2 ${styles.backButton}`}
          variant='outline-primary'
          onClick={() => handleStepOne()}
        >
          Re-upload
        </Button>
        <Button
          className={`mr-2 ${
            getDataWithError(tableData, true) === 0 && tableData.length
              ? styles.nextButton
              : styles.disableNextButton
          }`}
          onClick={() => handleBulkUpload()}
          disabled={getIsValid(tableData, values)}
        >
          Submit
        </Button>
      </div>
    </Data>
  );
};

export default StepTwo;
