import React, { useEffect, useState } from 'react';
import styles from './RecommendedChecksModal.module.scss';
import { Button, Modal } from 'react-bootstrap';
import CheckItem from './Components/CheckItem';
import {
  KBA_URL,
  UPSELL_MODAL_CHECK_ICON,
  UPSELL_MODAL_CHECK_INFO_TEXT,
  UPSELL_MODAL_CHECK_NAME
} from '../../../../../utils/commonConstant';
import { updateRecommendedChecks } from '../../../../../api/company';
import classNames from 'classnames';

const RecommendedChecksModal = (props) => {
  /**
   * @description: This state is used to store the checked items in the modal which are selected by the user.
   */
  const [checkedItems, setCheckedItems] = useState({});
  /**
   * @description: This state is used to toggle the view of all checks in the modal.
   */
  const [showMore, setShowMore] = useState(false);
  /**
   * @description: This state is used to toggle the explore button in the modal.
   */
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    const initialCheckedItems = {};

    props?.checksList?.forEach((item) => {
      initialCheckedItems[item] = false;
    });

    setCheckedItems(initialCheckedItems);
  }, []);

  useEffect(() => {
    let isValid = false;

    for (const key in checkedItems) {
      if (checkedItems[key]) {
        isValid = true;
        break;
      }
    }

    setIsValid(isValid);
  }, [checkedItems]);

  // Function to toggle the checked state of an item
  const toggleItem = (itemName) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemName]: !prevCheckedItems[itemName]
    }));
  };

  const onSubmitHandler = () => {
    props.toggleSuccessModal();
  };

  const HeaderContent = () => {
    return (
      <div className={styles.frame}>
        <div className={styles['modal-heading']}>Recommended checks</div>
        <p className={styles.text}>
          <span className={styles['text-wrapper']}>
            Based on your candidate data and industry peers, here are the checks you can opt for to
            improve the quality of hires at your organization.
          </span>
          <span className={styles.span} onClick={() => window.open(KBA_URL)}>
            Read more
          </span>
        </p>
      </div>
    );
  };

  const MostUsedChecks = (props) => {
    return (
      <div
        className={classNames(styles['most-used-checks-parent'], {
          [styles.checksL3]: props?.checksList?.length <= 3
        })}
      >
        <div className={styles['most-used-checks-div']}>
          <div className={styles['most-used-checks-label']}>MOST USED CHECKS</div>
        </div>
        {props?.checksList?.map((check, index) => {
          if (index < 3)
            return (
              <div className={styles.checkContainer} data-testid={`most-used-check-${check}`}>
                <CheckItem
                  checkName={UPSELL_MODAL_CHECK_NAME[check]?.fullName}
                  checkInfo={UPSELL_MODAL_CHECK_INFO_TEXT[check]}
                  checkIcon={UPSELL_MODAL_CHECK_ICON[check]}
                  toggleItem={toggleItem}
                  checkedItems={checkedItems}
                  check={check}
                  data-testid={`checkbox-${check}`}
                />
                <div className={styles['lineStyle']}></div>
              </div>
            );
        })}
      </div>
    );
  };

  const OtherChecks = (props) => {
    const OTHER_CHECKS_LIST = props?.checksList?.length > 3 ? props?.checksList.slice(3) : [];

    const toggleViewAllChecks = () => setShowMore((prevShowMore) => !prevShowMore);

    return OTHER_CHECKS_LIST.length ? (
      <div className={styles['other-checks-parent']}>
        <div className={styles['other-checks-div']}>
          <div className={styles['other-checks-label']}>OTHER CHECKS</div>
        </div>
        {OTHER_CHECKS_LIST.map((check, index) => {
          if (index > 3 && !showMore) return null;
          return (
            <div>
              <CheckItem
                checkName={UPSELL_MODAL_CHECK_NAME[check]?.fullName}
                checkInfo={UPSELL_MODAL_CHECK_INFO_TEXT[check]}
                checkIcon={UPSELL_MODAL_CHECK_ICON[check]}
                toggleItem={toggleItem}
                checkedItems={checkedItems}
                check={check}
              />
              <div
                className={classNames(styles['lineStyle'], {
                  [styles.visibleHidden]: OTHER_CHECKS_LIST.length <= 1
                })}
              ></div>
            </div>
          );
        })}
        {OTHER_CHECKS_LIST.length > 4 && !showMore ? (
          <div className={styles['view-all-checks-text']} onClick={toggleViewAllChecks}>
            View all checks
          </div>
        ) : null}
      </div>
    ) : null;
  };

  const FooterContent = (props) => {
    const handleClick = () => {
      setSubmitting(true);
      let data = {
        upsell_recommendation_id: '',
        explored_checks: []
      };

      data.upsell_recommendation_id = props?.upsellRecommendationId;

      for (const key in checkedItems) {
        if (checkedItems[key]) {
          data.explored_checks.push(key);
        }
      }

      updateRecommendedChecksApi(data);
    };

    const updateRecommendedChecksApi = (data) => {
      updateRecommendedChecks(data)
        .then((res) => {
          onSubmitHandler();
        })
        .catch((err) => {
          setSubmitting(false);
          console.log('Error in updating recommended checks', err);
        });
    };

    return (
      <div>
        <Button
          disabled={!isValid || isSubmitting}
          onClick={handleClick}
          className={styles['explore-button']}
          variant='primary'
        >
          Request Callback
        </Button>
      </div>
    );
  };

  const RibbonContent = () => {
    return (
      <div className={styles.ribbon}>
        <p className={styles.ribbonText}>
          Click &#34;Request Callback&#34; to enable your chosen checks for your company through
          your dedicated CSM
        </p>
      </div>
    );
  };

  return (
    <Modal show={props.show} onHide={props.onClose} centered dialogClassName={styles['modal-90w']}>
      <Modal.Header className={styles.closeContainer} closeButton></Modal.Header>
      <HeaderContent checksList={props?.checksList} />
      <RibbonContent />
      <Modal.Body className={styles['modal-body']}>
        <MostUsedChecks checksList={props?.checksList} />
        <OtherChecks checksList={props?.checksList} />
      </Modal.Body>

      <Modal.Footer className={styles['modal-footer']}>
        <FooterContent upsellRecommendationId={props?.upsellRecommendationId} />
      </Modal.Footer>
    </Modal>
  );
};

export default RecommendedChecksModal;
