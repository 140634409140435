export default {
  identity: {
    any: 0,
    config: []
  },
  address: {
    any: 0,
    config: []
  },
  court: {
    any: 0,
    config: []
  },
  employment: 0,
  education: {
    count: 0,
    config: [],
    type: '',
    method: ''
  },
  educationOfficial: {
    count: 0,
    config: [],
    type: '',
    method: 'OFFICIAL'
  },
  educationHybrid: {
    count: 0,
    config: [],
    type: '',
    method: 'HYBRID'
  },
  educationRegionalPartner: {
    count: 0,
    config: [],
    type: '',
    method: 'REGIONAL_PARTNER'
  },
  educationType: '',
  reference: {
    count: 0,
    config: []
  },
  reference: {
    count: 0,
    config: []
  },
  world: false,
  cibil: false,
  drug10Panel: false,
  drug5Panel: false,
  drug7Panel: false
};
