import React, { useEffect } from 'react';
import styles from './Finish.module.scss';
import Image from '../../core-components/Image/Image';
import { toast } from 'react-toastify';
import { ADMIN_URL } from '../../core-components/Utilities/CommonConstants';
import AppConstants from '../../../../../core-components/AppConstants';
import { createFile } from '../Components/Table/utils';
const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));
const companyId = new URLSearchParams(document.location.search).get('id');

export default function Finish({ setCurrentStep, data }) {
  useEffect(() => {
    toast.success('Alumni records added successfully.');
  }, []);

  return (
    <div className={styles.finishContainer}>
      <div className={styles.finishHeading}>
        <Image name='success.svg' style={{ height: '35px', marginRight: '20px' }} />
        Uploaded Successfully
      </div>
      <div
        className={styles.finishText}
      >{`${data?.length} alumni records added successfully.`}</div>
      <div className={styles.downloadBtnContainer}>
        Download uploaded data:
        <Image
          name='csv.svg'
          onClick={() => createFile({ data, type: 'csv', fileName: 'AlumniRecords' })}
          style={{ height: '30px', marginLeft: '20px' }}
        />
        <Image
          name='ExcelLogo.svg'
          onClick={() => createFile({ data, type: 'excel', fileName: 'AlumniRecords' })}
          style={{ height: '35px' }}
        />
      </div>
      <div className={styles.go_back}>
        <button
          onClick={() => {
            window.location.reload();
          }}
          className='btn btn-primary'
        >
          Upload More
        </button>
        <button
          onClick={() => {
            window.location.href = isSA
              ? `${AppConstants.baseURL}company/alumniVerification?id=${companyId}&tab=alumni_records`
              : `${AppConstants.baseURL}alumni-verification?tab=alumni_records`;
          }}
          className='btn btn-primary'
        >
          View Records
        </button>
      </div>
    </div>
  );
}
