// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nqCi_pKQ4CmHkKhS2rnb {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}

.LgwM32YTLaIuYIyQQKOT {
  margin-top: 10px;
  cursor: pointer;
}

.lcJfhhZ6pVdRc1BHjcTA {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Banners/UpsellLaptop/UpsellLaptopBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".bannerWrapper {\n  width: 100%;\n  max-width: 1100px;\n  margin: 0 auto;\n  overflow: hidden;\n  cursor: pointer;\n}\n\n.marginTop {\n  margin-top: 10px;\n  cursor: pointer;\n}\n\n.bannerImage {\n  width: 100%;\n  height: auto;\n  display: block;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerWrapper": `nqCi_pKQ4CmHkKhS2rnb`,
	"marginTop": `LgwM32YTLaIuYIyQQKOT`,
	"bannerImage": `lcJfhhZ6pVdRc1BHjcTA`
};
export default ___CSS_LOADER_EXPORT___;
