import React, { useState, useEffect } from 'react';
import styles from './DateRangePicker.module.scss';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import Image from '../../core-components/Image/Image';

export default function DateRangePicker({ form: { setFieldValue } }) {
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [dateRangeShow, setDateRangeShow] = useState({ startDate: null, endDate: null });
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const dateChange = () => {
    if (dateRangeShow?.endDate && setFieldValue) {
      setFieldValue('startDate', dateRangeShow.startDate);
      setFieldValue('endDate', dateRangeShow.endDate);
    }
    if (dateRangeShow?.startDate !== null && dateRangeShow?.endDate !== null) {
      setOpenDateSelector(false);
      setDateRange((prevState) => ({
        ...prevState,
        startDate: moment(dateRangeShow?.startDate).format('YYYY-MM-DD'),
        endDate: moment(dateRangeShow?.endDate).format('YYYY-MM-DD')
      }));
    }
  };

  useEffect(() => {
    dateChange();
  }, [dateRangeShow]);

  const handleOutSideClick = () => {
    setTimeout(() => {
      setOpenDateSelector(false);
    }, 100);
  };

  const clearDateFilter = () => {
    setFocusedInput('startDate');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
    setDateRangeShow((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null
    }));
    setDateRange((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null
    }));
  };

  return (
    <div data-testid="dateRangePickerContainer">
      <div className={styles.dateFilterField}>
        <div
          onClick={() => {
            if (isEmpty(dateRange.startDate) && isEmpty(dateRange.endDate))
              setOpenDateSelector(!openDateSelector);
          }}
          className={styles.dateInputField}
        >
          <div>
            <div className={styles.dateTextField}>
              <div
                className={
                  !isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate)
                    ? styles.paddingLeft
                    : styles.topTextPadding
                }
              >
                {!isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate) ? (
                  <p className={`${styles.dateText} ${styles.dateTextWidth}`}>
                    {moment(dateRangeShow?.startDate).format('DD-MM-YY')} to{' '}
                    {moment(dateRangeShow?.endDate).format('DD-MM-YY')}
                  </p>
                ) : (
                  <p className={styles.textDateRange}>Select date range</p>
                )}
              </div>
              <div
                className={
                  !isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate)
                    ? styles.paddingRight
                    : styles.topTextPadding
                }
              >
                {!isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate) ? (
                  <Image onClick={clearDateFilter} name='closeIcon.svg' data-testid="closeIcon" />
                ) : (
                  <Image singleDateRange={true} minimumNights={0} name='Mask.svg' />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dateRangePickerDiv} data-testid="dateRangePickerDiv">
        {openDateSelector && (
          <DayPickerRangeController
            onOutsideClick={handleOutSideClick}
            numberOfMonths={2}
            isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
            startDate={dateRangeShow.startDate}
            endDate={dateRangeShow.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDateRangeShow({
                startDate: startDate,
                endDate: endDate
              })
            }
            minimumNights={0}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) =>
              setFocusedInput(!focusedInput ? 'startDate' : focusedInput)
            }
          />
        )}
      </div>
    </div>
  );
}
