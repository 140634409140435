import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../core-components/Loader';
import styles from './RedirectionHandler.module.scss';
import { redirectToPreviousPathAfterLogin } from '../../utils/utilities';

const RedirectionHandler = (props) => {
  useEffect(() => {
    // If redirectTo contains value it will redirect the same else dashboard
    redirectToPreviousPathAfterLogin(props?.history);
  }, []);

  return (
    <div className={styles.loaderContainer}>
      <Loader />
    </div>
  );
};

export default withRouter(RedirectionHandler);
