import React from 'react';

import { Card, Button, Container } from 'react-bootstrap';
import styles from './PaymentStatus.module.scss';
import { Link } from 'react-router-dom';
import Image from '../../../core-components/Image/Image';
import localStorage from '../../../api/localStorage';
import AppConstants from '../../../core-components/AppConstants';

export default function PaymentStatus({ orderDetails, profile }) {
  const token = localStorage.getToken();

  const statusItem = {
    CANCELLED: {
      title: 'Order cancelled',
      subtitle: `Sorry. This order has been cancelled by ${orderDetails?.cancelledByUser?.name}.`,
      image: 'cancelled.png',
      btnText: token
        ? profile?.profile?.access_level === 5
          ? 'Buy'
          : 'Go to Dashboard'
        : 'Login to Buy',
      referenceNumber: orderDetails?.proformaInvoiceNumber
    },
    EXPIRED: {
      title: 'Order expired',
      subtitle: `Sorry. This order has been expired due to non-payment of over by 30 days.`,
      image: 'expired.png',
      btnText: token
        ? profile?.profile?.access_level === 5
          ? 'Buy'
          : 'Go to Dashboard'
        : 'Login to Buy',
      referenceNumber: orderDetails?.proformaInvoiceNumber
    },
    SUCCESS: {
      title: 'Thank you for your purchase',
      subtitle: `Payment has been completed successfully.`,
      image: 'PaymentSuccess.svg',
      btnText: 'View invoice',
      referenceNumber: orderDetails?.proformaInvoiceNumber || '-'
    },
    PAYMENT_INPROGRESS: {
      title: 'Payment is in progress',
      subtitle: `Payment is already initiated by someone from your team. Please wait for sometime and reload the page for update. `,
      image: 'hourglass.svg',
      btnText: token
        ? profile?.profile?.access_level === 5
          ? 'Buy'
          : 'Go to Dashboard'
        : 'Login to Buy',
      referenceNumber: orderDetails?.proformaInvoiceNumber || '-'
    }
  };

  const handleBuyBtn = () => {
    if (['CANCELLED', 'EXPIRED', 'PAYMENT_INPROGRESS'].includes(orderDetails.status)) {
      token
        ? profile?.profile?.access_level === 5
          ? window.open(`${AppConstants.baseURL}balance`, '_self')
          : window.open(`${AppConstants.baseURL}dashboard`, '_self')
        : window.open(`${AppConstants.baseURL}login`, '_self');
    } else {
      window.open(`${AppConstants.baseURL}invoice?invoiceId=${orderDetails?.invoiceId}`, '_blank');
    }
  };
  const handleViewDetails = () => {
    profile?.profile?.access_level === 5
      ? window.open(
        `${AppConstants.baseURL}transactionDetails?id=${orderDetails?.transactionId}`,
        '_self'
      )
      : window.open(`${AppConstants.baseURL}dashboard`, '_self');
  };

  return (
    <Card className={`text-center ${styles.card}`}>
      <Card.Body className={`${styles.body}`}>
        <Image className={styles.image} name={statusItem[orderDetails.status].image} />
        <Card.Title
          className={`${orderDetails.status === 'SUCCESS' ? 'text-success mb-4' : 'text-secondary mb-4'
            } ${styles.payment_title}`}
        >
          {statusItem[orderDetails.status].title}
        </Card.Title>
        <Card.Text className='mb-4'>{statusItem[orderDetails.status].subtitle}</Card.Text>
        <Card.Text className={styles.referenceText}>
          Reference Number: {statusItem[orderDetails.status].referenceNumber}
        </Card.Text>
        {!['SUCCESS', 'PAYMENT_INPROGRESS'].includes(orderDetails.status) && (
          <Card.Text className='m-3'>{'You may create a new order again.'}</Card.Text>
        )}
        <Container className={styles.btnContainer}>
          <Button onClick={handleBuyBtn} variant='primary'>
            {statusItem[orderDetails.status].btnText}
          </Button>
          {token && orderDetails.status === 'SUCCESS' && profile?.profile?.access_level === 5 ? (
            <Button onClick={handleViewDetails} variant='outline-primary'>
              View details
            </Button>
          ) : null}
        </Container>
        {orderDetails.status === 'SUCCESS' ? (
          <Card.Text className={styles.invoiceText}>
            Tax Invoice has been emailed to the original recipients
          </Card.Text>
        ) : null}
        <Card.Text className={styles.help}>
          <small className={`text-muted`}>
            Need help ?{' '}
            <Link onClick={() => window.open('mailto:cs@springverify.com', '_blank')}>
              Contact us
            </Link>
          </small>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
