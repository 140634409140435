import React from 'react';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Banner.module.scss';

export default (props) => {
  return (
    <div className={styles.bannerContainer}>
      <div>
        <FontAwesomeIcon icon={faInfoCircle} color={'#1890FF'} className={styles.image} />
      </div>
      <div>
        <h3 className={styles.heading}>IMPORTANT</h3>
        <div>
          <ul className={styles.listItems}>
            <li className={styles.listItem}>
              You can only select a single package for all the candidates that are uploaded through
              one file
            </li>
            <li>
              Any add-on checks selected, will be enabled and charged for all the candidates in the
              file
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
