// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.cS39A5Pt9iFC5_alPpxw {
  border: unset;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ {
  max-width: 500px;
  margin: 90px auto;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ img {
  margin-bottom: 32px;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ .qC33DBFh38NNG35TQEyw {
  color: #333;
  font-size: 24px;
  font-weight: 400;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ span {
  font-size: 14px;
  color: #2755fe;
  font-weight: 400;
  cursor: pointer;
}
.cS39A5Pt9iFC5_alPpxw .ZfOPpXrMWE23AmQmSoDJ p {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/CreateCandidate/AccessRestricted/AccessRestricted.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;;AACA;EACE,aAAA;AAEF;AAAE;EACE,gBAAA;EACA,iBAAA;AAEJ;AAAI;EACE,mBAAA;AAEN;AACI;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AACN;AACI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACN;AACI;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACN","sourcesContent":["* {\n  font-family: Poppins, sans-serif;\n}\n.card {\n  border: unset;\n\n  .body {\n    max-width: 500px;\n    margin: 90px auto;\n\n    img {\n      margin-bottom: 32px;\n    }\n\n    .title {\n      color: #333;\n      font-size: 24px;\n      font-weight: 400;\n    }\n    span {\n      font-size: 14px;\n      color: #2755fe;\n      font-weight: 400;\n      cursor: pointer;\n    }\n    p {\n      font-size: 14px;\n      font-weight: 400;\n      color: #666;\n      margin-bottom: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `cS39A5Pt9iFC5_alPpxw`,
	"body": `ZfOPpXrMWE23AmQmSoDJ`,
	"title": `qC33DBFh38NNG35TQEyw`
};
export default ___CSS_LOADER_EXPORT___;
