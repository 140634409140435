import React from 'react';
import styles from './UpsellCarousel.module.scss';
import Image from '../../../../../../core-components/Image';
import {
  UPSELL_MODAL_CHECK_ICON,
  UPSELL_MODAL_CHECK_NAME
} from '../../../../../../utils/commonConstant';
import Marquee from 'react-fast-marquee';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const UpsellCarousel = (props) => {
  const getSpanText = (check) => {
    return UPSELL_MODAL_CHECK_NAME[check]?.shortName;
  };

  return (
    <div className={styles.container} onClick={props?.onClickExploreNowHandler}>
      <Marquee speed={50} pauseOnHover={true}>
        <div className={styles.marquee}>
          {props?.checksList &&
            props?.checksList?.map((check) => {
              return (
                <div key={check} className={styles.icon}>
                  <OverlayTrigger
                    className={''}
                    placement='bottom'
                    overlay={<Tooltip className='upSellTooltip'>{getSpanText(check)}</Tooltip>}
                  >
                    <Image className={styles.checkIcon} name={UPSELL_MODAL_CHECK_ICON[check]} />
                  </OverlayTrigger>
                </div>
              );
            })}
        </div>
      </Marquee>
    </div>
  );
};

export default UpsellCarousel;
