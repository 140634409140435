import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
const utils = {
  isValidName: (name) => {
    const allowedSpecialCharacters = ['.', '-', "'"];
    if (
      name.length < 2 ||
      allowedSpecialCharacters.includes(name.charAt(0)) ||
      allowedSpecialCharacters.includes(name.charAt(name.length - 1))
    ) {
      return false;
    }
    let prev = '';
    // Check for consecutive '.' or '-' and for invalid characters
    for (let i = 0; i < name.length; i++) {
      const curr = name[i];
      if (!curr.match(/[a-zA-Z.\s'-]/)) {
        return false;
      }
      if (allowedSpecialCharacters.includes(curr) && allowedSpecialCharacters.includes(prev)) {
        return false;
      }
      if (curr !== ' ') {
        prev = curr;
      }
    }
    return true;
  },

  isAtLeastNYearsDate: ({ date, nYearsToValidate }) => {
    const inputDate = new Date(date);
    const currentDate = new Date();
    // Calculate the difference in years
    const diffInYears = currentDate.getFullYear() - inputDate.getFullYear();
    // Check if the age is at least nYearsToValidate years
    if (diffInYears < nYearsToValidate) {
      return false;
    }
    // Check if the date has occurred this year
    if (
      diffInYears === nYearsToValidate &&
      (currentDate.getMonth() < inputDate.getMonth() ||
        (currentDate.getMonth() === inputDate.getMonth() &&
          currentDate.getDate() < inputDate.getDate()))
    ) {
      return false;
    }
    return true;
  },

  formatPhoneNumber: ({ phoneNumber }) => {
    // Regular expression to match numeric characters and the plus sign
    const regex = /[0-9+]/g;
    const numericString = String(phoneNumber).match(regex);
    if (numericString === null) {
      return '';
    }
    return numericString.join('');
  },

  isValidPhoneNumber: (value, isRelaxedRule = false) => {
    if (isRelaxedRule) {
      const relaxedRegexRule = /^[a-zA-Z0-9,\-+\/;'[\] ()]+$/;
      const isValid = relaxedRegexRule.test(value);

      return { valid: isValid };
    }
    const regexForTenDigit = /^[6-9]{1}\d{9}/;
    try {
      let parsedPhone = null;
      let country;
      let phone = String(value).trim();
      if (!phone) {
        return { valid: false };
      }
      // to check no other special characters/alphabets are accepted expect digits and +
      if (!/^\+?[\d]+$/i.test(phone)) {
        return { valid: false };
      }
      // Numbers without country codes ( assuming indian numbers )
      if (phone[0] !== '+' && Number.isInteger(parseInt(phone[0], 10))) {
        if (phone.length === 10 && phone.match(regexForTenDigit)) {
          phone = `+91${phone}`;
        } else if (phone.length === 12 && phone.slice(2).match(regexForTenDigit)) {
          phone = `+91${phone.slice(2)}`;
        } else if (phone[0] === '0' && phone.slice(1).match(regexForTenDigit)) {
          phone = `+91${phone.slice(1)}`;
        } else {
          return { valid: false };
        }
      }
      if (phone.slice(0, 3) === '+91') {
        // library was allowing indian numbers with less than 10 digits
        if (!phone.slice(3).match(regexForTenDigit)) {
          return { valid: false };
        }
        country = 'IN';
      }
      // parse number ( parses numbers in specific formats also e.g. (+91) 123 456 7890 )
      // also parses numbers with different country codes
      parsedPhone = parsePhoneNumber(phone, country);
      if (parsedPhone) {
        phone = parsedPhone.number;
      }
      // validate the parsed number
      if (!isValidPhoneNumber(phone)) {
        return { valid: false };
      }

      return { valid: (parsedPhone && parsedPhone.number) || phone };
    } catch (err) {
      // try catch block was required because parsePhoneNumber throws an error if number is invalid
      return { valid: false };
    }
  }
};

export default utils;
