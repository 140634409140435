import { useEffect } from 'react';
import localStorage from '../../api/localStorage';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../utils/utilities';

export default (props) => {
  const { history } = props;
  const user = useSelector((s) => s?.profile?.profile);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    props
      ?.logout()
      .then(() => {
        const isVisitedAddCandidate = localStorage.getItem('isVisitedAddCandidate' + user?.id);
        localStorage.clearStorage();
        sessionStorage.clear();
        localStorage.deleteToken();
        if (isVisitedAddCandidate) {
          localStorage.setItem('isVisitedAddCandidate' + user?.id, isVisitedAddCandidate);
        }
        history.push({
          pathname: '/signIn'
        });
      })
      .catch((error) => {
        console.log(error);
        errToastMessage(error);
      });
  }, [history, props, user.id]);

  return null;
};
