import React from 'react';
import Modal from 'react-bootstrap/Modal';

import styles from './BgvLinkSentModal.module.scss';
import Image from '../../../../core-components/Image';
import AppConstants from '../../../../core-components/AppConstants';
import Button from '../../../../core-components/Button';
import { UPSELL_DISCOUNT_BANNER_DATE } from '../../../../utils/commonConstant';

export default (props) => {
  const upsellBannerWithOutDiscountImgDate = new Date(UPSELL_DISCOUNT_BANNER_DATE);
  const currentDate = new Date();

  const { addAnotherCandidateHandler, banners = [], content } = props;
  const isShowBuyRentLaptop = banners.includes('upsellLaptop') ? true : false;

  const getContent = () => {
    if (content === 'BGV_LINK_SENT') {
      return 'BGV Link has been sent to the candidate';
    } else if (content === 'CANDIDATE_ADDED') {
      return 'Candidate details has been added successfully';
    }
  };
  const getButtonContent = () => {
    if (content === 'BGV_LINK_SENT') {
      return 'Add more Candidates';
    } else if (content === 'CANDIDATE_ADDED') {
      return 'Proceed';
    }
  };

  return (
    <Modal
      size={'lg'}
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className={'addCandidateModal'}
      backdrop='static'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className={styles.modalBody}>
          <Image name={'GreenTickModal.svg'} />
          <div className={styles.bgvLinkSentText}>{getContent()}</div>
          {content === 'CANDIDATE_ADDED' && (
            <div className={styles.subContent}>{'You can proceed with more details'}</div>
          )}
          <div className={styles.whatsNextText}>{'What’s next?'}</div>
        </div>
        {currentDate <= upsellBannerWithOutDiscountImgDate ? (
          <Image name={'upsell-modal-discount.svg'} />
        ) : (
          <Image name={'upsell-modal.svg'} />
        )}
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <div className={styles.buttonFooter}>
          <Button className={styles.buyRentButton} onClick={addAnotherCandidateHandler}>
            {getButtonContent()}
          </Button>
          <Button
            className={isShowBuyRentLaptop ? styles.submitButton : styles.cancelButton}
            onClick={() => {
              if (isShowBuyRentLaptop) {
                window.open(`${AppConstants.baseURL}buy-rent-laptop`, '_blank');
              }
              props?.onHide();
            }}
          >
            💻 Buy laptop
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
