import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import { formValuesToAPI } from '../../../CommonComponent/Bgv.mapper';
import { errToastMessage, toSnakeCase } from '../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  setBgvSubmission as setBgvSubmissionAPI
} from '../../../../../actions/bgv';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data))
  };
};

const mapStateToProps = (state) => {
  return {
    cached_data: !state?.bgvCachedData?.data ? null : { ...state?.bgvCachedData?.data },
    config: !state?.bgvPackageConfig?.data ? null : { ...state?.bgvPackageConfig?.data },
    isLoadingBgvCache: state?.bgvCachedData?.loading
  };
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    enableReinitialize: true,
    // validationSchema,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      // we will update the current state value only in the case if we are saving the detail for first time.
      // const updateCurrentState =
      //   !props.cached_data || !props.cached_data.cache_data['id_check'] ? true : false;

      if (props.finalSubmitCheck === 1) {
        try {
          props.validateFinalSubmit(
            formValuesToAPI(toSnakeCase(props.cached_data.cache_data)),
            setSubmitting
          );
        } catch (error) {
          console.error(error);
          setSubmitting(false);
          errToastMessage('The form was not succesfully submitted. Please try again.');
        }
      } else {
        props.nextStep(true);
      }
    },
    displayName: 'Contact Details'
  })
);
