// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pUQok90ivHFtau4w5cYh {
  border-radius: unset;
  color: #2755fe;
  padding: 1px 8px;
  border-color: rgba(153, 153, 153, 0.2);
}
.pUQok90ivHFtau4w5cYh:disabled {
  cursor: not-allowed;
  color: rgba(8, 8, 8, 0.2);
}
.pUQok90ivHFtau4w5cYh:disabled:hover {
  background-color: transparent;
  color: rgba(8, 8, 8, 0.2);
  border: transparent;
}

.fuLO1IQSZALPdbJKBksG {
  color: #2755fe;
  padding: 1px 16px;
  border-radius: unset;
}
.fuLO1IQSZALPdbJKBksG:hover {
  background-color: transparent;
  color: #2755fe;
  border: transparent;
}

._4lsFnDPKQHr7iwMnkWk {
  width: 36px !important;
  height: 36px !important;
  border-radius: 4px !important;
  font-size: 30px !important;
  display: flex !important;
  align-items: center !important;
  border-color: rgba(153, 153, 153, 0.2) !important;
  background-color: #ffffff !important;
  justify-content: center !important;
}
._4lsFnDPKQHr7iwMnkWk:hover {
  background-color: #ffffff !important;
  color: #2755fe !important;
}

.Z6RcWe928hLmSv61_nJs {
  margin-inline: 12px !important;
  border-color: rgba(153, 153, 153, 0.2) !important;
  background-color: #ffffff !important;
  width: 64px !important;
  border-radius: 4px !important;
}`, "",{"version":3,"sources":["webpack://./src/core-components/CustomCounter/CustomCounter.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,sCAAA;AACF;AACE;EACE,mBAAA;EACA,yBAAA;AACJ;AAEI;EACE,6BAAA;EACA,yBAAA;EACA,mBAAA;AAAN;;AAKA;EACE,cAAA;EACA,iBAAA;EACA,oBAAA;AAFF;AAIE;EACE,6BAAA;EACA,cAAA;EACA,mBAAA;AAFJ;;AAMA;EACE,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,0BAAA;EACA,wBAAA;EACA,8BAAA;EACA,iDAAA;EACA,oCAAA;EACA,kCAAA;AAHF;AAIE;EACE,oCAAA;EACA,yBAAA;AAFJ;;AAMA;EACE,8BAAA;EACA,iDAAA;EACA,oCAAA;EACA,sBAAA;EACA,6BAAA;AAHF","sourcesContent":[".counterButton {\n  border-radius: unset;\n  color: #2755fe;\n  padding: 1px 8px;\n  border-color: rgba(153, 153, 153, 0.2);\n\n  &:disabled {\n    cursor: not-allowed;\n    color: rgba(8, 8, 8, 0.2);\n\n    // background-color: #bfbfbf;\n    &:hover {\n      background-color: transparent;\n      color: rgba(8, 8, 8, 0.2);\n      border: transparent;\n    }\n  }\n}\n\n.numberButton {\n  color: #2755fe;\n  padding: 1px 16px;\n  border-radius: unset;\n\n  &:hover {\n    background-color: transparent;\n    color: #2755fe;\n    border: transparent;\n  }\n}\n\n.size36 {\n  width: 36px !important;\n  height: 36px !important;\n  border-radius: 4px !important;\n  font-size: 30px !important;\n  display: flex !important;\n  align-items: center !important;\n  border-color: rgba(153, 153, 153, 0.2) !important;\n  background-color: #ffffff !important;\n  justify-content: center !important;\n  &:hover {\n    background-color: #ffffff !important;\n    color: #2755fe !important;\n  }\n}\n\n.customNumberButton {\n  margin-inline: 12px !important;\n  border-color: rgba(153, 153, 153, 0.2) !important;\n  background-color: #ffffff !important;\n  width: 64px !important;\n  border-radius: 4px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counterButton": `pUQok90ivHFtau4w5cYh`,
	"numberButton": `fuLO1IQSZALPdbJKBksG`,
	"size36": `_4lsFnDPKQHr7iwMnkWk`,
	"customNumberButton": `Z6RcWe928hLmSv61_nJs`
};
export default ___CSS_LOADER_EXPORT___;
