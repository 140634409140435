import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  setIntegration as setIntegrationAPI,
  getViewBuyPackages as getViewBuyPackagesAPI
} from '../../../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    integrationData: state?.integrationData || {},
    viewBuyPackages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) ? state.viewBuyPackages : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIntegrationAPI: (companyId) => dispatch(setIntegrationAPI(companyId)),
    getViewBuyPackages: (data) => dispatch(getViewBuyPackagesAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
