import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styles from '../../ManageApiToken.module.scss';
import Button from '../../../../../../core-components/Button';
import { deleteExternalApiToken } from '../../../../../../api/company';
import { errToastMessage } from '../../../../../../utils/utilities';

export default (props) => {
  const { data, index, dataDispatch, show, dispatch, getJwtTokenList, offset, limit } = props;

  const onCloseHandler = () => {
    dispatch({ type: 'TOGGLE_DELETE_MODAL' });
  };

  const onDeleteHandler = () => {
    dataDispatch({ type: 'SET_LOADING', value: true });
    onCloseHandler();

    const rowId = data[index]?.id;
    let payload = { id: rowId };

    deleteExternalApiToken(payload)
      .then((resp) => {
        toast.info('Token deleted successfully!');
        getJwtTokenList(10, 0);
        dataDispatch({
          type: 'SET_PAGINATION',
          offset: 0,
          limit: 10
        });
      })
      .catch((error) => {
        errToastMessage('Token deletion failed!');
        console.log(error);
      });
  };

  return (
    <Modal
      show={show}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
      onHide={onCloseHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Token</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginBottom: '25px' }}>
        <Form.Row>
          <Form.Label>Are you sure you want to delete this Token?</Form.Label>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCloseHandler} className={styles.cancelButton}>
          Cancel
        </Button>
        <Button onClick={onDeleteHandler} className={styles.deleteButton}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
