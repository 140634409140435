import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

import Image from '../../../core-components/Image';

import styles from './BgvHomeModal.module.scss';

export default ({ show, animation, onHide, companyName, isConsentAlreadyAdded }) => {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      animation={animation}
      onEscapeKeyDown={() => onHide}
      className='bgvHomeModalContainer'
      size='md'
    >
      <Modal.Body className={styles.bgvHomeModalBody}>
        <Row className={styles.homeModalBodyHeader}>
          <Col className={styles.homeModalHeaderContentContainer}>
            <div className={styles.homeModalSVLogo}>
              <Image name='SV_logo_homemodal.svg' />
            </div>
            <div className={styles.homeModalSVIllustration}>
              <Image name='clip-welcome.png' />
            </div>
          </Col>
        </Row>
        <Row className={styles.homeModalBodyContent}>
          <Col className={styles.homeModalBodyContentContainer}>
            <h2>Welcome to SpringVerify</h2>
            <div className={styles.welcomeTopContent}>
              <p>Hi!</p>
              <p>
                Your employment at <strong>{companyName}</strong> mandates that you go through our
                background verification check.
              </p>
            </div>
            <div className={styles.welcomeMiddleContent}>
              <p>Here’s how it works:</p>
              <ul>
                <li>
                  Fill out the form <strong>completely</strong> and <strong>accurately.</strong>
                </li>
                <li>
                  Make sure that the documents uploaded are <strong>clear</strong>.
                </li>
                {!isConsentAlreadyAdded && <li>Sign the consent form.</li>}
              </ul>
            </div>
            <div className={styles.welcomeBottomContent}>
              <p>
                We take your privacy very seriously. You can read about it{' '}
                <a href='https://in.springverify.com/privacy-policy/' target='_blank'>
                  here
                </a>
                .
              </p>
            </div>
            <p>Let’s get you SpringVerified!</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button variant='primary' onClick={onHide} block>
          {' '}
          Get Started{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
