// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cUItJjskymQoiRvpo7bQ {
  width: calc(50% - 10px);
  height: 176px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

.qAcb1mvTAYfGQRAbGi7R {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Tv_U8M5BfrHOOUWj0HsA {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #333333;
}

.qvQp9UL93Myo0ezGxqia {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.kwutUIq2h1uteHAsjhqC {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #333333;
}

.sJiFcflxrPhM_07IwGjz {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #666666;
}

.zgWe7jzLiVzP2rVIzkCB {
  width: 158px;
}

.MVPV1xr8FO9vs9l82TtE {
  width: 180px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/AlumniVerification/Overview/style.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gDAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,8BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,YAAA;AAEF","sourcesContent":[".container {\n  width: calc(50% - 10px);\n  height: 176px;\n  background: #ffffff;\n  border-radius: 4px;\n  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px 30px;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 26px;\n  text-align: left;\n  color: #333333;\n}\n.info {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.unitValue {\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 30px;\n  text-align: left;\n  color: #333333;\n}\n\n.unitTitle {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  text-align: left;\n  color: #666666;\n}\n\n.unit {\n  width: 158px;\n}\n\n.dropdown {\n  width: 180px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cUItJjskymQoiRvpo7bQ`,
	"header": `qAcb1mvTAYfGQRAbGi7R`,
	"title": `Tv_U8M5BfrHOOUWj0HsA`,
	"info": `qvQp9UL93Myo0ezGxqia`,
	"unitValue": `kwutUIq2h1uteHAsjhqC`,
	"unitTitle": `sJiFcflxrPhM_07IwGjz`,
	"unit": `zgWe7jzLiVzP2rVIzkCB`,
	"dropdown": `MVPV1xr8FO9vs9l82TtE`
};
export default ___CSS_LOADER_EXPORT___;
