export default (state, action) => {
  switch (action.type) {
    case 'SHOW_PURCHASE_HISTORY_TAB':
      return { ...state, activeTab: 'PURCHASE_HISTORY' };
    case 'SHOW_USAGE_LOG_TAB':
      return { ...state, activeTab: 'USAGE_LOG' };
    case 'SHOW_PENDING_ORDERS':
      return { ...state, activeTab: 'PENDING_ORDERS' };
    case 'TOGGLE_CREDITS_FLOW_VISIBILITY':
      return { ...state, showCreditsFlow: action.value };
    case 'SET_LOADING_FALSE':
      return { ...state, loading: false };
  }
};
