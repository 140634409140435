import React, { useEffect } from 'react';
import styles from './CreditBuyPackagePage.module.scss';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useState } from 'react';
import StepNavigation from './UtilComponents/Stepper';
import Summary from './Summary';
import Wallet from './Wallet';
import Packages from './Packages';
import ProFeatures from './ProFeatures';
import IncompleteItemModal from '../core-components/IncompleteItemModal/IncompleteItemModal';
import { getAmountAndTextArray, getPackageAmountTotal } from './UtilComponents/UtilitiesFunction';
import _, { flattenDeep, isEmpty } from 'lodash';
import Payments from './Payments';
import { buyOrder } from '../../../../api/company';
import PurchaseConfirmModal from './ConfirmPurchaseModal';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../core-components/Utilities/utilities';
import AppConstants from '../../../../core-components/AppConstants';
import CustomSpinner from '../core-components/CustomSpinner/CustomSpinner';
import { errToastMessage } from '../utils/Utilities';

const labelArray = [
  { label: 'Wallet' },
  { label: 'Packages' }
  //  { label: 'PRO Features' }
];

const CreditBuyPackagePage = (props) => {
  const {
    values,
    setFieldValue,
    walletDetail,
    history,
    invalidateSelectedTiers,
    selectedTiers,
    paymentDetails
  } = props;
  const [currentStep, updateCurrentStep] = useState(1);
  const [showIncompleteModal, setShowIncompleteModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [transactionId, setTransactionId] = useState('');
  const [showPurchaseConfirmModal, setShowPurchaseConfirmModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [addedCandidateCount, setAddedCandidateCount] = useState(null);
  const companyId = new URLSearchParams(document.location.search).get('company_id');
  const [isAdcFlow, setIsAdcFlow] = useState(false);
  const [requestPaymentUpdateFlag, setRequestPaymentUpdateFlag] = useState({
    flag: false,
    piNumber: '',
    orderId: ''
  });

  useEffect(() => {
    invalidateSelectedTiers();
    props.getWalletDetail(companyId);
    const urlParam = new URLSearchParams(document.location.search).get('flow');
    if (urlParam === 'approval') {
      setIsAdcFlow(true);
    }
  }, []);

  useEffect(() => {
    if (props.dirty || !isEmpty(props.selectedTiers)) {
      setShowIncompleteModal(true);
    } else {
      setShowIncompleteModal(false);
    }
  }, [values, props.selectedTiers]);

  useEffect(() => {
    if (requestPaymentUpdateFlag.flag) {
      if (props.isSA) {
        window.location.reload();
      } else {
        history.push({
          pathname: '/purchase/payment-success',
          search: `?proforma_number=${requestPaymentUpdateFlag.piNumber}&order_id=${requestPaymentUpdateFlag.orderId}`
        });
      }
    }
  }, [requestPaymentUpdateFlag]);

  const paymentRequestHandler = (piNumber, orderNumber) => {
    // This func will not show the Incomplete purchase modal and refresh the page when successful payment is requested to show balance summary page
    setShowIncompleteModal(false);
    setRequestPaymentUpdateFlag({ flag: true, piNumber: piNumber, orderId: orderNumber });
  };

  function updateStep(step) {
    if (step >= 1 && step <= 2) updateCurrentStep(step);
    if (step == 1) {
      setFieldValue('useCurrentWalletBalance', false);
    }
  }

  const getPageTitle = (step) => {
    switch (step) {
      case 1:
        return (
          <Wallet
            setFieldValue={setFieldValue}
            values={values}
            amountArray={getAmountAndTextArray(walletDetail)}
            isSA={props.isSA}
          />
        );
      case 2:
        return <Packages isSA={props.isSA} />;
      // case 3:
      //   return <ProFeatures  isSA={props.isSA}/>;
    }
  };
  const getPaymentObj = () => {
    const payload = {
      company_id: companyId,
      packages: [],
      credits: values.amount !== 'custom' ? parseInt(values.amount) : parseInt(String(values.customAmount).replace(/,/g, '')),
      tds_percentage: values.tdsPercentage !== 'NONE' ? values.tdsPercentage : 0,
      use_current_wallet_balance: values.useCurrentWalletBalance
    };
    let packagesArray = [];
    if (selectedTiers && !isEmpty(selectedTiers)) {
      const flattenedPackages = flattenDeep(Object.values(selectedTiers));
      Object.values(_.groupBy(flattenedPackages, 'id')).map((ob) => {
        packagesArray.push({
          package_id: ob[0].package_id,
          count: ob.length,
          recharge_available_options_id: ob[0].id
        });
      });
      payload['packages'] = packagesArray;
    }

    return payload;
  };
  const onPaymentSuccess = () => {
    setShowPaymentModal(true);
    invalidateSelectedTiers();
    updateCurrentStep(1);
    props.getWalletDetail(companyId);
    setLoading(false);
  };
  const onPaymentFailure = () => {
    setShowPaymentModal(true);
    setLoading(false);
  };
  const handleBuy = () => {
    setOrderId(null);
    setTransactionId(null);
    const payload = getPaymentObj();
    buyOrder(payload)
      .then((res) => {
        const initialValue = 0;
        const sumWithInitial = res.data.data.pkgResp
          .map((ap) => ap.number_of_allowed_candidates)
          .reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
        setAddedCandidateCount(sumWithInitial);
        setShowPurchaseConfirmModal(false);
        setPaymentStatus('completed');
        onPaymentSuccess();
      })
      .catch((err) => {
        setShowPurchaseConfirmModal(false);
        errToastMessage(err);
        setLoading(false);
      });
  };
  const closeModal = (isSuccess = false) => {
    setLoading(true);
    setShowPaymentModal(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (isSuccess) {
      if (isAdcFlow) {
        location.href = `${AppConstants.baseURL}approval`;
      } else {
        location.reload();
      }
    }
  };
  if (isLoading) {
    return (
      <Card className={styles.loaderCard}>
        <div className={styles.loader}>
          <CustomSpinner
            className='bgvLoader'
            as='div'
            animation='border'
            size='md'
            role='status'
            aria-hidden='true'
          />
        </div>
      </Card>
    );
  }
  return (
    <>
      {showPaymentModal ? (
        <Payments
          hideModal={(isSuccess) => closeModal(isSuccess)}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          orderId={orderId}
          addedCandidateCount={addedCandidateCount}
          isAdcFlow={isAdcFlow}
          description={
            <>
              {paymentStatus === 'pending' && <p>This might take a few seconds</p>}
              {paymentStatus === 'completed' && !addedCandidateCount && <p>Successful</p>}
              {paymentStatus === 'completed' && addedCandidateCount && (
                <p>Packages purchased for {addedCandidateCount} candidates</p>
              )}
              {paymentStatus === 'failed' && (
                <p>
                  Something went wrong with the payment gateway. Please try again in a few minutes.
                </p>
              )}
            </>
          }
          buttons={
            <>
              {transactionId || orderId ? (
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    setShowIncompleteModal(false);
                    window.open(
                      `${AppConstants.baseURL}transactionDetails?id=${transactionId}`,
                      '_blank'
                    );
                    closeModal(true);
                  }}
                >
                  {'View details'}
                </Button>
              ) : !transactionId && !orderId ? (
                <Button
                  variant='primary'
                  onClick={() => {
                    setLoading(true);
                    window.location = `${AppConstants.baseURL}candidate/create`;
                  }}
                >
                  {'Add candidates'}
                </Button>
              ) : null}
            </>
          }
        />
      ) : (
        <>
          <div className={styles.buyPackageContainer}>
            <div className={styles.heading}>What would you like to buy today?</div>
            <Row className={styles.cardContainer}>
              <Col lg={8} md={8} sm={8} xs={12}>
                <Card className={styles.leftContainer}>{getPageTitle(currentStep)}</Card>
              </Col>
              <Col lg={4} md={4} sm={4} xs={12}>
                <Summary {...props} currentStep={currentStep} />
              </Col>
            </Row>
            <IncompleteItemModal
              when={showIncompleteModal} // Same as "when" prop of Prompt of React-Router
              navigate={(path) => history.push(path)}
              shouldBlockNavigation={(location) => {
                // Here you can add the cases where you want to blocks the navigation
                if (showIncompleteModal) {
                  //Added this one because it is showing modal when we click on buy link itself.
                  if (location.pathname !== '/buy') {
                    return true;
                  }
                }
                return false;
              }}
              heading={'Incomplete Purchase'}
              subHeading={
                <div>
                  Items selected for purchase will be lost if you navigate away from this page. You
                  will have to re-add them again. <br />
                  <div style={{ paddingTop: '10px', fontWeight: 'bold' }}>Are you sure?</div>
                </div>
              }
            />
          </div>
          <div className={styles.stepNavigationCard}>
            <StepNavigation
              labelArray={labelArray}
              values={values}
              currentStep={currentStep}
              updateStep={updateStep}
              paymentObj={getPaymentObj()}
              setShowLoader={setLoading}
              onPaymentSuccess={onPaymentSuccess}
              onPaymentFailure={onPaymentFailure}
              setPaymentStatus={setPaymentStatus}
              setOrderId={setOrderId}
              setTransactionId={setTransactionId}
              handleBuy={() => setShowPurchaseConfirmModal(true)}
              isSA={props.isSA}
              paymentRequestSend={paymentRequestHandler}
            />
          </div>
        </>
      )}
      {showPurchaseConfirmModal ? (
        <PurchaseConfirmModal
          useCreditBalance={values.useCurrentWalletBalance}
          show={showPurchaseConfirmModal}
          onHide={() => {
            setShowPurchaseConfirmModal(false);
          }}
          setShowLoader={setShowLoader}
          onConfirm={handleBuy}
          creditBalance={getPackageAmountTotal(selectedTiers)}
          isSA={props.isSA}
        />
      ) : null}
    </>
  );
};

CreditBuyPackagePage.defaultProps = {
  isCA: false, // to check whether component is imported in CA side or not
  isSA: false // to check whether component is imported in SA side or not
};

export default CreditBuyPackagePage;
