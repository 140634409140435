import { connect } from 'react-redux';

import {
  getCategories,
  INVALIDATE_CATEGORIES,
  getCompanyPaymentDetails
} from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    categories: state.categories
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (limit, offset) => {
      return dispatch(getCategories(limit, offset));
    },
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails())
  };
};

export { mapDispatchToProps, mapStateToProps }; //Exported for writing testcases
export default connect(mapStateToProps, mapDispatchToProps);
