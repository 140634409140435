import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from '../../../../core-components/Button';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField/ValidatedFormInputField';
import styles from '../Profile.module.scss';
import { isEmpty } from 'lodash';

const CompanyProfile = (props) => {
  const [isCompanyEdit, setIsCompanyEdit] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const values = props?.values;
  const profileInfo = props?.profile?.profile;
  const companyName = profileInfo?.company_user_mapping?.company?.name;
  const { handleSubmit, isSubmitting, status, errors } = props;
  const [url, setUrl] = useState(profileInfo?.company_user_mapping?.company?.company_url);
  const [logo, setLogo] = useState(false);
  const isAdmin = localStorage.accessLevel === 'admin';

  useEffect(() => {
    if (!status) {
      setIsCompanyEdit(false);
    }
  }, [status]);

  function handle_Change(e) {
    var company_url = e.target.value;
    company_url.includes('https://') && company_url.replace('https://', 'www.');
    company_url.includes('http://') && company_url.replace('http://', 'www.');
    setNewUrl(company_url);
    setUrl(`https://logo.clearbit.com/${newUrl}`);
    values.logo_url = `https://logo.clearbit.com/${newUrl}`;
    setLogo(true);
  }

  return (
    <Card className={styles.cardInner}>
      <div className={styles.cardHead}>
        <div className={styles.cardHeading}>{'Company Profile'}</div>
        <div className={styles.cardHeadBtn}>
          {isAdmin ? (
            isCompanyEdit ? null : (
              <Button className={styles.editBtn} onClick={() => setIsCompanyEdit(!isCompanyEdit)}>
                {'Edit'}
              </Button>
            )
          ) : null}
        </div>
      </div>
      <Form className={styles.Form} onSubmit={handleSubmit} title='company'>
        <div className={styles.cardParticular}>
          <div className={styles.cardParticularTitle}>{'Company Name'}</div>
          <div className={styles.cardParticularValue}>
            {profileInfo?.company_user_mapping?.company?.name}
          </div>
        </div>
        <div className={styles.cardParticular}>
          <div className={styles.cardParticularTitle}>{'Company Friendly Name'}</div>
          <div className={styles.cardParticularValue}>
            {profileInfo?.company_user_mapping?.company?.friendly_name}
          </div>
        </div>
        <div className={styles.cardParticular}>
          <div className={styles.cardParticularTitle}>{'Company-website'}</div>
          {isCompanyEdit ? (
            <div className={styles.cardParticularFieldValue}>
              <Field
                component={ValidatedFormInputField}
                type='text'
                name='company_url'
                label='Company-website'
                onMouseOver={handle_Change}
                data-testid='company_url_input'
              />
            </div>
          ) : (
            <div className={styles.cardParticularValue}>
              {profileInfo?.company_user_mapping?.company?.company_url}
            </div>
          )}
        </div>
        <div className={styles.cardParticular}>
          <div className={styles.cardParticularTitle}>{'City'}</div>
          {isCompanyEdit ? (
            <div className={styles.cardParticularFieldValue}>
              <Field component={ValidatedFormInputField} type='text' name='city' />
            </div>
          ) : (
            <div className={styles.cardParticularValue}>
              {profileInfo?.company_user_mapping?.company?.city}
            </div>
          )}
        </div>
        <div className={styles.cardParticular}>
          <div className={styles.cardParticularTitle}>{'Logo'}</div>
          {logo ? (
            <img
              src={url}
              alt="company-logo"
              onError={() => {
                setUrl(`https://place-hold.it/250x50/666/fff.png/000?text=${companyName}&bold`);
                values.logo_url = `https://place-hold.it/250x50/666/fff.png/000?text=${companyName}&bold`;
              }}
            ></img>
          ) : (
            <img
              src={profileInfo?.company_user_mapping?.company?.logo_url}
              alt="company-logo"
              onError={() => {
                setUrl(`https://place-hold.it/250x50/666/fff.png/000?text=${companyName}&bold`);
              }}
            ></img>
          )}
        </div>
        {isCompanyEdit ? (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.updateBtn}
              type='submit'
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {'Update'}
            </Button>
            <Button
              className={styles.cancelBtn}
              onClick={() => {
                setIsCompanyEdit(!isCompanyEdit);
                window.location.reload(true);
              }}
            >
              {'Cancel'}
            </Button>
          </div>
        ) : null}
      </Form>
    </Card>
  );
};

export default CompanyProfile;