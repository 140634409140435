import * as Yup from 'yup';
import {
  getNameValidationSchema,
  getStrictNameValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getNameValidationSchema({ message: 'name', isRequired: true }),
  mobile: Yup.string().when('isValidPhone', {
    is: false,
    then: Yup.string('Please provide a valid Mobile Number')
  })
});
