import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import validationSchema from './EditEmail.validation';
import { mergeWith } from 'lodash';
import { editEmail as editEmailAPI } from '../../../../api/company';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../../../utils/utilities';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let initialValue = {
        email: props?.email || '',
        emailError: { error: true, errorMsg: 'Please provide a new email' }
      };
      return mergeWith({}, initialValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      setSubmitting(true);
      const finalPayloads = {
        email: values.email,
        email_bounce: true,
        candidate_id: props.candidateId
      };
      editEmailAPI(finalPayloads)
        .then((res) => {
          props.onHide(true);
          setSubmitting(false);
        })
        .catch((err) => {
          console.log(err.response);
          setSubmitting(false);
          if (
            err.response.data.message ==
            "A candidate with this email already exists, hence can't proceed"
          ) {
            setFieldValue('emailError', { error: true, errorMsg: 'Email already exits' });
          } else {
            errToastMessage(err);
            resetForm();
            props.onHide();
          }
        });
      // TO_ DO API INTEGRATION
    },
    displayName: 'Edit Email'
  })
);
