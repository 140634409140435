import { connect } from 'react-redux';
import { getHRMSSyncData } from '../../../../actions/company';
import { getAlumniTablesData, getAlumniRecordOverview } from '/src/actions/company';

const mapStateToProps = (state) => {
  return {
    HRMSSyncData: state.HRMSSyncData,
    alumniRecords: state?.alumniTableData?.rows || {},
    alumniRecordsCount: state?.alumniTableData?.count || 0
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHRMSSyncData: () => dispatch(getHRMSSyncData()),
    getAlumniTablesData: (data, dataType) => dispatch(getAlumniTablesData(data, dataType)),
    getRecordOverview: () => dispatch(getAlumniRecordOverview())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
