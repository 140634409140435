import * as Yup from 'yup';
import { getTrimmedValue } from '../../../../utils/utilities';
import { getNameValidationSchema } from '../../../../utils/ValidationSchema';
getNameValidationSchema;

export default Yup.object().shape({
  friendlyName: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .required('Field cannot be empty')
    .min(1, 'Min. character limit is 1')
    .max(20, 'Max. character limit is 20')
});
