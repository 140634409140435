import React from 'react';
import styles from './BgvSidebar.module.scss';
import Image from '../../../core-components/Image/Image';
import { toast } from 'react-toastify';
import { errToastMessage } from '../../../utils/utilities';

const BgvSidebar = ({ onClose }) => {
  const zoho = window?.$zoho;
  const handleChatbot = () => {
    if (zoho) {
      zoho.salesiq?.floatwindow?.visible('show');
      onClose();
    } else {
      errToastMessage('Chatbot not enabled..!');
    }
  };
  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarHeader}>
        <div className={styles.LogoContainer}>
          <Image name='sv_logo_desktop.svg' />
          <div className={styles.LogoText}>SpringVerify</div>
        </div>
        <button onClick={onClose} className={styles.CloseBtn}>
          &#x2715;
        </button>
      </div>

      <div className={styles.sidebarBody}>
        <ul className={styles.sidebarMenu}>
          <li>
            <a href='https://compliance.springworks.in/' target='_blank'>
              View our Trust Vault
            </a>
          </li>
          <li>
            <a onClick={handleChatbot}>Contact us</a>
          </li>
        </ul>
      </div>

      <div className={styles.sidebarFooter}>
        <div className={styles.FooterLogoContainer}>
          <Image name='sv_logo_desktop.svg' />
          <div className={styles.LogoText}> {new Date().getFullYear()} &#169; SpringRole</div>
        </div>
      </div>
    </div>
  );
};

export default BgvSidebar;
