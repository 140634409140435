import React, { useEffect, useState } from 'react';
import styles from './TabComponent.module.scss';

export default (props) => {
  const { activeTab, tabItems, handleTabChange } = props;

  // To Set Active tab from parent
  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  const [active, setActive] = useState(tabItems[0]);
  return (
    <div className={styles.tabComponentWrapper}>
      {tabItems.map((tab, index) => (
        <div className={styles.tabStyles}>
          <div
            key={index}
            className={active === tab ? styles.tabActive : null}
            onClick={() => {
              handleTabChange(tab);
              setActive(tab);
            }}
          >
            {tab}
          </div>
        </div>
      ))}
    </div>
  );
};
