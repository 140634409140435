import React, { useState, useEffect, useRef } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field } from 'formik';
import ValidatedFormInputField from '../../../../../core-components/ValidatedFormInputField';
import ValidatedRadioFields from '../../../../../core-components/ValidatedRadioFields';
import Checkbox from '../../../../../core-components/Checkbox';
import styles from './EmailContent.module.scss';
import { getTrimmedValue, getTrimmedValueWithNewLines } from '../../../../../utils/utilities';
import Image from '../../../../../core-components/Image/Image';
const _ = require('lodash');
export default function EmailContent(props) {
  const { values, setFieldValue, profile, formConfig, resetForm } = props;
  const previousStateValues = useRef(null);
  const [brandNameError, setBrandNameError] = useState('');
  const [isLogosGood, setIsLogosGood] = useState(false);

  const isValidImageUrl = async (url) => {
    if (!url) return null;

    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = url;
      img.onload = () => {
        resolve(img.height > 60);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  };

  const setLogoErrors = async () => {
    const logos = Object.values(values.companyLogos).map((e) => e.logoUrl);
    if (logos.length === 0) return setIsLogosGood(false);
    const isValid = await Promise.all(logos.map(isValidImageUrl));
    if (isValid.includes(false)) {
      setIsLogosGood(false);
    } else {
      setIsLogosGood(true);
    }
  };

  useEffect(() => {
    setLogoErrors();
  }, [values]);

  const hrNoteCount =
    values?.hrInviteNoteDefaultOrCustom == 1
      ? values?.defaultHrInviteNote.trim().length
      : values?.hrInviteNote?.trim()?.length;
  const hrWarningNoteCount =
    values?.hrWarningNoteDefaultOrCustom == 1
      ? values?.defaultHrWarningNote.trim().length
      : values?.hrWarningNote?.trim()?.length;

  const [hrInviteNoteRadioOptions, setHrInviteNoteRadioOptions] = useState([
    { value: '1', label: 'Default', disable: true },
    { value: '2', label: 'Custom', disable: true }
  ]);
  const [hrWarningNoteRadioOptions, setHrWarningNoteRadioOptions] = useState([
    { value: '1', label: 'Default', disable: true },
    { value: '2', label: 'Custom', disable: true }
  ]);

  const lastSettingValidation = () => {
    if (
      formConfig?.company?.friendlyName === values?.friendlyName &&
      formConfig?.hrInviteNote === values?.hrInviteNote &&
      formConfig?.hrWarningNote === values?.hrWarningNote &&
      formConfig?.hrInviteNoteStatus === values?.hrInviteNoteStatus &&
      formConfig?.hrWarningNoteStatus === values?.hrWarningNoteStatus &&
      (formConfig?.company?.logoUrlId === values?.logoUrlId ||
        (!formConfig?.company?.logoUrlId && !values?.logoUrlId)) &&
      (formConfig?.company?.useLogoInInviteMail === values?.useLogoInInviteMail ||
        (!formConfig?.company?.useLogoInInviteMail && !values?.useLogoInInviteMail)) &&
      previousStateValues?.current?.hrInviteNoteDefaultOrCustom ==
        values?.hrInviteNoteDefaultOrCustom &&
      previousStateValues?.current?.hrWarningNoteDefaultOrCustom ==
        values?.hrWarningNoteDefaultOrCustom &&
      formConfig?.isBrandEnabled == values?.isBrandEnabled
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValidForm =
    (hrNoteCount >= 10 && hrNoteCount <= 160 ? true : false) &&
    (hrWarningNoteCount >= 10 && hrWarningNoteCount <= 160 ? true : false) &&
    !brandNameError &&
    !lastSettingValidation();

  useEffect(() => {
    props.getCandidateFormConfig();
  }, []);

  useEffect(() => {
    const prevValue = {
      hrInviteNoteDefaultOrCustom:
        props?.formConfig?.hrInviteNote === values?.defaultHrInviteNote ? 1 : 2,
      hrWarningNoteDefaultOrCustom:
        props?.formConfig?.hrWarningNote === values?.defaultHrWarningNote ? 1 : 2
    };
    previousStateValues.current = prevValue;
  }, [props.formConfig]);

  useEffect(() => {
    const selectedLogo =
      Object.values(values?.companyLogos).find((logo) => logo.id === values?.logoUrlId)?.logoUrl ||
      null;
    props.setEmailContentData({ ...values, logoUrl: selectedLogo });
    props.isValidForm(isValidForm);
    const url = selectedLogo;
    if (url) {
      const img = new window.Image();
      img.src = url;
      img.onload = () => {
        const { naturalWidth: w, naturalHeight: h } = img;
        setFieldValue('logoUrl', w < 60 || h < 60 ? null : values.logoUrl);
      };
    }
    if (values.hrInviteNoteStatus) {
      setHrInviteNoteRadioOptions([
        { value: '1', label: 'Default' },
        { value: '2', label: 'Custom' }
      ]);
    } else {
      //To remove the default selected, I am making the values as 3 and 4
      setFieldValue('hrInviteNoteDefaultOrCustom', 1);
      setHrInviteNoteRadioOptions([
        { value: '3', label: 'Default', disable: true },
        { value: '4', label: 'Custom', disable: true }
      ]);
    }

    if (values.hrWarningNoteStatus) {
      setHrWarningNoteRadioOptions([
        { value: '1', label: 'Default' },
        { value: '2', label: 'Custom' }
      ]);
    } else {
      //To remove the default selected, I am making the values as 3 and 4
      setFieldValue('hrWarningNoteDefaultOrCustom', 1);
      setHrWarningNoteRadioOptions([
        { value: '3', label: 'Default', disable: true },
        { value: '4', label: 'Custom', disable: true }
      ]);
    }
  }, [values, isValidForm]);

  const validateAndSetBrandName = (e, setVal = false) => {
    const value = getTrimmedValue(e.target.value, true);
    const brandNameRegex = /^[a-zA-Z0-9 &().]*$/;
    if (!brandNameRegex.test(value)) {
      setBrandNameError('Please provide a valid brand name');
      return;
    }
    if (setVal) setFieldValue('friendlyName', value);
    setBrandNameError('');
  };

  useEffect(() => {
    if (props?.resetFormData) {
      resetForm();
      props.clearResetFormData(false);
    }
  }, [props?.resetFormData]);

  return (
    <div className={styles.emailContentWrapper}>
      <Form>
        <div className={styles.sectionHeader}>
          Brand Name
          <OverlayTrigger
            key={`password_protected`}
            placement='top'
            style
            overlay={
              <Tooltip id={`password_protected`} style={{ width: '200px' }}>
                <p>To edit the brand name please go to Brand settings tab.</p>
              </Tooltip>
            }
          >
            <Image name='info.svg' className={'ml-2'} />
          </OverlayTrigger>
        </div>
        <div className={styles.sectionSubHeader} style={{ marginBottom: '5px' }}>
          This will help your candidates recognize BGV emails
        </div>
        <div style={{ position: 'relative' }}>
          <Field
            component={ValidatedFormInputField}
            type='text'
            name='friendlyName'
            errors={brandNameError}
            placeholder='Brand Name'
            onInput={(event) => {
              validateAndSetBrandName(event);
              if (event.target.value.trim().length > 160) {
                event.target.value = event.target.value.trim().slice(0, 160);
              }
            }}
            onBlur={(e) => {
              validateAndSetBrandName(e, true);
            }}
            disabled
            style={{ border: '1px solid #ced4da' }}
          />
          <Field
            custom
            name='isBrandEnabled'
            className={'custom-checkbox-style-1 ' + styles.cBoxMargin}
            component={Checkbox}
            disabled={!values.friendlyName}
            label='Enabled'
          />
        </div>

        <hr />

        <div style={{ position: 'relative' }}>
          <Form.Group controlId='hrInviteNote'>
            <div className={styles.sectionHeader}>HR Note (In all invite emails)</div>
            <Form.Control
              as='textarea'
              rows={3}
              name='hrInviteNote'
              style={{ resize: 'none' }}
              onInput={(event) => {
                if (event.target.value.trim().length > 160) {
                  event.target.value = event.target.value.trim().slice(0, 160);
                }
              }}
              onBlur={(e) => {
                setFieldValue('hrInviteNote', getTrimmedValueWithNewLines(e.target.value, true));
              }}
              value={
                values.hrInviteNoteDefaultOrCustom == 1
                  ? values.defaultHrInviteNote
                  : values?.hrInviteNote
              }
              disabled={values.hrInviteNoteDefaultOrCustom != 2}
              placeholder='Type a note...'
              onChange={(e) => {
                setFieldValue('hrInviteNote', e.target.value);
              }}
            />
          </Form.Group>
          <div
            className={styles.hrNoteCharacterLimit}
            style={
              hrNoteCount == 160 || (hrNoteCount < 10 && hrNoteCount > 0)
                ? { color: '#DC3545' }
                : null
            }
          >
            Characters {hrNoteCount} out of 160
          </div>
        </div>

        <div className={styles.customRadioAndCheckGroup}>
          <Field
            custom
            name='hrInviteNoteStatus'
            className={'custom-checkbox-style-1 ' + styles.cBoxMargin}
            component={Checkbox}
            label='Enabled'
          />
          <Field
            name='hrInviteNoteDefaultOrCustom'
            component={ValidatedRadioFields}
            className={'custom-radio-style-1'}
            options={hrInviteNoteRadioOptions}
            onChange={() => {
              if (values.hrInviteNoteDefaultOrCustom == 1) {
                setFieldValue('hrInviteNote', props?.formConfig?.hrInviteNote);
              } else {
                setFieldValue('hrInviteNote', values?.defaultHrInviteNote);
              }
            }}
          />
        </div>
        <hr />
        <div style={{ position: 'relative' }}>
          <Form.Group controlId='hrWarningNote'>
            <div className={styles.sectionHeader}>Overdue Note (In all overdue emails)</div>
            <Form.Control
              as='textarea'
              rows={3}
              style={{ resize: 'none' }}
              name='hrWarningNote'
              onInput={(event) => {
                if (event.target.value.trim().length > 160) {
                  event.target.value = event.target.value.trim().slice(0, 160);
                }
              }}
              onBlur={(e) => {
                setFieldValue('hrWarningNote', getTrimmedValueWithNewLines(e.target.value, true));
              }}
              value={
                values.hrWarningNoteDefaultOrCustom == 1
                  ? values.defaultHrWarningNote
                  : values?.hrWarningNote
              }
              disabled={values.hrWarningNoteDefaultOrCustom != 2}
              placeholder='Type a note...'
              onChange={(e) => {
                setFieldValue('hrWarningNote', e.target.value);
              }}
            />
          </Form.Group>
          <div
            className={styles.hrNoteCharacterLimit}
            style={
              hrWarningNoteCount == 160 || (hrWarningNoteCount < 10 && hrWarningNoteCount > 0)
                ? { color: '#DC3545' }
                : null
            }
          >
            Characters {hrWarningNoteCount} out of 160
          </div>
        </div>

        <div className={styles.customRadioAndCheckGroup}>
          <Field
            custom
            name='hrWarningNoteStatus'
            className={'custom-checkbox-style-1 ' + styles.cBoxMargin}
            component={Checkbox}
            label='Enabled'
          />
          <Field
            name='hrWarningNoteDefaultOrCustom'
            component={ValidatedRadioFields}
            options={hrWarningNoteRadioOptions}
            className={'custom-radio-style-1'}
            onChange={() => {
              if (values.hrWarningNoteDefaultOrCustom == 1) {
                setFieldValue('hrWarningNote', props?.formConfig?.hrWarningNote);
              } else {
                setFieldValue('hrWarningNote', values?.defaultHrWarningNote);
              }
            }}
          />
        </div>
        <hr />
        <div className={styles.companyLogo}>
          <p>
            Company logo
            <OverlayTrigger
              key={`password_protected`}
              placement='top'
              style
              overlay={
                <Tooltip id={`password_protected`} style={{ width: '200px' }}>
                  <p>
                    To {values.logoUrl ? 'edit' : 'add'} the company logo please go to Brand
                    settings tab.
                  </p>
                </Tooltip>
              }
            >
              <Image name='info.svg' className={'ml-2'} />
            </OverlayTrigger>
          </p>
          <div className={styles.sectionSubHeader}>Include logo in invite email</div>
          <div className={styles.input}>
            <div className={styles.useLogoInInviteMail}>
              <Field
                custom
                name='useLogoInInviteMail'
                className={'custom-checkbox-style-1 ' + styles.cBoxMargin}
                component={Checkbox}
                disabled={
                  !isLogosGood ||
                  Object.values(values.companyLogos).every((logo) => logo.logoUrl === null)
                }
                label='Enabled'
                onChange={(e) => {
                  if (e.target.checked) {
                    const selectedLogo =
                      Object.values(values?.companyLogos).find(
                        (logo) => logo.id === values?.logoUrlId
                      )?.logoUrl || null;
                    if (!selectedLogo) {
                      const firstKeyWithValue = Object.keys(values?.companyLogos).find(
                        (key) => values.companyLogos[key].logoUrl
                      );
                      setFieldValue('logoUrlId', values?.companyLogos[firstKeyWithValue]?.id);
                    }
                    setFieldValue('useLogoInInviteMail', true);
                  } else {
                    setFieldValue('useLogoInInviteMail', false);
                    setFieldValue('logoUrlId', formConfig?.company?.logoUrlId);
                  }
                }}
              />
            </div>
            {isLogosGood && (
              <div className={styles.companyLogos}>
                {Object.keys(values.companyLogos)
                  .sort()
                  .map((key) => {
                    return (
                      values.companyLogos[key].logoUrl && (
                        <div
                          className={`${styles.logoContainer} ${
                            values.useLogoInInviteMail &&
                            values.logoUrlId === values.companyLogos[key].id
                              ? styles.active
                              : ''
                          }`}
                          style={{
                            background:
                              values.companyLogos[key].logoType === 'dark' ? '#F2F2F2' : '#6a778b',
                            position: 'relative',
                            pointerEvents: values.useLogoInInviteMail ? 'auto' : 'none'
                          }}
                          onClick={() => {
                            setFieldValue('logoUrlId', values.companyLogos[key].id);
                          }}
                        >
                          <div className={styles.select}>
                            {values.useLogoInInviteMail &&
                            values.logoUrlId === values.companyLogos[key].id ? (
                              <Image name='tickCircle.svg' />
                            ) : null}
                          </div>
                          <div className={styles.logo}>
                            <img src={values.companyLogos[key].logoUrl} />
                          </div>
                        </div>
                      )
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
