// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z12EtHNJ7_uxtCG8BKgZ {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

.iFG5xxnfL4kaTprR1vpg {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.oBcbbc9XplySfAQ3OHQ8 {
  font-family: roboto;
}

.AmWPo2cMRhQ1elow0nAg {
  border: 0.5px solid #a7a6a6;
  border-radius: 0.1rem;
  padding: 0 0.3rem;
  font-size: 12px;
  cursor: pointer;
}

.AmWPo2cMRhQ1elow0nAg::selection,
.uv46VZ6vV8ds8jlxdwR9::selection {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.uv46VZ6vV8ds8jlxdwR9 {
  border: 0.5px solid #a7a6a6;
  border-radius: 0.1rem;
  padding: 0 0.5rem;
  font-size: 12px;
}

.eYlTzCTA1Syo0SRUXd2L {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.ibdfelmR8uKO9mNUptbS {
  opacity: 0;
  transition: opacity 0.2s linear;
}

.Z13NRifIH07tcXlwtTCq {
  border: 1px solid #2755fe !important;
}

.UGNKQN0wU0PYYLRkGkKI {
  background: rgba(10, 157, 60, 0.24);
  color: rgb(29, 138, 66);
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.VWNRGmeXIPbNQEKgOT67 {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/CreditBuyPackagePage/Packages/Tiers/Tiers.modules.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,2BAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;AAIF;;AADA;;EAEE,yBAAA,EAAA,WAAA;EACA,sBAAA,EAAA,YAAA;EACA,qBAAA,EAAA,2BAAA;EACA,iBAAA;AAIF;;AAFA;EACE,2BAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;AAKF;;AAHA;EACE,UAAA;EACA,+BAAA;AAMF;;AAJA;EACE,UAAA;EACA,+BAAA;AAOF;;AALA;EACE,oCAAA;AAQF;;AANA;EACE,mCAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AASF;;AAPA;EACE,YAAA;AAUF","sourcesContent":[".title {\n  font-weight: 600;\n  font-size: 16px;\n  color: #333333;\n}\n.subtitle {\n  font-size: 12px;\n  font-weight: 400;\n  color: #333333;\n}\n.amountText {\n  font-family: roboto;\n}\n.tierButton {\n  border: 0.5px solid #a7a6a6;\n  border-radius: 0.1rem;\n  padding: 0 0.3rem;\n  font-size: 12px;\n  cursor: pointer;\n}\n\n.tierButton::selection,\n.tierCountButton::selection {\n  -webkit-user-select: none; /* Safari */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n}\n.tierCountButton {\n  border: 0.5px solid #a7a6a6;\n  border-radius: 0.1rem;\n  padding: 0 0.5rem;\n  font-size: 12px;\n}\n.tierActive {\n  opacity: 1;\n  transition: opacity 0.2s linear;\n}\n.tierInactive {\n  opacity: 0;\n  transition: opacity 0.2s linear;\n}\n.cardActive {\n  border: 1px solid #2755fe !important;\n}\n.discountTag {\n  background: rgba(10, 157, 60, 0.24);\n  color: rgba(29, 138, 66, 1);\n  padding: 2px 4px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: 500;\n}\n.cardContainer {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Z12EtHNJ7_uxtCG8BKgZ`,
	"subtitle": `iFG5xxnfL4kaTprR1vpg`,
	"amountText": `oBcbbc9XplySfAQ3OHQ8`,
	"tierButton": `AmWPo2cMRhQ1elow0nAg`,
	"tierCountButton": `uv46VZ6vV8ds8jlxdwR9`,
	"tierActive": `eYlTzCTA1Syo0SRUXd2L`,
	"tierInactive": `ibdfelmR8uKO9mNUptbS`,
	"cardActive": `Z13NRifIH07tcXlwtTCq`,
	"discountTag": `UGNKQN0wU0PYYLRkGkKI`,
	"cardContainer": `VWNRGmeXIPbNQEKgOT67`
};
export default ___CSS_LOADER_EXPORT___;
