import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAlumniTablesData } from '/src/actions/company';

const mapStateToProps = (state) => {
  return {
    alumniRecords: state?.alumniTableData?.rows || {},
    alumniRecordsCount: state?.alumniTableData?.count || 0
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAlumniRecords: (data) => dispatch(getAlumniTablesData(data, 'ALUMNI_RECORDS'))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
