// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uuwkFfJ3Ye5BdJ0EVH5U {
  font-size: 18px;
  color: #4c4c4c;
  margin-bottom: 28px;
  display: flex;
}

.i9sxuucc8z1C2AXiTcP1 {
  margin-right: 24px;
  background-color: #fff;
  padding: 0 12px 0 4px;
  font-size: 14px;
  color: #4c4c4c;
}
.i9sxuucc8z1C2AXiTcP1 label {
  color: #4c4c4c;
  font-size: 14px;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/frontend-common/core-components/ValidatedRadioFields/ValidatedRadioFields.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAEJ","sourcesContent":[".question {\n  font-size: 18px;\n  color: #4c4c4c;\n  margin-bottom: 28px;\n  display: flex;\n}\n\n.option {\n  margin-right: 24px;\n  background-color: #fff;\n  padding: 0 12px 0 4px;\n  font-size: 14px;\n  color: #4c4c4c;\n  label {\n    color: #4c4c4c;\n    font-size: 14px;\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": `uuwkFfJ3Ye5BdJ0EVH5U`,
	"option": `i9sxuucc8z1C2AXiTcP1`
};
export default ___CSS_LOADER_EXPORT___;
