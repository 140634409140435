import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import styles from './Confirm.module.scss';

export default (props) => {
  return (
    <Modal
      size='md'
      centered
      show={props.show}
      onHide={() => props.onClose()}
      className={styles.confirmationModal}
      backdropClassName={'confirmationModalBackdrop'}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Body>
        <Row className={styles.confirModalBody}>
          <Col lg={12} md={12} sm={12} xs={12} className={styles.confirmTextContainer}>
            <p className={styles.confirmModalHeading}>
              <span>{props.heading}</span>
            </p>
            <p className={styles.confirmText}>{props.subHeading}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className={styles.confirmModalFooter}>
        {!props.hideCloseBtn && (
          <Button
            type='button'
            className={props.enableBtn ? styles.cancelConfirmBtn : styles.disabledCancelConfirmBtn}
            disabled={!props.enableBtn}
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
        )}
        <Button
          type='button'
          onClick={() => props.onSubmit()}
          variant={props?.isDelete ? 'danger' : 'primary'}
          className={
            props.enableBtn && props?.isDelete
              ? styles.deleteBtn
              : props.enableBtn
              ? styles.activeConfirmBtn
              : styles.disabledConfirmBtn
          }
          disabled={!props.enableBtn || props?.disableSubmit}
        >
          {props.sBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
