import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import styles from './Communication.module.scss';
import Button from '../../../../core-components/Button';
import EmailContent from './EmailContent';
import EmailPreview from './EmailPreview/EmailPreview';
import AppConstants from '../../../../core-components/AppConstants';
import { toast } from 'react-toastify';
import { errToastMessage, getErrorMessage } from '../../../../utils/utilities';

import { editEmailCommunication } from '../../../../api/company';

export default function Communication(props) {
  const [emailContentData, setEmailContentData] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  const [resetFormData, setResetFormData] = useState(false);

  const handleSubmit = () => {
    const data = {
      bgvConfigId: props?.formConfig?.id,
      companyName: props?.formConfig?.company?.name,
      companyUuid: props?.formConfig?.company?.uuid,
      companyId: props?.formConfig?.companyId,
      hrNote:
        emailContentData?.hrInviteNoteDefaultOrCustom == 1
          ? emailContentData?.defaultHrInviteNote
          : emailContentData?.hrInviteNote,
      hrWarningNote:
        emailContentData?.hrWarningNoteDefaultOrCustom == 1
          ? emailContentData?.defaultHrWarningNote
          : emailContentData?.hrWarningNote,
      friendlyName: emailContentData?.friendlyName,
      logoUrlId: emailContentData?.logoUrlId,
      hrNoteStatus: emailContentData?.hrInviteNoteStatus ? 1 : 0,
      hrWarningNoteStatus: emailContentData?.hrWarningNoteStatus ? 1 : 0,
      useLogoInInviteMail: emailContentData?.useLogoInInviteMail,
      isBrandEnabled: emailContentData?.isBrandEnabled
    };

    editEmailCommunication(data)
      .then((resp) => {
        props.getCandidateFormConfig();
        toast.success('Settings updated successfully');
      })
      .catch((error) => {
        setIsValidForm(true);
        errToastMessage(error);
      });
  };

  return (
    <Card className={styles.communicationCard}>
      <div className={styles.tabInfoText}>
        Customize the background check invitation and consent reminder emails that we send to your
        candidates to align with your company's requirements.
      </div>
      <Container className={styles.communicationContainer}>
        <Row>
          <Col>
            <EmailContent
              setEmailContentData={(values) => setEmailContentData(values)}
              isValidForm={(value) => setIsValidForm(value)}
              resetFormData={resetFormData}
              clearResetFormData={setResetFormData}
            />
          </Col>
          <Col>
            <EmailPreview emailContentData={emailContentData} profile={props.profile} />
          </Col>
        </Row>
      </Container>
      <div className={styles.buttonGroup}>
        <Button
          className={styles.cancelButton}
          disabled={!isValidForm}
          onClick={() => {
            setResetFormData(true);
          }}
        >
          Cancel
        </Button>
        <Button
          className={styles.submitButton}
          disabled={!isValidForm}
          onClick={() => {
            setIsValidForm(false);
            handleSubmit();
          }}
        >
          Save
        </Button>
      </div>
    </Card>
  );
}
