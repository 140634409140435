import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';

export default compose(
  connect(null, null),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {};
      return mergeWith({}, storedValues, props.category, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {},
    displayName: 'Contact Details'
  })
);
