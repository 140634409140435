import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import styles from './CustomCounter.module.scss';

export default (props) => {
  const handleIncrement = () => {
    props.valueChange(props.value + 1, props.index);
  };

  const handleDecrement = () => {
    props.valueChange(props.value - 1, props.index);
  };

  return (
    <ButtonGroup size='sm'>
      <Button
        onClick={handleDecrement}
        disabled={!props.value || props.disabled}
        variant='outline-primary'
        size='sm'
        className={`${props?.isShowLargeSize ? styles.size36 : ''} ${styles.counterButton}`}
      >
        {'-'}
      </Button>
      <Button
        variant='light'
        size='sm'
        className={`${props?.isShowLargeSize ? styles.customNumberButton : ''} ${
          styles.numberButton
        }`}
      >
        {props.value}
      </Button>
      <Button
        data-testid='increment'
        onClick={handleIncrement}
        disabled={props.limit <= props.totalCount || props.disabled}
        variant='outline-primary'
        size='sm'
        className={`${props?.isShowLargeSize ? styles.size36 : ''} ${styles.counterButton}`}
      >
        {'+'}
      </Button>
    </ButtonGroup>
  );
};
