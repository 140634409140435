import React, { useState, useEffect } from 'react';
import styles from './AlumniRecords.module.scss';
import { Card } from '@material-ui/core';
import { has, isEmpty, capitalize, startsWith } from 'lodash';
import { Button, Form } from 'react-bootstrap';
import { Field } from 'formik';
import moment from 'moment';
import { DropdownList } from 'react-widgets';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ValidatedFormInputField from '../../core-components/ValidatedFormInputField';
import Image from '../../core-components/Image';
import CustomValidatedSelect from '../../core-components/CustomValidatedSelect';
import ViewRequestsModal from './Modals/ViewRequestsModal';
import { getErrorMessage, errToastMessage, toCamelCase, toSnakeCase } from '../../utils/Utilities';
import countryNames from '../../utils/countryNamesEn.json';
import countryOptions from '../../utils/countryCodesEn.json';
import { withRouter } from 'react-router-dom';
import ConfirmationModal from '../../core-components/ConfirmationModal';
import { deleteAlumniRecord } from '../../../../../api/company';
import { allowOnlyNumbersRegex } from '../../../../../utils/ValidationSchema';

const SUBMIT_BUTTON_TEXT = { ADD: 'Add', EDIT: 'Update', VIEW: 'Edit' };

const CUSTOM_STYLES_FOR_SELECT = {
  control: {
    height: '36px',
    minHeight: '36px',
    backgroundColor: '#2755fe0d',
    border: 'none',
    borderColor: 'none',
    borderRadius: '0px',
    fontSize: '14px'
  },
  menu: {
    minWidth: '200px',
    zIndex: '1',
    show: 'true'
  }
};

const AlumniRecords = (props) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    setErrors,
    errors,
    addAlumniRecord,
    getAlumniRecord,
    getAlumniTableData,
    AppConstants,
    isSa = false,
    history
  } = props;

  const [flow, setFlow] = useState('ADD');
  const [employeId, setEmployeeId] = useState(null);
  const [employeeRequests, setEmployeeRequests] = useState({ count: 0, rows: [] });
  const [showViewAllRequestModal, setShowViewAllRequestModal] = useState(false);
  const [showOnlyCreatedDate, setShowOnlyCreatedDate] = useState(true);
  const [dateError, setDateError] = useState({
    dateOfJoining: '',
    lastWorkingDay: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Useffects
   */

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const flowParam = urlParams.get('flow').toUpperCase();

    if (flowParam) {
      setFlow(flowParam.toUpperCase());

      if (flowParam !== 'ADD') {
        const flowParam = isSa ? urlParams.get('empId') : urlParams.get('id');
        setEmployeeId(flowParam);
      } else {
        setFieldValue('countryCode', '+91');
        setFieldValue('rmCountryCode', '+91');
      }
    }
  }, []);

  useEffect(() => {
    if (flow === 'VIEW' && employeId)
      getAlumniRecord({ employeeRecordId: employeId })
        .then((res) => {
          mapApiDataToValues(res);

          fetchRequestList({ limit: 10, offset: 0 });
        })
        .catch((err) => {
          console.log(err);
        });
  }, [employeId, flow]);

  const mapApiDataToValues = (apiResp) => {
    const {
      full_name,
      staff_id,
      date_of_birth,
      personal_email,
      business_email,
      phone_number,
      designation,
      date_of_joining,
      last_working_day,
      employment_type,
      exit_type,
      exit_reason,
      ctc,
      eligible_for_rehire,
      rm_name,
      rm_phone,
      rm_email,
      mode,
      created_at,
      updated_at
    } = apiResp.data.data;

    setFieldValue('fullName', full_name);
    setFieldValue('staffId', staff_id);
    setFieldValue('dateOfBirth', date_of_birth !== '0000-00-00' ? date_of_birth : null);
    setFieldValue('personalEmail', personal_email);
    setFieldValue('businessEmail', business_email);
    setFieldValue('phoneNumber', phone_number);
    setFieldValue('designation', designation);
    setFieldValue('dateOfJoining', date_of_joining); // Convert to string
    setFieldValue('lastWorkingDay', last_working_day);
    setFieldValue('employmentType', employment_type);
    setFieldValue('exitType', exit_type);
    setFieldValue('exitReason', exit_reason);
    setFieldValue('ctc', ctc);
    setFieldValue('eligibleForRehire', eligible_for_rehire);
    setFieldValue('rmName', rm_name);
    setFieldValue('rmPhone', rm_phone);
    setFieldValue('rmEmail', rm_email);
    setFieldValue('source', mode);
    setFieldValue('createdAt', created_at ? moment(created_at).format('DD-MM-YYYY') : '');
    setFieldValue('updatedAt', updated_at ? moment(updated_at).format('DD-MM-YYYY') : '');

    setShowOnlyCreatedDate(created_at === updated_at ? true : false);
  };

  /**
   * Local Re-usable Components
   */
  const NonEditableTextField = (props) => {
    const text = props?.field?.value || '-';
    return <div className={styles.fieldText}>{text}</div>;
  };

  const FIELD_COMPONENT = flow === 'VIEW' ? NonEditableTextField : ValidatedFormInputField;
  const DATE_FIELD_COMPONENT = flow === 'VIEW' ? NonEditableTextField : DatePicker;
  const DROP_DOWN_COMPONENT = flow === 'VIEW' ? NonEditableTextField : DropdownList;

  /**
   * Handlers and API calls
   */
  const onSubmitHandler = () => {
    if (flow === 'VIEW') {
      setFlow('EDIT');
    }

    if (flow === 'ADD') {
      setIsSubmitting(true);
      const formattedValues = {
        fullName: values.fullName,
        staffId: values.staffId,
        isStaffIdNotAvailable: values.isStaffIdNotAvailable,
        personalEmail: values.personalEmail,
        businessEmail: values.businessEmail,
        phone_number: values.phoneNumber ? values.countryCode + values.phoneNumber : '',
        designation: values.designation,
        employmentType: values.employmentType,
        exitType: values.exitType,
        exitReason: values.exitReason,
        ctc: values.ctc,
        eligibleForRehire: values.eligibleForRehire,
        rmName: values.rmName,
        rmEmail: values.rmEmail,
        rmPhone: values.rmPhone ? values.rmCountryCode + values.rmPhone : '',
        date_of_birth: values.dateOfBirth ? moment(values.dateOfBirth).format('YYYY-MM-DD') : '',
        date_of_joining: moment(values.dateOfJoining).format('YYYY-MM-DD'),
        last_working_day: moment(values.lastWorkingDay).format('YYYY-MM-DD')
      };
      let formData = toSnakeCase(formattedValues);
      const payload = { employeeData: [formData] };

      addAlumniRecord(payload)
        .then((res) => {
          toast.success('Alumni Record added successfully');
          setTimeout(() => {
            window.location.href = isSa
              ? `${AppConstants.baseURL}company/alumniVerification?id=${props.companyId}`
              : `${AppConstants.baseURL}alumni-verification`;
          }, 1000);
        })
        .catch((err) => {
          const errorMessage = getErrorMessage(err);
          if (errorMessage.includes('Staff ID') && errorMessage.includes('already exists')) {
            let newErrors = { ...errors };
            newErrors.staffId = 'Staff ID already exists';
            setErrors(newErrors);
          }
          setIsSubmitting(false);
        });
    }
  };
  const onDeleteHandler = () => {
    if (isSa) {
      deleteAlumniRecord(employeId, props.companyId)
        .then((res) => {
          toast.success('Alumni Record deleted Successfully');
          history.push(`/company/alumniVerification?id=${props.companyId}&tab=alumni_records`);
        })
        .catch((err) => {
          toast.error(getErrorMessage(err));
        });
    } else {
      deleteAlumniRecord(employeId)
        .then((res) => {
          toast.success('Alumni Record deleted Successfully');
          history.push('/alumni-verification?tab=alumni_records');
        })
        .catch((err) => {
          toast.error(getErrorMessage(err));
        });
    }
  };

  const fetchRequestList = ({ limit, offset }) => {
    const where = {
      limit: limit,
      offset: offset,
      alumni_employee_id: employeId
    };

    getAlumniTableData(where)
      .then((res) => {
        const data = toCamelCase(res.data.data);
        console.log('data', data);
        setEmployeeRequests(data);
      })
      .catch((err) => {
        console.log(getErrorMessage(err));
      });
  };

  const onCloseHandler = () => {
    const path = isSa
      ? `/company/alumniVerification?id=${props.companyId}`
      : `/alumni-verification`;

    history?.push(path);
  };

  const transformDate = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toISOString().split('T')[0];
  };

  return (
    <div className={styles.recordsWrapper}>
      <Card className={styles.recordsCard}>
        {/* Header */}
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>
            <div> {`Alumni Verification > ` + capitalize(flow) + ` Record `}</div>
            <div> {flow !== 'ADD' && <span>{`(ID: ` + employeId + ` )`}</span>}</div>
            <div>
              {' '}
              {flow === 'VIEW' && employeeRequests?.count > 0 && (
                <div className={styles.viewRequestContainer}>
                  <div
                    className={styles.viewAllRequestText}
                    onClick={() => setShowViewAllRequestModal(true)}
                  >{`View all ${employeeRequests.count} requests`}</div>
                </div>
              )}
            </div>
          </div>
          {flow === 'VIEW' && (
            <div className={styles.deleteButton}>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id={'DeleteTag'}>
                    <p>{'Delete'}</p>
                  </Tooltip>
                }
              >
                <Image height={'14'} onClick={() => setIsModalOpen(true)} name='Delete.svg' />
              </OverlayTrigger>
            </div>
          )}
        </div>
        {/* Sub Info */}
        {flow === 'VIEW' && (
          <div className={styles.subInfoText}>
            <span>{`Created On: ` + values.createdAt + ' '}</span>
            {!showOnlyCreatedDate && (
              <>
                <span>{`| `}</span>
                <span>{`Updated On: ` + values.updatedAt + ' '}</span>
              </>
            )}
            <span>{`| `}</span>
            <span>{`Source On: ` + values.source}</span>
          </div>
        )}

        <Form>
          {/* Personal Details */}
          <div className={`${styles.sectionTitle} ${styles.padding24}`}>PERSONAL DETAILS</div>
          <div
            className={
              errors?.personalEmail || errors?.businessEmail || errors?.phoneNumber
                ? `${styles.personalDetailsContainer} ${styles.maxHeight}`
                : `${styles.personalDetailsContainer}`
            }
          >
            {/* First Row */}
            <div className={styles.rowForFields}>
              {/* Employee Full Name */}
              <div className={`${styles.fieldContainer} ${styles.height84}`}>
                <div className={styles.fieldLabelText}>
                  {`Employee Full Name`}
                  {flow !== 'VIEW' && <span className={styles.required}>{` *`}</span>}
                </div>
                <Field name='fullName' component={FIELD_COMPONENT} />
              </div>

              {/* Staff ID */}
              <div
                className={
                  has(errors, 'staffId')
                    ? `${styles.fieldContainer} ${styles.height90}`
                    : `${styles.fieldContainer} ${styles.height84}`
                }
              >
                <div className={styles.fieldLabelText}>
                  {`Staff ID`}
                  {flow !== 'VIEW' && !values.isStaffIdNotAvailable && (
                    <span className={styles.required}>{` *`}</span>
                  )}
                </div>
                <Field
                  className={styles.noBottomMargin}
                  name='staffId'
                  disabled={values.isStaffIdNotAvailable}
                  component={FIELD_COMPONENT}
                  onChange={(e) => {
                    setFieldValue('staffId', e.target.value);
                    if (e.target.value) {
                      let newErrors = { ...errors };
                      delete newErrors.staffId;
                      setErrors(newErrors);
                    }
                  }}
                />

                {flow !== 'VIEW' && (
                  <div className={styles.staffIdCheckBoxContainer}>
                    <Form.Check
                      type='checkbox'
                      name='isStaffIdNotAvailable'
                      onChange={(e) => {
                        setFieldValue('isStaffIdNotAvailable', e.target.checked);
                        if (e.target.checked) {
                          setFieldValue('staffId', '');
                          setTimeout(() => {
                            let newErrors = { ...errors };
                            delete newErrors.staffId;
                            setErrors(newErrors);
                          }, 100);
                        }
                      }}
                    />
                    <div className={styles.staffIdText}>Staff ID not available</div>
                  </div>
                )}
              </div>

              {/* DOB */}
              <div className={`${styles.fieldContainer} ${styles.height84}`}>
                <div className={styles.fieldLabelText}>
                  {`Date of Birth`}
                  {flow !== 'VIEW' && values.isStaffIdNotAvailable && (
                    <span className={styles.required}>{` *`}</span>
                  )}
                </div>
                <div className={flow !== 'VIEW' ? styles.fieldBorder : null}>
                  <Field
                    name='dateOfBirth'
                    placeholderText={'DD-MM-YYYY'}
                    selected={values.dateOfBirth ? values.dateOfBirth : null}
                    dateFormat='dd-MM-yyyy'
                    className={
                      values.isStaffIdNotAvailable ? styles.datePicker : styles.datePickerBlack
                    }
                    onChange={(date) => {
                      setFieldValue('dateOfBirth', date);
                    }}
                    onChangeRaw={(e) => e.preventDefault()}
                    maxDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    component={DATE_FIELD_COMPONENT}
                  />
                  {flow !== 'VIEW' && (
                    <div className={styles.datePickerCalendarIcon}>
                      <Image name='calenderIcon.svg' />
                    </div>
                  )}
                </div>
                {flow !== 'VIEW' &&
                values.isStaffIdNotAvailable &&
                !startsWith(errors?.dateOfBirth, 'Date') &&
                values?.dateOfBirth === '' ? (
                  <div className={styles.errorMessage}>
                    Please provide either valid Staff ID or DOB
                  </div>
                ) : null}
                {errors?.dateOfBirth === 'Date10' && (
                  <div className={styles.errorMessage}>Date should be minimum 10 years old</div>
                )}
              </div>
            </div>
            {/* Second Row */}
            <div className={styles.rowForFields}>
              {/* Personal Email ID */}
              <div className={`${styles.fieldContainer} ${styles.height84}`}>
                <div className={styles.fieldLabelText}>{`Personal Email ID`}</div>
                <Field name='personalEmail' component={FIELD_COMPONENT} trimWithoutSpace={true} />
              </div>

              {/* Buisness Email ID */}
              <div className={`${styles.fieldContainer} ${styles.height84}`}>
                <div className={styles.fieldLabelText}>{`Business Email ID`}</div>
                <Field name='businessEmail' component={FIELD_COMPONENT} trimWithoutSpace={true} />
              </div>

              {/* Phone */}
              <div className={`${styles.fieldContainer} ${styles.height84}`}>
                <div className={styles.fieldLabelText}>{`Phone`}</div>
                <div className={flow !== 'VIEW' ? styles.phoneFieldContainer : null}>
                  {flow !== 'VIEW' && (
                    <Field
                      type='text'
                      name='countryCode'
                      defaultValue={values.countryCode}
                      options={countryNames}
                      filterLabel={countryOptions}
                      component={CustomValidatedSelect}
                      innerComponentStyles={CUSTOM_STYLES_FOR_SELECT}
                      borderColor='#dee0e5'
                      className={styles.countryCodeDropDown}
                    />
                  )}
                  <Field
                    type='text'
                    className={styles.phoneField}
                    name='phoneNumber'
                    component={FIELD_COMPONENT}
                    regex={allowOnlyNumbersRegex}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.horizontalDivider}></div>

          {/* Background Details */}
          <div className={styles.sectionTitle}>BACKGROUND DETAILS</div>
          <div className={styles.backgroundDetailsContainer}>
            {/* Third Row */}
            <div className={styles.rowForFields}>
              {/* Designation */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>
                  {`Designation`}
                  {flow !== 'VIEW' && <span className={styles.required}>{` *`}</span>}
                </div>
                <Field
                  className={styles.noBottomMargin}
                  name='designation'
                  component={FIELD_COMPONENT}
                />
              </div>

              {/* Date of Joining */}
              <div
                className={
                  dateError?.dateOfJoining
                    ? `${styles.fieldContainer} ${styles.height70}`
                    : `${styles.fieldContainer} ${styles.height62}`
                }
              >
                <div className={styles.fieldLabelText}>
                  {`Date of Joining`}
                  {flow !== 'VIEW' && <span className={styles.required}>{` *`}</span>}
                </div>
                <div className={flow !== 'VIEW' ? styles.fieldBorder : null}>
                  <Field
                    name='dateOfJoining'
                    placeholderText={'DD-MM-YYYY'}
                    selected={values.dateOfJoining ? values.dateOfJoining : null}
                    dateFormat='dd-MM-yyyy'
                    className={
                      flow !== 'VIEW'
                        ? `${styles.datePicker} ${styles.noBottomMargin}`
                        : styles.noBottomMargin
                    }
                    onChange={(date) => {
                      setFieldValue('dateOfJoining', date);
                      if (values.lastWorkingDay) {
                        const dateOfJoining = transformDate(date);
                        const lastWorkingDay = transformDate(values.lastWorkingDay);

                        if (new Date(dateOfJoining) >= new Date(lastWorkingDay)) {
                          setDateError({
                            lastWorkingDay:
                              'Last working day should be greater than date of joining.',
                            dateOfJoining: 'Date of joining should be less than last working day'
                          });
                        } else if (dateError.dateOfJoining) {
                          setDateError({ lastWorkingDay: '', dateOfJoining: '' });
                        }
                      } else if (dateError.dateOfJoining) {
                        setDateError({ lastWorkingDay: '', dateOfJoining: '' });
                      }
                    }}
                    onChangeRaw={(e) => e.preventDefault()}
                    maxDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    component={DATE_FIELD_COMPONENT}
                  />
                  {flow !== 'VIEW' && (
                    <div className={styles.datePickerCalendarIcon}>
                      <Image name='calenderIcon.svg' />
                    </div>
                  )}
                </div>
                {flow !== 'VIEW' && dateError.dateOfJoining && (
                  <div className={styles.errorMessage}>{dateError.dateOfJoining}</div>
                )}
              </div>

              {/* Last Working Day */}
              <div
                className={
                  dateError?.lastWorkingDay
                    ? `${styles.fieldContainer} ${styles.height70}`
                    : `${styles.fieldContainer} ${styles.height62}`
                }
              >
                <div className={styles.fieldLabelText}>
                  {`Last Working Day`}
                  {flow !== 'VIEW' && <span className={styles.required}>{` *`}</span>}
                </div>
                <div className={flow !== 'VIEW' ? styles.fieldBorder : null}>
                  <Field
                    name='lastWorkingDay'
                    placeholderText={'DD-MM-YYYY'}
                    selected={values.lastWorkingDay ? values.lastWorkingDay : null}
                    dateFormat='dd-MM-yyyy'
                    className={
                      flow !== 'VIEW'
                        ? `${styles.datePicker} ${styles.noBottomMargin}`
                        : styles.noBottomMargin
                    }
                    onChange={(date) => {
                      setFieldValue('lastWorkingDay', date);

                      if (values.dateOfJoining) {
                        const dateOfJoining = transformDate(values.dateOfJoining);
                        const lastWorkingDay = transformDate(date);

                        if (new Date(dateOfJoining) >= new Date(lastWorkingDay)) {
                          setDateError({
                            lastWorkingDay:
                              'Last working day should be greater than date of joining.',
                            dateOfJoining: 'Date of joining should be less than last working day'
                          });
                        } else if (dateError.dateOfJoining) {
                          setDateError({ lastWorkingDay: '', dateOfJoining: '' });
                        }
                      } else if (dateError.lastWorkingDay) {
                        setDateError({ lastWorkingDay: '', dateOfJoining: '' });
                      }
                    }}
                    onChangeRaw={(e) => e.preventDefault()}
                    maxDate={new Date()}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    component={DATE_FIELD_COMPONENT}
                  />
                  {flow !== 'VIEW' && (
                    <div className={styles.datePickerCalendarIcon}>
                      <Image name='calenderIcon.svg' />
                    </div>
                  )}
                </div>
                {flow !== 'VIEW' && dateError.lastWorkingDay && (
                  <div className={styles.errorMessage}>{dateError.lastWorkingDay}</div>
                )}
              </div>
            </div>
            {/* Fourth Row */}
            <div className={styles.rowForFields}>
              {/* Employment Type */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Employment Type`}</div>
                <Field name='employmentType' type='text' component={FIELD_COMPONENT} />
              </div>

              {/* Exit Type */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Exit Type`}</div>
                <Field
                  containerClassName={styles.dropDownStyle}
                  type='text'
                  name='exitType'
                  component={FIELD_COMPONENT}
                />
              </div>

              {/* Exit Reason */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Exit Reason`}</div>
                <Field
                  name='exitReason'
                  component={FIELD_COMPONENT}
                  className={styles.noBottomMargin}
                />
              </div>
            </div>
            {/* Fifth Row */}
            <div className={styles.rowForFields}>
              {/* CTC */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`CTC`}</div>
                <Field
                  type='text'
                  name='ctc'
                  component={FIELD_COMPONENT}
                  className={styles.noBottomMargin}
                />
              </div>

              {/* Eligibility to Rehire */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Eligibility to rehire`}</div>
                <Field name='eligibleForRehire' type='text' component={FIELD_COMPONENT} />
              </div>
            </div>
            {/* Sixth Row */}
            <div className={styles.rowForFields}>
              {/* Reporting Manager Name */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Reporting Manager Name`}</div>
                <Field name='rmName' component={FIELD_COMPONENT} />
              </div>

              {/* Reporting Manager Email ID */}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Reporting Manager Email ID`}</div>
                <Field name='rmEmail' component={FIELD_COMPONENT} trimWithoutSpace={true} />
              </div>

              {/* Reporting Manager Phone*/}
              <div className={`${styles.fieldContainer} ${styles.height62}`}>
                <div className={styles.fieldLabelText}>{`Reporting Manager Phone`}</div>
                <div className={flow !== 'VIEW' ? styles.phoneFieldContainer : null}>
                  {flow !== 'VIEW' && (
                    <Field
                      type='text'
                      name='rmCountryCode'
                      defaultValue={values.rmCountryCode}
                      options={countryNames}
                      filterLabel={countryOptions}
                      menuPlacement='top'
                      component={CustomValidatedSelect}
                      innerComponentStyles={CUSTOM_STYLES_FOR_SELECT}
                      borderColor='#dee0e5'
                      className={styles.countryCodeDropDown}
                    />
                  )}
                  <Field
                    type='text'
                    className={styles.phoneField}
                    name='rmPhone'
                    component={FIELD_COMPONENT}
                    regex={allowOnlyNumbersRegex}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className={styles.footer}>
            {flow !== 'VIEW' && (
              <Button
                onClick={onCloseHandler}
                variant='outline-primary'
                className={styles.cancelButton}
              >
                Cancel
              </Button>
            )}

            <OverlayTrigger
              key={`editRecord`}
              placement='bottom'
              overlay={
                flow === 'VIEW' && values.source !== 'MANUAL' ? (
                  <Tooltip id={`editRecord`}>
                    <p>
                      You cannot edit this record. Please update the record on your HRMS to sync the
                      details.
                    </p>
                  </Tooltip>
                ) : (
                  <span></span>
                )
              }
            >
              <div className={styles.btnContainer}>
                <Button
                  disabled={
                    !isEmpty(props.errors) ||
                    flow === 'VIEW' ||
                    !isEmpty(dateError.dateOfJoining) ||
                    !isEmpty(dateError.lastWorkingDay) ||
                    isSubmitting ||
                    (values.isStaffIdNotAvailable && values.dateOfBirth === '')
                  }
                  variant='primary'
                  className={styles.saveButton}
                  onClick={onSubmitHandler}
                >
                  <span>{SUBMIT_BUTTON_TEXT[flow]}</span>
                </Button>
              </div>
            </OverlayTrigger>
          </div>
        </Form>
      </Card>

      {/* View All Requests Modal */}
      {showViewAllRequestModal && (
        <ViewRequestsModal
          AppConstants={AppConstants}
          show={showViewAllRequestModal}
          onHide={() => {
            setShowViewAllRequestModal(false);
            fetchRequestList({ limit: 10, offset: 0 });
          }}
          rowData={employeeRequests.rows}
          count={employeeRequests.count}
          fetchRequestList={fetchRequestList}
        />
      )}
      {/* Show Delete Modal */}
      {isModalOpen && (
        <ConfirmationModal
          show={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          heading={'Delete record?'}
          subHeading={'Are you sure you want to delete the record?'}
          onSubmit={() => {
            setIsModalOpen(false);
            onDeleteHandler();
          }}
          isDelete={true}
          enableBtn={true}
          sBtnText='Delete'
        />
      )}
    </div>
  );
};

export default withRouter(AlumniRecords);
