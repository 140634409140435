import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    paymentDetails: state.paymentDetails || null,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
