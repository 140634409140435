import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';

import validationSchema from './PersonalProfile.validation';

import { editPersonalProfile } from '../../../../actions/authentication';
import { getCategories, INVALIDATE_CATEGORIES } from '../../../../actions/company';
import { getErrorMessage } from '../../../../utils/utilities';

const initialValues = {
  name: '',
  mobile: '',
  access: 'admin',
  category: [],
  isPersonalEdit: false
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    categories: state.categories
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    getCategories: () => dispatch(getCategories()),
    editPersonalProfile: (data) => dispatch(editPersonalProfile(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const selectedCategories = [];
      props?.profile?.profile?.user_category_mappings?.length > 0 &&
        props?.profile?.profile?.user_category_mappings?.map((category) => {
          selectedCategories.push({
            label: category.categoryName,
            value: category.categoryId
          });
        });
      const profile = props?.profile?.profile
        ? {
            name: props?.profile?.profile?.name,
            mobile: props?.profile?.profile?.mobile,
            access:
              props?.profile?.profile?.company_user_mapping?.access_level === 5 ? 'admin' : 'hr',
            category: selectedCategories
          }
        : null;
      return mergeWith({ isValidPhone: true }, initialValues, profile, (a, b) =>
        b === null ? a : b
      );
    },
    validationSchema,
    handleSubmit: (values, { setSubmitting, props, setFieldValue }) => {
      const userCategoryId = values.category && values.category.map((category) => category.value);
      const formData = {
        name: values.name,
        email: props?.profile?.profile?.email,
        admin_id: props?.profile?.profile?.company_user_mapping?.user_id,
        mobile: values.mobile,
        user_type:
          props?.profile?.profile?.company_user_mapping?.access_level === 5 ? 'admin' : 'hr',
        user_category_ids: userCategoryId
      };
      props
        .editPersonalProfile(formData)
        .then(() => {
          setSubmitting(false);
          setFieldValue('isPersonalEdit', false);
        })
        .catch((error) => getErrorMessage(error));
    },
    displayName: 'Edit Personal Pofile'
  })
);
