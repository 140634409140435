import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { deleteIntegrations, postIntegrations } from '../../../../../../api/company';
import Button from '../../../core-components/Button';
import styles from './InputArea.module.scss';
import { errToastMessage } from '../../../utils/Utilities';
import TextWithCopyButton from '../../Utils/TextWithCopyButton';

function InputArea(props) {
  const { values } = props;
  const [isTokenGenerated, setIsTokenGenerated] = useState('');
  const companyId = new URLSearchParams(document.location.search)?.get('company_id') || null;

  useEffect(() => {
    props.setIntegrationDataAPI(companyId);
  }, []);
  useEffect(() => {
    console.log(props);
    const integrationData = props?.integrationData;
    if (integrationData && !integrationData.loading) {
      setIsTokenGenerated(integrationData?.sprinto?.token);
    }
  }, [props.integrationData, companyId]);

  /** API Calls **/
  const postIntegrationsApi = () => {
    const payload = {
      company_id: companyId,
      source: 'SPRINTO',
      details: {}
    };

    postIntegrations(payload)
      .then((resp) => {
        toast.success('API token successfully generated.');
        props.setIntegrationDataAPI(companyId);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onRemoveConnectionHandler = async () => {
    try {
      const resp = await deleteIntegrations('SPRINTO', companyId);
      props.setIntegrationDataAPI(companyId);
      toast.success('Disconnected successfully.');
    } catch (error) {
      errToastMessage(error);
    }
  };

  return (
    <div>
      <Form>
        {!isTokenGenerated ? (
          <Button className={styles.generateTokenButton} onClick={postIntegrationsApi}>
            {'Generate token'}
          </Button>
        ) : (
          <div className={styles.generateTokenButton}>
            <div className={styles.generateTokenButton__heading}>Generated Token</div>
            <TextWithCopyButton text={isTokenGenerated} />
          </div>
        )}
      </Form>

      <div>
        <div className={styles.step1FooterContainer}>
          <Button className={styles.goBackBtn} onClick={() => props.history.push('/integrations')}>
            Go Back
          </Button>
          {isTokenGenerated ? (
            <Button
              className={styles.removeButton}
              variant='outline-danger'
              onClick={onRemoveConnectionHandler}
            >
              Disconnect
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default InputArea;
