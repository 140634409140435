import React, { useState, useEffect } from 'react';

import styles from '../Integrations.module.scss';
import { Button, Row, Col, Container } from 'react-bootstrap';
import FirstPage from '../Utils/TopTextArea';
import LeverSetting from '../Lever/LeverSetting';
import { getToken } from '../../../../../api/localStorage';
import ErrorPage from '../Utils/ErrorPage';
import { leverConfiguration, leverSSOAuthentication } from '../Utils/constants';
import AppConstants from '../../../../../core-components/AppConstants';
import IntegrationLayout from '../Utils/Layout';
import { getIntegrationFieldSubtypeMapping } from '../../../../../api/company';

export default function LeverHome(props) {
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  const [tokenError, setTokenError] = useState(false);
  const [leverConfig, setLeverConfig] = useState({});
  const [subtypeMapping, setLeverSubtypeMapping] = useState({});
  const [isLeverConnected, setIsLeverConnected] = useState(false);

  // useEffect(() => {
  //   if (
  //     errors &&
  //     errors.includes('=') &&
  //     errors.split('=').length > 0 &&
  //     errors.split('=')[1] === 'tokenFailed'
  //   ) {
  //     setTokenError(true);
  //   }
  // }, [errors]);
  useEffect(() => {
    props.setIntegrationAPI();
  }, []);

  useEffect(() => {
    const integrationData = props?.integrationData;
    if (integrationData.lever?.refreshToken) {
      setIsLeverConnected(true);
      let subtypeMapping = {};
      setLeverConfig(integrationData?.lever);
      getIntegrationFieldSubtypeMapping({ companyId, integrationName: 'LEVER' })
        .then((res) => {
          res.data.map((mapping) => {
            if (subtypeMapping?.hasOwnProperty(mapping.subtype_id_fk)) {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: [
                  ...subtypeMapping[mapping.subtype_id_fk].tags,
                  ...mapping.integration_field_value.split(';')
                ]
              };
            } else {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: mapping.integration_field_value.split(';')
              };
            }
          });
          setLeverSubtypeMapping(subtypeMapping);
        })
        .catch((err) => console.error(err));
    }
  }, [props.integrationData]);

  const modalOnHide = () => {
    if (companyId) {
      window.open(`/integrations?company_id=${companyId}`, '_self');
    } else {
      window.open(`/integrations`, '_self');
    }
  };
  // function extractErrorInfo(error) {
  //   const hashIndex = error.indexOf('#');
  //   const ampersandIndex = error.indexOf('?');
  //   const stepperComponent = error.substring(
  //     hashIndex + 1,
  //     ampersandIndex < 0 ? error.length : ampersandIndex
  //   );
  //   const stepperErrorValue =
  //     ampersandIndex > 0 ? error.substring(ampersandIndex + 1, error.length) : null;
  //   return {
  //     stepperComponent,
  //     stepperErrorValue
  //   };
  // }

  const RenderSteps = () => {
    return (
      <Container>
        <Row className='mt-5'>
          <h6 className='font-weight-bold'>Step 1: Authentication via SSO</h6>
        </Row>
        <div className={styles.listItems}>
          {leverSSOAuthentication.map((step, index) => {
            return (
              <div>
                {index + 1}. {step}
              </div>
            );
          })}
        </div>
        <Row className='mt-4'>
          <h6 className='font-weight-bold'>Step 2: Configuration</h6>
        </Row>
        <div className={styles.listItems}>
          {leverConfiguration.map((step, index) => {
            return (
              <div>
                {index + 1}. {step}
              </div>
            );
          })}
        </div>

        <div className={styles.step1FooterContainer}>
          <Button className={styles.goBackBtn} onClick={() => props.history.push('/integrations')}>
            Go Back
          </Button>
          <Button
            className={`${styles.activeBtn} ${styles.accentColor}`}
            href={redirectUrl}
            target='_blank'
          >
            Connect
          </Button>
        </div>
      </Container>
    );
  };
  let scope = `offline_access%20notes:write:admin%20opportunities:write:admin%20resumes:read:admin%20stages:read:admin%20tags:read:admin%20archive_reasons:read:admin%20webhooks:write:admin`;

  const redirectUrl =
    AppConstants.env === 'production'
      ? `https://auth.lever.co/authorize?client_id=CPZZr6wb2yvK3ecrYuOfulNxpkUQwUof&redirect_uri=https://iddorsnwi4.execute-api.ap-south-1.amazonaws.com/production/&response_type=code&state=LEVER;${getToken()}&audience=https://api.lever.co/v1/&scope=${scope}&prompt=consent`
      : `https://sandbox-lever.auth0.com/authorize?client_id=x3N3ByXRJZywy02eOAXeUaflaiI1S5VE&redirect_uri=https://c8a5y1yi90.execute-api.ap-south-1.amazonaws.com/SV-dev/&response_type=code&state=LEVER;${getToken()}&audience=https://api.sandbox.lever.co/v1/&scope=${scope}&prompt=consent`;

  return (
    <IntegrationLayout>
      <div className={styles.parentDivTopTextArea}>
        <FirstPage title={'Lever'} icon={'lever.png'} />
        {!isLeverConnected && <RenderSteps />}

        {(leverConfig?.auth_failure || tokenError) && (
          <ErrorPage
            show={leverConfig?.auth_failure || tokenError}
            redirectUrl={redirectUrl}
            onHide={() => modalOnHide()}
          />
        )}
        {isLeverConnected && (
          <LeverSetting
            subtypeMapping={subtypeMapping}
            leverConfig={leverConfig}
            tokenError={tokenError}
            history={props.history}
          />
        )}
      </div>
    </IntegrationLayout>
  );
}
