import React, { useEffect } from 'react';

import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import history from '../../../../../../utils/history';
import { convertToIndianNumeration } from '../../../../../../utils/utilities';

import styles from './AvailableBalance.module.scss';

function AvailableBalance(props) {
  let { availableBalance, companyBalance } = props;

  useEffect(() => {
    if (history.location.search) {
      const query = new URLSearchParams(location.search);
      const value = query.get('_stepTwo');

      if (value) {
        handleAddCredits();
      }
    } else {
      history.push({ pathname: '/credits' });
    }
  }, [history.location.search]);

  const handleAddCredits = () => {
    props.setCurrentStep(2);
  };

  return (
    <div className={styles.availableBalanceContainer}>
      <p className={styles.availableBalanceText}>{'Available balance'}</p>
      <h5 className={companyBalance >= 0 ? styles.positiveBalance : styles.negativeBalance}>
        {props?.loading ? (
          '-'
        ) : (
          <>
            <span className={styles.rupee}>{companyBalance < 0 && '-'}&#8377;</span>
            {convertToIndianNumeration(companyBalance < 0 ? companyBalance * -1 : companyBalance)}
          </>
        )}
      </h5>
      <div className={styles.actionContainer}>
        <Link to={{ pathname: '/credits', search: '?_stepTwo=true' }}>
          <Button variant={'primary'} className={styles.addCreditBtn}>
            {'+ Add credits'}
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default AvailableBalance;
