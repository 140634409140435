import React, { useState, useEffect } from 'react';
import IntegrationMain from './IntegrationMain';
import IntegrationLayout from './Utils/Layout';

function Integrations(props) {
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  useEffect(() => {
    props.setIntegrationDataAPI(companyId);
    props.getPaymentDetails();
  }, []);

  return (
    <IntegrationLayout>
      <IntegrationMain history={props.history} />
    </IntegrationLayout>
  );
}

export default Integrations;
