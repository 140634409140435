import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import styles from './CancelModal.module.scss';

export default (props) => {
    return (
        <Modal
            size='md'
            centered
            show={props.show}
            onHide={() => props.onClose()}
            className={styles.confirmationModal}
            backdropClassName={'confirmationModalBackdrop'}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Body>
                <Row className={styles.confirModalBody}>
                    <Col lg={12} md={12} sm={12} xs={12} className={styles.confirmTextContainer}>
                        <p className={styles.confirmModalHeading}>
                            <span>{props.heading}</span>
                        </p>
                        <p className={styles.confirmText}>{props.subHeading}</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={styles.confirmModalFooter}>
                <Button
                    type='button'
                    variant='outlined'
                    className={styles.cancelButton}
                    onClick={() => props.onClose()}
                >
                    Close
                </Button>
                <Button
                    type='button'
                    variant='danger'
                    onClick={() => props.onSubmit()}
                    className={styles.submitButton}
                >
                    Cancel Order
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
