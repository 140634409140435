import { FieldArray, Field, getIn } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { DropdownList } from 'react-widgets';
import { debounce, isEmpty, omit } from 'lodash';
import { Form, Row, Col, Button } from 'react-bootstrap';
import styles from './ReferAndEarnInput.module.scss';
import MobileInput from './MobileInput/MobileInput';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { GoEye } from 'react-icons/go';
import {
  baseContactForm,
  findDuplicateEmails,
  hasCompanyAndLinkedError,
  hasMobileAndEmailError,
  referralRelation
} from '../ReferAndEarnUtils';
import { referralEmailValidate } from '../../../../api/company';
import { checkEmailValidation } from '../../../../utils/ValidationSchema';
import { errToastMessage, getErrorMessage, validateEmail } from '../../../../utils/utilities';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField/ValidatedFormInputField';
import { toast } from 'react-toastify';

export default function ReferAndEarnInput(props) {
  const {
    values,
    setFieldValue,
    errors,
    handleSubmit,
    setFieldError,
    isSubmitting,
    handleBlur,
    touched,
    setTouched
  } = props;
  const [duplicateEmailErr, setDuplicateEmailErr] = useState({});
  const [domainExistsErr, setDomainExistsErr] = useState({});

  const handleAddOrRemoveContact = (type, index) => {
    let newContactCounts, newAdditionalContact;
    if (type === 'add') {
      newContactCounts = values.contactCount + 1;
      newAdditionalContact = [...values.additionalContact, baseContactForm];
    } else {
      newContactCounts = values.contactCount - 1;
      newAdditionalContact = values.additionalContact.filter((_, i) => i !== index);
    }
    setFieldValue('contactCount', newContactCounts);
    setFieldValue('additionalContact', newAdditionalContact);
    setDuplicateEmailErr(findDuplicateEmails(values.additionalContact));
  };

  const handleLinkedInInputBlur = (event, name) => {
    const inputValue = event.target.value.trim();
    if (inputValue.length === 1) {
      setFieldValue(name, `https://www.linkedin.com/in/${inputValue}`);
      return;
    }
    setFieldValue(name, inputValue);
  };

  // Check if Email and Domain already exists
  const emailValidate = debounce((email, index) => {
    if (checkEmailValidation(email)) {
      referralEmailValidate(email)
        .then((res) => {
          const resp = res.data;
          setDomainExistsErr((prevDomainExistsErr) => ({
            ...prevDomainExistsErr,
            [index]: resp.isDomainRegistered || resp.isReferralExists ? resp.message : '' // If either of the value is present set the error message with the index as the key
          }));
        })
        .catch((err) => errToastMessage(err));
    } else {
      setDomainExistsErr((prevDomainExistsErr) => ({
        ...prevDomainExistsErr,
        [index]: '' // If error is present we have to invalid error
      }));
    }
    return;
  }, 100);

  const debounceEmailInput = useCallback((email, index) => {
    return emailValidate(email, index);
  }, []);

  // ------------------ Button disabled conditions-------

  // Check if there are form errors
  const hasFormNoErrors =
    props.submitCount > 0
      ? isEmpty(errors)
      : isEmpty(errors)
      ? true
      : errors?.additionalContact.length &&
        errors?.additionalContact.every((contact) =>
          // Omitting two keys as we have to check error for these only when we click on submit button
          isEmpty(omit(contact, ['mobileAndEmailErr', 'companyNameAndLinkedInErr']))
        );
  // Check if the form is submitting
  const isFormSubmitting = !isSubmitting;

  // Check if there are no duplicate email values
  const noDuplicateEmailErrors = Object.values(duplicateEmailErr).every((value) => value === '');

  // Check if there are no already existed domain
  const noDomainEmailErrors = Object.values(domainExistsErr).every((value) => value === '');

  // Combine conditions to determine if the form is valid
  const isValidForm =
    hasFormNoErrors && isFormSubmitting && noDuplicateEmailErrors && noDomainEmailErrors;

  // ----------------------------------------------------------

  return (
    <div className={styles.contactsContainer}>
      <FieldArray
        name='additionalContact'
        render={() => {
          return (
            <>
              <div className={styles.visibleMsg}>
                <GoEye className={styles.visibleMsgIcon} /> This page is visible only to you.
              </div>
              {values.additionalContact && values.additionalContact.length > 0
                ? values.additionalContact.map((val, index) => (
                    <div className={styles.formContainer} key={index}>
                      {index > 0 ? <div className={styles.dashedBorder}></div> : null}
                      <div className={styles.inputHeading}>
                        {/* ---- Contact Index number ----- */}
                        <span className={styles.contactCount}>CONTACT {index + 1} </span>
                        {/* ---- Remove Contact Button ----- */}
                        {index > 0 ? (
                          <span onClick={() => handleAddOrRemoveContact('remove', index)}>
                            <AiOutlineMinusCircle
                              className={cn(styles.addOrRemoveIcon, styles.removeTextColor)}
                            />
                            <span className={cn(styles.addOrRemoveText, styles.removeTextColor)}>
                              Remove
                            </span>
                          </span>
                        ) : null}
                      </div>
                      {/* ------ First row for HR name and source ----------*/}
                      <Row className='mt-2'>
                        <Col sm={5} xs={5} md={5} lg={5} className={styles.inputField}>
                          <Form.Label className={styles.formLabel}>
                            HR name <span>*</span>
                          </Form.Label>
                          <Field
                            type='text'
                            name={`additionalContact.${index}.hrName`}
                            component={ValidatedFormInputField}
                            placeholder={'Enter name'}
                            styleNeeded={false}
                            style={{ width: '360px' }}
                          />
                        </Col>
                        <Col sm={5} xs={5} md={5} lg={5} className={styles.inputField}>
                          <Form.Label className={styles.formLabel}>
                            How do you know this HR? <span>*</span>
                          </Form.Label>
                          <DropdownList
                            data={referralRelation}
                            value={values?.additionalContact[index]?.relation}
                            placeholder={'Choose an option'}
                            onChange={(value) => {
                              setFieldValue(`additionalContact.${index}.relation`, value);
                            }}
                            style={{ width: '360px', height: '38px' }}
                            busy
                            busySpinner={
                              <div
                                style={{
                                  background: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z' fill='%23030D45'/%3e%3c/svg%3e")
    no-repeat right 0.7rem center/15px 20px`,
                                  width: '24px',
                                  height: '24px'
                                }}
                              ></div>
                            }
                          />
                        </Col>
                      </Row>
                      {/* ------ Second row for HR mobile and email ----------*/}
                      <Row className='mt-2'>
                        <Col sm={5} xs={5} md={5} lg={5} className={styles.inputField}>
                          <Form.Group controlId='countrySelect' style={{ width: '360px' }}>
                            <Field
                              name={`additionalContact.${index}.hrMobile`}
                              dropdownName={`additionalContact.${index}.countryCode`}
                              component={MobileInput}
                              label={true}
                              handleBlur={(e) => handleBlur(e)}
                              valuex={values.additionalContact[index].hrMobile}
                            />
                          </Form.Group>

                          {hasMobileAndEmailError(errors, index, props.submitCount) &&
                            errors.additionalContact[index]?.hrMobile &&
                            getIn(touched, `additionalContact.${index}.hrMobile`) && (
                              <div className={styles.inputError}>
                                {errors.additionalContact[index]?.hrMobile ||
                                  'Please enter a valid Mobile number'}
                              </div>
                            )}
                        </Col>
                        <Col sm={5} xs={5} md={5} lg={5} className={styles.inputField}>
                          <Form.Label className={styles.formLabel}>
                            HR Email ID <span>*</span>
                          </Form.Label>
                          <Field
                            type='text'
                            name={`additionalContact.${index}.hrEmail`}
                            component={ValidatedFormInputField}
                            showError={false}
                            placeholder={'Enter official email ID'}
                            onChange={(e) => {
                              setFieldValue(`additionalContact.${index}.hrEmail`, e.target.value);
                              debounceEmailInput(e.target.value.trim(), index);
                            }}
                            onBlur={(e) => {
                              setFieldValue(
                                `additionalContact.${index}.hrEmail`,
                                e.target.value.replace(/\s/g, '')
                              );
                              setDuplicateEmailErr(findDuplicateEmails(values.additionalContact));
                              const newTouched = { ...touched };
                              if (!newTouched.additionalContact) {
                                newTouched.additionalContact = [];
                              }
                              if (!newTouched.additionalContact[index]) {
                                newTouched.additionalContact[index] = {};
                              }
                              newTouched.additionalContact[index].hrEmail = true;
                              setTouched(newTouched);
                            }}
                            styleNeeded={false}
                            style={{ width: '360px' }}
                          />
                          {hasMobileAndEmailError(errors, index, props.submitCount) &&
                            errors.additionalContact[index]?.hrEmail &&
                            getIn(touched, `additionalContact.${index}.hrEmail`) && (
                              <div className={styles.inputError}>
                                {'Please enter a valid Email ID'}
                              </div>
                            )}
                          {touched.additionalContact &&
                            touched.additionalContact[index]?.hrEmail &&
                            !duplicateEmailErr?.hasOwnProperty(index) && (
                              <div className={styles.inputError}>
                                {validateEmail(values.additionalContact[index].hrEmail) &&
                                  'Provide an official work HR email.'}
                              </div>
                            )}
                          {(duplicateEmailErr.hasOwnProperty(index) ||
                            domainExistsErr.hasOwnProperty(index)) && (
                            <div className={styles.inputError}>
                              {duplicateEmailErr[index] || domainExistsErr[index]}
                            </div>
                          )}
                        </Col>
                      </Row>
                      {/* ------ Third row for linked in url and company name----------*/}
                      <Row className='mt-2'>
                        <Col sm={5} xs={5} md={5} lg={5} className={styles.inputField}>
                          <Form.Label className={styles.formLabel}>HR company name</Form.Label>
                          <Field
                            type='text'
                            name={`additionalContact.${index}.companyName`}
                            component={ValidatedFormInputField}
                            placeholder='Enter HR company name'
                            showError={false}
                            styleNeeded={false}
                            style={{ width: '360px' }}
                          />
                        </Col>
                        <Col sm={5} xs={5} md={5} lg={5} className={styles.inputField}>
                          <Form.Label className={styles.formLabel}>HR LinkedIn profile</Form.Label>
                          <Field
                            type='text'
                            name={`additionalContact.${index}.linkedIn`}
                            placeholder='linkedin.com/in/'
                            onChange={(e) =>
                              handleLinkedInInputBlur(e, `additionalContact.${index}.linkedIn`)
                            }
                            showError={false}
                            component={ValidatedFormInputField}
                            styleNeeded={false}
                            style={{ width: '360px' }}
                          />
                        </Col>
                      </Row>
                      {hasCompanyAndLinkedError(errors, index, props.submitCount) &&
                        ((errors.additionalContact[index]?.companyName &&
                          getIn(touched, `additionalContact.${index}.companyName`)) ||
                          (errors.additionalContact[index]?.linkedIn &&
                            getIn(touched, `additionalContact.${index}.linkedIn`))) && (
                          <div className={styles.inputError}>
                            {errors.additionalContact[index]?.companyNameAndLinkedInErr ||
                              'Please provide either valid company name or LinkedIn URL'}
                          </div>
                        )}
                      {/* <div className={styles.anonymous}>
                        <Row className='mt-2'>
                          <Col sm={5} xs={5} md={5} lg={5} className={styles.formCheckbox}>
                            <Form.Check
                              key={index}
                              name='anonymous'
                              id={`anonymous-${index}`}
                              type='checkbox'
                              className={styles.inputCheckbox}
                              checked={values.additionalContact[index].anonymous}
                              onChange={(e) => {
                                props.setFieldValue(
                                  `additionalContact.${index}.anonymous`,
                                  e.target.checked
                                );
                              }}
                              label={
                                <>
                                  <div className={styles.creditBalanceText}>Refer anonymously</div>
                                </>
                              }
                            />
                          </Col>
                        </Row>
                        {values.additionalContact[index].anonymous && (
                          <div className={styles.referNote}>
                            Note: Choosing this option could lower referral success since your name
                            and details won't be used for reference.
                        </div>
                        )}
                      </div> */}
                    </div>
                  ))
                : null}
              <Row>
                <Col
                  sm={5}
                  xs={5}
                  md={5}
                  lg={5}
                  className={cn(
                    styles.addOrRemoveText,
                    styles.addTextColor,
                    values.additionalContact && values.additionalContact.length > 14
                      ? styles.addTextMaxThreshold
                      : ''
                  )}
                  onClick={() => {
                    if (values.additionalContact && values.additionalContact.length <= 14) {
                      handleAddOrRemoveContact('add');
                    }
                  }}
                >
                  <AiOutlinePlusCircle className={styles.addOrRemoveIcon} />
                  <span>Add more contacts</span>
                </Col>
              </Row>
              <Row>
                <Col sm={5} xs={5} md={5} lg={5}>
                  <Button
                    disabled={!isValidForm}
                    onClick={handleSubmit}
                    className={isValidForm ? styles.submitBtn : styles.disabledBtn}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </>
          );
        }}
      />
    </div>
  );
}
