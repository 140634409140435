// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rXTIBz4vhxfwtoH4vEA2 {
  color: #FADB14;
  flex-shrink: 0;
  margin-left: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Bgv/BgvBodyContainer/BgvStepBodyContianer/Feedback/Stars.module.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,cAAA;EACA,oBAAA;AACJ","sourcesContent":[".stars {\n    color: #FADB14;\n    flex-shrink: 0;\n    margin-left: 0.25rem;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stars": `rXTIBz4vhxfwtoH4vEA2`
};
export default ___CSS_LOADER_EXPORT___;
