import React from 'react';
import Image from 'react-bootstrap/Image';
import { ADMIN_URL } from '../Utilities/CommonConstants';
import AppConstants from '../../../../../core-components/AppConstants';
import { ReactSVG } from 'react-svg';

export default (props) => {
  // Compulsory to pass isSA as a props when using in SA side
  let IconComponent = require(`../../images/${props.name}`).default;
  const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));
  const isPng = props?.name.includes('.png');
  const isSvg = props?.name.includes('.svg');

  const pathname = window.location.pathname;

  if (isSvg && !isSA && pathname === '/candidate/view/report') {
    return <ReactSVG src={require(`../../images/${props.name}`)} {...props} />;
  } else {
    return isSA && !isPng ? (
      <IconComponent className={props.className} {...props} />
    ) : props.name ? (
      <Image src={require(`../../images/${props.name}`)} {...props} />
    ) : (
      <Image {...props} />
    );
  }
};
