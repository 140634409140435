import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './StepTwo.validation';
import { validateDataForBulkUpload } from '../../../../../api/company';
import { setBulkUploadData as setBulkUploadDataAPI } from '../../../../../actions/company';
import { getErrorMessage } from '../../core-components/Utilities/utilities';
import { getDataWithError, getDataWithoutError } from '../utils/BulkUploadUtilities';
import { errToastMessage } from '../../utils/Utilities';
const mapStateToProps = (state) => {
  return {
    companyId: (state && state?.profile?.profile?.company_user_mapping?.company_id) || '',
    bulkData: (state && state?.bulkData) || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setBulkUploadDataAPI: (data) => dispatch(setBulkUploadDataAPI(data)) };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        firstName: '',
        lastName: '',
        fullName: '',
        phone: '',
        empId: '',
        email: '',
        hasTwoColumnsForName: false,
        switchToColumnLetters: false,
        filterValue: '',
        isEmailOptional: 0,
        finalData: null,
        tableData: [],
        loadingData: false,
        totalCount: 0,
        errorCount: 0,
        noErrorCount: 0,
        filteredDataCandidatesWithError: [],
        filteredDataCandidatesWithOutError: [],
        buttonClicked: false,
        dropDownValues: [...props?.rows[0], 'None']
      };

      if (props?.config) {
        storedValues['isEmailOptional'] = props?.config?.is_email_optional || 0;
      }

      // this if will work when we are naviagted to this page from  pkg listing page (go back btn)
      if ((props?.isCA || props?.isSA) && props?.isBulkUpload && props?.bulkData) {
        const { rows, values } = props.bulkData;

        if (values) {
          storedValues = values;
        }
      }

      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      setFieldValue('loadingData', true);
      // for SA side = props.saCompanyId passed from AddBulkCandidat.jsx file
      // for CA side = pick companyId from state
      const companyId = props?.isSA ? props?.saCompanyId : props?.companyId;
      validateDataForBulkUpload({ candidates: values?.finalData, companyId: companyId })
        .then((res) => {
          setFieldValue('tableData', res?.data?.data);
          setFieldValue('filteredDataCandidatesWithError', getDataWithError(res?.data?.data));
          setFieldValue('filteredDataCandidatesWithOutError', getDataWithoutError(res?.data?.data));
          setFieldValue('loadingData', false);
          setFieldValue('totalCount', res?.data?.data?.length || 0);
          setFieldValue('errorCount', getDataWithError(res?.data?.data, true));
          setFieldValue('noErrorCount', getDataWithoutError(res?.data?.data, true));

          // creating data for redux
          const copyData = values;
          copyData.tableData = res?.data?.data;
          copyData.filteredDataCandidatesWithError = getDataWithError(res?.data?.data);
          copyData.filteredDataCandidatesWithOutError = getDataWithoutError(res?.data?.data);
          copyData.totalCount = res?.data?.data?.length || 0;
          copyData.errorCount = getDataWithError(res?.data?.data, true);
          copyData.noErrorCount = getDataWithoutError(res?.data?.data, true);
          props.setBulkUploadDataAPI({ type: 'dropDownValues', data: copyData });
        })
        .catch((err) => errToastMessage(err));
    },
    displayName: 'Bulk candidate upload'
  })
);
