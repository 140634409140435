import React, { useState, useEffect } from 'react';
import localStorage from '../../api/localStorage';
import Loader from '../../core-components/Loader';
import AppConstants from '../AppConstants';
import styles from './RenderLink.module.scss';

export default (props) => {
  const token = localStorage.getToken();
  const [docUrl, setDocUrl] = useState('');
  const candidateId = new URLSearchParams(document.location.search).get('candidate_id') || null;
  const invoiceId = new URLSearchParams(document.location.search).get('invoiceId') || null;
  const partnerRequestLogId =
    new URLSearchParams(document.location.search).get('partner_request_log_id') || null;
  useEffect(() => {
    const pathname = props.location.pathname;
    const pathNameArray = pathname.split('/');
    let type = '';
    if (pathNameArray.includes('invoice')) {
      type = 'invoice';
    } else if (pathNameArray.includes('proforma-invoice')) {
      type = 'proforma';
    } else if (pathNameArray.includes('verification-request')) {
      type = 'verification-request';
    } else {
      type = pathNameArray[2];
    }
    switch (type) {
      case 'report':
        setDocUrl(
          `${AppConstants.saDevelopementAPIURL}candidate/report/pdf?candidate_id=${candidateId}&token=${token}`
        );
        break;
      case 'resume':
        setDocUrl(
          `${AppConstants.developmentAPIURL}auth/docs?candidate_id=${candidateId}&company_id=${props?.profile?.profile?.company_user_mapping?.company_id}&type=resume&token=${token}`
        );
        break;
      case 'consent-letter':
        setDocUrl(
          `${AppConstants.developmentAPIURL}auth/docs?candidate_id=${candidateId}&company_id=${props?.profile?.profile?.company_user_mapping?.company_id}&type=consent&token=${token}`
        );
        break;
      case 'invoice':
        setDocUrl(
          `${AppConstants.developmentAPIURL}auth/invoice?invoiceId=${invoiceId}&companyId=${props?.profile?.profile?.company_user_mapping?.company_id}&token=${token}`
        );
        break;
      case 'proforma':
        setDocUrl(
          `${AppConstants.developmentAPIURL}payments/purchase-order/proforma-invoice?invoiceId=${invoiceId}&companyId=${props?.profile?.profile?.company_user_mapping?.company_id}&is_proforma_invoice=true&token=${token}`
        );
        break;
      case 'verification-request':
        setDocUrl(
          `${AppConstants.developmentAPIURL}auth/docs?company_id=${props?.profile?.profile?.company_user_mapping?.company_id}&type=alumni_consent&token=${token}&partner_request_log_id=${partnerRequestLogId}`
        );
        break;
      default:
        props.history.push('/dashboard');
        break;
    }
  }, []);

  function onMyFrameLoad() {
    document.getElementById('loader').hidden = true;
    document.getElementById('report').hidden = false;
  }

  return (
    <div className={styles.RenderLink}>
      <div id='loader' style={{ textAlign: 'center', height: '100%' }}>
        <Loader style={{ textAlign: 'center' }} />
      </div>
      <iframe
        id='report'
        src={docUrl}
        className={styles.iframe}
        onLoad={onMyFrameLoad}
        hidden
        title='Report Document'
      ></iframe>
    </div>
  );
};
