import React, { useState, useEffect } from 'react';
import styles from '../style.module.scss';
import { DropdownList } from 'react-widgets';

const RequestOverView = (props) => {
  const [selectedOption, setSelectedOption] = useState('This Month');
  const [requestOverview, setRequestOverview] = useState([]);
  const { alumniRequestOverview, getRequestOverview } = props;

  const monthMapping = {
    'This Month': 'THIS_MONTH',
    'Last Month': 'LAST_MONTH',
    'Last 3 Months': 'LAST_3_MONTHS',
    'Last 6 Months': 'LAST_6_MONTHS',
    'Last Year': 'LAST_YEAR',
    'Year to Date': 'YEAR_TILL_DATE',
    'All Time': 'ALL_TIME'
  };

  useEffect(() => {
    getRequestOverview(monthMapping[selectedOption]);
  }, [selectedOption]);

  useEffect(() => {
    if (alumniRequestOverview?.loading) {
      setRequestOverview({ totalCreditsEarned: '-', totalRequests: '-', totalUniqueEntities: '-' });
    }
    if (alumniRequestOverview?.data) {
      setRequestOverview(alumniRequestOverview.data);
    }
  }, [alumniRequestOverview]);

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Request Overview</div>
        <div className={styles.dropdown}>
          <DropdownList
            data={[
              'This Month',
              'Last Month',
              'Last 3 Months',
              'Last 6 Months',
              'Last Year',
              'Year to Date',
              'All Time'
            ]}
            defaultValue={'This Month'}
            value={selectedOption}
            onChange={(value) => handleDropdownChange(value)}
          />
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.unit}>
          <div className={styles.unitValue}>{requestOverview.totalRequests || '-'}</div>
          <div className={styles.unitTitle}>Requests</div>
        </div>
        <div className={styles.unit}>
          <div className={styles.unitValue}>
            {requestOverview?.totalCreditsEarned ? (
              <>
                <span style={{ fontFamily: 'roboto' }}>&#8377; </span>
              </>
            ) : (
              ''
            )}
            {requestOverview.totalCreditsEarned || '-'}
          </div>
          <div className={styles.unitTitle}>Credits Earned</div>
        </div>
        <div className={styles.unit}>
          <div className={styles.unitValue}>{requestOverview.totalUniqueEntities || '-'}</div>
          <div className={styles.unitTitle}>Entities Requested</div>
        </div>
      </div>
    </div>
  );
};

export default RequestOverView;
