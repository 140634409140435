import React from 'react';
import { Modal } from 'react-bootstrap';
import AppConstants from '../../../../../core-components/AppConstants';
import Button from '../../core-components/Button';
import styles from './DomainLockModal.module.scss';

export default function DomainLockModal(props) {
  return (
    <div>
      <Modal
        size='lg'
        show={props?.showDomainLock}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop='static'
        keyboard={false}
        onHide={props?.handleShowDomainLock}
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            <h4>Issue with Domain Lock</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.isCA ? (
            <div className={styles.bodyDiv}>
              <div className={styles.heading1}>
                Some of your users belong to domains that are not a part of your whitelist
                <ul className={styles.ul}>
                  <li>
                    Please remove such users <b>OR</b>
                  </li>
                  <li>Add the missing domain to the whitelist</li>
                </ul>
              </div>
              <div className={styles.heading2}>
                You may review your users from the
                <a
                  className={styles.aTag}
                  onClick={() => window.open(`${AppConstants.baseURL}myTeam`, '_blank')}
                >
                  My Team
                </a>
                page
              </div>
            </div>
          ) : (
            <div className={styles.bodyDiv}>
              <div className={styles.heading1}>
                Some of the CA users belong to domains that are not a part of this whitelist
                <ul className={styles.ul}>
                  <li>
                    Please remove such users <b>OR</b>
                  </li>
                  <li>Add the missing domain to the whitelist</li>
                </ul>
              </div>
              <div className={styles.heading2}>
                You may review the CA users from the
                <a
                  className={styles.aTag}
                  onClick={() =>
                    window.open(`${AppConstants.baseURL}company/admins?id=${props.id}`, '_blank')
                  }
                >
                  Admin List
                </a>
                page
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={styles.passwordSubmitBtn}>
          <Button className={styles.btn} onClick={props?.handleShowDomainLock}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
