import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Card } from 'react-bootstrap';
import Button from '../../../../core-components/Button';
import styles from './AdditionalCharges.module.scss';
import Image from '../../../../core-components/Image';
import AdditionalChargeControls from './AdditionalChargeControls/AdditionalChargeControls';
import AdditionalChargesModal from './AdditionalChargesModal/AdditionalChargesModal';
import Loader from '../../../../core-components/Loader';
import validationSchema from './AdditionalCharges.validation';
import { ADC_APPROVE_TILL_LIMIT, ADC_CUSTOM_CONFIG } from '../../../../utils/commonConstant';
import { convertToCommaSeparateString } from '../../../../utils/utilities';

export default function AdditionalCharges(props) {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { getADC, updateADC, adcSettings = {} } = props;
  const { international = {}, passThrough = {} } = adcSettings;

  useEffect(() => {
    getADC();
  }, []);

  if (adcSettings?.loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  let passThroughLimit =
    passThrough?.type === ADC_APPROVE_TILL_LIMIT ? passThrough?.amountLimit : null;

  const initialValues = {
    pass_through: {
      type: passThrough?.type || ADC_CUSTOM_CONFIG
    },
    international: { type: international?.type || ADC_CUSTOM_CONFIG }
  };
  if (passThroughLimit) initialValues.pass_through['amount_limit'] = passThroughLimit;

  const onSubmitForm = (data, { setSubmitting, resetForm }) => {
    updateADC(data)
      .then((res) => {
        if (res?.data?.isAdcPresent) setShowWarningModal(true);
        resetForm();
      })
      .catch((err) => {
        setSubmitting(false);
        console.error(err);
      });
  };

  const handleCancel = () => {
    getADC();
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      enableReinitialize={true}
      initialValues={initialValues}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form noValidate>
          <div className={styles.parentDivWrapper}>
            <>
              <div className={styles.header}>
                Configure additional cost approval steps to prevent any undesired delays in the
                verification process.
                <div className={styles.knowMore}>
                  <a
                    href='https://support.springworks.in/portal/en/kb/articles/additional-cost-settings'
                    target='_blank'
                  >
                    {'Know more'}
                  </a>
                </div>
              </div>
              <Card className={styles.bodyCard}>
                <Card.Body>
                  <AdditionalChargeControls
                    component='passThrough'
                    header='Pass-through fees'
                    isCustom={passThrough?.customConfiguration}
                    amountLimit={passThroughLimit}
                    type={passThrough?.type}
                    name='pass_through'
                    content={
                      <>
                        Fees charged data-sources to provide verification.{' '}
                        <>These fees vary depending on the source.</>
                      </>
                    }
                  />
                  <hr />
                  <AdditionalChargeControls
                    component='international'
                    isCustom={international?.customConfiguration}
                    amountLimit={international?.amountLimit}
                    type={international?.type}
                    name='international'
                    header='International charges'
                    content={
                      <>
                        Charges to provide verification for data-source outside India.{' '}
                        {international?.amountLimit && (
                          <>
                            {' '}
                            (Fixed cost: <span className={styles.robotoFont}>₹</span>
                            {convertToCommaSeparateString(
                              international?.amountLimit.toString()
                            )}{' '}
                            per check).{' '}
                          </>
                        )}{' '}
                      </>
                    }
                  />
                </Card.Body>
                <div className={styles.footer}>
                  <div className={styles.sparkle}>
                    <Image name={'sparkles.svg'} />
                  </div>
                  <div>
                    Over 75% of SpringVerify customers have automated their approvals for faster
                    verification of their candidates
                  </div>
                </div>
              </Card>
              {(!international?.customConfiguration || !passThrough?.customConfiguration) && (
                <div className={styles.buttons}>
                  <Button className={styles.cancelBtn} disabled={!dirty} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    disabled={!isValid || isSubmitting || !dirty}
                    size='large'
                    className={styles.activeBtn}
                  >
                    Save
                  </Button>
                </div>
              )}
            </>
            {showWarningModal && (
              <AdditionalChargesModal
                onHide={() => setShowWarningModal(false)}
                show={showWarningModal}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
