import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { getCompanyTds } from '../../../utils/utilities';
const mapStateToProps = (state) => {
  return {
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyTds: 'NONE', // by default tds will be NONE irrespective what is selected from SA side for the TDS config
        tdsFromSAConfig: props?.paymentDetails?.tds || 'NONE' // this variable decides whether to show TDS otpions or not and which option to show
      };

      // if saved value then update value with it
      if (props && props?.savedTdsValue) {
        storedValues.companyTds = props?.savedTdsValue || 'NONE';
      }
      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    handleSubmit: {},
    displayName: 'apply company Tds buy pkg'
  })
);
