import React from 'react';
import styles from './PasswordRulesBox.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { has } from 'lodash';

const GREEN_CHECK = <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />;
const RED_CROSS = <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />;
const PASSWORD_CRITERIA = {
  TWELEVE_CHARACTERS: 'At least 12 characters',
  UPPER_CASE: 'At least one UPPER CASE character',
  LOWER_CASE: 'At least one lower case character',
  NUMBER: 'At least one number',
  SYMBOL: `At least one of special character except space and single/double quotes`,
  REPEATING: 'Avoid consecutive repeating characters'
};

const getVerificationIcon = (isRuleSatisfied) => {
  return isRuleSatisfied ? GREEN_CHECK : RED_CROSS;
};

export default (props) => {
  const { matchingResults, showAsListOnly } = props;

  return showAsListOnly ? (
    <ul className={styles.ulBox}>
      {Object.entries(PASSWORD_CRITERIA).map(([key, value]) => (
        <li className={styles.listItem} key={key}>
          {value}
        </li>
      ))}
    </ul>
  ) : (
    <Container className={styles.container}>
      <Row className={has(props, 'isUnsetFlexWrap') ? styles.unWrap : null}>
        <Col className={styles.iconBox}>
          {getVerificationIcon(matchingResults?.IS_CHARACTER_RULE_SATISFIED)}
        </Col>
        <Col sm={11} className={`${styles.textBox} `}>
          {PASSWORD_CRITERIA.TWELEVE_CHARACTERS}
        </Col>
      </Row>
      <Row className={has(props, 'isUnsetFlexWrap') ? styles.unWrap : null}>
        <Col className={styles.iconBox}>
          {getVerificationIcon(matchingResults?.IS_AT_LEAST_ONE_UPPER_CASE_PRESENT)}
        </Col>
        <Col sm={11} className={`${styles.textBox} `}>
          {PASSWORD_CRITERIA.UPPER_CASE}
        </Col>
      </Row>
      <Row className={has(props, 'isUnsetFlexWrap') ? styles.unWrap : null}>
        <Col className={styles.iconBox}>
          {getVerificationIcon(matchingResults?.IS_AT_LEAST_ONE_LOWER_CASE_PRESENT)}
        </Col>
        <Col sm={11} className={`${styles.textBox} `}>
          {PASSWORD_CRITERIA.LOWER_CASE}
        </Col>
      </Row>
      <Row className={has(props, 'isUnsetFlexWrap') ? styles.unWrap : null}>
        <Col className={styles.iconBox}>
          {getVerificationIcon(matchingResults?.IS_NUMBER_RULE_SATISFIED)}
        </Col>
        <Col sm={11} className={`${styles.textBox} `}>
          {PASSWORD_CRITERIA.NUMBER}
        </Col>
      </Row>
      <Row className={has(props, 'isUnsetFlexWrap') ? styles.unWrap : null}>
        <Col className={styles.iconBox}>
          {getVerificationIcon(matchingResults?.IS_SYMBOL_RULE_SATISFIED)}
        </Col>
        <Col sm={11} className={`${styles.textBox}`}>
          {PASSWORD_CRITERIA.SYMBOL}
        </Col>
      </Row>
      <Row className={has(props, 'isUnsetFlexWrap') ? styles.unWrap : null}>
        <Col className={styles.iconBox}>
          {getVerificationIcon(matchingResults?.IS_NO_REPEATING_CHAR_PRESENT)}
        </Col>
        <Col sm={11} className={`${styles.textBox}`}>
          {PASSWORD_CRITERIA.REPEATING}
        </Col>
      </Row>
    </Container>
  );
};
