import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';

import { bgvUrl as bgvUrlAPI } from '../../../../api/company';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    bgvUrl: (id) => dispatch(bgvUrlAPI(id))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    handleSubmit: (props, value) => {
      let candidateId = window.location.search.split('=')[1];
      bgvUrlAPI(candidateId)
        .then((response) => {
          window.open(response.data.data, '_blank');
          value.props.onHide();
        })
        .catch((error) => console.log(error));
    },
    displayName: 'Add Candidate BGV Proceed Modal'
  })
);
