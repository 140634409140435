import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import styles from './AccessRestricted.module.scss';
import Image from '../../../../core-components/Image';
import { Link } from 'react-router-dom';

function AccessRestricted(props) {
  return (
    <Card className={`text-center ${styles.card}`}>
      <Card.Body className={styles.body}>
        <Image name={'warning.svg'} />
        <Card.Title className={` ${styles.title}`}>Access restricted</Card.Title>
        <Card.Text>
          Access to add further candidates to the system has been temporarily restricted for your
          company. Please <Badge onClick={() => (window.open('mailto:cs@springverify.com', '_blank'))}>contact us</Badge> to know more.
        </Card.Text>
        <Link to='/candidates'>{'Back to candidates'}</Link>
      </Card.Body>
    </Card>
  );
}
export default AccessRestricted;
