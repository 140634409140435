import React, { useState, useEffect, useRef } from 'react';
import { Field, Form } from 'formik';
import Image from '../core-components/Image';
import { toast } from 'react-toastify';
import {
  forceLogout,
  FOR_WHITE_LISTED_DOMAIN,
  getErrorMessage,
  getTrimmedValue
} from '../core-components/Utilities/utilities';
import styles from './securitySetting.module.scss';
import Checkbox from '../core-components/Checkbox/Checkbox';
import ValidatedRadioFields from '../core-components/ValidatedRadioFields/ValidatedRadioFields';
import CustomizedChip from '../core-components/CustomizedChip/CustomizedChip';
import { isArray, isEmpty } from 'lodash';
import Button from '../core-components/Button';
import Loader from '../core-components/Loader';
import DomainLockModal from './DomainLockModal/DomainLockModal';
import FormSubmitSpinner from '../core-components/FormSubmitSpinner/FormSubmitSpinner';
import LoginMethods from '../../../../images/LoginMethods.svg';
import PasswordExpiry from '../../../../images/PasswordExpiry.svg';
import DomainWhitelist from '../../../../images/DomainWhitelist.svg';
import AutoLogout from '../../../../images/AutoLogout.svg';
import LoginDisableConfirmationModal from './LoginDisableConfirmationModal/LoginDisableConfirmationModal';
import { faPlusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { errToastMessage } from '../utils/Utilities';

const formValuesToAPI = (props, companyId = null) => {
  return {
    company_id: companyId,
    login_methods: {
      password_login: props.values.passwordLogin,
      google_login: props.values.googleLogin,
      microsoft_login: props.values.microsoftLogin
    },
    password_expiry: {
      enabled: props.values.passwordLogin && props.values.passwordExpiryToogle,
      no_of_days: !props.values.passwordExpiryToogle ? null : props.values.passwordExpiry
    },
    auto_logout: {
      enabled: props.values.autoLogoutToogle,
      no_of_hours: !props.values.autoLogoutToogle ? null : props.values.autoLogout
    },
    domain_whitelist: {
      enabled: props.values.domainWhitelistToogle,
      domains: !props.values.domainWhitelistToogle ? [] : props.values.domainWhitelist
    }
  };
};

const SecuritySettings = (props) => {
  const { setFieldValue, dirty, values, handleChange, getSecuritySettings, resetForm } = props;
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;
  const [loading, setLoading] = useState(true);
  const [showDomainLock, setShowDomainLock] = useState(false);
  const [showInputField, setShowInputField] = useState(false); // To show input field
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [logoutCA, setLogoutCA] = useState(false);
  const [localDomainValue, setLocalDomainValue] = useState('@');
  const [disableLoginMethodConfirmationModal, setdisableLoginMethodConfirmationModal] =
    useState(false);

  useEffect(() => {
    setLoading(true);
    getSecuritySettings(companyId)
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.getElementById('myfield') && document.getElementById('myfield').focus();
  }, [showInputField]);

  useEffect(() => {
    if (!props.values.passwordLogin) {
      setFieldValue('passwordExpiry', '');
      setFieldValue('passwordExpiryToogle', false);
    } else {
      setFieldValue('passwordExpiryToogle', !!props.securitySettingsData.passwordExpiry);
      if (props.securitySettingsData.passwordExpiry) {
        setFieldValue('passwordExpiry', props.securitySettingsData.passwordExpiry);
      }
    }
  }, [props.values.passwordLogin]);

  const handleAutoLogOutChange = (val) => {
    setFieldValue('autoLogout', val);
  };

  const handlePasswordExpiryChange = (val) => {
    setFieldValue('passwordExpiry', val);
  };

  const handleFormSubmit = (e) => {
    setFormSubmitting(true);
    e.preventDefault();
    const mapFormValues = formValuesToAPI(props, companyId);
    props
      .postSecuritySettings(mapFormValues)
      .then((res) => {
        if (res) {
          toast.success('Settings updated successfully');
          if (props.isCA && logoutCA) {
            forceLogout();
          } else {
            setFormSubmitting(false);
            getSecuritySettings(companyId);
          }
        } else {
          setFormSubmitting(false);
        }
      })
      .catch((e) => {
        setFormSubmitting(false);
        if (e.message == 'You have existing users, whose domain is not added in the list') {
          setShowDomainLock(true);
        } else {
          errToastMessage(e);
        }
      });
  };

  const addValueToDomainListArray = (newValue) => {
    newValue = getTrimmedValue(newValue);
    if (!props.values.domainWhitelist.includes(newValue)) {
      let whiteListDomainArray = [];
      whiteListDomainArray = [...props.values.domainWhitelist] || [];
      whiteListDomainArray.push(newValue.toLowerCase());
      setFieldValue('domainWhitelist', whiteListDomainArray);
    }
  };

  const isNotValidForm = () => {
    return (
      !isEmpty(props.errors) ||
      (props.values.domainWhitelistToogle && !props.values.domainWhitelist.length > 0) ||
      formSubmitting ||
      (!props?.values?.passwordLogin &&
        !props?.values?.googleLogin &&
        !props?.values?.microsoftLogin) ||
      (props.values.passwordExpiryToogle && !props.values.passwordExpiry) ||
      (props.values.autoLogoutToogle && !props.values.autoLogout)
      // ||
      // initialLoad
    );
  };

  const handleShowDomainLock = () => {
    setShowDomainLock(false);
  };
  const handleDisableLoginMethod = (isCancelled) => {
    setLogoutCA(false);
    setdisableLoginMethodConfirmationModal(false);
  };

  const handleDisableLogin = (e) => {
    e.preventDefault();
    setdisableLoginMethodConfirmationModal(true);
  };

  const checkLoginEdited = () => {
    if (
      (props?.securitySettingsData?.loginMethodGoogle && !props?.values?.googleLogin) ||
      (props?.securitySettingsData?.loginMethodMicrosoft && !props?.values?.microsoftLogin) ||
      (props?.securitySettingsData?.loginMethodPassword && !props?.values?.passwordLogin)
    ) {
      //State maintained for logging out CA when any login method was disabled
      setLogoutCA(true);
      return true;
    }
    return false;
  };

  const handleCancel = () => {
    resetForm();
  };

  if (loading || props?.securitySettingsData?.loading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={styles.outerdiv}>
        <div className={props.isSA ? styles.knowMoreSA : styles.headerContent}>
          Configure security settings to safeguard your company's data against unauthorized access.
          <a
            href='https://support.springworks.in/portal/en/kb/articles/a-guide-to-security-settings-on-springverify'
            target='_blank'
          >
            Know more
          </a>
        </div>
        <Form
          onSubmit={(e) => {
            if (checkLoginEdited()) {
              handleDisableLogin(e);
            } else {
              handleFormSubmit(e);
            }
          }}
          className={styles.formStyle}
        >
          <div className={styles.boxContainer + ' ' + (props.isSA && styles.boxContainerSA)}>
            {/* ---------------------------- Div for Login Method ------------------------------ */}
            <div className={styles.fieldWrapper}>
              {/* Added condition because for SA side for svg this import works */}
              {props.isSA ? (
                <LoginMethods />
              ) : (
                <Image className={styles.sectionIcon} width={32} name={'SecurityLogin.svg'} />
              )}
              <div className={styles.flexDivContainer}>
                <h5 className={styles.sectionHeader}>Login Methods</h5>
                <div className={styles.subTitle}>
                  Allow users login to this portal via selected method(s) only
                </div>
                <div className={styles.options}>
                  <Field
                    custom
                    name='passwordLogin'
                    value={values.passwordLogin}
                    // onChange={(e) => handlePasswordLogin(e)}
                    component={Checkbox}
                    className={styles.optionsField + ' custom-checkbox-style-1'}
                    onChange={handleChange}
                    label='Password Login'
                  />
                  <Field
                    custom
                    name='googleLogin'
                    value={values.googleLogin}
                    // onChange={(e) => handleGoogleLogin(e)}
                    onChange={handleChange}
                    className={styles.optionsField + ' custom-checkbox-style-1'}
                    component={Checkbox}
                    label='Google Account'
                  />
                  <Field
                    custom
                    name='microsoftLogin'
                    className={styles.optionsField + ' custom-checkbox-style-1'}
                    component={Checkbox}
                    value={values.microsoftLogin}
                    // onChange={(e) => handleMicrosoftLogin(e)}
                    onChange={handleChange}
                    label='Microsoft Account'
                  />
                </div>

                {/* Error block for login method section */}
                {props?.values?.passwordLogin === false &&
                props?.values?.googleLogin === false &&
                props?.values?.microsoftLogin === false ? (
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#dc3545',
                      marginTop: '-10px',
                      position: 'absolute'
                    }}
                  >
                    Enable at least one login method
                  </p>
                ) : null}
              </div>
            </div>

            <hr />

            {/* ------------------------- Div for Password Expiry  ------------------------------ */}
            <div className={styles.fieldWrapper}>
              {props.isSA ? (
                <PasswordExpiry />
              ) : (
                <Image
                  className={styles.sectionIcon}
                  width={32}
                  name={'SecurityPasswordExpiry.svg'}
                />
              )}
              <div
                className={
                  props?.values?.passwordLogin
                    ? styles.flexDivContainer
                    : styles.flexDivContainerGreyed
                }
              >
                <h5 className={styles.sectionHeader}>Password Expiry</h5>
                <div className={styles.subTitle}>
                  Force users to reset their password after a certain period of their last password
                  change
                </div>

                <div className={styles.optionPasswordExpiry}>
                  <Field
                    name='passwordExpiryToogle'
                    className={'custom-radio-style-1 mb-0'}
                    customClassNames={styles.radioClassName}
                    component={ValidatedRadioFields}
                    options={[
                      { value: false, label: 'Disabled', disable: !props?.values?.passwordLogin },
                      { value: true, label: 'Enabled', disable: !props?.values?.passwordLogin }
                    ]}
                    onChange={(e) => {
                      if (e.target.value == 'false') {
                        setFieldValue('passwordExpiry', '');
                      } else {
                        setFieldValue(
                          'passwordExpiry',
                          props.securitySettingsData.passwordExpiry || ''
                        );
                      }
                      setFieldValue(
                        'passwordExpiryToogle',
                        e.target.value == 'true' ? true : false
                      );
                    }}
                  />
                  <div className={'position-relative ' + styles.inputContainerSA}>
                    <div>
                      <Field
                        name={'passwordExpiry'}
                        label='passwordExpiry'
                        className={styles.inputField}
                        variant='outlined'
                        size='small'
                        placeholder='15-60'
                        type='text'
                        disabled={!props?.values?.passwordExpiryToogle}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          let numericValue = inputValue;

                          // Remove non-numeric characters
                          if (!isEmpty(inputValue))
                            numericValue = inputValue.replace(/[^0-9]/g, '');
                          handlePasswordExpiryChange(numericValue);
                        }}
                      />

                      <span className={styles.textField}>days</span>
                    </div>
                    {/* Error block for password expiry section */}
                    {props?.errors?.passwordExpiry ? (
                      <div className={styles.errorDiv}>{props?.errors?.passwordExpiry}</div>
                    ) : props.values.passwordExpiryToogle && !props.values.passwordExpiry ? (
                      <div className={styles.errorDiv}>{'Provide a valid input'}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {/* --------------------------- Div for AutoLogout  ------------------------------ */}
            <div className={styles.fieldWrapper}>
              {props.isSA ? (
                <AutoLogout />
              ) : (
                <Image className={styles.sectionIcon} width={32} name={'SecurityAutoLogout.svg'} />
              )}
              <div className={styles.flexDivContainer}>
                <h5 className={styles.sectionHeader}>Auto-Logout</h5>
                <div className={styles.subTitle}> Logs out user after period of inactivity </div>

                <div className={styles.optionPasswordExpiry}>
                  <Field
                    name='autoLogoutToogle'
                    component={ValidatedRadioFields}
                    className={'custom-radio-style-1 mb-0'}
                    customClassNames={styles.radioClassName}
                    options={[
                      { value: false, label: 'Disabled' },
                      { value: true, label: 'Enabled' }
                    ]}
                    onChange={(e) => {
                      if (e.target.value == 'false') {
                        setFieldValue('autoLogout', '');
                      } else {
                        setFieldValue('autoLogout', props.securitySettingsData.autoLogout || '');
                      }
                      setFieldValue('autoLogoutToogle', e.target.value == 'true' ? true : false);
                    }}
                  />
                  <div className={'position-relative ' + styles.inputContainerSA}>
                    <div>
                      <Field
                        name={'autoLogout'}
                        label='autoLogout'
                        className={styles.inputField}
                        variant='outlined'
                        size='small'
                        type='text'
                        placeholder='1-48'
                        disabled={!props?.values?.autoLogoutToogle}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          let numericValue = inputValue;
                          // Remove non-numeric characters
                          if (!isEmpty(inputValue))
                            numericValue = inputValue.replace(/[^0-9]/g, '');

                          handleAutoLogOutChange(numericValue);
                        }}
                      />
                      <span className={styles.textField}>hours</span>
                    </div>
                    {/* Error block for auto logout section */}
                    {props?.errors?.autoLogout ? (
                      <div className={styles.errorDiv}>{props?.errors?.autoLogout}</div>
                    ) : props.values.autoLogoutToogle && !props.values.autoLogout ? (
                      <div className={styles.errorDiv}>{'Provide a valid input'}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <hr />
            {/* -------------------------- Div for Domain Whitelist  --------------------------- */}
            <div className={styles.fieldWrapper}>
              {props.isSA ? (
                <DomainWhitelist />
              ) : (
                <Image className={styles.sectionIcon} width={32} name={'SecurityDomain.svg'} />
              )}

              <div className={styles.flexDivContainer}>
                <h5 className={styles.sectionHeader}>Domain Whitelist</h5>
                <div className={styles.subTitle}>
                  Allow addition of user within your company domain(s) only
                </div>
                <div className={styles.optionDomainWhitelisted}>
                  <Field
                    name='domainWhitelistToogle'
                    component={ValidatedRadioFields}
                    className={'custom-radio-style-1'}
                    customClassNames={styles.radioClassName}
                    options={[
                      { value: false, label: 'Disabled' },
                      { value: true, label: 'Enabled' }
                    ]}
                    onChange={(e) => {
                      if (e.target.value == 'true') {
                        let whiteListDomainArray = [];
                        //Setting whiteListed state variable same as API value when we toggled from disabled to enabled
                        props.securitySettingsData &&
                          props.securitySettingsData.companyUserDomainAllowedLists &&
                          props.securitySettingsData.companyUserDomainAllowedLists.length > 0 &&
                          props.securitySettingsData.companyUserDomainAllowedLists.map((value) => {
                            whiteListDomainArray.push(value.domain);
                          });
                        setFieldValue('domainWhitelist', whiteListDomainArray);
                      } else {
                        setFieldValue('domainWhitelist', []);
                      }
                      setFieldValue(
                        'domainWhitelistToogle',
                        e.target.value == 'true' ? true : false
                      );
                    }}
                  />
                </div>
                {props.values.domainWhitelistToogle && (
                  <div className={styles.domainWhitelistToogleField}>
                    {props.values.domainWhitelist.map((option, index) => {
                      // Matching regex to show red color chips
                      const res = FOR_WHITE_LISTED_DOMAIN.test(option);
                      return (
                        <div className={styles.chipsDiv}>
                          <CustomizedChip
                            key={index}
                            variant='outlined'
                            label={option}
                            error={true}
                            className={styles.chips}
                            color={res ? '#388e3c' : '#dc3545'}
                            deleteIconHoverColor={'#dc3545'}
                            onDelete={() => {
                              let whiteListDomainArray = [];
                              whiteListDomainArray =
                                props.values.domainWhitelist &&
                                props.values.domainWhitelist.length &&
                                props.values.domainWhitelist.filter((v) => v !== option);
                              setFieldValue('domainWhitelist', whiteListDomainArray);
                            }}
                          />
                          {res ? null : <div>Invalid Domain</div>}
                        </div>
                      );
                    })}

                    <div className='position-relative'>
                      <div className='d-flex align-items-center'>
                        {showInputField || props.values.domainWhitelist.length === 0 ? (
                          <Field
                            variant='outlined'
                            size='small'
                            type='text'
                            id='myfield'
                            value={localDomainValue}
                            placeholder='@'
                            className={styles.inputField}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                                addValueToDomainListArray(localDomainValue.trim().toLowerCase());
                                setLocalDomainValue('@');
                                setShowInputField(false);
                              }
                            }}
                            onBlur={(event) => {
                              event.preventDefault();
                              addValueToDomainListArray(localDomainValue.trim().toLowerCase());
                              setLocalDomainValue('@');
                              setShowInputField(false);
                            }}
                            onChange={(e) => {
                              setLocalDomainValue(e.target.value);
                            }}
                          />
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              color={'#1890FF'}
                              className={styles.image}
                              onClick={() => {
                                setShowInputField(true);
                              }}
                            />
                          </div>
                        )}
                        {props.values.domainWhitelist.length === 0 && (
                          <div className='position-relative'>
                            <OverlayTrigger
                              placement={props?.isSA ? 'right' : 'top'}
                              overlay={
                                <Tooltip
                                  className='ssInfoToolTipContainer'
                                  style={{
                                    fontSize: '8px'
                                  }}
                                >
                                  <p className={props?.isSA ? styles.tooltipTextSA : ''}>
                                    Add multiple domains by pressing enter key
                                  </p>
                                </Tooltip>
                              }
                            >
                              <div>
                                {props?.isSA ? (
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className={styles.fontAwesomeIconSA}
                                    color={'#1890FF'}
                                  />
                                ) : (
                                  <Image
                                    className='ml-2'
                                    width={20}
                                    isSA={props?.isSA}
                                    name='info.svg'
                                  />
                                )}
                              </div>
                            </OverlayTrigger>
                          </div>
                        )}
                      </div>
                      {/* Error block for domain whitelist section */}
                      {props?.errors?.domainWhitelist &&
                      !isArray(props?.errors?.domainWhitelist) ? (
                        <div className={styles.errorDiv}>Maximum number of domains reached</div>
                      ) : props.values.domainWhitelistToogle &&
                        !props.values.domainWhitelist.length ? (
                        <div className={styles.errorDiv}>{'Provide a valid input'}</div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            {/* Only CA side we are showing cancel button as per design */}
            {props.isCA && (
              <Button className={styles.cancelBtn} disabled={!dirty} onClick={handleCancel}>
                Cancel
              </Button>
            )}

            <Button
              type='submit'
              size='large'
              className={
                isNotValidForm() || !dirty
                  ? styles.disabledBtn
                  : props.isCA
                  ? styles.activeBtn
                  : styles.activeBtnSA //Green color button for SA side
              }
              disabled={isNotValidForm() || !dirty}
            >
              {/* || saveDisabledß */}
              Save
            </Button>
          </div>
        </Form>
      </div>
      {formSubmitting && <FormSubmitSpinner />}
      <LoginDisableConfirmationModal
        handleDisableLoginMethod={handleDisableLoginMethod}
        handleFormSubmit={handleFormSubmit}
        disableLoginMethodConfirmationModal={disableLoginMethodConfirmationModal}
        isCA={props.isCA}
      />
      <DomainLockModal
        handleShowDomainLock={handleShowDomainLock}
        showDomainLock={showDomainLock}
        isCA={props.isCA}
        id={companyId}
      />
    </>
  );
};

SecuritySettings.defaultProps = {
  isCA: false, // to check whether component is imported in CA side or not
  isSA: false // to check whether component is imported in SA side or not
};

export default SecuritySettings;
