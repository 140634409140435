import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';

const initialValue = {
  category: ''
};

export default compose(
  connect(null, null),
  withFormik({
    mapPropsToValues: (props) => {
      return mergeWith({}, {});
    },
    enableReinitialize: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {},
    displayName: 'CountryCodes'
  })
);
