import { connect } from 'react-redux';
import { getBrandSettings, editBrandSettings } from '../../../../actions/company';

const mapStateToProps = (state) => {
  return {
    brandSettings: state.brandSettings,
    profile: state.profile.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrandSettings: () => dispatch(getBrandSettings()),
    editBrandSettings: (data) => dispatch(editBrandSettings(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
