// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { has, isEmpty } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import Cookies from 'js-cookie';

// Import  styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styles from './CompanyAdmin.module.scss';

// Import local components & utils
import { errToastMessage, getBanners, toCamelCaseTemp } from '../../utils/utilities';
import { getHRMSDetails, getRecommendedChecks } from '../../api/company';
import localStorage from '../../api/localStorage';

import RecentlyAdded from './Dashboard/RecentlyAdded';
import Loader from '../../core-components/Loader';
import WelcomeModal from './AlumniWelcomeModal/WelcomeModal';
import UpsellBanner from './Banners/UpsellBanner/UpsellBanner';
import AlumniVerification from './Banners/AlumniVerification/AlumniVerification';
import MarketingBanner from '../MarketingBanner/MarketingBanner';
import RazorpayBanner from './Banners/Razorpay/RazorpayBanner';

export default (props) => {
  // Props
  const { paymentDetails } = props;

  // State variables
  const [recentCandidates, setRecentCandidates] = useState([]);
  const [loading, setLoading] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [upsellRecommendationData, setUpsellRecommendationData] = useState({});
  const [carouselBanners, setCarouselBanners] = useState(['refer']);

  // Other constants
  const CarouselBanners = {
    upsell: (
      <UpsellBanner
        getRecommendationsChecksApi={() => getRecommendationsChecksApi('DASHBOARD')}
        upsellRecommendationData={upsellRecommendationData}
      />
    ),
    // As per Improvement 20836
    // refer: <ReferAndEarnBanner history={props.history} />,

    // Commenting out the Slack Connect Banner under IMP-21002
    // connect: (
    //   <SlackConnectBanner
    //     history={props.history}
    //     getBanner={() =>
    //       setCarouselBanners(getBanners({ paymentDetails, upsellRecommendationData }))
    //     }
    //   />
    // ),
    alumniVerification: <AlumniVerification />,
    marketing: <MarketingBanner />,
    razorpay: <RazorpayBanner />
  };
  const swiperRefLocal = useRef();

  // UseEffects
  useEffect(() => {
    window.scrollTo(0, 0);
    props?.getCandidates('10', '0').then(() => {
      setRecentCandidates(props?.candidates?.all[0]?.candidates);
      setLoading(props?.candidates?.loading);
    });

    if (!props?.profile || isEmpty(props?.profile) || !has(props?.profile, 'profile')) {
      props
        ?.logout()
        .then(() => {
          localStorage.clearStorage();
          window.location.reload();
        })
        .catch((error) => {
          errToastMessage(error);
        });
    }

    getRecommendationsChecksApi('DASHBOARD');
  }, []);

  useEffect(() => {
    if (paymentDetails?.hasOwnProperty('alumniVerificationEnabled')) {
      getHRMSDetails()
        .then((res) => {
          exEmpRemainderModalHandler(res?.data?.data?.integrationDetails ? true : false);
        })
        .catch((err) => {
          exEmpRemainderModalHandler(false);
          console.error(err);
        });
    }

    setCarouselBanners(getBanners({ paymentDetails, upsellRecommendationData }));
  }, [paymentDetails]);

  useEffect(() => {
    setCarouselBanners(getBanners({ paymentDetails, upsellRecommendationData }));
  }, [upsellRecommendationData]);

  // Helper Functions
  const exEmpRemainderModalHandler = (isConnectedWithHRMS) => {
    const hideXEmpRemainderTill = Cookies.get(
      'hideXEmpRemainderTill' + props?.profile?.profile?.id
    );
    const isXEmpModalDisplayedToday = Cookies.get(
      'isXEmpModalDisplayedToday' + props?.profile?.profile?.id
    );
    const isExEmpEnabled = props?.paymentDetails?.alumniVerificationEnabled;

    const isSetShowModal =
      hideXEmpRemainderTill || isExEmpEnabled || isConnectedWithHRMS || isXEmpModalDisplayedToday
        ? false
        : true;
    setShowModal(isSetShowModal);
  };

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  const getRecommendationsChecksApi = (currentRoute) => {
    const params = {
      page: currentRoute
    };

    getRecommendedChecks(params)
      .then((res) => {
        setUpsellRecommendationData(toCamelCaseTemp(res?.data?.data));
      })
      .catch((err) => {
        errToastMessage(err);
        console.error(err);
      });
  };

  // Child components
  const Carousel = () => {
    return (
      <div
        className={styles.cContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {carouselBanners.length ? (
          <div className='swiper-custom'>
            <Swiper
              loop={true}
              ref={swiperRefLocal}
              slidesPerView={1}
              speed={700}
              autoplay={{ delay: 6000 }}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination]}
              className='mySwiper'
            >
              {carouselBanners.map((item, i) => {
                return (
                  <SwiperSlide key={'CAR_' + i}>
                    <div>{CarouselBanners[item]}</div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {showModal && !loading && !props?.paymentDetails?.disableCaFeatureReminder && (
        <WelcomeModal
          userId={props?.profile?.profile?.id}
          {...props}
          onHide={() => {
            const midnight = new Date();
            midnight.setHours(23, 59, 59, 999);
            Cookies.set(
              'isXEmpModalDisplayedToday' + props?.profile?.profile?.id,
              midnight.toDateString(),
              {
                expires: midnight,
                path: '/'
              }
            );
            setShowModal(false);
          }}
        />
      )}
      {props?.profile && !isEmpty(props?.profile) && has(props?.profile, 'profile') ? (
        <>
          {Carousel()}
          <RecentlyAdded data={recentCandidates} loading={loading} {...props} />
        </>
      ) : (
        <Card className={styles.loadingviewCandidateCard}>
          <Loader className={styles.viewCandidateLoader} />
        </Card>
      )}
    </div>
  );
};
