import React, { useState, useEffect } from 'react';
import styles from '../Integrations.module.scss';
import InputArea from './Components/InputArea';
import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';

import GreenHouseSetting from './GreenHouseSetting';

export default function GreenHouseHome(props) {
  const [greenHouseApiKey, setGreenHouseApiKey] = useState('');
  const [isGreenHouseWebHookDetected, setIsGreenHouseWebHookDetected] = useState(false);
  const [greenHouseConfig, setGreenHouseConfig] = useState({});
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;

  useEffect(() => {
    if (typeof props.setIntegrationAPI === 'function') {
      props.setIntegrationAPI();
    }
  }, []);

  useEffect(() => {
    const integrationData = props?.integrationData;

    if (integrationData && integrationData.greenhouse && integrationData.greenhouse.apiKey) {
      setGreenHouseApiKey(integrationData.greenhouse.apiKey);
      if (integrationData.greenhouse?.webhookEnabled != 0) {
        setIsGreenHouseWebHookDetected(true);
      }
      setGreenHouseConfig(integrationData.greenhouse);
    }
  }, [props.integrationData]);

  return (
    <IntegrationLayout>
      <div className={styles.parentDivTopTextArea}>
        <TopTextArea title={'Greenhouse'} icon={'greenhouse.png'} />
      </div>
      <div className={`${styles.parentDivInputArea} mb-5`}>
        {greenHouseApiKey && isGreenHouseWebHookDetected == 1 ? (
          <GreenHouseSetting history={props.history} greenHouseConfig={greenHouseConfig} />
        ) : (
          <InputArea history={props.history} />
        )}
      </div>
    </IntegrationLayout>
  );
}
