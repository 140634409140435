const sample = {
  staff_id: {
    name: "Staff ID",
    type: "string",
    unique: true,
    required: true,
    example: ["EMP001", "EMP002", "EMP003"],
    alternativeMatches: ["Employee ID", "ID"],
    validations: {
      regex: "^[A-Z0-9]+$",
      errorMessage:
        "Staff ID is required and should contain only uppercase letters and numbers.",
      level: "error",
    },
    rowData: [
      "SHRIND201",
      "SHRIND202",
      "SHRIND203",
      "SHRIND204",
      "SHRIND205",
      "SHRIND206",
      "SHRIND207",
      "SHRIND208",
      "SHRIND209",
      "SHRIND210",
      "SHRIND211",
      "SHRIND212",
      "SHRIND213",
      "SHRIND214",
      "SHRIND215",
      "SHRIND216",
      "SHRIND217",
      "SHRIND218",
      "SHRIND219",
      "SHRIND220",
      "SHRIND221",
      "SHRIND222",
      "SHRIND223",
      "SHRIND224",
      "SHRIND225",
      "SHRIND226",
      "SHRIND227",
      "SHRIND228",
      "SHRIND229",
      "SHRIND230",
      "SHRIND231",
      "SHRIND232",
      "SHRIND233",
      "SHRIND234",
      "SHRIND235",
      "SHRIND236",
      "SHRIND237",
      "SHRIND238",
      "SHRIND239",
      "SHRIND240",
      "SHRIND241",
      "SHRIND242",
      "SHRIND243",
      "SHRIND244",
      "SHRIND245",
      "SHRIND246",
      "SHRIND247",
      "SHRIND248",
      "SHRIND249",
      "SHRIND250",
      "SHRIND251",
      "SHRIND252",
      "SHRIND253",
      "SHRIND254",
      "SHRIND255",
      "SHRIND256",
      "SHRIND257",
      "SHRIND258",
      "SHRIND259",
      "SHRIND260",
      "SHRIND261",
      "SHRIND262",
      "SHRIND263",
      "SHRIND264",
      "SHRIND265",
      "SHRIND266",
      "SHRIND267",
      "SHRIND268",
      "SHRIND269",
      "SHRIND270",
      "SHRIND271",
      "SHRIND272",
      "SHRIND273",
      "SHRIND274",
      "SHRIND275",
      "SHRIND276",
      "SHRIND277",
      "SHRIND278",
      "SHRIND279",
      "SHRIND280",
      "SHRIND281",
      "SHRIND282",
      "SHRIND283",
      "SHRIND284",
      "SHRIND285",
      "SHRIND286",
      "SHRIND287",
      "SHRIND288",
      "SHRIND289",
      "SHRIND290",
      "SHRIND291",
      "SHRIND292",
      "SHRIND293",
      "SHRIND294",
      "SHRIND295",
      "SHRIND296",
      "SHRIND297",
      "SHRIND298",
      "SHRIND299",
      "SHRIND300",
      "SHRIND301",
      "SHRIND302",
      "SHRIND303",
      "SHRIND304",
      "SHRIND305",
      "SHRIND306",
      "SHRIND307",
      "SHRIND308",
      "SHRIND309",
      "SHRIND310",
      "SHRIND311",
      "SHRIND312",
      "SHRIND313",
      "SHRIND314",
      "SHRIND315",
      "SHRIND316",
      "SHRIND317",
      "SHRIND318",
      "SHRIND319",
      "SHRIND320",
      "SHRIND321",
      "SHRIND322",
      "SHRIND323",
      "SHRIND324",
      "SHRIND325",
      "SHRIND326",
      "SHRIND327",
      "SHRIND328",
      "SHRIND329",
      "SHRIND330",
      "SHRIND331",
      "SHRIND332",
      "SHRIND333",
      "SHRIND334",
      "SHRIND335",
      "SHRIND336",
      "SHRIND337",
      "SHRIND338",
      "SHRIND339",
      "SHRIND340",
      "SHRIND341",
      "SHRIND342",
      "SHRIND343",
      "SHRIND344",
      "SHRIND345",
      "SHRIND346",
      "SHRIND347",
      "SHRIND348",
      "SHRIND349",
      "SHRIND350",
      "SHRIND351",
      "SHRIND352",
      "SHRIND353",
      "SHRIND354",
      "SHRIND355",
      "SHRIND356",
      "SHRIND357",
      "SHRIND358",
      "SHRIND359",
      "SHRIND360",
      "SHRIND361",
      "SHRIND362",
      "SHRIND363",
      "SHRIND364",
      "SHRIND365",
      "SHRIND366",
      "SHRIND367",
      "SHRIND368",
      "SHRIND369",
      "SHRIND370",
      "SHRIND371",
      "SHRIND372",
      "SHRIND373",
      "SHRIND374",
      "SHRIND375",
      "SHRIND376",
      "SHRIND377",
      "SHRIND378",
      "SHRIND379",
      "SHRIND380",
      "SHRIND381",
      "SHRIND382",
      "SHRIND383",
      "SHRIND384",
      "SHRIND385",
      "SHRIND386",
      "SHRIND387",
      "SHRIND388",
      "SHRIND389",
      "SHRIND390",
      "SHRIND391",
      "SHRIND392",
      "SHRIND393",
      "SHRIND394",
      "SHRIND395",
      "SHRIND396",
      "SHRIND397",
      "SHRIND398",
      "SHRIND399",
      "SHRIND400",
    ],
    selectedHeader: { value: 0, label: "Staff ID" },
    value_percentage: 100,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  full_name: {
    name: "Full Name",
    type: "string",
    required: true,
    example: ["John Doe", "Jane Smith", "Bob Johnson"],
    alternativeMatches: ["Name", "Employee Name"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Full Name is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      "Deelip.           Hegde",
      "Vittal   Hegde",
      "Suyog &",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Harish",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Anoop",
      "Vanshika",
      "Saurab",
      "Varada",
      "Deelip",
      "Vittal",
      "Suyog",
      "Harsha",
      "Amit",
      "Amrit",
      "Sneha",
    ],
    selectedHeader: { value: 1, label: "Full Name *" },
    value_percentage: 100,
    validation_percentage: 99,
    date_format: "YYYY-MM-DD",
  },
  date_of_birth: {
    name: "Date of Birth",
    type: "date",
    required: true,
    example: ["1990-01-01", "1985-05-20", "1978-11-15"],
    alternativeMatches: ["DOB", "Birth Date"],
    validations: { errorMessage: "Date of Birth is required.", level: "error" },
    rowData: [
      "1993-02-28",
      "1993-03-01",
      "1993-03-02",
      "1993-03-03",
      "1993-03-04",
      "1993-03-05",
      "1993-03-06",
      "1993-03-07",
      "1993-03-08",
      "1993-03-09",
      "1993-03-10",
      "1993-03-11",
      "1993-03-12",
      "1993-03-13",
      "1993-03-14",
      "1993-03-15",
      "1993-03-16",
      "1993-03-17",
      "1993-03-18",
      "1993-03-19",
      "1993-03-20",
      "1993-03-21",
      "1993-03-22",
      "1993-03-23",
      "1993-03-24",
      "1993-03-25",
      "1993-03-26",
      "1993-03-27",
      "1993-03-28",
      "1993-03-29",
      "1993-03-30",
      "1993-03-31",
      "1993-04-01",
      "1993-04-02",
      "1993-04-03",
      "1993-04-04",
      "1993-04-05",
      "1993-04-06",
      "1993-04-07",
      "1993-04-08",
      "1993-04-09",
      "1993-04-10",
      "1993-04-11",
      "1993-04-12",
      "1993-04-13",
      "1993-04-14",
      "1993-04-15",
      "1993-04-16",
      "1993-04-17",
      "1993-04-18",
      "1993-04-19",
      "1993-04-20",
      "1993-04-21",
      "1993-04-22",
      "1993-04-23",
      "1993-04-24",
      "1993-04-25",
      "1993-04-26",
      "1993-04-27",
      "1993-04-28",
      "1993-04-29",
      "1993-04-30",
      "1993-05-01",
      "1993-05-02",
      "1993-05-03",
      "1993-05-04",
      "1993-05-05",
      "1993-05-06",
      "1993-05-07",
      "1993-05-08",
      "1993-05-09",
      "1993-05-10",
      "1993-05-11",
      "1993-05-12",
      "1993-05-13",
      "1993-05-14",
      "1993-05-15",
      "1993-05-16",
      "1993-05-17",
      "1993-05-18",
      "1993-05-19",
      "1993-05-20",
      "1993-05-21",
      "1993-05-22",
      "1993-05-23",
      "1993-05-24",
      "1993-05-25",
      "1993-05-26",
      "1993-05-27",
      "1993-05-28",
      "1993-05-29",
      "1993-05-30",
      "1993-05-31",
      "1993-06-01",
      "1993-06-02",
      "1993-06-03",
      "1993-06-04",
      "1993-06-05",
      "1993-06-06",
      "1993-06-07",
      "1993-06-08",
      "1993-06-09",
      "1993-06-10",
      "1993-06-11",
      "1993-06-12",
      "1993-06-13",
      "1993-06-14",
      "1993-06-15",
      "1993-06-16",
      "1993-06-17",
      "1993-06-18",
      "1993-06-19",
      "1993-06-20",
      "1993-06-21",
      "1993-06-22",
      "1993-06-23",
      "1993-06-24",
      "1993-06-25",
      "1993-06-26",
      "1993-06-27",
      "1993-06-28",
      "1993-06-29",
      "1993-06-30",
      "1993-07-01",
      "1993-07-02",
      "1993-07-03",
      "1993-07-04",
      "1993-07-05",
      "1993-07-06",
      "1993-07-07",
      "1993-07-08",
      "1993-07-09",
      "1993-07-10",
      "1993-07-11",
      "1993-07-12",
      "1993-07-13",
      "1993-07-14",
      "1993-07-15",
      "1993-07-16",
      "1993-07-17",
      "1993-07-18",
      "1993-07-19",
      "1993-07-20",
      "1993-07-21",
      "1993-07-22",
      "1993-07-23",
      "1993-07-24",
      "1993-07-25",
      "1993-07-26",
      "1993-07-27",
      "1993-07-28",
      "1993-07-29",
      "1993-07-30",
      "1993-07-31",
      "1993-08-01",
      "1993-08-02",
      "1993-08-03",
      "1993-08-04",
      "1993-08-05",
      "1993-08-06",
      "1993-08-07",
      "1993-08-08",
      "1993-08-09",
      "1993-08-10",
      "1993-08-11",
      "1993-08-12",
      "1993-08-13",
      "1993-08-14",
      "1993-08-15",
      "1993-08-16",
      "1993-08-17",
      "1993-08-18",
      "1993-08-19",
      "1993-08-20",
      "1993-08-21",
      "1993-08-22",
      "1993-08-23",
      "1993-08-24",
      "1993-08-25",
      "1993-08-26",
      "1993-08-27",
      "1993-08-28",
      "1993-08-29",
      "1993-08-30",
      "1993-08-31",
      "1993-09-01",
      "1993-09-02",
      "1993-09-03",
      "1993-09-04",
      "1993-09-05",
      "1993-09-06",
      "1993-09-07",
      "1993-09-08",
      "1993-09-09",
      "1993-09-10",
      "1993-09-11",
      "1993-09-12",
      "1993-09-13",
      "1993-09-14",
      "1993-09-15",
    ],
    selectedHeader: { value: 2, label: "Date of Birth *\r\n(YYYY-MM-DD)" },
    value_percentage: 100,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  designation: {
    name: "Designation",
    type: "string",
    required: true,
    example: ["Software Engineer", "Manager", "Director"],
    alternativeMatches: ["Job Title", "Role"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Designation is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
      "QA",
    ],
    selectedHeader: { value: 3, label: "Designation *" },
    value_percentage: 100,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  date_of_joining: {
    name: "Date of Joining",
    type: "date",
    required: true,
    example: ["2020-03-15", "2018-07-01", "2015-11-10"],
    alternativeMatches: ["Joining Date", "Start Date"],
    validations: {
      errorMessage: "Date of Joining is required.",
      level: "error",
    },
    rowData: [
      "2015-09-20",
      "2015-09-21",
      "2015-09-22",
      "2015-09-23",
      "2015-09-24",
      "2015-09-25",
      "2015-09-26",
      "2015-09-27",
      "2015-09-28",
      "2015-09-29",
      "2015-09-30",
      "2015-10-01",
      "2015-10-02",
      "2015-10-03",
      "2015-10-04",
      "2015-10-05",
      "2015-10-06",
      "2015-10-07",
      "2015-10-08",
      "2015-10-09",
      "2015-10-10",
      "2015-10-11",
      "2015-10-12",
      "2015-10-13",
      "2015-10-14",
      "2015-10-15",
      "2015-10-16",
      "2015-10-17",
      "2015-10-18",
      "2015-10-19",
      "2015-10-20",
      "2015-10-21",
      "2015-10-22",
      "2015-10-23",
      "2015-10-24",
      "2015-10-25",
      "2015-10-26",
      "2015-10-27",
      "2015-10-28",
      "2015-10-29",
      "2015-10-30",
      "2015-10-31",
      "2015-11-01",
      "2015-11-02",
      "2015-11-03",
      "2015-11-04",
      "2015-11-05",
      "2015-11-06",
      "2015-11-07",
      "2015-11-08",
      "2015-11-09",
      "2015-11-10",
      "2015-11-11",
      "2015-11-12",
      "2015-11-13",
      "2015-11-14",
      "2015-11-15",
      "2015-11-16",
      "2015-11-17",
      "2015-11-18",
      "2015-11-19",
      "2015-11-20",
      "2015-11-21",
      "2015-11-22",
      "2015-11-23",
      "2015-11-24",
      "2015-11-25",
      "2015-11-26",
      "2015-11-27",
      "2015-11-28",
      "2015-11-29",
      "2015-11-30",
      "2015-12-01",
      "2015-12-02",
      "2015-12-03",
      "2015-12-04",
      "2015-12-05",
      "2015-12-06",
      "2015-12-07",
      "2015-12-08",
      "2015-12-09",
      "2015-12-10",
      "2015-12-11",
      "2015-12-12",
      "2015-12-13",
      "2015-12-14",
      "2015-12-15",
      "2015-12-16",
      "2015-12-17",
      "2015-12-18",
      "2015-12-19",
      "2015-12-20",
      "2015-12-21",
      "2015-12-22",
      "2015-12-23",
      "2015-12-24",
      "2015-12-25",
      "2015-12-26",
      "2015-12-27",
      "2015-12-28",
      "2015-12-29",
      "2015-12-30",
      "2015-12-31",
      "2016-01-01",
      "2016-01-02",
      "2016-01-03",
      "2016-01-04",
      "2016-01-05",
      "2016-01-06",
      "2016-01-07",
      "2016-01-08",
      "2016-01-09",
      "2016-01-10",
      "2016-01-11",
      "2016-01-12",
      "2016-01-13",
      "2016-01-14",
      "2016-01-15",
      "2016-01-16",
      "2016-01-17",
      "2016-01-18",
      "2016-01-19",
      "2016-01-20",
      "2016-01-21",
      "2016-01-22",
      "2016-01-23",
      "2016-01-24",
      "2016-01-25",
      "2016-01-26",
      "2016-01-27",
      "2016-01-28",
      "2016-01-29",
      "2016-01-30",
      "2016-01-31",
      "2016-02-01",
      "2016-02-02",
      "2016-02-03",
      "2016-02-04",
      "2016-02-05",
      "2016-02-06",
      "2016-02-07",
      "2016-02-08",
      "2016-02-09",
      "2016-02-10",
      "2016-02-11",
      "2016-02-12",
      "2016-02-13",
      "2016-02-14",
      "2016-02-15",
      "2016-02-16",
      "2016-02-17",
      "2016-02-18",
      "2016-02-19",
      "2016-02-20",
      "2016-02-21",
      "2016-02-22",
      "2016-02-23",
      "2016-02-24",
      "2016-02-25",
      "2016-02-26",
      "2016-02-27",
      "2016-02-28",
      "2016-02-29",
      "2016-03-01",
      "2016-03-02",
      "2016-03-03",
      "2016-03-04",
      "2016-03-05",
      "2016-03-06",
      "2016-03-07",
      "2016-03-08",
      "2016-03-09",
      "2016-03-10",
      "2016-03-11",
      "2016-03-12",
      "2016-03-13",
      "2016-03-14",
      "2016-03-15",
      "2016-03-16",
      "2016-03-17",
      "2016-03-18",
      "2016-03-19",
      "2016-03-20",
      "2016-03-21",
      "2016-03-22",
      "2016-03-23",
      "2016-03-24",
      "2016-03-25",
      "2016-03-26",
      "2016-03-27",
      "2016-03-28",
      "2016-03-29",
      "2016-03-30",
      "2016-03-31",
      "2016-04-01",
      "2016-04-02",
      "2016-04-03",
      "2016-04-04",
      "2016-04-05",
      "2016-04-06",
    ],
    selectedHeader: { value: 4, label: "Date of Joining *\r\n(YYYY-MM-DD)" },
    value_percentage: 100,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  last_working_day: {
    name: "Last Working Day",
    type: "date",
    required: true,
    example: ["2021-12-31", "2022-06-15", "2023-02-28"],
    alternativeMatches: ["Last Day", "Exit Date"],
    validations: {
      errorMessage: "Last Working Day is required.",
      level: "error",
    },
    rowData: [
      "2017-09-20",
      "2017-09-21",
      "2017-09-22",
      "2017-09-23",
      "2017-09-24",
      "2017-09-25",
      "2017-09-26",
      "2017-09-27",
      "2017-09-28",
      "2017-09-29",
      "2017-09-30",
      "2017-10-01",
      "2017-10-02",
      "2017-10-03",
      "2017-10-04",
      "2017-10-05",
      "2017-10-06",
      "2017-10-07",
      "2017-10-08",
      "2017-10-09",
      "2017-10-10",
      "2017-10-11",
      "2017-10-12",
      "2017-10-13",
      "2017-10-14",
      "2017-10-15",
      "2017-10-16",
      "2017-10-17",
      "2017-10-18",
      "2017-10-19",
      "2017-10-20",
      "2017-10-21",
      "2017-10-22",
      "2017-10-23",
      "2017-10-24",
      "2017-10-25",
      "2017-10-26",
      "2017-10-27",
      "2017-10-28",
      "2017-10-29",
      "2017-10-30",
      "2017-10-31",
      "2017-11-01",
      "2017-11-02",
      "2017-11-03",
      "2017-11-04",
      "2017-11-05",
      "2017-11-06",
      "2017-11-07",
      "2017-11-08",
      "2017-11-09",
      "2017-11-10",
      "2017-11-11",
      "2017-11-12",
      "2017-11-13",
      "2017-11-14",
      "2017-11-15",
      "2017-11-16",
      "2017-11-17",
      "2017-11-18",
      "2017-11-19",
      "2017-11-20",
      "2017-11-21",
      "2017-11-22",
      "2017-11-23",
      "2017-11-24",
      "2017-11-25",
      "2017-11-26",
      "2017-11-27",
      "2017-11-28",
      "2017-11-29",
      "2017-11-30",
      "2017-12-01",
      "2017-12-02",
      "2017-12-03",
      "2017-12-04",
      "2017-12-05",
      "2017-12-06",
      "2017-12-07",
      "2017-12-08",
      "2017-12-09",
      "2017-12-10",
      "2017-12-11",
      "2017-12-12",
      "2017-12-13",
      "2017-12-14",
      "2017-12-15",
      "2017-12-16",
      "2017-12-17",
      "2017-12-18",
      "2017-12-19",
      "2017-12-20",
      "2017-12-21",
      "2017-12-22",
      "2017-12-23",
      "2017-12-24",
      "2017-12-25",
      "2017-12-26",
      "2017-12-27",
      "2017-12-28",
      "2017-12-29",
      "2017-12-30",
      "2017-12-31",
      "2018-01-01",
      "2018-01-02",
      "2018-01-03",
      "2018-01-04",
      "2018-01-05",
      "2018-01-06",
      "2018-01-07",
      "2018-01-08",
      "2018-01-09",
      "2018-01-10",
      "2018-01-11",
      "2018-01-12",
      "2018-01-13",
      "2018-01-14",
      "2018-01-15",
      "2018-01-16",
      "2018-01-17",
      "2018-01-18",
      "2018-01-19",
      "2018-01-20",
      "2018-01-21",
      "2018-01-22",
      "2018-01-23",
      "2018-01-24",
      "2018-01-25",
      "2018-01-26",
      "2018-01-27",
      "2018-01-28",
      "2018-01-29",
      "2018-01-30",
      "2018-01-31",
      "2018-02-01",
      "2018-02-02",
      "2018-02-03",
      "2018-02-04",
      "2018-02-05",
      "2018-02-06",
      "2018-02-07",
      "2018-02-08",
      "2018-02-09",
      "2018-02-10",
      "2018-02-11",
      "2018-02-12",
      "2018-02-13",
      "2018-02-14",
      "2018-02-15",
      "2018-02-16",
      "2018-02-17",
      "2018-02-18",
      "2018-02-19",
      "2018-02-20",
      "2018-02-21",
      "2018-02-22",
      "2018-02-23",
      "2018-02-24",
      "2018-02-25",
      "2018-02-26",
      "2018-02-27",
      "2018-02-28",
      "2018-03-01",
      "2018-03-02",
      "2018-03-03",
      "2018-03-04",
      "2018-03-05",
      "2018-03-06",
      "2018-03-07",
      "2018-03-08",
      "2018-03-09",
      "2018-03-10",
      "2018-03-11",
      "2018-03-12",
      "2018-03-13",
      "2018-03-14",
      "2018-03-15",
      "2018-03-16",
      "2018-03-17",
      "2018-03-18",
      "2018-03-19",
      "2018-03-20",
      "2018-03-21",
      "2018-03-22",
      "2018-03-23",
      "2018-03-24",
      "2018-03-25",
      "2018-03-26",
      "2018-03-27",
      "2018-03-28",
      "2018-03-29",
      "2018-03-30",
      "2018-03-31",
      "2018-04-01",
      "2018-04-02",
      "2018-04-03",
      "2018-04-04",
      "2018-04-05",
      "2018-04-06",
      "2018-04-07",
    ],
    selectedHeader: { value: 5, label: "Last Working Day *\r\n(YYYY-MM-DD)" },
    value_percentage: 100,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  phone_number: {
    name: "Phone Number",
    type: "phone",
    example: ["123-456-7890", "9876543210", "555-1234"],
    alternativeMatches: ["Phone", "Contact Number"],
    validations: { errorMessage: "Invalid Phone Number.", level: "warning" },
    rowData: [
      "8099898786",
      "8099898781",
      "4099898788",
      "7099898783",
      "7099898784",
      "9099898785",
      "9099898786",
      "9099898788",
      "7099898783",
      "6099898757",
      "9099898700",
      "8099898700",
      "8099898700",
      "7099898700",
      "7099898700",
      "9099898700",
      "9099898700",
      "9989878800",
      "9099898700",
      "6999898700",
      "9899898787",
      "8999898781",
      "9999898789",
      "7699898783",
      "7999898784",
      "9999898785",
      "9999898789",
      "9799898788",
      "6699898712",
      "6999898732",
      "9199898733",
      "8299898781",
      "9399898789",
      "7599898783",
      "7799898784",
      "9399898785",
      "8399898786",
      "8399898787",
      "9099898783",
      "6299898757",
      "6299898757",
      "6299898758",
      "6299898759",
      "6299898760",
      "6299898761",
      "6299898762",
      "6299898763",
      "6299898764",
      "6299898765",
      "6299898766",
      "6299898767",
      "6299898768",
      "6299898769",
      "6299898770",
      "6299898771",
      "6299898772",
      "6299898773",
      "6299898774",
      "6299898775",
      "6299898776",
      "6299898777",
      "6299898778",
      "6299898779",
      "6299898780",
      "6299898781",
      "6299898782",
      "6299898783",
      "6299898784",
      "6299898785",
      "6299898786",
      "6299898787",
      "6299898788",
      "6299898789",
      "6299898790",
      "6299898791",
      "6299898792",
      "6299898793",
      "6299898794",
      "6299898795",
      "6299898796",
      "6299898797",
      "6299898798",
      "6299898799",
      "6299898800",
      "6299898801",
      "6299898802",
      "6299898803",
      "6299898804",
      "6299898805",
      "6299898806",
      "6299898807",
      "6299898808",
      "6299898809",
      "6299898810",
      "6299898811",
      "6299898812",
      "6299898813",
      "6299898814",
      "6299898815",
      "6299898816",
      "6299898817",
      "6299898818",
      "6299898819",
      "6299898820",
      "6299898821",
      "6299898822",
      "6299898823",
      "6299898824",
      "6299898825",
      "6299898826",
      "6299898827",
      "6299898828",
      "6299898829",
      "6299898830",
      "6299898831",
      "6299898832",
      "6299898833",
      "6299898834",
      "6299898835",
      "6299898836",
      "6299898837",
      "6299898838",
      "6299898839",
      "6299898840",
      "6299898841",
      "6299898842",
      "6299898843",
      "6299898844",
      "6299898845",
      "6299898846",
      "6299898847",
      "6299898848",
      "6299898849",
      "6299898850",
      "6299898851",
      "6299898852",
      "6299898853",
      "6299898854",
      "6299898855",
      "6299898856",
      "6299898857",
      "6299898858",
      "6299898859",
      "6299898860",
      "6299898861",
      "6299898862",
      "6299898863",
      "6299898864",
      "6299898865",
      "6299898866",
      "6299898867",
      "6299898868",
      "6299898869",
      "6299898870",
      "6299898871",
      "6299898872",
      "6299898873",
      "6299898874",
      "6299898875",
      "6299898876",
      "6299898877",
      "6299898878",
      "6299898879",
      "6299898880",
      "6299898881",
      "6299898882",
      "6299898883",
      "6299898884",
      "6299898885",
      "6299898886",
      "6299898887",
      "6299898888",
      "6299898889",
      "6299898890",
      "6299898891",
      "6299898892",
      "6299898893",
      "6299898894",
      "6299898895",
      "6299898896",
      "6299898897",
      "6299898898",
      "6299898899",
      "6299898900",
      "6299898901",
      "6299898902",
      "6299898903",
      "6299898904",
      "6299898905",
      "6299898906",
      "6299898907",
      "6299898908",
      "6299898909",
      "6299898910",
      "6299898911",
      "6299898912",
      "6299898913",
      "6299898914",
      "6299898915",
      "8987567890",
    ],
    selectedHeader: { value: 6, label: "Phone Number" },
    value_percentage: 100,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  personal_email: {
    name: "Personal Email",
    type: "email",
    example: [
      "john.doe@example.com",
      "jane.smith@gmail.com",
      "bob.johnson@company.com",
    ],
    alternativeMatches: ["Personal Email", "Email"],
    validations: {
      regex: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
      errorMessage: "Invalid Personal Email format.",
      level: "error",
    },
    rowData: [
      "shilpa.joshi@unisys.co.in",
      "Sou@yomail.in",
      "abc@iimk.ac.in",
      "sheel@gmail.com",
      "dev@yahoo.com",
      "vagish123@oulook.com",
      "chees@Hotmail.co.in",
      "piyush@springrole.in",
      "Ajith@minstree.com",
      "Ajay@add.com",
      "shilpa.joshi16@unisys.co.in",
      "Sou26@yomail.in",
      "abc3@iimk.ac.in",
      "sheel4@gmail.com",
      "dev5@yahoo.com",
      "vagish1285@oulook.com",
      "chee4s@Hotmail.co.in",
      "piyush6@springrole.in",
      "Ajith6@minstree.com",
      "Ajay6@add.com",
      "shilpa.joshi198@unisys.co.in",
      "Sou27@yomail.in",
      "abc4@iimk.ac.in",
      "sheel7@gmail.com",
      "dev6@yahoo.com",
      "vagish1235@oulook.com",
      "chees26@Hotmail.co.in",
      "piyush7@springrole.in",
      "Ajith8@minstree.com",
      "Ajay7@add.com",
      "shilpa.joshi17@unisys.co.in",
      "Sou27@yomail.in",
      "abc37@iimk.ac.in",
      "sheel47@gmail.com",
      "dev57@yahoo.com",
      "vagish12357@oulook.com",
      "chee4s7@Hotmail.co.in",
      "piyush67@springrole.in",
      "Ajith87@minstree.com",
      "Ajay77@add.com",
      "abc1@yopmail.com",
      "abc3@yopmail.com",
      "add9@yopmail.com",
      "a10@yopmail.com",
      "a11@yopmail.com",
      "a12@yopmail.com",
      "a13@yopmail.com",
      "a14@yopmail.com",
      "a15@yopmail.com",
      "a16@yopmail.com",
      "a17@yopmail.com",
      "a18@yopmail.com",
      "a19@yopmail.com",
      "a20@yopmail.com",
      "a21@yopmail.com",
      "a22@yopmail.com",
      "a23@yopmail.com",
      "a24@yopmail.com",
      "a25@yopmail.com",
      "a26@yopmail.com",
      "a27@yopmail.com",
      "a28@yopmail.com",
      "a29@yopmail.com",
      "a30@yopmail.com",
      "a31@yopmail.com",
      "a32@yopmail.com",
      "a33@yopmail.com",
      "a34@yopmail.com",
      "a325@yopmail.com",
      "a320@yopmail.com",
      "a362@yopmail.com",
      "a326@yopmail.com",
      "a332@yopmail.com",
      "a323@yopmail.com",
      "a36@yopmail.com",
      "a37@yopmail.com",
      "a38@yopmail.com",
      "a39@yopmail.com",
      "a40@yopmail.com",
      "a41@yopmail.com",
      "a42@yopmail.com",
      "a43@yopmail.com",
      "a44@yopmail.com",
      "a45@yopmail.com",
      "a46@yopmail.com",
      "a47@yopmail.com",
      "a48@yopmail.com",
      "a49@yopmail.com",
      "a50@yopmail.com",
      "a51@yopmail.com",
      "a52@yopmail.com",
      "a532@yopmail.com",
      "a632@yopmail.com",
      "a732@yopmail.com",
      "a832@yopmail.com",
      "a932@yopmail.com",
      "a750@yopmail.com",
      "a751@yopmail.com",
      "a752@yopmail.com",
      "a753@yopmail.com",
      "a754@yopmail.com",
      "a55@yopmail.com",
      "a56@yopmail.com",
      "a57@yopmail.com",
      "a58@yopmail.com",
      "a59@yopmail.com",
      "a60@yopmail.com",
      "a61@yopmail.com",
      "a62@yopmail.com",
      "a63@yopmail.com",
      "a64@yopmail.com",
      "a65@yopmail.com",
      "a66@yopmail.com",
      "a67@yopmail.com",
      "a68@yopmail.com",
      "a69@yopmail.com",
      "a70@yopmail.com",
      "a71@yopmail.com",
      "a72@yopmail.com",
      "a73@yopmail.com",
      "a74@yopmail.com",
      "a75@yopmail.com",
      "a76@yopmail.com",
      "a77@yopmail.com",
      "a78@yopmail.com",
      "a79@yopmail.com",
      "a80@yopmail.com",
      "a81@yopmail.com",
      "a82@yopmail.com",
      "a83@yopmail.com",
      "a84@yopmail.com",
      "a85@yopmail.com",
      "a86@yopmail.com",
      "a87@yopmail.com",
      "a88@yopmail.com",
      "a89@yopmail.com",
      "a90@yopmail.com",
      "a91@yopmail.com",
      "a92@yopmail.com",
      "a93@yopmail.com",
      "a94@yopmail.com",
      "a95@yopmail.com",
      "a96@yopmail.com",
      "a97@yopmail.com",
      "a98@yopmail.com",
      "a99@yopmail.com",
      "a100@yopmail.com",
      "a101@yopmail.com",
      "a102@yopmail.com",
      "a103@yopmail.com",
      "a104@yopmail.com",
      "a105@yopmail.com",
      "a106@yopmail.com",
      "a107@yopmail.com",
      "a108@yopmail.com",
      "a109@yopmail.com",
      "a110@yopmail.com",
      "a111@yopmail.com",
      "a112@yopmail.com",
      "a113@yopmail.com",
      "a114@yopmail.com",
      "a115@yopmail.com",
      "a116@yopmail.com",
      "a117@yopmail.com",
      "a118@yopmail.com",
      "a119@yopmail.com",
      "a120@yopmail.com",
      "a121@yopmail.com",
      "a122@yopmail.com",
      "a123@yopmail.com",
      "a124@yopmail.com",
      "a125@yopmail.com",
      "a126@yopmail.com",
      "a127@yopmail.com",
      "a128@yopmail.com",
      "a129@yopmail.com",
      "a130@yopmail.com",
      "a131@yopmail.com",
      "a132@yopmail.com",
      "a133@yopmail.com",
      "a134@yopmail.com",
      "a135@yopmail.com",
      "a136@yopmail.com",
      "a137@yopmail.com",
      "a138@yopmail.com",
      "a139@yopmail.com",
      "a140@yopmail.com",
      "a141@yopmail.com",
      "a142@yopmail.com",
      "a143@yopmail.com",
      "a144@yopmail.com",
      "a145@yopmail.com",
      "a146@yopmail.com",
      "a147@yopmail.com",
      "a148@yopmail.com",
      "a149@yopmail.com",
      "a150@yopmail.com",
      "a151@yopmail.com",
      "a152@yopmail.com",
      null,
    ],
    selectedHeader: { value: 7, label: "Personal Email" },
    value_percentage: 99.5,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  business_email: {
    name: "Business Email",
    type: "email",
    example: [
      "john.doe@company.com",
      "jane.smith@business.com",
      "bob.johnson@corporation.com",
    ],
    alternativeMatches: ["Business Email", "Work Email"],
    validations: {
      regex: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
      errorMessage: "Invalid Business Email format.",
      level: "error",
    },
    rowData: [
      "shilpa@yop",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 8, label: "Business Email" },
    value_percentage: 0.5,
    validation_percentage: 99.5,
    date_format: "YYYY-MM-DD",
  },
  employment_type: {
    name: "Employment Type",
    type: "string",
    example: ["Full-Time", "Part-Time", "Contract"],
    alternativeMatches: ["Employment Category", "Work Type"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Employment Type is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 9, label: "Employment \r\nType" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  exit_type: {
    name: "Exit Type",
    type: "string",
    example: ["Resignation", "Termination", "Retirement"],
    alternativeMatches: ["Exit Category", "Leaving Type"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Exit Type is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 10, label: "Exit Type" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  exit_reason: {
    name: "Exit Reason",
    type: "string",
    example: ["New Opportunity", "Personal Reasons", "Job Dissatisfaction"],
    alternativeMatches: ["Reason for Leaving", "Exit Explanation"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Exit Reason is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 11, label: "Exit Reason" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  eligible_for_rehire: {
    name: "Eligible for Rehire",
    type: "boolean",
    example: ["yes", "might be", "no"],
    alternativeMatches: ["Rehire Eligibility", "Rehirable"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage: "Eligible for Rehire should be either true or false.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 12, label: "Eligible for Rehire" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  rm_name: {
    name: "Reporting Manager's Name",
    type: "string",
    example: ["John Manager", "Jane Supervisor", "Bob Director"],
    alternativeMatches: ["Manager Name", "Supervisor"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Reporting Manager's Name is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 13, label: "Reporting Manager's \r\nName" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  rm_designation: {
    name: "Reporting Manager's Designation",
    type: "string",
    example: ["Team Lead", "Manager", "Director"],
    alternativeMatches: ["Manager Designation", "Supervisor Title"],
    validations: {
      regex: "^[A-Za-z\\s]+$",
      errorMessage:
        "Reporting Manager's Designation is required and should contain only letters and spaces.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 14, label: "Reporting Manager's \r\nDesignation" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  rm_email: {
    name: "Reporting Manager's Email",
    type: "email",
    example: [
      "john.manager@example.com",
      "jane.supervisor@company.com",
      "bob.director@corporation.com",
    ],
    alternativeMatches: ["Manager Email", "Supervisor Email"],
    validations: {
      regex: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
      errorMessage: "Invalid Reporting Manager's Email format.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 15, label: "Reporting Manager's \r\nEmail" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
  rm_phone: {
    name: "Reporting Manager's Phone",
    type: "string",
    example: ["123-456-7890", "9876543210", "555-1234"],
    alternativeMatches: ["Manager Phone", "Supervisor Contact"],
    validations: {
      regex: "^\\d{3}-\\d{3}-\\d{4}$",
      errorMessage:
        "Reporting Manager's Phone should be in the format XXX-XXX-XXXX.",
      level: "warning",
    },
    rowData: [
      "4909878909",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 16, label: "Reporting Manager's \r\nPhone" },
    value_percentage: 0.5,
    validation_percentage: 99.5,
    date_format: "YYYY-MM-DD",
  },
  ctc: {
    name: "CTC",
    type: "string",
    example: ["80000", "100000", "120000"],
    alternativeMatches: ["Compensation", "Salary"],
    validations: {
      regex: "^\\d+$",
      errorMessage: "CTC should be a valid number.",
      level: "error",
    },
    rowData: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    selectedHeader: { value: 17, label: "CTC" },
    value_percentage: 0,
    validation_percentage: 100,
    date_format: "YYYY-MM-DD",
  },
};

export default sample;
