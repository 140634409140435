import React from 'react';
import styles from './UpsellLaptopBanner.module.scss';
import upsellBanner from '../../../../images/upsell-banner.svg';
import upsellBannerDiscount from '../../../../images/upsell-banner-discount.svg';

import AppConstants from '../../../../core-components/AppConstants';
import { UPSELL_DISCOUNT_BANNER_DATE } from '../../../../utils/commonConstant';

const UpsellLaptopBanner = (props) => {
  const upsellBannerWithOutDiscountImgDate = new Date(UPSELL_DISCOUNT_BANNER_DATE);
  const currentDate = new Date();

  return (
    <div className={props?.isCandidateDetailsPage ? styles.bannerWrapper : styles.marginTop}>
      {currentDate <= upsellBannerWithOutDiscountImgDate ? (
        <img
          src={upsellBannerDiscount}
          alt='Upsell Laptop Banner'
          className={props?.isCandidateDetailsPage ? styles.bannerImage : null}
          onClick={() => window.open(`${AppConstants.baseURL}buy-rent-laptop`, '_blank')}
        />
      ) : (
        <img
          src={upsellBanner}
          alt='Upsell Laptop Banner'
          className={props?.isCandidateDetailsPage ? styles.bannerImage : null}
          onClick={() => window.open(`${AppConstants.baseURL}buy-rent-laptop`, '_blank')}
        />
      )}
    </div>
  );
};
export default UpsellLaptopBanner;
