import React, { useRef } from 'react';
import AlumniUrlComponent from '../frontend-common/AlumniVerification/AlumniUrlComponent';
import styles from './AlumniVerification.module.scss';
import AlumniVerificationTabs from '../frontend-common/AlumniVerificationTabs/AlumniVerificationTabs';
import AlumniChats from '../frontend-common/AlumniVerification/AlumniChats';
import HRMSConnectBar from './HRMSConnectBar';
import Overview from '../frontend-common/AlumniVerification/Overview';
import { isEmpty } from 'lodash';
import AutoPilot from './AutoPilot/AutoPilot';
import HRMSorUpload from './HRMSorUpload/HRMSorUpload';

export default function AlumniVerification(props) {
  const { paymentDetails } = props;
  const hrmsConnectRef = useRef(null);

  return (
    <div className={styles.alumniWrapperWrapper}>
      <div className={styles.subHeading}>
        <h4 className={styles.header}>{'Alumni Verification'}</h4>
      </div>
      {/* TO-DO: Show both below components based on the API response */}
      {!isEmpty(paymentDetails) && !paymentDetails.loading ? (
        !paymentDetails.alumniVerificationEnabled ? (
          <>
            <AutoPilot />
            <HRMSorUpload {...props} />
          </>
        ) : (
          <>
            <AlumniUrlComponent />
            <HRMSConnectBar forwardRef={hrmsConnectRef} {...props} />
            <Overview />
            <AlumniChats />
            <AlumniVerificationTabs forwardedRef={hrmsConnectRef} {...props} />
          </>
        )
      ) : null}
    </div>
  );
}
