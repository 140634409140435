import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Overlay, OverlayTrigger, Table } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { TRANSACTION_TYPE } from '../../../../../utils/commonConstant';
import {
  convertToIndianNumeration,
  errToastMessage,
  getErrorMessage,
  toCamelCase
} from '../../../../../utils/utilities';
import styles from './PendingOrdersTable.module.scss';
import { MoreHorizOutlined } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import ConfirmationModal from './../CancelModal/CancelModal';
import Pagination from '../../../../../core-components/Pagination';
import Image from '../../../../../core-components/Image';
import _, { isEmpty } from 'lodash';
import { useHistory, withRouter } from 'react-router-dom';
import { cancelRequestPayment } from '../../../../../api/company';
import { toast } from 'react-toastify';
import AppConstants from '../../../../../core-components/AppConstants';
import EmptyTableBody from './EmptyTableBody';
const PendingOrdersTable = (props) => {
  const { getPendingOrders, pendingOrders, invalidatePendingOrders, history } = props;
  const pageSizes = [10, 25, 50, 100];
  const target = useRef(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [isData, setIsData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  useEffect(() => {
    getPendingOrders();
    return () => {
      invalidatePendingOrders();
    };
  }, []);
  useEffect(() => {
    if (pendingOrders && !isEmpty(pendingOrders)) {
      setLoading(pendingOrders.loading);
      if (!pendingOrders.loading) {
        setCount(pendingOrders.count);
        setData(pendingOrders.paymentOrders);
        if (pendingOrders.count === 0) {
          setIsData(false);
        }
      }
    }
  }, [pendingOrders]);

  const pageSizeOptions = [10, 25, 50, 100];
  let index = 0;
  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);
  const paginate = (limitValue, offsetValue) => {
    /** Usage logs api call */
    getPendingOrders(limitValue, offsetValue);
  };

  const cancelOrder = (orderId) => {
    setOrderId(orderId);
    setOpenConfirm(true);
  };
  const redirectToPayment = (token) => {
    history.push(`purchase/payment-request?token=${token}`);
  };
  const handleCancelOrder = () => {
    const allOrders = [...data];
    const payload = {
      order_id: orderId
    };
    cancelRequestPayment(payload)
      .then((res) => {
        toast.success('Order cancelled');
        const canceledOrderIndex = _.findIndex(allOrders, (d) => d.orderId === orderId);
        allOrders[canceledOrderIndex].status = 'CANCELLED';
        setData(allOrders);
        getPendingOrders(pageSize, pageNumber);
      })
      .catch((err) => {
        errToastMessage(err);
      });
    setOpenConfirm(false);
  };
  const getRequestor = (data) => {
    return data.requestedBy?.isRequestedBySa
      ? `${data?.requestedBy?.userName} (via SV)`
      : data?.requestedBy?.userName;
  };
  const redirectTo = (redirectLink) => {
    window.open(`/orderDetails?order_id=${redirectLink}`, '_blank');
  };
  return (
    <div className={styles.usageLogsWrapper}>
      <div className={styles.usageLogsCard}>
        <div className={styles.tableCard}>
          <div className={props.isSA ? styles.knowMoreSA : styles.headerContent}>
            Orders created by your team using "Request Payment".
            <a
              href='https://support.springworks.in/portal/en/kb/articles/what-are-pending-orders'
              target='_blank'
            >
              Know more
            </a>
          </div>
          <Table className={styles.usageLogsTable}>
            <thead>
              <tr>
                <th className={styles.alignLeft}>Date</th>
                <th className={styles.alignLeft}>TXN Type</th>
                <th className={styles.alignLeft}>User</th>
                <th className={styles.alignRight}>Amount</th>
                <th className={styles.alignCenter}>Actions</th>
              </tr>
            </thead>
            {isData ? (
              <tbody>
                {loading
                  ? Array.apply(null, { length: pageSize })
                      .map(Number.call, Number)
                      .map((loadData, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <ContentLoader height={20} width={20}>
                                <rect x='0' y='0' width='20' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignRight}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignCenter}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                          </tr>
                        );
                      })
                  : data &&
                    data.map((rowData, i) => {
                      return (
                        <tr
                          key={i}
                          className={styles.pointerTypeCursor}
                          onClick={() => redirectTo(rowData.orderId)}
                        >
                          <td className={styles.alignLeft}>
                            {moment(rowData?.requestedDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                          </td>
                          <td className={styles.alignLeft}>
                            <div className={styles.txnContainer}>
                              <span className={styles.txnLink}>
                                {TRANSACTION_TYPE[rowData?.transactionType]}
                              </span>
                              <div className={styles.statusContainer}>
                                <span
                                  className={`${styles.txnPill} ${
                                    rowData?.status === 'PENDING'
                                      ? styles.pendingTxn
                                      : styles.otherTxn
                                  }`}
                                >
                                  {rowData.status}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            className={`${styles.mainField} ${
                              rowData?.requestedBy?.deletedAt ? styles.deletedUser : ''
                            }`}
                          >
                            {getRequestor(rowData)}
                          </td>
                          <td className={styles.alignRight}>
                            <span className={styles.amount}>
                              &#8377;
                              {convertToIndianNumeration(rowData.amount, 2)}
                            </span>
                          </td>
                          <td className={styles.alignCenter} onClick={(e) => e.stopPropagation()}>
                            {rowData.status === 'PENDING' ? (
                              <OverlayTrigger
                                trigger={'click'}
                                placement={'bottom'}
                                rootClose
                                overlay={
                                  <div className={styles.popOverMenu}>
                                    <div
                                      className={`${styles.greenLink} ${styles.cursorPointer}`}
                                      onClick={() => redirectToPayment(rowData?.orderUniqueToken)}
                                    >
                                      Pay Now
                                    </div>
                                    <div
                                      className={`${styles.redLink} ${styles.cursorPointer}`}
                                      onClick={() => cancelOrder(rowData?.orderId)}
                                    >
                                      Cancel Order
                                    </div>
                                  </div>
                                }
                              >
                                <MoreHorizOutlined ref={target} className={styles.cursorPointer} />
                              </OverlayTrigger>
                            ) : (
                              <a
                                href={`${AppConstants.baseURL}proforma-invoice?invoiceId=${rowData.proformaNumber}`}
                                target='_blank'
                              >
                                [VIEW]
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            ) : (
              <EmptyTableBody />
            )}
          </Table>
          {isData ? (
            <Form.Row className={styles.paginationWrapper}>
              <Col className={styles.showPagination}>
                Show{' '}
                <select
                  className={styles.selectPagination}
                  value={pageSize}
                  onChange={(event) => {
                    setPageNumber(0);
                    setPageSize(parseInt(event.target.value));
                    paginate(event.target.value, 0);
                  }}
                >
                  {pageSizeOptions}
                </select>{' '}
                items of {count}
              </Col>
              <Col>
                <Pagination
                  forcePage={pageNumber}
                  onPageChange={({ selected }) => {
                    setPageNumber(selected);

                    paginate(pageSize, selected);
                  }}
                  pageCount={Math.ceil(count / pageSize)}
                />
              </Col>
              <Col />
            </Form.Row>
          ) : (
            <div />
          )}
          {openConfirm ? (
            <ConfirmationModal
              show={openConfirm}
              onClose={() => {
                setOpenConfirm(false);
              }}
              heading={'Cancel Order?'}
              subHeading={'Are you sure you want to cancel the order?'}
              onSubmit={() => {
                handleCancelOrder();
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PendingOrdersTable);
