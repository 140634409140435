import React from 'react';
import styles from './welcome.module.scss';
import Button from 'react-bootstrap/Button';
import { GrFormClose } from 'react-icons/gr';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import Image from '../../../core-components/Image/Image';
const hrmsStep = [
  {
    text: `Connect your HRMS, or Upload data`,
    image: 'csv-data2.svg',
    height: '118px',
    textWidth: '172px',
    padding: '12px 15px 0px 0px'
  },
  {
    text: 'Automated and instant response to BGV requests',
    image: 'response-bgv.svg',
    height: '105px',
    textWidth: '226px',
    padding: '12px 0px 0px 0px'
  },
  {
    text: 'Earn SpringVerify credits after every request served',
    image: 'amount-wallet.svg',
    height: '105px',
    textWidth: '185px',
    padding: '12px 0px 0px 0px'
  }
];

const WelcomeModal = ({ onHide, userId, history }) => {
  const handleRemindLater = () => {
    const exEmpAutoPilotRemainder = Cookies.get('hideXEmpRemainderTill' + userId);
    if (!exEmpAutoPilotRemainder) {
      const expirationDate = new Date();
      expirationDate.setHours(168, 59, 59, 999);
      Cookies.set('hideXEmpRemainderTill' + userId, expirationDate.toDateString(), {
        expires: expirationDate
      });
    }
    onHide();
  };

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles.modalContainer}>
        <div className={styles.close} onClick={onHide}>
          <GrFormClose />
        </div>
        <div className={styles.header}>
          <div className={styles.title}>Put ex-employee verification on auto-pilot</div>
          <div className={styles.timex}>Takes less than 5 mins</div>
        </div>

        <div className={styles['sub-heading']}>
          <div className={styles['sub-heading-item']}>
            <Image name='sparkle.svg' />
            Free upto 100% of HR time in confirming background check requests.
          </div>
          <div className={styles['sub-heading-item']}>
            <Image name='sparkle.svg' />
            Go from cost-center to profit-center in just minutes! Earn{' '}
            <span className={styles.dolar}>₹₹₹</span> for every request
          </div>
        </div>

        <div className={styles['stepContainer']}>
          {hrmsStep.map((data, index) => (
            <>
              <div className={styles.stepBox}>
                <span
                  className={styles.stepText}
                  style={{ width: data.textWidth, paddingLeft: index === 0 ? '8px' : '0px' }}
                >
                  {data.text}
                </span>
                <Image style={{ padding: data.padding }} height={data.height} name={data.image} />
              </div>
              {[0, 1].includes(index) && (
                <div className={styles.lineContainer}>
                  <Image className={styles.text} height='100%' name='arrow-left.svg' />
                </div>
              )}
            </>
          ))}
        </div>

        <div className={styles.buttonGroup}>
          <Button className={styles.remind} onClick={handleRemindLater}>
            Remind later
          </Button>
          <Button
            className={styles.setup}
            onClick={() => {
              history?.push('/alumni-verification');
            }}
          >
            Setup
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WelcomeModal);
