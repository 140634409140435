import React, { useEffect, useState } from 'react';
import styles from './UtilsComponent.module.scss';
import cn from 'classnames';
import { Card, Row, Col, Button } from 'react-bootstrap';
import container from './Stepper.container';
import RazorpayButton from '../../core-components/Razorpay';
import {
  buyOrder,
  finalizeRazorpayPayment,
  purchaseCreditsFailure
} from '../../../../../api/company';
import Confirm from '../../core-components/ConfirmationModalTds/Confirm';
import Image from '../../core-components/Image';
import { getPackageAmountTotal } from './UtilitiesFunction';
import { loggly_payment_type } from '../../core-components/Utilities/CommonConstants';
import localStorage from '../../../../../api/localStorage';
import RequestPayment from '../RequestPayment';

const StepNavigation = (props) => {
  const { values, handleBuy, paymentPayload, isSA } = props;
  const [disabled, setDisabled] = useState(false);
  const [addedBalance, setAddedBalance] = useState('');
  const [showTDSModal, setShowTdsModal] = useState(false);
  const [rpModalState, setrpModalState] = useState(false);

  const {
    currentStep,
    paymentObj,
    setShowLoader,
    onPaymentSuccess,
    onPaymentFailure,
    setPaymentStatus,
    setOrderId,
    setTransactionId
  } = props;
  useEffect(() => {
    // When selected radio button is Custom and disabling btn either when no value is provided and entered value is less than total arrear amount
    setDisabled(
      props.values.amount == 'custom' &&
        (!props.values.customAmount ||
          props.values.customAmount <
            props?.walletDetail?.arrearsTotalAmount -
              (props.walletDetail.currentBalance > 0 ? props.walletDetail.currentBalance : 0)) // Taking zero because when -ve currentBalance is there that -ve amount is already included in arrearsTotalAmount
    );
  }, [props.values]);

  const noItemSelected = () => {
    // Function to check whether amount in wallet and any package tiers is selected or not
    return props.values.amount == 0 && getPackageAmountTotal(props?.selectedTiers) == 0;
  };
  const finalizeRazorpayFailure = (payload) => {
    finalizeRazorpayPayment(payload).catch((error) => {
      onPaymentFailure();
    });
  };

  const handleRazorPayFlow = () => {
    setShowTdsModal((prev) => !prev);
  };
  const handleRequestPaymentModal = (success, piNumber, orderID) => {
    if (success) {
      // Calling this function because we do not have to show the Incomplete purchase modal on successful request
      props.paymentRequestSend(piNumber, orderID);
    } else {
      setrpModalState(false);
    }
  };
  const getRazorPayObject = () => {
    return {
      setShowLoader: setShowLoader,
      createOrder: () => buyOrder(paymentObj),
      createOrderPayload: paymentObj,
      buttonText: 'PAY NOW',
      onSuccess: finalizeRazorpayPayment,
      onFailure: finalizeRazorpayFailure,
      setPaymentStatus: setPaymentStatus,
      setOrderId: setOrderId,
      setTransactionId: setTransactionId,
      setAddedBalance: setAddedBalance,
      paymentDetails: props?.paymentDetails,
      onPaymentSuccess: onPaymentSuccess,
      type: loggly_payment_type.ORDER,
      buttonIcon: 'lock_guard_white.svg',
      disable: props.values.amount == 0 && getPackageAmountTotal(props?.selectedTiers) == 0
    };
  };
  return (
    <div className={styles.stepperRow}>
      <div className={styles.stepperLeft}>
        <div className={styles.stepWrapper}>
          {props.labelArray.map((item, index) => (
            <div
              className={cn(
                styles.stepBlock,
                props.currentStep > index + 1 ? styles.stepBlockGreen : styles.stepBlockGrey,
                props.currentStep === index + 1 ? styles.selected : ''
              )}
            >
              <div
                className={styles.circleWrapper}
                onClick={() => props.updateStep(props.index + 1)}
              >
                <div
                  className={cn(
                    styles.circle,
                    props.currentStep > index + 1 ? styles.circleCovered : ''
                  )}
                >
                  {index + 1}
                </div>
              </div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.stepperRight}>
        {/* Previous button  */}
        <Button
          className={cn(styles.cancelBtn, props.currentStep == 1 ? styles.firstStep : '')}
          onClick={() => {
            props.updateStep(props.currentStep - 1);
          }}
        >
          Previous
        </Button>
        {/* Request Payment button showing only for last final step */}
        {currentStep === 2 ? (
          <Button
            disabled={
              // Request Payment button will be disabled if total selected amount is less than current wallet balance or no item selected
              (props.values.amount + getPackageAmountTotal(props.selectedTiers) <=
                props.walletDetail.currentBalance &&
                values.useCurrentWalletBalance) ||
              noItemSelected()
            }
            className={styles.requestPaymentBtn}
            onClick={() => setrpModalState(true)}
          >
            <span>Request Payment</span>
          </Button>
        ) : null}
        {/* PAY NOW button showing only for CA side and for last final step  */}
        {/* Condition to show Buy button- When user can purchase packages using current wallet balance only. Because user have nothing to pay.*/}
        {/* If TDS selected is None we can start Razorpay flow directly else we have to show TDS modal*/}
        {currentStep === 2 && !isSA ? (
          getPackageAmountTotal(props.selectedTiers) <= props.walletDetail.currentBalance &&
          values.useCurrentWalletBalance ? (
            <Button disabled={noItemSelected()} className={styles.payBtn} onClick={handleBuy}>
              <span>Buy Now</span>
            </Button>
          ) : props.values.tdsPercentage === 'NONE' ? (
            <RazorpayButton {...getRazorPayObject()} />
          ) : (
            <Button
              disabled={noItemSelected()}
              className={styles.payBtn}
              onClick={handleRazorPayFlow}
            >
              <Image isSA={isSA} className='mr-1' name='lock_guard_white.svg' />
              <span>PAY NOW</span>
            </Button>
          )
        ) : null}
        {/* Next button not showing for last step */}
        {currentStep != 2 ? (
          <Button
            className={disabled ? styles.disabledBtn : styles.activeBtn}
            disabled={disabled}
            onClick={() => {
              props.updateStep(props.currentStep + 1);
            }}
          >
            Next
          </Button>
        ) : null}

        {props.values.TDS !== 'NONE' && currentStep === 2 && (
          <Confirm
            show={showTDSModal}
            onClose={handleRazorPayFlow}
            setTransactionId={setTransactionId}
            paymentsObj={getRazorPayObject()}
          />
        )}
        {rpModalState && (
          <RequestPayment
            show={rpModalState}
            onClose={handleRequestPaymentModal}
            paymentPayload={paymentObj}
            isSA={props.isSA}
          />
        )}
      </div>
    </div>
  );
};

export default container(StepNavigation);
