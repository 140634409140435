import React, { useState, useEffect } from 'react';
import { Modal, Table, Form, Col } from 'react-bootstrap';

import styles from './ViewRequestsModal.module.scss';
import moment from 'moment';
import Image from '../../../core-components/Image';
import Pagination from '../../../core-components/Pagination';

const ViewRequestsModal = (props) => {
  const companyId = new URLSearchParams(document.location.search).get('id') || null;
  const { show, onHide, rowData, count, fetchRequestList, AppConstants } = props;
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;
  useEffect(() => {
    fetchRequestList({ limit: limit, offset: offset });
  }, [offset, limit]);

  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };

  return (
    <div>
      <Modal show={show} size='lg' centered onHide={onHide}>
        <Modal.Header
          className={styles.modalHeader}
          closeButton
        >{`${count} Requests`}</Modal.Header>
        <Modal.Body>
          <Table className={styles.recentTable}>
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Timestamp</th>

                <th>Requesting entity</th>
                <th>Consent</th>
                <th>Amount</th>
              </tr>
            </thead>

            <tbody>
              {rowData.map((row) => {
                return (
                  <tr>
                    <td>{row.id}</td>
                    <td>{moment(row?.createdAt).utc().format('DD-MM-YYYY')}</td>
                    <td>{row?.requestingEntity}</td>
                    <td>
                      <a
                        href={`${AppConstants.baseURL}verification-request?partner_request_log_id=${
                          row.id
                        }${companyId ? '&company_id=' + companyId : ''}`}
                        target='_blank'
                      >
                        <Image name='reportIcon.svg' />
                      </a>
                    </td>
                    <td>
                      <div>
                        <span className={styles.rupeeFont}>₹</span>
                        {row?.clientShareAmount}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Form.Row className={styles.paginationWrapper}>
            <Col className={styles.showPagination}>
              Show{' '}
              <select
                className={styles.selectPagination}
                value={limit}
                onChange={(event) => {
                  setLimit(parseInt(event.target.value));
                  setPageSize(parseInt(event.target.value));
                  paginate(0, 0);
                }}
              >
                {pageSizeOptions}
              </select>{' '}
              items of {count}
            </Col>
            <Col>
              <Pagination
                forcePage={pageNumber}
                onPageChange={({ selected }) => {
                  paginate(selected, selected);
                }}
                pageCount={Math.ceil(count / pageSize)}
              />
            </Col>
            <Col />
          </Form.Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewRequestsModal;
