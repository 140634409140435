import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Confirm from '../ConfirmationModal/Confirm';

// This will basically show CUSTOM Incomplete Items Modal when you change the route (not the browser's default one).

const IncompleteItemModal = ({ when, navigate, shouldBlockNavigation, heading, subHeading }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    //Prompt from 'react-router-dom' do not handle the condition when we refresh the page. This useEffect will show the browser the default one.
    if (when) {
      const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = '';
        return '';
      };

      window.addEventListener('beforeunload', unloadCallback, { capture: true });
      return () => window.removeEventListener('beforeunload', unloadCallback, { capture: true });
    }
  }, [when]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Confirm
        show={modalVisible}
        onConfirm={handleConfirmNavigationClick}
        onClose={closeModal}
        heading={heading}
        subHeading={subHeading}
        sBtnText={'Proceed'}
        enableBtn={true}
        onSubmit={handleConfirmNavigationClick}
      />
    </>
  );
};
export default IncompleteItemModal;
