import React, { useState, useEffect } from 'react';

import { has } from 'lodash';
import { Row, Card, Col, Button } from 'react-bootstrap';

import Header from '../../../Header';
import Footer from '../../../Footer';
import AvailableBalance from './AvailableBalance';
import CreditTransactionHistory from './CreditTransactionHistory';
import InsuffCredits from './InsuffCredits';
import { AddCredits } from './AddCredits';
import ReviewOrder from './AddCredits/ReviewOrder';
import styles from './Credits.module.scss';
import PaymentResults from '../../../../../core-components/PaymentResults';
import AppConstants from '../../../../../core-components/AppConstants';
import { convertToIndianNumeration } from '../../../../../utils/utilities';

function Credits(props) {
  const pageSizes = [10, 25, 50, 100];

  const [limit, setLimit] = useState(pageSizes[0]);
  const [offset, setOffset] = useState(0);
  const [insuffOffset, setInsuffOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [amount, setAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [orderId, setOrderId] = useState('');
  const [addedBalance, setAddedBalance] = useState(0);
  const [companyBalance, setCompanyBalance] = useState(0);
  const [warningObj, setWarningObj] = useState({
    VERIFICATION: 0,
    ADDITIONAL_CHARGE: 0,
    ADD_ON: 0
  });

  const fetchCreditTransactionHistory = (where = { limit: 10, offset: 0 }) => {
    setLoading(true);
    props
      ?.getCreditTransactionHistory(where)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLimit = (limit = 10) => {
    setLimit(limit);
  };

  const handleOffset = (offset = 0) => {
    setOffset(offset);
  };

  const paginate = (limit, offset) => {
    const where = { limit, offset };

    fetchCreditTransactionHistory(where);
  };

  // Initally fetching transaction history
  useEffect(() => {
    props?.invalidateCreditTransactionHistory();
    fetchCreditTransactionHistory();
  }, []);

  useEffect(() => {
    if (
      has(props.creditTransactionHistory, 'all') &&
      has(props.creditTransactionHistory, 'loading') &&
      props.creditTransactionHistory.loading === false
    ) {
      setData(props.creditTransactionHistory.all[offset].data);
      setCount(props.creditTransactionHistory.all[offset].count);
      setLoading(false);
    }
  }, [props.creditTransactionHistory]);

  useEffect(() => {
    if (paymentStatus == 'failed' || paymentStatus == 'completed') setCurrentStep(4);
  }, [paymentStatus]);

  const pageSizeOptions = [];
  let index = 0;

  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <div className={styles.creditContainer}>
            <div className={styles.header}>
              <div className={styles.heading}>{'Credits'}</div>
            </div>

            <Card className={styles.creditCard}>
              <Row className={styles.creditDetailsContainer}>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <AvailableBalance
                    history={props?.history}
                    availableBalance={availableBalance}
                    loading={loading}
                    setCurrentStep={setCurrentStep}
                    companyBalance={companyBalance}
                  />
                </Col>
                <Col lg={8} md={8} sm={12} xs={12}>
                  {/* Insuff credits */}
                  <InsuffCredits
                    setAvailableBalanceFromInsuff={setAvailableBalance}
                    setInsuffOffset={setInsuffOffset}
                    setWarningObj={setWarningObj}
                    setCompanyBalance={setCompanyBalance}
                  />

                  {/* Credit transaction history */}
                  <CreditTransactionHistory
                    fetchCreditTransactionHistory={fetchCreditTransactionHistory}
                    data={data}
                    loading={loading}
                    limit={limit}
                    offset={offset}
                    count={count}
                    handleLimit={handleLimit}
                    handleOffset={handleOffset}
                    paginate={paginate}
                    pageSizeOptions={pageSizeOptions}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        );
      case 2:
        return (
          <AddCredits
            setCurrentStep={setCurrentStep}
            amount={amount}
            setAmount={setAmount}
            availableBalance={availableBalance}
            offset={insuffOffset}
            obj={warningObj}
            insuffCredits={props?.insuffCredits}
            companyBalance={companyBalance}
          />
        );
      case 3:
        return (
          <ReviewOrder
            amount={amount}
            setCurrentStep={setCurrentStep}
            setPaymentStatus={setPaymentStatus}
            setOrderId={setOrderId}
            setAddedBalance={setAddedBalance}
            paymentDetails={props?.paymentDetails}
          />
        );
      case 4:
        return (
          <PaymentResults
            paymentStatus={paymentStatus}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            orderId={orderId}
            setPaymentStatus={setPaymentStatus}
            description={
              <>
                {paymentStatus == 'pending' && <p>This might take a few seconds</p>}
                {paymentStatus == 'completed' &&
                  `
                    ₹${convertToIndianNumeration(
                      addedBalance
                    )} has been added to your credit balance and any pending
                    verifications have started. Current balance is ₹${convertToIndianNumeration(
                      parseFloat(companyBalance) + parseFloat(addedBalance)
                    )}
                  `}
                {paymentStatus == 'failed' &&
                  `Something went wrong with the payment gateway. Please try again in a few
                    minutes.`}
              </>
            }
            buttons={
              <>
                <Button
                  variant='primary'
                  onClick={() => {
                    window.open(`${AppConstants.baseURL}credits`, '_self');
                  }}
                >
                  {'View credits'}
                </Button>
                {!props.hideViewDetailsButton ? (
                  <Button
                    variant='outline-primary'
                    onClick={() => {
                      window.open(`${AppConstants.baseURL}purchaseHistory`, '_self');
                    }}
                  >
                    {'View details'}
                  </Button>
                ) : null}
              </>
            }
          />
        );
    }
  };
  return <div>{renderStep(currentStep)}</div>;
}

export default Credits;
