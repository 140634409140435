import React, { useEffect, useState, useRef } from 'react';

import styles from '../../Integrations.module.scss';
import { Container, Row } from 'react-bootstrap';

import TopTextArea from '../../Utils/TopTextArea';
import IntegrationLayout from '../../Utils/Layout';
import Button from '../../../core-components/Button';
import WarningModal from '../../WarningModal';
import ConfirmationModal from '../../../core-components/ConfirmationModal';
import AppConstants from '../../../../../../core-components/AppConstants';
import authenticate from '@truto/truto-link-sdk';

import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/Utilities';
import { heavenHRDetails } from '../../Utils/constants';
import {
  deleteIntegrations,
  postIntegrations,
  getTRUTOLinkToken
} from '../../../../../../api/company';

export default function HeavenHR(props) {
  const [integrationData, setIntegrationData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [linkToken, setLinkToken] = useState('');

  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);
  }, [props?.integrationData]);

  useEffect(() => {
    props.setIntegrationAPI();
    props.getViewBuyPackages({ limit: 50, offset: 0 });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const sendn8nSlackNotification = () => {
    const payload = {
      integration_name: 'HEAVENHR',
      company_name: props.profile.profile.company_user_mapping.company.name,
      integration_method: 'TRUTO',
      company_id: props.profile.profile.company_user_mapping.company.id
    };

    const url =
      AppConstants.env === 'production'
        ? 'https://springworks.app.n8n.cloud/webhook/external/integration/failed-slack-notificaion'
        : 'https://springworks.app.n8n.cloud/webhook-test/external/integration/failed-slack-notificaion-non-prod';

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };

  const handlePostIntegration = () => {
    setShowModal(true);
    sendn8nSlackNotification();
  };

  const addIntegrationAPI = ({ integrationId, integration }) => {
    const payload = {
      source: integration.toUpperCase(),
      details: { integration_id: integrationId }
    };
    postIntegrations(payload)
      .then((resp) => {
        toast.success('HeavenHR Integration added successfully.');
        props.setIntegrationAPI();
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations('HEAVENHR')
      .then((resp) => {
        props.setIntegrationAPI();
        toast.success('Removed connection successfully.');
        setTriggerUseEffect(!triggerUseEffect);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  useEffect(() => {
    getTRUTOLinkToken()
      .then((response) => {
        setLinkToken(response.data.link_token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const connectTruto = () => {
    const options = {
      integration: 'heavenhr'
    };
    authenticate(linkToken, options)
      .then((response) => {
        console.log(response);
        // { result: 'success', integration: 'copper' }
        addIntegrationAPI({
          integration: response.integration,
          integrationId: response.integrated_account_id
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <IntegrationLayout>
        <div className={styles.parentDivTopTextArea}>
          <TopTextArea title={'HeavenHR'} icon={'heavenhr.png'} />
          <Container>
            <div className='mt-5'>
              {heavenHRDetails.map((step, index) => {
                return (
                  <Row>
                    <h6>
                      <span className='font-weight-bold'>Step {index + 1}:</span> {step}
                    </h6>
                  </Row>
                );
              })}
            </div>
          </Container>
        </div>
        <Container>
          <div className={`${styles.parentDivInputArea} mb-5`}>
            <div className={styles.rowDisplay}>
              <Row>
                <div className={styles.step1FooterContainer}>
                  <Button className={styles.goBackKNITBtn} onClick={() => backStepHandler()}>
                    Go Back
                  </Button>
                  {!integrationData?.heavenhr?.integrationId ? (
                    <>
                      {props.viewBuyPackages?.count > 1 ? (
                        <Button onClick={() => handlePostIntegration()}>Connect</Button>
                      ) : (
                        //
                        <Button className={styles.connectButton} onClick={() => connectTruto()}>
                          Connect
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      className={styles.removeButton}
                      onClick={() => setIsOpen(true)}
                      variant='outline-danger'
                    >
                      Disconnect
                    </Button>
                  )}
                </div>
              </Row>
              {isOpen ? (
                <WarningModal
                  show={isOpen}
                  onHide={() => {
                    setIsOpen(false);
                  }}
                  title={'HeavenHR'}
                  onConfirm={() => {
                    onRemoveConnectionHandler();
                  }}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </IntegrationLayout>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          heading={'Integration Alert'}
          onSubmit={() => {
            setShowModal(false);
            props.history.push(`/integrations`);
          }}
          enableBtn={true}
          subHeading={
            'Your account includes multiple packages and requires a custom configuration. Our CS team has been alerted about your request and will get in touch with you to enable the integration.'
          }
          sBtnText={'Close'}
          hideCloseBtn={true}
        />
      ) : null}
    </>
  );
}
