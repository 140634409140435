import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';
import { toast } from 'react-toastify';

import validationSchema from './StepTwo.validation';
import { validateExEmpData } from '../../../../../api/company';
import { getErrorMessage } from '../../core-components/Utilities/utilities';
import {
  getDataWithError,
  getDataWithoutError,
  getFilteredArray
} from '../utils/BulkUploadUtilities';
import { uploadExEmpCSVData } from '../../../../../actions/company';
import moment from 'moment';
import { ADMIN_URL } from '../../core-components/Utilities/CommonConstants';
import AppConstants from '../../../../../core-components/AppConstants';
import { errToastMessage } from '../../utils/Utilities';

const mapStateToProps = (state) => {
  return {
    companyId: (state && state?.profile?.profile?.company_user_mapping?.company_id) || '',
    bulkData: (state && state?.alumniBulkUpload) || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bulkUploadExEmp: (data) => dispatch(uploadExEmpCSVData(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const columns = props.bulkData?.column ? getFilteredArray([...props?.bulkData?.column]) : [];
      let storedValues = {
        staffId: '',
        fullName: '',
        dateOfBirth: '',
        designation: '',
        dateOfJoining: '',
        lastWorkingDay: '',
        phoneNumber: '',
        personalEmail: '',
        businessEmail: '',
        employmentType: '',
        exitType: '',
        exitReason: '',
        eligibleForRehire: '',
        rmName: '',
        rmDesignation: '',
        rmEmail: '',
        rmPhone: '',
        ctc: '',
        filterValue: '',
        finalData: null,
        tableData: [],
        loadingData: false,
        totalCount: 0,
        errorCount: 0,
        noErrorCount: 0,
        filteredDataEmployeeWithError: [],
        filteredDataEmployeeWithOutError: [],
        buttonClicked: false,
        dropDownValues: [...columns, 'None']
      };
      return mergeWith({}, storedValues);
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      setFieldValue('loadingData', true);
      const companyId = new URLSearchParams(document.location.search).get('id');
      const isSA = ADMIN_URL.some((str) => AppConstants.baseURL.toString().includes(str));
      const payload = {
        alumni: values?.finalData.map((exEmp) => {
          return {
            staff_id: exEmp.staff_id,
            full_name: exEmp.full_name,
            date_of_birth: exEmp.date_of_birth,
            date_of_joining: exEmp.date_of_joining,
            last_working_day: exEmp.last_working_day,
            phone_number: exEmp?.phone_number?.toString(),
            personal_email: exEmp.personal_email,
            business_email: exEmp.business_email,
            employment_type: exEmp.employment_type,
            exit_type: exEmp.exit_type,
            exit_reason: exEmp.exit_reason,
            eligible_for_rehire: exEmp.eligible_for_rehire,
            rm_name: exEmp?.rm_name,
            rm_designation: exEmp?.rm_designation,
            rm_phone: exEmp?.rm_phone?.toString(),
            rm_email: exEmp.rm_email,
            designation: exEmp.designation,
            ctc: exEmp.ctc
          };
        })
      };
      if (isSA) {
        payload['company_id'] = companyId;
      }
      validateExEmpData(payload)
        .then((res) => {
          setFieldValue('tableData', res?.data?.data);
          setFieldValue('filteredDataCandidatesWithError', getDataWithError(res?.data?.data));
          setFieldValue('filteredDataCandidatesWithOutError', getDataWithoutError(res?.data?.data));
          setFieldValue('loadingData', false);
          setFieldValue('totalCount', res?.data?.data?.length || 0);
          setFieldValue('errorCount', getDataWithError(res?.data?.data, true));
          setFieldValue('noErrorCount', getDataWithoutError(res?.data?.data, true));
        })
        .catch((err) => errToastMessage(err));
    },
    displayName: 'Bulk candidate upload'
  })
);
