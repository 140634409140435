import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  Card,
  Col,
  Container,
  Row,
  Breadcrumb,
  Badge,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

import styles from './OrderDetails.module.scss';

import AppConstants from '../../../../../core-components/AppConstants';
import {
  convertToIndianNumeration,
  errToastMessage,
  toCamelCase
} from '../../../../../utils/utilities';
import { toast } from 'react-toastify';
import { getOrderDetailsByID } from '../../../../../api/company';
import Loader from '../../../../../core-components/Loader/Loader';
import { PAYMENT_REQUEST_EXPIRY_IN_DAYS } from '../../../../../utils/commonConstant';
import { copyToClipboard } from '../../../frontend-common/utils/Utilities';
import Image from '../../../frontend-common/core-components/Image/Image';

export default (props) => {
  const { paymentDetails } = props;
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clickToCopy, setClickToCopy] = useState(false);
  const message = 'Payment link copied to clipboard';
  const paymentLink = `${AppConstants.baseURL}purchase/payment-request?token=${orderDetails?.orderUniqueToken}`;
  const copyToClipboardHandler = () => {
    copyToClipboard(paymentLink, message);
  };
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const orderIdFromURL = query.get('order_id');
    if (orderIdFromURL) {
      getOrderDetailsByID(orderIdFromURL)
        .then((res) => {
          res.data?.data?.payment_orders.length > 0 &&
            setOrderDetails(toCamelCase(res.data?.data?.payment_orders[0]));
          setLoading(false);
        })
        .catch((err) => {
          errToastMessage(err);
          setLoading(false);
        });
    }
  }, []);

  const calculateTotal = () => {
    var total = convertToIndianNumeration(
      parseFloat(orderDetails?.amount || 0) +
        parseFloat(orderDetails?.amount * (paymentDetails?.tax / 100) || 0) -
        parseFloat(orderDetails?.amount * (orderDetails?.tdsPercentage / 100) || 0),
      2
    );
    return total;
  };

  // tax and tds details for company
  const taxTds = () => {
    return (
      <>
        {paymentDetails?.tax ? (
          <Row className='mt-2'>
            <Col xs={6} sm={6} md={6} lg={6}>
              Tax ({paymentDetails?.tax || 0}%)
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
              <span className={styles.taxAmount}>
                &#8377;
                {convertToIndianNumeration(orderDetails?.amount * (paymentDetails?.tax / 100), 2)}
              </span>
            </Col>
          </Row>
        ) : null}
        {/* need tds percentage from order */}
        {orderDetails.tdsPercentage ? (
          <Row className='mt-2'>
            <Col xs={6} sm={6} md={6} lg={6}>
              TDS ({orderDetails?.tdsPercentage || 0}%)
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
              <span className={styles.tdsAmount}>
                -&#8377;{convertToIndianNumeration(parseFloat(orderDetails?.tdsAmount) || 0, 2)}
              </span>
            </Col>
          </Row>
        ) : null}
      </>
    );
  };
  const getCancelledUser = () => {
    let viaText = '';
    if (orderDetails?.cancelledBy?.isCancelledBySa) {
      viaText = '(via SV)';
    }
    return `${orderDetails?.cancelledBy?.userName} ${viaText}`;
  };
  const getRequestedUser = () => {
    let viaText = '';
    if (orderDetails?.requestedBy?.isRequestedBySa) {
      viaText = '(via SV)';
    }
    return `${orderDetails?.requestedBy?.userName} ${viaText}`;
  };
  const summaryDetails = () => {
    return (
      <Container className={styles.summary}>
        <Row className={styles.total}>
          <Col xs={6} sm={6} md={6} lg={6}>
            Amount
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
            <span className={styles.amount}>
              &#8377;{convertToIndianNumeration(orderDetails?.amount || 0, 2)}
            </span>
          </Col>
        </Row>
        {taxTds()}
      </Container>
    );
  };
  const getStyleForDeletedUser = (userType) => {
    switch (userType) {
      case 'REQUESTED':
        return orderDetails?.requestedBy?.deletedAt ? styles.deletedUser : '';
      case 'CANCELLED':
        return orderDetails?.cancelledBy?.deletedAt ? styles.deletedUser : '';
      default:
        break;
    }
  };

  const getLinkOrDateLabel = () => {
    switch (orderDetails.status) {
      case 'PENDING':
        return 'Payment Link';
      case 'CANCELLED':
        return 'Cancelled Date';
      case 'EXPIRED':
        return 'Expired Date';
      default:
        break;
    }
  };
  const getLinkOrDate = () => {
    switch (orderDetails.status) {
      case 'PENDING':
        return (
          <div>
            <a
              href={`${AppConstants.baseURL}purchase/payment-request?token=${orderDetails?.orderUniqueToken}`}
              target='_blank'
              className={styles.blueColor}
            >
              Link
            </a>
            <span>
              {' '}
              (Expires on{' '}
              {moment(orderDetails?.requestedDate)
                .add(PAYMENT_REQUEST_EXPIRY_IN_DAYS + 1, 'days')
                .format('DD MMM YYYY')}
              )
            </span>
            <span
              onClick={() => {
                setClickToCopy(true);
                copyToClipboardHandler();
              }}
              className={styles.tooltip}
            >
              <Image className={styles.copyToClipboard} name={'CopyToken.svg'} />
              <span className={styles.tooltiptext}>{clickToCopy ? 'Copied' : 'Copy'}</span>
            </span>
          </div>
        );
      case 'CANCELLED':
        return (
          <span>
            {' '}
            {moment(orderDetails?.cancelledOrExpiredDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
          </span>
        );
      case 'EXPIRED':
        return (
          <span>
            {' '}
            {moment(orderDetails?.cancelledOrExpiredDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
          </span>
        );
      default:
        break;
    }
  };
  const getRequestee = () => {
    const CA_USERS = orderDetails?.requesteeDetails.filter((rqstee) => rqstee.user);
    const EXTERNAL_USERS = orderDetails?.requesteeDetails.filter((rqstee) => !rqstee.user);
    let requestees = [...CA_USERS, ...EXTERNAL_USERS].map((rqstee) =>
      rqstee.user ? rqstee.user.name : rqstee.email
    );
    return requestees.join(', ');
  };
  if (loading) {
    return <Loader />;
  }
  return orderDetails ? (
    <div className={styles.purchaseDetailsWrapper}>
      <div className={styles.heading}>{'Purchase Details'}</div>
      <Card className={styles.purchaseDetailsCard}>
        <Container className={styles.purchaseDetailsCardContainer}>
          <Row>
            <Col lg={8}>
              <div className={styles.purchaseTypeHeading}>
                <span className={styles.title}>PURCHASE (RECHARGE)</span>
                <span className={styles.subtitle}>
                  <span
                    className={`${styles.txnPill} ${
                      orderDetails?.status === 'PENDING' ? styles.pendingTxn : styles.otherTxn
                    }`}
                  >
                    {orderDetails.status}
                  </span>
                </span>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.summaryHeading}>Summary</div>
            </Col>
          </Row>
          <Row>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Row>
                <Col xs={5} sm={5} md={5} lg={5}>
                  <div className={styles.infoCard}>
                    <span className={styles.title}>Date</span>
                    <span className={styles.text}>
                      {moment(orderDetails?.requestedDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                    </span>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  {orderDetails?.status === 'CANCELLED' ? (
                    <div className={styles.infoCard}>
                      <span className={styles.title}>Cancelled By</span>
                      <span className={`${styles.text} ${getStyleForDeletedUser('CANCELLED')}`}>
                        {getCancelledUser(orderDetails)}
                      </span>
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={5} md={5} lg={5}>
                  <div className={styles.infoCard}>
                    <span className={styles.title}>{getLinkOrDateLabel()}</span>
                    <span className={`${styles.text}`}>{getLinkOrDate()}</span>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className={styles.infoCard}>
                    <span className={styles.title}>Requested By</span>
                    <span className={`${styles.text} ${getStyleForDeletedUser('REQUESTED')}`}>
                      {getRequestedUser()}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={5} sm={5} md={5} lg={5}>
                  <div className={styles.infoCard}>
                    <span className={styles.title}>Proforma Invoice</span>
                    <span className={`${styles.text} ${getStyleForDeletedUser('REQUESTED')}`}>
                      <a
                        href={`${AppConstants.baseURL}proforma-invoice?invoiceId=${orderDetails?.proformaNumber}`}
                        target='_blank'
                        className={styles.blueColor}
                      >
                        {orderDetails?.proformaNumber}
                      </a>
                    </span>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className={styles.infoCard}>
                    <span className={styles.title}>Requestee</span>
                    <span className={styles.text}>{getRequestee()}</span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4} md={4} lg={4}>
              <Card className={styles.summaryCard}>
                <Card.Body className={styles.infoCard}>
                  <Card.Text className={styles.text}>
                    {summaryDetails()}
                    <Row className={`${styles.total} mt-2`}>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        TOTAL
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} className='text-right'>
                        <span className={styles.amount}>
                          &#8377;
                          {calculateTotal()}
                        </span>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  ) : (
    <div className={styles.purchaseDetailsWrapper}>
      <Card className={styles.completedOrderCard}>Payment Order was completed.</Card>
    </div>
  );
};
