import React from 'react';
import styles from './RazorpayBanner.module.scss';
import razorpayImage from '../../../../images/razorpay.png';

const RazorpayBanner = () => {
  return (
    <div
      className={styles.bannerContainer}
      style={{ backgroundImage: `url(${razorpayImage})` }}
      onClick={() =>
        window.open(
          'https://payroll.razorpay.com/signup?utm_medium=external&utm_source=springverifypartnership=%2fpayroll%2f',
          '_blank'
        )
      }
    ></div>
  );
};
export default RazorpayBanner;
