import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  invalidatePaymentCreditTransaction,
  setPaymentCreditTransaction
} from '../../../../../actions/company';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCreditTxnDetails: (data) => dispatch(setPaymentCreditTransaction(data)),
    invalidateCreditTxnDetails: () => dispatch(invalidatePaymentCreditTransaction())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
