import React from 'react'
import { Card, ListGroup } from 'react-bootstrap';

import Image from '../../../../core-components/Image';
import Icon from '../../../../core-components/Icon';

import styles from './BgvSidePanel.module.scss';

export default function BgvSidePanel(props) {
  const classObj = {
    active: 'activeList',
    normal: 'normalList',
    filled: 'filledList'
  };
  const { sidePanelSequence } = props;
  return (
    <Card className={styles.sidePanelCard}>
      <Card.Body className={styles.sidePanelCardBody}>
        <ListGroup as='ul' className={styles.listGroup}>
          <ListGroup.Item as='li' className={styles.secureList}>
            <div>
              <Image name='secure_icon.svg' />
              <span>Secure BGV Form</span>
            </div>
          </ListGroup.Item>
          {sidePanelSequence.length > 0 &&
            sidePanelSequence.map((item, key) => {
              return (
                <ListGroup.Item
                  as='li'
                  key={`sidePanel_${key}`}
                  active={item?.isActive === 'active'}
                  className={
                    item?.isActive === 'active'
                      ? styles[classObj.active]
                      : item?.isActive === 'filled'
                        ? styles[classObj.filled]
                        : styles[classObj.normal]
                  }
                  onClick={
                    item?.isActive === 'filled'
                      ? () => props.handleSidePanelClick(item?.id)
                      : null
                  }
                >
                  <div>
                    <Icon icon={item?.img} color={item?.isActive === 'active' ? '#2755FE' : '#333333'} width={'16'} height={'16'} />
                    <span>{item?.text}</span>
                  </div>
                  <Icon icon={'TickBGV'} color={item?.tickImgColor} width={'16'} height={'16'} />
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Card.Body>
    </Card>
  )
}
