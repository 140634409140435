import { connect } from 'react-redux';
import { uploadExEmpCSV } from '../../../../../actions/company';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    bulkUploadExEmp: (data) => dispatch(uploadExEmpCSV(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
