import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { deleteIntegrations, postIntegrations } from '../../../../../../../api/company';
import Button from '../../../../core-components/Button';
import ValidatedFormInputField from '../../../../core-components/ValidatedFormInputField';
import WarningModal from '../../../WarningModal';
import styles from './InputArea.module.scss';
import { generateRazorPayAPIKeySteps } from '../../../Utils/constants';
import { errToastMessage } from '../../../../utils/Utilities';

function InputArea(props) {
  const { values, setFieldValue } = props;
  const [isOpen, setIsOpen] = useState(false);
  const companyId = new URLSearchParams(document.location.search).get('company_id') || null;
  const isRazorPayConnected = !!props.isRazoPayConnected;

  useEffect(() => {
    props.setIntegrationDataAPI(companyId);
  }, []);

  /** API Calls **/
  const postIntegrationsApi = () => {
    const payload = {
      company_id: companyId,
      source: 'RazorpayX',
      details: {
        api_key: values?.razorPayApiKey
      }
    };

    postIntegrations(payload)
      .then((resp) => {
        props.setIntegrationDataAPI(companyId);
        toast.success('Connected successfully.');
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const removeIntegrationsApi = () => {
    deleteIntegrations('RazorpayX', companyId)
      .then((resp) => {
        props.setIntegrationDataAPI(companyId);
        setFieldValue('razorPayApiKey', '');
        toast.success('Removed connection successfully.');
        setIsOpen(false);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onConnectHandler = () => {
    if (isRazorPayConnected) {
      toast.info('RazorPayX is already connected.');
      return;
    }

    if (values.razorPayApiKey) {
      postIntegrationsApi();
    } else {
      toast.info('Cannot connect to RazorPayX with empty API key value.');
    }
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  return (
    <Container>
      <Row className='mt-5'>
        <h6 className='font-weight-bold'>Step 1: Generate API key from RazorpayX</h6>
      </Row>
      <div className={styles.listItems}>
        {generateRazorPayAPIKeySteps.map((step, index) => {
          return (
            <div>
              {index + 1}. {step}
            </div>
          );
        })}
      </div>
      <Row className='mt-4'>
        <h6 className='font-weight-bold'>Step 2: Enter the API key and click Connect</h6>
      </Row>
      <Form className='mt-5'>
        <Row>
          <Col className={`col-sm-2 ${styles.inputLabel}`}>
            <span className=''>RazorpayX API key:</span>
          </Col>
          <Col className='col-sm-4'>
            <Field
              disabled={isRazorPayConnected}
              component={ValidatedFormInputField}
              type='text'
              placeholder='Key'
              name='razorPayApiKey'
              value={values?.razorPayApiKey || ''}
            />
          </Col>
        </Row>
      </Form>
      <Row>
        <div className={styles.step1FooterContainer}>
          <Button className={styles.goBackBtn} onClick={() => props.history.push('/integrations')}>
            Go Back
          </Button>
          {!isRazorPayConnected ? (
            <Button
              className={`${isRazorPayConnected ? styles.connectedButton : styles.connectButton}`}
              onClick={onConnectHandler}
            >
              Connect
            </Button>
          ) : null}
          {isRazorPayConnected ? (
            <Button
              className={styles.removeButton}
              variant='outline-danger'
              onClick={() => setIsOpen(true)}
            >
              Remove Connection
            </Button>
          ) : null}
        </div>
      </Row>
      {isOpen ? (
        <WarningModal
          show={isOpen}
          onHide={() => {
            setIsOpen(false);
          }}
          title={'RazorpayX'}
          onConfirm={() => {
            onRemoveConnectionHandler();
          }}
        />
      ) : null}
    </Container>
  );
}

export default InputArea;
