// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lhQ2miJpEGKjOlFnRm_z {
  text-align: center;
}
.lhQ2miJpEGKjOlFnRm_z .WEtbA4SP5Xc80o9tHjaB {
  height: 100px;
  width: auto;
  margin-top: 5px;
}
.lhQ2miJpEGKjOlFnRm_z .BrobF91JKHMcByNw5Ztc {
  font-family: sans-serif !important;
  font-size: 20px !important;
  margin-bottom: -5px;
}
.lhQ2miJpEGKjOlFnRm_z .BNNlO_SGXyzEUH09Da3a {
  font-family: sans-serif !important;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/UnAuthorized/UnAuthorized.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;EACA,eAAA;AACJ;AAEE;EACE,kCAAA;EACA,0BAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kCAAA;EACA,0BAAA;AADJ","sourcesContent":[".unAuthorized {\n  text-align: center;\n\n  .imgStyle {\n    height: 100px;\n    width: auto;\n    margin-top: 5px;\n  }\n\n  .labelStyle {\n    font-family: sans-serif !important;\n    font-size: 20px !important;\n    margin-bottom: -5px;\n  }\n\n  .aStyle {\n    font-family: sans-serif !important;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unAuthorized": `lhQ2miJpEGKjOlFnRm_z`,
	"imgStyle": `WEtbA4SP5Xc80o9tHjaB`,
	"labelStyle": `BrobF91JKHMcByNw5Ztc`,
	"aStyle": `BNNlO_SGXyzEUH09Da3a`
};
export default ___CSS_LOADER_EXPORT___;
