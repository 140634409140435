import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    packages:
      state.viewBuyPackages && !isEmpty(state.viewBuyPackages) && !state.viewBuyPackages.loading
        ? state.viewBuyPackages.rows
        : null,
    walletDetail: state.walletDetail || {},
    selectedTiers:
      state.selectedTiers && !isEmpty(state.selectedTiers) ? state.selectedTiers : null,
    paymentDetails: (state?.paymentDetails && state.paymentDetails) || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps);
