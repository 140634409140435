import React from 'react';
import Form from 'react-bootstrap/Form';
import cn from 'classnames';

import styles from './TextArea.module.scss';

export default React.forwardRef((props, ref) => {
  return (
    <Form.Control
      data-testid='form'
      {...props}
      as={'textarea'}
      className={cn(styles.input, props.className)}
      ref={ref}
    />
  );
});
