import React from 'react';

import styles from '../Integrations.module.scss';
import InputArea from './Components/InputArea';
import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';

export default function RazorPayXHome(props) {
  return (
    <IntegrationLayout>
      <div className={styles.parentDivTopTextArea}>
        <TopTextArea title={'RazorpayX'} icon={'razorPayX.png'} />
        <InputArea
          isRazoPayConnected={props.integrationData?.razorpayX?.apiKey}
          razorPayApiKey={props.integrationData?.razorpayX?.apiKey}
          history={props.history}
        />
      </div>
    </IntegrationLayout>
  );
}
