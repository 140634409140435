// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gsWP2QbOUmZzaHZXCWRB {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #dc3545;
}

/* Hide up and down arrows for number input */
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/core-components/ValidatedFormInputField/ValidateFormInputField.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AACF;;AACA,6CAAA;AAEE;EAEE,wBAAA;EACA,gBAAA;EACA,SAAA;AAAJ","sourcesContent":[".inputError {\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 14px;\n  color: #dc3545;\n}\n/* Hide up and down arrows for number input */\ninput[type='number'] {\n  &::-webkit-inner-spin-button,\n  &::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    appearance: none;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": `gsWP2QbOUmZzaHZXCWRB`
};
export default ___CSS_LOADER_EXPORT___;
