import { isEmpty, isArray } from 'lodash';

let educationLevelMap = {
  10: '10',
  12: '12',
  '12/Diploma': '12',
  UNDERGRAD: 'UNDERGRAD',
  POSTGRAD: 'POSTGRAD',
  Undergraduate: 'UNDERGRAD',
  Postgraduate: 'POSTGRAD',
  PHD: 'PHD',
  Other: 'OTHER'
};

export const formValuesToAPI = (formValues) => {
  const formatDetails = (data) => {
    if (!data.uan_number) {
      data.uan_number = null;
    }

    let returnValue;
    if (!isEmpty(data)) {
      if (isArray(data)) {
        returnValue = [...data];
      } else {
        returnValue = { ...data };
      }
    }
    return returnValue;
  };

  const formatReference = (formData) => {
    let values = { ...formData };
    if (values?.hr_info?.phone) {
      values.hr_info = {
        ...values.hr_info,
        phone_number: values?.hr_info?.phone
          ? values?.hr_info?.country_code + values?.hr_info?.phone
          : ''
      };
    }
    if (values?.manager_info?.phone) {
      values.manager_info = {
        ...values.manager_info,
        phone_number: values?.manager_info?.phone
          ? values?.manager_info?.country_code + values?.manager_info?.phone
          : ''
      };
    }
    if (values.ref_count && values.references_additional) {
      values.references_additional = values?.references_additional?.map((value) => ({
        ...value,
        phone_number: value?.phone ? value?.country_code + value?.phone : ''
      }));
      if (values.references_additional[0]) {
        values.manager_info = { ...values.references_additional[0] };
        values.references_additional.splice(0, 1);
      }
    }
    return values;
  };

  const formatEmploymentCheck = (formData) => {
    let returnValue = {};
    returnValue = { ...formData };
    if (!isEmpty(formData) && formData.data !== undefined && formData.data.length) {
      let employmentdataArray = [];
      for (let i = 0; i < formData.data.length; i++) {
        let currentStep = formData.data[i];
        //to get only links array
        let fileLinks = !isEmpty(currentStep.step_2)
          ? currentStep.step_2.proof_doc.map((value) => ({
              url: value.link,
              password: value?.password || ''
            }))
          : [];
        let step2Data = {
          ...currentStep.step_2
        };
        let step3Data = formatReference(currentStep.step_3);
        step2Data.proof_doc = fileLinks;
        employmentdataArray[i] = {
          ...currentStep.step_1,
          ...step2Data,
          ...step3Data
        };
      }
      returnValue = employmentdataArray;
    }
    return returnValue;
  };

  const formatEducationCheck = (formData) => {
    let returnValue = [];
    if (!isEmpty(formData)) {
      let eduDataArray = [];
      for (let i = 0; i < formData.length; i++) {
        let currentStep = formData[i];
        let step1Data = {};
        if (currentStep.step_1.education_level) {
          step1Data.education_level = educationLevelMap[currentStep.step_1.education_level];
        }

        let step2Data = {};
        if (currentStep.step_2.grade_sheet) {
          step2Data.grade_sheet = currentStep.step_2.grade_sheet.map((value) => ({
            url: value.link,
            password: value?.password || ''
          }));
        }
        step2Data.certificate =
          currentStep.step_2.certificate && !isEmpty(currentStep.step_2.certificate)
            ? {
                url: currentStep.step_2.certificate.link,
                password: currentStep?.step_2?.certificate?.password || ''
              }
            : null;
        eduDataArray[i] = {
          ...currentStep.step_1,
          ...step1Data,
          ...step2Data
        };
      }
      returnValue = eduDataArray;
    }

    return returnValue;
  };

  const getGmap = (step2) => {
    return {
      lat: step2.latitude || null,
      lng: step2.longitude || null,
      full_address: step2.map_address || null,
      place_id: step2.place_id || null
    };
  };

  const getAbsenceDetail = (step2) => {
    return {
      full_name: step2.other_full_name || null,
      relation: step2?.relation?.toUpperCase() || null,
      phone_number:
        (step2.relative_country_code && step2.relative_phone_number && step2.other_phone_number) ||
        null,
      other_relation_name: step2?.other_relation || null
    };
  };

  // SPRINT-10 changes has addres is changed to array of objects
  const formatAddrCheck = (formData) => {
    let returnValue = [];
    if (!isEmpty(formData)) {
      let addressDataArray = [];
      let j = 0;
      for (let i = 0; i < formData.length; i++) {
        if (!isEmpty(formData[i])) {
          let obj = {
            'Permanent Address': 'PERMANENT',
            'Current Address': 'CURRENT'
          };

          formData[i].step_1.address_type = ['Permanent Address', 'Current Address'].includes(
            formData[i].step_1.address_type
          )
            ? obj[formData[i].step_1.address_type] || null
            : formData[i].step_1.address_type;
          const step1 = { ...formData[i].step_1 };
          const step2 = { ...formData[i].step_2 };
          addressDataArray[j] = {
            ...formData[i].step_1,
            ...formData[i].step_2,
            ...formData[i].step_3,
            gmap_address_info: { ...getGmap(step2) },
            front_doc_password: formData[i]?.step_3?.front_doc_password || '',
            back_doc_password: formData[i]?.step_3?.back_doc_password || '',
            absence_contact_details: { ...getAbsenceDetail(step2) },
            is_candidate_available: step2.is_candidate_available === 'yes' ? true : false,
            relation: step2.relation ? step2.relation.toUpperCase() : ''
          };
          j++;
        }
      }
      returnValue = addressDataArray;
    }
    return returnValue;
  };

  const getPhoneNumberString = (countryCode, phoneNumber) => {
    let intlNumber = null;
    if (phoneNumber.startsWith('+')) {
      intlNumber = `${phoneNumber}`;
    } else {
      intlNumber = `${countryCode}${phoneNumber}`;
    }
    return intlNumber;
  };

  const formatContactDetails = (data) => {
    let returnValue;
    if (!isEmpty(data)) {
      const phone_numbers = [];
      if (data.alternate_phone_number) {
        const altPhone = {
          ...data.alt_phone_ref,
          data: !data.alt_country_code
            ? data.alternate_phone_number
            : data.alt_country_code + data.alternate_phone_number,
          belongs_to: 'CANDIDATE'
        };
        phone_numbers.push(altPhone);
      } else if (!data.alternate_phone_number && data.alt_phone_ref && data.alt_phone_ref?.data) {
        phone_numbers.push({ ...data.alt_phone_ref, is_deleted: true });
      }
      returnValue = {
        email: data.email,
        alternate_email: data.alternate_email || null,
        phone_number:
          data.country_code && data.phone_number
            ? getPhoneNumberString(data.country_code, data.phone_number)
            : null,
        whatsapp_update: data.whatsapp_update
      };
      if (phone_numbers?.length > 0) returnValue['phone_numbers'] = phone_numbers;
    }
    return returnValue;
  };

  const formatIdCheck = (idCheck) => {
    const idCheckArr = [];
    for (let value of idCheck) {
      if (!isEmpty(value)) {
        idCheckArr.push({
          ...value,
          id_type: value.id_type.name,
          front_doc_password: value?.front_doc_password || '',
          back_doc_password: value?.back_doc_password || ''
        });
      }
    }
    return idCheckArr;
  };

  return {
    basic_details: formValues.basic_details ? formatDetails(formValues.basic_details) : null,
    contact_details: formValues.contact_details
      ? formatContactDetails(formValues.contact_details)
      : null,
    id_check: formValues.id_check ? formatIdCheck(formValues.id_check) : [],
    employment_check: formValues.employment_check
      ? formatEmploymentCheck(formValues.employment_check)
      : [],
    education_check: formValues.education_check
      ? formatEducationCheck(formValues.education_check)
      : [],
    address_check: formValues.address_check ? formatAddrCheck(formValues.address_check) : null,
    consent: formValues.consent ? formatDetails(formValues.consent) : null
  };
};
