import React from 'react';
import { Form, Col } from 'react-bootstrap';

import CustomSelect from '../CustomSelect';

export default React.forwardRef(({ field, form, label, ...props }, ref) => {
  return (
    <Form.Group as={Col} controlId={field && field.name} className={props.className}>
      {label && <Form.Label>{label}</Form.Label>}
      <CustomSelect {...field} {...props} {...form} ref={ref} />
    </Form.Group>
  );
});
