import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';

export const GENDER = {
  1: 'Male',
  2: 'Female',
  3: 'Not Specified'
};
export const RowData = (props) => {
  const { data, errorClass, onChangeHandler, type, rowIndex, cellName } = props;
  let result = null;

  const handleChange = (e) => {
    onChangeHandler(e.target.value, rowIndex, cellName);
  };

  const handleKeyDown = (e) => {
    // Check if the pressed key is a number (0-9) or a valid control key (e.g., Backspace, Delete)
    if (
      cellName === 'phone' &&
      !/^\d$/.test(e.key) &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  if (typeof data === 'object') {
    result = (
      <td>
        <div style={{ display: 'flex' }}>
          <input
            type='text'
            defaultValue={data?.value || ''}
            className={errorClass}
            onBlur={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div style={{ marginLeft: '5px' }}>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip
                  style={{
                    color: '#ffffff',
                    fontSize: '8px'
                  }}
                  id={'error'}
                >
                  {data?.error}
                </Tooltip>
              }
            >
              <div>
                <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#808080' }} />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </td>
    );
  } else {
    if (type === 'gender') {
      return <td> {GENDER[data]}</td>;
    }
    result = <td>{data}</td>;
  }
  return result;
};

export const getTableRows = ({ array, values, styles, onChangeHandler }) => {
  let rows = [];
  array?.length >= 0 &&
    array.forEach((row, index) => {
      let rowInfo = (
        <tr className={styles.rowData} key={index}>
          <RowData
            data={row?.first_name || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='first_name'
            onChangeHandler={onChangeHandler}
          />

          {values.hasTwoColumnsForName && (
            <RowData
              data={row?.last_name || '-'}
              errorClass={styles.errorField}
              rowIndex={index}
              cellName='last_name'
              onChangeHandler={onChangeHandler}
            />
          )}
          <RowData
            data={row?.phone || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            type='number'
            cellName='phone'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.emp_id || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='emp_id'
            onChangeHandler={onChangeHandler}
          />
          <RowData
            data={row?.email || '-'}
            errorClass={styles.errorField}
            rowIndex={index}
            cellName='email'
            onChangeHandler={onChangeHandler}
          />
        </tr>
      );

      rows.push(rowInfo);
    });
  return rows;
};

// get required symbol
export const getRequiredField = (value) => {
  return (
    <div>
      {value}
      <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
    </div>
  );
};
// get headers for table
export const getHeaders = (hasTwoColumnsForName) => {
  return (
    <>
      <th>
        {hasTwoColumnsForName ? getRequiredField('First Name') : getRequiredField('Full Name')}
      </th>
      {hasTwoColumnsForName && <th>Last Name</th>}
      <th>{getRequiredField('Phone')}</th>
      <th style={{ width: '120px' }}>Employee ID</th>
      <th>{getRequiredField('Email')}</th>
    </>
  );
};

// create instructions block for step one
export const getInstructionItems = (styles) => {
  const labels = [
    'only one sheet in it',
    'no merged cells',
    'no empty rows in between the data',
    'a header in the first row with all the required fields',
    'Full Name, Phone  Number, Email are present for each candidate',
    'Max 200 records (rows)',
    'Max 20 columns'
  ];
  const result = labels.map((val, index) => (
    <span className={styles.instructionItem} key={index}>
      <FontAwesomeIcon icon={faCheck} className={styles.checkItemSvg} />
      {val}
    </span>
  ));
  return result;
};
