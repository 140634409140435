// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RiwPOesWOTMsHZP1d7na {
  padding-left: 0;
}

.B6zKvIheD3L3Fpo_lXyJ {
  max-width: 40px;
}

.KoLtxBhkxKXQc53i0DY_ {
  font-weight: 200;
  padding-left: 0;
  font-size: 14px;
  transition: color 0.7s ease-in-out;
}

.Stau3nBG45xzz_U0pwpb {
  flex-wrap: unset;
}

.xaN96usIhKWEGIIPFxYG {
  color: #FF0000;
}

.RG61qAAyaBIy8pVWZ_0b {
  color: #008000;
}

.qlmQIGpCvH3Q0paClB_L {
  padding-left: 20px !important;
  margin-bottom: 0px;
}

.fLkJ7jQOmzK1YL6QeUjl {
  font-weight: 200;
  font-size: 14px;
  padding-left: 5px;
  margin-bottom: 2px;
  padding-bottom: 1px;
}`, "",{"version":3,"sources":["webpack://./src/core-components/DynamicPasswordRules/PasswordRulesBox.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,eAAA;EACA,kCAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,6BAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ","sourcesContent":[".container {\n    padding-left: 0;\n}\n\n.iconBox {\n    max-width: 40px;\n}\n\n.textBox {\n    font-weight: 200;\n    padding-left: 0;\n    font-size: 14px;\n    transition: color 0.7s ease-in-out;\n}\n\n.unWrap {\n    flex-wrap: unset;\n}\n\n.fontRed {\n    color: #FF0000;\n}\n\n.fontGreen {\n    color: #008000;\n}\n\n.ulBox {\n    padding-left: 20px !important;\n    margin-bottom: 0px;\n}\n\n.listItem {\n    font-weight: 200;\n    font-size: 14px;\n    padding-left: 5px;\n    margin-bottom: 2px;\n    padding-bottom: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RiwPOesWOTMsHZP1d7na`,
	"iconBox": `B6zKvIheD3L3Fpo_lXyJ`,
	"textBox": `KoLtxBhkxKXQc53i0DY_`,
	"unWrap": `Stau3nBG45xzz_U0pwpb`,
	"fontRed": `xaN96usIhKWEGIIPFxYG`,
	"fontGreen": `RG61qAAyaBIy8pVWZ_0b`,
	"ulBox": `qlmQIGpCvH3Q0paClB_L`,
	"listItem": `fLkJ7jQOmzK1YL6QeUjl`
};
export default ___CSS_LOADER_EXPORT___;
