import React, { useEffect, useState } from 'react';
import styles from './TabComponent.module.scss';
import AddAlumni from '../../AlumniVerification/AddAlumni/AddAlumni';
import Button from '../Button';

export default (props) => {
  const companyId = new URLSearchParams(document.location.search).get('id');
  const { activeTab, tabItems, handleTabChange, history, isSA } = props;

  // To Set Active tab from parent
  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  const [active, setActive] = useState(tabItems[0]);
  return (
    <div className={styles.tabComponentWrapper}>
      <div className={styles.tabContainer}>
        {tabItems.map((tab, index) => (
          <div className={styles.tabStyles}>
            <div
              key={index}
              className={active === tab ? styles.tabActive : null}
              onClick={() => {
                handleTabChange(tab);
                setActive(tab);
              }}
            >
              {tab}
            </div>
          </div>
        ))}
      </div>
      {isSA && (
        <div>
          <Button
            className={styles.alumniBtn}
            onClick={() => {
              history?.push({
                pathname: '/company/alumniVerification',
                search: `?id=${companyId}&flow=bulk`
              });
            }}
          >
            Bulk Upload
          </Button>
          <Button
            className={styles.alumniBtn}
            onClick={() => {
              history?.push({
                pathname: '/company/alumniVerification',
                search: `?id=${companyId}&flow=add`
              });
            }}
          >
            Add Single Record{' '}
          </Button>
        </div>
      )}
    </div>
  );
};
