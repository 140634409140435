import React from 'react';
import styles from './Header.module.scss';
import { Card } from 'react-bootstrap';
import { getBannerText } from './utils';
import cn from 'classnames';
import Image from '../../../core-components/Image';
import { Link } from 'react-router-dom';
import Banner from '../../../core-components/Banner/Banner';
import AppConstants from '../../../core-components/AppConstants';

export const WalletPackageBalanceBanner = (props) => {
  const { location, walletDetail, paymentDetails, historyProps } = props;

  const { text, color } = getBannerText(walletDetail, paymentDetails?.packageDetails?.rows);
  if (text) {
    return (
      <Card
        className={cn(
          styles.bannerCardContainer,
          color == 'Yellow' ? styles.lowBalance : styles.criticalLow
        )}
        onClick={() => {
          historyProps.history.push('/buy');
        }}
      >
        <div className={styles.bannerDivContainer}>
          <Image
            name={color == 'Yellow' ? 'warningPackage.svg' : 'warningRedIcon.svg'}
            className={styles.warningRedIcon}
          />
          <span className={styles.bannerText}>{text}</span>
          {location.pathname != '/buy' ? (
            <span className={styles.bannerText}>
              &nbsp;{' '}
              <span className={color == 'Yellow' ? styles.bannerTextYellow : styles.bannerTextRed}>
                Click here
              </span>
              &nbsp; to recharge.
            </span>
          ) : (
            ''
          )}
        </div>
      </Card>
    );
  }
  return null;
};

export const NoPackageBanner = () => {
  return (
    <Card
      className={cn(styles.bannerCardContainer, styles.noPackageBanner)}
      onClick={() => {
        window.open('mailto:cs@springverify.com', '_blank');
      }}
    >
      <div className={styles.bannerDivContainer}>
        <Image name={'warningPackage.svg'} className={styles.warningRedIcon} />
        <span className={styles.ptext}>
          There are no packages currently assigned to your account. Please reach out to{' '}
          <span className={styles.ctaColor}>SpringVerify team.</span>
        </span>
      </div>
    </Card>
  );
};

export const AdminBounceEmailBannerVisible = () => {
  const url = new URL(window.location.href);
  const path = url.pathname;

  return (
    <div
      onClick={() => {
        if (!path.includes('myTeam')) window.open(`${AppConstants.baseURL}myTeam`, '_self');
      }}
      className={styles.adminBouncedBanner}
    >
      <Banner whiteTextColor={true} clsName='adminBounceEmailBanner' name='whiteWarning.svg'>
        The invite email to some of the admins you have added has bounced. Please correct them in
        order for the admins to access the portal by visiting the
        <Link className={styles.myTeamTag}>My Team</Link>
        page.
      </Banner>
    </div>
  );
};

export const AddCreditsWarning = () => {
  return (
    <div
      className={styles.cursorPointer}
      onClick={() => {
        window.open('mailto:cs@springverify.com', '_blank');
      }}
    >
      <Banner clsName={'addCreditsWarning'}>
        <div className={styles.actionsContainer}>
          <div className={styles.warningText}>
            <span className={styles.warningTextFirst}>Payment is due.&nbsp;</span>
            <span className={styles.warningSecondFirst}>
              You have unpaid invoices. Please reach out to
            </span>
          </div>
          <div className={styles.invoices}>
            <a href='mailto:cs@springverify.com' target='_blank' className={styles.mailTag}>
              SpringVerify team
            </a>
          </div>
          &nbsp;to clear them.
          {/* Needs to be improvised from the product level so commenting this for now*/}
          {/* <div className={styles.invoices}>
          <span onClick={() => props.historyProps.history.push('/invoices')}>Payment & Invoices</span>
        </div> */}
        </div>
      </Banner>
    </div>
  );
};

export const CreditLimitExceeded = () => {
  return (
    <div
      className={styles.cursorPointer}
      onClick={() => {
        window.location.href = 'mailto:cs@springverify.com';
      }}
    >
      <Banner clsName={'addCreditsWarning'}>
        <div className={styles.fontWeight600}>
          Your company has exceeded the the maximum outstanding balance. Please reach out to&nbsp;
          <a className={styles.linkColor} href='mailto:cs@springverify.com' target='_blank'>
            SpringVerify team
          </a>
          &nbsp;for assistance.{' '}
        </div>
      </Banner>
    </div>
  );
};
