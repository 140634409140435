import React, { useState } from 'react';
import 'react-dates/initialize';
import { isInclusivelyBeforeDay, SingleDatePicker as DatePicker } from 'react-dates';

import Image from './../Image';
import moment from 'moment';

const SingleDatePicker = ({
  form: { setFieldValue, setFieldTouched, values },
  field,
  ...props
}) => {
  const [focusedInput, setFocusedInut] = useState({
    calendarFocused: false
  });
  return (
    <DatePicker
      date={values[field.name]}
      displayFormat={props.displayFormat}
      onDateChange={(date) => {
        setFieldValue(field.name, date);
      }}
      isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
      placeholder={props.placeholder}
      numberOfMonths={1}
      inputIconPosition={props.inputIconPosition}
      focused={focusedInput.calendarFocused}
      onFocusChange={({ focused }) => setFocusedInut({ calendarFocused: focused })}
      customInputIcon={<Image name='calenderIcon.svg' />}
      customCloseIcon={
        <Image style={{ marginRight: '15px', marginTop: '6px' }} name='closeIcon.svg' />
      }
      showClearDate={props.showClearDate}
      disabled={props.disabled}
    />
  );
};

SingleDatePicker.defaultProps = {
  displayFormat: 'DD MMM YYYY',
  inputIconPosition: 'after',
  placeholder: ' DD MMM YYYY',
  disabled: false,
  showClearDate: true,
  customInputIcon: <Image name='calenderIcon.svg' />
};

export default SingleDatePicker;
