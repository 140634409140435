// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CmlHJW6Z3igQZX2rpRDc {
  border-radius: 5px;
  padding: 0 16px;
  border: 0;
  height: 45px;
}`, "",{"version":3,"sources":["webpack://./src/core-components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;AACF","sourcesContent":[".button {\n  border-radius: 5px;\n  padding: 0 16px;\n  border: 0;\n  height: 45px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `CmlHJW6Z3igQZX2rpRDc`
};
export default ___CSS_LOADER_EXPORT___;
