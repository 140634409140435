import React from 'react';
import Form from 'react-bootstrap/Form';

import styles from './ValidatedRadioFields.module.scss';

export default ({ field, form, label, options, handleChangeValue, customClassNames, ...props }) => {
  return (
    <Form.Group style={{ display: 'flex' }} controlId={field && field.name} {...props}>
      {label && <Form.Label className={styles.question}>{label}</Form.Label>}
      {options &&
        options.map((option, i) => {
          return (
            <Form.Check
              data-testid='checkbox'
              className={styles.option + ' ' + customClassNames}
              custom
              onChange={(e) => {
                form.setFieldValue(field.name, e.target.value);
                // this func() only comes for TDS from add candidate, buy pkg, ADC recharge flows only
                typeof handleChangeValue === 'function' && handleChangeValue(e.target.value);
              }}
              value={option.value}
              key={i}
              type='radio'
              id={field.name + option.value}
              label={option.label}
              checked={String(option.value) === String(field.value)}
              disabled={option.disable}
            />
          );
        })}
    </Form.Group>
  );
};
