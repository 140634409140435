// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lz3DDmuRARQqu45EhJEr {
  line-height: 24px;
}
.lz3DDmuRARQqu45EhJEr .HO_2Na3wEiHZS1a2iQG5 .gIzUX5JDzbnV6LqlWdOQ {
  text-align: center;
}
.lz3DDmuRARQqu45EhJEr .HO_2Na3wEiHZS1a2iQG5 .MF7aHZnRanqAX0fWSyYT {
  padding-left: 0;
}
.lz3DDmuRARQqu45EhJEr .Fla4B5TX_zIBjaoyWswq {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
.lz3DDmuRARQqu45EhJEr .dXpIFNyZgd6ItX1ENTvp {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.lz3DDmuRARQqu45EhJEr .wvPoHPLA_ap0iT7yQVHi button {
  border-radius: 5px;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.lz3DDmuRARQqu45EhJEr .wvPoHPLA_ap0iT7yQVHi .JxwohviRv23rZHzUEBog {
  border: 2px solid #cfc3fb;
  color: #6558f5;
}
.lz3DDmuRARQqu45EhJEr .wvPoHPLA_ap0iT7yQVHi .xlPvSLt8cMCebpeJI9L3 {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Transactions/Components/CancelModal/CancelModal.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACI;EACE,kBAAA;AACN;AACI;EACE,eAAA;AACN;AAEE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAAJ;AAEE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGI;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADN;AAGI;EACE,yBAAA;EACA,cAAA;AADN;AAGI;EACE,YAAA;AADN","sourcesContent":[".confirmationModal {\n  line-height: 24px;\n  .confirModalBody {\n    .questionImage {\n      text-align: center;\n    }\n    .confirmTextContainer {\n      padding-left: 0;\n    }\n  }\n  .confirmModalHeading {\n    font-weight: 600;\n    font-size: 16px;\n    color: #262626;\n  }\n  .confirmText {\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 22px;\n    color: #595959;\n  }\n  .confirmModalFooter {\n    button {\n      border-radius: 5px;\n      padding: 5px 16px;\n      font-weight: 500;\n      font-size: 16px;\n      line-height: 24px;\n    }\n    .cancelButton {\n      border: 2px solid #cfc3fb;\n      color: #6558f5;\n    }\n    .submitButton {\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": `lz3DDmuRARQqu45EhJEr`,
	"confirModalBody": `HO_2Na3wEiHZS1a2iQG5`,
	"questionImage": `gIzUX5JDzbnV6LqlWdOQ`,
	"confirmTextContainer": `MF7aHZnRanqAX0fWSyYT`,
	"confirmModalHeading": `Fla4B5TX_zIBjaoyWswq`,
	"confirmText": `dXpIFNyZgd6ItX1ENTvp`,
	"confirmModalFooter": `wvPoHPLA_ap0iT7yQVHi`,
	"cancelButton": `JxwohviRv23rZHzUEBog`,
	"submitButton": `xlPvSLt8cMCebpeJI9L3`
};
export default ___CSS_LOADER_EXPORT___;
