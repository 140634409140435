import React from 'react';
import Button from 'react-bootstrap/Button';
import cn from 'classnames';

import styles from './Button.module.scss';

export default (props) => {
  return (
    <Button
      {...props}
      variant={props.disabled && props.variant !== 'link' ? 'secondary' : props.variant}
      className={props.variant !== 'link' && cn(styles.button, props.className)}
    >
      {props.children}
    </Button>
  );
};
