import React, { useEffect, useState } from 'react';

import { Card, Row, Col } from 'react-bootstrap';

import Package from './Package';
import CustomSpinner from '../../../../core-components/CustomSpinner';

import styles from './PackageListing.module.scss';
import _ from 'lodash';

function PackageListing(props) {
  const packageType = props?.packageType === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
  const { viewBuyPackages, selectedPackageIndex } = props;
  const [packages, setPackages] = useState([]);
  const handleBuy = (subtypeId) => {
    props?.handleSelectedPackageIndex(subtypeId);
    props?.handleStepChange(2);
  };
  useEffect(() => {
    if (!viewBuyPackages?.loading && viewBuyPackages?.rows?.length > 0) {
      const insufficient_packages = viewBuyPackages?.rows.filter(pckg => pckg.currentBalance === 0);
      const sufficient_packages = viewBuyPackages?.rows.filter(pckg => pckg.currentBalance > (Math.floor(pckg.leastRechargeUnitsTier * 0.2)));
      const low_packages = viewBuyPackages?.rows.filter(pckg => (pckg.currentBalance !== 0) && (pckg.currentBalance <= (Math.floor(pckg.leastRechargeUnitsTier * 0.2))));
      const allPackages = [...insufficient_packages, ..._.orderBy(low_packages, ['currentBalance']), ..._.orderBy(sufficient_packages, ['currentBalance'])];
      setPackages(allPackages);
    }
  }, [viewBuyPackages])
  return (
    <>
      <div className={styles.header}>
        <div className={styles.heading}>{'Packages'}</div>
      </div>

      <Card className={styles.packagesCard}>
        <Row className={styles.packagesDetailsContainer}>
          <Col lg={3} md={3} sm={3} xs={3} className={styles.packagesDetailsLeft}>
            <h3>{'Verify candidates with these packages'}</h3>
          </Col>
          <Col lg={9} md={9} sm={9} xs={9} className={styles.packagesDetailsRight}>
            {props?.viewBuyPackages &&
              !props.viewBuyPackages.loading &&
              props.viewBuyPackages.rows ? (
              props.viewBuyPackages?.rows.length ? (
                <>
                  {packages.map((packg, index) => {
                    return (
                      <Package
                        key={index}
                        index={index}
                        pDetails={packg}
                        showPricing={true}
                        showBuyBtn={true}
                        showBorder={index === selectedPackageIndex}
                        handleBuy={handleBuy}
                        packageType={packageType}
                        isAadhaarAllowed={
                          props?.createCandConfig?.includeAadhaarInIdCheck === 1 ? true : false
                        }
                        createFriendlyNameFlow={true}
                        getPackages={props?.getPackages}
                      />
                    );
                  })}
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '50vh'
                  }}
                >
                  No package is assigned to this company. Please contact cs@springverify.com
                </div>
              )
            ) : (
              <div
                style={{
                  display: 'flex',

                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <CustomSpinner
                  className='bgvLoader'
                  as='div'
                  animation='border'
                  size='md'
                  role='status'
                  aria-hidden='true'
                />
              </div>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default PackageListing;
