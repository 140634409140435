import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isArray } from 'lodash';
import { toast } from 'react-toastify';

import {
  getErrorMessage,
  getAnyCount,
  getAnyCountAddressCourt
} from '../../../../../utils/utilities';

const mapStateToProps = (state) => {
  return {
    candidateConfig: state.createCandConfig || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const { pDetails, savedValues } = props;
      let baseValues = {};
      let idCount = getAnyCount(pDetails.config.identity);
      let addressCount = getAnyCountAddressCourt(pDetails.config.address);
      let courtCount = getAnyCountAddressCourt(pDetails.config.court);
      let refBaseCount = 0;

      let refConfig = [];
      if (
        pDetails.config.reference &&
        ['SPECIFIC', 'SPECIFIC_LOCK'].includes(pDetails.config.referenceType)
      ) {
        refConfig = pDetails.config.reference;
        refBaseCount = pDetails.config.reference.reduce((a, b) => a + b);
      } else if (pDetails.config.referenceType === 'TOTAL') {
        refBaseCount = pDetails.config.reference;
        refConfig =
          pDetails.config.reference > pDetails.config.employment
            ? new Array(pDetails.config.reference || 0).fill(0)
            : new Array(pDetails.config.employment || 0).fill(0);
      }

      baseValues = {
        identity: idCount
          ? {
              any: idCount,
              baseCount: idCount,
              baseConfig: pDetails.config.identity,
              config: []
            }
          : null,
        address:
          addressCount && addressCount === 1 && pDetails?.config?.address?.length === 1
            ? {
                baseCount: addressCount,
                baseConfig: pDetails.config.address,
                value: addressCount ? 'ANY_1' : null
              }
            : null,
        court:
          courtCount && courtCount === 1 && pDetails?.config?.court?.length === 1
            ? {
                baseCount: courtCount,
                baseConfig: pDetails.config.court,
                value: courtCount ? 'ANY_1' : null
              }
            : null,
        reference: {
          baseCount: refBaseCount,
          config: refConfig
        }
      };

      let storedValues = {};
      if (savedValues && savedValues.customise) {
        storedValues = { ...savedValues.customise };
      }

      return mergeWith(baseValues, storedValues, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, props }) => {
      props.onSubmit({ customise: values });
      setSubmitting(false);
    },
    displayName: 'Customize verifcation'
  })
);
