// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TMUpiTx0v8NPKAnyapFg {
  width: 100%;
  height: 100px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.uzeAVItVgoMMM5Le4Gxw {
  margin-top: 15px;
  font-size: 20px;
  color: #12a999;
  text-align: center;
}

.NO4qNT44TD1eZbXUvxKn {
  margin-top: 25px;
  font-size: 15px;
  color: black;
  text-align: center;
}

.OO4450WlWbgSDi9SyDW6 {
  width: 100%;
  margin-top: 35px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.Fc_UJOxco575Lthczxw7 {
  width: 120px;
}

.paUZQY2HpsyZrrAq0pqR {
  padding: 0px !important;
  margin: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Upsell/UpsellModals/UpsellSuccessModal/UpsellSuccessModal.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,sBAAA;AACF","sourcesContent":[".checkIcon {\n  width: 100%;\n  height: 100px;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n}\n\n.thankYouText {\n  margin-top: 15px;\n  font-size: 20px;\n  color: #12a999;\n  text-align: center;\n}\n\n.reachOutText {\n  margin-top: 25px;\n  font-size: 15px;\n  color: black;\n  text-align: center;\n}\n\n.footer {\n  width: 100%;\n  margin-top: 35px;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n}\n\n.cancelBtn {\n  width: 120px;\n}\n\n.modalBody {\n  padding: 0px !important;\n  margin: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkIcon": `TMUpiTx0v8NPKAnyapFg`,
	"thankYouText": `uzeAVItVgoMMM5Le4Gxw`,
	"reachOutText": `NO4qNT44TD1eZbXUvxKn`,
	"footer": `OO4450WlWbgSDi9SyDW6`,
	"cancelBtn": `Fc_UJOxco575Lthczxw7`,
	"modalBody": `paUZQY2HpsyZrrAq0pqR`
};
export default ___CSS_LOADER_EXPORT___;
