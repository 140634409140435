// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zLRRgNvH8iwnu73oMb6n {
  display: flex;
  justify-content: space-between;
  margin-top: -190px;
  font-family: Poppins, sans-serif;
}
.zLRRgNvH8iwnu73oMb6n .VFM6deGIfAmk6ccb2ekb {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.OSLguuyacYTgzisrPnA6 {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: 30px auto 48px;
  padding: 48px;
  font-family: Poppins, sans-serif;
  min-height: 695px;
}
.OSLguuyacYTgzisrPnA6 .rHAo8bvttYvgU7WY9T7I .PyU2TnV0Iu9S6ZWpuKIU {
  font-weight: 500;
  font-size: 32px;
  color: #333333;
}
.OSLguuyacYTgzisrPnA6 .rHAo8bvttYvgU7WY9T7I .MgWZpP_hiD8TNsawDJjD {
  max-height: 640px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/ViewBuyPackage/PackageListing/PackageListing.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,gCAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAEJ;;AACA;EACE,mBAAA;EACA,gDAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,gCAAA;EACA,iBAAA;AAEF;AAAI;EACE,gBAAA;EACA,eAAA;EACA,cAAA;AAEN;AAAI;EACE,iBAAA;EACA,gBAAA;AAEN","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  margin-top: -190px;\n  font-family: Poppins, sans-serif;\n  .heading {\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 36px;\n    color: #ffffff;\n  }\n}\n.packagesCard {\n  background: #ffffff;\n  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);\n  border-radius: 4px;\n  margin: 30px auto 48px;\n  padding: 48px;\n  font-family: Poppins, sans-serif;\n  min-height: 695px;\n  .packagesDetailsContainer {\n    .packagesDetailsLeft {\n      font-weight: 500;\n      font-size: 32px;\n      color: #333333;\n    }\n    .packagesDetailsRight {\n      max-height: 640px;\n      overflow-y: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `zLRRgNvH8iwnu73oMb6n`,
	"heading": `VFM6deGIfAmk6ccb2ekb`,
	"packagesCard": `OSLguuyacYTgzisrPnA6`,
	"packagesDetailsContainer": `rHAo8bvttYvgU7WY9T7I`,
	"packagesDetailsLeft": `PyU2TnV0Iu9S6ZWpuKIU`,
	"packagesDetailsRight": `MgWZpP_hiD8TNsawDJjD`
};
export default ___CSS_LOADER_EXPORT___;
