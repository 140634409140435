// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._7z0a0SEwjREjHhECzrq {
  background: #2755fe;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 136px;
  margin-right: 0px;
}
._7z0a0SEwjREjHhECzrq:hover {
  background: #2755fe;
  color: #ffffff;
}

.lXZeCv_o1sO4YPACAMGb {
  display: block;
}

.IDS7bcEf2txWj9jIEBRB {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyAdmin/Candidates/FilterModal/FilterModal.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;AACF;AAAE;EACE,mBAAA;EACA,cAAA;AAEJ;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,eAAA;EACA,cAAA;EACA,4BAAA;AAGF","sourcesContent":[".submitButton {\n  background: #2755fe;\n  border-radius: 5px;\n  font-family: Poppins, sans-serif;\n  font-size: 14px;\n  line-height: 20px;\n  text-align: center;\n  color: #ffffff;\n  width: 136px;\n  margin-right: 0px;\n  &:hover {\n    background: #2755fe;\n    color: #ffffff;\n  }\n}\n.customFooter {\n  display: block;\n}\n.errorMsg {\n  font-size: 13px;\n  color: #dc3545;\n  margin-top: -30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": `_7z0a0SEwjREjHhECzrq`,
	"customFooter": `lXZeCv_o1sO4YPACAMGb`,
	"errorMsg": `IDS7bcEf2txWj9jIEBRB`
};
export default ___CSS_LOADER_EXPORT___;
