import React, { useState } from 'react';
import styles from './Utils.module.scss'; // Assuming you have a CSS module for styling
import Image from '../../core-components/Image/index';
import { copyToClipboard } from '../../utils/Utilities';
import cn from 'classnames';

function TextWithCopyButton({
  text,
  number = 50,
  message = 'Copied to Clipboard!',
  className,
  isSA = false
}) {
  const [clickToCopy, setClickToCopy] = useState(false);

  const copyToClipboardHandler = () => {
    copyToClipboard(text, message);
  };

  const truncatedText = text && text?.slice(0, number) + (text.length > number ? '..' : '');

  return (
    <div className={cn(styles.generateTokenButton__container, className ? className : '')}>
      <div className={styles.textContainer}>
        <span className={styles.cutoffText}>{truncatedText}</span>
      </div>
      <div
        onClick={() => {
          setClickToCopy(true);
          copyToClipboardHandler();
        }}
        className={styles.tooltip}
      >
        <Image isSA={isSA} className={styles.copyToClipboard} name={'CopyToken.svg'} />
        <span className={styles.tooltiptext}>{clickToCopy ? 'Copied' : 'Copy'}</span>
      </div>
    </div>
  );
}

export default TextWithCopyButton;
