import React from 'react';

import AddEditModal from './AddEditModal';
import AddEditModalPackage from './AddEditModalPackage';
import AddEditUpload from './AddEditUpload';

class EducationModals extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    switch (this.props.currentSubStep) {
      case 1:
        return this.props.isSpecific ? (
          <AddEditModalPackage {...this.props} />
        ) : (
          <AddEditModal {...this.props} />
        );
      case 2:
        return <AddEditUpload {...this.props} />;
      default:
        return null;
    }
  }
}

export default EducationModals;
